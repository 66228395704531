/*
 * DrawPhotoMarkerMode
 *
 * Draw a photo marker on the map using Mapbox style (not Marker API)
 *
 * Type DrawPhotoMarkerModeState:
 *    feature : DrawFeature<JsonFeature>
 */
import { pluck } from '@range.io/functional'
import BasicDrawMode from './basic-draw-mode.js'

const DrawPhotoMarkerMode = { ...BasicDrawMode }

/*
 * @sig onSetup :: {} -> DrawPhotoMarkerModeState
 */
DrawPhotoMarkerMode.onSetup = function (startOptions) {
    this.deselect(pluck('id', this.getSelected()))
    const feature = this.newFeature({
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [] },
        properties: {
            annotationType: 'photoMarker',
            isTask: startOptions.selectedTool === 'task',
            // temporarily assign random number of photo count
            photoCount: 4, // to keep Cypress tests consistent; was: Math.floor(10 * Math.random()),
        },
    })
    this.addFeature(feature)
    return { feature }
}

/*
 * @sig onTap :: (DrawPhotoMarkerModeState, Event) -> ()
 * @sig onClick :: (DrawPhotoMarkerModeState, Event) -> ()
 */
DrawPhotoMarkerMode.onTap = DrawPhotoMarkerMode.onClick = function (state, e) {
    state.feature.updateCoordinate('', e.lngLat.lng, e.lngLat.lat)
    this.finishDrawing(state)
}

/*
 * @sig finishDrawing :: (DrawPhotoMarkerModeState) -> ()
 */
DrawPhotoMarkerMode.finishDrawing = function (state) {
    this.map.fire('draw.create', { features: [state.feature.toGeoJSON()] })
    this.changeMode('select', { featureId: state.feature.id })
}

/*
 * @sig cancelDrawingAndDelete :: (DrawPhotoMarkerModeState) -> ()
 */
DrawPhotoMarkerMode.cancelDrawingAndDelete = function (state) {
    this.deleteFeature([state.feature.id])
    this.changeMode('idle')
}

/*
 * @sig toDisplayFeatures (DrawPhotoMarkerModeState, GeoJsonFeature, DisplayFunc) -> ()
 * DisplayFunc = GeoJsonFeature -> ()
 */
DrawPhotoMarkerMode.toDisplayFeatures = function (state, geojson, display) {
    geojson.properties.active = geojson.properties.id === state.feature.id ? 'true' : 'false'
    display(geojson)
}

export default DrawPhotoMarkerMode
