/*
 * Admin Page: Members panel
 */
import { Invitation, Participant } from '@range.io/basic-types'
import { assoc, mapObject, pluck, uniq, without } from '@range.io/functional'
import React, { useMemo, useRef, useState } from 'react'
import { useSelector, useStore } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import { useSearch } from '../components-reusable/hooks/index.js'
import useBulkSelectMode from '../components-reusable/hooks/useBulkSelectMode.js'
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    DropdownMenuWithTooltip,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    ScrollArea,
    StatusPill,
    Tabs,
    Text,
    TextInput,
    Tooltip,
} from '../components-reusable/index.js'
import { SelectPermissions } from '../components-reusable/Select.js'
import { InvitationAddedCommand } from '../firebase/commands/invitation-added-command.js'
import { InvitationChangedCommand } from '../firebase/commands/invitation-changed-command.js'
import { InvitationRemovedCommand } from '../firebase/commands/invitation-removed-command.js'
import { OrganizationParticipantChangedCommand } from '../firebase/commands/organization-participant-changed-command.js'
import { OrganizationParticipantReInvitedCommand } from '../firebase/commands/organization-participant-re-invited-command.js'
import { OrganizationParticipantRemovedCommand } from '../firebase/commands/organization-participant-removed-command.js'
import { OrganizationParticipantRemovedSelfCommand } from '../firebase/commands/organization-participant-removed-self-command.js'
import { ProjectParticipantAddedCommand } from '../firebase/commands/project-participant-added-command.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'

import { styled } from '../range-theme/index.js'
import { ParticipantShape } from '../react-shapes/participant-shape.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import * as Segment from '../segment/segment.js'
import AdminNavigationPanel, { AdminNavigationType } from './AdminNavigationPanel.js'
import AdminOrganizationsPanel from './AdminOrganizationsPanel.js'
import AdminViewManageProjects, { ProjectListRow, ToggleAllRow } from './AdminViewManageProjects.js'
import InvitationDisplay from './InvitationDisplay.js'
import {
    LeaveOrganizationModal,
    StyledModalActions,
    StyledModalContainer,
    StyledOverlay,
    StyledPrimaryModalLabel,
    StyledSecondaryModalLabel,
    SuspendMemberModal,
} from './Modal.js'
import { SearchInput, TableTools } from './TableTools.js'

const DEFAULT_SORT_SETTINGS = { field: 'fullName', isAscending: true }
const SORT_TYPES = {
    name: 'fullName',
    projectsCount: 'projectsCount',
}
const SHOW_CHECKBOXES = false // temporary flag to hide checkboxes, till we implement bulk-actions features

const PARTICIPANTS_DROPDOWN_FILTER_OPTIONS = [
    { id: 'active', name: 'Active members' },
    { id: 'all', name: 'All members' },
    { id: 'invited', name: 'Invited members' },
]
const GUESTS_DROPDOWN_FILTER_OPTIONS = [
    { id: 'active', name: 'Active Guests' },
    { id: 'all', name: 'All Guests' },
    { id: 'invited', name: 'Pending Invites' },
]

const memberName = member => `${member.firstName} ${member.lastName}`
const memberFallback = member => `${member.firstName[0]}${member.lastName[0]}`

const StyledSelect = styled(SelectPermissions, {
    lineHeight: '30px',
    width: '376px',
})

const permissionDropdownOptions = {
    toAdmin: {
        id: 'Admin',
        name: 'Admin',
        description: 'Paid • Access to all projects and settings. Can view and edit all pins.',
    },
    toCollaborator: {
        id: 'Collaborator',
        name: 'Collaborator',
        description: 'Paid • Access to specific projects. Can view and edit all pins.',
    },
    promoteToMember: {
        id: 'promoteToMember',
        name: 'Upgrade to Organization Member',
        description: 'Paid • Upgrade this Guest to a paid Organization Member',
    },
    downgradeToGuest: {
        id: 'downgradeToGuest',
        name: 'Downgrade to Guest',
        description: 'Free • Access to specific projects. Can only view and edit pins they are following.',
    },
    suspendMember: {
        id: 'suspendMember',
        name: 'Suspend Member',
        description: `Free • Disable member's log in your Organization. All user data will be preserved.`,
        destructive: true,
    },
    suspendGuest: {
        id: 'suspendGuest',
        name: 'Suspend Guest',
        description: `Free • Disable Guest's log in your Organization. All user data will be preserved.`,
        destructive: true,
    },
    leaveOrganization: {
        id: 'leaveOrganization',
        name: 'Leave Organization',
        description: 'Leave this organization',
        destructive: true,
    },
    resendInvitation: {
        id: 'resendInvitation',
        name: 'Resend Invitation',
        description: 'Resend the email invitation to this person',
    },
    cancelInvitation: {
        id: 'cancelInvitation',
        name: 'Cancel Invitation',
        description: 'Cancel this email invitation',
        destructive: true,
    },
}

const permissionsForMember = (isLeavingOrgAllowed, userIsAdmin, participantIsMe, participant) => {
    const permissionsForUser = []

    if (!participantIsMe) {
        permissionsForUser.push(permissionDropdownOptions.toAdmin)
        permissionsForUser.push(permissionDropdownOptions.toCollaborator)
        permissionsForUser.push(permissionDropdownOptions.downgradeToGuest)
    } else {
        // current user can see only their own role as he cannot change it
        if (participant.organizationRole === 'Admin') permissionsForUser.push(permissionDropdownOptions.toAdmin)
        else permissionsForUser.push(permissionDropdownOptions.toCollaborator)
    }

    permissionsForUser.map(permission => {
        return { ...permission, disabled: participantIsMe || !userIsAdmin }
    })

    if (isLeavingOrgAllowed && participantIsMe) permissionsForUser.push(permissionDropdownOptions.leaveOrganization)

    if (userIsAdmin && !participantIsMe) permissionsForUser.push(permissionDropdownOptions.suspendMember)

    return permissionsForUser
}

const permissionsForMemberInvite = canManageInvitations => {
    return canManageInvitations
        ? [
              permissionDropdownOptions.toAdmin,
              permissionDropdownOptions.toCollaborator,
              permissionDropdownOptions.downgradeToGuest,
              permissionDropdownOptions.resendInvitation,
              permissionDropdownOptions.cancelInvitation,
          ].filter(option => option)
        : []
}

const permissionsForGuest = (isLeavingOrgAllowed, canManageParticipants, participantIsMe) => {
    const permissions = []

    if (canManageParticipants && !participantIsMe) {
        permissions.push(permissionDropdownOptions.promoteToMember)
    }

    if (isLeavingOrgAllowed && participantIsMe) {
        permissions.push(permissionDropdownOptions.leaveOrganization)
    }

    if (canManageParticipants && !participantIsMe) {
        permissions.push(permissionDropdownOptions.suspendGuest)
    }

    return permissions
}

// eslint-disable-next-line no-unused-vars
const permissionsForGuestInvite = canManageInvitations => {
    return canManageInvitations
        ? [
              permissionDropdownOptions.promoteToMember,
              permissionDropdownOptions.resendInvitation,
              permissionDropdownOptions.cancelInvitation,
          ]
        : []
}

const hasAdminRole = el => el.organizationRole === 'Admin'
const hasGuestRole = el => el.organizationRole === 'Guest'

const isActuallyANumber = str => !isNaN(parseFloat(str)) // check if string can be turned to a number

const sortTwoStrings = (valA, valB, ascending) => {
    // Explicitly check for undefined values
    if (typeof valA === 'undefined') return ascending ? -1 : 1
    if (typeof valB === 'undefined') return ascending ? 1 : -1

    // Perform case insensitive comparison
    if (ascending) {
        return valA.toLowerCase().localeCompare(valB.toLowerCase())
    } else {
        return -valA.toLowerCase().localeCompare(valB.toLowerCase())
    }
}

const sortTwoNumbers = (valA, valB, ascending) => {
    if (!valA) return ascending ? -1 : 1
    if (!valB) return ascending ? 1 : -1
    if (ascending) return valA - valB
    return valB - valA
}

/**
 * Sort items by field or joinedFields.
 * @param  {Array} items
 * @param  {Object} sortSettings {field, joinedFields, isAscending}
 * @return {Array} the items array sorted
 */
const sortByField = ({ items, sortSettings }) => {
    const { field, joinedFields, isAscending } = sortSettings

    return items.sort((a, b) => {
        const valA = joinedFields ? joinedFields.map(singleField => a[singleField]).join(' ') : a[field]
        const valB = joinedFields ? joinedFields.map(singleField => b[singleField]).join(' ') : b[field]

        // some fields are stored as strings, but actually are numbers, i.e. identifiers
        // so those shouldn't be compared as strings
        if (typeof valA === 'string' && !isActuallyANumber(valA)) {
            return sortTwoStrings(valA, valB, isAscending)
        } else {
            if (!valA) return isAscending ? -1 : 1
            if (!valB) return isAscending ? 1 : -1
            if (isAscending) return valA - valB
            return valB - valA
        }
    })
}

const sortParticipantsByProjectsCount = (a, b, state, ascending) => {
    const projectsCountA = Object.keys(ReduxSelectors.currentProjectsForParticipant(state, a)).length
    const projectsCountB = Object.keys(ReduxSelectors.currentProjectsForParticipant(state, b)).length

    const isUserAAdmin = hasAdminRole(a)
    const isUserBAdmin = hasAdminRole(b)
    const bothAreAdmins = isUserAAdmin && isUserBAdmin
    const bothAreCollaborators = !isUserAAdmin && !isUserBAdmin

    // sort them alphabetically if both are admins or they have the same projects count
    if (bothAreAdmins || (bothAreCollaborators && projectsCountA === projectsCountB)) {
        const nameA = Participant.fullName(a)
        const nameB = Participant.fullName(b)
        return sortTwoStrings(nameA, nameB, ascending)
    } else if (isUserAAdmin) return ascending ? 1 : -1
    else if (isUserBAdmin) return ascending ? -1 : 1

    return sortTwoNumbers(projectsCountA, projectsCountB, ascending)
}

const sortByNameOrEmail = (a, b, ascending) => {
    // if the invitation is not a re-invitation, it means we only have user email
    const valA = a.firstName ? [a.firstName, a.lastName].join(' ') : a.inviteeEmail
    const valB = b.firstName ? [b.firstName, b.lastName].join(' ') : b.inviteeEmail

    return sortTwoStrings(valA, valB, ascending)
}

const sortInviteesByProjectsCount = (a, b, ascending) => {
    // if invitation grants admin rights it means user has access to all projects
    // if both compared invitations grant admin right, then sort them alphabetically by either invitee name or email
    if (hasAdminRole(a) && hasAdminRole(b)) return sortByNameOrEmail(a, b, ascending)
    else if (hasAdminRole(a)) return ascending ? 1 : -1
    else if (hasAdminRole(b)) return ascending ? -1 : 1

    // by default compare projects count
    const projectsCountA = a.projectIds?.length
    const projectsCountB = b.projectIds?.length

    return sortTwoNumbers(projectsCountA, projectsCountB, ascending)
}

const SelectOrgPermission = ({ disabled, options, value, onValueChange }) => (
    <StyledSelect
        triggerCss={{ width: '118px' }}
        disabled={disabled}
        options={options}
        value={value}
        onValueChange={onValueChange}
    />
)

const StyledPrimaryText = styled(Text, {
    fs: '14px',
    fw: '600',

    variants: {
        variant: {
            activeMember: {
                color: '$neutral04',
            },
            suspendedMember: {
                color: '$neutral05',
                textDecoration: 'line-through',
            },
            secondaryInfo: {
                fw: 500,
            },
            secondaryInfoSuspendedMember: {
                fw: 500,
                color: '$neutral05',
            },
        },
    },
})

const StyledSecondaryText = styled(Text, {
    fs: '12px',
    fg: '$neutral05',
})

// checkbox | avatar | user details | projects | role selector
const tableRowGridColumns = SHOW_CHECKBOXES ? '12px 32px 1fr 178px 1fr' : '0 32px 1fr 178px 1fr'

// checkbox | avatar | user details | re-invite button
const suspendedTableRowGridColumns = SHOW_CHECKBOXES ? '12px 32px 1fr 178px' : '0 32px 1fr 178px'

const BaseTableRow = styled(Box, {
    padding: '0px 16px',
    display: 'grid',
    gridGap: '16px',
    h: '70px',
    ai: 'center',
    bt: '1px solid $neutral07',
})

const StyledTableRow = styled(BaseTableRow, {
    gridTemplateColumns: tableRowGridColumns,
})

const StyledSuspendedTableRow = styled(BaseTableRow, {
    gridTemplateColumns: suspendedTableRowGridColumns,
})

const StyledTableHeader = styled(StyledTableRow, {
    color: '$neutral05',
    fs: 14,
    background: '$neutral09',
    outline: '1px solid $neutral07',
    border: 'none',
    height: '40px',
})

const Step1ModalActions = ({ disabled, useNextStep, submitButtonCopy = 'Next Step', onCancel, onSend }) => {
    const sendInvitations = () => (
        <Button
            data-cy="send-invites"
            css={{ h: 40 }}
            disabled={disabled}
            variant="primary"
            size="lg"
            onClick={onSend}
            withLoadingState
        >
            {submitButtonCopy}
        </Button>
    )
    const nextStep = () => (
        <Button data-cy="next-step" css={{ h: 40 }} disabled={disabled} variant="primary" size="lg" onClick={onSend}>
            {submitButtonCopy}
        </Button>
    )

    return (
        <StyledModalActions>
            <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                Cancel
            </Button>
            {useNextStep ? nextStep() : sendInvitations()}
        </StyledModalActions>
    )
}
const Step2ModalActions = ({ onBack, onSend, submitButtonCopy }) => (
    <StyledModalActions>
        <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onBack}>
            Back
        </Button>
        <Button data-cy="send-invites" css={{ h: 40 }} variant="primary" size="lg" onClick={onSend} withLoadingState>
            {submitButtonCopy}
        </Button>
    </StyledModalActions>
)

const ProjectInviteList = ({
    hasProjects,
    isViewEmpty,
    isToggleInProgress,
    projects,
    projectSelected,
    onProjectChecked,
}) => {
    if (!hasProjects)
        return (
            <Flex css={{ justifyContent: 'center' }}>
                <Text
                    css={{
                        color: '$neutral05',
                        mt: 24,
                        mb: 24,
                        fs: 14,
                        ml: 16,
                        mr: 16,
                        textAlign: 'center',
                    }}
                >
                    Your Organization has no active projects. <br />
                    Click "Send Invites" to continue.
                </Text>
            </Flex>
        )

    if (isViewEmpty)
        return (
            <FlexColumn css={{ height: 'inherit', alignItems: 'center', justifyContent: 'center', pt: 56, pb: 56 }}>
                <Text css={{ color: '$neutral05', fs: '14px', fw: '500' }}>No matches found.</Text>
            </FlexColumn>
        )

    return projects.map(project => (
        <ProjectListRow
            key={project.id}
            disabled={isToggleInProgress}
            name={project.name}
            setChecked={() => onProjectChecked(project.id)}
            checked={projectSelected[project.id]}
            project={project}
            onClick={() => {}}
        />
    ))
}

const SelectProjectsModal = ({
    description,
    isTogglingProjectsInProgress,
    projects,
    projectSelected,
    selectedProjectCount,
    title,
    hideStep2,
    submitButtonCopy,
    onCancel,
    onSend,
    toggleProjectSelected,
    onToggleAllProjects,
}) => {
    const searchFilterSettings = {
        fields: ['name', 'address'],
    }

    const {
        searchValue,
        setSearchValue,
        filteredItems: filteredProjects,
    } = useSearch({
        ...searchFilterSettings,
        items: projects,
    })

    const activeProjects = useMemo(() => {
        return Object.keys(projectSelected).reduce((acc, projectId) => {
            if (!projectSelected[projectId]) return acc
            return [...acc, projectId]
        }, [])
    }, [projectSelected])

    const areAllProjectsActive = activeProjects.length === projects.length

    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer type="wide">
                <FlexColumn style={{ maxHeight: '100%', overflow: 'hidden' }}>
                    <FlexColumn css={{ padding: '24px', maxHeight: '100%', overflow: 'hidden' }}>
                        <FlexRow css={{ alignItems: 'center', pb: '8px', gap: '8px' }}>
                            <Icon name="share16x17" css={{ width: '24px' }} />
                            <StyledPrimaryModalLabel>{title}</StyledPrimaryModalLabel>
                        </FlexRow>
                        <FlexRow
                            css={{ alignItems: 'center', fs: 14, lh: 1.5, pb: '16px', gap: '8px', color: '$neutral05' }}
                        >
                            {description}
                        </FlexRow>
                        <TextInput
                            css={{ width: '100%', mb: '8px', flex: '1 0 auto' }}
                            placeholder="Search for projects..."
                            onChange={setSearchValue}
                            value={searchValue}
                        />
                        {searchValue === '' && (
                            <ToggleAllRow
                                isToggleInProgress={isTogglingProjectsInProgress}
                                projectsCount={projects.length}
                                checked={areAllProjectsActive}
                                onCheckedChange={isOn =>
                                    onToggleAllProjects(isOn, projects, activeProjects, toggleProjectSelected)
                                }
                            />
                        )}
                        <FlexRow css={{ alignItems: 'center', fs: 12, fw: 500, lh: 1.5, pb: '8px', gap: '8px' }}>
                            {selectedProjectCount}x selected
                        </FlexRow>
                        <ScrollArea
                            css={{ display: 'grid', background: '$neutral09', b: '1px solid $neutral07', br: 6 }}
                        >
                            <ProjectInviteList
                                hasProjects={projects.length > 0}
                                isViewEmpty={filteredProjects.length === 0}
                                isToggleInProgress={isTogglingProjectsInProgress}
                                projects={filteredProjects}
                                onProjectChecked={id => toggleProjectSelected(id)}
                                projectSelected={projectSelected}
                            />
                        </ScrollArea>
                    </FlexColumn>
                    <Step2ModalActions onBack={() => hideStep2()} onSend={onSend} submitButtonCopy={submitButtonCopy} />
                </FlexColumn>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

const InviteMembersEmailsModal = ({
    action,
    copy,
    disabled,
    emails,
    inputRef,
    level,
    maxEmailCount,
    permissionOptions,
    preexistingEmails,
    showLevelSelector,
    showProjectsStep,
    submitButtonCopy,
    variant,
    onCancel,
    onLevelChange,
    setEmails,
    setDisabled,
}) => (
    <StyledOverlay onClick={onCancel}>
        <StyledModalContainer type="wide">
            <Box css={{ padding: '24px' }}>
                <FlexColumn css={{ pb: '8px', gap: '10px' }}>
                    <FlexRow css={{ alignItems: 'center', gap: '8px' }}>
                        <Icon name="team" css={{ width: '24px', color: '$neutral05' }} />
                        <StyledPrimaryModalLabel>{copy.modalTitle}</StyledPrimaryModalLabel>
                    </FlexRow>
                    {copy.modalDescription && (
                        <StyledSecondaryModalLabel>{copy.modalDescription}</StyledSecondaryModalLabel>
                    )}
                </FlexColumn>

                {variant !== 're-invite' && (
                    <InvitationDisplay
                        preexistingEmails={preexistingEmails}
                        maxEmailCount={maxEmailCount}
                        emails={emails}
                        setEmails={setEmails}
                        onCancel={onCancel}
                        ref={inputRef}
                        setHasValidInvitation={valid => setDisabled(!valid)}
                        defaultPlaceholder={copy.inputPlaceholder}
                        variant={variant}
                    />
                )}
                {showLevelSelector && (
                    <FlexRow css={{ alignItems: 'center', pt: 16, gap: '8px' }}>
                        <Text css={{ mr: 'auto', color: '$neutral05', fw: 400 }}>{copy.permissionsLabel}</Text>
                        <SelectPermissions
                            value={level}
                            onValueChange={onLevelChange}
                            options={permissionOptions}
                            css={{ height: 40, width: 200 }}
                            triggerVariant="light"
                        />
                    </FlexRow>
                )}
            </Box>
            <Step1ModalActions
                disabled={disabled}
                useNextStep={showProjectsStep}
                onCancel={onCancel}
                onSend={action}
                submitButtonCopy={submitButtonCopy}
            />
        </StyledModalContainer>
    </StyledOverlay>
)

/*
 * Invite Organization members modal
 */
const InviteOrganizationMembersModal = ({
    isTogglingProjectsInProgress,
    preexistingEmails,
    maxEmailCount,
    variant = 'default',
    onSend,
    onCancel,
}) => {
    const isValidEmail = email => /\S+@\S+\.\S+/.test(email)

    const _onSend = e => {
        const projectIds = () => {
            if (isAdmin) return [] // projectIds are ignored for admins

            const entries = Object.entries(projectSelected).filter(([id, allow]) => allow)
            return pluck(0, entries)
        }

        e.preventDefault()

        // handle the value still in the input that hadn't yet become a "pill"
        // because we clicked "Send Invites" rather than typing one of the keys that turns text into a pill
        const email = inputRef.current?.value
        const toSend = emails.concat(email).filter(isValidEmail)
        const isAdmin = level === 'Admin'

        if (toSend.length > 0 || variant === 're-invite')
            onSend({ emails: toSend, organizationRole: level, projectIds: projectIds() })
    }

    const action = e => (level === 'Admin' ? _onSend(e) : setShowStep2(true))
    const isDisabledByDefault = variant !== 're-invite'
    const getDefaultRole = variant => {
        if (variant === 'guests') return 'Guest'
        return 'Admin'
    }

    const [emails, setEmails] = useState([])
    const [disabled, setDisabled] = useState(isDisabledByDefault)
    const [level, setLevel] = useState(getDefaultRole(variant))
    const [showStep2, setShowStep2] = useState(false)
    const inputRef = useRef(null)
    const projects = useSelector(ReduxSelectors.organizationProjectAsArray).filter(project => !project.isArchived) // do not show archived projects on the projects list

    const allProjectsFalse = projects.reduce((acc, p) => assoc(p.id, false, acc), {})
    const [projectSelected, setProjectSelected] = useState(allProjectsFalse)
    const selectedProjectCount = Object.values(projectSelected).filter(a => a).length

    const toggleProjectSelected = id => setProjectSelected(assoc(id, !projectSelected[id], projectSelected))

    const onToggleAllProjects = isOn => {
        const newProjectsSelected = {}
        projects.forEach(project => {
            newProjectsSelected[project.id] = isOn
        })
        setProjectSelected(newProjectsSelected)
    }

    const onLevelChange = value => {
        setLevel(value)
    }

    const shouldShowLevelSelector = variant !== 'guests' // only show levels dropdown if we are not on the guests tab, as guests have their own logic
    const shouldShowProjectsAccessStep = level !== 'Admin' // only show projects access modal step if the access level we want to grant is other than Admin

    const levels = {
        default: [
            {
                id: 'Admin',
                name: 'Admins',
                description: 'Paid • Access to all projects and settings. Can view and edit all pins.',
            },
            {
                id: 'Collaborator',
                name: 'Collaborators',
                description: 'Paid • Access to specific projects. Can view and edit all pins.',
            },
        ],
        're-invite': [
            {
                id: 'Admin',
                name: 'Admin',
                description: 'Paid • Access to all projects and settings. Can view and edit all pins.',
            },
            {
                id: 'Collaborator',
                name: 'Collaborator',
                description: 'Paid • Access to specific projects. Can view and edit all pins.',
            },
        ],
    }

    const copy = {
        default: {
            modalTitle: 'Invite Organization Members',
            inputPlaceholder: 'Email, comma separated',
            permissionsLabel: 'Invite new Organization members as',
            projectsScreenDescription: `Enable the projects you would like the new "Collaborators" to work on. You can also manage
                        project access later if you haven't set these up yet.`,
            projectsScreenTitle: 'Share Project Access',
        },
        guests: {
            modalTitle: 'Invite Organization Guests',
            inputPlaceholder: 'Email, comma separated',
            projectsScreenDescription: `Select the projects you would like to enable access too. You can also manage project access later if you haven't set these up yet.`,
            projectsScreenTitle: 'Enable Project Access',
        },
        're-invite': {
            modalTitle: 'Re-Invite Organization Member',
            modalDescription: 'Select the role you want to re-invite this person as.',
            permissionsLabel: 'Re-Invite Organization Member as',
            projectsScreenDescription: `Enable the projects you would like the user to work on. You can also manage
                        project access later if you haven't set these up yet.`,
            projectsScreenTitle: 'Share Project Access',
        },
    }

    const guestLevel = {
        id: 'Guest',
        name: 'Guest',
        description: 'Free • Access to specific projects. Can only view and edit pins they are following.',
    }
    levels['re-invite'].push(guestLevel)

    const submitButtonCopy = shouldShowProjectsAccessStep
        ? 'Next Step'
        : emails.length > 1
        ? 'Send Invites'
        : 'Send Invite'

    return (
        <>
            <InviteMembersEmailsModal
                copy={copy[variant]}
                onCancel={onCancel}
                action={action}
                disabled={disabled}
                emails={emails}
                inputRef={inputRef}
                level={level}
                maxEmailCount={maxEmailCount}
                permissionOptions={levels[variant]}
                preexistingEmails={preexistingEmails}
                showLevelSelector={shouldShowLevelSelector}
                showProjectsStep={shouldShowProjectsAccessStep}
                submitButtonCopy={submitButtonCopy}
                variant={variant}
                onLevelChange={onLevelChange}
                setEmails={setEmails}
                setDisabled={setDisabled}
            />
            {showStep2 && (
                <SelectProjectsModal
                    description={copy[variant].projectsScreenDescription}
                    isTogglingProjectsInProgress={isTogglingProjectsInProgress}
                    maxEmailCount={maxEmailCount}
                    projects={projects}
                    projectSelected={projectSelected}
                    title={copy[variant].projectsScreenTitle}
                    toggleProjectSelected={toggleProjectSelected}
                    onToggleAllProjects={onToggleAllProjects}
                    hideStep2={() => setShowStep2(false)}
                    selectedProjectCount={selectedProjectCount}
                    submitButtonCopy={emails.length > 1 ? 'Send Invites' : 'Send Invite'}
                    onCancel={onCancel}
                    onSend={_onSend}
                />
            )}
        </>
    )
}

/*
 * Promote Guest modal
 */
const PromoteGuestModal = ({ guest, onSend, onCancel }) => {
    const [organizationRole, setOrganizationRole] = useState('Admin')

    const onSubmit = () => onSend({ guest, organizationRole })

    const organizationRoles = [
        { id: 'Admin', name: 'Admin', description: 'Access to all projects and settings. Can view and edit all pins.' },
        {
            id: 'Collaborator',
            name: 'Collaborator',
            description: 'Access to specific projects. Can view and edit all pins.',
        },
    ]

    return (
        <>
            <StyledOverlay onClick={onCancel}>
                <StyledModalContainer type="wide">
                    <Box css={{ padding: '24px' }}>
                        <FlexColumn css={{ pb: '8px', gap: '10px' }}>
                            <FlexRow css={{ alignItems: 'center', gap: '8px' }}>
                                <Icon name="team" css={{ width: '24px', color: '$neutral05' }} />
                                <StyledPrimaryModalLabel>Promote Organization Guest to Member</StyledPrimaryModalLabel>
                            </FlexRow>
                        </FlexColumn>

                        <FlexRow css={{ alignItems: 'center', pt: 16, gap: '8px' }}>
                            <Text css={{ textAlign: 'left', flex: '1 1 auto', color: '$neutral05', fw: 400 }}>
                                Promote Guest to
                            </Text>
                            <SelectPermissions
                                value={organizationRole}
                                onValueChange={setOrganizationRole}
                                options={organizationRoles}
                                css={{ height: 40, width: 330 }}
                                triggerVariant="light"
                            />
                        </FlexRow>
                    </Box>
                    <Step1ModalActions
                        useNextStep={false}
                        onCancel={onCancel}
                        onSend={onSubmit}
                        submitButtonCopy="Promote"
                    />
                </StyledModalContainer>
            </StyledOverlay>
        </>
    )
}

/*
 * Downgrade User modal
 */
const DowngradeUserModal = ({ user, onSend, onCancel }) => {
    const [projectSelected, setProjectSelected] = useState(false) // by default start from all projects toggled off
    const [showStep2, setShowStep2] = useState(false)

    const projects = useSelector(ReduxSelectors.organizationProjectAsArray).filter(project => !project.isArchived) // do not show archived projects on the projects list
    const selectedProjectCount = Object.values(projectSelected).filter(a => a).length
    const shouldShowProjectsAccessStep = hasAdminRole(user)

    // Collaborators skip the project access step, because when turning into Guest they preserve their project access.
    // This will only be triggered for Admin users
    const _onSend = () => {
        const projectIds = () => {
            const entries = Object.entries(projectSelected).filter(([id, allow]) => allow)
            return pluck(0, entries)
        }

        onSend({ user, projectIds: projectIds() })
    }

    const onSubmit = () => (shouldShowProjectsAccessStep ? setShowStep2(true) : onSend({ user }))

    const onToggleAllProjects = isOn => {
        const newProjectsSelected = {}
        projects.forEach(project => {
            newProjectsSelected[project.id] = isOn
        })
        setProjectSelected(newProjectsSelected)
    }

    const toggleProjectSelected = id => setProjectSelected(assoc(id, !projectSelected[id], projectSelected))

    return (
        <>
            <StyledOverlay onClick={onCancel}>
                <StyledModalContainer type="admin">
                    <Box css={{ padding: '24px' }}>
                        <FlexColumn css={{ pb: '8px', gap: '10px' }}>
                            <FlexRow css={{ alignItems: 'center', gap: '8px' }}>
                                <StyledPrimaryModalLabel>Downgrade to "Guest"</StyledPrimaryModalLabel>
                            </FlexRow>
                            <FlexRow
                                css={{
                                    alignItems: 'center',
                                    fs: 14,
                                    lh: 1.5,
                                    gap: '8px',
                                    color: '$neutral05',
                                }}
                            >
                                Guests will ONLY have access to pins they are following or have created.
                            </FlexRow>
                        </FlexColumn>
                    </Box>
                    <Step1ModalActions
                        useNextStep={shouldShowProjectsAccessStep}
                        onCancel={onCancel}
                        onSend={onSubmit}
                        submitButtonCopy={shouldShowProjectsAccessStep ? 'Next Step' : 'Downgrade'}
                    />
                </StyledModalContainer>
            </StyledOverlay>
            {showStep2 && (
                <SelectProjectsModal
                    description="Select the projects you would like to enable access too. You can also manage project access later if you haven't set these up yet."
                    projects={projects}
                    projectSelected={projectSelected}
                    title="Enable Project Access"
                    toggleProjectSelected={toggleProjectSelected}
                    hideStep2={() => setShowStep2(false)}
                    submitButtonCopy="Confirm"
                    selectedProjectCount={selectedProjectCount}
                    onToggleAllProjects={onToggleAllProjects}
                    onCancel={onCancel}
                    onSend={_onSend}
                />
            )}
        </>
    )
}

const StyledTableHeaderLabel = styled(FlexRow, {
    gap: '3px',
    mr: 'auto',
    ai: 'center',
    cursor: 'pointer',
    color: '$neutral05',
    p: '4px 6px 4px 6px',
    ml: -6,
    br: 6,

    '&:hover': { color: '$neutral04', background: '$neutral07' },
})

const TableHeaderColumn = ({ label, onSortColumnClick, type, sortSettings, tooltipText }) => {
    const isSortedByThisColumn = !!sortSettings && sortSettings?.field === type
    const isAscending = isSortedByThisColumn && sortSettings?.isAscending

    const iconCss = {
        transform: !isAscending ? 'rotate(180deg)' : false,
    }

    const handleSortClick = () =>
        onSortColumnClick({ type, isAscending: isSortedByThisColumn ? isAscending : undefined })

    return (
        <Tooltip tooltipText={tooltipText} side="bottom" align="center">
            <StyledTableHeaderLabel data-cy={`task-list-header-button-${type}`} onClick={handleSortClick}>
                <Text>{label}</Text>
                {isSortedByThisColumn && <Icon css={iconCss} name="chevronDown" iconSize="16" />}
            </StyledTableHeaderLabel>
        </Tooltip>
    )
}

/*
 * The header the Member table
 */
const MemberTableHeader = ({
    isChecked,
    isPartiallyChecked,
    sortSettings,
    onSortColumnClick,
    onCheckAllItemsClick,
}) => {
    // Columns: checkbox | empty | name | projects | permissions
    return (
        <StyledTableHeader css={{ flex: '1 0 auto' }}>
            {SHOW_CHECKBOXES ? (
                <Checkbox
                    size="md"
                    onClick={onCheckAllItemsClick}
                    checked={isChecked}
                    partiallyChecked={isPartiallyChecked}
                    data-cy="list-view-header-checkbox"
                />
            ) : (
                <div />
            )}
            <div />

            <TableHeaderColumn
                label="Name"
                type={SORT_TYPES.name}
                onSortColumnClick={onSortColumnClick}
                sortSettings={sortSettings}
                tooltipText="Sort by name"
            />
            <TableHeaderColumn
                label="Total Projects"
                type={SORT_TYPES.projectsCount}
                onSortColumnClick={onSortColumnClick}
                sortSettings={sortSettings}
                tooltipText="Sort by project count"
            />
            <div />
        </StyledTableHeader>
    )
}

/*
 * The header of the Suspended Users table
 */
const SuspendedUsersTableHeader = ({
    isChecked,
    isPartiallyChecked,
    sortSettings,
    onCheckAllItemsClick,
    onSortColumnClick,
}) => {
    // Columns: checkbox | avatar | name | re-invite button
    return (
        <StyledTableHeader css={{ flex: '1 0 auto' }}>
            {SHOW_CHECKBOXES ? (
                <Checkbox
                    size="md"
                    onClick={onCheckAllItemsClick}
                    checked={isChecked}
                    partiallyChecked={isPartiallyChecked}
                    data-cy="list-view-header-checkbox"
                />
            ) : (
                <div />
            )}

            <div />
            <TableHeaderColumn
                label="Name"
                type={SORT_TYPES.name}
                onSortColumnClick={onSortColumnClick}
                sortSettings={sortSettings}
                tooltipText="Sort by name"
            />
            <div />
        </StyledTableHeader>
    )
}

const ManageProjectsButton = ({ onManageProjects }) => {
    const css = { background: '$neutral10', fw: 500, color: '$primary04' }
    return (
        <Button css={css} variant="secondary" size="md" onClick={onManageProjects} role="button" tabIndex="0">
            Manage Projects
        </Button>
    )
}

/*
 * A single row of the Member table
 *
 */
const ParticipantTableRow = ({
    participantIsMe, // needed to prevent me from being able to edit my own permissions
    canManageParticipants,
    participant, // row of the table
    onPermissionDropdownOptionChange, // Admin <=> Collaborator
    onParticipantSelected, // eg. "show the Manage Projects modal"
    projectCount,
    isLeavingOrgAllowed,
    isChecked,
    onParticipantChecked,
}) => {
    const { avatarUrl, organizationRole } = participant
    const projectList = organizationRole === 'Collaborator' ? projectCount : 'All'
    const showManageAsLink = canManageParticipants && participant.organizationRole === 'Collaborator'

    const permissionsForUser = permissionsForMember(
        isLeavingOrgAllowed,
        canManageParticipants,
        participantIsMe,
        participant
    )

    const onManageProjects = () => onParticipantSelected(participant)

    return (
        <StyledTableRow>
            {SHOW_CHECKBOXES ? (
                <Checkbox
                    size="md"
                    defaultChecked={true}
                    checked={isChecked}
                    onClick={e => e.stopPropagation()} // do not bubble
                    onCheckedChange={() => onParticipantChecked(participant.id)}
                />
            ) : (
                <div />
            )}
            <Avatar fallbackText={memberFallback(participant)} url={avatarUrl} />
            <Box css={{ fd: 'row' }}>
                <StyledPrimaryText variant="activeMember">{memberName(participant)}</StyledPrimaryText>
                <StyledSecondaryText>{participant.email}</StyledSecondaryText>
            </Box>
            <FlexRow css={{ gap: '12px', height: '40px', alignItems: 'center' }}>
                <Text css={{ minWidth: 20, width: 'auto' }}>{projectList}</Text>
                {showManageAsLink && <ManageProjectsButton onManageProjects={onManageProjects} />}
            </FlexRow>
            <FlexRow css={{ justifyContent: 'flex-end', gap: '4px', height: '40px' }}>
                <SelectOrgPermission
                    disabled={!canManageParticipants && !participantIsMe} // must be Admin or this is my (logged user) permission
                    isUserThisParticipant={participantIsMe}
                    options={permissionsForUser}
                    value={organizationRole}
                    onValueChange={onPermissionDropdownOptionChange({ participant })}
                />
            </FlexRow>
        </StyledTableRow>
    )
}

const GuestOptionsDropdown = ({ options, onOptionChange }) => {
    return (
        <DropdownMenuWithTooltip.Root>
            <DropdownMenuWithTooltip.Trigger tooltipText="More Actions">
                <Button
                    css={{ w: 32, h: 32, alignSelf: 'center', color: '$neutral05' }}
                    variant="dropdown-trigger"
                    data-cy="guest-actions-trigger"
                >
                    <Icon iconSize="32px" name="threeDot" />
                </Button>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Portal>
                <DropdownMenuWithTooltip.Content
                    data-cy="guest-actions-dropdown"
                    side="bottom"
                    align="end"
                    sideOffset={6}
                    css={{
                        backgroundColor: '$neutral10',
                    }}
                    onClick={e => e.stopPropagation()} // do not propagate to prevent clicking project card
                >
                    {options.map(option => {
                        return (
                            <DropdownMenuWithTooltip.MenuItem
                                key={option.id}
                                value={option.id}
                                destructive={option.destructive}
                            >
                                <Button
                                    variant="dropdown-menuitem"
                                    disabled={option.disabled}
                                    onClick={() => {
                                        onOptionChange(option.id)
                                    }}
                                >
                                    <FlexColumn
                                        css={{
                                            alignItems: 'flex-start',
                                            gap: 2,
                                            width: 330,
                                        }}
                                    >
                                        <Text
                                            css={{
                                                color: option.destructive ? '$red03' : '$neutral04',
                                            }}
                                        >
                                            {option.name}
                                        </Text>
                                        <Text
                                            css={{
                                                fs: 12,
                                                color: option.destructive ? '$red03' : '$neutral05',
                                                textAlign: 'left',
                                            }}
                                        >
                                            {option.description}
                                        </Text>
                                    </FlexColumn>
                                </Button>
                            </DropdownMenuWithTooltip.MenuItem>
                        )
                    })}
                </DropdownMenuWithTooltip.Content>
            </DropdownMenuWithTooltip.Portal>
        </DropdownMenuWithTooltip.Root>
    )
}

/*
 * A single row of the Guest table
 *
 */
const GuestTableRow = ({
    guest, // row of the table
    guestIsMe, // needed to prevent me from being able to edit my own permissions
    isLeavingOrgAllowed,
    isChecked,
    projectCount = 0,
    canManageParticipants, // should the logged-in user be able to do things?
    onPermissionDropdownOptionChange,
    onGuestSelected, // eg. "show the Manage Projects modal"
    onParticipantChecked,
}) => {
    const { avatarUrl } = guest
    const permissions = permissionsForGuest(isLeavingOrgAllowed, canManageParticipants, guestIsMe)
    const canAccessPermissions = canManageParticipants || guestIsMe
    const onManageProjects = () => onGuestSelected(guest)

    const handleOptionChange = optionId => onPermissionDropdownOptionChange({ participant: guest })(optionId)

    return (
        <StyledTableRow>
            {SHOW_CHECKBOXES ? (
                <Checkbox
                    size="md"
                    defaultChecked={true}
                    checked={isChecked}
                    onClick={e => e.stopPropagation()} // do not bubble
                    onCheckedChange={() => onParticipantChecked(guest.id)}
                />
            ) : (
                <div />
            )}
            <Avatar fallbackText={memberFallback(guest)} url={avatarUrl} />
            <Box css={{ fd: 'row' }}>
                <StyledPrimaryText variant="activeMember">{memberName(guest)}</StyledPrimaryText>
                <StyledSecondaryText>{guest.email}</StyledSecondaryText>
            </Box>
            <FlexRow css={{ gap: '12px', height: '40px', alignItems: 'center' }}>
                <Text css={{ minWidth: 20, width: 'auto' }}>{projectCount}</Text>
                {canManageParticipants && <ManageProjectsButton onManageProjects={onManageProjects} />}
            </FlexRow>
            <FlexRow css={{ justifyContent: 'flex-end', gap: '4px', height: '40px' }}>
                {canAccessPermissions && (
                    <GuestOptionsDropdown options={permissions} onOptionChange={handleOptionChange} />
                )}
            </FlexRow>
        </StyledTableRow>
    )
}

/*
 * A single row of the Member table
 *
 */
const SuspendedParticipantTableRow = ({
    canManageParticipants, // should the logged-in user be able to do things?
    participant, // row of the table
    isChecked,
    onParticipantChecked,
    handleReInviteSuspendedParticipant,
}) => {
    const { avatarUrl } = participant

    return (
        <StyledSuspendedTableRow>
            {SHOW_CHECKBOXES ? (
                <Checkbox
                    size="md"
                    defaultChecked={true}
                    checked={isChecked}
                    onClick={e => e.stopPropagation()} // do not bubble
                    onCheckedChange={() => onParticipantChecked(participant.id)}
                />
            ) : (
                <div />
            )}
            <Avatar fallbackText={memberFallback(participant)} url={avatarUrl} />
            <Box css={{ fd: 'row' }}>
                <FlexRow css={{ gap: 6 }}>
                    <FlexColumn>
                        <FlexRow css={{ gap: 6 }}>
                            <StyledPrimaryText variant="suspendedMember">{memberName(participant)}</StyledPrimaryText>
                            <StatusPill>SUSPENDED</StatusPill>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
            </Box>
            {canManageParticipants && (
                <FlexRow css={{ gap: 16, alignItems: 'center', ml: 'auto' }}>
                    <Button
                        css={{ background: '$neutral10', fw: 500, color: '$primary04', width: 148, minWidth: 148 }}
                        variant="secondary"
                        size="lg"
                        onClick={() => handleReInviteSuspendedParticipant(participant)}
                        role="button"
                        tabIndex="0"
                    >
                        <Icon name="accountUserAdd" iconSize="17" />
                        Re-Invite
                    </Button>
                </FlexRow>
            )}
        </StyledSuspendedTableRow>
    )
}

const GuestInvitationTableRow = ({
    canManageInvitations,
    invitation,
    onPermissionDropdownOptionChange,
    onSelectedInvitation,
}) => {
    const {
        inviteeEmail,
        firstName,
        lastName,
        inviterFirstName,
        inviterLastName,
        projectIds,
        timestamp,
        resentTimestamp,
    } = invitation
    const hasName = firstName && lastName
    const projectsCount = projectIds?.length || 0
    const permissions = permissionsForGuestInvite(canManageInvitations)
    const onManageProjects = () => onSelectedInvitation(invitation)
    const invitationTimestamp = resentTimestamp || timestamp

    const handleOptionChange = optionId => onPermissionDropdownOptionChange({ invitation })(optionId)

    return (
        <StyledTableRow>
            <div />
            <Avatar fallbackText={inviteeEmail[0]} />
            <Box css={{ fd: 'row' }}>
                <FlexRow css={{ gap: 6 }}>
                    <StyledPrimaryText variant="activeMember">
                        {hasName ? `${firstName} ${lastName}` : inviteeEmail}
                    </StyledPrimaryText>
                    <StatusPill variant="pending">PENDING</StatusPill>
                </FlexRow>
                <StyledSecondaryText>
                    Invited by {inviterFirstName} {inviterLastName} on {invitationTimestamp.toLocaleDateString()}
                </StyledSecondaryText>
            </Box>

            <FlexRow css={{ gap: '12px', height: '40px', alignItems: 'center' }}>
                <Text css={{ minWidth: 20, width: 'auto' }}>{projectsCount}</Text>
                {canManageInvitations && <ManageProjectsButton onManageProjects={onManageProjects} />}
            </FlexRow>
            <FlexRow css={{ justifyContent: 'flex-end', gap: '4px', height: '40px' }}>
                {canManageInvitations && (
                    <GuestOptionsDropdown options={permissions} onOptionChange={handleOptionChange} />
                )}
            </FlexRow>
        </StyledTableRow>
    )
}

const GuestInvitationsTable = ({
    canManageInvitations,
    invitations,
    onPermissionDropdownOptionChange,
    onSelectedInvitation,
}) => {
    return (
        <FlexColumn css={{ width: '100%' }}>
            {invitations
                .filter(i => !i.acceptedTimestamp)
                .map(invitation => (
                    <GuestInvitationTableRow
                        key={invitation.inviteeEmail}
                        invitation={invitation}
                        canManageInvitations={canManageInvitations}
                        onPermissionDropdownOptionChange={onPermissionDropdownOptionChange}
                        onSelectedInvitation={onSelectedInvitation}
                    />
                ))}
        </FlexColumn>
    )
}

/*
 * Temporary until we get NewMemberTableRow working properly
 */
const InvitationsTable = ({
    invitations,
    resendInvitation,
    canManageInvitations,
    onPermissionDropdownOptionChange,
    onSelectedInvitation,
}) => {
    const InvitationTableRow = ({ invitation, resendInvitation, canManageInvitations }) => {
        const {
            inviteeEmail,
            firstName,
            lastName,
            inviterFirstName,
            inviterLastName,
            organizationRole,
            projectIds,
            timestamp,
            resentTimestamp,
        } = invitation
        const hasName = firstName && lastName
        const projectList = organizationRole === 'Collaborator' ? projectIds?.length || 0 : 'All'
        const showManageProjects = canManageInvitations && organizationRole === 'Collaborator'
        const onManageProjects = () => onSelectedInvitation(invitation)
        const options = permissionsForMemberInvite(canManageInvitations)
        const invitationTimestamp = resentTimestamp || timestamp

        return (
            <StyledTableRow>
                <div />
                <Avatar fallbackText={inviteeEmail[0]} />
                <Box css={{ fd: 'row' }}>
                    <FlexRow css={{ gap: 6 }}>
                        <StyledPrimaryText variant="activeMember">
                            {hasName ? `${firstName} ${lastName}` : inviteeEmail}
                        </StyledPrimaryText>
                        <StatusPill variant="pending">PENDING</StatusPill>
                    </FlexRow>
                    <StyledSecondaryText>
                        Invited by {inviterFirstName} {inviterLastName} on {invitationTimestamp.toLocaleDateString()}
                    </StyledSecondaryText>
                </Box>

                <FlexRow css={{ gap: '12px', height: '40px', alignItems: 'center' }}>
                    <Text css={{ minWidth: 20, width: 'auto' }}>{projectList}</Text>
                    {showManageProjects && <ManageProjectsButton onManageProjects={onManageProjects} />}
                </FlexRow>
                <FlexRow css={{ justifyContent: 'flex-end', gap: '4px', height: '40px' }}>
                    <SelectOrgPermission
                        disabled={!canManageInvitations}
                        isUserThisParticipant={false}
                        options={options}
                        value={organizationRole}
                        onValueChange={onPermissionDropdownOptionChange({ invitation })}
                    />
                </FlexRow>
            </StyledTableRow>
        )
    }

    return (
        <FlexColumn css={{ width: '100%' }}>
            {invitations
                .filter(i => !i.acceptedTimestamp)
                .map(invitation => (
                    <InvitationTableRow
                        key={invitation.inviteeEmail}
                        invitation={invitation}
                        resendInvitation={resendInvitation}
                        canManageInvitations={canManageInvitations}
                    />
                ))}
        </FlexColumn>
    )
}

const ParticipantsTable = ({
    state,
    participants,
    user,
    canManageParticipants,
    setOrganizationParticipant,
    onPermissionDropdownOptionChange,
    isLeavingOrgAllowed,
    checkedParticipants,
    handleParticipantChecked,
}) => {
    // one row of the table
    return participants.map(participant => {
        const projects = ReduxSelectors.currentProjectsForParticipant(state, participant)
        return (
            <ParticipantTableRow
                key={participant.id}
                participantIsMe={participant.id === user.id}
                canManageParticipants={canManageParticipants}
                participant={participant}
                onParticipantSelected={setOrganizationParticipant}
                onPermissionDropdownOptionChange={onPermissionDropdownOptionChange}
                projectCount={Object.keys(projects).length}
                isLeavingOrgAllowed={isLeavingOrgAllowed}
                isChecked={checkedParticipants.includes(participant.id)}
                onParticipantChecked={handleParticipantChecked}
            />
        )
    })
}

const SuspendedParticipantsTable = ({
    canManageParticipants,
    participants,
    checkedParticipants,
    handleReInviteSuspendedParticipant,
    handleParticipantChecked,
}) => {
    // one row of the table
    return participants.map(participant => (
        <SuspendedParticipantTableRow
            key={participant.id}
            canManageParticipants={canManageParticipants}
            participant={participant}
            isChecked={checkedParticipants.includes(participant.id)}
            onParticipantChecked={handleParticipantChecked}
            handleReInviteSuspendedParticipant={handleReInviteSuspendedParticipant}
        />
    ))
}

const MembersTable = ({
    areAllParticipantsChecked,
    checkedParticipants,
    dropdownFilterOptions,
    isLeavingOrgAllowed,
    participantsDropdownFilterValue,
    projects,
    searchValue,
    showMembersModal,
    sortedInvitations,
    participants,
    sortSettings,
    state,
    user,
    canManageParticipants,
    handleCheckAllItems,
    handleParticipantChecked,
    handlePermissionDropdownOptionChange,
    onSortColumnClick,
    resendInvitation,
    setOrganizationParticipant,
    setParticipantsDropdownFilterValue,
    setSearchValue,
    setSelectedInvitation,
}) => {
    const shouldShowActiveUsers =
        participantsDropdownFilterValue === 'all' || participantsDropdownFilterValue === 'active'

    const shouldShowInvites =
        canManageParticipants &&
        (participantsDropdownFilterValue === 'all' || participantsDropdownFilterValue === 'invited')

    const participantsInvitations = sortedInvitations.filter(invitation => !hasGuestRole(invitation))

    return (
        <FlexColumn css={{ gap: '16px', marginTop: '16px' }}>
            <TableTools
                canManageParticipants={canManageParticipants}
                dropdownFilterOptions={dropdownFilterOptions}
                dropdownFilterValue={participantsDropdownFilterValue}
                membersButtonText="Invite Members"
                membersButtonDataCy="invite-members"
                searchValue={searchValue}
                onMembersButtonClick={showMembersModal}
                onDropdownFilterValueChange={setParticipantsDropdownFilterValue}
                setSearchValue={setSearchValue}
            />

            <Box
                css={{
                    border: '1px solid $neutral07',
                    br: '6px',
                    fg: '$neutral04',
                    bg: '$neutral09',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <MemberTableHeader
                    isChecked={areAllParticipantsChecked}
                    isPartiallyChecked={checkedParticipants.length && !areAllParticipantsChecked}
                    onCheckAllItemsClick={handleCheckAllItems}
                    sortSettings={sortSettings}
                    onSortColumnClick={onSortColumnClick}
                />
                <ScrollArea>
                    {shouldShowActiveUsers && (
                        <ParticipantsTable
                            participants={participants}
                            state={state}
                            user={user}
                            canManageParticipants={canManageParticipants}
                            setOrganizationParticipant={setOrganizationParticipant}
                            onPermissionDropdownOptionChange={handlePermissionDropdownOptionChange}
                            projects={projects}
                            isLeavingOrgAllowed={isLeavingOrgAllowed}
                            handleParticipantChecked={handleParticipantChecked}
                            checkedParticipants={checkedParticipants}
                        />
                    )}
                    {shouldShowInvites && (
                        <InvitationsTable
                            invitations={participantsInvitations}
                            resendInvitation={resendInvitation}
                            canManageInvitations={canManageParticipants}
                            onPermissionDropdownOptionChange={handlePermissionDropdownOptionChange}
                            onSelectedInvitation={setSelectedInvitation}
                        />
                    )}
                </ScrollArea>
            </Box>
        </FlexColumn>
    )
}

const GuestsTableRows = ({
    canManageParticipants,
    guests,
    user,
    onGuestSelected,
    onPermissionDropdownOptionChange,
    isLeavingOrgAllowed,
    checkedParticipants,
    state,
    handleParticipantChecked,
}) => {
    // one row of the table
    return guests.map(guest => {
        const projects = ReduxSelectors.currentProjectsForParticipant(state, guest)
        return (
            <GuestTableRow
                key={guest.id}
                guest={guest}
                guestIsMe={guest.id === user.id}
                isLeavingOrgAllowed={isLeavingOrgAllowed}
                isChecked={checkedParticipants.includes(guest.id)}
                projectCount={Object.keys(projects).length}
                canManageParticipants={canManageParticipants}
                onGuestSelected={onGuestSelected}
                onParticipantChecked={handleParticipantChecked}
                onPermissionDropdownOptionChange={onPermissionDropdownOptionChange}
            />
        )
    })
}

const GuestsTable = ({
    areAllParticipantsChecked,
    canManageParticipants,
    checkedParticipants,
    dropdownFilterOptions,
    isLeavingOrgAllowed,
    searchValue,
    invitations,
    guests,
    participantsDropdownFilterValue,
    sortSettings,
    state,
    user,
    handleCheckAllItems,
    handleParticipantChecked,
    handlePermissionDropdownOptionChange,
    onShowInviteGuestsModal,
    onSortColumnClick,
    setOrganizationParticipant,
    setParticipantsDropdownFilterValue,
    setSearchValue,
    setSelectedInvitation,
}) => {
    const guestsInvitations = invitations.filter(invitation => hasGuestRole(invitation))

    const shouldShowActiveGuests =
        participantsDropdownFilterValue === 'all' || participantsDropdownFilterValue === 'active'

    const shouldShowInvites =
        canManageParticipants &&
        (participantsDropdownFilterValue === 'all' || participantsDropdownFilterValue === 'invited')

    const hasGuestsOrInvitations = guests.length > 0 || guestsInvitations.length > 0

    return (
        <FlexColumn css={{ gap: '16px', marginTop: '16px' }}>
            <TableTools
                canManageParticipants={canManageParticipants}
                dropdownFilterOptions={dropdownFilterOptions}
                dropdownFilterValue={participantsDropdownFilterValue}
                membersButtonText="Invite Guests"
                membersButtonDataCy="invite-guests"
                searchValue={searchValue}
                onMembersButtonClick={onShowInviteGuestsModal}
                onDropdownFilterValueChange={setParticipantsDropdownFilterValue}
                setSearchValue={setSearchValue}
            />

            <Box
                css={{
                    border: '1px solid $neutral07',
                    br: '6px',
                    fg: '$neutral04',
                    bg: '$neutral09',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <MemberTableHeader
                    isChecked={areAllParticipantsChecked}
                    isPartiallyChecked={checkedParticipants.length && !areAllParticipantsChecked}
                    onCheckAllItemsClick={handleCheckAllItems}
                    sortSettings={sortSettings}
                    onSortColumnClick={onSortColumnClick}
                />
                <ScrollArea>
                    {shouldShowActiveGuests && !hasGuestsOrInvitations && (
                        <FlexColumn css={{ justifyContent: 'center', alignItems: 'center', height: '200px', gap: 12 }}>
                            <Text css={{ color: '$neutral05', fs: 16, textAlign: 'center' }}>No Guests found.</Text>
                            {!!canManageParticipants && (
                                <Button
                                    data-cy="invite-guests"
                                    css={{ width: 170 }}
                                    variant="primary"
                                    size="lg"
                                    onClick={onShowInviteGuestsModal}
                                >
                                    <Icon iconSize="18" name="addUser" />
                                    <Text>Invite Guests</Text>
                                </Button>
                            )}
                        </FlexColumn>
                    )}
                    {shouldShowActiveGuests && hasGuestsOrInvitations && (
                        <GuestsTableRows
                            canManageParticipants={canManageParticipants}
                            checkedParticipants={checkedParticipants}
                            guests={guests}
                            isLeavingOrgAllowed={isLeavingOrgAllowed}
                            state={state}
                            user={user}
                            handleParticipantChecked={handleParticipantChecked}
                            onPermissionDropdownOptionChange={handlePermissionDropdownOptionChange}
                            onGuestSelected={setOrganizationParticipant}
                        />
                    )}
                    {shouldShowInvites && (
                        <GuestInvitationsTable
                            canManageInvitations={canManageParticipants}
                            invitations={guestsInvitations}
                            resendInvitation={() => {}}
                            onPermissionDropdownOptionChange={handlePermissionDropdownOptionChange}
                            onSelectedInvitation={setSelectedInvitation}
                        />
                    )}
                </ScrollArea>
            </Box>
        </FlexColumn>
    )
}

const SuspendedMembersTable = ({
    areAllParticipantsChecked,
    canManageParticipants,
    checkedParticipants,
    searchValue,
    sortedParticipants,
    sortSettings,
    handleCheckAllItems,
    handleParticipantChecked,
    handleReInviteSuspendedParticipant,
    onSortColumnClick,
    setSearchValue,
}) => {
    const hasNoSuspendedUsers = sortedParticipants.suspended.length === 0

    return (
        <FlexColumn css={{ gap: '16px', marginTop: '16px' }}>
            <Flex direction="row" justify="space-between">
                <SearchInput placeholder="Search by name" onChange={setSearchValue} value={searchValue} />
            </Flex>

            {/* Members Table */}
            <Box
                css={{
                    border: '1px solid $neutral07',
                    br: '6px',
                    fg: '$neutral04',
                    bg: '$neutral09',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <SuspendedUsersTableHeader
                    isChecked={areAllParticipantsChecked}
                    isPartiallyChecked={checkedParticipants.length && !areAllParticipantsChecked}
                    onCheckAllItemsClick={handleCheckAllItems}
                    sortSettings={sortSettings}
                    onSortColumnClick={onSortColumnClick}
                />
                <ScrollArea>
                    {hasNoSuspendedUsers && (
                        <Flex css={{ justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <Text css={{ color: '$neutral05', fs: 16, textAlign: 'center' }}>
                                No suspended users found.
                            </Text>
                        </Flex>
                    )}
                    {!hasNoSuspendedUsers && (
                        <SuspendedParticipantsTable
                            participants={sortedParticipants.suspended}
                            canManageParticipants={canManageParticipants}
                            handleReInviteSuspendedParticipant={handleReInviteSuspendedParticipant}
                            handleParticipantChecked={handleParticipantChecked}
                            checkedParticipants={checkedParticipants}
                        />
                    )}
                </ScrollArea>
            </Box>
        </FlexColumn>
    )
}

const AdminViewMembers = () => {
    const showMembersModal = () => setShowInviteMembersModal(true)
    const hideMembersModal = () => setShowInviteMembersModal(false)

    const createInvitation = (email, organizationRole, projectIds) =>
        Invitation.from({
            id: v4(),
            organizationName: organization.name,
            organizationId: organization.id,
            inviterEmail: user.email,
            inviterFirstName: user.firstName,
            inviterLastName: user.lastName,
            inviteeEmail: email,
            organizationRole,
            projectIds,
            timestamp: new Date(),
        })

    const sendInvitations = ({ emails, organizationRole, projectIds }) => {
        emails = without(pluck('email', participants), emails)
        emails = without(pluck('inviteeEmail', invitations), emails)
        emails = uniq(emails)

        if (emails.length === 0) return

        runCommand(InvitationAddedCommand.Outbound(emails.map(e => createInvitation(e, organizationRole, projectIds))))
        hideMembersModal()
        setShowInviteGuestsModal(false)
    }

    const resendInvitation = email =>
        updateInvitationForInvitee({
            inviteeEmail: email,
            changes: {
                resentTimestamp: new Date(),
            },
        })

    const onInviteeProjectsToggled = async (projectIds, isOn) => {
        const invitation = invitations.find(i => i.inviteeEmail === selectedInvitation.inviteeEmail)
        const { id } = invitation

        // Either add or remove project id from the array
        const newProjectIds = isOn
            ? invitation.projectIds.concat(projectIds)
            : without(projectIds, invitation.projectIds)

        return runCommand(
            InvitationChangedCommand.Outbound(invitation, id, {
                projectIds: newProjectIds,
            })
        )
    }

    const updateOrganizationParticipantRole = ({ participant, organizationRole, projectIds }) => {
        const update = Participant.update(participant, { organizationRole })
        return runCommand(OrganizationParticipantChangedCommand.Outbound(organization.id, update, projectIds))
    }

    // Admin <=> Collaborator
    const handlePermissionDropdownOptionChange =
        ({ participant, invitation }) =>
        optionName => {
            switch (optionName) {
                // if the option selected was to leave the organization show the modal
                case permissionDropdownOptions.leaveOrganization.id:
                    setShowLeaveOrganizationModal(true)
                    return
                case permissionDropdownOptions.suspendMember.id:
                case permissionDropdownOptions.suspendGuest.id:
                    setUserToSuspend(participant)
                    return
                case permissionDropdownOptions.cancelInvitation.id:
                    onCancelUserInvite(invitation.inviteeEmail)
                    return
                case permissionDropdownOptions.resendInvitation.id:
                    resendInvitation(invitation.inviteeEmail)
                    return
                case permissionDropdownOptions.promoteToMember.id:
                    setGuestToPromote(participant || invitation)
                    return
                case permissionDropdownOptions.downgradeToGuest.id:
                    setUserToDowngrade(participant || invitation)
                    return
                case permissionDropdownOptions.toAdmin.id:
                case permissionDropdownOptions.toCollaborator.id: {
                    if (participant) {
                        updateOrganizationParticipantRole({ participant, organizationRole: optionName }) // update the user with the new role
                    } else if (invitation) {
                        // update the invitation with new role
                        updateInvitationForInvitee({
                            inviteeEmail: invitation.inviteeEmail,
                            changes: {
                                organizationRole: optionName,
                            },
                        })
                    }
                    break
                }
                default:
                    throw new Error(`Unexpected option ${optionName}`)
            }
        }

    // Change Project permissions for the current participant (we're showing the Manage Projects modal)
    const onParticipantProjectsToggled = (projectIds, isAllowed) =>
        runCommand(ProjectParticipantAddedCommand.Outbound(projectIds, organizationParticipant, !isAllowed))

    const { runCommand } = useCommandHistory()
    const organization = useSelector(ReduxSelectors.selectedOrganization)
    const invitations = useSelector(ReduxSelectors.invitations).filter(invitation => !invitation.acceptedTimestamp) // get only pending invitations
    const user = useSelector(ReduxSelectors.selectedUser)
    const projects = useSelector(ReduxSelectors.organizationProjectAsArray)
    const participants = useSelector(ReduxSelectors.organizationParticipantsAsArray)
    const usersParticipant = user && participants.find(p => p.id === user.id)
    const userIsAdmin = usersParticipant?.organizationRole === 'Admin'
    const canManageParticipants = useSelector(ReduxSelectors.isUpdateAllowed('organization', organization))
    const DEFAULT_TAB = 'members'

    const [showInviteMembersModal, setShowInviteMembersModal] = useState()
    const [showInviteGuestsModal, setShowInviteGuestsModal] = useState(false)
    const [organizationParticipant, setOrganizationParticipant] = useState()
    const [selectedInvitation, setSelectedInvitation] = useState()
    const [showLeaveOrganizationModal, setShowLeaveOrganizationModal] = useState(false)
    const [participantToReInvite, setParticipantToReInvite] = useState()
    const [userToSuspend, setUserToSuspend] = useState(false)
    const [guestToPromote, setGuestToPromote] = useState(false)
    const [userToDowngrade, setUserToDowngrade] = useState(false)
    const [checkedParticipants, setCheckedParticipants] = useState([])
    const [lastClickedCheckboxId, setLastClickedCheckboxId] = useState(false)
    const [activeTab, setActiveTab] = useState(DEFAULT_TAB)
    const [isTogglingProjectsInProgress, setIsTogglingProjectsInProgress] = useState(false)
    const [sortSettings, setSortSettings] = useState(DEFAULT_SORT_SETTINGS)
    const [participantsDropdownFilterValue, setParticipantsDropdownFilterValue] = useState('all')

    const organizations = useSelector(ReduxSelectors.organizationsAsArray)
    const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)
    const { dispatch, getState } = useStore()
    const state = getState()
    const navigate = useNavigate()

    const isInBulkSelectMode = useBulkSelectMode()

    const searchFilterSettings = {
        fields: ['email'],
        joinedFields: [['firstName', 'lastName']],
    }

    const {
        searchValue,
        setSearchValue,
        filteredItems: filteredParticipants,
    } = useSearch({
        ...searchFilterSettings,
        items: participants,
    })

    const { filteredItems: filteredInvitations } = useSearch({
        ...searchFilterSettings,
        fields: ['inviteeEmail'],
        items: invitations,
        value: searchValue,
    })

    // Allow leave organization option only when there is more than one participant
    // and logged user is a collaborator
    // or logged user is not the only admin
    const isLeavingOrgAllowed = useMemo(() => {
        return Boolean(
            participants.length > 1 &&
                (!userIsAdmin ||
                    participants.filter(participant => participant.organizationRole === 'Admin')?.length > 1)
        )
    }, [participants])

    const onLeaveOrganization = async () => {
        const update = Participant.update(usersParticipant, { isSuspended: true })
        await runCommand(OrganizationParticipantRemovedSelfCommand.Outbound(organization.id, update))
        setShowLeaveOrganizationModal(false)
        navigate('/') // navigate to the dashboard
    }

    const updateInvitationForInvitee = ({ inviteeEmail, changes }) => {
        const invitation = invitations.find(i => i.inviteeEmail === inviteeEmail)
        return runCommand(InvitationChangedCommand.Outbound(invitation, invitation.id, changes))
    }

    const onSuspendMember = async () => {
        const update = Participant.update(userToSuspend, { isSuspended: true })
        runCommand(OrganizationParticipantRemovedCommand.Outbound(organization.id, update))
        setUserToSuspend(null)
    }

    const onCancelUserInvite = email => {
        const invitation = invitations.find(i => i.inviteeEmail === email)
        const { id } = invitation
        runCommand(InvitationRemovedCommand.Outbound(invitation, id))
    }

    const onReInviteSuspendedParticipant = ({ organizationRole, projectIds }) => {
        runCommand(
            OrganizationParticipantReInvitedCommand.Outbound(organization.id, {
                userId: participantToReInvite.id,
                userFullName: Participant.fullName(participantToReInvite),
                organizationRole,
                projectIds,
            })
        )

        setParticipantToReInvite(null)
    }

    const handleReInviteSuspendedParticipant = participant => {
        setParticipantToReInvite(participant)
    }

    const handlePromoteGuest = ({ guest, organizationRole }) => {
        let userName

        if (guest.inviteeEmail) {
            updateInvitationForInvitee({ inviteeEmail: guest.inviteeEmail, changes: { organizationRole } })
            userName = guest.inviteeEmail
        } else {
            updateOrganizationParticipantRole({ participant: guest, organizationRole })
            userName = Participant.fullName(guest)
        }

        dispatch(
            ReduxActions.toastAdded({
                id: 'upgrade-user',
                toastLabel: `${userName} successfully upgraded to ${organizationRole}`,
                severity: 'success',
                showUndo: false,
            })
        )

        setGuestToPromote(null)
    }

    const handleDowngradeUser = async ({ user, projectIds }) => {
        const organizationRole = 'Guest'
        let userName

        // if inviteeEmail is present it is just an invitation role to downgrade, not actual participant
        if (user.inviteeEmail) {
            let newInvitationData = { organizationRole }
            if (projectIds) newInvitationData = { ...newInvitationData, projectIds }

            updateInvitationForInvitee({ inviteeEmail: user.inviteeEmail, changes: newInvitationData })

            userName = user.inviteeEmail
        } else {
            await updateOrganizationParticipantRole({ participant: user, organizationRole, projectIds })
            userName = Participant.fullName(user)
        }

        dispatch(
            ReduxActions.toastAdded({
                id: 'downgrade-user',
                toastLabel: `${userName} successfully downgraded to Guest`,
                severity: 'success',
                showUndo: false,
            })
        )

        setUserToDowngrade(null)
    }

    const handleParticipantChecked = participantId => {
        setCheckedParticipants(checkedItemsArray => {
            const _idsToToggle = () => {
                const allParticipantsList = sortedParticipants.active.concat(sortedParticipants.suspended) // merge two participants lists
                const allIdsArray = allParticipantsList.map(obj => obj.id)

                // Get index of most recently checked/unchecked checkbox on the rows list
                const lastClickedItemIndex = allIdsArray.findIndex(id => id === lastClickedCheckboxId)

                // Get index of currently checked/unchecked item on the rows list
                const currentItemIndex = allIdsArray.findIndex(id => id === participantId)

                // Get all items between two points
                if (lastClickedItemIndex > currentItemIndex)
                    return allIdsArray.slice(currentItemIndex, lastClickedItemIndex)

                return allIdsArray.slice(lastClickedItemIndex, currentItemIndex)
            }

            // Check if item isn't already present in checked items.
            // If it isn't, it means user just checked it and we have to add it to checked items.
            const shouldAddItems = checkedItemsArray.indexOf(participantId) === -1

            if (!isInBulkSelectMode && shouldAddItems) return [...checkedItemsArray, participantId] // if we are not in bulk select mode, simply add new item as checked

            if (!isInBulkSelectMode) return without(participantId, checkedItemsArray)

            const idsToToggle = _idsToToggle()

            // Add all idsToToggle to the original checked items
            if (shouldAddItems)
                return uniq([...checkedItemsArray, ...idsToToggle, participantId, lastClickedCheckboxId])

            // uncheck all items in between last clicked checkbox and the checkbox that was clicked right now
            return without([...idsToToggle, lastClickedCheckboxId], checkedItemsArray)
        })
        setLastClickedCheckboxId(participantId)
    }

    const handleCheckAllItems = () => {
        if (areAllParticipantsChecked) {
            setCheckedParticipants([])
        } else if (activeTab === 'members') {
            setCheckedParticipants(sortedParticipants.active.map(item => item.id))
        } else if (activeTab === 'suspended') {
            setCheckedParticipants(sortedParticipants.suspended.map(item => item.id))
        }
    }

    const handleTabChange = value => {
        setActiveTab(value)
        setCheckedParticipants([])
    }

    const _turnOnSomeProjects = (userCurrentProjectsIds, allProjects, onProjectsToggled) => {
        const projectsToEnable = allProjects
            .filter(project => !userCurrentProjectsIds.includes(project.id))
            .map(project => project.id)
        return onProjectsToggled(projectsToEnable, true)
    }

    const _turnOnAllProjects = (allProjects, onProjectsToggled) => {
        const projectsToEnable = allProjects.map(project => project.id)
        return onProjectsToggled(projectsToEnable, true)
    }

    const _turnOffAllProjects = (allProjects, onProjectsToggled) => {
        const projectsToEnable = allProjects.map(project => project.id)
        return onProjectsToggled(projectsToEnable, false) // false = not allow these projects
    }

    const handleToggleAllProjects = async (isOn, allProjects, userCurrentProjectsIds, onProjectsToggled) => {
        setIsTogglingProjectsInProgress(true)

        // user has some projects assigned, toggle on only remaining ones
        if (isOn && Object.keys(userCurrentProjectsIds).length) {
            await _turnOnSomeProjects(userCurrentProjectsIds, allProjects, onProjectsToggled)
        }
        // user has no projects assigned, toggle on all
        else if (isOn) {
            await _turnOnAllProjects(allProjects, onProjectsToggled)
        }
        // should suspend from all projects
        else {
            await _turnOffAllProjects(allProjects, onProjectsToggled)
        }

        setIsTogglingProjectsInProgress(false)
    }

    const handleToggleAllInviteeProjects = async (isOn, allProjects) => {
        setIsTogglingProjectsInProgress(true)

        let newProjectIds = [] // if toggle was off, we just remove access to all projects
        // if toggle was on we grant access to all projects
        if (isOn) {
            newProjectIds = allProjects.map(project => project.id)
        }

        await updateInvitationForInvitee({
            inviteeEmail: selectedInvitation.inviteeEmail,
            changes: {
                projectIds: newProjectIds,
            },
        })

        setIsTogglingProjectsInProgress(false)
    }

    const onSortColumnClick = ({ type, isAscending }) => {
        if (isAscending === undefined) setSortSettings({ field: type, isAscending: true })
        else if (isAscending) setSortSettings({ field: type, isAscending: false })
        else setSortSettings(DEFAULT_SORT_SETTINGS)
    }

    // Check if any invitation is for already existing, but previously suspended participant
    // and enrich the invitation with that participant firstName and lastName.
    const enrichInvitationsWithUserName = invitations => {
        return invitations?.map(invitation => {
            const participant =
                !invitation.acceptedTimestamp && invitation.userId
                    ? participants.find(participant => participant.id === invitation.userId)
                    : {}

            const { firstName, lastName } = participant || {}
            return { ...invitation, firstName, lastName }
        })
    }

    const enrichedInvitations = useMemo(
        () => enrichInvitationsWithUserName(filteredInvitations),
        [filteredInvitations, participants]
    )

    const sortedInvitations = useMemo(() => {
        if (sortSettings.field === SORT_TYPES.name)
            return enrichedInvitations.sort((a, b) => sortByNameOrEmail(a, b, sortSettings.isAscending))

        if (sortSettings.field === SORT_TYPES.projectsCount) {
            return enrichedInvitations.sort((a, b) => sortInviteesByProjectsCount(a, b, sortSettings.isAscending))
        }

        return enrichedInvitations
    }, [enrichedInvitations, sortSettings])

    const categorizeParticipant = (participant, accumulator) => {
        const projects = ReduxSelectors.currentProjectsForParticipant(state, participant)
        const participantProjectCount = Object.keys(projects).length
        const participantIsMe = participant.id === user.id

        // We want to display participant as suspended if:
        // a) he was suspended from the organization or
        // b) if the participant is viewed by non admin user
        //      and participant is not an admin
        //      and he was suspended from all the projects that currently logged in user has access to.

        // I can only see this participant on the list as active if:
        // - I am an admin
        // - that user is admin
        // - or this participant is me
        // - or that participant has any project assigned
        const canISeeThisParticipant =
            canManageParticipants ||
            participant.organizationRole === 'Admin' ||
            participantIsMe ||
            participantProjectCount
        const hasPendingReInvite =
            participant.isSuspended &&
            invitations.find(invitation => !invitation.acceptedTimestamp && invitation.userId === participant.id)
        const shouldShowAsSuspended = (participant.isSuspended || !canISeeThisParticipant) && !hasPendingReInvite
        const isGuest = hasGuestRole(participant)

        if (shouldShowAsSuspended) {
            accumulator.suspended.push(participant)
        } else if (isGuest && hasPendingReInvite) {
            accumulator.reinvitedGuests.push(participant)
        } else if (hasPendingReInvite) {
            accumulator.reinvited.push(participant)
        } else if (isGuest) {
            accumulator.activeGuests.push(participant)
        } else {
            accumulator.active.push(participant)
        }
    }

    // Sort participants into 3 groups:
    // - suspended participants
    // - reinvited participants - we don't display them, instead we display the invitation
    // - active participants
    const categorizedParticipants = useMemo(() => {
        return filteredParticipants.reduce(
            (acc, option) => {
                categorizeParticipant(option, acc)
                return acc
            },
            { active: [], suspended: [], reinvited: [], activeGuests: [], reinvitedGuests: [] }
        )
    }, [filteredParticipants, invitations, userIsAdmin])

    const sortParticipantsWithinCategory = items =>
        mapObject((category, key) => {
            let joinedFields

            // If the sorting field is name, we have to compare both firstName and lastName
            if (sortSettings.field === SORT_TYPES.name) {
                joinedFields = ['firstName', 'lastName']
                return sortByField({ items: category, sortSettings: { ...sortSettings, joinedFields } })
            }

            if (sortSettings.field === SORT_TYPES.projectsCount) {
                return category.sort((a, b) => sortParticipantsByProjectsCount(a, b, state, sortSettings.isAscending))
            }
            return category
        }, items)

    // Sort participants based on sort settings
    const sortedParticipants = useMemo(() => {
        return sortParticipantsWithinCategory(categorizedParticipants)
    }, [categorizedParticipants, sortSettings])

    // Consider only participants visible to logged in user, without suspended ones and guests
    const activeParticipantsCount = useMemo(() => {
        if (!sortedParticipants.active) return 0
        return sortedParticipants.active.length || 0
    }, [sortedParticipants])

    // Consider only guests visible to logged in user, without suspended ones and regular participants
    const activeGuestsParticipantsCount = useMemo(() => {
        if (!sortedParticipants.activeGuests) return 0
        return sortedParticipants.activeGuests.length || 0
    }, [sortedParticipants])

    const areAllParticipantsChecked = useMemo(() => {
        if (!filteredParticipants.length) return false // when no items displayed

        if (activeTab === 'members') {
            if (!sortedParticipants.active?.length) return false
            return sortedParticipants.active?.length === checkedParticipants.length
        } else if (activeTab === 'suspended') {
            if (!sortedParticipants.suspended?.length) return false
            return sortedParticipants.suspended?.length === checkedParticipants.length
        } else if (activeTab === 'guests') {
            if (!sortedParticipants.activeGuests?.length) return false
            return sortedParticipants.activeGuests?.length === checkedParticipants.length
        }

        return false
    }, [filteredParticipants, activeTab, sortedParticipants, checkedParticipants])

    if (organizationParticipant) {
        const currentProjectsIds = Object.keys(
            ReduxSelectors.currentProjectsForParticipant(state, organizationParticipant)
        )
        return (
            <AdminViewManageProjects
                participantShape={ParticipantShape.fromParticipant()(organizationParticipant)}
                allProjects={projects}
                currentProjectsIds={currentProjectsIds}
                isTogglingProjectsInProgress={isTogglingProjectsInProgress}
                onProjectsToggled={onParticipantProjectsToggled}
                onClose={() => setOrganizationParticipant(false)}
                onToggleAllProjects={handleToggleAllProjects}
            />
        )
    }

    if (selectedInvitation) {
        const invitation = invitations.find(i => i.inviteeEmail === selectedInvitation.inviteeEmail)
        return (
            <AdminViewManageProjects
                invitation={invitation}
                allProjects={projects}
                currentProjectsIds={invitation.projectIds}
                isTogglingProjectsInProgress={isTogglingProjectsInProgress}
                onProjectsToggled={onInviteeProjectsToggled}
                onClose={() => setSelectedInvitation(false)}
                onToggleAllProjects={handleToggleAllInviteeProjects}
            />
        )
    }

    Segment.sendPage('organization members', '', {})

    return (
        <Flex css={{ w: '100vw', h: '100vh', bg: '$neutral10' }}>
            <AdminOrganizationsPanel
                relativePath="members"
                organizations={organizations}
                selectedOrganizationId={selectedOrganization?.id}
            />
            {showInviteMembersModal && (
                <InviteOrganizationMembersModal
                    isTogglingProjectsInProgress={isTogglingProjectsInProgress}
                    preexistingEmails={pluck('email', participants).concat(pluck('inviteeEmail', invitations))}
                    onSend={sendInvitations}
                    onCancel={hideMembersModal}
                    onToggleAllProjects={handleToggleAllProjects}
                />
            )}
            {showInviteGuestsModal && (
                <InviteOrganizationMembersModal
                    preexistingEmails={pluck('email', participants).concat(pluck('inviteeEmail', invitations))}
                    variant="guests"
                    onSend={sendInvitations}
                    onCancel={() => setShowInviteGuestsModal(false)}
                />
            )}
            {showLeaveOrganizationModal && (
                <LeaveOrganizationModal
                    onCancel={() => setShowLeaveOrganizationModal(false)}
                    onSubmit={onLeaveOrganization}
                />
            )}
            {userToSuspend && (
                <SuspendMemberModal
                    onCancel={() => setUserToSuspend(null)}
                    submitButtonCopy={hasGuestRole(userToSuspend) ? 'Suspend Guest' : 'Suspend Member'}
                    onSubmit={onSuspendMember}
                    user={userToSuspend}
                />
            )}
            {guestToPromote && (
                <PromoteGuestModal
                    guest={guestToPromote}
                    onCancel={() => setGuestToPromote(null)}
                    onSend={handlePromoteGuest}
                />
            )}
            {userToDowngrade && (
                <DowngradeUserModal
                    user={userToDowngrade}
                    onCancel={() => setUserToDowngrade(null)}
                    onSend={handleDowngradeUser}
                />
            )}

            {participantToReInvite && (
                <InviteOrganizationMembersModal
                    variant="re-invite"
                    onCancel={() => setParticipantToReInvite(null)}
                    onSend={onReInviteSuspendedParticipant}
                />
            )}
            <AdminNavigationPanel selectedPanel={AdminNavigationType.MEMBERS} />

            <Box css={{ flexGrow: 3, overflow: 'hidden' }}>
                <Flex
                    direction="column"
                    css={{ height: '100%', p: '64px 42px 42px 42px', gap: 24, justifyContent: 'flex-start' }}
                >
                    <Text css={{ fs: '32px', fw: '700', fg: '$neutral04', lh: '40px' }}>Organization Members</Text>

                    <Tabs.Root
                        defaultValue={DEFAULT_TAB}
                        value={activeTab}
                        css={{ height: '100%', overflow: 'hidden' }}
                        onValueChange={handleTabChange}
                    >
                        <Tabs.List>
                            <Tabs.Trigger value="members">
                                <Text>Members ({activeParticipantsCount})</Text>
                            </Tabs.Trigger>
                            <Tabs.Trigger value="guests">
                                <Text>Guests ({activeGuestsParticipantsCount})</Text>
                            </Tabs.Trigger>
                            <Tabs.Trigger value="suspended">
                                <Text>Suspended Users ({sortedParticipants.suspended?.length})</Text>
                            </Tabs.Trigger>
                        </Tabs.List>
                        <Tabs.Content value="members" css={{ overflow: 'hidden', display: 'flex' }}>
                            <MembersTable
                                areAllParticipantsChecked={areAllParticipantsChecked}
                                canManageParticipants={canManageParticipants}
                                checkedParticipants={checkedParticipants}
                                dropdownFilterOptions={PARTICIPANTS_DROPDOWN_FILTER_OPTIONS}
                                isLeavingOrgAllowed={isLeavingOrgAllowed}
                                participantsDropdownFilterValue={participantsDropdownFilterValue}
                                projects={projects}
                                searchValue={searchValue}
                                showMembersModal={showMembersModal}
                                sortedInvitations={sortedInvitations}
                                participants={sortedParticipants.active}
                                sortSettings={sortSettings}
                                state={state}
                                user={user}
                                handleCheckAllItems={handleCheckAllItems}
                                handleParticipantChecked={handleParticipantChecked}
                                handlePermissionDropdownOptionChange={handlePermissionDropdownOptionChange}
                                onSortColumnClick={onSortColumnClick}
                                setParticipantsDropdownFilterValue={setParticipantsDropdownFilterValue}
                                resendInvitation={resendInvitation}
                                setOrganizationParticipant={setOrganizationParticipant}
                                setSearchValue={setSearchValue}
                                setSelectedInvitation={setSelectedInvitation}
                            />
                        </Tabs.Content>
                        <Tabs.Content value="guests" css={{ overflow: 'hidden', display: 'flex' }}>
                            <GuestsTable
                                areAllParticipantsChecked={areAllParticipantsChecked}
                                canManageParticipants={canManageParticipants}
                                checkedParticipants={checkedParticipants}
                                dropdownFilterOptions={GUESTS_DROPDOWN_FILTER_OPTIONS}
                                isLeavingOrgAllowed={isLeavingOrgAllowed}
                                participantsDropdownFilterValue={participantsDropdownFilterValue}
                                searchValue={searchValue}
                                invitations={sortedInvitations}
                                guests={sortedParticipants.activeGuests}
                                sortSettings={sortSettings}
                                state={state}
                                user={user}
                                handleCheckAllItems={handleCheckAllItems}
                                handleParticipantChecked={handleParticipantChecked}
                                handlePermissionDropdownOptionChange={handlePermissionDropdownOptionChange}
                                onShowInviteGuestsModal={() => setShowInviteGuestsModal(true)}
                                onSortColumnClick={onSortColumnClick}
                                setParticipantsDropdownFilterValue={setParticipantsDropdownFilterValue}
                                setOrganizationParticipant={setOrganizationParticipant}
                                setSearchValue={setSearchValue}
                                setSelectedInvitation={setSelectedInvitation}
                            />
                        </Tabs.Content>
                        <Tabs.Content value="suspended" css={{ overflow: 'hidden', display: 'flex' }}>
                            <SuspendedMembersTable
                                areAllParticipantsChecked={areAllParticipantsChecked}
                                canManageParticipants={canManageParticipants}
                                checkedParticipants={checkedParticipants}
                                searchValue={searchValue}
                                sortedParticipants={sortedParticipants}
                                sortSettings={sortSettings}
                                handleCheckAllItems={handleCheckAllItems}
                                handleParticipantChecked={handleParticipantChecked}
                                handleReInviteSuspendedParticipant={handleReInviteSuspendedParticipant}
                                onSortColumnClick={onSortColumnClick}
                                setSearchValue={setSearchValue}
                            />
                        </Tabs.Content>
                    </Tabs.Root>
                </Flex>
            </Box>
        </Flex>
    )
}

export { InviteOrganizationMembersModal }
export default AdminViewMembers
