import { Comment, FeedItem } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import { itemFromFeedItem } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'
import { createComment } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// CommentAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const CommentAddedCommand = taggedSum('CommentAddedCommand', {
    Inbound: { feedItem: 'FeedItem' },
    Outbound: { comment: 'Comment' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A CommentAddedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Comment)
 */
const runInboundCommand = async (resources, command) => {
    const comment = await itemFromFeedItem(Comment, 'Comment', 'added', command.feedItem)
    if (!comment) return

    const { dispatch } = resources
    dispatch(ReduxActions.commentAdded(comment))
}

/*
 * A CommentAddedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { comment } = command
    const { id, parentType, parentId, text, isNote } = comment
    const { projectId, dispatch, displayError } = resources

    try {
        await createComment({
            projectId,
            commentId: id,
            parentType,
            parentId,
            text,
            isNote,
        })
        dispatch(ReduxActions.commentAdded(comment))
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CommentAddedCommand,
        CommandPlayer({
            CommandType: CommentAddedCommand,
            Type: FeedItem,
            collectionPath: (projectId, userId) => `/projects/${projectId}/participants/${userId}/feedItems`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
        })
    )
}

export { CommentAddedCommand, addCommand }
