/*
 * Define filter settings for the Navigator panel on the main left panel
 * There are about a dozen different filter settings the user can set
 * There is also a "default" value for all the settings used to control:
 *
 * - the "reset" button
 * - the filter button
 * - the filter banner
 *
 * There are a slew of functions to update the settings of the form: (oldSettings, newSetting) => newSettings
 */

import { equals, LookupTable, omit, tagged, without } from '@range.io/functional'
import { ParticipantShape } from 'range-editor/src/react-shapes/participant-shape.js'

const NavigatorFilterSettings = tagged('NavigatorFilterSettings', {
    showPhotoPins: 'Boolean',
    showTaskPins: 'Boolean',
    showOverdueTasksOnly: 'Boolean',
    showOnlyMyPins: 'Boolean',
    showArchivedPins: 'Boolean',
    showStatuses: '[StatusShape]',
    shouldFilterByTags: 'Boolean',
    shouldFilterByAssignee: 'Boolean',
    shouldFilterByIdentifiers: 'Boolean',
    shouldFilterByFollower: 'Boolean',

    shouldFilterByDateCreated: 'Boolean',
    dateCreatedDayRange: 'Number?',

    shouldFilterByDateUpdated: 'Boolean',
    dateUpdatedDayRange: 'Number?',

    matchUntagged: 'Boolean',
    tags: '[TagShape]',

    selectedIdentifiers: '[String]',

    matchUnassigned: 'Boolean',
    allUsers: '[ParticipantShape]', // LookupTable actually, not array
    assignees: '[ParticipantShape]', // LookupTable actually, not array
    followers: '[ParticipantShape]', // LookupTable actually, not array
})

// can't be usefully set until the project data is loaded
const defaults = (statusShapes, participantShapes) => ({
    showPhotoPins: true,
    showTaskPins: true,
    showOverdueTasksOnly: false,
    showOnlyMyPins: false,
    showArchivedPins: false,
    showStatuses: statusShapes,
    shouldFilterByTags: false,
    shouldFilterByAssignee: false,
    shouldFilterByIdentifiers: false,
    shouldFilterByFollower: false,
    shouldFilterByDateCreated: false,
    dateCreatedDayRange: undefined,
    shouldFilterByDateUpdated: false,
    dateUpdatedDayRange: undefined,
    matchUntagged: false,
    matchUnassigned: false,
    tags: [],
    selectedIdentifiers: [],

    allUsers: LookupTable(participantShapes, 'id'),
    assignees: LookupTable([], 'id'),
    followers: LookupTable([], 'id'),
})

// Abbreviation
const from = NavigatorFilterSettings.from

// defaults
const defaultFilterSettings = (statusShapes, participantShapes) => from(defaults(statusShapes, participantShapes))

/*
 * Are the current filter settings the same as the default filter settings?
 * As a special case, if shouldFilterByTags, shouldFilterByAssignee or shouldFilterByDateCreated ARE true, but no tags or assignees
 * have been selected yet, consider this a match
 */
const isSetToDefaults = (navigatorFilterSettings, statusShapes, participantShapes) => {
    // We only want to compare applied statuses ids
    const statusesToIdsMap = statuses => statuses?.map(status => status.id).sort()

    const keysToOmit = [
        'shouldFilterByTags',
        'shouldFilterByAssignee',
        'shouldFilterByDateCreated',
        'shouldFilterByDateUpdated',
        'shouldFilterByIdentifiers',
        'allUsers',
        'shouldFilterByFollower',
    ]

    let currentSettings = omit(keysToOmit, navigatorFilterSettings)
    currentSettings = {
        ...currentSettings,
        showStatuses: statusesToIdsMap(currentSettings.showStatuses),
    }

    let defaultSettings = omit(keysToOmit, defaultFilterSettings(statusShapes, participantShapes))
    defaultSettings = {
        ...defaultSettings,
        showStatuses: statusesToIdsMap(defaultSettings.showStatuses),
    }

    return equals(currentSettings, defaultSettings)
}

// simple toggles
const setShowTaskPins = (nfs, b) => from({ ...nfs, showTaskPins: b })
const setShowOverdueTasksOnly = (nfs, b) => from({ ...nfs, showOverdueTasksOnly: b })
const setShowPhotoPins = (nfs, b) => from({ ...nfs, showPhotoPins: b })
const setShowOnlyMyPins = (nfs, b) => from({ ...nfs, showOnlyMyPins: b })
const setShowArchivedPins = (nfs, b) => from({ ...nfs, showArchivedPins: b })
const setShouldFilterByAssignee = (nfs, b) => from({ ...nfs, shouldFilterByAssignee: b })
const setShouldFilterByIdentifiers = (nfs, b) => from({ ...nfs, shouldFilterByIdentifiers: b })
const setShouldFilterByFollower = (nfs, b) => from({ ...nfs, shouldFilterByFollower: b })
const setShouldFilterByTags = (nfs, b) => from({ ...nfs, shouldFilterByTags: b })
const setShouldFilterByDateCreated = (nfs, b) => from({ ...nfs, shouldFilterByDateCreated: b })
const setDateCreatedDayRange = (nfs, b) => from({ ...nfs, dateCreatedDayRange: b })
const setShouldFilterByDateUpdated = (nfs, b) => from({ ...nfs, shouldFilterByDateUpdated: b })
const setDateUpdatedDayRange = (nfs, b) => from({ ...nfs, dateUpdatedDayRange: b })

// ---------------------------------------------------------------------------------------------------------------------
// Handle status toggles
// ---------------------------------------------------------------------------------------------------------------------

// This is tricky only because the Statuses and nominally part of the project data, and not predefined
const setStatus = (nfs, statusShape, checked) => {
    const newStatuses = checked ? nfs.showStatuses.concat(statusShape) : without(statusShape, nfs.showStatuses)
    return from({ ...nfs, showStatuses: newStatuses })
}

// ---------------------------------------------------------------------------------------------------------------------
// Handle assignee filters
// ---------------------------------------------------------------------------------------------------------------------

// The singleton "unassigned tasks" ParticipantShape
const unassignedTasksShape = ParticipantShape(
    '4db05060-896f-4481-ae74-11190f85700b',
    '',
    null,
    'Unassigned Tasks',
    false
)

const dateRangeOptions = [
    { name: 'Last day', dayValue: 1 },
    { name: 'Last 5 days', dayValue: 5 },
    { name: 'Last 7 days', dayValue: 7 },
    { name: 'Last 14 days', dayValue: 14 },
    { name: 'Last 30 days', dayValue: 30 },
]

// The user turned the checkbox OFF for the given participantShape; remove it from the assignees and reset matchUnassigned
const unselectAssignee = (nfs, participantShape) =>
    from({ ...nfs, assignees: nfs.assignees.removeItem(participantShape), matchUnassigned: false })

/*
 * The user toggled the checkbox for the given participantShape. There are 3 cases
 *
 *                                  side effect
 * - unassigned went on             turn OFF all "real" assignees; set matchUnassigned
 * - unassigned went off            turn OFF "unassigned"; reset matchUnassigned
 * - some other assignee changed    toggle assignee; reset matchUnassigned
 */
const toggleAssignee = (nfs, participantShape) => {
    // set assignees to ["unassigned"]; set matchUnassigned
    const turnOnUnassigned = () =>
        from({ ...nfs, assignees: LookupTable([participantShape], 'id'), matchUnassigned: true })

    // set assignees to [] and reset matchUnassigned
    const turnOffUnassigned = () => from({ ...nfs, assignees: LookupTable([], 'id'), matchUnassigned: false })

    // toggle the specific assignee; turn OFF "unassigned"; reset matchUnassigned
    const toggleAssignee = () => {
        let assignees = nfs.assignees.toggleItem(participantShape)
        assignees = assignees.removeItem(unassignedTasksShape)

        return from({ ...nfs, assignees, matchUnassigned: false })
    }

    if (participantShape === unassignedTasksShape && !nfs.matchUnassigned) return turnOnUnassigned()
    if (participantShape === unassignedTasksShape && nfs.matchUnassigned) return turnOffUnassigned()
    return toggleAssignee()
}

const toggleIdentifier = (nfs, identifier) => {
    const identifiers = nfs.selectedIdentifiers
    const newIdentifiers =
        identifiers.indexOf(identifier) === -1 ? identifiers.concat(identifier) : without(identifier, identifiers)

    return from({ ...nfs, selectedIdentifiers: newIdentifiers })
}

const unselectIdentifier = (nfs, identifier) => {
    const newIdentifiers = without(identifier, nfs.selectedIdentifiers)

    return from({ ...nfs, selectedIdentifiers: newIdentifiers })
}

const unselectFollower = (nfs, participantShape) =>
    from({ ...nfs, followers: nfs.followers.removeItem(participantShape) })

const toggleFollower = (nfs, participantShape) => {
    const newFollowers = nfs.followers.toggleItem(participantShape)
    return from({ ...nfs, followers: newFollowers })
}

// ---------------------------------------------------------------------------------------------------------------------
// Handle tag toggles
// ---------------------------------------------------------------------------------------------------------------------

const toggleMatchUntagged = nfs => from({ ...nfs, tags: [], matchUntagged: !nfs.matchUntagged })
const setTagShapes = (nfs, tags) => from({ ...nfs, tags, matchUntagged: false })

// ---------------------------------------------------------------------------------------------------------------------
// public API
// ---------------------------------------------------------------------------------------------------------------------

// defaults
NavigatorFilterSettings.defaultFilterSettings = defaultFilterSettings
NavigatorFilterSettings.isSetToDefaults = isSetToDefaults

// simple toggles
NavigatorFilterSettings.setShowTaskPins = setShowTaskPins
NavigatorFilterSettings.setShowOverdueTasksOnly = setShowOverdueTasksOnly
NavigatorFilterSettings.setShowPhotoPins = setShowPhotoPins
NavigatorFilterSettings.setShowOnlyMyPins = setShowOnlyMyPins
NavigatorFilterSettings.setShowArchivedPins = setShowArchivedPins
NavigatorFilterSettings.setShouldFilterByAssignee = setShouldFilterByAssignee
NavigatorFilterSettings.setShouldFilterByIdentifiers = setShouldFilterByIdentifiers
NavigatorFilterSettings.setShouldFilterByFollower = setShouldFilterByFollower
NavigatorFilterSettings.setShouldFilterByTags = setShouldFilterByTags
NavigatorFilterSettings.setShouldFilterByDateCreated = setShouldFilterByDateCreated
NavigatorFilterSettings.setDateCreatedDayRange = setDateCreatedDayRange
NavigatorFilterSettings.setShouldFilterByDateUpdated = setShouldFilterByDateUpdated
NavigatorFilterSettings.setDateUpdatedDayRange = setDateUpdatedDayRange

// status
NavigatorFilterSettings.setStatus = setStatus

// assignees
NavigatorFilterSettings.unselectAssignee = unselectAssignee
NavigatorFilterSettings.toggleAssignee = toggleAssignee

// followers
NavigatorFilterSettings.unselectFollower = unselectFollower
NavigatorFilterSettings.toggleFollower = toggleFollower

// tags
NavigatorFilterSettings.setTagShapes = setTagShapes
NavigatorFilterSettings.toggleMatchUntagged = toggleMatchUntagged

// identifiers
NavigatorFilterSettings.toggleIdentifier = toggleIdentifier
NavigatorFilterSettings.unselectIdentifier = unselectIdentifier

NavigatorFilterSettings.unassignedTasksShape = unassignedTasksShape
NavigatorFilterSettings.dateRangeOptions = dateRangeOptions

export default NavigatorFilterSettings
