import { useEffect } from 'react'

/*
 * Given a ref to a DOM element and a function, call the function when the user clicks outside
 * of the area of that ref's element.
 * If you have multiple elements to check against, pass array of refs.
 */
const useOnClickOutside = (ref, handler) => {
    useEffect(
        () => {
            const listener = event => {
                const isEventWithinElement = (event, ref) => {
                    if (!ref.current) return false // check if element exists, maybe its not yet visible on the screen
                    if (ref.current.contains(event.target)) return true
                    return false
                }

                // Do nothing (just return) if clicking ref's element or descendent elements.
                // If you pass multiple refs to check against
                if (Array.isArray(ref)) {
                    // then check if the event was in at least one the refs or its children
                    if (ref.some(el => isEventWithinElement(event, el))) return
                } else {
                    // assume it is just a single ref to check the event against
                    if (isEventWithinElement(event, ref)) return
                }

                handler(event)
            }
            document.addEventListener('mousedown', listener)
            document.addEventListener('touchstart', listener)
            return () => {
                document.removeEventListener('mousedown', listener)
                document.removeEventListener('touchstart', listener)
            }
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    )
}

export default useOnClickOutside
