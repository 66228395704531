/*
 * Create Account Form
 */

import { Organization, User } from '@range.io/basic-types'
import { REGEX } from '@range.io/basic-types/src/string-types.js'
import { equals } from '@range.io/functional'
import React, { useState } from 'react'
import { useStore } from 'react-redux'
import { ReduxActions } from '../redux/index.js'
import { Banner, Button, FlexColumn, FlexRow, Logo, LogoText, Text } from '../components-reusable/index.js'
import { attemptSignOut } from '../firebase/authentication-helpers.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { UserChangedCommand } from '../firebase/commands/user-changed-command.js'
import { SelectRole } from './pages/SharedComponents.js'
import {
    BGLogo,
    ButtonLayout,
    InputFlex,
    InputLabel,
    LabelLayout,
    LinkTextCTA,
    SecondaryLabel,
    StyledForm,
    StyledLogo,
    StyledPage,
    StyledTextInput,
    ScrollableContainer,
} from './RegistrationSharedStyles.js'

const userRoleOptions = [{ id: 'Please select one...', name: 'Please select one...' }].concat(User.roles)
const industryOptions = [{ id: 'Please select one...', name: 'Please select one...' }].concat(Organization.industries)

/*
 * Create Account Page
 */

const CreateAccountForm = ({ user, onSignOut, onSubmit }) => {
    const hasAllValues = () => firstName && lastName && role
    const { dispatch } = useStore()

    const _onSubmit = e => {
        e.preventDefault()
        const roleOption = userRoleOptions.find(r => r.id === role)
        onSubmit(firstName, lastName, roleOption.name)
        dispatch(ReduxActions.setShowAccountCreatedToast(true))
    }

    const setFirstName = s => {
        const e = ['', errors[1], errors[2]]

        if (s === '') e[0] = 'First Name is required'
        if (!s.match(REGEX.nameRegex)) e[0] = 'First Name is not valid'

        setErrors(e)
        _setFirstName(s)
    }

    const setLastName = s => {
        const e = [errors[0], '', errors[2]]

        if (s === '') e[1] = 'Last Name is required'
        if (!s.match(REGEX.nameRegex)) e[1] = 'Last Name is not valid'

        setErrors(e)
        _setLastName(s)
    }

    const setRole = id => {
        setErrors([errors[0], errors[1], id === userRoleOptions[0].id ? 'Role is required' : ''])
        _setRole(id)
    }

    const [firstName, _setFirstName] = useState('')
    const [lastName, _setLastName] = useState('')
    const [role, _setRole] = useState(userRoleOptions[0].id)
    const [errors, setErrors] = useState(['', '', ''])

    const hasErrors = !equals(errors, ['', '', ''])
    const disabled = hasErrors || !hasAllValues()

    return (
        <StyledForm onSubmit={_onSubmit}>
            <ScrollableContainer>
                <StyledLogo>
                    <Logo />
                    <LogoText />
                </StyledLogo>
                <LabelLayout>
                    <FlexColumn
                        css={{
                            color: '$neutral04',
                            overflow: 'hidden',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        <SecondaryLabel css={{ color: '$neutral05' }}>Logged in as</SecondaryLabel>
                        <SecondaryLabel
                            css={{
                                color: '$neutral04',
                                fs: 18,
                                mb: 12,
                                fw: 500,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                            }}
                        >
                            {user.email}
                        </SecondaryLabel>
                    </FlexColumn>
                    <SecondaryLabel>
                        You're almost there. Finish creating your Range account by completing the information below.
                    </SecondaryLabel>
                </LabelLayout>
                {errors.join('') && <Banner toastLabel={errors.join(' ')} severity="error"></Banner>}
                <InputFlex>
                    <InputLabel>First Name</InputLabel>
                    <StyledTextInput
                        placeholder="Tony"
                        value={firstName}
                        onChange={setFirstName}
                        onBlur={setFirstName}
                    />
                </InputFlex>
                <InputFlex>
                    <InputLabel>Last Name</InputLabel>
                    <StyledTextInput placeholder="Stark" value={lastName} onChange={setLastName} onBlur={setLastName} />
                </InputFlex>
                <InputFlex>
                    <InputLabel>Job Title</InputLabel>
                    <FlexRow css={{ gap: '4px', height: '40px' }}>
                        <SelectRole options={userRoleOptions} value={role} onValueChange={setRole} onBlur={setRole} />
                    </FlexRow>
                </InputFlex>
                <ButtonLayout>
                    <Button variant="primary" disabled={disabled} type="submit" size="lg" css={{ w: '100%' }}>
                        <Text>Create Account</Text>
                    </Button>
                    <LinkTextCTA onClick={onSignOut}>Log out</LinkTextCTA>
                </ButtonLayout>
            </ScrollableContainer>
        </StyledForm>
    )
}

/*
 * Create Account Page
 */

const CreateAccountDumb = ({ user, onSignOut, onSubmit }) => {
    return (
        <StyledPage>
            <BGLogo>
                <img src="/rangebackgroundicon.svg" icon="Range icon" />
            </BGLogo>
            <CreateAccountForm onSignOut={onSignOut} onSubmit={onSubmit} user={user} />
        </StyledPage>
    )
}

const CreateAccount = ({ user }) => {
    const onSubmit = (firstName, lastName, role) =>
        runCommand(UserChangedCommand.Outbound(user.id, { firstName, lastName, role }, true))

    const { runCommand } = useCommandHistory()
    return <CreateAccountDumb onSubmit={onSubmit} onSignOut={attemptSignOut} user={user} />
}

export { CreateAccount, CreateAccountDumb, industryOptions, userRoleOptions }
