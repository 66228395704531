/*
 * Filter the CollaborationShapes that meet the navigatorFilterSettings. This is ALL the CollaborationShapes
 * that meet the filter criteria, not just the ones on the selected canvas.
 * @sig _filteredCollaborations :: State -> [CollaborationShape]
 * memoized as filteredCollaborations below
 */
import { arrayToLookupTable, byFieldStringComparator, memoizeReduxState, pluck } from '@range.io/functional'
import { differenceInCalendarDays } from 'date-fns'
import ReduxSelectors from './redux-selectors.js'

const _filteredCollaborations = state => {
    const sort = collaborationShapes => {
        if (sortOrder === 'recent') return collaborationShapes.sort((a, b) => b.lastUpdateDate - a.lastUpdateDate)
        if (sortOrder === 'alphabetical') return collaborationShapes.sort(byFieldStringComparator('name', false, true))

        throw new Error("Don't understand sortOrder")
    }

    // either it's not a task, or it matches one of the statuses selected by the user
    const filterStatuses = collaborationShape =>
        !collaborationShape.status || showStatuses.includes(collaborationShape.status)

    const uniq = items => [...new Set(items)] // convert to Set and back to Array

    // Does this Collaboration have ALL selected tags?
    // :: CollaborationShape -> Boolean
    const filterByTags = collaborationShape => {
        // user has the master switch off; allow all collaborations through
        if (!shouldFilterByTags) return true

        const uploadTags = collaborationShape.uploads.flatMap(u => u.tags)
        const collaborationPlusUploadTags = uniq(collaborationShape.tags.concat(uploadTags))

        if (matchUntagged) return collaborationPlusUploadTags.length === 0 // matchUntagged inverts the logic
        if (tags.length === 0) return true // the user hasn't yet selected any tags to filter on: all pass

        // use the tag ids, not the tags to compare to avoid issues with out-of-date TagShapes
        // collaboration or some upload has ANY of required tags
        const collaborationPlusUploadTagIds = new Set(pluck('id', collaborationPlusUploadTags))
        const userSelectedTagIds = new Set(pluck('id', tags))
        return userSelectedTagIds.intersection(collaborationPlusUploadTagIds).size > 0
    }

    // Does this Collaboration have ANY of the selected assignees?
    // :: CollaborationShape -> Boolean
    const filterByAssignee = collaborationShape => {
        // user has the master switch off; allow all collaborations through
        if (!shouldFilterByAssignee) return true

        if (matchUnassigned) return collaborationShape.assignee === undefined // matchUnassigned inverts the logic
        if (assignees.length === 0) return true // the user hasn't yet selected any assignees to filter on: all pass
        return assignees.some(a => a === collaborationShape.assignee) // has ANY required assignee
    }

    // Does this Collaboration is followed by ANY of the selected followers?
    // :: CollaborationShape -> Boolean
    const filterByFollower = collaborationShape => {
        // user has the master switch off; allow all collaborations through
        if (!shouldFilterByFollower) return true

        if (followers.length === 0) return true // the user hasn't yet selected any followers to filter on: all pass
        return followers.some(a => collaborationShape.followers.includes(a.id)) // has ANY required follower
    }

    const isOverdue = task => task.dueDate < new Date() && !task.isCompleted

    const filterByDayRange = (date, maxDayDifference) => {
        const daysDiff = differenceInCalendarDays(new Date(), date)
        return daysDiff <= maxDayDifference
    }

    // main -------------------------
    const selectedCanvas = state.canvases[state.selectedCanvas]
    if (!selectedCanvas) return [] // too early

    const navigatorFilterSettings = state.navigatorFilterSettings
    const { sortOrder } = state.navigatorSortSettings

    const allShapes = ReduxSelectors.allShapes(state)
    let { collaborations: collaborationShapes } = allShapes

    const {
        showPhotoPins,
        showTaskPins,
        showOverdueTasksOnly,
        showOnlyMyPins,
        showArchivedPins,
        showStatuses,
        shouldFilterByDateCreated,
        dateCreatedDayRange,
        shouldFilterByDateUpdated,
        dateUpdatedDayRange,
        shouldFilterByIdentifiers,
        selectedIdentifiers,
        shouldFilterByFollower,
        followers,
    } = navigatorFilterSettings
    const { matchUntagged, shouldFilterByTags, tags } = navigatorFilterSettings
    const { matchUnassigned, shouldFilterByAssignee, assignees } = navigatorFilterSettings
    if (!showPhotoPins) collaborationShapes = collaborationShapes.filter(cs => cs.isTask)
    if (!showTaskPins) collaborationShapes = collaborationShapes.filter(cs => !cs.isTask)
    if (showTaskPins && showOverdueTasksOnly) collaborationShapes = collaborationShapes.filter(isOverdue)
    if (showOnlyMyPins) collaborationShapes = collaborationShapes.filter(cs => cs.author.id === state.selectedUserId)
    if (!showArchivedPins) collaborationShapes = collaborationShapes.filter(cs => !cs.isArchived)
    if (showStatuses) collaborationShapes = collaborationShapes.filter(filterStatuses)
    if (shouldFilterByTags) collaborationShapes = collaborationShapes.filter(filterByTags)
    if (shouldFilterByAssignee) collaborationShapes = collaborationShapes.filter(filterByAssignee)
    if (shouldFilterByDateCreated && dateCreatedDayRange)
        collaborationShapes = collaborationShapes.filter(({ date }) => filterByDayRange(date, dateCreatedDayRange))
    if (shouldFilterByDateUpdated && dateUpdatedDayRange)
        collaborationShapes = collaborationShapes.filter(({ lastUpdateDate }) =>
            filterByDayRange(lastUpdateDate, dateUpdatedDayRange)
        )

    if (shouldFilterByIdentifiers && selectedIdentifiers.length) {
        collaborationShapes = collaborationShapes.filter(({ identifier }) =>
            selectedIdentifiers.some(selectedIdentifier => selectedIdentifier === identifier)
        )
    }

    if (shouldFilterByFollower) collaborationShapes = collaborationShapes.filter(filterByFollower)

    return sort(collaborationShapes)
}

/*
 * Memoize the _filteredCollaborations function, recomputing only if any of the collections or filters has changed
 */
const filteredCollaborations = memoizeReduxState(
    [
        'navigatorFilterSettings', // the whole point...
        'navigatorSortSettings', // the whole point...

        'canvases',
        'collaborations',
        'geometries',
        'selectedCanvas',
        'uploads', // we need this so when upload image URLs get updated this also gets refreshed
    ],
    _filteredCollaborations
)

/*
 * Filter the TaskList that meet the taskListFilterSettings. This is ALL the CollaborationShapes
 * that meet the filter criteria, not just the ones on the selected canvas.
 * @sig _filteredTaskList :: State -> [CollaborationShape]
 * memoized as filteredTaskList below
 */
const _filteredTaskList = state => {
    const uniq = items => [...new Set(items)] // convert to Set and back to Array

    // Does this Collaboration have ANY of the selected assignees?
    // :: CollaborationShape -> Boolean
    const filterByAssignee = collaborationShape => {
        // user has the master switch off; allow all collaborations through
        if (!shouldFilterByAssignee) return true

        if (matchUnassigned) return collaborationShape.assignee === undefined // matchUnassigned inverts the logic
        if (assignees.length === 0) return true // the user hasn't yet selected any assignees to filter on: all pass
        return assignees.some(a => a === collaborationShape.assignee) // has ANY required assignee
    }

    // Does this Collaboration have ANY of the selected canvas?
    // :: CollaborationShape -> Boolean
    const filterByCanvas = collaborationShape => {
        // user has the master switch off; allow all collaborations through
        if (!shouldFilterByCanvas) return true
        if (selectedCanvasShapes.length === 0) return true // the user hasn't yet selected any canvases to filter by: all pass
        return selectedCanvasShapes.some(canvas => canvas.id === collaborationShape.canvasId) // has ANY required canvas
    }

    // Does this Collaboration have ALL selected tags?
    // :: CollaborationShape -> Boolean
    const filterByTags = collaborationShape => {
        // user has the master switch off; allow all collaborations through
        if (!shouldFilterByTags) return true

        const uploadTags = collaborationShape.uploads.flatMap(u => u.tags)
        const collaborationPlusUploadTags = uniq(collaborationShape.tags.concat(uploadTags))

        if (matchUntagged) return collaborationPlusUploadTags.length === 0 // matchUntagged inverts the logic
        if (tags.length === 0) return true // the user hasn't yet selected any tags to filter on: all pass

        // use the tag ids, not the tags to compare to avoid issues with out-of-date TagShapes
        // collaboration or some upload has ANY of required tags
        const collaborationPlusUploadTagIds = new Set(pluck('id', collaborationPlusUploadTags))
        const userSelectedTagIds = new Set(pluck('id', tags))
        return userSelectedTagIds.intersection(collaborationPlusUploadTagIds).size > 0
    }

    // either it's not a task, or it matches one of the statuses selected by the user
    const filterStatuses = collaborationShape =>
        !collaborationShape.status || showStatuses.includes(collaborationShape.status)

    const filterByDayRange = (date, maxDayDifference) => {
        const daysDiff = differenceInCalendarDays(new Date(), date)
        return daysDiff <= maxDayDifference
    }

    // Does this Collaboration is followed by ANY of the selected followers?
    // :: CollaborationShape -> Boolean
    const filterByFollower = collaborationShape => {
        // user has the master switch off; allow all collaborations through
        if (!shouldFilterByFollower) return true

        if (followers.length === 0) return true // the user hasn't yet selected any followers to filter on: all pass
        return followers.some(a => collaborationShape.followers.includes(a.id)) // has ANY required follower
    }

    const allShapes = ReduxSelectors.allShapes(state)
    const { collaborations: allCollaborations } = allShapes

    const taskListFilterSettings = state.taskListFilterSettings
    // filter to only have tasks
    let allTasks = Object.values(allCollaborations).filter(c => !!c.isTask)

    const now = new Date().toISOString()

    const {
        shouldFilterByAssignee,
        matchUnassigned,
        assignees,
        showOnlyCreatedByMe,
        showOnlyOverdueTasks,
        showArchivedTasks,
        shouldFilterByTags,
        tags,
        matchUntagged,
        showStatuses,
        shouldFilterByCanvas,
        selectedCanvasShapes,
        shouldFilterByDateCreated,
        dateCreatedDayRange,
        shouldFilterByDateUpdated,
        dateUpdatedDayRange,
        shouldFilterByIdentifiers,
        selectedIdentifiers,
        shouldFilterByFollower,
        followers,
    } = taskListFilterSettings
    if (shouldFilterByAssignee) allTasks = allTasks.filter(filterByAssignee)

    if (showOnlyCreatedByMe) allTasks = allTasks.filter(cs => cs.author === state.selectedUserId)
    if (showOnlyOverdueTasks) {
        allTasks = allTasks.filter(cs => {
            if (!cs.dueDate) return false
            return new Date(cs.dueDate).toISOString() < now // show only tasks that are after due date
        })
    }
    if (!showArchivedTasks) allTasks = allTasks.filter(cs => !cs.isArchived)

    if (shouldFilterByTags) allTasks = allTasks.filter(filterByTags)

    if (showStatuses) allTasks = allTasks.filter(filterStatuses)

    if (shouldFilterByCanvas) allTasks = allTasks.filter(filterByCanvas)

    if (shouldFilterByDateCreated && dateCreatedDayRange)
        allTasks = allTasks.filter(({ date }) => filterByDayRange(date, dateCreatedDayRange))

    if (shouldFilterByDateUpdated && dateUpdatedDayRange)
        allTasks = allTasks.filter(({ lastUpdateDate }) => filterByDayRange(lastUpdateDate, dateUpdatedDayRange))

    if (shouldFilterByIdentifiers && selectedIdentifiers.length) {
        allTasks = allTasks.filter(({ identifier }) =>
            selectedIdentifiers.some(selectedIdentifier => selectedIdentifier === identifier)
        )
    }

    if (shouldFilterByFollower) allTasks = allTasks.filter(filterByFollower)

    return allTasks
}

/*
 * Memoize the _filteredTaskList function, recomputing only if any of the collections or filters has changed
 */
const filteredTaskList = memoizeReduxState(
    [
        'taskListFilterSettings',

        'canvases',
        'collaborations',
        'geometries',
        'selectedCanvas',
        'uploads', // we need this so when upload image URLs get updated this also gets refreshed
    ],
    _filteredTaskList
)

const _isDateInRange = (queryDate, dateRange) =>
    queryDate.getTime() >= dateRange[0].getTime() && queryDate.getTime() <= dateRange[1].getTime()

/*
 * Filter the MediaView items that meet the mediaViewFilterSettings. This is ALL the CollaborationShapes
 * that meet the filter criteria.
 * @sig _filteredMediaView :: State -> [CollaborationShape]
 * memoized as filteredMediaView below
 */
const _filteredMediaView = state => {
    const allCanvases = ReduxSelectors.canvasLookupTable(state)
    const allUploads = Object.values(ReduxSelectors.uploadLookupTable(state))
    const allCollaborations = ReduxSelectors.collaborationLookupTable(state)

    const mediaViewFilterSettings = state.mediaViewFilterSettings

    const { dateRange, canvasIds, tagIds, participantIds } = mediaViewFilterSettings

    const _getUploadCanvas = upload => {
        const parentCollaboration = allCollaborations[upload.parentId]
        const geometry = ReduxSelectors.geometryForCollaboration(state, parentCollaboration)
        return allCanvases[geometry.canvasId]
    }

    const filtered = allUploads.filter(upload => {
        const filteredByDate = !dateRange.length || _isDateInRange(upload.date, dateRange)

        const filteredByCanvas = !canvasIds.length || canvasIds.includes(_getUploadCanvas(upload).id)

        const tagsCombined = [...upload.tagIds, ...allCollaborations[upload?.parentId]?.tags]

        const filteredByTags = !tagIds.length || tagIds.some(tagId => tagsCombined.includes(tagId))

        const filteredByAssignee = !participantIds.length || participantIds.includes(upload.authorId)

        return filteredByDate && filteredByCanvas && filteredByTags && filteredByAssignee
    })

    return arrayToLookupTable('id', filtered)
}

/*
 * Memoize the _filteredMediaView function, recomputing only if any of the collections or filters has changed
 */
const filteredMediaView = memoizeReduxState(
    [
        'mediaViewFilterSettings',

        'canvases',
        'collaborations',
        'uploads', // we need this so when upload image URLs get updated this also gets refreshed
    ],
    _filteredMediaView
)

export { filteredCollaborations, filteredTaskList, filteredMediaView }
