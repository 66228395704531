import React from 'react'
import { Box, Button, Flex, FlexColumn, FlexRow, Icon, Text, TextInput } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledPage = styled(FlexRow, {
    // top-level grid
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(180deg, #44ddff, #7f75f6)',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
})

const InputLabel = styled(Text, {
    color: '$neutral04',
    width: '100%',
    fontSize: '13px',
})

const LinkText = styled(Text, {
    fontWeight: '500',
    fontSize: '13px',
    color: '$primary04',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
})

const LinkTextCTA = styled('a', {
    fontWeight: '500',
    fontSize: '13px',
    alignSelf: 'center',
    color: '$primary04',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Breakpoints
// ---------------------------------------------------------------------------------------------------------------------

const breakpoints = {
    small: '493px',
    medium: '810px',
    large: '1200px',
}

const StyledForm = styled('form', {
    display: 'flex',
    flexDirection: 'column',
})

const StyledContainer = styled(FlexColumn, {
    backgroundColor: '$neutral10',
    height: 'auto',
    alignSelf: 'center',
    flexShrink: '0',
    zIndex: 1,

    alignItems: 'center',
    justifyContent: 'center',
    br: 10,
    position: 'relative',
    width: '490px',
    margin: 'auto',
    boxShadow: '0px 6px 10px #00000010',

    overflow: 'hidden',
    maxHeight: '90vh',
    maxWidth: '90vw',

    // Media queries for responsive design
    [`@media (max-width: ${breakpoints.medium})`]: {
        width: '90%', // Adjust width for medium and small screens
    },
    [`@media (max-width: ${breakpoints.small})`]: {
        width: '100%', // Adjust width for small screens
    },
})

const StyledWrapper = styled('div', {
    overflowY: 'auto',
    padding: 60,
    width: '100%',
    boxSizing: 'border-box',

    // Media queries for responsive design
    [`@media (max-width: ${breakpoints.medium})`]: {
        padding: '40px 24px', // Adjust padding for small screens
    },
})

const StyledContent = styled(FlexColumn, {
    gap: '24px',

    // Media queries for responsive design
    [`@media (max-width: ${breakpoints.medium})`]: {
        gap: '14px',
    },
})
const StyledLogo = styled(FlexRow, {
    width: '100%',
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    mb: 16,
})

const StyledLogoOrg = styled(FlexRow, {
    width: '100%',
    gap: '20px',
    alignItems: 'flex-start',
    justifyContent: 'center',
})

const LabelLayout = styled(FlexColumn, {
    gap: '8px',
    width: '100%',
    justifyContent: 'center',
    mb: 8,
})

const PrimaryLabel = styled(Text, {
    fw: 700,
    fs: '32px',
    alignSelf: 'center',
    color: '$neutral04',
    textAlign: 'center',
    lineHeight: '1.3',
})

const SecondaryLabel = styled(Text, {
    fw: 400,
    fs: '16px',
    alignSelf: 'center',
    color: '$neutral05',
    textAlign: 'center',
})

const FooterLabel = styled(Text, {
    fw: 400,
    fs: '12px',
    alignSelf: 'center',
    color: '$neutral05',
    textAlign: 'center',
})

const PrimaryLabelOrg = styled(Flex, {
    fw: 700,
    fs: '32px',
    color: '$neutral04',
    textAlign: 'center',
    lineHeight: '1.3',
})

const SecondaryLabelOrg = styled(Text, {
    fw: 400,
    fs: '16px',
    alignSelf: 'start',
    color: '$neutral05',
    textAlign: 'center',
    width: '100%',
})

const InputFlex = styled(FlexColumn, {
    gap: '6px',
    width: '100%',
    alignItems: 'left',
    justifyContent: 'center',
})

const StyledTextInput = styled(TextInput, {
    justifySelf: 'stretch',
    flexGrow: '0',
    width: '100%',

    input: {
        color: '$neutral05',
        '&:focus': { color: '$neutral04' },
    },
})

const StyledCodeInput = styled('input', {
    borderRadius: '6px',
    justifySelf: 'stretch',
    flexGrow: '0',
    width: '100%',
    minHeight: '56px',
    color: '$neutral05',
    border: '1px solid $neutral07',
    backgroundColor: '$neutral09',
    textAlign: 'center',
    fs: 24,
    '&:hover': {
        border: '1px solid $primary04',
    },
    '&:focus': {
        color: '$neutral04',
    },
})

const ButtonLayout = styled(FlexColumn, {
    gap: '24px',
    width: '100%',
    marginTop: '8px',
    alignItems: 'center',
    justifyContent: 'center',
})

const StyledFooter = styled(FlexRow, {
    color: '$neutral04',
    width: '100%',
    height: 'auto',
    fontSize: '13px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '2px',
    lineHeight: '1.2',
})

const StyledFooterText = styled(Text, {
    fontWeight: '500',
    color: '$neutral05',
})

const BGLogo = styled(Box, {
    position: 'fixed',
})

const OrganizationLogo = styled(Box, {
    minWidth: '40px',
    minHeight: '40px',
    maxWidth: '40px',
    maxHeight: '40px',
    backgroundColor: '$neutral08',
    borderRadius: '999px',
    border: '1px solid $neutral07',
})

const OrganizationLabelContainer = styled(FlexColumn, {
    width: '100%',
    alignItems: 'left',
    justifyContent: 'left',
    flexWrap: 'noWrap',
})

const OrganizationMemberRow = styled(FlexRow, {
    width: '100%',
    alignItems: 'left',
    justifyContent: 'left',
    flexWrap: 'noWrap',
    gap: '4px',
})

const SecondaryLabelOrganization = styled(Text, {
    fw: 400,
    fs: '14px',
    alignSelf: 'center',
    color: '$neutral05',
    textAlign: 'left',
})

const UrlMessage = styled(FlexRow, {
    ai: 'center',
    gap: 6,
    fs: 14,
    variants: {
        variant: {
            success: {
                color: '$green03',
            },
            error: {
                color: '$red03',
            },
        },
    },
})

const WrappedInput = styled(FlexRow, {
    b: '1px solid $neutral07',
    background: '$neutral09',
    br: 6,
    ai: 'center',
    pl: 8,
    color: '$neutral05',
    fs: 14,
    transitionDuration: '0.4s',
    overflow: 'hidden',
})

const ContinueWithGoogle = ({ onClick }) => {
    // <Button type="button">  or this button will submit its surrounding form -- which we don't want
    return (
        <FlexColumn css={{ width: '100%', gap: 24 }}>
            <Button
                data-cy="login-google"
                type="button"
                onClick={onClick}
                variant="secondary"
                size="lg"
                css={{ w: '100%' }}
            >
                <Icon name="googleColor" iconSize="16" />
                <Text>Continue with Google</Text>
            </Button>
            <FlexRow css={{ ai: 'center', width: '100%', gap: 4, color: '$neutral05', fs: 14 }}>
                <Flex css={{ minHeight: 1, background: '$neutral07', width: '100%' }} />
                <Text>Or</Text>
                <Flex css={{ minHeight: 1, background: '$neutral07', width: '100%' }} />
            </FlexRow>
        </FlexColumn>
    )
}

const ScrollableContainer = ({ children, ...rest }) => {
    return (
        <StyledContainer {...rest}>
            <StyledWrapper>
                <StyledContent css={{ gap: '24px' }}>{children}</StyledContent>
            </StyledWrapper>
        </StyledContainer>
    )
}

export {
    breakpoints,
    StyledPage,
    InputLabel,
    LinkText,
    LinkTextCTA,
    StyledForm,
    ScrollableContainer,
    StyledLogo,
    StyledLogoOrg,
    LabelLayout,
    PrimaryLabel,
    SecondaryLabel,
    FooterLabel,
    PrimaryLabelOrg,
    SecondaryLabelOrg,
    InputFlex,
    StyledTextInput,
    StyledCodeInput,
    ButtonLayout,
    StyledFooter,
    StyledFooterText,
    BGLogo,
    OrganizationLogo,
    OrganizationLabelContainer,
    OrganizationMemberRow,
    SecondaryLabelOrganization,
    UrlMessage,
    WrappedInput,
    ContinueWithGoogle,
}
