/*
 * Invitation: a user invites a new user
 */
import { mergeRight, tagged } from '@range.io/functional'
import { dateToTimestamp, timestampToDate } from '../helper/timestamp.js'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Invitation = tagged('Invitation', {
    id                  : StringTypes.Id,
    organizationId      : StringTypes.Id,            // id of an Organization the inviter has permissions for
    organizationName    : 'String',
    userId              : 'String?',                 // id of reinvited user
    inviteeEmail        : StringTypes.Email,         // email of invitation recipient
    organizationRole    : StringTypes.OrganizationRole,
    timestamp           : 'Object',                  // Date when the inviter SENT the invitation
    resentTimestamp     : 'Object?',                 // Date when the invitee RESENT the invitation
    acceptedTimestamp   : 'Object?',                 // Date when the invitee ACCEPTED the invitation
    projectIds          : StringTypes.OptionalIds,   // Collaborators and Guests have access to a subset of projects
  
    // NOT needed for Early-Access invitations, because there is no inviter in that case
    inviterEmail        : StringTypes.OptionalEmail,
    inviterFirstName    : 'String?',
    inviterLastName     : 'String?',
    
    
    // projectRoles: { [ProjectId]: CanEdit|CanView }  // TODO: handle projectRoles
})

/*
 * Create a new Invitation merging an old one with values from changes
 * @sig update :: (Invitation, {k:v}) -> Invitation
 */
Invitation.update = (invitation, changes) => Invitation.from(mergeRight(invitation, changes))

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Create an Invitation from a JSON object
 * @sig fromFirebase :: {k:v} -> Invitation
 */
// prettier-ignore
Invitation.fromFirebase = data => Invitation.from({
    id                   : data.id,
    organizationId       : data.organizationId,
    organizationName     : data.organizationName,
    userId               : data.userId,
    inviteeEmail         : data.inviteeEmail,
    organizationRole     : data.organizationRole,
    timestamp            : timestampToDate(data.timestamp),
    resentTimestamp      : timestampToDate(data.resentTimestamp),
    acceptedTimestamp    : timestampToDate(data.acceptedTimestamp),
    projectIds           : data.projectIds,
    
    inviterEmail         : data.inviterEmail,
    inviterFirstName     : data.inviterFirstName,
    inviterLastName      : data.inviterLastName,
})

// prettier-ignore
Invitation.toFirebase = invitation => ({
    id                   : invitation.id,
    organizationId       : invitation.organizationId,
    organizationName     : invitation.organizationName,
    userId               : invitation.userId,
    inviteeEmail         : invitation.inviteeEmail,
    organizationRole     : invitation.organizationRole,
    timestamp            : dateToTimestamp(invitation.timestamp),
    resentTimestamp      : dateToTimestamp(invitation.resentTimestamp),
    acceptedTimestamp    : dateToTimestamp(invitation.acceptedTimestamp),
    projectIds           : invitation.projectIds,
    
    inviterEmail         : invitation.inviterEmail,
    inviterFirstName     : invitation.inviterFirstName,
    inviterLastName      : invitation.inviterLastName,
})

export default Invitation
