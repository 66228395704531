/*
 * The storageVersion object has keys for different versions of an image; these are:
 *
 *  main                    the base image
 *  thumbnail0              smaller thumbnail image
 *  thumbnail1              larger thumbnail image
 *  annotated               the base image with pre-rendered annotations
 *  annotated.thumbnail0    smaller thumbnail of the annotated image
 *  annotated.thumbnail1    larger thumbnail of the annotated image
 *  location                the location snapshot image for a collaboration (with a pin placed showing a location)
 *  location.thumbnail0     smaller thumbnail of the location snapshot
 *  location.thumbnail1     larger thumbnail of the location snapshot
 *
 * These strings are `StorageVersionKey`s in the comments below
 *
 * When a given StorageVersionKey is present in a Firestore object's storageVersion field,
 * there will also be a corresponding image stored in Firebase Storage
 *
 *  uploads
 *      main                    projects/${projectId}/uploads/${uploadId}                <= note "main" has no suffix
 *      thumbnail0              projects/${projectId}/uploads/${uploadId}.thumbnail0
 *      thumbnail1              projects/${projectId}/uploads/${uploadId}.thumbnail1
 *
 *  annotated uploads
 *      annotated               projects/${projectId}/uploads/${uploadId}.annotated
 *      annotated.thumbnail0    projects/${projectId}/uploads/${uploadId}.annotated.thumbnail0
 *      annotated.thumbnail1    projects/${projectId}/uploads/${uploadId}.annotated.thumbnail1
 *
 *  collaboration location snapshots
 *      location                projects/${projectId}/collaborations/${entityId}.location
 *      location.thumbnail0     projects/${projectId}/collaborations/${entityId}.location.thumbnail0
 *      location.thumbnail1     projects/${projectId}/collaborations/${entityId}.location.thumbnail1
 *
 * But: just because we *may* have many versions of an image doesn't mean that we *do* have many versions of it.
 * There *might* be a thumbnail0 version of an Upload with annotations, but probably not -- most Uploads don't
 * have annotations at all.
 *
 * Rather than trying to guess which order to try to find the "best" version of an image, a
 * "UrlSearchOrder" expressly lists the order in which to look for images.
 *
 * A UrlSearchOrder is just an array of strings, where each of the strings is a StorageVersionKey that *might* be
 * found in the storageVersion of a Firestore object. For convenience, URL_SEARCH_ORDER includes all the ones
 * we're currently using.
 *
 * For instance, this search order assumes we would like to use the annotated.thumbnail0 if it exists,
 * but if not, then the next best choice would be the "large" annotated image, if there's no annotated image
 * try the "regular" thumbnail0 image, and finally use the "main" image if all else fails
 *
 *  ANNOTATED_THUMBNAIL_0: ['annotated.thumbnail0', 'annotated', 'thumbnail0', 'main'],
 *
 * useImageUrl and getImageUrl both require a UrlSearchOrder to be passed in
 */

import { PromiseCacheWithCacheBusting } from '@range.io/functional'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { downloadUrlForPath } from '../../firebase/firebase-facade.js'
import { ReduxSelectors } from '../../redux/index.js'

const cache = PromiseCacheWithCacheBusting()

const NO_IMAGE_FILE = '/no-image.svg'

// ---------------------------------------------------------------------------------------------------------------------
// StorageVersion mapping
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const URL_SEARCH_ORDER = {
    MAIN                 : ['main'],
    THUMBNAIL_0          : ['thumbnail0', 'main'],
    THUMBNAIL_1          : ['thumbnail1', 'main'],
    ANNOTATED            : ['annotated', 'main'],
    ANNOTATED_THUMBNAIL_0: ['annotated.thumbnail0', 'annotated', 'thumbnail0', 'main'],
    ANNOTATED_THUMBNAIL_1: ['annotated.thumbnail1', 'annotated', 'thumbnail1', 'main'],
    LOCATION             : ['location'],
    LOCATION_THUMBNAIL_0 : ['location.thumbnail0', 'location'],
    LOCATION_THUMBNAIL_1 : ['location.thumbnail1', 'location'],
}

/*
 * Convert a storageVersionKey from a Firestore object's storageVersion to the appropriate path in Firebase Storage
 *
 * @sig storagePathForImage :: (Id, Id, StorageVersionKey) -> FirebaseStoragePath
 */
const storagePathForImage = (projectId, entityId, storageVersionKey) => {
    // uploads
    const uploadPath = `projects/${projectId}/uploads/${entityId}`
    if (storageVersionKey === 'main') return `${uploadPath}`
    if (storageVersionKey === 'thumbnail0') return `${uploadPath}.thumbnail0`
    if (storageVersionKey === 'thumbnail1') return `${uploadPath}.thumbnail1`

    if (storageVersionKey === 'annotated') return `${uploadPath}.annotated`
    if (storageVersionKey === 'annotated.thumbnail0') return `${uploadPath}.annotated.thumbnail0`
    if (storageVersionKey === 'annotated.thumbnail1') return `${uploadPath}.annotated.thumbnail1`

    // collaborations
    const collaborationPath = `projects/${projectId}/collaborations/${entityId}`
    if (storageVersionKey === 'location') return `${collaborationPath}.location`
    if (storageVersionKey === 'location.thumbnail0') return `${collaborationPath}.location.thumbnail0`
    if (storageVersionKey === 'location.thumbnail1') return `${collaborationPath}.location.thumbnail1`

    return 'no-version'
}

/*
 * Return data for the "best" storageVersionKey we can find, along with the associated storagePath and versionTimestamp,
 * or if we find NO storageVersionKey, return the storagePath for the main image and a versionTimes
 *
 * @sig findAVersion :: (Id, Id, [StorageVersionKey], {k:v) -> { storageVersionKey: StorageVersionKey, storagePath: String, versionTimestamp: Number|'has-no-storage-version' }
 */
const findAVersion = (projectId, entityId, urlSearchOrder, storageVersion) => {
    const storageVersionKey = urlSearchOrder.find(u => storageVersion?.[u]) || 'main'
    const storagePath = storagePathForImage(projectId, entityId, storageVersionKey || 'main')
    return {
        storageVersionKey,
        storagePath,
        versionTimestamp: storageVersion?.[storageVersionKey] || 'has-no-storage-version',
    }
}

/*
 * Request the Firebase Storage URL for "best" version we have for the given entity (currently, Upload or Collaboration)
 * If there is no such image in Storage, return the default image for "no image file"
 *
 * @sig getImageUrl :: (Id, Id, [StorageVersionKey], {k:v}) -> Promise String
 */
const getImageUrl = async (projectId, entityId, urlSearchOrder, storageVersion) => {
    try {
        const { storageVersionKey, storagePath, versionTimestamp } = findAVersion(
            projectId,
            entityId,
            urlSearchOrder,
            storageVersion
        )
        const cacheKey = `${entityId}|${storageVersionKey}`
        return await cache.waitFor(cacheKey, () => downloadUrlForPath(storagePath), projectId, versionTimestamp)
    } catch (e) {
        // if the error is something OTHER than not found, rethrow it
        if (e.code !== 'storage/object-not-found') throw e

        return Promise.resolve(NO_IMAGE_FILE)
    }
}

/*
 * A hook to call getImageUrl; return null initially, and then returns the appropriate URL when getImageUrl finds one
 * Because this is a hook the change in URL will trigger a render of the caller
 *
 * @sig useImageUrl :: ([StorageVersionKey], Id) -> String
 */
const useImageUrl = (urlSearchOrder, entityId) => {
    const [url, setUrl] = useState(null)
    const { projectId } = useParams()
    const storageVersion = useSelector(ReduxSelectors.storageVersionForEntity(entityId))

    const fetchImageUrl = async () => {
        const newUrl = await getImageUrl(projectId, entityId, urlSearchOrder, storageVersion)
        setUrl(newUrl)
    }

    useEffect(() => {
        if (entityId) {
            // there's a storageVersion entry so we expect an image
            if (storageVersion) fetchImageUrl()
            // no storageVersion means there's no file yet uploaded to the storage
            else setUrl(null)
        }
    }, [urlSearchOrder, entityId, storageVersion])

    return { url }
}

export { getImageUrl, useImageUrl, URL_SEARCH_ORDER }
