/*
 * See Readme
 */

import { Organization } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import * as Firestore from 'firebase/firestore'
import { ReduxActions } from '../../redux/index.js'
import { enrichDeletedFields } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// OrganizationChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const OrganizationChangedCommand = taggedSum('OrganizationChangedCommand', {
    Inbound: {
        organization: 'Organization',
    },
    Outbound: {
        id: StringTypes.Id, // Organization id
        changes: 'Object', // each entry defines key/value of the existing Organization that changed
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

// nothing to do; will be caught by OrganizationAddedCommand
const runInboundCommand = () => {}

/*
 * Write a change to the Organization to Firebase
 */
const runOutboundCommand = async (resources, command) => {
    const { id, changes } = command
    const { dispatch, displayError, runTransaction, firestore } = resources
    const { id: _, ...changesWithoutId } = enrichDeletedFields(Organization.toFirebase(changes))

    try {
        const path = `/organizations/${id}`
        await runTransaction(async tx => tx.update(Firestore.doc(firestore, path), changesWithoutId))
        dispatch(ReduxActions.organizationChanged({ id, changes }))
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        OrganizationChangedCommand,
        CommandPlayer({
            CommandType: OrganizationChangedCommand,
            Type: Organization,
            documentPath: organizationId => `/organizations/${organizationId}`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
            resourceKey: 'organizationId',
        })
    )
}

export { OrganizationChangedCommand, addCommand }
