import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import RangeStatus from '@range.io/basic-types/src/core/range-status.js'

const RangeStatusChangedCommand = taggedSum('RangeStatusChangedCommand', {
    Inbound: {
        rangeStatus: 'Object',
    },
    Outbound: {},
})

const runInboundCommand = (resources, command) => {
    const { dispatch } = resources
    const { rangeStatus } = command

    dispatch(ReduxActions.rangeStatusChanged(rangeStatus))
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        RangeStatusChangedCommand,
        CommandPlayer({
            CommandType: RangeStatusChangedCommand,
            Type: RangeStatus,
            documentPath: () => '/RangeStatus/RangeIsClosed',
            runInboundCommand,
            runOutboundCommand: () => {},
            addCommandToHistory,
            changeType: 'modified',
            resourceKey: 'RangeStatus', // special resourceKey that requires to be called from App to attach listeners
        })
    )
}

export { RangeStatusChangedCommand, addCommand }
