/*
 * SelectSymbolMode
 *
 * Custom select mode used to handle all symbol (Point) annotation type - text and marker.
 * Extends Mapbox GL Draw's simple_select mode.
 *
 * Type SelectSymbolModeState:
 *    feature : DrawFeature<JsonFeature>
 */
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw-unminified.js'

const SelectSymbolMode = { ...MapboxDraw.modes.simple_select }

SelectSymbolMode.onSetup = function (opts) {
    const state = MapboxDraw.modes.simple_select.onSetup.bind(this)(opts)
    state.featureId = opts.featureId
    this.setSelected([state.featureId])
    return state
}

SelectSymbolMode.clickAnywhere = function (state) {
    MapboxDraw.modes.simple_select.clickAnywhere.bind(this)(state)
    this.changeMode('idle')
}

SelectSymbolMode.clickOnFeature = function (state, e) {
    state.featureId = e.featureTarget.properties.id
    MapboxDraw.modes.simple_select.clickOnFeature.bind(this)(state, e)
}

SelectSymbolMode.toDisplayFeatures = function (state, geojson, display) {
    geojson.properties.active = state.featureId === geojson.properties.id ? 'true' : 'false'
    geojson.properties.anythingSelected = true
    display(geojson)
}

export default SelectSymbolMode
