import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Box, Button, FlexColumn, FlexRow, Flex, Icon, Text } from '../components-reusable/index.js'
import SelectTypeahead from '../components-reusable/SelectTypeahead.js'
import { styled } from '../range-theme/index.js'

const ModalContainer = ({ children, ...restProps }) => {
    return (
        <Box
            onClick={e => {
                e.preventDefault()
                e.stopPropagation() // Needed to not bubble click action so overlay click doesn't trigger
            }}
            {...restProps}
        >
            {children}
        </Box>
    )
}

const StyledModalContainer = styled(ModalContainer, {
    bg: '$neutral10',
    color: '$neutral04',
    width: '500px',
    border: '1px solid $neutral07',
    br: '6px',
    boxShadow: '0px 6px 10px #00000010',
    zIndex: 1000,
})

const StyledModalActions = styled(Box, {
    width: '100%',
    height: '72px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '24px',
    paddingLeft: '24px',
    bt: '1px solid $neutral07',
    display: 'flex',
    gap: '16px',
    justifyContent: 'right',
})

const StyledPrimaryModalLabel = styled(Flex, {
    fs: '20px',
    fw: 700,
})

const StyledSecondaryModalLabel = styled(Text, {
    fs: '14px',
    color: '$neutral05',
    lh: '1.4',
})

const StyledOverlay = styled(FlexColumn, {
    width: '100vw',
    height: '100vh',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$neutralOverlay',
    ai: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 100,
})

const StyledTag = styled(FlexRow, {
    background: '$neutral09',
    br: 999,
    b: '1px solid $neutral07',
    height: 32,
    fs: '14',
    fw: 500,
    alignItems: 'center',
    pl: '10px',
    pr: '10px',
    width: 'fit-content',
})

const StyledTagWrapper = styled(FlexRow, {
    gap: '8px',
    flexWrap: 'wrap',
    pb: '24px',
})

const StyledLabels = styled(FlexRow, {
    gap: 8,
    pb: '8px',
    ai: 'center',
})

const StyledIcon = styled(Icon, {
    color: '$neutral04',
    width: '24px',
    height: '24px',
})

/*
 * Edit single tag component
 *
 * @sig TagManagerEditSingleTagModal :: (LookupTable, OnSaveFunc, OnCancelFunc, Id) -> ()
 *
 *   OnSaveFunc = ActionItem -> *
 *   OnCancelFunc = () -> *
 *   ActionItem = { isNew: Boolean, newTagName: String, item: ? }
 *      item is likely a TagName; it must have an id
 *      isNew implies newTagName will be defined;
 *      !isNew implies item will be defined
 */
const TagManagerEditSingleTagModal = ({ allTags, onSave, onCancel, selectedTagId }) => {
    const [itemSelection, setItemSelection] = useState(null)
    const [inputValue, setInputValue] = useState('')

    const items = Object.values(allTags)
        .filter(tag => tag.id !== selectedTagId)
        .map(tag => {
            return { id: tag.id, label: `${tag.name} (${tag.count})`, searchLabel: tag.name }
        })
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))

    const handleCreateItemSelected = newTagName => setItemSelection({ newTagName, isNew: true })

    const handleItemSelected = item => setItemSelection({ item, isNew: false })

    const handleSaveClicked = () => {
        if (!itemSelection) onSave({ newTagName: inputValue, isNew: true })
        else onSave(itemSelection)
    }

    const handleInputChange = value => {
        setInputValue(value)
        setItemSelection(null) // if user changes input, reset the tag selection
    }

    return (
        <StyledOverlay onClick={onCancel}>
            {' '}
            {/* Overlay click will trigger cancel */}
            <StyledModalContainer onClick={e => e.stopPropagation()}>
                {' '}
                {/* Prevents click inside modal from propagating */}
                <Box css={{ p: '24px' }}>
                    <StyledLabels>
                        <StyledIcon name="tags" iconSize="24" />
                        <StyledPrimaryModalLabel>Edit Tag</StyledPrimaryModalLabel>
                    </StyledLabels>
                    <StyledSecondaryModalLabel css={{ pb: '24px' }}>
                        Rename your tag by merging it with an existing tag, or by creating a new tag.
                    </StyledSecondaryModalLabel>
                    <SelectTypeahead
                        creatable={true}
                        items={items}
                        formatCreateNewLabel={newValue => `Create tag "${newValue}"`}
                        onCreateItemSelect={handleCreateItemSelected}
                        onItemSelect={handleItemSelected}
                        placeholder="Rename to..."
                        value={inputValue}
                        onValueChange={handleInputChange}
                    />
                </Box>
                <StyledModalActions>
                    <Button variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={handleSaveClicked}
                        disabled={itemSelection === null && inputValue === ''}
                    >
                        Save
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

TagManagerEditSingleTagModal.displayName = 'TagManagerEditSingleTagModal'

TagManagerEditSingleTagModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    selectedTagId: PropTypes.string.isRequired,
}

/*
 * Edit multiple tag component
 *
 * @sig TagManagerEditMultipleTagsModal :: (LookupTable, OnSaveFunc, OnCancelFunc, [Id]) -> ()
 *
 *   OnSaveFunc = ActionItem -> *
 *   OnCancelFunc = () -> *
 *   ActionItem = { isNew: Boolean, newTagName: String, item: ? }
 *      item is likely a TagName; it must have an id
 *      isNew implies newTagName will be defined;
 *      !isNew implies item will be defined
 */

const TagManagerEditMultipleTagsModal = ({ allTags, onSave, onCancel, selectedTagIds }) => {
    const [itemSelection, setItemSelection] = useState(null)
    const [inputValue, setInputValue] = useState('')

    const items = Object.values(allTags)
        .filter(tag => selectedTagIds.indexOf(tag.id) === -1)
        .map(tag => {
            return { id: tag.id, label: `${tag.name} (${tag.count})`, searchLabel: tag.name }
        })
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))

    const handleCreateItemSelected = newTagName => setItemSelection({ newTagName, isNew: true })

    const handleItemSelected = item => setItemSelection({ item, isNew: false })

    const handleSaveClicked = () => {
        if (!itemSelection) onSave({ newTagName: inputValue, isNew: true })
        else onSave(itemSelection)
    }

    const getTagLabel = tagId => {
        const tag = allTags[tagId]
        return `${tag.name} (${tag.count})`
    }

    const handleInputChange = value => {
        setInputValue(value)
        setItemSelection(null) // if user changes input, reset the tag selection
    }

    return (
        <StyledOverlay onClick={onCancel}>
            {/* Overlay click will trigger cancel */}
            <StyledModalContainer>
                <Box css={{ p: '24px' }}>
                    <StyledLabels>
                        <StyledIcon name="tags" iconSize="24" />
                        <StyledPrimaryModalLabel>Edit {selectedTagIds.length} tags</StyledPrimaryModalLabel>
                    </StyledLabels>
                    <StyledSecondaryModalLabel css={{ pb: '24px' }}>
                        Rename your selected tags by choosing a tag from your existing tag set, or by creating a new
                        tag.
                    </StyledSecondaryModalLabel>
                    <StyledTagWrapper>
                        {selectedTagIds.map((tagId, idx) => (
                            <StyledTag key={`styled-tag-${idx}`}>{getTagLabel(tagId)}</StyledTag>
                        ))}
                    </StyledTagWrapper>
                    <SelectTypeahead
                        creatable={true}
                        items={items}
                        formatCreateNewLabel={newValue => `Create tag "${newValue}"`}
                        onCreateItemSelect={handleCreateItemSelected}
                        onItemSelect={handleItemSelected}
                        placeholder="Rename to..."
                        value={inputValue}
                        onValueChange={handleInputChange}
                    />
                </Box>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={handleSaveClicked}
                        disabled={itemSelection === null && inputValue === ''}
                    >
                        Save
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

TagManagerEditMultipleTagsModal.displayName = 'TagManagerEditMultipleTagsModal'

TagManagerEditMultipleTagsModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
}

export { TagManagerEditSingleTagModal, TagManagerEditMultipleTagsModal }
