import { TimeFormat } from '@range.io/basic-types'
import React from 'react'
import { Avatar, Button, DropdownMenuWithTooltip, FlexRow, Icon, Text } from '../components-reusable/index.js'
import PossiblySuspendedUserName from '../components-reusable/PossiblySuspendedUserName.js'
import { styled } from '../range-theme/index.js'

const UserNameTimeStamp = styled(Text, {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
})

const UserNameText = styled(PossiblySuspendedUserName, {
    color: '$neutral04',
    fs: '14px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
})

const UploadDateText = styled(Text, {
    color: '$neutral05',
    fs: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
})

const MoreActionsDropdown = ({ allowDelete, onDeleteClick, onDownloadClick }) => (
    <DropdownMenuWithTooltip.Root>
        <DropdownMenuWithTooltip.Trigger tooltipText="More Actions">
            <Button css={{ w: 32, color: '$neutral05' }} variant="dropdown-trigger">
                <Icon iconSize="28px" name="threeDot" />
            </Button>
        </DropdownMenuWithTooltip.Trigger>
        <DropdownMenuWithTooltip.Content side="bottom" align="end" sideOffset={6}>
            <DropdownMenuWithTooltip.MenuItem>
                <Button variant="dropdown-menuitem" onClick={onDownloadClick}>
                    <Icon iconSize="16px" name="download" css={{ mr: 8 }} />
                    <Text>Download</Text>
                </Button>
            </DropdownMenuWithTooltip.MenuItem>
            {allowDelete && (
                <DropdownMenuWithTooltip.MenuItem>
                    <Button variant="dropdown-menuitem-destructive" onClick={onDeleteClick}>
                        <Icon iconSize="16px" name="trash" css={{ mr: 8 }} />
                        <Text>Delete</Text>
                    </Button>
                </DropdownMenuWithTooltip.MenuItem>
            )}
        </DropdownMenuWithTooltip.Content>
    </DropdownMenuWithTooltip.Root>
)

const MediaViewItemHeader = ({ date, allowDelete, onDeleteClick, onDownloadClick, participantShape }) => (
    <FlexRow css={{ height: '39px', display: 'flex', alignItems: 'center' }}>
        <Avatar size="32" css={{ marginRight: '10px', zIndex: 0 }} participantShape={participantShape} />
        <UserNameTimeStamp>
            <UserNameText participantShape={participantShape} />
            <UploadDateText>{TimeFormat.primary(date)}</UploadDateText>
        </UserNameTimeStamp>
        <MoreActionsDropdown
            allowDelete={allowDelete}
            onDeleteClick={onDeleteClick}
            onDownloadClick={onDownloadClick}
        />
    </FlexRow>
)

export default MediaViewItemHeader
