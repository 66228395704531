/*
 * Extend Array, combining it with a hash table
 *
 *
 */

import { without } from '../index.js'

const addNonEnumerable = (target, key, value) => {
    Object.defineProperty(target, key, { value, enumerable: false })
}

/*
 * @sig LookupTable :: ([A]|{k:A}, idField = 'id') -> LookupTable
 * items can be an array or object. If it's an object, its values become the items
 */
const LookupTable = (items, idField = 'id') => {
    if (!Array.isArray(items)) items = Object.values(items)

    // fill hash from items
    const hash = {}
    items.forEach(o => (hash[o[idField]] = o))

    // initialize an array and add 'get'
    const result = Array.from(items)
    addNonEnumerable(result, 'type', 'LookupTable')
    addNonEnumerable(result, 'get', key => hash[key])
    addNonEnumerable(result, 'filter', predicate => LookupTable(items.filter(predicate), idField))
    addNonEnumerable(result, 'sort', sortFunc => LookupTable(Array.from(items).sort(sortFunc), idField))
    addNonEnumerable(result, 'prepend', o => LookupTable([o, ...items]))
    addNonEnumerable(result, 'map', mapFun => LookupTable(Array.from(items).map(mapFun), idField))

    addNonEnumerable(result, 'addItem', (item, sort) => {
        const newItems = [...items, item]
        if (sort) newItems.sort(sort)
        return LookupTable(newItems, idField)
    })

    addNonEnumerable(result, 'removeItem', item => LookupTable(without(item, items), idField))
    addNonEnumerable(result, 'toggleItem', (item, sort) =>
        items.indexOf(item) === -1 ? result.addItem(item, sort) : result.removeItem(item)
    )

    return result
}

LookupTable.is = o => o.type === 'LookupTable'

export default LookupTable
