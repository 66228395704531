import React from 'react'
import { styled } from '@stitches/react'
import { Avatar, FlexRow, FlexColumn, PossiblySuspendedUserName, Text } from '../components-reusable/index.js'

const StyledRolePill = styled(Text, {
    borderRadius: 999,
    fontSize: 11,
    lineHeight: '20px !important', // TODO: fix some Text properties not being replaceable
    fw: 500,
    height: '22px',
    padding: '0 8px',
    boxSizing: 'border-box',
    alignItems: 'center',
    width: 'fit-content',
    background: '$neutral09',
    color: '$neutral05',
    border: '1px solid $neutral07',

    variants: {
        suspended: {
            true: {
                color: '$red03',
                border: 'none',
                background: '$red01',
            },
        },
        variant: {
            dark: {
                padding: '0 6px',
                background: '$neutral10',
                color: '$neutral04',
                border: '1px solid $neutral08',
            },
        },
    },
})

const StyledUserRow = styled(FlexRow, {
    gap: 8,
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    width: 'fit-content',
    minWidth: 0,
})

const RolePill = ({ children, suspended }) => (
    <StyledRolePill
        suspended={suspended}
        css={{
            display: 'inline-flex',
        }}
    >
        {children}
    </StyledRolePill>
)

const UserRow = ({ participantShape, isOnline }) => {
    const { email, organizationRole, isSuspended } = participantShape

    return (
        <StyledUserRow>
            <Avatar size="24" participantShape={participantShape} isOnline={isOnline} />
            <FlexColumn
                css={{
                    overflow: 'hidden',
                    gap: 2,
                }}
            >
                <FlexRow css={{ gap: 4 }}>
                    <PossiblySuspendedUserName
                        style={{ alignContent: 'center', fontSize: '14px', fontWeight: '500' }}
                        participantShape={participantShape}
                        ellipsed
                        inherit
                    />
                    {organizationRole && <RolePill>{organizationRole}</RolePill>}
                    {isSuspended && <RolePill suspended={true}>Suspended</RolePill>}
                </FlexRow>
                {email && (
                    <Text
                        css={{
                            textDecoration: participantShape.isSuspended ? 'line-through' : 'none',
                            color: '$neutral05',
                            fontSize: '11px',
                            fontWeight: '500',
                        }}
                        ellipsed
                    >
                        {email}
                    </Text>
                )}
            </FlexColumn>
        </StyledUserRow>
    )
}
export { StyledRolePill as RolePill }

export default UserRow
