import React from 'react'
import { FlexColumn, Text } from '../components-reusable'

const EmptyIcon = () => (
    <svg width="59" height="72" viewBox="0 0 59 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M46.6 1V12.6667H58M46.6 1L58 12.6667M46.6 1H1V71H58V12.6667M21.2635 18.6167C11.5022 21.4167 43.0517 51.6188 45.175 41.5417C47.2982 31.4646 7.65475 45.7563 14.6657 52.7417C21.6767 59.7271 31.0247 15.8896 21.2635 18.6167Z"
            stroke="url(#paint0_linear_21128_77117)"
            strokeWidth="1.51562"
        />
        <defs>
            <linearGradient
                id="paint0_linear_21128_77117"
                x1="31.7266"
                y1="-75.6667"
                x2="-50.1644"
                y2="-49.4104"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#5D81FF" />
                <stop offset="1" stopColor="#B85DFF" />
            </linearGradient>
        </defs>
    </svg>
)

const PDFCanvasSourceEditEmptyView = () => (
    <FlexColumn css={{ height: '100%', justifyContent: 'center', alignItems: 'center', gap: 12 }}>
        <EmptyIcon />
        <Text
            css={{
                fontSize: '20px',
                lineHeight: '21px',
                fontWeight: 600,
                marginBottom: '4px',
                color: '$neutral04',
                mt: 24,
            }}
        >
            Your PDF drawings will appear here
        </Text>
        <Text
            css={{
                fontSize: '16px',
                lineHeight: '22px',
                fontWeight: 400,
                color: '$neutral05',
                width: 500,
                textAlign: 'center',
            }}
        >
            Upload PDF drawings using the file picker in the left panel, or click "Create Project" if you have no files
            to upload.
        </Text>
    </FlexColumn>
)

export default PDFCanvasSourceEditEmptyView
