/*
 * DrawRectangleMode
 *
 * Draw mode used to draw a rectangle on the map
 *
 * Type DrawRectangleModeState:
 *    rectangle  : DrawFeature<JsonFeature>
 *    startPoint : XYPoint?
 *    endPoint   : XYPoint?
 */

import BasicDrawMode from './basic-draw-mode.js'

const DrawRectangleMode = { ...BasicDrawMode }

/*
 * @sig onSetup :: {} -> DrawRectangleModeState
 */
DrawRectangleMode.onSetup = function () {
    const rectangle = this.newFeature({
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [[]],
        },
        properties: { annotationType: 'rectangle' },
    })
    this.addFeature(rectangle)
    this.clearSelectedFeatures()
    this.setActionableState({ trash: true })
    return { rectangle }
}

/*
 * @sig onTap :: (DrawRectangleModeState, Event) -> ()
 */
DrawRectangleMode.onTap = function (state, e) {
    if (state.startPoint) this.onMouseMove(state, e)
    this.onClick(state, e)
}

/*
 * @sig onClick :: (DrawRectangleModeState, Event) -> ()
 */
DrawRectangleMode.onClick = function (state, e) {
    if (state.startPoint && state.startPoint[0] !== e.lngLat.lng && state.startPoint[1] !== e.lngLat.lat) {
        state.endPoint = [e.lngLat.lng, e.lngLat.lat]
        this.finishDrawing(state)
    } else state.startPoint = [e.lngLat.lng, e.lngLat.lat]
}

/*
 * Called on mouse move, used to handle proportional shaping of the rectangle once the startPoint is defined
 * @sig onMouseMove :: (DrawRectangleModeState, Event) -> ()
 */
DrawRectangleMode.onMouseMove = function (state, e) {
    // if startPoint, update the feature coordinates, using the bounding box concept
    // we are simply using the startingPoint coordinates and the current Mouse Position
    // coordinates to calculate the bounding box on the fly, which will be our rectangle
    if (state.startPoint) {
        state.rectangle.updateCoordinate('0.0', state.startPoint[0], state.startPoint[1]) // minX, minY - the starting point
        state.rectangle.updateCoordinate('0.1', e.lngLat.lng, state.startPoint[1]) // maxX, minY
        state.rectangle.updateCoordinate('0.2', e.lngLat.lng, e.lngLat.lat) // maxX, maxY
        state.rectangle.updateCoordinate('0.3', state.startPoint[0], e.lngLat.lat) // minX,maxY
        state.rectangle.updateCoordinate('0.4', state.startPoint[0], state.startPoint[1]) // minX,minY - ending point (equals to starting point)
    }
}

/*
 * @sig onStop :: (DrawRectangleModeState) -> ()
 */
DrawRectangleMode.onStop = function (state) {
    // check to see if we've deleted this feature
    if (this.getFeature(state.rectangle.id) === undefined) return

    // remove last added coordinate
    state.rectangle.removeCoordinate('0.4')
    if (state.rectangle.isValid()) this.map.fire('draw.create', { features: [state.rectangle.toGeoJSON()] })
}

/*
 * @sig toDisplayFeatures (DrawRectangleModeState, GeoJsonFeature, DisplayFunc) -> ()
 * DisplayFunc = GeoJsonFeature -> ()
 */
DrawRectangleMode.toDisplayFeatures = function (state, geojson, display) {
    const isActivePolygon = geojson.properties.id === state.rectangle.id
    geojson.properties.active = isActivePolygon ? 'true' : 'false'
    if (!isActivePolygon) return display(geojson)

    // Only render the rectangular polygon if it has the starting point
    if (!state.startPoint) return
    return display(geojson)
}

/*
 * @sig finishDrawing :: (DrawRectangleModeState) -> ()
 */
DrawRectangleMode.finishDrawing = function (state) {
    this.changeMode('select', { featureId: state.rectangle.id })
}

/*
 * @sig cancelDrawingAndDelete :: (DrawRectangleModeState) -> ()
 */
DrawRectangleMode.cancelDrawingAndDelete = function (state) {
    this.deleteFeature([state.rectangle.id])
    this.changeMode('idle')
}

export default DrawRectangleMode
