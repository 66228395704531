import PropTypes from 'prop-types'
import React from 'react'
import { keyframes, styled } from '../range-theme'

const dotsAnimation = keyframes({
    '0%': { transform: 'translateX(-30px)', opacity: 0 },
    '25%': { opacity: 1 },
    '50%': { transform: 'translateX(30px)', opacity: 0 },
    '100%': { opacity: 0 },
})

const StyledLoadingAnimationContainer = styled('div', {
    span: {
        display: 'inline-block',
        borderRadius: '100%',
        animation: `${dotsAnimation} 4s infinite`,
        animationTimingFunction: 'cubic-bezier(0.030, 0.615, 0.995, 0.415)',
        animationFillMode: 'both',

        '&.l-1': { animationDelay: '1s' },
        '&.l-2': { animationDelay: '0.8s' },
        '&.l-3': { animationDelay: '0.6s' },
        '&.l-4': { animationDelay: '0.4s' },
        '&.l-5': { animationDelay: '0.2s' },
        '&.l-6': { animationDelay: '0' },
    },
    variants: {
        variant: {
            primary: {
                span: {
                    background: '$pink03',
                    height: '8px',
                    width: '8px',
                    margin: '12px 2px',
                },
            },
            secondary: {
                span: {
                    background: '$secondary02',
                    height: '8px',
                    width: '8px',
                    margin: '12px 2px',
                },
            },
            tertiary: {
                span: {
                    background: 'white',
                    height: '8px',
                    width: '8px',
                    margin: '12px 2px',
                },
            },
            tertiarySmall: {
                span: {
                    background: '$neutral05',
                    height: '4px',
                    width: '4px',
                    margin: '3px 1px',
                },
            },
            button: {
                span: {
                    background: 'white',
                    height: '4px',
                    width: '4px',
                    margin: '3px 1px',
                },
            },
        },
    },
})

const LoadingDotsAnimation = ({ variant = 'tertiary', ...rest }) => (
    <StyledLoadingAnimationContainer data-cy="loading-dots" variant={variant} {...rest}>
        <span className="l-1" />
        <span className="l-2" />
        <span className="l-3" />
        <span className="l-4" />
        <span className="l-5" />
        <span className="l-6" />
    </StyledLoadingAnimationContainer>
)

LoadingDotsAnimation.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'tertiarySmall', 'button']),
}

export default LoadingDotsAnimation
