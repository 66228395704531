import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '../range-theme/index.js'
import { Flex, Text } from '../components-reusable/index.js'

const StyledGradientText = styled('span', {
    background: 'linear-gradient(249deg, #5D81FF 2.13%, #B85DFF 115.81%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
})

const FileUploadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="87" height="101" viewBox="0 0 87 101" fill="none">
        <g opacity="0.6">
            <g filter="url(#filter0_d_20221_151511)">
                <path
                    d="M10 17C10 9.8203 15.8203 4 23 4H47C57.4934 4 66 12.5066 66 23V60C66 64.4183 62.4183 68 58 68H18C13.5817 68 10 64.4183 10 60V17Z"
                    fill="var(--colors-neutral08)"
                />
                <path
                    d="M10.25 17C10.25 9.95837 15.9584 4.25 23 4.25H47C57.3553 4.25 65.75 12.6447 65.75 23V60C65.75 64.2802 62.2802 67.75 58 67.75H18C13.7198 67.75 10.25 64.2802 10.25 60V17Z"
                    stroke="var(--colors-neutral07)"
                    stroke-opacity="0.5"
                    stroke-width="0.5"
                />
            </g>
            <g filter="url(#filter1_d_20221_151511)">
                <path
                    d="M44 7.39231C44 5.51893 45.5187 4.00024 47.3921 4.00024H48.2379C58.0476 4.00024 66 11.9526 66 21.7624C66 23.5506 64.5504 25.0002 62.7621 25.0002H52.75C47.9175 25.0002 44 21.0827 44 16.2502V7.39231Z"
                    fill="url(#paint0_linear_20221_151511)"
                />
                <path
                    d="M44.25 7.39231C44.25 5.657 45.6568 4.25024 47.3921 4.25024H48.2379C57.9096 4.25024 65.75 12.0907 65.75 21.7624C65.75 23.4125 64.4123 24.7502 62.7621 24.7502H52.75C48.0556 24.7502 44.25 20.9447 44.25 16.2502V7.39231Z"
                    stroke="var(--colors-neutral08)"
                    stroke-opacity="0.91"
                    stroke-width="0.5"
                />
            </g>
        </g>
        <g filter="url(#filter2_bd_20221_151511)">
            <path
                d="M15 20C15 13.9249 19.9249 9 26 9H52C62.4934 9 71 17.5066 71 28V65C71 69.4183 67.4183 73 63 73H23C18.5817 73 15 69.4183 15 65V20Z"
                fill="var(--colors-neutral08)"
            />
            <path
                d="M15.25 20C15.25 14.0629 20.0629 9.25 26 9.25H52C62.3553 9.25 70.75 17.6447 70.75 28V65C70.75 69.2802 67.2802 72.75 63 72.75H23C18.7198 72.75 15.25 69.2802 15.25 65V20Z"
                stroke="var(--colors-neutral07)"
                stroke-opacity="0.5"
                stroke-width="0.5"
            />
        </g>
        <g filter="url(#filter3_d_20221_151511)">
            <path
                d="M49 12.3923C49 10.5189 50.5187 9.00024 52.3921 9.00024H53.2379C63.0476 9.00024 71 16.9526 71 26.7624C71 28.5506 69.5504 30.0002 67.7621 30.0002H57.75C52.9175 30.0002 49 26.0827 49 21.2502V12.3923Z"
                fill="url(#paint1_linear_20221_151511)"
            />
            <path
                d="M49.25 12.3923C49.25 10.657 50.6568 9.25024 52.3921 9.25024H53.2379C62.9096 9.25024 70.75 17.0907 70.75 26.7624C70.75 28.4125 69.4123 29.7502 67.7621 29.7502H57.75C53.0556 29.7502 49.25 25.9447 49.25 21.2502V12.3923Z"
                stroke="var(--colors-neutral08)"
                stroke-opacity="0.91"
                stroke-width="0.5"
            />
        </g>
        <g filter="url(#filter4_bd_20221_151511)">
            <path
                d="M21 22C21 17.5817 24.5817 14 29 14H58C68.4934 14 77 22.5066 77 33V70C77 74.4183 73.4183 78 69 78H29C24.5817 78 21 74.4183 21 70V22Z"
                fill="var(--colors-neutral08)"
            />
            <path
                d="M21.25 22C21.25 17.7198 24.7198 14.25 29 14.25H58C68.3553 14.25 76.75 22.6447 76.75 33V70C76.75 74.2802 73.2802 77.75 69 77.75H29C24.7198 77.75 21.25 74.2802 21.25 70V22Z"
                stroke="var(--colors-neutral07)"
                stroke-opacity="0.5"
                stroke-width="0.5"
            />
        </g>
        <g filter="url(#filter5_d_20221_151511)">
            <path
                d="M55 17.3923C55 15.5189 56.5187 14.0002 58.3921 14.0002H59.2379C69.0476 14.0002 77 21.9526 77 31.7624C77 33.5506 75.5504 35.0002 73.7621 35.0002H63.75C58.9175 35.0002 55 31.0827 55 26.2502V17.3923Z"
                fill="url(#paint2_linear_20221_151511)"
            />
            <path
                d="M55.25 17.3923C55.25 15.657 56.6568 14.2502 58.3921 14.2502H59.2379C68.9096 14.2502 76.75 22.0907 76.75 31.7624C76.75 33.4125 75.4123 34.7502 73.7621 34.7502H63.75C59.0556 34.7502 55.25 30.9447 55.25 26.2502V17.3923Z"
                stroke="var(--colors-neutral08)"
                stroke-opacity="0.91"
                stroke-width="0.5"
            />
        </g>
        <g filter="url(#filter6_d_20221_151511)">
            <path
                d="M46 82.0156V62.0156M46 62.0156L36 72.0156M46 62.0156L56 72.0156"
                stroke="url(#paint3_linear_20221_151511)"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_20221_151511"
                x="0"
                y="0"
                width="76"
                height="84"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20221_151511" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_20221_151511" result="shape" />
            </filter>
            <filter
                id="filter1_d_20221_151511"
                x="34"
                y="0.000244141"
                width="42"
                height="41"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20221_151511" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_20221_151511" result="shape" />
            </filter>
            <filter
                id="filter2_bd_20221_151511"
                x="5"
                y="5"
                width="76"
                height="84"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_20221_151511" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="effect1_backgroundBlur_20221_151511"
                    result="effect2_dropShadow_20221_151511"
                />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_20221_151511" result="shape" />
            </filter>
            <filter
                id="filter3_d_20221_151511"
                x="39"
                y="5.00024"
                width="42"
                height="41"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20221_151511" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_20221_151511" result="shape" />
            </filter>
            <filter
                id="filter4_bd_20221_151511"
                x="11"
                y="10"
                width="76"
                height="84"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_20221_151511" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend
                    mode="normal"
                    in2="effect1_backgroundBlur_20221_151511"
                    result="effect2_dropShadow_20221_151511"
                />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_20221_151511" result="shape" />
            </filter>
            <filter
                id="filter5_d_20221_151511"
                x="45"
                y="10.0002"
                width="42"
                height="41"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20221_151511" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_20221_151511" result="shape" />
            </filter>
            <filter
                id="filter6_d_20221_151511"
                x="24"
                y="56.0156"
                width="44"
                height="44"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20221_151511" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_20221_151511" result="shape" />
            </filter>
            <linearGradient
                id="paint0_linear_20221_151511"
                x1="55.8594"
                y1="-18.9998"
                x2="26.0717"
                y2="-6.71233"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5D81FF" />
                <stop offset="1" stop-color="#B85DFF" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_20221_151511"
                x1="60.8594"
                y1="-13.9998"
                x2="31.0717"
                y2="-1.71233"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5D81FF" />
                <stop offset="1" stop-color="#B85DFF" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_20221_151511"
                x1="66.8594"
                y1="-8.99976"
                x2="37.0717"
                y2="3.28767"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5D81FF" />
                <stop offset="1" stop-color="#B85DFF" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_20221_151511"
                x1="46.7812"
                y1="40.1109"
                x2="19.3471"
                y2="50.9131"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#5D81FF" />
                <stop offset="1" stop-color="#B85DFF" />
            </linearGradient>
        </defs>
    </svg>
)

/*
 * Dropzone UI that displays after user is trying to drop a file
 */
const CollaborationWindowFileDropzone = ({ maxFileSize }) => {
    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            css={{
                m: 16,
                flex: '1 1 100%',
                br: '12px',
                backgroundColor: '$neutral09',
                border: '1px dashed $primary04',
            }}
        >
            <FileUploadIcon />
            <Text css={{ color: '$neutral04' }}>
                Drop your <StyledGradientText>files</StyledGradientText> here
            </Text>
            <Text css={{ fs: '12px', color: '$neutral05', mt: '4px' }}>Max file size {maxFileSize}mb</Text>
        </Flex>
    )
}

CollaborationWindowFileDropzone.propTypes = {
    maxFileSize: PropTypes.number,
}

export default CollaborationWindowFileDropzone
