import { useNavigate } from 'react-router-dom'
import { selectionChanged } from '../../firebase/commands-2/index.js'

/*
 * Hook that exposes a way to select a collaboration and properly show it on the canvas.
 * It deals with navigating to correct URL and updates the map position and makes sure given
 * collaboration is selected properly in Redux so the rest of the app can work properly.
 */
export const useNavigateToCollaboration = () => {
    const navigateToCollaboration = collaborationId => {
        selectionChanged({ collaborationId, context: 'navigateToCollaboration' }, { navigate })
    }

    const navigate = useNavigate()
    return { navigateToCollaboration }
}
