import PropTypes from 'prop-types'
import React from 'react'
import { Button, FlexColumn, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import PageLoading from './PageLoading.js'

const StyledOverlay = styled(FlexColumn, {
    width: '100vw',
    height: '100vh',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$neutralOverlay',
    ai: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 999,
})

const StyledContent = styled(FlexColumn, {
    justifyContent: 'center',
    alignItems: 'center',
    bg: '$neutral10Alpha',
    backdropFilter: 'blur(12px) saturate(190%) contrast(50%) brightness(130%)',
    color: '$neutral04',
    width: '600px',
    height: '500px',
    border: '1px solid $neutral07',
    br: '6px',
    boxShadow: '0px 40px 40px #00000025',
    zIndex: 1000,
    gap: '24px',
})

const CreatingReportInfoModal = ({ onCancel }) => (
    <StyledOverlay>
        <StyledContent data-cy="create-report-info-modal">
            <PageLoading css={{ width: '148px', height: '168px', background: 'transparent' }} />
            <Text css={{ fw: 700, fs: '20px', lineHeight: '28px' }}>Creating Report</Text>
            <Text css={{ fw: 400, fs: '14px', lineHeight: '20px', color: '$neutral05' }}>
                This may take a few seconds so hold tight 😎
            </Text>
            <Button data-cy="create-report-cancel-button" variant="secondary" size="lg" onClick={onCancel}>
                Cancel
            </Button>
        </StyledContent>
    </StyledOverlay>
)

CreatingReportInfoModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
}

export default CreatingReportInfoModal
