import PropTypes from 'prop-types'
import React from 'react'
import {
    DropdownMenuWithTooltip,
    FlexRow,
    Icon,
    RadioGroup,
    RadioGroupItem,
    Text,
} from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledSortOrderLabel = styled(Text, {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const StyledSortRadioButton = styled(RadioGroupItem, {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    boxSizing: 'border-box',
    borderRadius: '6px',
    color: '$neutral05',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
    fontWeight: 400,
    gap: '8px',
    height: 32,
    justifyContent: 'space-between',
    minHeight: 32,
    paddingLeft: '10px',
    transitionDuration: '0.4s',

    '&:hover': {
        color: '$neutral03',
        backgroundColor: '$primary02',
    },

    // handle "checked" value of Radio button
    "&[data-state='checked']": {
        backgroundColor: '$primary02',
        color: '$neutral04',
    },
})

const NavigatorSortSelector = ({ navigatorSortSettings, updateNavigatorSortSettings }) => {
    const { sortOrder } = navigatorSortSettings
    const sortOrderText = sortOrder === 'recent' ? 'Recently Updated' : 'Alphabetical'
    const dropdownMenuCss = { display: 'flex', fd: 'column', gap: '8px' }
    const setSortOrder = order => updateNavigatorSortSettings({ ...navigatorSortSettings, sortOrder: order })

    return (
        <DropdownMenuWithTooltip.Root>
            <DropdownMenuWithTooltip.Trigger tooltipText="Sort Order" asChild>
                <FlexRow
                    css={{
                        gap: 4,
                        cursor: 'pointer',
                        p: '2px 6px 2px 6px',
                        transitionDuration: '0.4s',
                        br: 6,
                        ai: 'center',
                        '&:hover': { background: '$primary02' },
                    }}
                >
                    <Text>{sortOrderText}</Text>
                    <Icon iconSize="16px" name="chevronDown" />
                </FlexRow>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Content side="bottom" align="start" sideOffset={0} css={{ mt: 4 }}>
                <RadioGroup css={dropdownMenuCss} orientation="vertical" value={sortOrder} onValueChange={setSortOrder}>
                    <DropdownMenuWithTooltip.MenuItem>
                        <StyledSortRadioButton value="recent">
                            <StyledSortOrderLabel>Recently Updated</StyledSortOrderLabel>
                        </StyledSortRadioButton>
                    </DropdownMenuWithTooltip.MenuItem>
                    <DropdownMenuWithTooltip.MenuItem>
                        <StyledSortRadioButton value="alphabetical">
                            <StyledSortOrderLabel>Alphabetical</StyledSortOrderLabel>
                        </StyledSortRadioButton>
                    </DropdownMenuWithTooltip.MenuItem>
                </RadioGroup>
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Root>
    )
}

const navigatorSortSettingsPropTypes = {
    sortOrder: PropTypes.oneOf(['recent', 'alphabetical']).isRequired,
}

NavigatorSortSelector.propTypes = {
    navigatorSortSettings: PropTypes.shape(navigatorSortSettingsPropTypes).isRequired,
    updateNavigatorSortSettings: PropTypes.func.isRequired,
}

export default NavigatorSortSelector
