import React, { useMemo } from 'react'
import PDFCanvasSourceEdit from '../PDFCanvasSourceEdit'
import PDFCanvasSourceEditPanel from '../PDFCanvasSourceEditPanel'

const NewProjectPage2 = ({ getStoredData, onCancel, onCanvasDataUpdate, onPrevious, onSave }) => {
    const storedCanvasEditorData = useMemo(getStoredData, [])

    const renderSidePanel = opts => (
        <PDFCanvasSourceEditPanel.ProjectWizard onCancel={onCancel} onPrevious={onPrevious} {...opts} />
    )

    return (
        <PDFCanvasSourceEdit
            items={storedCanvasEditorData.canvases}
            itemUpdateLabel="Replace Sheet"
            onSave={onSave}
            onDataUpdate={onCanvasDataUpdate}
            order={storedCanvasEditorData.canvasOrder}
            renderSidePanel={renderSidePanel}
        />
    )
}

export default NewProjectPage2
