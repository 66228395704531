/*
 * Tooltip: facade that essentially just styles @radix-ui/react-tooltip
 *
 * The structure of a radix-ui Tooltip
 *
 *    <Tooltip.Provider>                   <-- global values related to duration (Provider is NOT required)
 *        <Tooltip.Root>                   <-- open, onOpenChange, delayDuration
 *            <Tooltip.Trigger />          <-- usually a button
 *            <Tooltip.Content>
 *                -- contents here --      <-- contents of the tooltip; eg. passed-in "tooltipText"
 *                <Tooltip.Arrow />        <-- this is the black triangle pointing away from the tooltip
 *            </Tooltip.Content>
 *        </Tooltip.Root>
 *    </Tooltip.Provider>
 *
 * The location of a tooltip is controlled by the side, align and sideOffset properties on Tooltip.Content
 *
 * Unfortunately, tooltips interact with DropdownMenus and Toolbars because all three have a "trigger" (button) element
 * and you'll end up with a button-in-a-button. radix-ui provides "asChild" as a way around this
 *
 * (see https://www.radix-ui.com/docs/primitives/overview/styling#changing-the-rendered-element) but it requires
 * careful management nonetheless
 *
 */

import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import PropTypes from 'prop-types'
import React from 'react'
import { RangeUITheme, styled } from '../range-theme/index.js'
import { Icon } from './index.js'
import Tooltip from './Tooltip.js'

/*
 * The content and behavior of the tooltip itself, including how it animates based on the data-state set by radix-ui
 */
// prettier-ignore
const StyledTooltipContent = Tooltip.Content

/*
 * Triangle pointing from a (visible) tooltip back to its trigger
 */
const StyledTooltipArrow = styled(Tooltip.Arrow, {
    fill: '$neutral03',
    boxShadow: RangeUITheme.shadows.lg,
})

const DISTANCE_TO_ARROW_TIP = 8 // space from button to triangle on Tooltip in pixels
const DELAY_DURATION = 300

const StyledDropdownMenuTrigger = styled(DropdownMenuTrigger, {
    all: 'unset',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',

    color: '$neutral05',
    borderRadius: 6,

    '&:hover': {
        color: '$neutral04',
        backgroundColor: '$neutral07',
    },

    variants: {
        size: {
            '28px': { width: 28, height: 28 },
            '32px': { width: 32, height: 32 },
        },
    },
})

/*
 * This is a specific implementation of a Tooltip that wraps a button that will display a DropdownMenu when clicked.
 *
 * The Tooltip trigger and the DropdownMenu trigger interact requiring the use of asChild to get right
 * The content of the Tooltip is always just the tooltipText and the icon is specified by iconName
 */
const IconButtonWithTooltipForDropdownMenu = ({ dataCy, iconName, tooltipText, side, size }) => (
    <Tooltip.Provider>
        <Tooltip.Root delayDuration={DELAY_DURATION}>
            <Tooltip.Trigger asChild>
                <StyledDropdownMenuTrigger data-cy={dataCy} size={size}>
                    <Icon iconSize="28px" name={iconName} />{' '}
                </StyledDropdownMenuTrigger>
            </Tooltip.Trigger>

            <StyledTooltipContent side={side} align={'center'} offset={20} sideOffset={DISTANCE_TO_ARROW_TIP}>
                {tooltipText}
                <StyledTooltipArrow />
            </StyledTooltipContent>
        </Tooltip.Root>
    </Tooltip.Provider>
)

// prettier-ignore
IconButtonWithTooltipForDropdownMenu.propTypes = {
    dataCy       : PropTypes.string,
    iconName     : PropTypes.string.isRequired,
    tooltipText  : PropTypes.string.isRequired,
    side         : PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    size         : PropTypes.oneOf(['28px', '32px']),
}

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

export default IconButtonWithTooltipForDropdownMenu
