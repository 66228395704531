import { styled } from '../range-theme/index.js'

/*
 * Minimal layout element
 */
const Box = styled('div', {
    boxSizing: 'border-box',
    minWidth: '0',
})

const RowBox = styled('div', {
    boxSizing: 'border-box',
    minWidth: '0',
    display: 'flex',
    flexDirection: 'row',
})

export { Box, RowBox }
