import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Box, Button, Checkbox, FlexColumn, FlexRow, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const ModalContainer = ({ children, ...restProps }) => {
    return (
        <Box
            onClick={e => {
                e.preventDefault()
                e.stopPropagation() // Needed to not bubble click action so overlay click doesn't trigger
            }}
            {...restProps}
        >
            {children}
        </Box>
    )
}

const StyledModalContainer = styled(ModalContainer, {
    bg: '$neutral09Alpha',
    backdropFilter: 'blur(12px) saturate(190%) contrast(50%) brightness(130%)',
    color: '$neutral04',
    width: '500px',
    border: '1px solid $neutral07',
    br: '6px',
    boxShadow: '0px 6px 10px #00000010',
    zIndex: 1000,
})

const StyledModalActions = styled(Box, {
    width: '100%',
    height: '72px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '24px',
    paddingLeft: '24px',
    bt: '1px solid $neutral07',
    display: 'flex',
    gap: '16px',
    justifyContent: 'right',
})

const StyledPrimaryModalLabel = styled(Text, {
    fs: '20px',
    fw: 700,
})

const StyledSecondaryModalLabel = styled(Text, {
    fs: '14px',
    color: '$neutral05',
    lh: '1.4',
})

const StyledOverlay = styled(FlexColumn, {
    width: '100vw',
    height: '100vh',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$neutralOverlay',
    ai: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 999,
})

/*
 * Bulk delete tasks component
 */

const ListViewDeleteTasksModal = ({ onDelete, onCancel }) => {
    const [confirmed, setConfirmed] = useState()

    const primaryLabel = 'Are you sure you want to delete the selected tasks?'
    const secondaryLabel =
        'Deleting these tasks will also delete all associated content. This includes comments, photos, documents, history, etc. This cannot be undone.'

    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer data-cy="list-view-delete-tasks-modal">
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel css={{ paddingBottom: '12px' }}>{primaryLabel}</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel css={{ paddingBottom: '24px' }}>
                        {secondaryLabel}
                    </StyledSecondaryModalLabel>
                    <FlexRow css={{ gap: '12px', alignItems: 'center' }}>
                        <Checkbox
                            variant="destructive"
                            size="lg"
                            defaultChecked={false}
                            checked={confirmed}
                            onCheckedChange={setConfirmed}
                        />
                        <StyledSecondaryModalLabel>
                            Confirm you would like to delete tasks. This can't be undone.
                        </StyledSecondaryModalLabel>
                    </FlexRow>
                </Box>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="destructive" size="lg" onClick={onDelete} disabled={!confirmed}>
                        Delete Tasks
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

ListViewDeleteTasksModal.displayName = 'ListViewDeleteTasksModal'

ListViewDeleteTasksModal.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default ListViewDeleteTasksModal
