import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Box, Button, Checkbox, FlexColumn, FlexRow, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledOuterBox = styled(Box, {
    bg: '$neutral10',
    color: '$neutral04',
    width: '500px',
    border: '1px solid $neutral08',
    br: '6px',
    boxShadow: '0px 6px 10px #00000010',
    zIndex: 1000,
})

const StyledModalActions = styled(Box, {
    width: '100%',
    height: '72px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '24px',
    paddingLeft: '24px',
    bt: '1px solid $neutral07',
    display: 'flex',
    gap: '16px',
    justifyContent: 'right',
})

const StyledPrimaryModalLabel = styled(Text, {
    fs: '20px',
    fw: 700,
})

const StyledSecondaryModalLabel = styled(Text, {
    fs: '14px',
    color: '$neutral05',
    lh: '1.4',
})

const StyledOverlay = styled(FlexColumn, {
    width: '100vw',
    height: '100vh',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$neutralOverlay',
    ai: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 100,
})

const BaseTagManagerDeleteTagModal = ({ onCancel, onDelete, primaryLabel, secondaryLabel, confirmLabel }) => {
    const [confirmed, setConfirmed] = useState()
    return (
        <StyledOverlay>
            <StyledOuterBox>
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel css={{ paddingBottom: '12px' }}>{primaryLabel}</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel css={{ paddingBottom: '24px' }}>
                        {secondaryLabel}
                    </StyledSecondaryModalLabel>
                    <FlexRow css={{ gap: '12px', alignItems: 'center' }}>
                        <Checkbox
                            variant="destructive"
                            size="lg"
                            defaultChecked={false}
                            checked={confirmed}
                            onCheckedChange={setConfirmed}
                        />
                        <StyledSecondaryModalLabel>{confirmLabel}</StyledSecondaryModalLabel>
                    </FlexRow>
                </Box>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="destructive" size="lg" onClick={onDelete} disabled={!confirmed}>
                        Delete Tag
                    </Button>
                </StyledModalActions>
            </StyledOuterBox>
        </StyledOverlay>
    )
}

/*
 * Delete single tag component
 */
const TagManagerDeleteSingleTagModal = ({ instanceCount, onDelete, onCancel }) => {
    const primaryLabel = 'Are you sure you want to delete this tag?'
    const secondaryLabel = `This tag is being used ${instanceCount} times. It will be removed from everywhere it’s being used.`
    const confirmLabel = 'Confirm you would like to delete this tag.'

    return (
        <BaseTagManagerDeleteTagModal
            onCancel={onCancel}
            onDelete={onDelete}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            confirmLabel={confirmLabel}
        />
    )
}

TagManagerDeleteSingleTagModal.displayName = 'TagManagerDeleteSingleTagModal'

TagManagerDeleteSingleTagModal.propTypes = {
    instanceCount: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

/*
 * Delete multiple tags component
 */

const TagManagerDeleteMultipleTagsModal = ({ onDelete, onCancel, instanceCount }) => {
    const primaryLabel = 'Are you sure you want to delete the selected tags?'
    const secondaryLabel = `The selected tags are being used ${instanceCount} times. They will be removed from everywhere they're being used.`
    const confirmLabel = 'Confirm you would like to delete these tags.'

    return (
        <BaseTagManagerDeleteTagModal
            onCancel={onCancel}
            onDelete={onDelete}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            confirmLabel={confirmLabel}
        />
    )
}

TagManagerDeleteMultipleTagsModal.displayName = 'TagManagerDeleteMultipleTagsModal'

TagManagerDeleteMultipleTagsModal.propTypes = {
    instanceCount: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export { TagManagerDeleteSingleTagModal, TagManagerDeleteMultipleTagsModal }
