/*
 * Admin Page: Account settings panel
 */
import { User } from '@range.io/basic-types'
import React, { useCallback, useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useBooleanState } from '../components-reusable/hooks/index.js'
import useLocalStorage from '../components-reusable/hooks/useLocalStorage.js'
import { Avatar, Box, Button, Flex, FlexRow, Icon, Select, Switch, Text } from '../components-reusable/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { UserChangedCommand } from '../firebase/commands/user-changed-command.js'
import { getKnockPreferences, setKnockPreferences } from '../knock/knock.js'
import { RangeUITheme } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import * as Segment from '../segment/segment.js'
import AdminNavigationPanel, { AdminNavigationType } from './AdminNavigationPanel.js'
import AdminOrganizationsPanel from './AdminOrganizationsPanel.js'
import Link from './Link.js'
import { EditUserNameModal, EditUserRoleModal } from './Modal.js'
import ImageUpload from './ImageUpload.js'

// Shared CSS
const topTableRowCss = {
    display: 'grid',
    gridTemplateColumns: '260px 1fr 2fr',
    ai: 'center',
    h: '70px',
}

const middleTableRowCss = {
    ...topTableRowCss,
    bt: '1px solid $neutral07',
}

const buttonIconCss = {
    minWidth: '16px',
    maxWidth: '16px',
}

const tableColumn1Css = { marginLeft: '24px', color: '$neutral05' }

const helperTextCss = {
    color: '$neutral05',
    fontStyle: 'italic',
    marginLeft: 'auto',
    marginTop: 8,
    marginBottom: 8,
}

// const instructionCss = { marginLeft: 'auto', color: '$neutral05', fontSize: '12px', fontStyle: 'italic', pr: '16px' }

/*
 * Account name row
 */
const AccountName = ({ user }) => {
    const onUpdate = (firstName, lastName) => {
        runCommand(UserChangedCommand.Outbound(user.id, { firstName, lastName }))
        closeModal()
    }

    const { get: showModal, set: openModal, unset: closeModal } = useBooleanState(false)
    const { firstName, lastName } = user
    const { runCommand } = useCommandHistory()

    const buttonsCss = { marginRight: '16px', background: '$neutral10', width: 110, marginLeft: 'auto' }
    return (
        <Box css={topTableRowCss}>
            <Text css={tableColumn1Css}>Name</Text>
            <Text css={{ marginLeft: '16px', color: '$neutral04' }}>
                {firstName} {lastName}
            </Text>
            {showModal() && <EditUserNameModal user={user} onCancel={closeModal} onUpdate={onUpdate} />}
            <Button onClick={openModal} css={buttonsCss} variant="secondary" size="lg">
                <Icon name="edit" css={buttonIconCss} />
                <Text>Update</Text>
            </Button>
        </Box>
    )
}

/*
 * User role
 */
const AccountRole = ({ user }) => {
    const onUpdate = role => {
        runCommand(UserChangedCommand.Outbound(user.id, { role }))
        closeModal()
    }

    const { get: showModal, set: openModal, unset: closeModal } = useBooleanState(false)
    const { role } = user
    const { runCommand } = useCommandHistory()

    const buttonCss = { marginRight: '16px', background: '$neutral10', width: 110, marginLeft: 'auto' }
    return (
        <Box css={middleTableRowCss}>
            <Text css={tableColumn1Css}>Job Title</Text>
            <Text css={{ marginLeft: '16px', color: '$neutral04' }}>{role}</Text>
            {showModal() && <EditUserRoleModal user={user} onCancel={closeModal} onUpdate={onUpdate} />}
            <Button onClick={openModal} css={buttonCss} variant="secondary" size="lg">
                <Icon name="edit" css={buttonIconCss} />
                <Text>Update</Text>
            </Button>
        </Box>
    )
}

/*
 * Account profile avatar row
 */
const AccountProfileAvatar = ({ user, onUploadNewProfilePicture, onClearProfilePicture }) => {
    const { avatarUrl } = user
    const name = User.fullName(user)

    const css = { marginLeft: '16px' }

    return (
        <Box css={middleTableRowCss}>
            <Text css={tableColumn1Css}>Profile Image</Text>
            <FlexRow css={{ gap: 8, alignItems: 'center', flexWrap: 'wrap' }}>
                <Avatar css={css} size="32" color="gray" url={avatarUrl} fallbackText={name[0]} tooltipText={name} />
                {avatarUrl && (
                    <Button onClick={onClearProfilePicture} css={buttonIconCss} variant="no-appearance" size="lg">
                        <Text>Remove</Text>
                    </Button>
                )}
            </FlexRow>

            <FlexRow css={{ justifySelf: 'flex-end', alignItems: 'center', gap: '16px' }}>
                <Text css={helperTextCss}>
                    Used to represent your profile throughout the Range app. JPEG or PNG, 5mb limit.
                </Text>

                <ImageUpload addNewImage={onUploadNewProfilePicture} maxWidth={96} maxHeight={96} />
            </FlexRow>
        </Box>
    )
}

/*
 * Account email row
 */
const AccountEmail = ({ user }) => {
    return (
        <Box css={middleTableRowCss}>
            <Text css={tableColumn1Css}>Email</Text>
            <Text css={{ marginLeft: '16px', color: '$neutral04' }}>{user.email}</Text>
        </Box>
    )
}

/*
 * Account theme row
 */
const AccountTheme = ({ colorTheme, colorThemes, onThemeChange }) => {
    const themeSelectOptions = Object.values(colorThemes)?.map(el => {
        return { id: el, name: el }
    })
    return (
        <Box css={middleTableRowCss}>
            <Text css={tableColumn1Css}>UI Color Theme</Text>
            <Text css={{ marginLeft: '16px', color: '$neutral04', textTransform: 'capitalize' }}>{colorTheme}</Text>
            <Select
                options={themeSelectOptions}
                value={colorTheme}
                onValueChange={onThemeChange}
                capitalize
                css={{
                    marginRight: '16px',
                    lineHeight: '30px',
                    justifyContent: 'space-between',
                    display: 'flex',
                    height: 40,
                    background: '$neutral10',
                    width: 110,
                    marginLeft: 'auto',
                }}
            />
        </Box>
    )
}

/*
 * Account email notification row
 */

const AccountEmailNotification = () => {
    const setEmailNotificationPreferences = async value => setKnockPreferences(dispatch, getState, value)

    const { dispatch, getState } = useStore()
    const preferencesSet = useSelector(ReduxSelectors.notificationPreferences)
    const shouldAllowEmailNotifications = preferencesSet?.channel_types?.email

    // download Knock preferences into store into Redux at the start
    useEffect(() => {
        const p = async () => await getKnockPreferences(dispatch, getState)
        p()
    }, [])

    const helperTextCss = {
        color: '$neutral05',
        fontStyle: 'italic',
        marginLeft: 'auto',
        marginTop: 8,
        marginBottom: 8,
    }
    return (
        <Box css={middleTableRowCss}>
            <Text css={tableColumn1Css}>Email Notifications</Text>
            <Text css={{ marginLeft: '16px', color: '$neutral04', textTransform: 'capitalize' }}>
                {shouldAllowEmailNotifications ? 'Enabled' : 'Disabled'}
            </Text>
            <FlexRow css={{ alignItems: 'center', marginRight: 16, gap: 16, justifyContent: 'end' }}>
                <Text css={helperTextCss}>
                    Opt in or out of email notifications sent to you about activity in a Range project.
                </Text>
                <Switch
                    variant="filter"
                    checked={shouldAllowEmailNotifications}
                    onCheckedChange={setEmailNotificationPreferences}
                />
            </FlexRow>
        </Box>
    )
}

const AdminViewAccountSettings = () => {
    const user = useSelector(ReduxSelectors.selectedUser)
    const organizations = useSelector(ReduxSelectors.organizationsAsArray)
    const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)
    const [colorTheme, setColorTheme] = useLocalStorage('rangeTheme', 'system')
    const { runCommand } = useCommandHistory()

    const header2Css = { fs: '32px', fw: '700', fg: '$neutral04', lh: '40px' }
    const tableCss = { mt: '40px', border: '1px solid $neutral07', br: '6px', bg: '$neutral09' }

    const handleThemeChange = useCallback(themeName => {
        RangeUITheme.changeColorMode(themeName)
        setColorTheme(themeName)
    }, [])

    const handlePrefersDarkColorScheme = useCallback(e => {
        RangeUITheme.changeColorMode('system')
    }, [])

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    React.useEffect(() => {
        if (colorTheme === RangeUITheme.colorThemes.system)
            // listen for prefers-color-scheme changes
            mediaQuery.addEventListener('change', handlePrefersDarkColorScheme)
        else {
            // remove prefers-color-scheme change listener
            mediaQuery.removeEventListener('change', handlePrefersDarkColorScheme)
        }

        handleThemeChange(colorTheme)
        return () => {
            mediaQuery.removeEventListener('change', handlePrefersDarkColorScheme)
        }
    }, [colorTheme])

    const handleUploadNewProfilePicture = async fileURI => {
        runCommand(UserChangedCommand.Outbound(user.id, { avatarUrl: fileURI }))
    }

    const handleClearProfilePicture = async () => {
        runCommand(UserChangedCommand.Outbound(user.id, { avatarUrl: null }))
    }

    Segment.sendPage('account settings', '', {})

    return (
        <Flex css={{ w: '100vw', h: '100vh', bg: '$neutral10' }}>
            <AdminOrganizationsPanel
                relativePath="account"
                organizations={organizations}
                selectedOrganizationId={selectedOrganization?.id}
            />
            <AdminNavigationPanel selectedPanel={AdminNavigationType.ACCOUNT} />
            <Box css={{ flexGrow: 3 }}>
                {user && (
                    <Flex direction="column" css={{ m: '64px 42px auto 42px' }}>
                        {/* Account Settings */}
                        <Text css={header2Css}>Account Settings</Text>
                        <Box css={tableCss}>
                            <AccountName user={user} />
                            <AccountRole user={user} />
                            <AccountEmail user={user} />
                            <AccountProfileAvatar
                                user={user}
                                onUploadNewProfilePicture={handleUploadNewProfilePicture}
                                onClearProfilePicture={handleClearProfilePicture}
                            />
                            <AccountEmailNotification user={user} />
                            <AccountTheme
                                colorTheme={colorTheme}
                                colorThemes={RangeUITheme.colorThemes}
                                onThemeChange={setColorTheme}
                            />
                        </Box>
                        <FlexRow css={{ color: '$neutral05', gap: 4, mt: 16, fs: 16, fw: 500 }}>
                            If you would like to permanently delete your Range account, please email
                            <Link href="mailto:support@range.io">support@range.io</Link>
                        </FlexRow>
                    </Flex>
                )}
            </Box>
        </Flex>
    )
}

export default AdminViewAccountSettings
