/*
 * A container component for list that allows scrolling and styles it appropriately.
 * Does virtualization of elements, only putting those elements into the DOM that should
 * be rendered or are withing a small window to allow smooth scrolling.
 *
 * Wraps the virtualized list into a ScrollArea for consistent styling and browser behaviour.
 * Allows overriding the styling if needed.
 *
 * Requires that the user provides array of objects to render and a render function
 * (this is not a HOC that wraps it's children into a component!)
 */

import React, { forwardRef, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import ScrollArea from './ScrollArea'

/*
 * Given an array of objects and a function to render a single list item
 * renders a scrollable list with virtualization. Allows scroll position restoration
 * through `restoreStateFrom` prop (it's a result of Virtuoso getState)
 * @sig ScrollableList :: ({children: node, css: CSSObject, data: Array, renderDataItem: Function, restoreStateFrom: ScrollState}) -> ReactComponent
 */
const ScrollableList = forwardRef(function ScrollableList(
    { children, css, data, renderDataItem, restoreStateFrom },
    ref
) {
    const [scrollParent, setScrollParent] = useState()

    return (
        <ScrollArea css={css} ref={setScrollParent}>
            {children}
            <Virtuoso
                data={data}
                itemContent={(index, item) => renderDataItem(item, index)}
                customScrollParent={scrollParent ?? undefined}
                ref={ref}
                restoreStateFrom={restoreStateFrom}
            />
        </ScrollArea>
    )
})

export default ScrollableList
