import { v4 } from 'uuid'

const mark = name => {
    const id = v4().replace('-.*', '')
    const start = `start ${id}`
    const end = `end ${id}`

    performance.mark(start)

    return () => {
        performance.mark(end)
        performance.measure(name, start, end)
    }
}

export { mark }
