import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Icon from '../components-reusable/Icon.js'
import {
    Box,
    Flex,
    FlexColumn,
    FlexRow,
    LoadingDotsAnimation,
    RadioGroup,
    RadioGroupItem,
    Text,
    Tooltip,
} from '../components-reusable/index.js'
import { QrCode } from '../components-reusable/QrCode.js'
import { attemptSignOut } from '../firebase/authentication-helpers.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import { KnockNotificationDropdown, OnlyOrganizationItemsCell } from './KnockNotificationDropdown.js'
import Link from './Link.js'
import OrganizationIcon from './OrganizationIcon'
import { AdminMenuButtonSupport } from './pages/SharedComponents.js'
import UserMenuDropdown from './UserMenuDropdown'

/*
 * Panel showing logo, name, member count and invite member button
 */
const OrganizationSummaryPanel = () => {
    const organization = useSelector(ReduxSelectors.selectedOrganization)

    // Consider only participants visible to logged in user, meaning non-admin users will see only those members, that they are actually allowed to see.
    // At the moment these are all users that the logged in user shares projects access with.
    const orgParticipants = useSelector(ReduxSelectors.organizationParticipantsAsArray)

    // Ensure suspended users are not count as active ones.
    const activeParticipantsCount = useMemo(() => {
        if (!orgParticipants) return 0
        return Object.values(orgParticipants).filter(el => !el.isSuspended).length || 0
    }, [orgParticipants])

    // Check if organization data is still loading
    const isLoading = !organization

    return (
        <Box css={{ w: '100%', p: '12px', br: '6px', bg: '$neutral10', b: '1px solid $neutral07' }}>
            {isLoading ? (
                // Render loading animation inside the Box when data is loading
                <Flex css={{ alignItems: 'center', width: '100%', height: 81 }}>
                    <LoadingDotsAnimation variant="tertiarySmall" />
                </Flex>
            ) : (
                // Render organization data inside the Box when available
                <>
                    <FlexRow css={{ justifyContent: 'space-between' }}>
                        <OrganizationIcon src={organization.icon} name={organization.name} circle />
                        <Tooltip tooltipText="Notifications" side="bottom">
                            <Flex>
                                <KnockNotificationDropdown KnockItemCell={OnlyOrganizationItemsCell} />
                            </Flex>
                        </Tooltip>
                    </FlexRow>
                    <Text css={{ fs: '14px', fw: 700, mt: '8px', fg: '$neutral04' }}>{organization.name}</Text>
                    <Text css={{ fs: '12px', color: '$neutral05' }}>{`${activeParticipantsCount} members`}</Text>
                </>
            )}
        </Box>
    )
}

const adminMenuButtonCss = {
    w: '100%',
    paddingLeft: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'left',
    height: '40px',
    gap: '10px',
    transitionDuration: '0.4s',
    color: '$neutral04',
    display: 'flex',
    flexDirection: 'row',
    ai: 'center',
    cursor: 'pointer',
    br: '6px',
    fs: '14px',
    border: '1px solid transparent',
    boxShadow: '0px 0px 0px #5D81FF50',

    '&:hover': {
        backgroundColor: '$primary02',
        border: '1px solid $primary03',
        boxShadow: '6px 6px 2px #8B8F9D20',
    },

    '&:active': {
        backgroundColor: '$primary02',
        border: '1px solid $primary03',
        boxShadow: 'none',
    },

    '& svg': {
        color: '$neutral05',
    },

    // handle "checked" value of Radio button
    "&[data-state='checked']": {
        backgroundColor: '$primary02',
        border: '1px solid $primary03',
        '& *': {
            fontWeight: '600',
        },
    },
}

const AdminMenuButton = styled(Box, adminMenuButtonCss)

/*
 * Radio group for Notifications Projects Members Settings
 */
const MenuItemAsRadioButton = ({ name, iconName, dataCy }) => {
    return (
        <RadioGroupItem data-cy={dataCy} value={name} asChild>
            <AdminMenuButton>
                <Icon iconSize="18px" name={iconName} />
                <Text>{name}</Text>
            </AdminMenuButton>
        </RadioGroupItem>
    )
}

const AdminNavigationType = {
    PROJECTS: 'Projects',
    MEMBERS: 'Members',
    SETTINGS: 'Settings',
    ACCOUNT: 'Account',
}

/*
 * AdminNavigationPanel: for a selected Organization show summary information and a selector for
 * Notifications, Projects, Members and Settings
 */
const AdminNavigationPanel = ({ selectedPanel }) => {
    const selectorCss = { display: 'flex', fd: 'column', mt: '16px', gap: '8px', w: '100%' }
    const user = useSelector(ReduxSelectors.selectedUser)
    const isEditingOrganizationAllowed = useSelector(ReduxSelectors.isUpdateAllowed('organization'))
    const navigate = useNavigate()

    const handleSelectionChange = newSelection => {
        if (newSelection === AdminNavigationType.PROJECTS) navigate('../projects')
        if (newSelection === AdminNavigationType.MEMBERS) navigate('../members')
        if (newSelection === AdminNavigationType.SETTINGS) navigate('../settings')
        if (newSelection === AdminNavigationType.ACCOUNT) navigate('../account')
    }

    const openInNewTab = url => {
        window.open(url, '_blank', 'noreferrer')
    }

    const navigateToAccountSettings = () => navigate('../account')
    return (
        <FlexColumn
            css={{
                minWidth: '211px',
                w: '211px',
                ai: 'center',
                p: '16px',
                bg: '$neutral09',
                brgt: '1px solid $neutral07',
            }}
        >
            <OrganizationSummaryPanel />
            {/* selector buttons for Notifications, Projects, Members and Settings */}
            <RadioGroup
                css={selectorCss}
                orientation="vertical"
                value={selectedPanel}
                onValueChange={handleSelectionChange}
            >
                <MenuItemAsRadioButton
                    dataCy="projects"
                    name={AdminNavigationType.PROJECTS}
                    iconName="projectDetails"
                />
                <MenuItemAsRadioButton dataCy="members" name={AdminNavigationType.MEMBERS} iconName="teamMembers" />
                {isEditingOrganizationAllowed && (
                    <MenuItemAsRadioButton dataCy="settings" name={AdminNavigationType.SETTINGS} iconName="settings" />
                )}
            </RadioGroup>
            <QrCode text="Get Mobile App" src="/GetMobileAppsQrCode.png" variant="admin" />
            <AdminMenuButtonSupport buttonText="Video Tutorials" variant="adminNav" />

            <Link
                href="mailto:support@range.io"
                variant="button-outline"
                css={{ mb: '12px', bg: '$neutral09', ...adminMenuButtonCss }}
            >
                <Icon css={{ pl: 2 }} iconSize="20px" name="support" />
                <Text css={{ w: '100%', pl: 4 }}>Email Support</Text>
            </Link>
            <UserMenuDropdown
                user={user}
                onLogOut={attemptSignOut}
                openInNewTab={openInNewTab}
                navigateToAccountSettings={navigateToAccountSettings}
                selectedPanel={selectedPanel}
                navigationTypes={AdminNavigationType}
            />
        </FlexColumn>
    )
}

export { AdminNavigationType }
export default AdminNavigationPanel
