/*
 * RangeStatus
 *
 * Defines status of the whole Range across all applications
 */
import { mergeRight, tagged } from '@range.io/functional'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const RangeStatus = tagged('RangeStatus', {
    isClosed: 'Boolean',
})

RangeStatus.update = (rangeStatus, changes) => RangeStatus.from(mergeRight(rangeStatus, changes))

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

RangeStatus.fromFirebase = o =>
    RangeStatus.from({
        isClosed: o.isClosed,
    })

export default RangeStatus
