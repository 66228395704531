/*
 * Define DrawMarkerMode and DrawTextMode both of which are, in fact, DrawSymbolModes
 * DrawSymbolMode just creates a Point when the user clicks and leaves the Point selected
 *
 * DrawMarkerMode differs from DrawTextMode only in the properties that are created:
 *
 * - DrawMarkerMode includes a property for 'icon'  (NOTE: this becomes 'user_icon' in the style)
 * - DrawTextMode includes a property for 'text' (NOTE: this becomes 'user_text' in the style)
 *
 * Each DrawXYXMode has its contents copied into a "ModeInterface" object by MapboxGLDraw,
 * and at runtime "this" will be that ModeInterface object.
 *
 * These Modes depend heavily on integration with the mapbox style in mapbox-gl-draw-style.js to work properly
 *
 * DrawSymbolState
 *
 *   point: The GeoJson Point where the symbol should be placed
 *
 * All these functions are impure because of the integration with ModeInterface
 */
import phrase from '../phrase-generator.js'

import BasicDrawMode from './basic-draw-mode.js'

const conversations = () => {
    const count = Math.floor(3 * Math.random())
    return count === 2 ? `\n${count} conversations` : ''
}

const photos = () => {
    const count = Math.floor(3 * Math.random())
    return count === 2 ? `\n${count} photos` : ''
}

/*
 * Basis for both DrawTextMode and DrawMarkerMode
 */
const DrawSymbolMode = { ...BasicDrawMode }
/*
 * User selected this mode; set everything up and create a new Draw Feature
 * @sig onSetup :: () -> DrawSymbolState
 */
DrawSymbolMode.onSetup = function () {
    const geojson = this.createGeoJson()
    const point = this.newFeature(geojson)

    this.addFeature(point)

    this.clearSelectedFeatures()
    this.setActionableState({ trash: true })

    return { point }
}

/*
 * User clicked: create a new GeoJSON Point and return to simple_select with the new object selected
 * @sig onClick :: (DrawSymbolState, Event) -> ()
 */
DrawSymbolMode.onClick = function (state, e) {
    state.point.updateCoordinate('', e.lngLat.lng, e.lngLat.lat)
    this.finishDrawing(state)
}

/*
 * Synonym for onClick for taps
 */
DrawSymbolMode.onTap = function (state, e) {
    return this.onClick(state, e)
}

/*
 * User canceled mode
 * @sig onStop :: DrawSymbolState -> ()
 */
DrawSymbolMode.onStop = function (state) {
    if (state.point.isValid()) this.map.fire('draw.create', { features: [state.point.toGeoJSON()] })
}

/*
 * Show every Feature on the map, marking "our" Point as selected
 * @sig toDisplayFeatures :: DrawSymbolState -> ()
 */
DrawSymbolMode.toDisplayFeatures = function (state, geojson, display) {
    const isActivePoint = geojson.properties.id === state.point.id
    geojson.properties.active = isActivePoint ? 'true' : 'false'
    display(geojson)
}

DrawSymbolMode.finishDrawing = function (state) {
    this.changeMode('select', { featureId: state.point.id })
}

DrawSymbolMode.cancelDrawingAndDelete = function (state) {
    this.deleteFeature([state.point.id])
    this.changeMode('idle')
}

/*
 * Extend DrawSymbolMode to create a GeoJsonFeature with text. The Point created includes a "text" field
 */
const DrawTextMode = Object.assign({}, DrawSymbolMode, {
    createGeoJson: () => ({
        type: 'feature',
        geometry: { type: 'Point', coordinates: [] },
        properties: {
            text: 'untitled',
            annotationType: 'text',
        },
    }),
})

/*
 * Extend DrawSymbolMode to create a GeoJsonFeature with an icon (and load the icons once, too)
 */
let iconsNotYetLoaded = true
const DrawMarkerMode = Object.assign({}, DrawSymbolMode, {
    loadImage: (map, name) => {
        const icons = {
            red: 'https://maps.google.com/mapfiles/kml/paddle/red-blank.png',
            blue: 'https://maps.google.com/mapfiles/kml/paddle/blu-blank.png',
        }

        map.loadImage(icons[name], (error, image) => {
            if (error) throw new Error(error)
            map.addImage(name, image)
        })
    },

    createGeoJson() {
        if (iconsNotYetLoaded && this.map) {
            this.loadImage(this.map, 'red')
            this.loadImage(this.map, 'blue')
            iconsNotYetLoaded = false
        }

        return {
            type: 'feature',
            geometry: { type: 'Point', coordinates: [] },
            properties: {
                icon: 'red',
                text: phrase(),
                conversations: conversations(),
                photos: photos(),
                annotationType: 'marker',

                /*
                Examples for use with example formats in mapbox-gl-draw-style.js
                fontSize: 1.0 + 0.5 * Math.random(),
                textColor: Math.random() > 0.5 ? 'green' : 'blue',
                textFont: 'Arial Unicode MS Bold',
                 */
            },
        }
    },
})

export { DrawMarkerMode, DrawTextMode }
