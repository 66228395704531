/*
 * See Readme
 */

import { Participant } from '@range.io/basic-types'
import { equals, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import reloadOrganization from './helpers/reload-organization.js'

// ---------------------------------------------------------------------------------------------------------------------
// ParticipantAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const OrganizationParticipantAddedCommand = taggedSum('OrganizationParticipantAddedCommand', {
    Inbound: { participant: 'Participant' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * To guarantee idempotency, we reload the ENTIRE organization when a participant is added
 */
const runInboundCommand = async (resources, command) => {
    const { organizationId, dispatch, getState } = resources
    const { participant } = command
    const state = getState()

    // if there is already an equal OrganizationParticipant, don't reload the entire Organization:
    // it can't have changed on the basis of this participant, since this participant hasn't changed
    const existingParticipant = ReduxSelectors.organizationParticipantWithId(state, participant.id)
    if (equals(existingParticipant, participant)) return

    const { organizations, projects } = await reloadOrganization(state, organizationId)
    dispatch(ReduxActions.organizationsAndProjectsWereChanged({ organizations, projects }))
}

const addOrganizationParticipantAddedCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        OrganizationParticipantAddedCommand,
        CommandPlayer({
            CommandType: OrganizationParticipantAddedCommand,
            Type: Participant,
            collectionPath: organizationId => `/organizations/${organizationId}/participants/`,
            runInboundCommand,
            addCommandToHistory,
            changeType: 'added',
            resourceKey: 'organizationId',
        })
    )
}

export { OrganizationParticipantAddedCommand, addOrganizationParticipantAddedCommandSingleton }
