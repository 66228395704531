/*
 * MultiSelectMode
 *
 * Map mode to handle state where multiple geometries are selected;
 * extends Mapbox GL Draw simple_select
 *
 * Type MultiSelectModeState : {}
 */

import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw-unminified.js'

import { initHoverState } from './hoverStateHandler.js'

const MultiSelectMode = { ...MapboxDraw.modes.simple_select }

/*
 * @sig onSetup :: {} -> MultiSelectModeState
 */
MultiSelectMode.onSetup = function (opts) {
    this.selectFeature(opts.featureId)
    return { hoverState: initHoverState({ doRender: id => this.doRender(id), getFeature: id => this.getFeature(id) }) }
}

/*
 * Makes sure if a more complicated feature (one having parent or children) is being selected
 * then also all connected feature get selected.
 * @sig selectFeature :: (FeatureId) -> ()
 */
MultiSelectMode.selectFeature = function (featureId) {
    const feature = this.getFeature(featureId)
    if (feature.properties?.arrowFeatureId) this.select([featureId, feature.properties?.arrowFeatureId])
    else if (feature.properties?.parentFeatureId) this.select([featureId, feature.properties?.parentFeatureId])
    else this.select(featureId)
}

/*
 * User clicked outside of any existing geometry - clear selection and go back to idle mode
 * @sig clickAnywhere :: (MultiSelectModeState) -> ()
 */
MultiSelectMode.clickAnywhere = function (state) {
    MapboxDraw.modes.simple_select.clickAnywhere.bind(this)(state)
    this.changeMode('idle')
}

/*
 * @sig onTrash :: (MultiSelectModeState) -> ()
 */
MultiSelectMode.onTrash = function (state) {
    this.deleteFeature(this.getSelectedIds())
    this.fireActionable()
    this.changeMode('idle')
}

/*
 * @sig clickOnFeature :: (MultiSelectModeState, Event) -> ()
 */
MultiSelectMode.clickOnFeature = function (state, e) {
    this.stopExtendedInteractions(state)
    const featureId = e.featureTarget.properties.id
    if (e.originalEvent.shiftKey) {
        const isFeatureSelected = this.isSelected(featureId)
        if (isFeatureSelected) {
            this.deselect(featureId)
            const selectedIdsAfterDeselect = this.getSelectedIds()
            if (selectedIdsAfterDeselect.length === 1)
                this.changeMode('select', { featureId: selectedIdsAfterDeselect[0] })
        } else this.selectFeature(featureId)
    } else {
        this.changeMode('select', { featureId })
    }
    this.doRender(featureId)
}

/*
 * @sig onKeyDown :: (MultiSelectModeState, Event) -> ()
 */
MultiSelectMode.onKeyDown = function (state, e) {
    // fire only if key is not hold continuously
    if (e.key === 'Shift' && !e.repeat) return this.map.fire('draw.changeCursor', { cursorName: 'hand' })
}

/*
 * @sig onKeyUp :: (MultiSelectModeState, Event) -> ()
 */
MultiSelectMode.onKeyUp = function (state, e) {
    if (e.key === 'Escape') return this.changeMode('idle')
    if (e.key === 'Shift') return this.map.fire('draw.resetCursor')
}

/*
 * @sig startOnActiveFeature :: (MultiSelectModeState, Event) -> ()
 */
MultiSelectMode.startOnActiveFeature = function (state, e) {
    MapboxDraw.modes.simple_select.startOnActiveFeature.bind(this)(state, e)
    this.map.fire('draw.changeCursor', { cursorName: 'move' })
}

/*
 * Called when a feature is dragged.
 * Because startOnActiveFeature is also called on click we have to move startDrag here.
 * @sig onDrag :: (MultiSelectModeState, Event) -> ()
 */
MultiSelectMode.onDrag = function (state, e) {
    if (state.dragMoving) this.map.fire('draw.startDrag')
    MapboxDraw.modes.simple_select.onDrag.bind(this)(state, e)
}

/*
 * @sig onTouchEnd :: (MultiSelectModeState, Event) -> ()
 * @sig onMouseUp :: (MultiSelectModeState, Event) -> ()
 */
MultiSelectMode.onTouchEnd = MultiSelectMode.onMouseUp = function (state, e) {
    if (state.dragMoving) {
        this.map.fire('draw.resetCursor')
        this.map.fire('draw.stopDrag')
    }
    MapboxDraw.modes.simple_select.onMouseUp.bind(this)(state, e)
}

/*
 * @sig toDisplayFeatures (MultiSelectModeState, GeoJsonFeature, DisplayFunc) -> ()
 * DisplayFunc = GeoJsonFeature -> ()
 */
MultiSelectMode.toDisplayFeatures = function (state, geojson, display) {
    geojson.properties.active = this.isSelected(geojson.properties.id) ? 'true' : 'false'
    geojson.properties.user_hover = state.hoverState.isGeoJsonHovered(geojson)
    display(geojson)
}

/*
 * @sig onMouseMove :: (MultiSelectModeState, Event) -> ()
 */
MultiSelectMode.onMouseMove = function (state, e) {
    state.hoverState.handleMouseMove(e)

    // TODO: commented to fix [#72]. Kept here for testing purposes if a potential fix is built.
    // if (isActiveFeature(e)) this.map.fire('draw.changeCursor', { cursorName: 'hover-selected' })
    // else this.map.fire('draw.resetCursor')
}

export default MultiSelectMode
