/*
 * TagName
 *
 * Every tag in a project is actually just an index to a TagName so we can rename a Tag quickly
 */
import { mergeRight, pick, tagged } from '@range.io/functional'
import { v4 } from 'uuid'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const TagName = tagged('TagName', {
    id  : StringTypes.Id,
    name: 'String',
    isHidden: 'Boolean?' // default is false: not hidden
})

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * @sig fromFirebase = {k:v} -> TagName
 */
TagName.fromFirebase = TagName.from
TagName.toFirebase = tagName => pick(['id', 'name', 'isHidden'], tagName)

/*
 * Create a new TagName merging an old one with values from changes
 * @sig update :: (TagName, {k:v}) -> TagName
 */
TagName.update = (upload, changes) => TagName.from(mergeRight(upload, changes))

TagName.fromName = name =>
    TagName.from({
        id: v4(),
        name,
        isHidden: false,
    })

export default TagName
