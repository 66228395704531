import { Collaboration, Geometry } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import { CollaborationShape } from '../../react-shapes/collaboration-shape.js'
import { v4 } from 'uuid'
import { Timestamp } from '@firebase/firestore'
import * as Segment from '../../segment/segment.js'

import { createGeometryFeatureAndCollaboration } from './index.js'

// ---------------------------------------------------------------------------------------------------------------------
// DuplicateCollaborationCommand
// ---------------------------------------------------------------------------------------------------------------------
const DuplicateCollaborationCommand = taggedSum('DuplicateCollaborationCommand', {
    Outbound: { collaborationShape: 'CollaborationShape', runCommand: 'Any' },
    // Inbound will be called on collaboration-added
})

/*
 * A DuplicateCollaborationCommand.Outbound has arrived from the UI.
 * It will:
 * - create new geometry
 * - create new feature
 * - create new collaboration
 * - duplicate the high level info from the collab (no comments, no uploads)
 */
const runOutboundCommand = async (resources, command) => {
    const { collaborationShape } = command
    const { dispatch, displayError, getState, projectId, mapboxMap } = resources

    if (!CollaborationShape.is(collaborationShape)) throw new Error('Expecting collaboration shape')

    try {
        const state = getState()
        const geometry = ReduxSelectors.selectedGeometries(state)[0] // create duplicate of the selected geometry
        const point = mapboxMap.project(geometry.coordinates) // get the original marker position relative to window, in px
        const newPoint = { x: point.x, y: point.y - 20 } // place the new marker 20px above the old one
        const newCoordinates = Object.values(mapboxMap.unproject(newPoint)) // calculate lat lng coordinates from the window position

        const newGeometry = {
            ...geometry,
            id: v4(), // change the id
            coordinates: newCoordinates,
        }

        // parse to Geometry
        const geojsonGeometry = Geometry.asGeoJson(newGeometry)
        const geometryType = Geometry.fromGeoJson(collaborationShape.canvasId, geojsonGeometry)

        const { name, isArchived, tags, assignee, description, dueDate, isCompleted, status, followers } =
            collaborationShape
        const newName = name?.length ? name + ' (copy)' : '(copy)'
        const dueDateTimestamp = dueDate ? Timestamp.fromDate(dueDate).toMillis() : undefined

        let newCollabData = {
            name: newName,
            isArchived,
            tags,
            followers,
        }
        if (collaborationShape.isTask)
            newCollabData = {
                ...newCollabData,
                assigneeId: assignee?.id,
                description,
                dueDateTimestamp,
                isCompleted,
            }

        const canvasId = ReduxSelectors.selectedCanvasId(state)
        const statusId = status?.id

        const args = {
            projectId,
            canvasId,
            geometry: geometryType,
            statusId,
            ...newCollabData,
        }

        // run createGeometryFeatureAndCollaboration() (new geometry, feature and collaboration will be created in the process)
        const collaborationId = await createGeometryFeatureAndCollaboration(resources, args, true)

        dispatch(
            ReduxActions.toastAdded({
                id: window.location.href,
                toastLabel: `${name.length ? name : 'pin'} has been duplicated`,
                severity: 'success',
                showUndo: false,
            })
        )

        const params = ReduxSelectors.paramsForCollaborationTrackEvent(getState())
        params && Segment.sendTrack('collaboration duplicated', collaborationId, params)
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        DuplicateCollaborationCommand,
        CommandPlayer({
            CommandType: DuplicateCollaborationCommand,
            Type: Collaboration,
            runInboundCommand: () => {},
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'duplicated',
            resourceKey: 'collaborationId',
        })
    )
}

export { DuplicateCollaborationCommand, addCommand }
