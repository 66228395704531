import React from 'react'
import { Button, FlexRow, Icon, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { MapLayerZoomControls } from './CanvasView.js'

const StyledBlendWithCutout = styled('div', {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    pointerEvents: 'none',
    display: 'flex',

    '&::after': {
        content: '',
        position: 'absolute',
        margin: '80px auto',
        left: '50%',
        translate: '-50%',
        height: 'calc(100% - 160px)',
        aspectRatio: '4 / 3',
        border: '3px solid $pink03',
        borderRadius: '6px',
        boxShadow: '0 0 0 100vmax rgba(0, 0, 0, 0.75)', // Large shadow to create the cutout effect
        boxSizing: 'border-box',
        pointerEvents: 'none',
    },
})

/*
 * An overlay component that display the guide for the canvas snippet, zoom controls and controls to take or abort taking the snippet.
 * Requires passing in the Mapbox map instance we render in the main component so we can re-use the same zoom controls as the main map in CanvasView.
 */
const CanvasSnippetOverlay = ({ mapboxMap, project, canvasSource, onCancel, onSave }) => {
    return (
        <StyledBlendWithCutout>
            <FlexRow css={{ gap: 12, margin: '12px 12px 0 auto', zIndex: 200, pointerEvents: 'all' }}>
                <Button css={{ w: 78, h: 40 }} variant="neutral" onClick={onCancel}>
                    <Text>Cancel</Text>
                </Button>
                <Button css={{ w: 140, h: 40 }} variant="accentPink" onClick={onSave}>
                    <Icon iconSize="18px" name="scissors" />
                    <Text>Save Snippet</Text>
                </Button>
            </FlexRow>
            <FlexRow
                css={{
                    zIndex: 200,
                    color: 'white',
                    width: '100%',
                    top: 32,
                    position: 'absolute',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 12,
                    fontSize: 18,
                    fontWeight: 700,
                }}
            >
                <Icon name="info" iconSize="24" />
                <Text>Pan and zoom to your desired viewport</Text>
            </FlexRow>
            <FlexRow css={{ zIndex: 200, pointerEvents: 'all' }}>
                <MapLayerZoomControls mapboxMap={mapboxMap} project={project} canvasSource={canvasSource} />
            </FlexRow>
        </StyledBlendWithCutout>
    )
}

export default CanvasSnippetOverlay
