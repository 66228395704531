/*
 * See Readme
 */

import { Project } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import { deleteProject } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// ProjectRemovedCommand
// ---------------------------------------------------------------------------------------------------------------------
const ProjectRemovedCommand = taggedSum('ProjectRemovedCommand', {
    Inbound: {
        project: 'Project',
    },
    Outbound: {
        projectId: StringTypes.Id,
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A ProjectRemovedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Project)
 */
// const runInboundCommand = (resources, command) => {
//     const { dispatch, getState } = resources
//     const project = command.project
// }

/*
 * A ProjectRemovedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { projectId } = command
    const { dispatch, displayError } = resources

    const showToast = ({ toastLabel, severity = 'success', id }) => {
        const toast = { id, severity, toastLabel, showUndo: false }
        dispatch(ReduxActions.toastAdded(toast))
    }

    try {
        await deleteProject(projectId)
        dispatch(ReduxActions.projectRemoved(projectId))
        showToast({ toastLabel: `Your project has been successfully deleted`, id: projectId })
    } catch (e) {
        showToast({ toastLabel: `Deleting the project failed`, severity: 'error', id: projectId })
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        ProjectRemovedCommand,
        CommandPlayer({
            CommandType: ProjectRemovedCommand,
            Type: Project,
            documentPath: projectId => `/projects/${projectId}`,
            runInboundCommand: () => {},
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'removed',
        })
    )
}

export { ProjectRemovedCommand, addCommand }
