import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../components-reusable/Button.js'
import { Flex, FlexRow } from '../components-reusable/Flex.js'
import { styled } from '../range-theme/index.js'
import { Box, Logo, LogoText } from '../components-reusable/index.js'
import Link from './Link.js'

const StyledNotFoundContainer = styled('div', {
    background: '$neutral09',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    backgroundImage: 'url(/grid_glow.svg)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
})

const StyledPrimaryText = styled('div', {
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: '60px',
    color: '$neutral04',
})

const StyledSecondaryText = styled('div', {
    fontSize: '18px',
    lineHeight: '24px',
    color: '$neutral05',
})

const StyledLogo = styled(FlexRow, {
    width: '100%',
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'start',
})

const NotFound = ({ beforeNavigate }) => {
    const navigate = useNavigate()

    const navigateTo = to => {
        beforeNavigate() // let the parent do something before you navigate
        navigate(to)
    }

    return (
        <StyledNotFoundContainer>
            <Flex
                justify="between"
                css={{
                    padding: '32px 40px 0',
                }}
            >
                <StyledLogo onClick={() => navigateTo('/')}>
                    <Logo />
                    <LogoText />
                </StyledLogo>
                <Link
                    data-cy="contact"
                    href="mailto:hello@range.io"
                    variant="button-outline"
                    css={{ minWidth: 120, height: '40px' }}
                >
                    Contact us
                </Link>
            </Flex>
            <Flex direction="column" css={{ maxWidth: '530px', flex: 1, marginLeft: '10%', zIndex: 2, gap: 24 }}>
                <Box>
                    <img src="/something_went_wrong.svg" />
                </Box>
                <StyledPrimaryText>
                    <span>Oops... something went wrong.</span>
                </StyledPrimaryText>
                <StyledSecondaryText>
                    Looks like we can’t find the page you’re looking for, or you don’t have access...
                    <br />
                    <br />
                    If the problem persists, please email{' '}
                    <Link
                        href="mailto:support@range.io"
                        css={{ color: '#5D81FF', fontWeight: 400, textDecoration: 'underline' }}
                    >
                        support@range.io
                    </Link>
                    .
                </StyledSecondaryText>
                <Button variant="primary" size="lg" css={{ width: '201px', mt: 16 }} onClick={() => navigateTo('/')}>
                    ← Back to your projects
                </Button>
            </Flex>
        </StyledNotFoundContainer>
    )
}

export default NotFound
