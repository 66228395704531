/*
 * See Readme
 */

import { TagName } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer, { simpleInboundChangedRunCommand, simpleOutboundChangedRunCommand } from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// TagNameChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const TagNameChangedCommand = taggedSum('TagNameChangedCommand', {
    Inbound: {
        tagName: 'TagName',
    },
    Outbound: {
        id: StringTypes.Id, // TagName id
        changes: 'Object', // each entry defines key/value of the existing TagName that changed
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A TagNameChangedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the TagName)
 */
const runInboundCommand = (resources, command) =>
    simpleInboundChangedRunCommand(resources, command, TagName, ReduxActions.tagNameAdded, command => command.tagName)

/*
 * A TagNameChangedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) =>
    await simpleOutboundChangedRunCommand(resources, command, TagName, ReduxActions.tagNameChanged, 'tagNames')

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        TagNameChangedCommand,
        CommandPlayer({
            CommandType: TagNameChangedCommand,
            Type: TagName,
            collectionPath: projectId => `/projects/${projectId}/tagNames/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
        })
    )
}

export { TagNameChangedCommand, addCommand }
