/*
 * Geometry
 *
 * A Geometry is a shape on a Map with only visual properties, like a GeoJSON Feature.
 */
import { mergeRight, tagged } from '@range.io/functional'
import { v4 } from 'uuid'
import { millisOrTimestampToDate } from '../helper/timestamp.js'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Geometry = tagged('Geometry', {
    id            : StringTypes.Id,
    canvasId      : StringTypes.Id,
    annotationType: /arrow|line|marker|photoMarker|polygon|polyline|rectangle|text/,
    coordinates   : 'Object',  // [lng, lat]|[[lng, lat]]|[[[lng, lat]]]
    archivedDate  : 'Object?', // Date object
    elevation     : 'Number?',
    
    collaborationId: StringTypes.OptionalId,
})

// TODO: remove Geometry renames
Geometry.prototype.renameFieldButLoudly('canvas', 'canvasId')

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Requires converting coordinates from JSON because Firestore doesn't allow nested arrays
 * @sig fromFirebase :: (k:v) -> Geometry
 */
// prettier-ignore
Geometry.fromFirebase = o => Geometry.from({
    id             : o.id,
    canvasId       : o.canvas,
    annotationType : o.annotationType,
    coordinates    : JSON.parse(o.coordinates),
    archivedDate   : millisOrTimestampToDate(o.archivedTimestamp),
    elevation      : o?.elevation,
    
    collaborationId: o.collaborationId,
})

// ---------------------------------------------------------------------------------------------------------------------
// Syntactic sugar for creating a specific kind of Collaboration object
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Convert a GeoJSON object to a Geometry
 * The GeoJSON *must* have an annotationType property
 * @sig :: GeoJSON -> Geometry
 */
Geometry.fromGeoJson = (canvas, geoJson) =>
    Geometry(
        geoJson.id || v4(),
        canvas,
        geoJson.properties.annotationType,
        geoJson.geometry.coordinates,

        // will have been flattened to a string
        geoJson.properties.archivedDate ? new Date(geoJson.properties.archivedDate) : undefined
    )

// :: A|[A]|[[A]]|[[[A]]]|... -> Number
const arrayDepth = a => (Array.isArray(a) ? 1 + arrayDepth(a[0]) : 0)

// prettier-ignore
const annotationTypeToGeoJsonType = (geometry) => {

    // arrow has two geometries and line and a head
    if (geometry.annotationType === 'arrow' && arrayDepth(geometry.coordinates) === 1) return 'Point'
    if (geometry.annotationType === 'arrow' && arrayDepth(geometry.coordinates) === 2) return 'LineString'

    if (geometry.annotationType === 'line')        return 'LineString'
    if (geometry.annotationType === 'marker')      return 'Point'
    if (geometry.annotationType === 'photoMarker') return 'Point'
    if (geometry.annotationType === 'polygon')     return 'Polygon'
    if (geometry.annotationType === 'polyline')    return 'LineString'
    if (geometry.annotationType === 'rectangle')   return 'Polygon'
    if (geometry.annotationType === 'text')        return 'Point'
}

/*
 * Convert a Geometry to a GeoJSON
 * The Geometry *must* have an annotationType property
 * @sig :: GeoJSON -> Geometry
 */
Geometry.asGeoJson = geometry => {
    return {
        id: geometry.id,
        type: 'Feature',
        geometry: {
            type: annotationTypeToGeoJsonType(geometry),
            coordinates: geometry.coordinates,
        },
        properties: {
            annotationType: geometry.annotationType,
            archivedDate: geometry.archivedDate,
        },
    }
}

/*
 * Create a new Geometry merging an old one with values from changes
 * @sig update :: (Geometry, {k:v}) -> Geometry
 */
Geometry.update = (geometry, changes) => Geometry.from(mergeRight(geometry, changes))

/*
 * Return a new Geometry overriding the old geometry with that of the passed-in GeoJSON Feature
 * @sig updateGeometryFromGeoJson :: (Geometry, <geojson-feature>) -> Geometry
 */
Geometry.updateGeometryFromGeoJson = (oldGeometry, geoJsonFeature) =>
    Geometry(
        oldGeometry.id,
        oldGeometry.canvasId,
        oldGeometry.annotationType,
        geoJsonFeature.geometry.coordinates,

        // will have been flattened to a string
        oldGeometry.archivedDate ? new Date(oldGeometry.archivedDate) : undefined
    )

const footToMeterConversionRate = 3.2808399

Geometry.getFormattedForDisplay = geometry => {
    const lng = geometry.coordinates[0].toFixed(8)
    const lat = geometry.coordinates[1].toFixed(8)
    const result = { lat, lng }
    if (geometry?.elevation) {
        const elevationMetres = geometry.elevation.toFixed(2)
        const elevationFeet = (geometry.elevation * footToMeterConversionRate).toFixed(2)
        return { ...result, elevationMetres, elevationFeet }
    }
    return result
}

export default Geometry
