/*
 * Collaboration
 *
 * A Collaboration is workflow related to a Feature
 */
import { mergeRight, pick, removeNilValues, renameKeys, tagged } from '@range.io/functional'
import { v4 } from 'uuid'
import { dateToTimestamp, millisOrTimestampToDate } from '../helper/timestamp.js'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Collaboration = tagged('Collaboration', {
    id             : StringTypes.Id,
    identifier     : 'String?', // human-readable number rather than UUID
    date           : 'Object', // Date
    author         : StringTypes.OptionalId,
    feature        : StringTypes.Id,
    name           : 'String',
    followers      : StringTypes.Ids, // -> [User id]
    
    // for Issue Collaborations only
    statusName     : StringTypes.OptionalId, // -> StatusName
    description    : 'String?',
    tags           : StringTypes.Ids, // -> [TagName]
    assignee       : StringTypes.OptionalId,
    dueDate        : 'Object?', // Date
    storageVersion : 'Object?',
    
    featureId      : StringTypes.Id,
    geometryId     : StringTypes.Id,
    canvasId       : StringTypes.Id,
    projectId      : StringTypes.Id,
    organizationId : StringTypes.Id,
})

/*
 * Is this Collaboration a Task, rather than a Photo Marker?
 * TODO: convert Collaboration to a taggedSum with Task and PhotoMarker constructors
 */
Collaboration.isTask = collaboration => collaboration.statusName

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Firebase doesn't like arrays and will not store an empty array
 * @sig fromFirebase = {k:v} -> Collaboration
 */
Collaboration.fromFirebase = o =>
    Collaboration.from({
        id: o.id,
        identifier: o.identifier,
        date: millisOrTimestampToDate(o.timestamp),
        author: o.author,
        feature: o.feature,
        name: o.name || '',
        followers: o.followers || [],

        // issue
        statusName: o.statusName,
        description: o.description,
        tags: o.tags || [],
        assignee: o.assignee,
        dueDate: millisOrTimestampToDate(o.dueDateTimestamp),

        storageVersion: o.storageVersion,

        featureId: o.featureId,
        geometryId: o.geometryId,
        canvasId: o.canvasId,
        projectId: o.projectId,
        organizationId: o.organizationId,
    })

Collaboration.toFirebase = o =>
    pick(
        [
            'id',
            'timestamp',
            'author',
            'feature',
            'name',
            'statusName',
            'description',
            'tags',
            'assignee',
            'dueDateTimestamp',
            'storageVersion',
            'followers',
        ],
        renameKeys(
            {
                date: ['timestamp', () => dateToTimestamp(o.date)],
                dueDate: ['dueDateTimestamp', () => dateToTimestamp(o.dueDate)],
            },
            o
        )
    )

// ---------------------------------------------------------------------------------------------------------------------
// Syntactic sugar for creating a specific kind of Collaboration object
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Initialize a Collaboration for the Feature with some defaults
 * @sig newCollaborationForFeature :: { User, Feature, Boolean } -> Collaboration
 */
Collaboration.newCollaborationForFeature = ({ author, feature, isTask }) =>
    Collaboration.from(
        removeNilValues({
            id: v4(),
            date: new Date(),
            author,
            feature,
            name: '',
            tags: [],
            followers: [],
            statusName: isTask ? Collaboration.initialCollaborationStatus : undefined,
        })
    )

/*
 * Create a new Collaboration merging an old one with values from changes
 * @sig update :: (Collaboration, {k:v}) -> Collaboration
 */
Collaboration.update = (collaboration, changes) => Collaboration.from(mergeRight(collaboration, changes))

// hack: values will be replaced by GeometrySlice before they're used
Collaboration.initialCollaborationStatus = 'will be a uuid'
Collaboration.completedCollaborationStatus = 'will be a uuid'

export default Collaboration
