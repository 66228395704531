/*
 * Simplified Tooltip where the tooltip popup always just shows text in a contrasting-color box.
 *
 *     TooltipPrimitive.Root
 *         TooltipPrimitive.Trigger
 *              {children}                             <=== some element needing a tooltip, eg. a button or avatar
 *         TooltipPrimitive.Trigger
 *
 *         TooltipContent
 *             {tooltipText}                           <=== the text of the tooltip, passed in as a string
 *             TooltipPrimitive.Arrow >
 *         TooltipContent
 *     TooltipPrimitive.Root
 *
 * NOTE: These tooltips will NOT work properly on Dropdown menus
 */

import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '../range-theme/index.js'

const DISTANCE_TO_ARROW_TIP = 8 // space from button to triangle on Tooltip in pixels
const DELAY_DURATION = 0

/*
 * Triangle pointing from a (visible) tooltip back to its trigger
 */
const TooltipArrow = styled(TooltipPrimitive.Arrow, {
    fill: '$neutral04',
    boxShadow: '0px 6px 10px  rgba( 0,  0,  0, 0.1)',
})

/*
 * Rounded rectangle wrapper for tooltip text, animated on-screen
 */
const TooltipContent = styled(TooltipPrimitive.Content, {
    color: '$neutral07',
    backgroundColor: '$neutral04',
    zIndex: 999,

    padding: '6px 8px',
    borderRadius: '6px',
    fontFamily: '$default',
    fontSize: '14px',
    fontWeight: 500,
    boxShadow: '0px 6px 10px  rgba( 0,  0,  0, 0.1)',
})

/*
 * A Tooltip to wrap around some other element specified as children (eg. Button, Avatar), and the given tooltipText
 * The text is rendered in a box with an arrow; the styling of this box is the main point of this Component
 */
const Tooltip = ({ tooltipText, side, children, align, offset, sideOffset, delayDuration, dataCy, css = {} }) => (
    <TooltipPrimitive.Provider>
        <TooltipPrimitive.Root delayDuration={delayDuration}>
            <TooltipPrimitive.Trigger data-cy={dataCy} asChild>
                {children}
            </TooltipPrimitive.Trigger>

            <TooltipContent
                side={side}
                align={align}
                offset={offset}
                sideOffset={sideOffset}
                css={css}
                data-cy="tooltip"
            >
                {tooltipText}
                <TooltipArrow />
            </TooltipContent>
        </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
)

// ---------------------------------------------------------------------------------------------------------------------
// Tooltip API
// ---------------------------------------------------------------------------------------------------------------------
Tooltip.displayName = 'Tooltip'
Tooltip.defaultProps = {
    align: 'center',
    open: false,
    offset: 20,
    sideOffset: DISTANCE_TO_ARROW_TIP,
    delayDuration: DELAY_DURATION,
    size: 'bottom',
}

// prettier-ignore
Tooltip.propTypes = {
    tooltipText  : PropTypes.string.isRequired,
    children     : PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]).isRequired,
    side         : PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    align        : PropTypes.oneOf(['start', 'center', 'end']),
    offset       : PropTypes.number,
    sideOffset   : PropTypes.number,
    delayDuration: PropTypes.number,
}

Tooltip.Content = TooltipContent
Tooltip.Provider = TooltipPrimitive.Provider
Tooltip.Root = TooltipPrimitive.Root
Tooltip.Trigger = TooltipPrimitive.Trigger
Tooltip.Arrow = TooltipPrimitive.Arrow

export default Tooltip
