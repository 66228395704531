import RangeColors from './range-colors.js'
import { darkTheme } from './stitches-config.js'

// prettier-ignore
const RangeUITheme = Object.assign(RangeColors, {
    fonts: { default: "'Inter', sans-serif" },
    
    fontSizes    : {
        xs       : '11px',
        sm       : '12px',
        md       : '13px',
        lg       : '14px',
        xl       : '16px',
        xxl      : '18px',
        xxxl     : '20px',
        xxxxl    : '32px',
    },
    
    fontWeights  : {
        light    : '300',
        regular  : '400',
        medium   : '500',
        semiBold : '600',
        bold     : '700',
    },
    
    borderRadii  : {
        xs       : '4px',
        sm       : '6px',
        md       : '12px',
        lg       : '24px',
    },
    
    shadows      : {
        sm       : '0px 2px 6px   rgba(47, 54, 71, 0.4)',
        md       : '0px 2px 4px   rgba( 0,  0,  0, 0.1);',
        lg       : '0px 6px 10px  rgba( 0,  0,  0, 0.1)',
        xl       : '0px 2px 12px  rgba( 0,  0,  0, 0.1)',
        xxl      : '0px 0px 20px  rgba( 0,  0,  0, 0.1)',
        xxxl     : '0px 4px 20px  rgba( 0,  0,  0, 0.1)',
    },

})

const colorThemes = {
    light: 'light',
    dark: 'dark',
    system: 'system',
}
RangeUITheme.colorThemes = colorThemes

/*
 * Copy the LIGHT or DARK colors into the top-level RangeUITheme object
 * Impure: directly manipulates the RangeUITheme object
 * @sig handleThemeChange :: Mode -> ()
 *      Mode = light|dark|system
 */
const handleThemeChange = mode => {
    const colors = RangeColors[mode]

    // fix stitches themes
    if (mode === colorThemes.light) document.body.classList.remove(darkTheme)
    else document.body.classList.add(darkTheme)

    Object.assign(RangeUITheme, colors)
    Object.assign(RangeUITheme, { mode })
}

const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

/*
 * Change the UI theme.
 * If the mode is set to 'system', then it will listen for system color preference.
 * @sig changeColorMode :: Mode -> ()
 *      Mode = light|dark|system
 */
RangeUITheme.changeColorMode = mode => {
    switch (mode) {
        case colorThemes.light: {
            handleThemeChange(colorThemes.light)
            break
        }
        case colorThemes.dark: {
            handleThemeChange(colorThemes.dark)
            break
        }
        // using system setting
        default: {
            if (mediaQuery.matches) handleThemeChange(colorThemes.dark)
            else handleThemeChange(colorThemes.light)
            break
        }
    }
}

export default RangeUITheme
