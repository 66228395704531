import PropTypes from 'prop-types'
import React from 'react'
import { Box, Button, FlexColumn, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const ModalContainer = ({ children, ...restProps }) => {
    return (
        <Box
            onClick={e => {
                e.preventDefault()
                e.stopPropagation() // Needed to not bubble click action so overlay click doesn't trigger
            }}
            {...restProps}
        >
            {children}
        </Box>
    )
}

const StyledModalContainer = styled(ModalContainer, {
    bg: '$neutral09Alpha',
    backdropFilter: 'blur(12px) saturate(190%) contrast(50%) brightness(130%)',
    color: '$neutral04',
    width: '500px',
    border: '1px solid $neutral07',
    br: '6px',
    boxShadow: '0px 6px 10px #00000010',
    zIndex: 1000,
})

const StyledModalActions = styled(Box, {
    width: '100%',
    height: '72px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '24px',
    paddingLeft: '24px',
    bt: '1px solid $neutral07',
    display: 'flex',
    gap: '16px',
    justifyContent: 'right',
})

const StyledPrimaryModalLabel = styled(Text, {
    fs: '20px',
    fw: 700,
})

const StyledSecondaryModalLabel = styled(Text, {
    fs: '14px',
    color: '$neutral05',
    lh: '1.4',
})

const StyledOverlay = styled(FlexColumn, {
    width: '100vw',
    height: '100vh',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$neutralOverlay',
    ai: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 99999,
})

/*
 * PDF canvas source new project component
 */

const PDFCanvasSourceDeletePDFsModal = ({ onDelete, onCancel }) => {
    const primaryLabel = "Are you sure you want to remove all PDF's?"
    const secondaryLabel = "Any changes you've made to sheet names will be lost"

    return (
        <StyledOverlay data-cy="modal" onClick={onCancel}>
            <StyledModalContainer>
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel css={{ paddingBottom: '12px' }}>{primaryLabel}</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel css={{ paddingBottom: '24px' }}>
                        {secondaryLabel}
                    </StyledSecondaryModalLabel>
                </Box>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="destructive" size="lg" onClick={onDelete}>
                        Confirm
                    </Button>
                </StyledModalActions>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

PDFCanvasSourceDeletePDFsModal.displayName = 'PDFCanvasSourceDeletePDFsModal'

PDFCanvasSourceDeletePDFsModal.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default PDFCanvasSourceDeletePDFsModal
