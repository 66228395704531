/*
 * Simple return-the-selected-X
 */
import { pick } from '@range.io/functional'
import memoizeResult from './memoize-result.js'

const _field = field => state => state[field]
const _value = (field, id) => state => state[field][state[id]]

// simple fields
const allProjectMetrics = _field('projectMetrics')
const currentlyLoadingProject = _field('currentlyLoadingProject')
const isLoadingInitialData = _field('isLoadingInitialData')
const cursor = _field('cursor')
const drawingMode = _field('drawingMode')
const globalModalData = _field('globalModalData')
const mapPositionsHistory = _field('mapPositionsHistory')
const mediaViewFilterSettings = _field('mediaViewFilterSettings')
const navigatorFilterSettings = _field('navigatorFilterSettings')
const navigatorSortSettings = _field('navigatorSortSettings')
const pdfLoadingState = _field('pdfLoadingState')
const backgroundMapLoadingState = _field('backgroundMapLoadingState')
const selectedCanvasId = _field('selectedCanvas')
const selectedCollaborationId = _field('selectedCollaborationId')
const selectedGeometryIds = _field('selectedGeometryIds')
const selectedOrganizationId = _field('selectedOrganizationId')
const selectedProjectId = _field('selectedProjectId')
const selectedTool = _field('selectedTool')
const selectedUserId = _field('selectedUserId')
const selectedMediaUploadsIds = _field('selectedMediaUploadsIds')
const shouldFocusCollabWindow = _field('shouldFocusCollabWindow')
const showAccountCreatedToast = _field('showAccountCreatedToast')
const showCanvasSnippetMode = _field('showCanvasSnippetMode')
const showTimelineDetails = _field('showTimelineDetails')
const taskListFilterSettings = _field('taskListFilterSettings')
const notificationPreferences = _field('notificationPreferences')
const knockUserToken = _field('knockUserToken')

// lookup tables
const canvasLookupTable = _field('canvases')
const canvasSourceLookupTable = _field('canvasSources')
const collaborationLookupTable = _field('collaborations')
const commentLookupTable = _field('comments')
const organizationLookupTable = _field('organizations')
const projectLookupTable = _field('projects')
const statusNameLookupTable = _field('statusNames')
const tagNameLookupTable = _field('tagNames')
const toastLookupTable = _field('toasts')
const updatesLookupTable = _field('updates')
const uploadLookupTable = _field('uploads')

const sortByName = (a, b) => a.name.localeCompare(b.name)

// AsArray
const collaborationsAsArray = memoizeResult(state => Object.values(state.collaborations))
const commentsAsArray = memoizeResult(state => Object.values(state.comments))
const featuresAsArray = memoizeResult(state => Object.values(state.features))
const geometriesAsArray = memoizeResult(state => Object.values(state.geometries))
const organizationsAsArray = memoizeResult(state => Object.values(state.organizations).sort(sortByName))
const presencesAsArray = memoizeResult(state => Object.values(state.presences))
const projectsAsArray = memoizeResult(state => Object.values(state.projects))
const statusNamesAsArray = memoizeResult(state => Object.values(state.statusNames))
const updatesAsArray = memoizeResult(state => Object.values(state.updates))
const uploadsAsArray = memoizeResult(state => Object.values(state.uploads))

// selected value from a field
const selectedCanvas = _value('canvases', 'selectedCanvas')
const selectedCanvasSource = _value('canvasSources', 'selectedCanvasSource')
const selectedCollaboration = _value('collaborations', 'selectedCollaborationId')
const selectedFeature = _value('features', 'selectedFeatureId')
const selectedOrganization = _value('organizations', 'selectedOrganizationId')
const selectedProject = _value('projects', 'selectedProjectId')

// slightly more complicated
const selectedGeometries = state => Object.values(pick(state.selectedGeometryIds, state.geometries))
const selectedUser = state => state.selectedUserId && state.user // fixme: id guards actual value for now

// As array

export {
    // simple fields
    allProjectMetrics,
    currentlyLoadingProject,
    isLoadingInitialData,
    cursor,
    drawingMode,
    globalModalData,
    knockUserToken,
    mapPositionsHistory,
    mediaViewFilterSettings,
    navigatorFilterSettings,
    navigatorSortSettings,
    pdfLoadingState,
    backgroundMapLoadingState,
    selectedCanvasId,
    selectedCollaborationId,
    selectedGeometryIds,
    selectedOrganizationId,
    selectedProjectId,
    selectedTool,
    selectedUserId,
    selectedMediaUploadsIds,
    shouldFocusCollabWindow,
    showAccountCreatedToast,
    showCanvasSnippetMode,
    showTimelineDetails,
    taskListFilterSettings,
    notificationPreferences,
    // lookup tables
    canvasLookupTable,
    canvasSourceLookupTable,
    collaborationLookupTable,
    commentLookupTable,
    organizationLookupTable,
    projectLookupTable,
    statusNameLookupTable,
    tagNameLookupTable,
    toastLookupTable,
    updatesLookupTable,
    uploadLookupTable,
    // AsArray
    collaborationsAsArray,
    commentsAsArray,
    featuresAsArray,
    geometriesAsArray,
    organizationsAsArray,
    presencesAsArray,
    projectsAsArray,
    statusNamesAsArray,
    updatesAsArray,
    uploadsAsArray,
    // selected value from a field
    selectedCanvas,
    selectedCanvasSource,
    selectedCollaboration,
    selectedFeature,
    selectedOrganization,
    selectedProject,
    // slightly more complicated
    selectedGeometries,
    selectedUser,
}
