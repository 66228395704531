import { isUsingEmulator } from '../../components-application/URLController'

const DEFAULT_ENV = 'development'

const parseHostname = hostname => {
    // dev
    if (hostname.match(/development.*web.app/)) return 'development'

    // demorange/beta
    if (hostname.match(/demorangeio/)) return 'demorangeio'
    if (hostname.match(/beta.range.io/)) return 'demorangeio'

    // staging
    if (hostname.match(/staging-range-io/)) return 'staging-range-io'
    if (hostname.match(/staging.range.io/)) return 'staging-range-io'

    // production
    if (hostname.match(/production-range-io/)) return 'production-range-io'
    if (hostname.match(/app.range.io/)) return 'production-range-io'
}

/*
 * Pull the environment name from the browser URL -- if there is a window at all
 * @sig envFromWindowLocation :: () -> String|undefined
 */
const envFromWindowLocation = () => {
    // in order to work in node environments
    if (typeof window === 'undefined') return { env: DEFAULT_ENV, useEmulator: false }

    const url = new URL(window.location.href)
    const env = url.searchParams.get('env') || parseHostname(url.hostname) || DEFAULT_ENV
    const useEmulator = isUsingEmulator()
    return { env, useEmulator }
}

export default envFromWindowLocation
