/*
 * Simple Geometry calculations
 *
 * Types used here:
 *
 *   Point = [X, Y]
 *   BoundingBox = [X, Y, Width, Height]
 *   X = Y = Width = Height = Number
 *
 * (Remember: 0 is at the TOP in canvas coordinates, so minY is a closer to the top than maxY)
 */
import { pluck } from '@range.io/functional'

/*
 * Find the BoundingBox of an array of Points
 * @sig boundingBox :: [Point] -> BoundingBox
 */
const boundingBox = points => {
    const xs = pluck(0, points)
    const ys = pluck(1, points)

    const minX = Math.min(...xs)
    const maxX = Math.max(...xs)
    const minY = Math.min(...ys)
    const maxY = Math.max(...ys)

    return [minX, minY, maxX - minX, maxY - minY]
}

/*
 * Return a new Point offset from the original
 * @sig offsetPoint :: (Point, Number, Number) -> Point
 */
const offsetPoint = (p, dx, dy) => [p[0] + dx, p[1] + dy]

//
/*
 * Return the (Euclidean) distance between two points
 * @sig distance :: (Point, Point) -> Number
 */
const distance = (p1, p2) => Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2))

/*
 * "distance" with added magic constant for comparing two points
 * @sig arePointsNearEachOther :: (Point, Point, Number) -> Boolean
 */
const arePointsNearEachOther = (p1, p2, epsilon = 5) => distance(p1, p2) < epsilon

/*
 * Given a line from start to end, an angle and a length, find the point that is angle degrees from the direction
 * of from start to end, with the given length
 * @sig pointAtAngleToLine :: (Point, Point, Number, Number) -> Point
 */
const pointAtAngleToLine = (start, end, angle, length) => {
    const [x1, y1] = start
    const [x2, y2] = end

    const angleP1P2 = Math.atan2(y2 - y1, x2 - x1) // Calculate the angle from start to end
    const totalAngle = angleP1P2 + angle * (Math.PI / 180)
    return [x2 + length * Math.cos(totalAngle), y2 + length * Math.sin(totalAngle)]
}

/*
 * Ex
 * @sig expandBoundingBox :: (BoundingBox, Number, Number) -> BoundingBox
 */
const expandBoundingBox = (boundingBox, dx, dy) => {
    const [x, y, width, height] = boundingBox
    return [x - dx, y - dy, width + 2 * dx, height + 2 * dy]
}

/*

 */
const isPointInBoundingBox = (boundingBox, x, y) => {
    const [left, top, width, height] = boundingBox
    const right = left + width
    const bottom = top + height

    return left <= x && x <= right && top <= y && y <= bottom
}

/*
 * Calculate the perpendicular distance from a point to a line
 * @sig distanceFromPointToLine :: (Point, Point, Point) -> Number
 * https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line (Line defined by two points)
 */
const distanceFromPointToLine = (point, startPoint, endPoint) => {
    const x0 = point[0]
    const y0 = point[1]
    const x1 = startPoint[0]
    const y1 = startPoint[1]
    const x2 = endPoint[0]
    const y2 = endPoint[1]

    const numerator = Math.abs((y2 - y1) * x0 - (x2 - x1) * y0 + x2 * y1 - y2 * x1)
    const denominator = Math.sqrt((y2 - y1) ** 2 + (x2 - x1) ** 2)
    return numerator / denominator
}

/*
 * Simplify a line made up of Points using the Douglas-Peucker algorithm, throwing out points that
 * aren't very far off an existing line. It works recursively:
 *
 * - define a line from start point to end point
 * - find the point between the start and end that's at the furthest distance from the line
 * - throw away the points between the start and end points if the furthest one is less than epsilon distance away
 * - or recurse, using that middle point as the end of one subline and the start of anothre
 */
const simplifyPoints = (points, epsilon) => {
    // Find the point that's at the maximum distance from the line created by the first and last points
    const findMaximumDistance = points => {
        let maximumDistance = 0
        let index = 0
        const startPoint = points[0]
        const endPoint = points.at(-1)

        // try each point between the first and last to see if it's further from the line than any previous one
        for (let i = 1; i < points.length - 1; i++) {
            const distance = distanceFromPointToLine(points[i], startPoint, endPoint)

            // yes, it is further
            if (distance > maximumDistance) {
                index = i
                maximumDistance = distance
            }
        }

        return { maximumDistance, index }
    }

    // Recursive function to reduce points
    const eliminateClosePoints = points => {
        const { maximumDistance, index } = findMaximumDistance(points)

        // the point was far enough away from the line to become the new endpoint of two separate lines
        // from the start point to midpoint and the other from midpoint to end point
        if (maximumDistance > epsilon) {
            const left = eliminateClosePoints(points.slice(0, index + 1))
            const right = eliminateClosePoints(points.slice(index))
            return [...left.slice(0, -1), ...right]
        }

        // no point was very far off the line, so throw all the intermediate points away
        else {
            return [points[0], points.at(-1)]
        }
    }

    return eliminateClosePoints(points)
}

/*
 * Catmull-Rom algorithm to turn an array of points into a Bezier with 2 control points
 * @sig convertPointsToBeziers :: [Point] -> [Bezier]
 *  Bezier = [Number, Number, Number, Number, Number, Number]
 */
const convertPointsToBeziers = points => {
    // special case if the entire set of points were simplified to a straight line
    if (points.length === 2) {
        const [x0, y0] = points[0]
        const [x1, y1] = points[1]
        return [[x0, y0, x1, y1, x1, y1]] // one control point at each end
    }

    const beziers = []

    // push the last point on a 2nd time, since the loop ends at length - 2 and we would otherwise lose the final point
    points = [...points, points.at(-1)]

    for (let i = 1; i < points.length - 2; i++) {
        const [p0x, p0y] = points[i - 1]
        const [p1x, p1y] = points[i]
        const [p2x, p2y] = points[i + 1]
        const [p3x, p3y] = points[i + 2]

        // in each round the focus is on finding control points between p1 and p2
        // (we don't include p1 in the result, since our SVG-style 'paths' assume each p1 IS the previous p2)
        const cp1x = p1x + (p2x - p0x) / 6
        const cp1y = p1y + (p2y - p0y) / 6
        const cp2x = p2x - (p3x - p1x) / 6
        const cp2y = p2y - (p3y - p1y) / 6

        beziers.push([cp1x, cp1y, cp2x, cp2y, p2x, p2y])
    }

    return beziers
}

export {
    boundingBox,
    convertPointsToBeziers,
    simplifyPoints,
    expandBoundingBox,
    isPointInBoundingBox,
    offsetPoint,
    distance,
    arePointsNearEachOther,
    pointAtAngleToLine,
}
