import React, { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ReduxSelectors } from '../redux/index.js'
import { Invitation, Participant } from '@range.io/basic-types'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { without } from '@range.io/functional'

import { styled } from '../range-theme/index.js'
import { UserRoles } from '../redux/slices/redux-selectors-permissions.js'
import { ProjectParticipantAddedCommand } from '../firebase/commands/project-participant-added-command.js'
import { InvitationChangedCommand } from '../firebase/commands/invitation-changed-command.js'
import {
    Avatar,
    FlexColumn,
    FlexRow,
    Icon,
    IconButtonWithTooltip,
    Link,
    ScrollArea,
    StatusPill,
    Switch,
    Text,
} from '../components-reusable/index.js'
import { RolePill } from './UserRow.js'
import { TableTools } from './TableTools.js'
import { useSearch } from '../components-reusable/hooks/index.js'

const MembersWindowStyled = styled('div', {
    backgroundColor: '$neutral10',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '6px',
    border: '1px solid $neutral07',
    width: '100%',
    height: '100%',
})

const ContentWrapperStyled = styled(FlexColumn, {
    padding: 24,
    height: '100%',
    color: '$neutral05',
    gap: '16px',
})

const InfoBannerStyled = styled(FlexRow, {
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    background: '$orange02',
    borderRadius: '6px',
    color: '$neutral03',
    fontSize: 12,
    fontWeight: 500,
    borderBottom: '1px solid $orange03',
})

const ParticipantsTableStyled = styled(FlexColumn, {
    borderRadius: '6px',
    background: '$neutral09',
    border: '1px solid $neutral07',
})

const ParticipantRowStyled = styled(FlexRow, {
    padding: '8px 16px',
    gap: '16px',
    alignItems: 'center',
    borderBottom: '1px solid $neutral07',
    '&:last-child': {
        borderBottom: 'none',
    },
})

const MembersWindowContainer = ({ children }) => {
    return createPortal(
        <div
            style={{
                position: 'absolute',
                zIndex: '9',
                top: 50,
                padding: 12,
                right: 0,
                height: '100%',
                maxHeight: '-webkit-fill-available',
                width: '630px',
                maxWidth: '50%',
            }}
        >
            {children}
        </div>,
        document.body
    )
}

const UserRow = ({
    avatarFallbackText,
    avatarUrl,
    checked,
    displayName,
    isPending,
    organizationRole,
    userInfo,
    onToggleClick,
}) => {
    return (
        <ParticipantRowStyled>
            <Switch variant="filter" checked={checked} onCheckedChange={isOn => onToggleClick(isOn)} />
            <Avatar fallbackText={avatarFallbackText} url={avatarUrl} />
            <FlexColumn>
                <FlexRow css={{ gap: 4 }}>
                    <Text
                        css={{
                            color: '$neutral04',
                            fs: 14,
                            fw: 500,
                        }}
                    >
                        {displayName}
                    </Text>

                    {organizationRole && <RolePill variant="dark">{organizationRole}</RolePill>}
                    {isPending && <StatusPill variant="pending">Pending</StatusPill>}
                </FlexRow>

                <Text
                    css={{
                        color: '$neutral05',
                        fs: 12,
                        fw: 400,
                    }}
                >
                    {userInfo}
                </Text>
            </FlexColumn>
        </ParticipantRowStyled>
    )
}

const ParticipantRow = ({ participant, checked, onToggleProjectClick }) => {
    const { avatarUrl, organizationRole } = participant

    const fullName = Participant.fullName(participant)

    const handleToggleClick = isOn => onToggleProjectClick({ isOn, participant })

    return (
        <UserRow
            avatarFallbackText={`${participant.firstName[0]}${participant.lastName[0]}`}
            avatarUrl={avatarUrl}
            checked={checked}
            displayName={fullName}
            organizationRole={organizationRole}
            userInfo={participant.email}
            participant={participant}
            onToggleClick={handleToggleClick}
        />
    )
}

const InvitationRow = ({ invitation, checked, onToggleInvitationClick }) => {
    const {
        firstName,
        lastName,
        inviteeEmail,
        inviterFirstName,
        inviterLastName,
        resentTimestamp,
        timestamp,
        organizationRole,
    } = invitation

    const hasName = firstName && lastName
    const invitationTimestamp = resentTimestamp || timestamp
    const displayName = hasName ? `${firstName} ${lastName}` : inviteeEmail

    const handleToggleClick = isOn => onToggleInvitationClick({ isOn, invitation })

    return (
        <UserRow
            avatarFallbackText={hasName ? `${firstName[0]}` + `${lastName[0]}` : inviteeEmail[0]}
            checked={checked}
            displayName={displayName}
            organizationRole={organizationRole}
            userInfo={`Invited by ${inviterFirstName} ${inviterLastName} on ${invitationTimestamp.toLocaleDateString()}`}
            onToggleClick={handleToggleClick}
            isPending
        />
    )
}

const hasGuestRole = item => item.organizationRole === UserRoles.GUEST
const hasCollaboratorRole = item => item.organizationRole === UserRoles.COLLABORATOR
const isGuestOrCollaborator = item => hasCollaboratorRole(item) || hasGuestRole(item)

/**
 * `MembersWindow` is a React functional component that displays a list of project members and invitations,
 * allowing users to view and manage participants with access to a specific project within an organization.
 * The component supports closing the window and navigating to a detailed view of all members.
 *
 * @param {string} organizationName - The name of the organization to be displayed in the information banner.
 * @param {Function} onCloseClick - Callback function to handle the closing of the members window.
 */
const MembersWindow = ({ organizationName, onCloseClick }) => {
    const DROPDOWN_FILTER_OPTIONS = [
        { id: 'all', name: 'All Users' },
        { id: 'enabled', name: 'Enabled Users' },
        { id: 'disabled', name: 'Disabled Users' },
        { id: 'collaborators', name: 'Collaborators' },
        { id: 'guests', name: 'Guests' },
        { id: 'pending', name: 'Only Pending' },
    ]

    const navigate = useNavigate()
    const { workspaceId } = useParams()
    const { runCommand } = useCommandHistory()

    const invitations = useSelector(ReduxSelectors.invitations).filter(invitation => !invitation.acceptedTimestamp) // get only pending invitations
    const organizationParticipants = useSelector(ReduxSelectors.organizationParticipantsAsArray)
    const selectedProjectParticipantShapes = useSelector(ReduxSelectors.selectedProjectParticipantShapes)
    const selectedProjectId = useSelector(ReduxSelectors.selectedProjectId)

    const [allowedUsersIds, setAllowedUsersIds] = useState([])
    const [allowedInvitationIds, setAllowedInvitationIds] = useState([])
    const [dropdownFilterValue, setDropdownFilterValue] = useState('all')

    const guestsAndCollaboratorParticipants = organizationParticipants.filter(participant =>
        isGuestOrCollaborator(participant)
    )

    const guestsAndCollaboratorInvitations = invitations.filter(invitation => isGuestOrCollaborator(invitation))

    const onViewMembersClick = () => navigate(`/${workspaceId}/members`)

    // Change Project permission for the participant
    const onToggleProjectClick = ({ participant, isOn }) => {
        runCommand(ProjectParticipantAddedCommand.Outbound([selectedProjectId], participant, !isOn))
    }

    // Change Project permission for the invitation
    const onToggleInvitationClick = ({ invitation, isOn }) => {
        // Either add or remove project id from the array
        const newProjectIds = isOn
            ? invitation.projectIds.concat(selectedProjectId)
            : without([selectedProjectId], invitation.projectIds)

        return runCommand(
            InvitationChangedCommand.Outbound(invitation, invitation.id, {
                projectIds: newProjectIds,
            })
        )
    }

    // Sort participant and invitations alphabetically into one list
    const participantsAndInvitationsSorted = useMemo(() => {
        // Create a function to generate the comparison key for sorting
        const getSortKey = item => {
            if (Participant.is(item)) {
                // Participant: use full name or fallback to email
                return [item.firstName, item.lastName].join(' ').trim() || item.email
            } else if (Invitation.is(item)) {
                // Invitation: use inviteeEmail
                return item.inviteeEmail
            }
            return '' // Fallback if neither type matches, should not happen
        }

        // Combine participants and invitations into a single list, so we can then sort
        const combinedList = [...guestsAndCollaboratorParticipants, ...guestsAndCollaboratorInvitations]

        // Sort using the sort key generated by the getSortKey function
        combinedList.sort((a, b) => getSortKey(a).localeCompare(getSortKey(b)))

        return combinedList
    }, [guestsAndCollaboratorInvitations, guestsAndCollaboratorParticipants, selectedProjectId])

    // Get participants and invitations ids that have access to this project
    useEffect(() => {
        // Filter allowed participants and invitations
        const allowedParticipants = []
        const allowedInvitations = []

        participantsAndInvitationsSorted.forEach(item => {
            if (Participant.is(item)) {
                // This is a participant
                if (!selectedProjectParticipantShapes[item.id]?.isSuspended) {
                    allowedParticipants.push(item.id)
                }
            } else if (Invitation.is(item)) {
                // This is an invitation
                if (item.projectIds.includes(selectedProjectId)) {
                    allowedInvitations.push(item.id)
                }
            }
        })

        setAllowedUsersIds(allowedParticipants)
        setAllowedInvitationIds(allowedInvitations)
    }, [participantsAndInvitationsSorted, selectedProjectParticipantShapes, selectedProjectId])

    // Sort participant and invitations alphabetically into one list
    const participantsAndInvitationsFiltered = useMemo(() => {
        switch (dropdownFilterValue) {
            case 'all':
                return participantsAndInvitationsSorted
            case 'enabled':
                return participantsAndInvitationsSorted.filter(
                    item => allowedUsersIds.includes(item.id) || allowedInvitationIds.includes(item.id)
                )
            case 'disabled':
                return participantsAndInvitationsSorted.filter(
                    item => !allowedUsersIds.includes(item.id) && !allowedInvitationIds.includes(item.id)
                )
            case 'collaborators':
                return participantsAndInvitationsSorted.filter(item => hasCollaboratorRole(item))
            case 'guests':
                return participantsAndInvitationsSorted.filter(item => hasGuestRole(item))
            case 'pending':
                return participantsAndInvitationsSorted.filter(item => Invitation.is(item))
        }
    }, [participantsAndInvitationsSorted, dropdownFilterValue])

    const searchFilterSettings = {
        fields: ['email', 'inviteeEmail'], // match both participant email and invitee email
        joinedFields: [['firstName', 'lastName']],
    }

    const {
        searchValue,
        setSearchValue,
        filteredItems: participantsAndInvitationsToDisplay,
    } = useSearch({
        ...searchFilterSettings,
        items: participantsAndInvitationsFiltered,
    })

    return (
        <MembersWindowStyled>
            <ContentWrapperStyled>
                <FlexColumn>
                    <FlexRow css={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <FlexRow css={{ gap: '6px', alignItems: 'center', color: '$neutral04' }}>
                            <Icon name="team" iconSize="26" />
                            <Text css={{ fs: '20px', fw: 700 }}>Project Members</Text>
                        </FlexRow>

                        <IconButtonWithTooltip
                            iconName="close24x24"
                            size="20px"
                            tooltipText="Close"
                            side="bottom"
                            onClick={onCloseClick}
                            variant="neutralHover32"
                        />
                    </FlexRow>
                    <Text css={{ fontSize: '16px' }}>View and manage who has access to this project.</Text>
                </FlexColumn>
                <InfoBannerStyled>
                    <Icon name="user" iconSize="12" />
                    <Text>
                        All Admins of the {organizationName} Organization have access to this project.{' '}
                        <Link
                            onClick={onViewMembersClick}
                            css={{ textDecoration: 'underline', color: 'inherit', display: 'inline-flex' }}
                        >
                            View members
                        </Link>
                    </Text>
                </InfoBannerStyled>

                <TableTools
                    canManageParticipants={false}
                    dropdownFilterOptions={DROPDOWN_FILTER_OPTIONS}
                    dropdownFilterValue={dropdownFilterValue}
                    inputWidth={300}
                    searchValue={searchValue}
                    onDropdownFilterValueChange={setDropdownFilterValue}
                    setSearchValue={setSearchValue}
                />

                <Text css={{ fs: 12, fw: 500 }}>
                    {allowedUsersIds.length} Collaborators and/or Guests have access to this project.
                </Text>

                <FlexColumn css={{ minHeight: 0 }}>
                    <ScrollArea>
                        <ParticipantsTableStyled>
                            {!participantsAndInvitationsToDisplay.length && (
                                <FlexColumn
                                    css={{
                                        height: 'inherit',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        pt: 56,
                                        pb: 56,
                                    }}
                                >
                                    <Text css={{ color: '$neutral05', fs: '14px', fw: '500' }}>
                                        No matching users found.
                                    </Text>
                                </FlexColumn>
                            )}

                            {participantsAndInvitationsToDisplay.map(el => {
                                return Participant.is(el) ? (
                                    <ParticipantRow
                                        key={el.id}
                                        participant={el}
                                        checked={allowedUsersIds.includes(el.id)}
                                        onToggleProjectClick={onToggleProjectClick}
                                    />
                                ) : (
                                    <InvitationRow
                                        key={el.id}
                                        invitation={el}
                                        checked={allowedInvitationIds.includes(el.id)}
                                        onToggleInvitationClick={onToggleInvitationClick}
                                    />
                                )
                            })}
                        </ParticipantsTableStyled>
                    </ScrollArea>
                </FlexColumn>
            </ContentWrapperStyled>
        </MembersWindowStyled>
    )
}

export { MembersWindowContainer, MembersWindow }
