import {
    Canvas,
    CanvasSource,
    Collaboration,
    Comment,
    Feature,
    Geometry,
    Invitation,
    Organization,
    Presence,
    Project,
    StatusName,
    TagName,
    Update,
    Upload,
} from '@range.io/basic-types'
import {
    assoc,
    filterObject,
    filterValues,
    mapObject,
    memoizeReduxState,
    mergeRight,
    pick,
    pluck,
} from '@range.io/functional'
import { CollaborationShape } from '../../react-shapes/collaboration-shape.js'
import { ParticipantShape } from '../../react-shapes/participant-shape.js'
import { generateCollaborationShapes } from '../generate-shapes.js'
import memoizeResult from './memoize-result.js'
import { LoggedInStatus } from './redux-actions.js'
import {
    allProjectMetrics,
    backgroundMapLoadingState,
    canvasLookupTable,
    canvasSourceLookupTable,
    collaborationLookupTable,
    collaborationsAsArray,
    commentLookupTable,
    commentsAsArray,
    currentlyLoadingProject,
    cursor,
    drawingMode,
    featuresAsArray,
    geometriesAsArray,
    globalModalData,
    isLoadingInitialData,
    knockUserToken,
    mapPositionsHistory,
    mediaViewFilterSettings,
    navigatorFilterSettings,
    navigatorSortSettings,
    notificationPreferences,
    organizationLookupTable,
    organizationsAsArray,
    pdfLoadingState,
    presencesAsArray,
    projectLookupTable,
    projectsAsArray,
    selectedCanvas,
    selectedCanvasId,
    selectedCanvasSource,
    selectedCollaboration,
    selectedCollaborationId,
    selectedFeature,
    selectedGeometries,
    selectedGeometryIds,
    selectedMediaUploadsIds,
    selectedOrganization,
    selectedOrganizationId,
    selectedProject,
    selectedProjectId,
    selectedTool,
    selectedUser,
    selectedUserId,
    shouldFocusCollabWindow,
    showAccountCreatedToast,
    showCanvasSnippetMode,
    showTimelineDetails,
    statusNameLookupTable,
    statusNamesAsArray,
    tagNameLookupTable,
    taskListFilterSettings,
    toastLookupTable,
    updatesAsArray,
    updatesLookupTable,
    uploadLookupTable,
    uploadsAsArray,
} from './redux-selectors-basic.js'
import { filteredCollaborations, filteredMediaView, filteredTaskList } from './redux-selectors-filters.js'
import { isCreateAllowed, isDeleteAllowed, isUpdateAllowed, UserRoles } from './redux-selectors-permissions.js'

const empty = {}

// ---------------------------------------------------------------------------------------------------------------------
// Helpers
// ---------------------------------------------------------------------------------------------------------------------

// Return ALL the items as an array
const updatesForCollaboration = (state, id) => updatesAsArray(state).filter(u => u.parentId === id)

// State -> { [Id]: Participant }
const organizationParticipants = state => selectedOrganization(state)?.participants || {}

/*
 * Return the user with the specified id
 * @sig organizationParticipantWithId :: (State, String) -> User|Participant|undefined
 */
const organizationParticipantWithId = (state, userId) => organizationParticipants(state)[userId]

/*
 * Return only the projects permitted to the given user; at the moment, this is identical to those permitted to the org
 */
const _userProjectsAsArray = state => {
    const organizationProjects = state.projects

    const user = selectedUser(state)
    if (!user) return []

    const userProjectIds = user.permissions.projects ?? []
    let projects = userProjectIds.map(i => organizationProjects[i])
    projects = projects.filter(p => p) // remove nils
    return projects.sort((a, b) => a.name.localeCompare(b.name))
}

const _userProjectsAsLookupTable = state => {
    const organizationProjects = state.projects

    const user = selectedUser(state)
    if (!user) return {}

    const userProjectIds = user.permissions.projects ?? []
    return pick(userProjectIds, organizationProjects)
}

const userProjectsAsArray = memoizeReduxState(['projects', 'user'], _userProjectsAsArray)

const userProjectsForSelectedOrganizationAsArray = state => {
    const organization = selectedOrganization(state)
    if (!organization) return []

    const organizationProjects = organization.projectIds
    const allProjects = userProjectsAsArray(state)
    return allProjects.filter(p => organizationProjects.includes(p.id))
}

const userProjectsAsLookupTable = memoizeReduxState(['projects'], _userProjectsAsLookupTable)

/*
 * Return all users who are "present" -- meaning their lastPresenceTimestamp was recent AND they're not "me"
 * @sig presences :: State -> [User]
 */
const presences = state => {
    const me = state.selectedUserId
    return presencesAsArray(state).filter(u => {
        const isNotMe = u.id !== me
        const movedRecently = u.lastPresenceTimestamp + Presence.userIsNoLongerPresentAfter > Date.now()
        return isNotMe && movedRecently
    })
}

/*
 * The project.participants for the selected Project
 */
const selectedProjectParticipants = state => {
    const project = selectedProject(state)
    return project?.participants || empty
}

/*
 * Return all the participants of the selected project as an object
 * @sig selectedProjectParticipantShapes :: State -> { [Id]: ParticipantShape }
 */
const selectedProjectParticipantShapes = state => {
    const project = selectedProject(state)
    if (!project) return {}

    return mapObject(ParticipantShape.fromParticipant(), project.participants) // <-- extra () is actually correct...
}

/*
 * Return the participantShape of the selected project with the given id
 * @sig selectedProjectParticipantShapes :: (State, Id) -> ParticipantShaped
 */
const selectedProjectParticipantShapeWithId = (state, id) => selectedProjectParticipantShapes(state)?.[id]

/*
 * Return all the participants of the selected project as an array
 * @sig selectedProjectParticipantShapes :: State -> [ParticipantShape]
 */
const selectedProjectParticipantShapesAsArray = state => Object.values(selectedProjectParticipantShapes(state))

const selectedProjectParticipantWithId = (state, id) => selectedProjectParticipants(state)[id]

/*
 * Return all the Users who have recent Presences
 * @sig presentProjectParticipants :: State -> [Participant]
 */
const presentProjectParticipants = state => {
    const reducer = (acc, p) => acc.concat(_users[p.id])

    const _presences = presences(state)
    const _users = selectedProjectParticipants(state)
    return _presences.reduce(reducer, [])
}

/*
 * Return the TagName with the given text or undefined
 * @sig :: tagNameForName :: (State, String) -> TagName|undefined
 */
const tagNameForName = (state, name) => {
    const tagNames = Object.values(state.tagNames)
    return tagNames.find(t => t.name === name)
}

/*
 * Return all TagNames enriched with their count of usage across the app
 * @sig :: tagDataPropTypes :: (State) => {...TagName, Number}
 */
const enrichedTagNames = state => {
    const allTagNames = ReduxSelectors.tagNameLookupTable(state)
    const allUploads = ReduxSelectors.uploadLookupTable(state)
    const allCollaborations = ReduxSelectors.collaborationLookupTable(state)
    const counts = {}
    Object.keys(allTagNames).forEach(tagId => (counts[tagId] = 0))
    Object.values(allUploads).forEach(upload => {
        upload.tagIds.forEach(tagId => (counts[tagId] += 1))
    })
    Object.values(allCollaborations).forEach(collaboration => {
        collaboration.tags.forEach(tagId => (counts[tagId] += 1))
    })
    const newAllTagNames = {}
    Object.values(allTagNames).forEach(tag => {
        newAllTagNames[tag.id] = { ...tag }
        newAllTagNames[tag.id].count = counts[tag.id]
    })
    return newAllTagNames
}

/*
 * Given an array of TagName ids, return the associated text for each tag
 * @sig namesFromTagNameIds :: (State, [Id]) -> [String]
 */
const namesFromTagNameIds = (state, tagNameIds) => {
    const tagNames = state.tagNames
    return tagNameIds.map(id => tagNames[id].name)
}

/*
 * Return the StatusName of the Collaboration
 * @sig statusNameForCollaboration :: (State, Collaboration) -> StatusName|undefined
 */
const statusNameForCollaboration = (state, collaboration) =>
    collaboration ? state.statusNames[collaboration.statusName] : undefined

/*
 * Return the StatusName of the currently-selected Collaboration
 * @sig statusOfSelectedCollaboration :: State -> StatusName
 */
const statusOfSelectedCollaboration = state => statusNameForCollaboration(state, selectedCollaboration(state))

/*
 * Return ONLY the geometries that meet the user's filter criteria (on the current canvas)
 * @sig _geometriesForSelectedCanvas :: State -> [Geometry]
 *
 */
const _geometriesForSelectedCanvas = state => {
    let collaborationShapes = filteredCollaborations(state)
    collaborationShapes = collaborationShapes.filter(cs => cs.canvasId === state.selectedCanvas)
    const geometryIds = collaborationShapes.map(c => c.geometry().id)
    return geometryIds.map(id => state.geometries[id])
}

/*
 * It's expensive to call _geometriesForSelectedCanvas, and it changes "rarely" and each call with the same
 * inputs will return a *different* array filled with the same Geometries, so memoize!
 *
 * Note/hack:
 * 'collaborations' is in this list because when a Geometry is added, the collaboration will possibly
 * have arrived earlier, in which case the CollaborationShape's canvas will not be around yet, because
 * the geometry has the canvas. We could fix this a couple of ways, including duplicating the canvas id in Firestore.
 * Because the CollaborationShape has no canvas, it is, by definition, not on the "current" canvas and never appears.
 * However, as it happens, we will definitely get storageVersion updates for a new collaboration AFTER we
 * have gotten the Collaboration's Geometry (and therefore its canvas) when the location snapshot arrives,
 * at which time we also have a canvas for the Collaboration, and it will appear.
 * DroneDeploy maps will not trigger updates, because we currently don't create locationSnapshots for these
 */
const geometriesForSelectedCanvas = memoizeReduxState(
    ['selectedCanvas', 'navigatorFilterSettings', 'geometries', 'collaborations'],
    _geometriesForSelectedCanvas
)

// prettier-ignore
const itemWithId = (state, Type, id) => {
    if (Type === Canvas             ) return  state.canvases            [id]
    if (Type === CanvasSource       ) return  state.canvasSources       [id]
    if (Type === CanvasSource.Pdf   ) return  state.canvasSources       [id]
    if (Type === Collaboration      ) return  state.collaborations      [id]
    if (Type === Comment            ) return  state.comments            [id]
    if (Type === Feature            ) return  state.features            [id]
    if (Type === Geometry           ) return  state.geometries          [id]
    if (Type === Invitation         ) return  state.invitations         [id]
    if (Type === Organization       ) return  state.organizations       [id]
    if (Type === Presence           ) return  state.presences           [id]
    if (Type === Project            ) return  state.projects            [id]
    if (Type === StatusName         ) return  state.statusNames         [id]
    if (Type === TagName            ) return  state.tagNames            [id]
    if (Type === Update             ) return  state.updates             [id]
    if (Type === Upload             ) return  state.uploads             [id]

    throw new Error("Don't understand Type " + Type)
}

// ---------------------------------------------------------------------------------------------------------------------
// Collaboration Selectors
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Return all the Collaborations for the currently-selected Feature
 * @sig _collaborationsForSelectedFeature :: State -> [Collaboration]
 */
const _collaborationsForSelectedFeature = state => {
    const featureId = state.selectedFeatureId
    return collaborationsAsArray(state).filter(c => c.feature === featureId)
}

/*
 * Return the first Collaboration for the currently-selected Feature
 * @sig firstCollaborationForSelectedFeature :: State -> Collaboration
 */
const firstCollaborationForSelectedFeature = state => {
    const featureId = state.selectedFeatureId

    return featureId ? _collaborationsForSelectedFeature(state)[0] : undefined
}

/*
 * Return the first Collaboration for the given geometryId
 * @sig firstCollaborationForGeometry :: State -> Collaboration
 */
const firstCollaborationForGeometry = (state, geometryId) => {
    const feature = firstFeatureForGeometry(state, geometryId)
    return feature ? collaborationsAsArray(state).find(c => c.feature === feature.id) : null
}

/*
 * Is the currently-selected Collaboration marked isCompleted
 * @sig isSelectedCollaborationCompleted :: State -> Boolean
 */
const isSelectedCollaborationCompleted = state => {
    const collaboration = state.collaborations[state.selectedCollaborationId]
    return collaboration.statusName === Collaboration.completedCollaborationStatus
}

/*
 * All the Updates for the selected Collaboration
 * @sig updatesForSelectedCollaboration :: State -> [Update]
 */
const updatesForSelectedCollaboration = state => {
    const { selectedCollaborationId } = state

    // no Collaboration selected, implies there are no selected Updates either
    return selectedCollaborationId ? updatesForCollaboration(state, selectedCollaborationId) : []
}

/*
 * All the comments for the selected Collaboration
 * @sig selectCommentsForCollaboration :: (State, Collaboration) -> [Comment]
 */
const commentsForSelectedCollaboration = state => {
    const { selectedCollaborationId } = state
    return filterValues(comment => comment.parentId === selectedCollaborationId, state.comments)
}

/*
 * Return the Feature with the given Geometry's id
 * @sig firstFeatureForGeometry :: (State, Id) -> Feature|undefined
 */
const firstFeatureForGeometry = (state, geometryId) => featuresAsArray(state).find(f => f.geometryId === geometryId)

/*
 * Return geometry for the given Collaboration
 * @sig geometryForCollaboration :: (State, Collaboration) -> Geometry
 */
const geometryForCollaboration = (state, collaboration) => {
    const featureLookup = state.features
    const geometryLookup = state.geometries
    const feature = featureLookup[collaboration.feature]
    return geometryLookup[feature.geometryId]
}

/*
 * Return all comments for the given Collaboration
 * @sig commentsForCollaboration :: (State, Collaboration) -> [Comment]
 */
const commentsForCollaboration = (state, collaboration) =>
    collaboration ? filterValues(comment => comment.parentId === collaboration.id, commentsAsArray(state)) : []

/*
 * Return all uploads for the given Collaboration
 * @sig uploadsForCollaboration :: (State, Collaboration) -> [Upload]
 */
const uploadsForCollaboration = (state, collaboration) =>
    collaboration ? filterValues(upload => upload.parentId === collaboration.id, uploadsAsArray(state)) : []

/*
 * Return canvas for given Collaboration
 * @sig canvasForCollaboration :: (State, Collaboration) -> Canvas
 */
const canvasForCollaboration = (state, collaboration) => {
    const geometry = geometryForCollaboration(state, collaboration)
    return state.canvases[geometry.canvasId]
}

/*
 * Return all commentShapes for the given Upload
 * @sig commentShapesForUpload :: (State, Upload) -> [CommentShape]
 */
const _commentShapesForUpload = (state, upload) => {
    if (!upload) return []
    const allCommentShapes = generateCollaborationShapes(state).comments
    return allCommentShapes.filter(c => c.parentId === upload.id)
}

const commentShapesForUpload = memoizeReduxState(['uploads', 'collaborations', 'comments'], _commentShapesForUpload)

// return the Collaboration for the upload
const collaborationForUpload = (state, upload) => collaborationsAsArray(state).find(c => upload?.parentId === c.id)

// return correlated uploads for the upload
const correlatedUploads = (state, upload) => {
    const uploadsLookup = state.uploads
    return Object.values(uploadsLookup)
        .filter(el => el.correlationId === upload.correlationId)
        .sort((a, b) => new Date(a.date) - new Date(b.date))
}

/*
 * Return ONLY the canvases that actually have geometries on them
 * @sig canvasesWithGeometries :: State -> [Canvas]
 */
const canvasesWithGeometries = state => {
    const { geometries, canvases } = state

    const canvasIdsUsedByGeometries = pluck('canvasId', Object.values(geometries))
    return Object.values(pick(canvasIdsUsedByGeometries, canvases))
}

/*
 * Note: Assuming a maximum of 1 PDF canvasSource per canvas
 * @sig pdfCanvasSource :: (State, Canvas) -> CanvasSource.Pdf|undefined
 */
const pdfCanvasSource = (state, canvas) =>
    Object.values(state.canvasSources).find(cs => cs.canvasId === canvas.id && cs.type === 'pdf')

/*
 * Note: Assuming a maximum of 1 PDF canvasSource per canvas
 * @sig pdfCanvasSourceForSelectedCanvas :: (State) -> CanvasSource.Pdf|undefined
 */
const pdfCanvasSourceForSelectedCanvas = state => {
    const canvas = state.canvases[state.selectedCanvas]
    return canvas ? pdfCanvasSource(state, canvas) : undefined
}

/*
 * Return the CanvasSources for the given Canvas; for a Pdf there should be 1, but it can be > 1 for other types
 * @sig canvasSourcesForCanvas :: (State, Canvas) -> [CanvasSource]
 */
const canvasSourcesForCanvas = (state, canvas) =>
    Object.values(state.canvasSources).filter(cs => cs.canvasId === canvas.id)

/*
 * Return the CanvasSources for the selected Canvas; for a Pdf there should be 1, but it can be > 1 for other types
 */
const canvasSourcesForSelectedCanvas = state =>
    Object.values(state.canvasSources).filter(cs => cs.canvasId === state.selectedCanvas)

/*
 * Sorted array of all canvases: ALL PDF's (sorted by name), followed by all non-PDF's (in no particular order)
 * @sig canvasesAsArray :: State -> [Canvas]
 */
const canvasesAsArray = state => {
    const orderPDFsFirstThenByName = (canvas1, canvas2) => {
        const pdfCanvasSource1 = pdfCanvasSource(state, canvas1)
        const pdfCanvasSource2 = pdfCanvasSource(state, canvas2)

        // one or both are not PDF's
        if (!pdfCanvasSource1 && !pdfCanvasSource2) return 0
        if (!pdfCanvasSource1 && pdfCanvasSource2) return -1
        if (pdfCanvasSource1 && !pdfCanvasSource2) return 1

        // sort PDF's by name (temporary)
        // TODO: Make it use canvas ordering once it's in place
        return pdfCanvasSource1.name.localeCompare(pdfCanvasSource2.name)
    }

    return Object.values(state.canvases).sort(orderPDFsFirstThenByName)
}

/*
 * Return the Canvas and its first CanvasSource
 * @sig canvasAndFirstSourceForId :: (State, Id) -> { canvas: Canvas, canvasSource: CanvasSource }
 */
const __canvasAndFirstSourceForId = (state, canvasId) => {
    const canvas = state.canvases[canvasId]
    return { canvas, canvasSource: canvasSourcesForCanvas(state, canvas)[0] }
}

// non-partial (and memoized) version of canvasAndFirstSourceForId
const _canvasAndFirstSourceForId = memoizeReduxState(
    ['canvasId', 'canvases', 'canvasSources'],
    __canvasAndFirstSourceForId
)

// partial version of canvasAndFirstSourceForId calls non-partial version
const canvasAndFirstSourceForId = canvasId => state => _canvasAndFirstSourceForId(state, canvasId)

/*
 * All CanvasSource.Pdf's sorted by name
 *
 * @sig _pdfCanvasSources :: State -> [CanvasSource.Pdf]
 */
const pdfCanvasSources = state => {
    const canvasSources = Object.values(state.canvasSources)
    const pdfCanvasSources = canvasSources.filter(cs => CanvasSource.Pdf.is(cs))
    return pdfCanvasSources.sort((a, b) => a.name.localeCompare(b.name))
}

/*
 * All Canvases that have a related CanvasSource.Pdf
 * @sig pdfCanvases :: State -> [Canvas]
 */
const pdfCanvases = state => {
    const canvasSourceIds = pluck('canvasId', pdfCanvasSources(state))
    const canvases = pick(canvasSourceIds, state.canvases)
    return Object.values(canvases)
}

/*
 * Return all the Canvases and related CanvasSources for PDF's
 * @ sig pdfCanvasesAndTheirSources :: State -> [[Canvas], [CanvasSource.Pdf]saf
 */
const pdfCanvasesAndTheirSources = state => [pdfCanvases(state), pdfCanvasSources(state)]

/*
 * Return a lookup table where for each Collaboration a single value is assigned - its most recent update date.
 * It takes into account collaboration creation date, all updates to that collaboration (status, due date, assignee)
 * and creation dates for related entities - comments and uploads
 * @sig recentCollaborationUpdateLookup :: State -> {Id: Date}
 */
const recentCollaborationUpdateLookup = state => {
    const updatesLookup = empty
    Object.values(state.collaborations).forEach(c => (updatesLookup[c.id] = [c.date]))

    // we are only interested in entities related to an existing collaboration
    const filterApplicable = e => e.parentType === 'Collaboration' && !!updatesLookup[e.parentId]
    const updatesFiltered = Object.values(state.updates).filter(filterApplicable)
    const uploadsFiltered = Object.values(state.uploads).filter(filterApplicable)
    const commentsFiltered = Object.values(state.comments).filter(filterApplicable)

    const entities = [...updatesFiltered, ...uploadsFiltered, ...commentsFiltered]
    entities.forEach(e => updatesLookup[e.parentId].push(e.date))

    return mapObject(dates => dates.sort((a, b) => b - a)[0], updatesLookup)
}

// :: State -> CollaborationShape
const collaborationShapeForSelectedCollaboration = state => {
    const collaboration = selectedCollaboration(state)
    const shapeLookupTable = ReduxSelectors.allShapes(state)
    return CollaborationShape.fromCollaboration(shapeLookupTable)(collaboration)
}

/*
 * Return all Projects where:
 *
 * - the project is for the currently-selected organization
 * - project.participants includes participant.id
 * - the participant is not suspended
 *
 * @sig currentProjectsForParticipant :: (State, Participant) -> { Id: Project }
 */
const currentProjectsForParticipant = (state, participant) => {
    const projectHasParticipant = project => {
        const projectParticipant = project.participants?.[participant.id]
        return projectParticipant && !projectParticipant.isSuspended && organizationProjects.includes(project.id)
    }

    const organizationProjects = selectedOrganization(state).projectIds
    return filterObject(projectHasParticipant, state.projects)
}

/*
 * Return all the Projects for the currently-selected Organization
 * @sig organizationProjectAsArray :: State => [Project]
 */
const organizationProjectAsArray = state => {
    const organization = selectedOrganization(state)
    if (!organization) return []

    // include only elements of state.projects that match one of the organization.projectIds
    const { projectIds: organizationProjectIds } = organization
    const projects = filterObject(p => organizationProjectIds.includes(p.id), state.projects)
    return Object.values(projects)
}

/*
 * Return the organizationRole for the selected user within the selected organization
 * @sig selectedUsersRoleInSelectedOrganization :: state -> 'Admin'|'Collaborator'|'Guest'|undefined
 *
 */
const selectedUsersRoleInSelectedOrganization = state => {
    if (!state.selectedUserId || !state.selectedOrganizationId) return undefined
    const participants = selectedOrganization(state)?.participants
    if (!participants) return undefined

    const participant = participants[state.selectedUserId]
    return participant.organizationRole
}

/*
 * Return the expected for Segment for ANY track event
 */
const _paramsForTrackEvent = state => {
    const userId = state.selectedUserId
    const groupId = state.selectedOrganizationId
    const organizationRole = selectedUsersRoleInSelectedOrganization(state)
    const params = { groupId, userId, platform: 'web', organizationRole }

    return groupId && userId && organizationRole && params
}

const paramsForTrackEvent = memoizeReduxState(['selectedUserId', 'selectedOrganizationId'], _paramsForTrackEvent)

/*
 * Return the complicated data expected for Segment when reporting on a Collaboration
 */
const paramsForCollaborationTrackEvent = state => {
    const collaboration = selectedCollaboration(state)
    const canvasSource = state.canvasSources[state.selectedCanvasSource]
    const collaborationType = Collaboration.isTask(collaboration) ? 'task' : 'photo'
    const collaborationTitle = collaboration.name || ''
    const canvasType = canvasSource.type
    const paramsForAnyTrackEvent = paramsForTrackEvent(state)

    const valid = paramsForAnyTrackEvent && collaborationType && canvasType
    return valid && mergeRight(paramsForAnyTrackEvent, { collaborationType, canvasType, collaborationTitle })
}

/*
 * Return the data expected for Segment when reporting on a new Project
 */
const paramsForProjectTrackEvent = (state, { name, organizationId, projectType }) => {
    const organization = state.organizations[organizationId]
    const organizationName = organization.name

    const paramsForAnyTrackEvent = paramsForTrackEvent(state)
    return mergeRight(paramsForAnyTrackEvent, { name, projectType, organizationName })
}

// return mapPosition for the selectedCanvas (or undefined, if there is none yet)
const defaultPosition = { center: { lng: 0, lat: 0 }, zoom: 21 }
const mapPosition = state => state.mapPositionsHistory[state.selectedCanvas] ?? defaultPosition

/*
 * Return mapPositions, first Canvas and first CanvasSource for the project
 * @sig initialDataForSelectedProject :: State -> { {Id: MapPosition}, Canvas, CanvasSource }
 *  MapPosition = { center: { lng: Number, lat: Number }, zoom: Number }
 */
const initialDataForSelectedProject = state => {
    // pull the center from either the first CanvasSource for the canvas, or (for a PDF) from the project's center
    const mapPositionForCanvasId = (acc, canvasId) => {
        const canvas = state.canvases[canvasId]
        const canvasSource = canvasSourcesForCanvas(state, canvas)[0]
        const center = canvasSource.center ?? project.center // [lng, lat]

        const mapPosition = { center: { lng: center[0], lat: center[1] }, zoom: canvasSource.zoom ?? 19.8 }
        return assoc(canvasId, mapPosition, acc)
    }

    const project = state.projects[state.selectedProjectId]
    const mapPositions = project.canvasOrder.reduce(mapPositionForCanvasId, {})
    const firstCanvasId = project.canvasOrder[0]
    const firstCanvas = state.canvases[firstCanvasId]
    const firstCanvasSource = Object.values(filterObject(cs => cs.canvasId === firstCanvasId, state.canvasSources))[0]
    return { mapPositions, firstCanvas, firstCanvasSource }
}

// return the storageVersion of the Collaboration or Upload with the given entityId
const storageVersionForEntity = entityId => state => {
    const entity = state.collaborations[entityId] || state.uploads[entityId]
    return entity?.storageVersion
}

const invitationsForSelectedOrganization = state =>
    Object.values(state.invitations).filter(i => i.organizationId === state.selectedOrganizationId)

const organizationParticipantsAsArray = state => {
    const participants = Object.values(state?.organizations?.[state?.selectedOrganizationId]?.participants || {})
    const organizationRole = selectedUsersRoleInSelectedOrganization(state)

    // if user is admin he should have access to all organization users
    if (organizationRole === UserRoles.ADMIN || !participants.length) return participants

    const orgProjects = organizationProjectAsArray(state)

    // if user is not an admin he should have access to see:
    // - the admins
    // - themself
    // - only organization users that are assigned (or were once assigned) to the same projects
    return participants.filter(
        participant =>
            participant.organizationRole === UserRoles.ADMIN ||
            participant.id === state.selectedUserId ||
            orgProjects.some(project => Object.keys(project.participants).includes(participant.id))
    )
}

const collaborationWithId = (state, collaborationId) => state.collaborations[collaborationId]
const isProjectDataLoaded = state => Object.keys(state.canvases).length > 0

const isRangeClosed = state => !!state.rangeStatus?.isClosed

const ReduxSelectors = {
    // canvases
    canvasAndFirstSourceForId,
    canvasLookupTable,
    canvasesAsArray: memoizeResult(canvasesAsArray),
    canvasesWithGeometries,
    pdfCanvases,
    selectedCanvas,
    selectedCanvasId,

    // canvasSources
    canvasSources: canvasSourceLookupTable,
    canvasSourcesForSelectedCanvas: memoizeResult(canvasSourcesForSelectedCanvas),
    pdfCanvasSource,
    pdfCanvasSourceForSelectedCanvas,
    pdfCanvasSources,
    pdfCanvasesAndTheirSources,
    selectedCanvasSource,

    // collaborations
    canvasForCollaboration,
    collaborationLookupTable,
    collaborationShapeForSelectedCollaboration: memoizeResult(collaborationShapeForSelectedCollaboration),
    collaborationWithId,
    collaborationsAsArray,
    commentsForCollaboration,
    commentsForSelectedCollaboration,
    firstCollaborationForGeometry,
    firstCollaborationForSelectedFeature,
    geometryForCollaboration,
    isSelectedCollaborationCompleted,
    recentCollaborationUpdateLookup: memoizeResult(recentCollaborationUpdateLookup),
    selectedCollaboration,
    selectedCollaborationId,
    shouldFocusCollabWindow,
    statusNameForCollaboration,
    statusOfSelectedCollaboration,
    taskListFilterSettings,
    updatesForCollaboration,
    updatesForSelectedCollaboration,
    uploadLookupTable,
    uploadsForCollaboration,

    // comments
    commentLookupTable,

    // features
    selectedFeature,

    // filters
    filteredCollaborations,
    filteredTaskList,
    filteredMediaView,

    // geometries
    geometries: geometriesAsArray,
    geometriesForSelectedCanvas,
    navigatorFilterSettings,
    navigatorSortSettings,
    selectedGeometries,
    selectedGeometryIds,

    // loggedInStatus
    isAwaitingEmailVerification: state => state.loggedInStatus === LoggedInStatus.awaitingEmailVerification,
    isLoggedIn: state => state.loggedInStatus === LoggedInStatus.loggedIn,
    isLoggedOut: state => state.loggedInStatus === LoggedInStatus.loggedOut,
    isLoginStatusKnown: state => state.loggedInStatus !== LoggedInStatus.unknown,
    loggedInStatus: state => state.loggedInStatus,

    // map
    cursor,
    drawingMode,
    mapPosition,
    mapPositionsHistory,
    selectedTool,
    showCanvasSnippetMode,

    // media
    mediaViewFilterSettings,
    selectedMediaUploadsIds,

    // presences
    presences: memoizeResult(presences),
    presentProjectParticipants: memoizeResult(presentProjectParticipants),

    // organizations
    invitations: memoizeResult(invitationsForSelectedOrganization),
    organizationLookupTable,
    organizationParticipantsAsArray: memoizeResult(organizationParticipantsAsArray),
    organizationProjectAsArray: memoizeResult(organizationProjectAsArray),
    organizationsAsArray,
    selectedOrganization,
    selectedOrganizationId,
    selectedUsersRoleInSelectedOrganization,

    // participants
    organizationParticipantWithId,
    selectedProjectParticipantShapeWithId,
    selectedProjectParticipantShapes: memoizeResult(selectedProjectParticipantShapes),
    selectedProjectParticipantShapesAsArray: memoizeResult(selectedProjectParticipantShapesAsArray),
    selectedProjectParticipantWithId,
    selectedProjectParticipants,

    // permissions
    isCreateAllowed,
    isDeleteAllowed,
    isUpdateAllowed,

    // projects
    allProjectMetrics,
    currentProjectsForParticipant,
    currentlyLoadingProject,
    isLoadingInitialData,
    initialDataForSelectedProject,
    isProjectDataLoaded,
    projectLookupTable,
    projectsAsArray,
    selectedProject,
    selectedProjectId,
    userProjectsAsArray,
    userProjectsAsLookupTable,
    userProjectsForSelectedOrganizationAsArray: memoizeResult(userProjectsForSelectedOrganizationAsArray),

    // segment
    paramsForCollaborationTrackEvent,
    paramsForProjectTrackEvent,
    paramsForTrackEvent,

    // statusNames
    statusNames: statusNameLookupTable,
    statusNamesAsArray,

    // tagNames
    enrichedTagNames: memoizeResult(enrichedTagNames),
    tagNameForName,
    tagNameLookupTable,
    textsFromTagNameIds: namesFromTagNameIds,

    // toasts
    showAccountCreatedToast,
    toastLookupTable,

    // updates
    updatesLookupTable,

    // uploads
    collaborationForUpload,
    commentShapesForUpload,
    uploadsAsArray,
    correlatedUploads,

    // users
    selectedUser,
    selectedUserId,
    notificationPreferences,
    knockUserToken,

    // generic
    itemWithId,

    allShapes: state => generateCollaborationShapes(state),

    // miscellaneous
    globalModalData,
    pdfLoadingState,
    backgroundMapLoadingState,
    showTimelineDetails,
    storageVersionForEntity,
    isRangeClosed,
}

export default ReduxSelectors
