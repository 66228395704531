/*
 * See Readme
 */

import { Project } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { equals, firstKey, taggedSum } from '@range.io/functional'
import * as Firestore from 'firebase/firestore'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import { isUpdateAllowed } from '../../redux/slices/redux-selectors-permissions.js'
import { enrichDeletedFields } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// ProjectChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const ProjectChangedCommand = taggedSum('ProjectChangedCommand', {
    Inbound: {
        project: 'Project',
    },
    Outbound: {
        id: StringTypes.Id, // Project id
        changes: 'Object', // each entry defines key/value of the existing Project that changed
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A ProjectChangedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Project)
 */
const runInboundCommand = (resources, command) => {
    const { dispatch, getState } = resources
    const project = command.project
    const oldProject = ReduxSelectors.itemWithId(getState(), Project, project.id)

    // new project can't have participants since they come from a different document collection
    if (project.participants) throw new Error('How did project get participants?')

    const projectWithOldParticipants = Project.update(project, { participants: oldProject.participants })
    dispatch(ReduxActions.projectAdded(projectWithOldParticipants))
}

/*
 * A ProjectChangedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 * TODO: this implementation is the same as the "simple" one -- but has a document path rather than a collection path
 */
const runOutboundCommand = async (resources, command) => {
    const { id, changes } = command
    const { dispatch, displayError, getState, runTransaction, firestore } = resources
    const oldItem = ReduxSelectors.itemWithId(getState(), Project, id)
    const field = firstKey(changes)
    const oldValue = oldItem[field]
    const newValue = changes[field]

    if (equals(oldValue, newValue)) return // no change

    const { id: _, ...changesWithoutId } = enrichDeletedFields(Project.toFirebase(changes))

    if (!isUpdateAllowed('project', changesWithoutId)(getState())) return

    try {
        const path = Firestore.doc(firestore, `/projects/${id}`)
        await runTransaction(async transaction => {
            transaction.update(path, changesWithoutId)
        })
        dispatch(ReduxActions.projectChanged({ id, changes }))

        // show the toast when archiving/unarchiving
        if (changesWithoutId.isArchived !== undefined) {
            let toastLabel = `Your project has been successfully unarchived`
            if (changesWithoutId.isArchived) {
                toastLabel = `Your project has been successfully archived`
            }
            const toast = { id, severity: 'success', toastLabel, showUndo: false }
            dispatch(ReduxActions.toastAdded(toast))
        }
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        ProjectChangedCommand,
        CommandPlayer({
            CommandType: ProjectChangedCommand,
            Type: Project,
            documentPath: projectId => `/projects/${projectId}`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
        })
    )
}

export { addCommand, ProjectChangedCommand }
