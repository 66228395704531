import React from 'react'
import { AvatarWithTooltip, Image } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const IconWrapper = styled('div', {
    overflow: 'hidden',
    display: 'inline-flex',
    boxSizing: 'border-box',

    variants: {
        circle: {
            true: {
                borderRadius: '100%',
                border: '1px solid $neutral07',
                background: '$neutral10',
            },
        },
    },
})

const OrganizationIcon = ({ src, name, css, size = 32, circle }) => {
    return src ? (
        <IconWrapper css={{ ...css, width: size, height: size }} circle={circle}>
            <Image src={src} alt={`${name}`} />
        </IconWrapper>
    ) : (
        <AvatarWithTooltip size={size.toString()} color="gray" fallbackText={name[0]} tooltipText={name} />
    )
}

export default OrganizationIcon
