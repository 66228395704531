import { Collaboration, Feature, FeedItem, Geometry } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import { itemFromFeedItem, loadItemFromFirestoreDocument } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// CollaborationAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const CollaborationAddedCommand = taggedSum('CollaborationAddedCommand', {
    Inbound: { feedItem: 'FeedItem' },
    // NO Outbound: always done by GeometriesAddedCommand.Outbound
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A Collaboration FeedItem arrived; read the Collaboration and its Feature and Geometry and send them all to redux
 */
const runInboundCommand = async (resources, command) => {
    const collaboration = await itemFromFeedItem(Collaboration, 'Collaboration', 'added', command.feedItem)
    if (!collaboration) return

    const { featureId, geometryId, projectId } = collaboration
    const feature = await loadItemFromFirestoreDocument(Feature, `projects/${projectId}/features/${featureId}`)
    const geometry = await loadItemFromFirestoreDocument(Geometry, `projects/${projectId}/geometries/${geometryId}`)

    const { dispatch } = resources
    dispatch(ReduxActions.collaborationAdded(collaboration))
    dispatch(ReduxActions.featureAdded(feature))
    dispatch(ReduxActions.geometryAdded(geometry))
}

/*
 * A CollaborationAddedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 * BUT these currently ALL are handled in the transaction in GeometriesAddedCommand
 */
const runOutboundCommand = async (resources, command) => {
    throw new Error('CollaborationAddedCommand.runOutboundCommand should never have been called')
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CollaborationAddedCommand,
        CommandPlayer({
            CommandType: CollaborationAddedCommand,
            Type: FeedItem,
            collectionPath: (projectId, userId) => `/projects/${projectId}/participants/${userId}/feedItems`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
        })
    )
}

export { CollaborationAddedCommand, addCommand }
