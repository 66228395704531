/*
 * See Readme
 */

import { Canvas } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer, { simpleInboundChangedRunCommand, simpleOutboundChangedRunCommand } from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// CanvasChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const CanvasChangedCommand = taggedSum('CanvasChangedCommand', {
    Inbound: {
        canvas: 'Canvas',
    },
    Outbound: {
        id: StringTypes.Id, // Canvas id
        changes: 'Object', // each entry defines key/value of the existing Canvas that changed
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A CanvasChangedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Canvas)
 */
const runInboundCommand = (resources, command) =>
    simpleInboundChangedRunCommand(resources, command, Canvas, ReduxActions.canvasAdded, command => command.canvas)

/*
 * A CanvasChangedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) =>
    await simpleOutboundChangedRunCommand(resources, command, Canvas, ReduxActions.canvasChanged, 'canvases')

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CanvasChangedCommand,
        CommandPlayer({
            CommandType: CanvasChangedCommand,
            Type: Canvas,
            collectionPath: projectId => `/projects/${projectId}/canvases/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
        })
    )
}

export { CanvasChangedCommand, addCommand }
