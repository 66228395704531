import { assoc } from '@range.io/functional'
import React from 'react'
import { Text } from './Text.js'

/*
 * Write the userName with a line-through if they're suspended
 */
const PossiblySuspendedUserName = ({
    css = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    participantShape,
    ellipsed = false,
    ...restProps
}) => {
    css = participantShape.isSuspended ? assoc('textDecoration', 'line-through', css) : css
    const fullName = participantShape.fullName
    return (
        <Text ellipsed={ellipsed} css={css} {...restProps}>
            {fullName}
        </Text>
    )
}

export default PossiblySuspendedUserName
