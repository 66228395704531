/*
 * See Readme
 */

import { Organization, Project } from '@range.io/basic-types'
import { arrayToLookupTable, assoc, filter, mergeRight, omit } from '@range.io/functional'
import { ReduxSelectors } from '../../../redux/index.js'
import { loadItemAndParticipants } from '../../firebase-facade.js'

/*
 * We're reloading a single organization which may have more or fewer projects, participants, suspended participants (etc.) than before
 * reloadOrganization :: (State, Id) -> Promise { organizations: {k:v}, projects: {k:v} }
 */
const reloadOrganization = async (state, organizationId) => {
    const iCanSeeProject = projectId => permissions.projects.includes(projectId)
    const loadProject = async projectId => await loadItemAndParticipants(Project, `/projects/${projectId}`)

    // load the newOrganization and replace any old value for it in organizations
    const oldOrganizations = ReduxSelectors.organizationLookupTable(state)
    const oldOrganization = oldOrganizations[organizationId]
    const newOrganization = await loadItemAndParticipants(Organization, `/organizations/${organizationId}`)
    const newOrganizations = assoc(organizationId, newOrganization, oldOrganizations)

    // restrict to only the projects I'm allowed to see
    const permissions = ReduxSelectors.selectedUser(state).permissions
    const oldProjects = ReduxSelectors.projectLookupTable(state)
    const myProjects = newOrganization.projectIds.filter(iCanSeeProject)

    // load all of my projects
    const newProjectPromises = myProjects.map(loadProject)
    let newProjects = await Promise.all(newProjectPromises)
    newProjects = arrayToLookupTable('id', newProjects)

    // remove OLD projects no longer part of newOrganization.projectIds
    const obsoleteProjectIds = filter(p => !myProjects.includes(p), oldOrganization?.projectIds || [])
    const projectsWithoutObsolete = omit(obsoleteProjectIds, oldProjects)

    // now that we have removed the old projects, we can merge in the new ones
    const projects = mergeRight(projectsWithoutObsolete, newProjects)

    return { organizations: newOrganizations, projects }
}

export default reloadOrganization
