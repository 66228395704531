import { Box, TextArea } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledFeatureDataBar = styled(Box, {
    display: 'flex',
    flexDirection: 'column',
    color: '$neutral01',
    rowGap: '12px',
    backgroundColor: '$neutral10',
    zIndex: 201,
    position: 'relative',
    variants: {
        variant: {
            task: { padding: '24px 24px 24px 24px' },
            photo: { padding: '24px 24px 12px 24px' },
        },
    },
    defaultVariants: {
        variant: 'task',
    },
})

const StyledNameInput = styled(TextArea, {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '27px',
    border: 'none',
    background: 'none',
    br: '0px',

    '&:hover': { border: 'none' },
    '&:active': { border: 'none' },
    '&:focus': { border: 'none' },
    '&::placeholder': { border: 'none', color: '$neutral05' },
})

export { StyledFeatureDataBar, StyledNameInput }
