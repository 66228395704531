/*
 * See Readme
 */

import { TagName } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer, { simpleInboundRemovedRunCommand, simpleOutboundRemovedRunCommand } from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// TagNameRemovedCommand
// ---------------------------------------------------------------------------------------------------------------------
const TagNameRemovedCommand = taggedSum('TagNameRemovedCommand', {
    Inbound: { item: 'TagName' },
    Outbound: { item: 'TagName' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A TagNameRemovedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the TagName)
 */
const runInboundCommand = (resources, command) =>
    simpleInboundRemovedRunCommand(resources, command, TagName, ReduxActions.tagNameRemoved)

/*
 * A TagNameRemovedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) =>
    await simpleOutboundRemovedRunCommand(
        resources,
        command,
        TagName,
        'tagNames',
        ReduxActions.tagNameRemoved,
        ReduxActions.tagNameAdded
    )

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        TagNameRemovedCommand,
        CommandPlayer({
            CommandType: TagNameRemovedCommand,
            Type: TagName,
            collectionPath: projectId => `/projects/${projectId}/tagNames/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'removed',
        })
    )
}

export { TagNameRemovedCommand, addCommand }
