/*
 * Basic component to display User name, that also has an action button that can be assigned actions (like removing a User or clearing an input that's inside the pill).
 */

import PropTypes from 'prop-types'
import React from 'react'
import { Box, FlexRow, Icon, Tooltip } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledPillButton = styled(Box, {
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    background: '$primary04',
    transitionDuration: '0.4s',
    mr: '-5px',

    '&:hover': {
        cursor: 'pointer',
        background: '$primary06',
    },
})

const StyledPill = styled(FlexRow, {
    padding: '5px 10px 5px 10px',
    gap: '8px',
    color: '$neutral04',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid $neutral07',
    borderRadius: '1000px',
    width: 'fit-content',
    userSelect: 'none',
    flexWrap: 'nowrap',
    alignItems: 'center',
    background: '$primary02',
    fw: 500,
    maxHeight: 32,

    variants: {
        outlined: {
            true: {
                border: '1px solid $primary04',
            },
        },
    },
})

const Pill = ({
    children,
    outlined,
    tooltipText = 'Remove',
    onDeselect,
    onClick = () => {},
    showDeselectButton = true,
}) => {
    const _onDeselect = e => {
        e.stopPropagation()
        onDeselect()
    }

    return (
        <StyledPill outlined={outlined} onClick={onClick}>
            {children}
            {showDeselectButton && (
                <Tooltip tooltipText={tooltipText} side="bottom" align="center">
                    <StyledPillButton onClick={_onDeselect}>
                        <Icon name="close" css={{ size: '8px', color: '$neutral09' }} />
                    </StyledPillButton>
                </Tooltip>
            )}
        </StyledPill>
    )
}

Pill.propTypes = {
    outlined: PropTypes.bool, // variant
    showDeselectButton: PropTypes.bool,
    onDeselect: PropTypes.func,
    onClick: PropTypes.func,
    tooltipText: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default Pill
