/*
 * KnockNotificationDropdown
 *
 * Render a Knock Feed using the standard Knock components:
 *
 * - KnockFeedProvider
 * - NotificationIconButton
 * - NotificationFeedPopover
 * - NotificationCell
 *
 * To allow filtering, calls to NotificationCell are wrapped in another Component which is passed into
 * KnockNotificationDropdown: KnockItemCell.
 *
 * We define 3 such components, each of which filters Knock "items" based on different criteria
 *
 * - OnlyOrganizationItemsCell
 * - OnlyCollaborationItemsCell
 * - OnlyProjectItemsCell
 *
 * Copied from Knock sample code: https://github.com/knocklabs/javascript/tree/main/packages/react
 */
import { KnockFeedProvider, NotificationCell, NotificationFeedPopover, NotificationIconButton } from '@knocklabs/react'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RangeUITheme } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'

const KNOCK_FEED_ID = '58be23cc-2ccd-48a5-87b5-7e9fcc175027' // in-app

/* ---------------------------------------------------------------------------------------------------------------------
 * Preprocess 'body' blocks in a Knock item
 *
 * Knock's NotificationCell component renders Knock `item` objects.
 * In particular, it renders the 'rendered' field from the 'body' block of the item.
 *
 * Our Knock workflows produces HTML text that look like this (with standardized meanings for classes
 * project, subject, label and message).
 *
 *    <p class="project">5451 Vanderbilt Ave</p>
 *    <p class="subject"><b>1-1 • Repair water damage</b></p>
 *    <p class="label">Sam Atmore says</p>
 *    <p class="message"><blockquote>"Starting on the polygon"</blockquote></p>
 *
 * However, in some cases we don't want to show all these paragraphs:
 *
 * - at the organization level we need the project and subject
 * - at the project level, the project is superfluous, since we know which project we're in
 * - at the collabotration level, both the project and subject are superfluous
 *
 * So: we preprocess each item we get from Knock by removing paragraphs before sending the text to the
 * NotificationCell.
 *
 * NOTE: we could just implement our own version of NotificationCell, but NotificationCell is fairly complicated.
 *
 * Here's a more complete example of a Knock `item`:
 *
 *  {
 *   __cursor: 'g3QAAA...',
 *   __typename: 'FeedItem',
 *   activities: [...],
 *   actors: [...],
 *   archived_at: null,
 *       blocks: [
 *         {
 *             content: '{% if collaborationName == ""%}\n{% assign name = "Untitled Pin" %}\n{% else %}\n{% assign name = collaborationName %}\n{% endif %}\n\n<p class="project">{{projectName}}</p>\n<p class="subject"><b>{{projectIdentifier}}-{{collaborationIdentifier}} • {{ name }}</b></p>\n\n{% for activity in activities %}\n\n{% if activity.action == "added-comment-to-collaboration" %}\n<p class="label">{{ activity.actor.name }} says</p>\n<p class="message"><blockquote>"{{activity.commentText}}"</blockquote></p>\n{% endif %}\n\n{% endfor %}\n\n',
 * ====>       name: 'body',
 * ====>       rendered: '<p class="project">5451 Vanderbilt Ave</p><p class="subject"><b>1-1 • Repair water damage</b></p><p class="label">Sam Atmore says</p><p class="message"><blockquote>"Starting on the polygon"</blockquote></p>',
 *             type: 'markdown',
 *         },
 *         {
 *             content: '/{{ organizationId }}/{{ projectId }}/{{ canvasId }}/?c={{ collaborationId }}',
 *             name: 'action_url',
 *             rendered: '/c39f1e8b-cabb-44c9-822e-e0d7ee92a90d/474503d1-1adc-4268-88fe-2a797148f16d/e5db7eb2-bd9f-44ba-9c90-e6e6e528b930/?c=13083d62-03a1-43d7-8eb0-ca5bb8b357f3',
 *             type: 'text',
 *         },
 *      ],
 *  }
 *
 * ------------------------------------------------------------------------------------------------------------------ */

/*
 * Remove any element of the HTML that has any of the classes we've passed in and return the result
 *
 * Example: removeHtmlClasses(['project', 'subject'], text), where the text is:
 *
 *    <p class="project">5451 Vanderbilt Ave</p>
 *    <p class="subject"><b>1-1 • Repair water damage</b></p>
 *    <p class="label">Sam Atmore says</p>
 *    <p class="message"><blockquote>"Starting on the polygon"</blockquote></p>
 *
 * returns:
 *
 *    <p class="label">Sam Atmore says</p>
 *    <p class="message"><blockquote>"Starting on the polygon"</blockquote></p>
 *
 * @sig removeHtmlClasses :: ([String], HTML) -> HTML
 */
const removeHtmlClasses = (classes, text) => {
    const removeHtmlClass = clazz => doc.querySelectorAll('.' + clazz).forEach(el => el.remove())

    const parser = new DOMParser()
    const doc = parser.parseFromString(text, 'text/html')

    classes.map(removeHtmlClass)
    return doc.body.innerHTML
}

/*
 * Call removeHtmlClasses on the item's 'body' blocks
 * Impure: modifies item in place
 */
const removeClassesFromKnockItem = (classes, item) => {
    const possiblyRemoveProjectClass = b => {
        if (b.name === 'body') b.rendered = removeHtmlClasses(classes, b.rendered)
    }

    item.blocks.forEach(possiblyRemoveProjectClass)
}

/*
 * Render a NotificationCell only for items that have same collaborationId as the currently selected Collaboration
 */
const OnlyCollaborationItemsCell = props => {
    const { item } = props
    removeClassesFromKnockItem(['project', 'subject'], item)
    const selectedCollaborationId = useSelector(ReduxSelectors.selectedCollaborationId)

    const showItem = item?.data?.collaborationId === selectedCollaborationId
    return showItem ? <NotificationCell {...props} /> : null
}

/*
 * Render a NotificationCell only for items that have same projectId as the currently selected Project
 */
const OnlyProjectItemsCell = props => {
    const { item } = props
    removeClassesFromKnockItem(['project'], item)
    const selectedProjectId = useSelector(ReduxSelectors.selectedProjectId)

    const showItem = item?.data?.projectId === selectedProjectId
    return showItem ? <NotificationCell {...props} /> : null
}

/*
 * Render a NotificationCell only for items that have same organizationId as the currently selected Organization
 * However, a new user may not HAVE an organizationId at the moment we send them a welcome email.
 * So, as a special case, if there is NO organizationId, then we allow the message through
 */
const OnlyOrganizationItemsCell = props => {
    const { item } = props
    const selectedOrganizationId = useSelector(ReduxSelectors.selectedOrganizationId)

    const organizationId = item?.data?.organizationId
    const showItem = organizationId === undefined || organizationId === selectedOrganizationId

    return showItem ? <NotificationCell {...props} /> : null
}

/*
 * Render the Knock messages in their standard popover; copied from sample code
 * KnockItemCell should render a NotificationCell (eg. OnlyOrganizationItemsCell, etc.)
 */
const _KnockNotificationDropdown = ({ KnockItemCell }) => {
    const renderItem = props => <KnockItemCell key={props.item.id} {...props} />

    const [isVisible, setIsVisible] = useState(false)
    const buttonRef = useRef(null)

    return (
        <KnockFeedProvider feedId={KNOCK_FEED_ID} colorMode={RangeUITheme.mode}>
            <div>
                <NotificationIconButton ref={buttonRef} onClick={e => setIsVisible(!isVisible)} />
                <NotificationFeedPopover
                    buttonRef={buttonRef}
                    isVisible={isVisible}
                    onClose={() => setIsVisible(false)}
                    renderItem={renderItem}
                />
            </div>
        </KnockFeedProvider>
    )
}

// Ignore Knock for Cypress
const KnockNotificationDropdown = window.Cypress ? () => null : _KnockNotificationDropdown

export { OnlyOrganizationItemsCell, OnlyCollaborationItemsCell, OnlyProjectItemsCell, KnockNotificationDropdown }
