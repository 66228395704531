/*
 * Wrap a Mapbox map in a React Component. Children should be MapLayers
 */
import { Presence } from '@range.io/basic-types'
import { memoizeOnce, throttle } from '@range.io/functional'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-dev.js'
import React, { useEffect, useRef } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Commands2 from '../firebase/commands-2/index.js'
import { addResource, removeResource } from '../firebase/commands-2/resources.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { PresenceChangedCommand } from '../firebase/commands/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import cursors from './cursors.js'

const accessToken = 'pk.eyJ1IjoicmFuZ2UtbWFwYm94IiwiYSI6ImNsZW9tdjFyZDAwZ3Azd25xYW9lbmdyNjUifQ.XwN5HIELuR-mEhA_i_z5XA'
mapboxgl.accessToken = accessToken

/*
 * Change the cursor over the Mapbox canvas
 * seems to need a delay to reliably set the cursor
 * Impure: modifies MapboxMap
 * Memoized!
 * @sig changeCursor :: (MapboxMap, String) -> ()
 */
const _changeCursor = (mapboxMap, cursor) => {
    const customCursor = cursors[cursor]
    setTimeout(() => {
        if (!mapboxMap) return
        const canvas = mapboxMap.getCanvas()
        canvas.style.cursor = customCursor || 'default'
    }, 0)
}
const changeCursor = memoizeOnce((mapboxMap, cursor) => cursor, _changeCursor)

const StyledMapboxContainer = styled('div', {
    height: 'var(--gridRow1Height)',
    width: 'var(--gridColumn1Width)',

    '& canvas': {
        outline: 'none', // prevent blue outline border on map
    },

    '.mapboxgl-map': {
        font: '12px/20px Helvetica Neue, Arial, Helvetica, sansSerif',
        overflow: 'hidden',
        position: 'relative',
        '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    },

    '.mapboxgl-canvas': {
        position: 'relative',
        left: '0',
        top: '0',
    },

    '.mapboxgl-marker': {
        position: 'absolute',
        top: '0',
        left: '0',
        'will-change': 'transform',
    },

    '.mapboxgl-popup': {
        position: 'absolute',
        top: '0',
        left: '0',
        display: 'flex',
        'will-change': 'transform',
        pointerEvents: 'none',

        transform: '(50%, 100%)',
    },

    // mapbox checks if this object has this color to determine whether you've loaded mapbox-gl.css -- which we haven't
    '.mapboxgl-canary': {
        backgroundColor: 'rgb(250, 128, 114)',
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Map component
// ---------------------------------------------------------------------------------------------------------------------
const Map = props => {
    const { showStreets = true, showTileBoundaries = false, children } = props
    const { dispatch, getState } = useStore()
    const mapPosition = useSelector(ReduxSelectors.mapPosition)
    const cursor = useSelector(ReduxSelectors.cursor)
    const { runCommand } = useCommandHistory()
    const { canvasId } = useParams()

    // consolidate the reference stuff
    const mapRef = useRef()

    const getInitialPosition = () => mapPosition

    // initialize Mapbox
    const startup = () => {
        const { center, zoom } = getInitialPosition()
        const mapboxMap = new mapboxgl.Map({
            container: 'map-container',
            style: showStreets ? 'mapbox://styles/mapbox/streets-v11' : 'mapbox://styles/mapbox/empty-v8',
            center,
            zoom,
            maxPitch: 0,
            dragRotate: false,
            pitchWithRotate: false,
        })

        // stow for later
        mapRef.current = mapboxMap

        // disable map rotation using right click + drag
        // disable map rotation using touch rotation gesture
        mapboxMap.dragRotate.disable()
        mapboxMap.touchZoomRotate.disableRotation()
        mapboxMap.showTileBoundaries = showTileBoundaries

        // make sure initial custom cursor gets applied as soon as Mapbox map is loaded
        mapboxMap.once('load', () => {
            addResource('foregroundMapboxMap', mapboxMap)
            Commands2.selectionChanged({ canvasId, context: 'foregroundMapLoaded' })
            _changeCursor(mapboxMap, cursor)
        })

        mapboxMap.on('move', event => {
            if (event.dontSendSelectionChange) return // was triggered below
            Commands2.selectionChanged({ ...mapboxMap.getCenter(), zoom: mapboxMap.getZoom(), context: 'userMovedMap' })
        })

        const publishPosition = e => {
            const lastPresencePlace = [e.lngLat.lng, e.lngLat.lat]
            const userId = ReduxSelectors.selectedUserId(getState())
            const canvas = ReduxSelectors.selectedCanvas(getState())

            // there is no Presence without a User and Canvas
            // userId can be undefined just as the user is logging in or out
            if (!userId || !canvas) return

            const changes = { lastPresencePlace, lastPresenceTimestamp: Date.now(), canvas: canvas.id }
            runCommand(PresenceChangedCommand.Outbound(userId, changes))
        }

        mapboxMap.on('mousemove', throttle(Presence.updatePresenceThrottleTime, publishPosition))

        mapboxMap.on(
            'draw.changeCursor',
            ({ cursorName }) => cursorName !== cursor && dispatch(ReduxActions.customCursorSet({ cursorName }))
        )

        mapboxMap.on('draw.resetCursor', () => dispatch(ReduxActions.customCursorResetToState()))

        // cleanup
        return () => {
            mapboxMap.remove()
            mapRef.current = undefined
            removeResource('foregroundMapboxMap')
        }
    }

    // responds to any map position changes that came from Redux.
    // It's the stopping point for the Mapbox -> Redux -> Mapbox update route
    const updateMapPositionIfNeeded = () => {
        // Mapbox map hasn't been initialized yet, so we don't have to do anything
        if (!mapRef.current) return

        const { center: reduxCenter, zoom: reduxZoom } = ReduxSelectors.mapPosition(getState())
        const mapboxCenter = mapRef.current.getCenter()
        const mapboxZoom = mapRef.current.getZoom()

        const areCentersDifferent = mapboxCenter.lng !== reduxCenter.lng || mapboxCenter.lat !== reduxCenter.lat
        if (areCentersDifferent || reduxZoom !== mapboxZoom)
            mapRef.current.jumpTo({ center: reduxCenter, zoom: reduxZoom }, { dontSendSelectionChange: true })
    }

    // happens once
    useEffect(startup, [])

    useEffect(updateMapPositionIfNeeded, [mapPosition])

    const renderChildrenWithMapboxMap = children => {
        const renderChildWithMapboxMap = child => {
            if (!child) return null

            return React.cloneElement(child, {
                ...child.props,
                mapboxMap: mapRef.current,
            })
        }
        return React.Children.map(children, renderChildWithMapboxMap)
    }

    changeCursor(mapRef.current, cursor)

    const { className } = props
    return (
        <div id="map" className={className}>
            <StyledMapboxContainer id="map-container" />
            {renderChildrenWithMapboxMap(children)}
        </div>
    )
}

/*
 * For this function, there are 3 bounds:
 *
 * - mapBounds is the complete bounds of the Mapbox map
 * - collaborationBounds is the area of mapBounds that's hidden behind the Collaboration window
 * - visibleBounds is the area to the left of the Collaboration window (mapBounds - collaborationBounds)
 *
 * If the point is not in the visibleBounds, we need to recenter the map, but for the pin to appear
 * centered in the visibleBounds (as opposed to centered in the mapBounds) we center on a point that's
 * offset by half the width of the collaborationBounds
 *
 * @sig possiblyRecenter :: (CollaborationShape, MapboxMap) -> void
 */
export const possiblyRecenter = (collaborationShape, mapboxMap) => {
    const mapboxCanvasWidthInPixels = mapboxMap.getCanvasContainer().offsetWidth
    const mapBounds = mapboxMap.getBounds()

    const leftEdgeOfCollaborationWindowInPixels = 512 // 500 pixels + 12 pixels of padding

    const leftOfCollaborationWindowInPixels = mapboxCanvasWidthInPixels - leftEdgeOfCollaborationWindowInPixels
    const { lng: collaborationWest } = mapboxMap.unproject({ x: leftOfCollaborationWindowInPixels, y: 0 })

    const collaborationBounds = new mapboxgl.LngLatBounds([
        collaborationWest,
        mapBounds.getSouth(),
        mapBounds.getEast(),
        mapBounds.getNorth(),
    ])
    const visibleBounds = new mapboxgl.LngLatBounds([
        mapBounds.getWest(),
        mapBounds.getSouth(),
        collaborationWest,
        mapBounds.getNorth(),
    ])

    const target = collaborationShape.geometry().coordinates

    if (!visibleBounds.contains(target)) {
        const collaborationWindowWidthInLng = collaborationBounds.getEast() - collaborationBounds.getWest()
        const lng = target[0] + collaborationWindowWidthInLng / 2 // offset by half the width of the collaboration window
        mapboxMap.flyTo({ center: { lng, lat: target[1] } })
    }
}

export default Map
