import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '../range-theme/index.js'
import { LoadingDotsAnimation } from './index.js'

const ImageWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 0 auto',
    width: '100%',
    height: '100%',

    img: {
        maxWidth: '100%',
        maxHeight: '100%',
        height: 'auto',
        width: 'auto',
        objectFit: 'contain',
    },

    variants: {
        isLoading: {
            true: {
                img: {
                    display: 'none',
                },
            },
            false: {
                img: {
                    display: 'visible',
                },
            },
        },
    },
})

const Image = ({ src, alt }) => {
    const [isLoading, setIsLoading] = useState(true)

    if (!src) return

    const onImageLoaded = () => setIsLoading(false)
    return (
        <ImageWrapper isLoading={isLoading}>
            {isLoading && <LoadingDotsAnimation />}
            <img src={src} onLoad={onImageLoaded} alt={alt} />
        </ImageWrapper>
    )
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
}

export default Image
