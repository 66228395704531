import { Collaboration, Feature, Geometry } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { v4 } from 'uuid'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import * as Segment from '../../segment/segment.js'
import { loadItemFromFirestoreDocument } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'
import { createCollaboration } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// GeometriesAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const GeometriesAddedCommand = taggedSum('GeometriesAddedCommand', {
    Inbound: { geometry: 'Geometry' }, // will not be used: now handled by reading FeedItem for Collaboration
    Outbound: {
        geometry: 'Geometry',
        isTask: 'Boolean',
        selectNewGeometry: 'Boolean?', // default true; decide if the new geometry should be focused, so its collab window will appear
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

const createGeometryFeatureAndCollaboration = async (resources, args, selectNewGeometry = true) => {
    const { dispatch } = resources
    const { projectId, geometry } = args

    const collaborationId = v4()
    const featureId = v4()
    const geometryId = geometry.id
    const coordinates = geometry.coordinates

    await createCollaboration({ ...args, collaborationId, featureId, geometryId, coordinates })

    // because the Collaboration and Feature are now created by the server, we need to retrieve them
    // before updating the UI by dispatching geometryFeatureAndCollaborationAdded
    const projectPath = `projects/${projectId}`
    const [collaboration, feature] = await Promise.all([
        loadItemFromFirestoreDocument(Collaboration, `${projectPath}/collaborations/${collaborationId}`),
        loadItemFromFirestoreDocument(Feature, `${projectPath}/features/${featureId}`),
    ])

    dispatch(ReduxActions.geometryFeatureAndCollaborationAdded({ geometry, feature, collaboration, selectNewGeometry }))
    return collaborationId
}

/*
 * A GeometriesAddedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 * The incoming data includes a Geometry, Feature and Collaboration
 */
const runOutboundCommand = async (resources, command) => {
    const { getState, projectId, displayError } = resources
    const { geometry, isTask, selectNewGeometry = true } = command

    const canvasId = ReduxSelectors.selectedCanvasId(getState())
    const statusId = isTask ? Collaboration.initialCollaborationStatus : null

    try {
        const args = { projectId, canvasId, geometry, statusId }
        const collaborationId = await createGeometryFeatureAndCollaboration(resources, args, selectNewGeometry)

        const params = ReduxSelectors.paramsForCollaborationTrackEvent(getState())
        params && Segment.sendTrack('collaboration created', collaborationId, params)
        return collaborationId
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        GeometriesAddedCommand,
        CommandPlayer({
            CommandType: GeometriesAddedCommand,
            Type: Geometry,
            collectionPath: projectId => `/projects/${projectId}/geometries/`,
            runInboundCommand: () => {},
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
        })
    )
}

export { GeometriesAddedCommand, addCommand, createGeometryFeatureAndCollaboration }
