import { Participant } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged } from '@range.io/functional'
import PropTypes from 'prop-types'

// prettier-ignore
const ParticipantShape = tagged('ParticipantShape', {
    id                : StringTypes.OptionalId,
    
    avatarFallbackText: 'String',
    avatarUrl         : StringTypes.OptionalImageUri,
    fullName          : 'String',
    isSuspended       : 'Boolean',
    organizationRole  : StringTypes.OptionalOrganizationRole, // optional as it may be suspended participant
    email             : StringTypes.OptionalEmail, // optional as it may be suspended participant
})

// prettier-ignore
const ParticipantShapePropTypes = {
    id                : PropTypes.string,
    
    avatarFallbackText: PropTypes.string.isRequired,
    fullName          : PropTypes.string.isRequired,
    avatarUrl         : PropTypes.string,
    isSuspended       : PropTypes.bool,
    organizationRole  : PropTypes.string,
    email             : PropTypes.string,

}

ParticipantShape.fromParticipant = shapeLookupTable => participant =>
    ParticipantShape.from({
        id: participant.id,

        avatarFallbackText: Participant.fullName(participant)[0],
        avatarUrl: participant.avatarUrl,
        fullName: Participant.fullName(participant),
        isSuspended: participant.isSuspended ?? false,
        organizationRole: participant.organizationRole,
        email: participant.email,
    })

ParticipantShape.matchesFullName = (participantShape, substring) =>
    participantShape.fullName.toLowerCase().includes(substring.toLowerCase())

export { ParticipantShape, ParticipantShapePropTypes }
