/*
 * Handle resetting a password. There are three parts to this
 *
 * Present the ResetPassword modal to get the email address needing resetting
 * Send a Firebase Auth-generated email to that address with a link back to app.range.io
 * Show the NewPassword modal to get the new password and tell Firebase to change it
 *
 * Firebase has these relevant built-in functions
 *
 *   sendPasswordResetEmail     sends the reset password email to the email address
 *   confirmPasswordReset       validates that the actionCode received when a user clicks their email is still valid
 *   verifyPasswordResetCode    resets the password to the new password
 *
 * The link in the email will have the form
 *
 *  app.range.io/actionCode?mode=resetPassword&oobCode=<actioncode>
 *
 * which will be redirected to the <FirebaseRedirect> component.
 * FirebaseRedirect will in turn redirect to NewPassword modal
 *
 * NewPassword will
 *
 *  - call confirmPasswordReset with the actionCode
 *  - wait for the user to enter a valid new email
 *  - call verifyPasswordResetCode to have Firebase change the password
 *
 *
 * If you want to test this using the emulator, this will work:
 *
 * - Click "Forgot Password" enter, say, rangehdq+tom@gmail.com
 * - Look in the firebase emulator output; there should be a line that says:
 *     To reset the password for rangehdq+tom@gmail.com, follow this link:
 *     http://127.0.0.1:9099/emulator/action?mode=resetPassword&lang=en&oobCode=YBB8K5c02yiiw6BITpsMAv4upnXBfx50oszGhQo3wjdiQvYndIkCPv&apiKey=fake-api-key&newPassword=NEW_PASSWORD_HERE
 * - DON'T CLICK THAT LINK!                                                     ^
 * - construct this URL by replacing ___ACTION_CODE___ with the oobCode above   |
 *
 *    http://localhost:8080/actioncode?emulator&mode=resetPassword&lang=en&oobCode=___ACTION_CODE___
 *
 *    in this example the link would be; make sure NOT to include &apiKey...
 *
 *    http://localhost:8080/actioncode?emulator&mode=resetPassword&lang=en&oobCode=YBB8K5c02yiiw6BITpsMAv4upnXBfx50oszGhQo3wjdiQvYndIkCPv
 * - paste this link into the browser; it should take you to the New Password dialog
 */

import {
    confirmPasswordReset,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    verifyPasswordResetCode,
} from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import { Banner, Button, Flex, Logo, LogoText, Text } from '../components-reusable/index.js'
import { auth } from '../firebase/configure-environment/config.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions } from '../redux/index.js'
import {
    BGLogo,
    breakpoints,
    ButtonLayout,
    InputFlex,
    InputLabel,
    LabelLayout,
    LinkTextCTA,
    PrimaryLabel,
    ScrollableContainer,
    SecondaryLabel,
    StyledForm,
    StyledLogo,
    StyledPage,
    StyledTextInput,
} from './RegistrationSharedStyles.js'

/*
 * Reset Password Form
 */
const isValidEmail = email => /\S+@\S+\.\S+/.test(email)

/*
 * Shows the form that simply asks the user for the email where the reset should be sent
 */
const ResetPasswordForm = ({ errors, setErrors, onSubmit }) => {
    const _onSubmit = e => {
        e.preventDefault()
        if (!errors) onSubmit(email) // submit when no errors
    }

    const _onChange = email => {
        setEmail(email)
        if (isValidEmail(email)) setErrors(undefined) // reset errors if email is correct
    }

    const _onBlur = email => {
        if (!isValidEmail(email)) setErrors(`That doesn't look like an email`)
    }

    const [email, setEmail] = useState('')

    return (
        <StyledForm onSubmit={_onSubmit}>
            <ScrollableContainer>
                <StyledLogo>
                    <Logo />
                    <LogoText />
                </StyledLogo>
                {errors && <Banner toastLabel={errors} severity="error"></Banner>}
                <LabelLayout>
                    <SecondaryLabel>
                        Enter your email below and we'll send you a link to reset your password.
                    </SecondaryLabel>
                </LabelLayout>
                <StyledTextInput value={email} placeholder="name@workemail.com" onChange={_onChange} onBlur={_onBlur} />
                <ButtonLayout>
                    <Button variant="primary" type="submit" size="lg" css={{ w: '100%' }}>
                        <Text>Send me the link</Text>
                    </Button>
                    <LinkTextCTA href="/login">Remember your password? Log in</LinkTextCTA>
                </ButtonLayout>
            </ScrollableContainer>
        </StyledForm>
    )
}

/*
 * Shows the modal allowing the user to send an email
 * Then calls sendPasswordResetEmail to have Firebase send it
 */

const ResetPassword = () => {
    const onSubmit = async email => {
        try {
            await sendPasswordResetEmail(auth, email)
            navigate('/resetPasswordConfirmation')
        } catch (e) {
            if (e.message.match('auth/missing-email')) setErrors('There is no Range account for that email')
            if (e.message.match('auth/user-not-found')) setErrors('There is no Range account for that email')
            else setErrors(e.message)
        }
    }

    const [errors, setErrors] = useState()
    const navigate = useNavigate()

    return (
        <StyledPage>
            <BGLogo>
                <img src="/rangebackgroundicon.svg" icon="Range icon" />
            </BGLogo>
            <ResetPasswordForm errors={errors} setErrors={setErrors} onSubmit={onSubmit} />
        </StyledPage>
    )
}

/*
 * Form to allow the user to select (and confirm) their new password
 */
const NewPasswordForm = ({ errors, onSubmit, gotoLogin }) => {
    const _onSubmit = e => {
        e.preventDefault()
        onSubmit(password, confirmPassword)
    }

    const onChangeScore = (score, message) => {
        setPasswordScore(score)
        setPasswordMessage([message?.warning, message?.suggestions].flat().join(' '))
    }

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordMessage, setPasswordMessage] = useState('')
    const [passwordScore, setPasswordScore] = useState(0)

    return (
        <StyledForm onSubmit={_onSubmit}>
            <ScrollableContainer>
                <StyledLogo>
                    <Logo />
                    <LogoText />
                </StyledLogo>
                <LabelLayout>
                    <SecondaryLabel>Please enter a new password for your account</SecondaryLabel>
                    {errors && <Banner toastLabel={errors} severity="error"></Banner>}
                </LabelLayout>
                <InputFlex>
                    <InputLabel>New Password</InputLabel>
                    <StyledTextInput
                        type="password"
                        placeholder="Enter new password"
                        onChange={setPassword}
                        onBlur={setPassword}
                    />
                </InputFlex>

                <InputFlex>
                    <PasswordStrengthBar password={password} onChangeScore={onChangeScore} />
                    <Text css={{ fontSize: 13, color: '$neutral05' }}>{passwordMessage}</Text>
                </InputFlex>

                <InputFlex>
                    <InputLabel>Confirm Password</InputLabel>
                    <StyledTextInput type="password" placeholder="Re-enter new password" onBlur={setConfirmPassword} />
                </InputFlex>
                <ButtonLayout>
                    <Button variant="primary" type="submit" size="lg" css={{ w: '100%' }} disabled={passwordScore < 3}>
                        <Text>Update password</Text>
                    </Button>
                    <LinkTextCTA onClick={gotoLogin}>Remember your password? Log in</LinkTextCTA>
                </ButtonLayout>
            </ScrollableContainer>
        </StyledForm>
    )
}

/*
 * Modal to allow the user to select (and confirm) their new password
 */
const NewPasswordDumb = ({ errors, onSubmit, gotoLogin }) => (
    <StyledPage>
        <BGLogo>
            <img src="/rangebackgroundicon.svg" icon="Range icon" />
        </BGLogo>
        <NewPasswordForm errors={errors} onSubmit={onSubmit} gotoLogin={gotoLogin} />
    </StyledPage>
)

/*
 * Reset the password
 *
 * - call verifyPasswordResetCode to make sure the actionCode in the link is still valid
 * - wait for the user to enter the new password
 *
 * - call confirmPasswordReset to have Firebase reset the password
 * - call signInWithEmailAndPassword to save the user from having to enter their email/password again
 *
 * awaitingConfirmation is used to make sure confirmPasswordReset is only called once (otherwise the 2nd one would fail)
 */
let awaitingConfirmation = false
const NewPassword = () => {
    const gotoLogin = () => navigate('/login')

    const onSubmit = async (password, confirmPassword) => {
        if (password !== confirmPassword) {
            setErrors(`Passwords don't match`)
            return
        }

        // try to change the Firebase Auth password
        try {
            // make sure to confirm only once
            if (awaitingConfirmation) return
            awaitingConfirmation = true

            await confirmPasswordReset(auth, actionCode, password) // change password
            await signInWithEmailAndPassword(auth, email, password) // log in with new password
            navigate('/')

            awaitingConfirmation = false

            // show the toast
            const toastLabel = `Your password has been successfully updated`
            const toast = { id: v4(), severity: 'success', toastLabel, showUndo: false }
            dispatch(ReduxActions.toastAdded(toast))
        } catch (e) {
            if (e.code === 'auth/invalid-action-code') setErrors('Something went wrong: your link is invalid')
            else setErrors(`Something went wrong${e.message}`)

            awaitingConfirmation = false
        }
    }

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const actionCode = params.get('actionCode')
    const [errors, setErrors] = useState()
    const [email, setEmail] = useState()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        const processActionCode = async () => {
            try {
                // Verify the password reset code is valid.
                const email = await verifyPasswordResetCode(auth, actionCode)
                setEmail(email)
            } catch (e) {
                if (e.code === 'auth/expired-action-code') setErrors(`Your link expired`)
                else if (e.code === 'auth/invalid-action-code') setErrors(`Something went wrong; your link is invalid`)
                else setErrors(`Something went wrong; ${e.message}`)

                console.error(e)
            }
        }

        processActionCode()
    }, [])

    return <NewPasswordDumb errors={errors} onSubmit={onSubmit} gotoLogin={gotoLogin} />
}

/*
 * Display modal saying we have sent you a password reset link
 */

const StyledImg = styled('img', {
    width: '100%',
    height: 'auto',
    maxHeight: '111px',

    // Media queries for responsive design
    [`@media (max-width: ${breakpoints.medium})`]: {
        maxHeight: '90px',
    },
    [`@media (max-width: ${breakpoints.small})`]: {
        maxHeight: '60px',
    },
})

const ResetPasswordConfirmation = () => (
    <StyledPage>
        <BGLogo>
            <img src="/rangebackgroundicon.svg" icon="Range icon" />
        </BGLogo>
        <StyledForm>
            <ScrollableContainer>
                <StyledLogo>
                    <Logo />
                    <LogoText />
                </StyledLogo>
                <Flex>
                    <StyledImg src="password reset image.svg" />
                </Flex>
                <LabelLayout>
                    <PrimaryLabel>Check your email</PrimaryLabel>
                    <SecondaryLabel>
                        If a Range account exists for the email you've provided, you will get an email with instructions
                        on how to reset your password. Be sure to check your spam folder.
                    </SecondaryLabel>
                </LabelLayout>
                <LinkTextCTA href="/login">Back to Log in</LinkTextCTA>
            </ScrollableContainer>
        </StyledForm>
    </StyledPage>
)

export { ResetPassword, NewPasswordDumb, NewPassword, ResetPasswordConfirmation }
