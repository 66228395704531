/*
 * Function to create a CommandPlayer; defines shared boilerplate for all CommandPlayers.
 *
 * You could define your own CommandPlayer function and ignore this as long as it returns the same functions
 * defined here
 *
 * See Readme
 */
import { Timestamp } from '@firebase/firestore'
import { FeedItem } from '@range.io/basic-types'
import { assoc, equals, firstKey } from '@range.io/functional'
import { ReduxSelectors } from '../../redux/index.js'
import * as FirebaseFacade from '../firebase-facade.js'

/*
 * Given a Firestore "change" object return its id and data combined into a single object
 * @sig addIdToChangedDoc :: Change -> { id: Id, a:..., b:... }
 */
const addIdToChangedDoc = change => assoc('id', change.doc.id, change.doc.data())

/*
 * Listen to modifications to the Firestore collection at collectionPath, and when one arrives create a object
 * with the given (tagged) type and send it to the eventListener. Returns an unsubscribe function
 * Impure: listens to Firestore
 *
 * @sig addListenerForCollectionModifications :: (Type, String, Listener) -> UnsubscribeFunction
 *  Listener = Type -> Unsubscribe function
 */
const addListenerForCollectionModifications = (changeType, Type, collectionPath, eventListener) => {
    const docChangeHandler = change => {
        if (change.type !== changeType) return

        const data = addIdToChangedDoc(change)
        const item = Type.fromFirebase(data)
        eventListener(item)
    }

    const processChanges = querySnapshot => querySnapshot.docChanges().forEach(docChangeHandler)

    // for feeds, we listen only to documents arriving AFTER the current time
    const createdSince = Type === FeedItem ? Timestamp.now() : undefined

    // set up the listener
    return FirebaseFacade.addCollectionListener({ path: collectionPath, callback: processChanges, createdSince })
}

/*
 * Listen to modifications to the Firestore document at path, and when one arrives create an object
 * with the given (tagged) type and send it to the eventListener. Returns an unsubscribe function
 * Impure: listens to Firestore
 *
 * @sig addListenerForDocumentModifications :: (Type, String, String, Listener) -> UnsubscribeFunction
 *  Listener = Type -> Unsubscribe function
 */
const addListenerForDocumentModifications = (changeType, Type, path, eventListener) => {
    const publishDocChange = snapshot => {
        // if exists() is false, the "change" was that the document was deleted
        // since this function tracks updates, but not deletes, we should ignore deleted ones
        // There is a discrepancy between the Firestore API for listening to documents and collections
        // For collections, there is a list "changes" each of which includes the type of change
        // for documents, there is only the "exists" function to distinguish deletes from the others
        if (!snapshot.exists()) return

        const data = assoc('id', snapshot.id, snapshot.data())
        const item = Type.fromFirebase(data)
        eventListener(item)
    }

    return FirebaseFacade.addDocumentListener(path, publishDocChange)
}
const throwMissing = arg => () => {
    throw new Error(arg + ' is not implemented')
}

/*
 * resourceKey
 *
 * Each Command is currently listening for changes to a specific resourceKey, and when its resource key changes
 * it must do something. In particular, resourceKey is likely to be projectId, which, when changed, means that the
 * Firestore listeners that used to listen to one project's path now have to listen to the new project's path instead.
 * resourceKey can also be userId, for similar reasons
 *
 * changeKey
 *
 * Each Command is listening for exactly one of the three "changes" that Firestore sends: added, modified or removed.
 * Technically this is true only for Command that listen to a collection. Commands that listen to a document
 * (project, user or organization) don't need a changeType, but it doesn't hurt to make it explicit
 *
 *
 * . For most Commands, this is
 * resourceKey is needed to identify which resources the
 */
const CommandPlayer = ({
    CommandType, // eg. CollaborationChangeCommand
    Type, // OPTIONAL eg. Collaboration
    collectionPath, // OPTIONAL eg. 'projects/${projectId}/collaborations
    documentPath, // OPTIONAL eq. 'projects/${projectId}`
    runInboundCommand = throwMissing('Inbound Command'), // OPTIONAL eg. runCollaborationChangedInboundCommand
    runOutboundCommand = throwMissing('Outbound Command'), // OPTIONAL eg. runCollaborationChangedOutboundCommand
    addCommandToHistory, // callback to CommandHistory to actually RUN a command
    changeType = 'modified', // added|modified|removed
    resourceKey = 'projectId', // projectId|userId|organizationId|invitationId
}) => {
    // -----------------------------------------------------------------------------------------------------------------
    // Manage resources -- if projectId changes we need to listen to the subcollections of the new project
    // -----------------------------------------------------------------------------------------------------------------

    /*
     *
     */
    const changeFirestoreListeners = (value, onInboundChange) => {
        unsubscribeFromFirebaseChanges?.()

        if (documentPath)
            unsubscribeFromFirebaseChanges = addListenerForDocumentModifications(
                changeType,
                Type,
                documentPath(value),
                onInboundChange
            )

        if (collectionPath)
            unsubscribeFromFirebaseChanges = addListenerForCollectionModifications(
                changeType,
                Type,
                collectionPath(value, userId),
                onInboundChange
            )

        // it's possible we're NOT listening to any Firestore changes, leaving unsubscribeFromFirebaseChanges undefined
    }

    const removeFirestoreListeners = () => {
        unsubscribeFromFirebaseChanges?.()
        unsubscribeFromFirebaseChanges = undefined
    }

    /*
     * If the projectId changed, unsubscribe the old Firestore listeners and set up new ones for the new projectId
     */
    const resourceChanged = (key, value) => {
        const onInboundChange = data => addCommandToHistory(CommandType.Inbound(data))
        if (key === resourceKey) changeFirestoreListeners(value, onInboundChange)
        if (key === 'userId') userId = value
    }

    /*
     * If the projectId was removed, unsubscribe the old Firestore listeners
     */
    const resourceRemoved = key => {
        if (key === resourceKey) return removeFirestoreListeners()
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Run commands
    // -----------------------------------------------------------------------------------------------------------------

    const runCommand = (resources, command) =>
        command.match({
            Inbound: () => runInboundCommand(resources, command),
            Outbound: async () => await runOutboundCommand(resources, command),
        })

    const undoCommand = (resources, command) => {} // some day
    const redoCommand = (resources, command) => {} // some day

    let unsubscribeFromFirebaseChanges
    let userId

    return {
        resourceChanged,
        resourceRemoved,

        runCommand,
        undoCommand,
        redoCommand,

        // default implementations of runCommand
    }
}

// -----------------------------------------------------------------------------------------------------------------
// Common implementation of Run commands
// -----------------------------------------------------------------------------------------------------------------

/*
 * An inbound command of the given Type has arrived
 * - getItem will pull the inbound object from the command
 * - reduxAction will be dispatched
 *
 * @sig simpleInboundChangedRunCommand :: (Resources, Command, Type, Function, getItem) -> *
 *
 * Example: change a Canvas
 *
 *   const runCanvasChangedInboundCommand = (resources, command) =>
 *       simpleInboundChangedRunCommand(
 *           resources,
 *           command,
 *           Canvas,
 *           ReduxActions.canvasAdded, // <-- use the "Added" since the entire object arrived from Firestore
 *           command => command.canvas // <-- pull the canvas from the command
 *       )
 */
const simpleInboundChangedRunCommand = (resources, command, Type, reduxAction, getItem) => {
    const { dispatch, getState } = resources
    const item = getItem(command)
    const oldItem = ReduxSelectors.itemWithId(getState(), Type, item.id)

    if (equals(item, oldItem)) return // no change

    dispatch(reduxAction(item))
}

/*
 * Send an object of the given Type to Redux AND to Firestore and handle rolling back if it fails
 * - reduxAction will be dispatched to send the data to Redux
 * - transactionUpdate will be called to send the data to Firestore
 *
 * @sig simpleOutputChangeRunCommand :: (Resources, Command, Type, Function, String) -> *
 *
 * Example: send a Canvas change
 *
 *   const runCanvasChangedOutboundCommand = async (resources, command) =>
 *       await simpleOutboundChangedRunCommand(
 *           resources,
 *           command,
 *           Canvas,
 *           ReduxActions.canvasChanged,
 *           'canvases'
 *       )
 */
const simpleOutboundChangedRunCommand = async (resources, command, Type, reduxAction, reduxCollection) => {
    const { id, changes } = command
    const { projectId, dispatch, displayError, getState, runTransaction, transactionUpdate } = resources
    const oldItem = ReduxSelectors.itemWithId(getState(), Type, id)
    const field = firstKey(changes)
    const oldValue = oldItem[field]
    const newValue = changes[field]

    if (equals(oldValue, newValue)) return // no change

    const { id: _, ...changesWithoutId } = FirebaseFacade.enrichDeletedFields(Type.toFirebase(changes))

    try {
        dispatch(reduxAction({ id, changes }))
        await runTransaction(async transaction => {
            transactionUpdate(transaction, projectId, reduxCollection, id, changesWithoutId)
        })
    } catch (e) {
        const params = { id, changes: { [field]: oldValue } }
        dispatch(reduxAction(params))
        displayError(e)
    }
}

const simpleInboundRemovedRunCommand = (resources, command, Type, reduxRemoveAction) => {
    const { dispatch } = resources
    const { item } = command
    dispatch(reduxRemoveAction(item))
}

const simpleOutboundRemovedRunCommand = async (
    resources,
    command,
    Type,
    reduxCollection,
    reduxRemoveAction,
    reduxAddAction
) => {
    const { projectId, dispatch, displayError, runTransaction, transactionDelete } = resources
    const { item } = command

    try {
        dispatch(reduxRemoveAction(item))
        await runTransaction(async transaction => {
            transactionDelete(transaction, projectId, reduxCollection, item.id)
        })
    } catch (e) {
        dispatch(reduxAddAction(item))
        displayError(e)
    }
}

const simpleInboundAddedRunCommand = (resources, command, Type, reduxAction, getItem) => {
    const { dispatch } = resources
    const item = getItem(command)
    dispatch(reduxAction(item))
}

const simpleOutboundAddedRunCommand = async (
    resources,
    command,
    Type,
    reduxAction,
    reduxUndoAction,
    getItem,
    reduxCollection
) => {
    const item = getItem(command)
    const { projectId, dispatch, displayError, runTransaction, transactionSet } = resources
    const { id, ...data } = Type.toFirebase(item)

    try {
        dispatch(reduxAction(item))
        await runTransaction(async transaction => {
            transactionSet(transaction, projectId, reduxCollection, item.id, data)
        })
    } catch (e) {
        dispatch(reduxUndoAction(item))
        displayError(e)
    }
}

export {
    simpleInboundAddedRunCommand,
    simpleInboundChangedRunCommand,
    simpleInboundRemovedRunCommand,
    simpleOutboundAddedRunCommand,
    simpleOutboundChangedRunCommand,
    simpleOutboundRemovedRunCommand,
}
export default CommandPlayer
