import PropTypes from 'prop-types'
import React from 'react'
import {
    Button,
    DropdownMenuWithTooltip,
    FlexRow,
    Icon,
    IconButtonWithTooltip,
    Text,
} from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { VersionDropdown } from './VersionDropdown.js'

const StyledTopBar = styled(FlexRow, {
    color: '$neutral01',
    padding: '15px 24px 14px 24px',
    borderBottom: '1px solid $neutral07',
    background: '$neutral10',
})

const MoreActionsDropdown = ({ allowDelete, onDeleteClick, onDownloadClick }) => (
    <DropdownMenuWithTooltip.Root>
        <DropdownMenuWithTooltip.Trigger tooltipText="More Actions">
            <Button css={{ w: 32, color: '$neutral05' }} variant="dropdown-trigger">
                <Icon iconSize="28px" name="threeDot" />
            </Button>
        </DropdownMenuWithTooltip.Trigger>
        <DropdownMenuWithTooltip.Content side="bottom" align="end" sideOffset={6}>
            <DropdownMenuWithTooltip.MenuItem>
                <Button variant="dropdown-menuitem" onClick={onDownloadClick}>
                    <Icon iconSize="16px" name="download" css={{ mr: 8 }} />
                    <Text>Download</Text>
                </Button>
            </DropdownMenuWithTooltip.MenuItem>
            {allowDelete && (
                <DropdownMenuWithTooltip.MenuItem>
                    <Button
                        variant="dropdown-menuitem-destructive"
                        css={{ background: 'none' }}
                        onClick={onDeleteClick}
                    >
                        <Icon iconSize="16px" name="trash" css={{ mr: 8 }} />
                        <Text>Delete</Text>
                    </Button>
                </DropdownMenuWithTooltip.MenuItem>
            )}
        </DropdownMenuWithTooltip.Content>
    </DropdownMenuWithTooltip.Root>
)

/*
 * MediaDetailsViewTopBar component
 */

const MediaDetailsViewTopBar = ({
    allowDelete,
    upload,
    onCloseClick,
    onDeleteClick,
    onDownloadClick,
    onUploadClicked,
    onUploadNewFile,
}) => {
    return (
        <StyledTopBar>
            {/* <FlexRow css={{ gap: '4px' }}>
             <IconButtonWithTooltip
             iconName="chevronLeft"
             size="20px"
             tooltipText="Previous photo"
             side="bottom"
             onClick={() => {}}
             variant="neutralHover32"
             />
             
             <IconButtonWithTooltip
             iconName="chevronRight"
             size="20px"
             tooltipText="Next photo"
             side="bottom"
             onClick={() => {}}
             variant="neutralHover32"
             />
             </FlexRow> */}
            <FlexRow css={{ width: '100%', gap: '4px', justifyContent: 'space-between' }}>
                <VersionDropdown upload={upload} onUploadClicked={onUploadClicked} onUploadNewFile={onUploadNewFile} />
                <FlexRow css={{ gap: '4px', ml: 'auto' }}>
                    <MoreActionsDropdown
                        allowDelete={allowDelete}
                        onDeleteClick={onDeleteClick}
                        onDownloadClick={onDownloadClick}
                    />

                    <IconButtonWithTooltip
                        iconName="close24x24"
                        size="20px"
                        tooltipText="Close"
                        side="bottom"
                        onClick={onCloseClick}
                        variant="neutralHover32"
                    />
                </FlexRow>
            </FlexRow>
        </StyledTopBar>
    )
}

MediaDetailsViewTopBar.displayName = 'MediaDetailsViewTopBar'

MediaDetailsViewTopBar.propTypes = {
    allowDelete: PropTypes.bool.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onDownloadClick: PropTypes.func.isRequired,
}

export default MediaDetailsViewTopBar
