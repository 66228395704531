/*
 * DrawArrowMode
 *
 * Draw mode used to draw an arrow on the map
 *
 * Type ArrowState:
 *    line       : DrawFeature<JsonFeature>
 *    head       : DrawFeature<JsonFeature>
 *    startPoint : XYPoint?
 *    endPoint   : XYPoint?
 */

import bearing from '@turf/bearing'

import BasicDrawMode from './basic-draw-mode.js'

const DrawArrowMode = { ...BasicDrawMode }

/*
 * When the mode starts this function will be called.
 * @sig onSetup :: {} -> ArrowState
 */
DrawArrowMode.onSetup = function () {
    const head = this.newFeature({
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [] },
        properties: { annotationType: 'arrow' },
    })
    const line = this.newFeature({
        type: 'Feature',
        geometry: { type: 'LineString', coordinates: [] },
        // TODO: make arrowFeatureId more generic, in line with parentFeatureId property used in other places
        properties: { annotationType: 'arrow', arrowFeatureId: head.id },
    })
    head.properties.parentFeatureId = line.id

    this.addFeature(line)
    this.addFeature(head)

    this.clearSelectedFeatures()
    this.setActionableState({ trash: true })

    return { line, head }
}

/*
 * User tapped in a mobile app: simulate moving to the start point and then clicking at the end point
 * Impure: updates Draw
 * @sig onTap :: (ArrowState, Event) -> ()
 */
DrawArrowMode.onTap = function (state, e) {
    if (state.startPoint) this.onMouseMove(state, e)
    this.onClick(state, e)
}

/*
 * User clicked the map: start or end the arrow depending on whether this is the 2nd click
 * Impure: updates Draw
 * @sig onClick :: (ArrowState, Event) -> ()
 */
DrawArrowMode.onClick = function (state, e) {
    const { lng, lat } = e.lngLat
    const isEndPoint = state.startPoint && state.startPoint[0] !== lng && state.startPoint[1] !== lat
    if (isEndPoint) {
        state.line.updateCoordinate('1', lng, lat)
        this.finishDrawing(state)
    } else state.startPoint = [lng, lat]
}

/*
 * User moved the mouse: update the arrow based on the new position
 * Impure: changes the coordinates and rotation of the arrow
 * @sig onMouseMove :: (ArrowState, Event) -> ()
 */
DrawArrowMode.onMouseMove = function (state, e) {
    if (state.startPoint) {
        const endpoint = [e.lngLat.lng, e.lngLat.lat]
        state.line.updateCoordinate('0', ...state.startPoint)
        state.line.updateCoordinate('1', ...endpoint)
        state.head.updateCoordinate('0', ...endpoint)
        state.head.setProperty('rotation', bearing(state.startPoint, endpoint))
    }
}

/*
 * User did something to change the mode away from DrawArrowMode; clean up
 * Impure: updates Draw
 * @sig onStop :: ArrowState :: ()
 */
DrawArrowMode.onStop = function (state) {
    if (this.getFeature(state.line.id) === undefined) return
    if (state.line.isValid()) this.map.fire('draw.create', { features: [state.line.toGeoJSON()] })
}

/*
 * Called for *every* Feature in Draw; except for our own arrow, we just display each one;
 * Our arrow becomes "active" and, if it has a startPoint, gets drawn
 * Impure: modifies Draw
 * @sig toDisplayFeatures (ArrowState, GeoJsonFeature, DisplayFunc) -> ()
 * DisplayFunc = GeoJsonFeature -> ()
 */
DrawArrowMode.toDisplayFeatures = function (state, geojson, display) {
    const isOurArrow = geojson.properties.id === state.line.id || geojson.properties.id === state.head.id

    // mark (only) our arrow as active
    geojson.properties.active = isOurArrow ? 'true' : 'false'

    // draw the object if it has a startPoint -- or if it's simply not our arrow at all
    if (state.startPoint || !isOurArrow) display(geojson)
}

/*
 * Update endPoint state variable and finish drawing the arrow
 * @sig finishDrawing :: (ArrowState) -> ()
 */
DrawArrowMode.finishDrawing = function (state) {
    state.endPoint = state.line.getCoordinates()[1]
    this.changeMode('select', { featureId: state.line.id })
}

/*
 * Delete used features and back out into idle mode
 * @sig cancelDrawingAndDelete :: (ArrowState) -> ()
 */
DrawArrowMode.cancelDrawingAndDelete = function (state) {
    this.deleteFeature([state.line.id])
    this.deleteFeature([state.head.id])
    this.changeMode('idle')
}

export default DrawArrowMode
