/*
 * Add a single, persistent, window.keydown listener that looks through a stack of KeyMaps and
 * if it finds a matches calls the related listener.
 *
 * This allows us to listen for, say, a hierarchy of meanings for an escape keypress:
 * if the CollaborationWindow is open, escape should close it, but if the Due Date Picker is also open,
 * the first escape should close the DatePicker, but not the CollaborationWindow, and a second escape
 * should still close the CollaborationWindow. By pushing two separate listeners for the escape key,
 * we can order the appropriate actions
 *
 * KeyMap = { KeyName: Thunk }
 *  KeyName = KeyboardEvent.key
 *  Thunk = () -> *
 */

let isWindowHandlerSet
const keyMaps = []
const keyMapNames = [] // each KeyMap has an arbitrary name as well, for debugging

/*
 * All key downs are handled by this one function, which calls the first handler for the given key that it finds
 * @sig handleKeyDown :: KeyDownEvent -> *
 */
const handleKeyDown = e => {
    for (let i = keyMaps.length - 1; i >= 0; i--) {
        const keyMap = keyMaps[i]
        const keyHandler = keyMap[e.key] || keyMap.wildcard
        if (keyHandler) {
            // console.log('keyDown for key ', e.key, ' handled by key map ', keyMapNames[i])
            keyHandler(e)
            return
        }
    }
}

/*
 * Push a new keymap onto the stack
 * @sig pushKeyMap :: (String, KeyMap) -> ()
 */
const pushKeyMap = (name, map) => {
    keyMapNames.push(name)
    keyMaps.push(map)
    // console.log('Pushing keyMap ' + name + '; existing maps ', keyMapNames)

    // set the handler if it's not already
    if (!isWindowHandlerSet) {
        isWindowHandlerSet = true

        // the handler itself is never removed, instead we add and remove keyMaps
        window.addEventListener('keydown', handleKeyDown)
    }
}

/*
 * Pop the top keymap off the stack (and it better have the right name)
 * @sig popKeyMap :: String -> ()
 */
const popKeyMap = name => {
    if (keyMapNames.at(-1) === name) {
        keyMapNames.pop()
        keyMaps.pop()
    } else console.error('Refusing to pop keyMap not at top of stack; map: ', name, ' existing keyMaps: ', keyMapNames)

    // console.log('Popped keyMap ' + name + '; existing maps ', keyMapNames)
}

/*
 * Clear the entire stack of KeyMaps
 */
const clearKeyMaps = () => {
    keyMapNames.splice(0, keyMapNames.length)
    keyMaps.splice(0, keyMaps.length)
}

const useKeyMaps = () => ({
    pushKeyMap,
    popKeyMap,
    clearKeyMaps,
})

export default useKeyMaps
