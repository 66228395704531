import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '../range-theme/index.js'
import { Box, Flex, FlexRow, Icon, Text, Tooltip } from './index.js'

const AlertBanner = styled(FlexRow, {
    height: '36px',
    ai: 'center',
    justifyContent: 'start',
    pl: '12px',
    pr: '12px',
    transitionDuration: '0.4s',
    fontSize: '16px',
    width: 'fit-content',
    border: '1px solid $neutral07',
    br: '0px 6px 6px 0px',
    background: '$neutral08',
    gap: '32px',
})

const Indent = styled(Box, {
    width: '6px',
    height: 'auto',
    br: '5px 0px 0px 5px',
    background: '$green03',
})

const ConfirmationMessageLabel = styled(Text, {
    width: 'fit-content',
    height: 'auto',
    mb: 'auto',
    mt: 'auto',
    color: '$neutral04',
})

const UndoLabel = styled(Text, {
    width: 'fit-content',
    height: 'auto',
    mb: 'auto',
    mt: 'auto',
    fontSize: '18px',
    fontWeight: '500',
    color: '$primary04',
    lineHeight: '21px',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
})

const IconCSS = styled(Icon, {
    width: '16px',
    height: '16px',
    mb: 'auto',
    mt: 'auto',
    cursor: 'pointer',
    color: '$neutral05',

    '&:hover': {
        color: '$neutral04',
    },
})

/*
 * UndoBanner component
 */

const UndoBanner = ({ confirmationMessage, onClick, onUndoClick }) => {
    return (
        <Flex>
            <Indent />
            <AlertBanner>
                <FlexRow css={{ flex: '1', gap: '8px' }}>
                    <ConfirmationMessageLabel>{confirmationMessage}.</ConfirmationMessageLabel>
                    <UndoLabel onClick={onUndoClick}>Undo</UndoLabel>
                </FlexRow>
                <Tooltip tooltipText="Close" side="bottom" align="center">
                    <FlexRow>
                        <IconCSS onClick={onClick} name="close" />
                    </FlexRow>
                </Tooltip>
            </AlertBanner>
        </Flex>
    )
}

UndoBanner.displayName = 'UndoBanner'

UndoBanner.propTypes = {
    confirmationMessage: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onUndoClick: PropTypes.func.isRequired,
}

export default UndoBanner
