/*
 * Coordinate notification preferences in Knock and Redux
 *
 * The same value is used in both places, which is the standard Knock Preferences -- even in Redux.
 * That is, we're using the Knock datastructure verbatim in Redux, on the assumption that our notification
 * preferences are likely to get much more complicated over time
 *
 * see https://docs.knock.app/concepts/preferences
 */

import Knock from '@knocklabs/client'
import { assoc, assocPath } from '@range.io/functional'
import { updateKnockUserToken } from '../firebase/commands/https-calls.js'
import { KNOCK_PUBLIC_API_KEY } from '../firebase/configure-environment/config-local.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'

/*
 * Authenticate the current user, assuming they must already have a knockUserToken (set in App.js)
 */
const _authenticateKnockUser = async getState => {
    const state = getState()

    const userId = ReduxSelectors.selectedUserId(state)
    const userToken = ReduxSelectors.knockUserToken(state)

    await knock.authenticate(userId, userToken)
}

/*
 * Get the user's current notification preferences from Knock, store them in Redux and return them
 * @sig getKnockPreferences :: (Dispatch, GetState) -> Promise {k:v}
 */
const _getKnockPreferences = async (dispatch, getState) => {
    await _authenticateKnockUser(getState)
    let preferenceSet = await knock.user.getPreferences()

    // default email to true
    if (!preferenceSet.channel_types) preferenceSet = assoc('channel_types', { email: true }, preferenceSet)

    // store in Redux
    dispatch(ReduxActions.notificationPreferencesChanged(preferenceSet))

    return preferenceSet
}

/*
 * Update the user's notification preferences and store them at Knock and in Redux
 * Sets ONLY the email notifications for now
 * @sig setKnockPreferences :: (Dispatch, GetState, Boolean) -> Promise Nil
 */
const _setKnockPreferences = async (dispatch, getState, shouldReceiveEmailNotifications) => {
    // get the current preferences and update the email channel_type
    let preferenceSet = ReduxSelectors.notificationPreferences(getState())
    preferenceSet = assocPath(['channel_types', 'email'], shouldReceiveEmailNotifications, preferenceSet)

    // send changes to Knock
    await _authenticateKnockUser(getState)
    await knock.user.setPreferences(preferenceSet)

    // store in Redux
    dispatch(ReduxActions.notificationPreferencesChanged(preferenceSet))
}

/*
 * Refresh the Knock Token (times out after an hour by default)
 */
const _updateKnockToken = async () => {
    const { token } = await updateKnockUserToken()
    return token
}

// avoid Knock for Cypress
const knock = window.Cypress ? {} : new Knock(KNOCK_PUBLIC_API_KEY)
const getKnockPreferences = window.Cypress ? () => ({ email: true }) : _getKnockPreferences
const setKnockPreferences = window.Cypress ? () => {} : _setKnockPreferences
const updateKnockToken = window.Cypress ? () => undefined : _updateKnockToken

export { updateKnockToken, getKnockPreferences, setKnockPreferences }
