/*
 * The Toolbar of drawing tools that appears at the top-left of the map
 */
import React from 'react'
import { useDispatch } from 'react-redux'
import { FlexRow, IconButtonWithTooltip } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions } from '../redux/index.js'
import RecentCollaborationsPopover from './RecentCollaborationsPopover.js'

const showAllTools = false

const StyledToolbar = styled(FlexRow, {
    bg: '$neutral10',
    border: '1px solid $neutral07',
    borderRadius: 6,
    overflow: 'visible',
})

const StyledToolbarNavigator = styled(FlexRow, {
    bg: '$neutral09',
    border: '1px solid $neutral07',
    borderRadius: 6,
    overflow: 'visible',
    mb: 16,
})

/*
 * The panel of drawing tools that appears at the top-left of the map
 */
const DrawingToolsToolbar = ({ selectedTool }) => {
    if (selectedTool === 'idle' || selectedTool === 'none') selectedTool = 'selection'

    // click handlers
    const dispatch = useDispatch()
    const idle = () => dispatch(ReduxActions.drawingModeChanged('idle'))

    let line
    let polyline
    let arrow
    let polygon
    let rect
    // let point
    let text

    if (showAllTools) {
        line = () => dispatch(ReduxActions.drawingModeChanged('line'))
        polyline = () => dispatch(ReduxActions.drawingModeChanged('polyline'))
        arrow = () => dispatch(ReduxActions.drawingModeChanged('arrow'))
        polygon = () => dispatch(ReduxActions.drawingModeChanged('polygon'))
        rect = () => dispatch(ReduxActions.drawingModeChanged('rectangle'))
        // point = () => dispatch(ReduxActions.drawingModeChanged('point'))
        text = () => dispatch(ReduxActions.drawingModeChanged('text'))
    }

    const photoMarker = () => dispatch(ReduxActions.drawingModeChanged('photoMarker'))
    const task = () => dispatch(ReduxActions.drawingModeChanged('task'))
    const onTakeCanvasSnippet = () => dispatch(ReduxActions.showCanvasSnippetMode({ show: true }))

    /*
     * Button with a Tooltip in the toolbar, highlighted if its value matches selectedTool
     *
     * Normally the Tooltip's trigger would be a button and the Toolbar's button would be a 2nd button.
     * Specifying asChild in the Tooltip tells radix-ui the two buttons should be coalesced into one.
     *
     * However, there is a bug where both buttons use an attribute named data-state and the tooltip's data-state is
     * overridden when the buttons are coalesced.
     *
     * To get around this, we explicitly control the highlighted state of the button by modifying
     * the "aria-checked" value and then varying the style (in Tooltip.IconButton) based on the value of "aria-checked."
     * This means we are effectively implementing our own ToggleGroup, so, though normally the toolbar buttons
     * would be ToolbarPrimitive.ToggleItem's we can just use ToolbarPrimitive.Button's -- without the group
     *
     * Furthermore, in order to avoid having "corners" appear as an attribute in the final HTML button,
     * we prefix it with a '$' which styled understands as: don't include this attribute in the generated tag
     *
     * @see Tooltip.IconButton
     */
    const Button = props => {
        const { tooltipText, onClick, value, icon = value, corners, size = '28px', css } = props
        const checked = selectedTool === value ? 'on' : 'off'

        const dataCy = tooltipText.replace(/ /g, '-').toLowerCase()
        return (
            <IconButtonWithTooltip
                css={css}
                dataCy={dataCy}
                size={size}
                iconName={icon}
                side="bottom"
                tooltipText={tooltipText}
                onClick={onClick}
                variant="toolbar"
                corners={corners}
                ariaChecked={checked}
            />
        )
    }

    const otherTools = (
        <>
            <Button value="line" tooltipText="Add Line" onClick={line} />
            <Button value="polyline" tooltipText="Add Polyline" onClick={polyline} />
            <Button value="arrow" tooltipText="Add Arrow" onClick={arrow} />
            <Button value="polygon" tooltipText="Add Polygon" onClick={polygon} />
            <Button value="rectangle" tooltipText="Add Rectangle" onClick={rect} />
            {/* <Button value="point" tooltipText="Add Marker" onClick={point} /> */}
            <Button value="text" tooltipText="Add Text" onClick={text} />
        </>
    )

    return (
        <FlexRow css={{ gap: '12px' }}>
            <StyledToolbar>
                <RecentCollaborationsPopover>
                    <Button icon="recents" value="recents" tooltipText="Show Recently Viewed Pins" size="32px" />
                </RecentCollaborationsPopover>
            </StyledToolbar>

            <StyledToolbar>
                <Button value="selection" tooltipText="Selection" onClick={idle} corners="left" />
                {showAllTools && otherTools}
                <Button icon="photoMarker" value="point" tooltipText="Add Photo Pin" onClick={photoMarker} />
                <Button icon="taskMarker" value="task" tooltipText="Add Task Pin" onClick={task} corners="right" />
            </StyledToolbar>

            <StyledToolbar>
                <Button
                    icon="scissors"
                    value="snippet"
                    tooltipText="Add Snippet"
                    size="22px"
                    onClick={onTakeCanvasSnippet}
                    css={{ color: '$pink03', '&:hover': { color: '$pink04' } }}
                />
            </StyledToolbar>
        </FlexRow>
    )
}

const DrawingToolsToolbarNavigator = ({ selectedTool }) => {
    if (selectedTool === 'idle' || selectedTool === 'none') selectedTool = 'selection'

    // click handlers
    const dispatch = useDispatch()
    const photoMarker = () => dispatch(ReduxActions.drawingModeChanged('photoMarker'))
    const task = () => dispatch(ReduxActions.drawingModeChanged('task'))

    /*
     * Button with a Tooltip in the toolbar, highlighted if its value matches selectedTool
     *
     * Normally the Tooltip's trigger would be a button and the Toolbar's button would be a 2nd button.
     * Specifying asChild in the Tooltip tells radix-ui the two buttons should be coalesced into one.
     *
     * However, there is a bug where both buttons use an attribute named data-state and the tooltip's data-state is
     * overridden when the buttons are coalesced.
     *
     * To get around this, we explicitly control the highlighted state of the button by modifying
     * the "aria-checked" value and then varying the style (in Tooltip.IconButton) based on the value of "aria-checked."
     * This means we are effectively implementing our own ToggleGroup, so, though normally the toolbar buttons
     * would be ToolbarPrimitive.ToggleItem's we can just use ToolbarPrimitive.Button's -- without the group
     *
     * Furthermore, in order to avoid having "corners" appear as an attribute in the final HTML button,
     * we prefix it with a '$' which styled understands as: don't include this attribute in the generated tag
     *
     * @see Tooltip.IconButton
     */
    const Button = props => {
        const { tooltipText, onClick, value, icon = value, corners } = props
        const checked = selectedTool === value ? 'on' : 'off'

        const dataCy = tooltipText.replace(/ /g, '-').toLowerCase()
        return (
            <IconButtonWithTooltip
                dataCy={dataCy}
                size="28px"
                iconName={icon}
                side="bottom"
                tooltipText={tooltipText}
                onClick={onClick}
                variant="toolbar"
                corners={corners}
                ariaChecked={checked}
            />
        )
    }

    return (
        <StyledToolbarNavigator>
            <Button icon="photoMarker" value="point" tooltipText="Add Photo Pin" onClick={photoMarker} corners="left" />
            <Button icon="taskMarker" value="task" tooltipText="Add Task Pin" onClick={task} corners="right" />
        </StyledToolbarNavigator>
    )
}

export default DrawingToolsToolbar
export { DrawingToolsToolbarNavigator }
