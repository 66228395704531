import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReduxActions } from '../redux/index.js'
import { Box, Button, Checkbox, FlexColumn, FlexRow, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledOuterBox = styled(Box, {
    bg: '$neutral09',
    color: '$neutral04',
    width: '500px',
    border: '1px solid $neutral07',
    br: '6px',
    boxShadow: '0px 40px 40px #00000025',
    zIndex: 1000,
    position: 'relative',
})

const StyledModalActions = styled(Box, {
    width: '100%',
    height: '72px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '24px',
    paddingLeft: '24px',
    bt: '1px solid $neutral07',
    display: 'flex',
    gap: '16px',
    justifyContent: 'right',
})

const StyledPrimaryModalLabel = styled(Text, {
    fs: '20px',
    fw: 700,
})

const StyledSecondaryModalLabel = styled(Text, {
    fs: '14px',
    color: '$neutral05',
    lh: '1.4',
})

const StyledOverlay = styled(FlexColumn, {
    width: '100vw',
    height: '100vh',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$neutralOverlay',
    ai: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 300,
})

/*
 * Dynamic global Modal component
 */
const GlobalModal = ({ type = 'info', title, description, confirmationLabel, cancelButton, submitButton }) => {
    const needsConfirmation = Boolean(confirmationLabel)
    const [confirmed, setConfirmed] = useState(!needsConfirmation)
    const dispatch = useDispatch()

    const _onCancel = () => {
        dispatch(ReduxActions.globalModalDataReset())
        cancelButton.onClick?.()
    }

    const _onSubmit = () => {
        dispatch(ReduxActions.globalModalDataReset())
        submitButton.onClick?.()
    }

    return (
        <StyledOverlay>
            <StyledOuterBox>
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel css={{ paddingBottom: '12px' }}>{title}</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel>{description}</StyledSecondaryModalLabel>
                    {needsConfirmation && (
                        <FlexRow css={{ gap: '12px', alignItems: 'center', paddingTop: '24px' }}>
                            <Checkbox
                                id="checkbox-confirm"
                                variant="destructive"
                                size="lg"
                                defaultChecked={false}
                                checked={confirmed}
                                onCheckedChange={setConfirmed}
                            />
                            <StyledSecondaryModalLabel>
                                <label htmlFor="checkbox-confirm">{confirmationLabel}</label>
                            </StyledSecondaryModalLabel>
                        </FlexRow>
                    )}
                </Box>
                <StyledModalActions>
                    <Button
                        css={{ height: '40px' }}
                        variant={submitButton ? 'secondary' : 'primary'}
                        size="lg"
                        onClick={_onCancel}
                    >
                        {cancelButton?.label || 'Cancel'}
                    </Button>
                    {submitButton && (
                        <Button
                            variant={type === 'destructive' ? 'destructive' : 'primary'}
                            size="lg"
                            onClick={_onSubmit}
                            disabled={!confirmed}
                        >
                            {submitButton.label}
                        </Button>
                    )}
                </StyledModalActions>
            </StyledOuterBox>
        </StyledOverlay>
    )
}

GlobalModal.displayName = 'Global Modal'

GlobalModal.propTypes = {
    type: PropTypes.oneOf(['info', 'destructive']),
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    confirmationLabel: PropTypes.string,
    cancelButton: PropTypes.exact({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func,
    }),
    submitButton: PropTypes.exact({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func,
    }),
}

export default GlobalModal
