/*
 * Per tile/pixel computations
 *
 * Assume:
 *
 * - we arbitrarily adopt Mapbox's convention that a "tile" is 512 x 512 pixels
 * - we arbitrarily adopt the CSS definition of a pixel, which is a measure of *length* 1/96 inch long
 * - at zoom level 0, the whole Earth can be viewed in 1 tile and that at each increasing zoom
 *      there are twice as many tiles in both directions, so that zoom 1 has 4 tiles:
 *      2 wide and 2 high, each of which covers a 1/4 of the Earth in 512 x 512 pixels
 *
 * And these are true:
 *
 * - the circumference of the Earth at the Equator is (roughly) 40,075 kilometers
 * - the length of a "small circle" connecting all the points at a given latitude depends on the latitude
 *
 * Then:
 *
 * - the number of tiles to cover a small circle of the earth doubles at every zoom
 *   so at zoom z: 2 ^ z tiles are needed are needed to cover any small circle
 * - the number of meters in a tile is: (length of the small circle at the tile's latitude)  / (2 ^ z)
 * - the number of metersPerPixel = number of meters per tile / 512 pixels per tile
 * - a tile will be 5 1/3 inches wide (512 pixels / 96 pixels / inch)
 *
 * "Ground-size" Tiles
 *
 * For a given latitude there is some zoom level at which the space taken up on the screen is the same
 * as that on the ground. That is, there is some zoom where the 5 1/3 inches of tile would show a part
 * of the Earth 5 1/3 inches wide. At the equator, this is about 28.14; at latitude 40 it's more like 27.76.
 * These are really approximate values since a screen pixel is basically never precisely 1/96th of an inch.
 *
 * At 0 degrees latitude
 *
 *      40075000 meter * 39.37 in  * 96 pixels  *     1     = 512.1 pixels
 *                       --------    ---------    ---------
 *                        meter       inch        2 ^ 28.14
 *
 * At 40 degrees latitude
 *
 *      30699244 meter * 39.37 in  * 96 pixels  *     1     = 510.5 pixels
 *                       --------    ---------    ---------
 *                        meter       inch        2 ^ 27.76
 *
 * Types
 *
 *  Degrees = Number [-90..90]
 *  Zoom    = Number [0..30]
 *  Meters  = Number
 *  LngLat  = [Degrees, Degrees]
 *
 *
 * @see https://docs.mapbox.com/help/glossary/zoom-level/
 */

// equatorial mean radius of Earth (in meters)
const R = 6378137

const squared = x => x * x

// degrees to radians :: Degrees -> Number
const degreesToRadians = x => (x * Math.PI) / 180.0

/*
 * Great Circle distance between two LngLats (approximated)
 * @sig greatCircleDistance :: (LngLat, LngLat) -> Meters
 */
const greatCircleDistance = (lngLat1, lngLat2) => {
    const haversine = x => squared(Math.sin(x / 2))

    const lng1 = degreesToRadians(lngLat1[0])
    const lng2 = degreesToRadians(lngLat2[0])
    const lat1 = degreesToRadians(lngLat1[1])
    const lat2 = degreesToRadians(lngLat2[1])

    const ht = haversine(lat2 - lat1) + Math.cos(lat1) * Math.cos(lat2) * haversine(lng2 - lng1)
    return 2 * R * Math.asin(Math.sqrt(ht))
}

/*
 * Given a latitude in degrees, return the number of meters for each degree of longitude
 * @sig metersPerDegreeOfLngAtLatitude :: Degrees -> Meters
 */
const metersPerDegreeOfLngAtLatitude = lat => {
    const metersPerMillionthDegreeOfLng = greatCircleDistance([0, lat], [0.000001, lat])
    return metersPerMillionthDegreeOfLng * 1000000
}

/*
 * Given a latitude in degrees, return the number of meters for each degree of latitude
 * @sig metersPerDegreeOfLatAtLatitude :: Degrees -> Meters
 */
const metersPerDegreeOfLatAtLatitude = lat => {
    const metersPerMillionthDegreeOfLat = greatCircleDistance([0, lat - 0.0000005], [0, lat + 0.0000005])
    return metersPerMillionthDegreeOfLat * 1000000
}

/*
 * Return *just* the (orthogonal) horizontal distance in meters between two LatLngs
 * @sig horizontalDistanceInMeters :: (LngLat, LngLat) -> Number
 */
const horizontalDistanceInMeters = (p1, p2) => {
    const metersPerLng = metersPerDegreeOfLngAtLatitude(p1.lat)
    const distanceLng = p2.lng - p1.lng
    return distanceLng * metersPerLng
}

/*
 * Return *just* the (orthogonal) vertical distance in meters between two LatLngs
 * @sig verticalDistanceInMeters :: (LngLat, LngLat) -> Number
 */
const verticalDistanceInMeters = (p1, p2) => {
    const metersPerLat = metersPerDegreeOfLatAtLatitude(p1.lat)
    const distanceLat = p2.lat - p1.lat
    return distanceLat * metersPerLat
}

/*
 * Return the width and height in meters of a box defined by three corners
 * @sig lngLatBoundsInMetersAndFeet = (LngLat, LngLat, LngLat) => [Number, Number]
 */
const lngLatBoundsInMeters = (northWest, northEast, southWest) => [
    horizontalDistanceInMeters(northWest, northEast),
    verticalDistanceInMeters(southWest, northWest),
]

/*
 * Return a Bounds from a center and a width and height in meters
 * @sig boundsFromCenterAndSize = (LngLat, Number, Number) => Bounds
 *   Bounds = { north; LngLat, east: LngLat, south: LngLat, west: LngLat }
 */
const boundsFromCenterAndSize = (center, width, height) => {
    const metersPerLat = metersPerDegreeOfLatAtLatitude(center.lat)
    const metersPerLng = metersPerDegreeOfLngAtLatitude(center.lat)
    const widthInLng = width / metersPerLng
    const heightInLat = height / metersPerLat

    const north = center.lat + heightInLat / 2
    const south = center.lat - heightInLat / 2
    const east = center.lng + widthInLng / 2
    const west = center.lng - widthInLng / 2

    return { north, south, east, west }
}

/*
 * Return our version of a Bounds object from a Mapbox bounds object
 */
const mapboxBoundsToBounds = mapboxBounds => {
    const east = mapboxBounds.getEast()
    const west = mapboxBounds.getWest()
    const north = mapboxBounds.getNorth()
    const south = mapboxBounds.getSouth()
    return { north, south, east, west }
}

/*
 * Compute the x, y, width, height we need to give to context.drawImage
 *
 * We're trying to copy the entire offscreen canvas into the onscreen canvas at the appropriate position and scale:
 *
 *     context.drawImage(offscreenCanvas, 0, 0, offscreen pixel width, offscreen pixel height, x, y, width, height)
 *
 * This function will compute x, y, width, height we need to give to context.drawImage
 *
 * Suppose
 *
 * - the  onscreen canvas is 1000 x 1000 pixels and represents a bounds of ( w: 5.0, s: 5.0, e: 5.5, n: 5.5 }
 * - the offscreen canvas is 1600 x 1600 pixels and represents a bounds of ( w: 5.1, s: 5.1, e: 5.2, n: 5.2 }
 *
 * In this case, the offscreen canvas fits entirely into the onscreen canvas
 *
 *                                 lng
 *                     +----+----+----+----+----+
 *                    5.0  5.1  5.2  5.3  5.4  5.5
 *
 *                               pixels
 *                     +----+----+----+----+----+
 *                     0   200  400  600  800  1000
 *     +  5.5       0  +----+----+----+----+----+
 *     |               |                        |
 *     +  5.4     200  +                        +
 *     |               |                        |
 * lat +  5.3     400  +                        +
 *     |               |                        |
 *     +  5.2     600  +    +----+              +
 *     |               |    |    |              |
 *     +  5.1     800  +    +----+              +
 *     |               |                        |
 *     +  5.0    1000  +----+----+----+----+----+
 *
 * Beware the y axis: The canvas dimension increases DOWNWARDS, but the lat dimension increases UPWARDS!
 *
 * Since we scaled the offscreen canvas down from 1600 x 1600 to 200 x 200, we multiplied both dimensions by 1/8,
 * which works out to be
 *
 *                    onscreen pixel width     offscreen lng delta                   1000   0.1              1
 *     width scale  = ---------------------  * -------------------               =   ---- * ---          =   -
 *                    offscreen pixel width    onscreen lng delta                    1600   0.5              8
 *
 *                    onscreen pixel height    offscreen lat delta                   1000   0.1              1
 *     height scale = ---------------------  * -------------------               =   ---- * ---          =   -
 *                    offscreen pixel height   onscreen lat delta                    1600   0.5              8
 *
 * then the target onscreen width and height:
 *
 *     width        = width scale  * offscreen pixel width                       =   1/8 * 1600          =  200
 *     height       = height scale * offscreen pixel height                      =   1/8 * 1600          =  200
 *
 * For the x position, we compute the onscreen pixel location by:
 *
 *                    (offscreen.west - onscreen.west)                               (5.1 - 5.0)
 *      x           = --------------------------------   * onscreen pixel width  =   ----------- * 1000  =  200
 *                          onscreen lng delta                                           0.5
 *
 * However, for the y position, we need to invert the scale, the corresponding computation will get us the bottom value:
 *
 *                    (offscreen.south - onscreen.south)                             (5.1 - 5.0)
 *      bottom      = ---------------------------------- * onscreen pixel height =   ----------- * 1000  =  200
 *                          onscreen lat delta                                           0.5
 *
 *      y           = onscreen pixel height - bottom - height                    =   1000 - 200 - 200    = 600
 *
 *
 *
 * @sig  projectOffscreenOntoOnscreen :: (Bounds, Dimensions, Bounds, Dimensions) -> [Number, Number, Number, Number]
 *  Bounds = { west: Number, east: Number, south: Number, north: Number }
 *  Dimensions = { width: Number, height: Number }
 */
const projectOffscreenOntoOnscreen = (
    onscreenBounds,
    onscreenCanvasDimensions,
    offscreenBounds,
    offscreenCanvasDimensions
) => {
    const onscreen = Object.assign(onscreenBounds, {
        pixelWidth: onscreenCanvasDimensions.width,
        pixelHeight: onscreenCanvasDimensions.height,
        latDelta: onscreenBounds.north - onscreenBounds.south,
        lngDelta: onscreenBounds.east - onscreenBounds.west,
    })

    const offscreen = Object.assign(offscreenBounds, {
        pixelWidth: offscreenCanvasDimensions.width,
        pixelHeight: offscreenCanvasDimensions.height,
        latDelta: offscreenBounds.north - offscreenBounds.south,
        lngDelta: offscreenBounds.east - offscreenBounds.west,
    })

    const widthScale = (offscreen.lngDelta * onscreen.pixelWidth) / (offscreen.pixelWidth * onscreen.lngDelta)
    const width = offscreen.pixelWidth * widthScale
    const fractionFromLeft = (offscreen.west - onscreen.west) / onscreen.lngDelta
    const x = fractionFromLeft * onscreen.pixelWidth

    const heightScale = (offscreen.latDelta * onscreen.pixelHeight) / (offscreen.pixelHeight * onscreen.latDelta)
    const height = offscreen.pixelHeight * heightScale
    const fractionFromBottom = (offscreen.south - onscreen.south) / onscreen.latDelta
    const bottom = fractionFromBottom * onscreen.pixelHeight
    const y = onscreen.pixelHeight - bottom - height

    return { x, y, width, height }
}

export {
    degreesToRadians,
    horizontalDistanceInMeters,
    verticalDistanceInMeters,
    lngLatBoundsInMeters,
    boundsFromCenterAndSize,
    projectOffscreenOntoOnscreen,
    mapboxBoundsToBounds,
}
