import { TagName } from '@range.io/basic-types'
import { uniq } from '@range.io/functional'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    CollaborationChangedCommand,
    TagNameAddedCommand,
    TagNameChangedCommand,
    TagNameRemovedCommand,
    UploadChangedCommand,
} from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { ReduxSelectors } from '../redux/index.js'
import TagManagerFullscreen from './TagManagerFullscreen.js'
import { convertIdsToTagsObjects } from '../helpers.js'

// update any Collaboration or Upload whose tags have changed; then remove obsolete TagNames
const processAndUpdateTags = ({ runCommand, processTags, toRemove = [], dispatch, getState, allTagNames }) => {
    const dispatchTagChangesToCollaboration = collaboration => {
        const { haveChanged, newTags } = processTags(collaboration.tags)
        if (haveChanged) {
            const tagsArray = convertIdsToTagsObjects(newTags, allTagNames)
            const data = { id: collaboration.id, changes: { tags: tagsArray }, skipUpdate: true }
            runCommand(CollaborationChangedCommand.Outbound.from(data))
        }
    }

    const dispatchTagChangesToUpload = upload => {
        const { haveChanged, newTags } = processTags(upload.tagIds)
        if (haveChanged) runCommand(UploadChangedCommand.Outbound(upload.id, { tagIds: newTags }))
    }

    const removeTagName = tagId => runCommand(TagNameRemovedCommand.Outbound(tagNameLookup[tagId]))

    const state = getState()
    const tagNameLookup = ReduxSelectors.tagNameLookupTable(state)

    // update Collaborations and Uploads whose tags have changed; then remove obsolete TagNames
    ReduxSelectors.collaborationsAsArray(state).forEach(dispatchTagChangesToCollaboration)
    ReduxSelectors.uploadsAsArray(state).forEach(dispatchTagChangesToUpload)
    toRemove.forEach(removeTagName)
}

const TagManager = () => {
    const { runCommand } = useCommandHistory()
    const selectedProject = useSelector(ReduxSelectors.selectedProject)
    const allTagNames = useSelector(ReduxSelectors.enrichedTagNames)
    const navigate = useNavigate()
    const { workspaceId, projectId } = useParams()
    const dispatch = useDispatch()

    const handleClose = () => {
        if (window.history.state?.usr?.safeToNavigateBack) navigate(-1)
        else navigate(`/${workspaceId}/${projectId}`)
    }

    const addNewTagName = newTagName => {
        const tagName = TagName.fromName(newTagName)
        runCommand(TagNameAddedCommand.Outbound(tagName))
    }

    // remove tagNameIds from any Collaboration or Upload that had them and then delete the TagNames too
    const handleDeleteTags = tagIds => {
        const processTags = tags => {
            const newTags = tags.filter(tagId => tagIds.indexOf(tagId) === -1)
            const haveChanged = newTags.length < tags.length
            return { haveChanged, newTags }
        }

        const thunk = (dispatch, getState) =>
            processAndUpdateTags({ runCommand, processTags, toRemove: tagIds, dispatch, getState, allTagNames })

        dispatch(thunk)
    }

    /*
     * Three possibilities:
     *
     * - rename a single TagName (no Collaborations/Upload modified)
     * - combine multiple TagNames into a single new TagName
     * -
     */
    const handleEditTags = (actionItem, tagIds) => {
        const processTagsAfterEdit = (targetTagName, tagsToRemove) => {
            const processTags = tags => {
                let newTags = tags.filter(tagId => tagsToRemove.indexOf(tagId) === -1)
                const haveChanged = newTags.length < tags.length
                if (haveChanged) newTags = uniq(newTags.concat([targetTagName.id]))
                return { haveChanged, newTags }
            }

            const thunk = (dispatch, getState) =>
                processAndUpdateTags({
                    runCommand,
                    processTags,
                    toRemove: tagsToRemove,
                    dispatch,
                    getState,
                    allTagNames,
                })

            dispatch(thunk)
        }

        if (actionItem.isNew && tagIds.length === 1)
            // edit action on a single tag with a new name -> just rename the existing tag with the new name
            runCommand(TagNameChangedCommand.Outbound(tagIds[0], { name: actionItem.newTagName }))
        else if (actionItem.isNew) {
            // we need to create a new TagName with this new name before processing
            const targetTagName = TagName.fromName(actionItem.newTagName)
            runCommand(TagNameAddedCommand.Outbound(targetTagName))
            processTagsAfterEdit(targetTagName, tagIds)
        } else {
            const targetTagName = allTagNames[actionItem.item.id]
            processTagsAfterEdit(targetTagName, tagIds)
        }
    }

    return (
        <TagManagerFullscreen
            allTagNames={allTagNames}
            onAddNewTag={addNewTagName}
            onClose={handleClose}
            onDelete={handleDeleteTags}
            onEdit={handleEditTags}
            projectName={selectedProject?.name}
        />
    )
}

export default TagManager
