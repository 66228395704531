/*
 * Text input boxes
 *
 * TextInput: vanilla Box containing an input field
 *   Input
 */

import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useState, useRef } from 'react'
import { styled } from '../range-theme/index.js'
import IconButtonWithTooltip from './IconButtonWithTooltip.js'
import { mergeRefs } from '../helpers.js'
import Icon from './Icon.js'

// ---------------------------------------------------------------------------------------------------------------------
// CSS
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Input wrapper that holds input and clear button
 */
const StyledInputWrapper = styled('div', {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'visible',
    height: '40px',
    maxHeight: '100%',
    width: '100%',
    boxSizing: 'border-box',
})

/*
 * The actual text input, including active, focus and placeholder pseudoclass
 */
const StyledInput = styled('input', {
    flex: '1 1 auto',
    padding: '0 30px 0 12px', // padding on the right = 24px for the clear button and 6px additional.
    overflow: 'hidden',
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: '$default',
    lineHeight: '21px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    caretColor: '$neutral04',
    color: '$neutral04',
    transitionDuration: '0.4s',
    br: '6px',

    '&:active': {
        background: '$primary01',
    },

    '&:hover': {
        background: '$primary01',
    },

    '&:focus': {
        background: '$primary01',
        color: '$neutral04',
    },

    '&:valid': {
        color: '$neutral04',
    },

    '&::placeholder': {
        color: '$neutral05',
    },

    '&:disabled': {
        color: '$neutral05',
    },

    '&:focus-visible': {
        outline: 'none',
    },

    variants: {
        variant: {
            default: {
                border: '1px solid $neutral07',
                backgroundColor: '$neutral09',

                '&:active, &:focus': {
                    border: '1px solid $primary03',
                    backgroundColor: '$primary01',
                },
                '&:hover': {
                    border: '1px solid $primary03',
                    backgroundColor: '$primary01',
                },
            },

            simple: {
                border: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
})

/*
 * TextInput
 *
 * Can be used as controlled input, in such case you have to provide onChange method and a value string.
 *      e.g. <TextInput value={value} onChange={val => setValue(val)} />
 * You can provide initialValue without having to turn TextInput into controlled state.
 *      e.g. <TextInput initialValue="My initial value" />
 * To hide clear button use noClear argument.
 *      e.g. <TextInput noClear />
 * CSS and className will both be applied on the top-level wrapper.
 */
const TextInput = React.forwardRef((props, ref) => {
    const {
        onBlur = () => {},
        onChange,
        onKeyDown = () => {},
        initialValue = '',
        value,
        noClear = false,
        className,
        css,
        iconName,
        checkInputValidity = false,
        'data-cy': dataCy,
        variant = 'default',
        ...otherProps
    } = props
    const [inputValue, setInputValue] = useState(initialValue)
    const inputRef = useRef(null)
    const isControlled = onChange !== undefined && value !== undefined

    const _onBlur = _ => onBlur(isControlled ? value || '' : inputValue)

    const _onChange = e => {
        if (checkInputValidity && !e.target.validity.valid) return // will test agains the input pattern

        if (onChange) onChange(e.target.value)
        if (!isControlled) setInputValue(e.target.value)
    }

    // blur on tab or enter key
    const _onKeyDown = e => {
        if (e.key === 'Tab') _onBlur()
        if (e.key === 'Enter') _onBlur()
        onKeyDown(e)
    }

    // clear value on X click, focus input
    const _onClearClick = () => {
        if (onChange) onChange('')
        inputRef.current?.focus()
        if (!isControlled) setInputValue('')
    }

    return (
        <StyledInputWrapper data-cy={dataCy} className={`text-input ${className}`} css={css}>
            {iconName && (
                <Icon
                    iconSize="16"
                    css={{ color: '$neutral05', position: 'absolute', left: '8px', pointerEvents: 'none' }}
                    name={iconName}
                />
            )}
            <StyledInput
                value={isControlled ? value : inputValue}
                onChange={_onChange}
                onKeyDown={_onKeyDown}
                onBlur={_onBlur}
                {...otherProps}
                ref={mergeRefs([ref, inputRef])}
                css={{ paddingLeft: iconName ? '32px' : '12px' }}
                variant={variant}
            />
            {!!(!noClear && (value?.length || (inputValue && inputValue?.length))) && (
                <IconButtonWithTooltip
                    dataCy="input-clear-button"
                    iconName="close"
                    size="9px"
                    tooltipText="Clear"
                    side="right"
                    onClick={_onClearClick}
                    css={{
                        position: 'absolute',
                        right: '6px',
                        flex: '0 0 auto',
                        width: '24px',
                        height: '32px',
                        color: '$neutral05',
                        cursor: 'pointer',
                        '&:hover': {
                            color: '$neutral04',
                        },
                        '&:focus': {
                            color: '$neutral04',
                        },
                    }}
                />
            )}
        </StyledInputWrapper>
    )
})

TextInput.displayName = 'Text Input'
TextInput.defaultProps = {
    noClear: false,
}

TextInput.propTypes = {
    placeholder: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    noClear: PropTypes.bool,
    initialValue: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    css: PropTypes.object,
}

const FreeRangeTextInput = React.forwardRef((props, ref) => <StyledInput ref={ref} {...props} />)

// ---------------------------------------------------------------------------------------------------------------------
// TextArea
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const StyledTextArea = styled('textarea', {
    flex: '1 1 auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '21px',
    border: 'none',
    br: '6px',
    padding: '0',
    background: '$neutral09',
    color: '$neutral04',
    resize: 'none',
    fontFamily: '$default',
    fontSize: '14px',
    fontWeight: '400',
    transitionDuration: '0.4s',
    caretColor: '$neutral04',
    outline: 'none',

    '&:active': {
        border: 'none',
    },

    '&:focus': {
        border: 'none',
    },

    '&:hover': {
        border: 'none',
    },

    '&::placeholder': {
        color: '$neutral05',
    },

    '&:disabled': {
        color: '$red03',
        border: '1px solid $red03',
        caretColor: '$red03',
    },

    '&::-webkit-scrollbar': {
        background: '$neutral08',
        width: '6px',
        display: 'flex',
        cursor: 'pointer',
        borderRadius: '4px',
        padding: 2,
    },

    '&::-webkit-scrollbar-thumb': {
        flex: 1,
        background: '#99999950',
        borderRadius: '4px',
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            minWidth: 44,
            minHeight: 44,
        },
    },
})

/*
 * TextArea
 * forwardRef is useful for TextAreaExternallyChangeable
 */
const TextArea = forwardRef((props, ref) => {
    const { placeholder, onBlur, externalValue = '', focus, maxLineCount, ...otherProps } = props
    const _onBlur = e => onBlur(e.target.value)
    const textAreaRef = useRef(null)

    useEffect(() => {
        if (focus && textAreaRef.current) textAreaRef.current.focus()
    }, [focus])

    // if the external value changes, we want to update our locally-stored value
    useEffect(() => {
        if (textAreaRef && textAreaRef.current) textAreaRef.current.value = externalValue
    }, [externalValue])

    // blur on tab key
    const onKeyDown = e => {
        if (e.key === 'Tab') _onBlur(e)
        _resize(e.target)
    }

    const _resize = textArea => {
        textArea.style.height = 'inherit' // force a change

        // Get the computed styles for the element
        const computed = window.getComputedStyle(textArea)

        // note: assumes line height was specified in pixels in PIXELS!
        const limit = maxLineCount * parseInt(computed.lineHeight)

        // Calculate the height
        const height =
            parseInt(computed.getPropertyValue('border-top-width')) +
            parseInt(computed.getPropertyValue('border-bottom-width')) +
            parseInt(computed.getPropertyValue('padding-top')) +
            parseInt(computed.getPropertyValue('padding-bottom')) +
            textArea.scrollHeight

        // In case you have a limitation
        textArea.style.height = `${Math.min(height, limit)}px`
        if (height > limit) {
            textArea.style.overflowY = 'auto'
        }
    }

    setTimeout(() => {
        if (textAreaRef && textAreaRef.current) _resize(textAreaRef.current)
    })

    return (
        <StyledTextArea
            rows={1}
            ref={mergeRefs([textAreaRef, ref])}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            onBlur={_onBlur}
            {...otherProps}
            onInput={e => _resize(e.target)}
        />
    )
})

TextArea.displayName = 'Text Area'
TextArea.defaultProps = {
    onBlur: () => {},
    maxLineCount: 1000,
}

// prettier-ignore
TextArea.propTypes = {
    placeholder  : PropTypes.string.isRequired,
    onBlur       : PropTypes.func,
    externalValue: PropTypes.string,
    focus        : PropTypes.bool,
    maxLineCount : PropTypes.number
}

// ---------------------------------------------------------------------------------------------------------------------
// TextAreaExternallyChangeable
// ---------------------------------------------------------------------------------------------------------------------

/*
 * TextAreaExternallyChangeable
 * A TextArea that -- in addition to holding its own value -- can have its value changed from outside.
 * This is useful for example for the name of a Collaboration, which could be changed externally by some
 * other user modifying its name.
 *
 * If two people are editing the same Collaboration at the same instant, one of them will be surprised to
 * see the name change under their cursor -- but this should be rare.
 */
const TextAreaExternallyChangeable = forwardRef((props, ref) => {
    const inputRef = useRef()

    // update the ref's value iff props.externalValue changes
    useEffect(() => {
        if (inputRef?.current) inputRef.current.value = props.externalValue
    }, [props.externalValue])

    return <TextArea ref={mergeRefs([inputRef, ref])} {...props} />
})

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

TextAreaExternallyChangeable.displayName = 'Text Input Externally Changeable'
TextAreaExternallyChangeable.defaultProps = {}

// prettier-ignore
TextAreaExternallyChangeable.propTypes = {
    placeholder  : PropTypes.string.isRequired,
    onBlur       : PropTypes.func.isRequired,
    focus        : PropTypes.bool,
    externalValue: PropTypes.string,
    maxLineCount : PropTypes.number
}

export { FreeRangeTextInput, TextInput, TextArea, TextAreaExternallyChangeable }
