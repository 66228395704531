/*
 * These functions handle sending data to and from Firebase. They're all async.
 * The handlers can be called at any time, since other people have caused these events.
 *
 * Firebase event listeners
 *
 * Firebase can send events when an element of the tree stored in the database is Created|Updated|Removed
 * These functions add listeners for such Firebase events
 *
 * Simple functions to read from Firebase
 *
 * Uploads
 *
 * uploadFile   send a file to Firebase storage
 * deleteUpload delete a file from Firebase storage
 *
 */
import { Collaboration, Comment, Feature, Geometry, Participant, Update, Upload } from '@range.io/basic-types'
import { arrayToLookupTable, assoc, mapObject, mergeRight } from '@range.io/functional'
import * as Firestore from 'firebase/firestore'
import * as FirebaseStorage from 'firebase/storage'
import { firestore, storage } from './configure-environment/config.js'
import { mark } from './console.js'

/* ---------------------------------------------------------------------------------------------------------------------
 * Firebase paths
 * ------------------------------------------------------------------------------------------------------------------ */

/*
 * Retrieve the Firestore document at the given path (one time)
 * @sig getDocFromFirestore :: String -> Promise *
 */
const getDocFromFirestore = async path => {
    const ref = Firestore.doc(firestore, path)
    const snapshot = await Firestore.getDoc(ref)
    const documentData = snapshot.data()
    documentData.id = snapshot.id
    return documentData
}

/*
 * Return true if there's a doc at the given path
 * @sig existsDocFromFirestore :: String -> Promise *
 */
const existsDocFromFirestore = async path => {
    const ref = Firestore.doc(firestore, path)
    const snapshot = await Firestore.getDoc(ref)
    return snapshot.exists()
}

/*
 * Return the count of objects matching a query with a 'where' clause, eg.
 *
 *   await getCountQueryFromFirestore(`projects/${project.id}/collaborations`, 'statusName', '==', statusName.id)
 * @sig getCountQueryFromFirestore ::  (String, String, String, *) -> Promise Number
 */
const getCountQueryFromFirestore = async (path, field, comparator, value) => {
    const collection = Firestore.collection(firestore, path)
    const query = Firestore.query(collection, Firestore.where(field, comparator, value))
    const snapshot = await Firestore.getCountFromServer(query)
    return snapshot.data().count
}

const logError = (path, e) => console.error(path, e)

/*
 * (Possibly) track each unsubscriber, by adding it to outstandingSubscriptions and removing it after it has been called
 * Useful for debugging only
 *
 * trackUnsubscribers: whether to track at all
 * trackUnsubscriber: wraps an unsubscriber so we can log remaining subscriptions after it has been called
 * logUnsubscribers: lists all remaining subscriptions
 */
const trackSubscribers = false // set to true to show listeners
const outstandingSubscriptions = new Map()
const listSubscribers = () => Array.from(outstandingSubscriptions.values()).map(ref => ref.path)
const trackUnsubscriber = unsubscriber => () => {
    unsubscriber()
    outstandingSubscriptions.delete(unsubscriber)
    console.log('removing listener: subscribers now', listSubscribers())
}

/*
 * Listen for changes to the Firestore document at the given path (repeatedly) calling callback when it changes
 * @sig addDocumentListener :: (String, CallbackFunc) -> Unsubscriber
 *  CallbackFunc = FirestoreDocument -> ()
 *  Unsubscriber = () -> {}
 */
const addDocumentListener = (path, callback, errorCallback = e => logError(path, e)) => {
    const _callback = snapshot => {
        console.groupCollapsed('listener called (doc)', path)
        console.log(snapshot.id, snapshot.data())
        console.groupEnd()
        return callback(snapshot)
    }

    const ref = Firestore.doc(firestore, path)
    const unsubscriber = Firestore.onSnapshot(ref, trackSubscribers ? _callback : callback, errorCallback)

    outstandingSubscriptions.set(unsubscriber, ref)

    if (trackSubscribers) {
        console.groupCollapsed('adding listener', path, ' (doc)')
        console.log('subscribers now', listSubscribers())
        console.trace()
        console.groupEnd()
    }
    return trackSubscribers ? trackUnsubscriber(unsubscriber) : unsubscriber
}

/*
 * Listen for changes to the Firestore document at the given path (repeatedly) calling callback when it changes
 * @sig addDocumentListener :: ({ path: String, callback: CallbackFunc, createdSince: Timestamp|undefined }) -> Unsubscriber
 *  CallbackFunc = FirestoreDocument -> ()
 *  Unsubscriber = () -> {}
 *
 * If there is a value for createdSince, we use a query against the 'createdAt' field, so that only documents
 * with a `createdAt` field that is more recent than `createdSince` are returned.
 *
 * For instance, when we first load a project, we get all the Uploads and then listen to the user's feeds collection.
 * It's pointless to listen to feedItems that are older than the time when we got all the Uploads, so we pass
 * in the time we read them all as the createdSince value
 */
const addCollectionListener = ({ path, callback, createdSince, errorCallback = e => logError(path, e) }) => {
    const _callback = snapshot => {
        console.groupCollapsed('listener called (collection)', path)
        const data = snapshot
            .docChanges()
            .map(d => [d.type, d.doc.data()])
            .flat()
        console.log(data)
        console.groupEnd()
        return callback(snapshot)
    }

    const ref = Firestore.collection(firestore, path)
    const query = createdSince
        ? Firestore.query(ref, Firestore.where('createdAt', '>', createdSince))
        : Firestore.query(ref)
    const unsubscriber = Firestore.onSnapshot(query, trackSubscribers ? _callback : callback, errorCallback)

    outstandingSubscriptions.set(unsubscriber, ref)

    if (trackSubscribers) {
        console.groupCollapsed('adding listener', path, ' (collection)')
        console.log('subscribers now', listSubscribers())
        console.trace()
        console.groupEnd()
    }
    return trackSubscribers ? trackUnsubscriber(unsubscriber) : unsubscriber
}

/*
 * Retrieve the Firestore document at the given path and create the specified (tagged) Type from it
 * The path *must* point to a document
 * @sig loadItemFromFirestoreDocument :: (TaggedType, String) -> Promise TaggedType
 *  TaggedType :: Geometry, Feature, etc.
 */
const loadItemFromFirestoreDocument = async (Type, path) => {
    try {
        const data = await getDocFromFirestore(path)
        return Type.fromFirebase(data)
    } catch (e) {
        console.error('loadItemFromFirestoreDocument ', path, e)
        throw e
    }
}
/*
 * Retrieve all the Firestore documents at the given (subcollection) path and create an item for each child
 * The path *must* point to a collection
 * @sig loadItemsFromFirestoreCollection :: (TaggedType, String) -> Promise [TaggedType]
 *  TaggedType :: Geometry, Feature, etc.
 */
const loadItemsFromFirestoreCollection = async (Type, path) => {
    const createItem = doc => Type.fromFirebase(assoc('id', doc.id, doc.data()))

    try {
        const query = Firestore.collection(firestore, path)
        const querySnapshot = await Firestore.getDocs(query)
        return querySnapshot.docs.map(createItem)
    } catch (e) {
        console.error('loadItemsFromFirestoreCollection ', path, e)
        throw e
    }
}

const loadItemsFromFirestoreCollectionAsLookup = async (Type, path) => {
    const array = await loadItemsFromFirestoreCollection(Type, path)
    return arrayToLookupTable('id', array)
}

/*
 * Load an Organization or Project and its related participants, pulled from the participants and suspendedParticipants
 * @sig loadItemAndParticipants :: (Project|Organization, String) -> Project|Organization
 */
const loadItemAndParticipants = async (Type, path) => {
    const measureLoadParticipants = mark('LoadParticipants' + path)

    const participantPath = `${path}/participants`
    const suspendedParticipantPath = `${path}/suspendedParticipants`

    const markParticipantSuspended = participant => Participant.update(participant, { isSuspended: true })

    let [participants, suspendedParticipants] = await Promise.all([
        loadItemsFromFirestoreCollectionAsLookup(Participant, participantPath),
        loadItemsFromFirestoreCollectionAsLookup(Participant, suspendedParticipantPath),
    ])

    // combine participants and suspendedParticipants
    suspendedParticipants = mapObject(markParticipantSuspended, suspendedParticipants)
    participants = mergeRight(participants, suspendedParticipants)

    const rootData = await getDocFromFirestore(path)

    measureLoadParticipants()

    return Type.fromFirebase(assoc('participants', participants, rootData))
}

/*
 *
 */
/*
 * Upload a file to Firebase Storage to storagePath and return a URL for it
 * @sig uploadFile :: (String, File|Blob, PercentCompleteCallback) -> Promise Url
 *
 * OptionalPercentageCompleteCallback = Percentage -> ()
 * Percentage = Number [0..1]
 *
 * @see https://firebase.google.com/docs/storage/web/upload-files
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Blob
 * @see https://developer.mozilla.org/en-US/docs/Web/API/File
 */
const uploadToStorage = (storagePath, file, percentCompleteCallback) =>
    new Promise((resolve, reject) => {
        const ref = FirebaseStorage.ref(storage, storagePath)
        const uploadTask = FirebaseStorage.uploadBytesResumable(ref, file)
        uploadTask.then(resolve).catch(reject)

        if (percentCompleteCallback)
            uploadTask.on('state_changed', snapshot => {
                const percent = snapshot.bytesTransferred / snapshot.totalBytes
                percentCompleteCallback(percent)
            })
    })

/*
 * Upload a file to Firebase Storage at projects/${projectId}/uploads/${uploadId} and return a URL for it
 * @sig uploadFile :: (Id, Id, File|Blob, OptionalPercentageCompleteCallback) -> Promise Url
 * OptionalPercentageCompleteCallback = Percentage -> ()
 * Percentage = Number [0..1]
 */
const uploadFile = async (projectId, uploadId, file, percentageCompleteCallback) =>
    uploadToStorage(`projects/${projectId}/uploads/${uploadId}`, file, percentageCompleteCallback)

/*
 * Upload a CanvasSource file to Firebase Storage and return a URL for it
 * @sig uploadCanvasSource :: (Id, Id, File|Blob, OptionalPercentageCompleteCallback) -> Promise Url
 *
 * OptionalPercentageCompleteCallback = Percentage -> ()
 * Percentage = Number [0..1]
 */
const uploadCanvasSource = async (projectId, canvasSourceId, file, percentageCompleteCallback) => {
    const path = `projects/${projectId}/canvasSources/${canvasSourceId}`
    await uploadToStorage(path, file, percentageCompleteCallback)
    return downloadUrlForPath(path)
}

/*
 * Each object in Firebase Storage is at a given hierarchical path.
 * You can get a URL for an object at a given path by calling getDownloadURL for the path
 * (The download URL is valid for only 7 days, so we can't just store the URL in Firestore.)
 * This function returns the download URL for the given Firebase Storage path
 *
 * @sig downloadUrlForPath :: String -> Promise String
 */
const downloadUrlForPath = storagePath => {
    const ref = FirebaseStorage.ref(storage, storagePath)
    return FirebaseStorage.getDownloadURL(ref)
}

/*
 * When you update a Firestore document and the change you want to make resets a value to undefined,
 * rather than sending undefined, you have to use the specific Firestore marker returned by deleteField()
 *
 * This function looks for any undefined value in o and converts its value from undefined to deleteField()
 * @sig enrichDeletedFields :: ({k:v}, *) -> {k:v}
 *
 * Note: specify null as the sentinel if you're sending a field deletion to one of our Firebase Functions
 */
const enrichDeletedFields = (o, sentinel = Firestore.deleteField()) => {
    const enrichDeletedField = value => (typeof value === 'undefined' ? sentinel : value)
    return mapObject(enrichDeletedField, o)
}

/*
 * Download the related item for a FeedItem. Returns undefined if the expectedItemType or expectedAction doesn't match
 * (Multiple listeners will be listening to projects/participants/items; most of them should ignore any particular feed item.)
 * @sig itemFromFeedItem :: (Type, String, String, FeedItem) -> Promise Item|undefined
 */
const itemFromFeedItem = async (Type, expectedItemType, expectedAction, feedItem) => {
    // prettier-ignore
    const pathForType = () => {
        if (expectedItemType === 'Collaboration') return `projects/${projectId}/collaborations/${itemId}`
        if (expectedItemType === 'Comment')       return `projects/${projectId}/comments/${itemId}`
        if (expectedItemType === 'Update')        return `projects/${projectId}/updates/${itemId}`
        if (expectedItemType === 'Upload')        return `projects/${projectId}/uploads/${itemId}`

        throw new Error(`Don't understand type: `, expectedItemType)
    }

    const { action, projectId, itemId, itemType } = feedItem

    // we're not interested in this feed item
    if (itemType !== expectedItemType || action !== expectedAction) return

    // TODO: if the document doesn't exist, make sure it appears in a deleted collection

    // download the document the feed item points to
    return await loadItemFromFirestoreDocument(Type, pathForType())
}

/*
 * Query Firebase using a single 'where' constructed from field, comparator and value
 * (see loadAllItemsForCollaboration)
 *
 * @sig queryFromFirestore = (String, String, String, String) -> Promise [FirestoreItem]
 */
const queryFromFirestore = async (path, field, comparator, value) => {
    const collection = Firestore.collection(firestore, path)
    const query = Firestore.query(collection, Firestore.where(field, comparator, value))
    const snapshot = await Firestore.getDocs(query)
    return snapshot.docs.map(doc => assoc('id', doc.id, doc.data()))
}

const loadAllItemsForCollaboration = async (projectId, collaborationId) => {
    // all items related to a collaboration (except Collaboration itself) have a field named collaborationId
    const items = p => queryFromFirestore(`projects/${projectId}/${p}`, 'collaborationId', '==', collaborationId)

    const promises = [
        getDocFromFirestore(`projects/${projectId}/collaborations/${collaborationId}`),
        items('geometries'),
        items('features'),
        items('comments'),
        items('updates'),
        items('uploads'),
    ]
    const [collaboration, geometries, features, comments, updates, uploads] = await Promise.all(promises)

    return {
        geometry: Geometry.fromFirebase(geometries[0]),
        feature: Feature.fromFirebase(features[0]),
        collaboration: Collaboration.fromFirebase(collaboration),
        comments: comments.map(Comment.fromFirebase),
        updates: updates.map(Update.fromFirebase),
        uploads: uploads.map(Upload.fromFirebase),
    }
}

export {
    addDocumentListener,
    addCollectionListener,
    existsDocFromFirestore,
    getDocFromFirestore,
    getCountQueryFromFirestore,
    itemFromFeedItem,
    loadAllItemsForCollaboration,
    loadItemFromFirestoreDocument,
    loadItemsFromFirestoreCollection,
    loadItemsFromFirestoreCollectionAsLookup,
    loadItemAndParticipants,
    uploadFile,
    uploadCanvasSource,
    downloadUrlForPath,
    enrichDeletedFields,
}
