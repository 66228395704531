/*
 * SelectMenu component allows selection of multiple items from a dropdown list.
 * Can also be used as a single-select, but for such cases the Select component would be a first choice.
 * The inner list of items has to be composed out of the SelectMenu subcomponents to allow proper keyboard navigation,
 * though such components can only be wrappers - you decide what's the item content.
 */

import React, { useRef } from 'react'
import { styled } from '../range-theme/index.js'
import { Checkbox, FlexRow, TextInput } from './index.js'
import DropdownMenuWithTooltip from './DropdownMenuWithTooltip.js'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

const StyledListItem = styled(DropdownMenuWithTooltip.MenuItem, {
    alignItems: 'center',
    color: '$neutral05',
    padding: '8px 6px',
    fontSize: '14px',
    lineHeight: '21px',
    width: '100%',
    userSelect: 'none',
    cursor: 'pointer',
    borderRadius: '6px',
    marginBottom: 4,

    '&:last-child': {
        marginBottom: 0,
    },

    '&:hover': {
        backgroundColor: '$primary02',
        color: '$neutral04',
        [`& ${Checkbox}`]: {
            border: '1px solid $primary04',
        },
    },

    '&[data-selected="true"]': {
        borderRadius: '6px',
        [`& ${Checkbox}`]: {
            border: '1px solid $primary04',
        },
    },

    '&[data-highlighted]': {
        backgroundColor: '$primary02',
        color: '$neutral04',
        borderRadius: '6px',
        [`& ${Checkbox}`]: {
            border: '1px solid $primary04',
        },
    },
})

/*
 * SelectMenu.Option is a wrapper for the list item. Supports multi-selection by rendering a checkbox.
 */
const SelectMenuOption = ({ value, multiSelect, onItemClick, onCheckboxClick, children, isSelected, ...rest }) => (
    <StyledListItem
        key={`item-${value}`}
        onSelect={e => {
            onItemClick(value)
        }}
        data-selected={isSelected}
        {...rest}
    >
        <FlexRow css={{ gap: 10 }}>
            {multiSelect && (
                <Checkbox
                    size="md"
                    id={value}
                    checked={isSelected}
                    onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        onCheckboxClick(value)
                    }}
                />
            )}
            {children}
        </FlexRow>
    </StyledListItem>
)

/*
 * SelectMenu.Root contains all the parts of SelectMenu.
 * children - SelectMenu options
 * triggerElement - React.Node, element that toggles the SelectMenu content visibility
 * width - 362px by default
 * maxListHeight - by default full available height
 */
const SelectMenuRoot = React.forwardRef(
    (
        {
            children,
            triggerElement,
            align = 'end',
            width = '362px',
            maxListHeight = 'var(--radix-dropdown-menu-content-available-height)',
            onOpenChange,
            onInteractOutside,
            ...rest
        },
        contentRef
    ) => {
        return (
            <DropdownMenuWithTooltip.Root onOpenChange={onOpenChange} {...rest}>
                {triggerElement}
                <DropdownMenu.Portal>
                    <DropdownMenuWithTooltip.Content
                        className="select-menu-options"
                        side="bottom"
                        align={align}
                        sideOffset={6}
                        loop={true}
                        onInteractOutside={onInteractOutside}
                        css={{
                            width,
                            maxWidth: `var(--radix-dropdown-menu-content-available-width)`,
                            maxHeight: `min(calc(var(--radix-dropdown-menu-content-available-height) - 40px), ${maxListHeight})`,
                            padding: '8px',
                            backgroundColor: '$neutral10',
                            gap: 0,
                        }}
                        ref={contentRef}
                    >
                        {children}
                    </DropdownMenuWithTooltip.Content>
                </DropdownMenu.Portal>
            </DropdownMenuWithTooltip.Root>
        )
    }
)

/*
 * SelectMenu.Input is an input element that you can use as one of the SelectMenu list items.
 */
const SelectMenuInput = ({ placeholder, value, onChange }) => {
    const inputRef = useRef()
    return (
        <SelectMenu.Item
            css={{ padding: 0, flex: '1 0 auto', '&:hover': { backgroundColor: '$primary01' } }}
            onSelect={e => e.preventDefault()} // prevent the dropdown from collapsing when input is clicked
            onPointerEnter={() => inputRef?.current.focus()}
            onPointerLeave={event => event.preventDefault()}
            onPointerMove={event => event.preventDefault()}
        >
            <TextInput
                ref={inputRef}
                autoFocus="autofocus"
                css={{
                    height: 36,
                    marginBottom: 8,
                }}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                onKeyDown={e => {
                    if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp' && e.key !== 'Escape') e.stopPropagation() // allow bubbling only for ArrowDown, ArrowUp and Escape
                }}
                iconName="searchFollowers"
                variant="default"
            />
        </SelectMenu.Item>
    )
}

/*
 * SelectMenu.Trigger is a wrapper for an element that should toggle the SelectMenu list.
 */
const SelectMenuTrigger = props => <DropdownMenuWithTooltip.Trigger {...props} />

/*
 * SelectMenu.Item is a raw wrapper for the list item. Use only if you want to create a new custom menu option.
 * Otherwise, please use SelectMenu.Option, that already supports multi-selection and selection state.
 */
const SelectMenuItem = props => <DropdownMenuWithTooltip.MenuItem {...props} />

/*
 * Use as a visual content separator.
 */
const Separator = props => <DropdownMenuWithTooltip.Separator {...props} />

const SelectMenu = {
    Root: SelectMenuRoot,
    Item: SelectMenuItem,
    Option: SelectMenuOption,
    Trigger: SelectMenuTrigger,
    Input: SelectMenuInput,
    Separator,
}

export default SelectMenu
