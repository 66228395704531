/*
 * A helper module to handle hover state in Mapbox Draw modes
 *
 * It returns an instance that extends any mode with hover state functionality.
 * Requires the user to pass a function to re-render a geometry and to get the full geometry instance
 * from its' ID. Handles hover state for map features, vertices and midpoints.
 *
 * Hold information about what geometry is hovered over. Can be either that geometry ID or a coord path
 * of a vertex or midpoint.
 * Type HoverStateData : {}
 */
import { lib as MapboxDrawLib } from '@mapbox/mapbox-gl-draw'

const { isVertex, isOfMetaType, isInactiveFeature, isActiveFeature, noTarget } = MapboxDrawLib.CommonSelectors

/*
 * @sig isMidpoint :: (MapboxMouseEvent) -> Boolean
 */
const isMidpoint = isOfMetaType('midpoint')

/*
 * Get coord path for a given feature.
 * Used to distinguish which vertex or midpoint we are dealing with.
 * Because every midpoint has the exact same coord as the vertex that follows it
 * a suffix 'a' is added to make them unique, and simplify usage
 * @sig getCoordPathFromFeature :: GeoJSON -> String
 */
const getCoordPathFromFeature = feature =>
    feature.properties.meta === 'midpoint' ? feature.properties.coord_path + 'a' : feature.properties.coord_path

const initHoverState = ({ doRender, getFeature }) => {
    let hoverState = null
    let lastUpdatedGeometryId = null

    /*
     * Given a geometry ID trigger a render of it
     * and, if there are any related geometries (parent or children),
     * then render them too
     * @sig renderGeometryAndRelated :: (String) -> ()
     */
    const renderGeometryAndRelated = geometryId => {
        doRender(geometryId)
        const geometry = getFeature(geometryId)
        const relatedGeometryId = geometry?.properties?.arrowFeatureId ?? geometry?.properties?.parentFeatureId ?? null
        if (relatedGeometryId) doRender(relatedGeometryId)
    }

    /*
     * Given a HoverState and geometryId verify if an update is needed and handle it
     * @sig updateHoverState :: (HoverStateData, String) -> ()
     */
    const updateHoverState = (newHoverState, geometryId) => {
        if (newHoverState === hoverState) return
        hoverState = newHoverState
        if (lastUpdatedGeometryId && lastUpdatedGeometryId !== geometryId)
            renderGeometryAndRelated(lastUpdatedGeometryId)
        if (geometryId) renderGeometryAndRelated(geometryId)
        lastUpdatedGeometryId = geometryId
    }

    /*
     * Based on MouseEvent information and knowing user is over any geometry
     * update hover state accordingly
     * @sig handleNonEmptyGeometryMouseEvent :: (MapboxMouseEvent) -> ()
     */
    const handleNonEmptyGeometryMouseEvent = e => {
        if (isVertex(e) || isMidpoint(e)) {
            const coordPath = getCoordPathFromFeature(e.featureTarget)
            if (coordPath !== hoverState) updateHoverState(coordPath, e.featureTarget.properties.parent)
        } else if (isActiveFeature(e)) {
            updateHoverState(null, null)
        } else if (isInactiveFeature(e)) {
            const geometryId = e.featureTarget?.properties?.id || null
            updateHoverState(geometryId, geometryId)
        }
    }

    /*
     * @sig handleMouseMove :: (MapboxMouseEvent) -> ()
     */
    const handleMouseMove = e => {
        if (noTarget(e)) updateHoverState(null, null)
        else handleNonEmptyGeometryMouseEvent(e)
    }

    /*
     * Given a GeoJSON return if the user is hovering over it
     * @sig isGeoJsonHovered :: (GeoJSON) -> Boolean
     */
    const isGeoJsonHovered = geojson =>
        hoverState === geojson.properties.id ||
        (geojson.properties.user_parentFeatureId && hoverState === geojson.properties.user_parentFeatureId) ||
        (geojson.properties.user_arrowFeatureId && hoverState === geojson.properties.user_arrowFeatureId)

    /*
     * Given a GeoJSON of a handle return if the user is hovering over it
     * @sig isHandleHovered :: (GeoJSON) -> Boolean
     */
    const isHandleHovered = handle => hoverState === getCoordPathFromFeature(handle)

    return { isGeoJsonHovered, handleMouseMove, isHandleHovered }
}

export { initHoverState }
