import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { styled } from '../range-theme/index.js'
import { FlexColumn, FlexRow, Icon, TextInput } from './index.js'
import ScrollArea from './ScrollArea.js'

const StyledContainer = styled(FlexColumn, { position: 'relative' })

const StyledInput = styled(TextInput, {
    width: '450px',
})

const StyledOptionList = styled(FlexColumn, {
    width: '450px',
    backgroundColor: '$neutral10',
    border: '1px solid $neutral07',
    borderRadius: '6px',
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    zIndex: 1,
    top: '44px',
})

const StyledOptionItem = styled(FlexRow, {
    color: '$neutral05',
    cursor: 'pointer',
    borderRadius: '6px',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    padding: '8px',
    margin: '8px',
    ai: 'center',

    '&:hover': {
        color: '$neutral04',
        backgroundColor: '$neutral08',
    },
})

const StyledEmptyState = styled(FlexRow, {
    color: '$neutral05',
    justifyContent: 'center',
    padding: '20px',
    fontSize: '14px',
})

/*
 * @sig SelectTypeahead :: (Boolean, NewLabelFunc, [Item], String, CreateItemSelectFunc, ItemSelectFunc, String) -> ()
 *
 * NewLabelFunc         = String -> String  eg. newValue => `Create "${newValue}"`
 * CreateItemFunc       = String -> *       eg. newTagName => setItemSelection({ newTagName, isNew: true })
 * CreateItemSelectFunc = String -> *       eg. item => setItemSelection({ newTagName, isNew: false })
 *
 * Item = { id: String, searchLabel: String, label: string }
 *
 *   searchLabel    eg. "Option 3"
 *   label          eg. "Option 3 (10)"
 */
const SelectTypeahead = ({
    creatable = false,
    formatCreateNewLabel,
    items,
    maxListHeight = '280px',
    onCreateItemSelect,
    onItemSelect,
    placeholder,
    value,
    onValueChange,
}) => {
    const [isFocused, setIsFocused] = useState(false)
    const [filteredItems, setFilteredItems] = useState([])
    const inputRef = useRef()
    const listRef = useRef()

    const [highlightedIndex, setHighlightedIndex] = useState(-1)

    useEffect(() => {
        // Reset highlighted index when the list changes
        setHighlightedIndex(-1)
    }, [filteredItems])

    useEffect(() => {
        setFilteredItems([...items])
    }, [])

    const focusOut = () => {
        setIsFocused(false)
    }

    useEffect(() => {
        // Listens for clicks outside of the input and list to hide the list and inactivate the input
        const listener = event => {
            // the input ref is undefined - that means the input is not even visible, same the list, so no need to do anything
            if (!inputRef.current) return
            // do nothing if user clicked on input or any element of the list
            if (inputRef.current.contains(event.target) || listRef.current?.contains(event.target)) return
            focusOut()
        }
        document.addEventListener('mousedown', listener)
        return () => document.removeEventListener('mousedown', listener)
    }, [])

    useEffect(() => {
        if (highlightedIndex >= 0 && itemRefs.current[highlightedIndex]) {
            itemRefs.current[highlightedIndex].current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            })
        }
    }, [highlightedIndex])

    const updateFilteredWithInput = inputValue => {
        const newFiltered = items.filter(item => {
            const testStr = item?.searchLabel ?? item.label
            return testStr.toLowerCase().includes(inputValue)
        })
        setFilteredItems(newFiltered)
    }

    const handleInputChange = value => {
        const newValue = value.trim()
        updateFilteredWithInput(newValue.toLowerCase())
        onValueChange(value)
    }

    const handleItemSelected = item => {
        const newInputValue = item?.searchLabel ?? item.label
        onValueChange(newInputValue)
        focusOut()
        onItemSelect(item)
    }

    const handleCreateNewSelected = () => {
        const inputValue = value.trim()
        focusOut()
        onCreateItemSelect(inputValue)
    }

    const itemRefs = useRef([])

    const renderItems = () => {
        const Option = ({ item, idx, onClick, type }) => (
            <StyledOptionItem
                ref={itemRefs.current[idx]}
                key={`select-typeahead-item-${idx}`}
                onClick={onClick}
                css={idx === highlightedIndex ? { backgroundColor: '$neutral08', color: '$neutral04' } : {}}
            >
                {type === 'add' && <Icon name="addCircled" iconSize="14px" css={{ marginRight: '8px' }} />}
                {item.label}
            </StyledOptionItem>
        )

        const listItems = filteredItems.map((item, idx) => {
            // Assign or create a ref for each item
            if (!itemRefs.current[idx]) {
                itemRefs.current[idx] = React.createRef()
            }
            return <Option item={item} idx={idx} onClick={() => handleItemSelected(item)} />
        })

        if (creatable) {
            const inputValue = value.trim()
            const hasThisEntry = items?.some(item => {
                const testStr = item?.searchLabel ?? item?.label
                return testStr?.toLowerCase().localeCompare(inputValue.toLowerCase()) === 0
            })
            if (inputValue.length > 0 && !hasThisEntry) {
                const item = { label: formatCreateNewLabel(inputValue), searchLabel: inputValue }
                listItems.push(
                    <Option item={item} idx={listItems.length} onClick={handleCreateNewSelected} type="add" />
                )
            }
        }

        // Show empty state only when input is empty and there are no filtered items
        if ((!creatable || !value.length) && listItems.length === 0)
            return <StyledEmptyState>No tags available</StyledEmptyState>

        return listItems
    }

    const handleKeyDown = event => {
        const highlightNext = () => setHighlightedIndex(prevIndex => (prevIndex < maxIndex ? prevIndex + 1 : prevIndex))
        const highlightPrevious = () => setHighlightedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0))
        const togglehighlighted = () => handleItemSelected(filteredItems[highlightedIndex])

        const key = event.key
        const maxIndex = creatable ? filteredItems.length : filteredItems.length - 1
        const isThereAhighlightedItem = highlightedIndex >= 0 && highlightedIndex < filteredItems.length

        if (key === 'ArrowDown') highlightNext()
        else if (key === 'ArrowUp') highlightPrevious()
        else if (key === 'Enter' && isThereAhighlightedItem) togglehighlighted()
        else if (key === 'Enter' && !isThereAhighlightedItem && creatable) handleCreateNewSelected()
        else if (key === 'Escape') setIsFocused(false) // Close the expanded list
    }

    return (
        <StyledContainer>
            <StyledInput
                value={value}
                placeholder={placeholder}
                onChange={handleInputChange}
                onFocus={() => setIsFocused(true)}
                onKeyDown={handleKeyDown}
                ref={inputRef}
            />
            {isFocused && (
                <StyledOptionList ref={listRef}>
                    <ScrollArea maxHeight={maxListHeight}>{renderItems()}</ScrollArea>
                </StyledOptionList>
            )}
        </StyledContainer>
    )
}

SelectTypeahead.propTypes = {
    creatable: PropTypes.bool,
    formatCreateNewLabel: PropTypes.func,
    items: PropTypes.array.isRequired,
    onCreateItemSelect: PropTypes.func,
    onItemSelect: PropTypes.func,
    placeholder: PropTypes.string,
}

export default SelectTypeahead
