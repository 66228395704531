/*
 * BasicDrawMode
 *
 * Base component to keep all shared behaviours between all drawing modes.
 * Should be extended by every draw mode in the app
 *
 * Type DrawModeState :: Object
 * Gets overridden by other mode's states when extending
 *
 */

const BasicDrawMode = {}

/*
 * Called when keyboard key is released.
 * @sig onKeyUp :: (DrawModeState, Event) -> DrawModeState
 */
BasicDrawMode.onKeyUp = function (state, e) {
    if (e.key === 'Enter') return this.finishDrawing(state)
    if (e.key === 'Escape' || e.key === 'Delete') return this.cancelDrawingAndDelete(state)
}

/*
 * Callback for trash action
 * @sig onTrash :: (DrawModeState) -> ()
 */
BasicDrawMode.onTrash = function (state) {
    this.cancelDrawingAndDelete(state)
}

/*
 * Handler to successfully finish drawing action
 * has to be overridden by every draw mode
 * @sig finishDrawing :: (DrawModeState) -> ()
 */
BasicDrawMode.finishDrawing = function (state) {
    throw new Error('You are using a drawing mode without finishDrawing defined!')
}

/*
 * Handler to safely cancel out of drawing mode and do cleanup
 * has to be overridden by every draw mode
 * @sig cancelDrawingAndDelete :: (DrawModeState) -> ()
 */
BasicDrawMode.cancelDrawingAndDelete = function (state) {
    throw new Error('You are using a drawing mode without cancelDrawingAndDelete defined!')
}

export default BasicDrawMode
