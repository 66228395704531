import React from 'react'
import { Box, Checkbox, FlexRow, IconButtonWithTooltip, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledTagManagerListItem = styled(FlexRow, {
    ai: 'center',
    height: '48px',
    borderBottom: '1px solid $neutral07',
    flex: 'auto 0 0',
    gap: '8px',

    '&:last-child': {
        borderBottom: 'none',
    },
})

const StyledItemCell = styled(FlexRow, {
    flex: '1 1',
    justifyContent: 'start',
    ai: 'center',
    gap: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const StyledItemText = styled(Text, {
    width: 'auto',
    color: '$neutral04',
    fs: 16,
    justifyContent: 'start',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const TagManagerListItem = ({ count, name, onDelete, onEdit, onSelectChange, selected }) => (
    <StyledTagManagerListItem>
        <Box css={{ ml: '16px', mr: '8px' }}>
            <Checkbox size="md" checked={selected} onCheckedChange={onSelectChange} />
        </Box>
        <StyledItemCell>
            <StyledItemText>{name}</StyledItemText>
        </StyledItemCell>
        <StyledItemCell>
            <StyledItemText>{count}</StyledItemText>
            <IconButtonWithTooltip
                iconName="edit"
                tooltipText="Edit Tag"
                side="bottom"
                onClick={onEdit}
                size="16"
                variant="tagManager"
                css={{
                    ml: 'auto',
                }}
            />
            <IconButtonWithTooltip
                iconName="trash"
                tooltipText="Delete Tag"
                side="bottom"
                onClick={onDelete}
                size="16"
                variant="tagManagerDestructive"
                css={{
                    mr: 12,
                }}
            />
        </StyledItemCell>
    </StyledTagManagerListItem>
)

export default TagManagerListItem
