/*
 * Specialize a Radix DropdownMenu to allow a tooltip (nicely).
 *
 * Defines:
 *     Root       outer-most element
 *     Trigger    the trigger button with the tooltip
 *     Content    the contents of the dropdown menu itself
 *     MenuItem   individual buttons within the dropdown menu
 *
 * Both Trigger and MenuItem use the "asChild" flag, so their "children" have to be a button of some kind
 *
 * Tracking whether the tooltip is open:
 *
 * By default, when you close a DropdownMenu (by clicking somewhere else, for instance) the tooltip will
 * automatically reappear, which is not what we want.
 *
 * Probably there's a good way to fix this, but we're not using whatever that way would be.
 *
 * Instead the Root and Trigger subcomponents both modify a shared state: shouldShowTooltip/setShouldShowTooltip
 * The state is stored in a React context for which the Root is a provider and the Trigger a consumer.
 *
 * Whenever the dropdown closes, shouldShowTooltip is set to false.
 *
 * When the tooltip is rerendered right afterwards,
 * it is actually shown ONLY if shouldShowTooltip is true -- and, in any event, shouldShowTooltip is set back to true.
 */
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import React, { createContext, forwardRef, useContext, useState } from 'react'
import { styled } from '../range-theme/index.js'

// ---------------------------------------------------------------------------------------------------------------------
// Stitches components
// ---------------------------------------------------------------------------------------------------------------------

/*
 * The tooltip content
 */
const StyledTooltip = styled(TooltipPrimitive.Content, {
    backgroundColor: '$neutral04',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '6px',
    borderRadius: '6px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    color: '$neutral07',
    zIndex: 999,
    fs: 14,
    fw: 500,
})

/*
 * The arrow connecting the tooltip to the trigger button
 */
const StyledTooltipArrow = styled(TooltipPrimitive.TooltipArrow, {
    fill: '$neutral04',
})

/*
 * A wrapper for the content of the dropdown menu
 */
const menuContentStyle = {
    backgroundColor: '$neutral09Alpha',
    display: 'flex',
    zIndex: 999,
    flexDirection: 'column',
    gap: '8px',
    padding: '8px',
    borderRadius: '6px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    border: '1px solid $neutral07',
    backdropFilter: 'blur(12px) saturate(190%) contrast(50%) brightness(130%)',
    '&:hover': {
        color: '$neutral04',
    },

    variants: {
        variant: {
            'no-appearance': {
                background: 'none',
                border: 'none',
                padding: '0',
                backdropFilter: 'none',
            },
        },
    },
}

/*
 * A wrapper for the content of the dropdown menu
 */
const StyledDropdownMenuContent = styled(DropdownMenuPrimitive.Content, menuContentStyle)

const StyledDropdownMenuSubContent = styled(DropdownMenuPrimitive.SubContent, menuContentStyle)

const menuItemStyle = {
    display: 'flex',
    color: '$neutral05',
    padding: '8px 12px 8px 10px',
    fontSize: '14px',
    fontWeight: '400',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '4px',
    gap: '1px',
    outline: 'none',
    transitionDuration: '0.4s',

    '&:hover': {
        backgroundColor: '$primary02',
        cursor: 'pointer',
        '&[data-disabled]': {
            backgroundColor: 'none',
        },
    },

    variants: {
        destructive: {
            true: {
                color: '$red03',
            },
        },
    },
}

/*
 * A button in the dropdown menu
 */
const StyleDropdownMenuItem = styled(DropdownMenuPrimitive.DropdownMenuItem, menuItemStyle)

const StyledDropdownMenuSubTrigger = styled(DropdownMenuPrimitive.SubTrigger, menuItemStyle)

/*
 * React context magic
 */
const DropdownWithTooltipContext = createContext()
const useDropdownMenuWithTooltipContext = () => useContext(DropdownWithTooltipContext)

/*
 * Wrapper for DropdownWithTooltipContext.Provider AND DropdownMenuPrimitive.Root that owns shouldShowTooltip
 * and publishes it to DropdownWithTooltipContext
 */
const DropdownMenuWithTooltipRoot = ({ initialOpen = false, children, onOpenChange = () => {}, ...rest }) => {
    const [shouldShowTooltip, setShouldShowTooltip] = useState(true)
    const [dropdownOpen, setDropdownOpen] = useState(initialOpen)

    const _setDropdownOpen = isOpen => {
        if (!isOpen) setShouldShowTooltip(false) // just closed the dropdown; don't automatically show the tooltip
        setDropdownOpen(isOpen)
        onOpenChange(isOpen)
    }

    return (
        <DropdownWithTooltipContext.Provider value={{ shouldShowTooltip, setShouldShowTooltip }}>
            <DropdownMenuPrimitive.Root open={dropdownOpen} onOpenChange={_setDropdownOpen} {...rest}>
                {children}
            </DropdownMenuPrimitive.Root>
        </DropdownWithTooltipContext.Provider>
    )
}

/*
 * Wrapper for TooltipPrimitive.Root AND TooltipPrimitive.Trigger AND DropdownMenuPrimitive.Trigger
 * They're all marked with asChild so these three AND the child Button are all coalesced into a single DOM button
 *
 *     tooltipText is shown when hovering over the buttom
 *     children must be a button of some kind
 */
const DropdownMenuWithTooltipTrigger = ({ tooltipText, children }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const { shouldShowTooltip, setShouldShowTooltip } = useDropdownMenuWithTooltipContext()

    // if we just closed the dropdown; don't automatically show the tooltip again
    // otherwise, show or hide the tooltip normally
    const _setTooltipOpen = isOpen => {
        if (!shouldShowTooltip) setShouldShowTooltip(true)
        else setTooltipOpen(isOpen)
    }

    return (
        <TooltipPrimitive.Provider>
            <TooltipPrimitive.Root delayDuration={0} open={tooltipOpen} onOpenChange={_setTooltipOpen} asChild>
                <TooltipPrimitive.Trigger asChild>
                    <DropdownMenuPrimitive.Trigger asChild>{children}</DropdownMenuPrimitive.Trigger>
                </TooltipPrimitive.Trigger>

                {tooltipText && (
                    <TooltipPrimitive.Portal>
                        <StyledTooltip side="bottom" align="center" offset={20} sideOffset={8}>
                            {tooltipText}
                            <StyledTooltipArrow />
                        </StyledTooltip>
                    </TooltipPrimitive.Portal>
                )}
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    )
}

/*
 * Wrapper for DropdownMenuPrimitive.Content.
 */
const DropdownMenuContent = forwardRef(({ children, side, align, sideOffset, variant, css = {}, ...rest }, ref) => {
    return (
        <StyledDropdownMenuContent
            css={css}
            side={side}
            align={align}
            sideOffset={sideOffset}
            ref={ref}
            variant={variant}
            {...rest}
        >
            {children}
        </StyledDropdownMenuContent>
    )
})

/*
 * Wrapper for DropdownMenuPrimitive.DropdownMenuItem. asChild is set so the DropdownMenuPrimitive.DropdownMenuItem
 * will be coalesced with the children button into a single DOM element
 *
 * destructive is passed as a variant to the StyleDropdownMenuItem
 * children should be a button
 */
const DropdownMenuItem = ({ destructive, children, onSelect, ...rest }) => (
    <StyleDropdownMenuItem destructive={destructive} onSelect={onSelect} asChild {...rest}>
        {children}
    </StyleDropdownMenuItem>
)

const StyledDropdownSeparator = styled(DropdownMenuPrimitive.DropdownMenuSeparator, {
    width: '100%',
    height: 1,
    marginBottom: 4,
    backgroundColor: '$neutral07',
})

const DropdownMenuSubTrigger = ({ children }) => (
    <StyledDropdownMenuSubTrigger asChild>{children}</StyledDropdownMenuSubTrigger>
)

const DropdownMenuSubContent = forwardRef(({ children, side, align, sideOffset, alignOffset }, ref) => (
    <StyledDropdownMenuSubContent {...{ side, align, sideOffset, alignOffset, ref }}>
        {children}
    </StyledDropdownMenuSubContent>
))

/*
 * Returns 4 subcomponents
 */
const DropdownMenuWithTooltip = {
    Root: DropdownMenuWithTooltipRoot,
    Trigger: DropdownMenuWithTooltipTrigger,
    MenuItem: DropdownMenuItem,
    Content: DropdownMenuContent,
    Group: DropdownMenuPrimitive.Group,
    Separator: StyledDropdownSeparator,
    Portal: DropdownMenuPrimitive.Portal,
    Sub: DropdownMenuPrimitive.Sub,
    SubContent: DropdownMenuSubContent,
    SubTrigger: DropdownMenuSubTrigger,
}

export default DropdownMenuWithTooltip
