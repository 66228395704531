import { sendEmailVerification } from 'firebase/auth'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Flex, FlexColumn, Logo, LogoText, Text } from '../components-reusable/index.js'
import { attemptSignOut } from '../firebase/authentication-helpers.js'
import { auth } from '../firebase/configure-environment/config.js'
import {
    BGLogo,
    ButtonLayout,
    LabelLayout,
    LinkTextCTA,
    SecondaryLabel,
    StyledLogo,
    ScrollableContainer,
    StyledPage,
} from './RegistrationSharedStyles.js'

/*
 * Invited to org wrong account
 */

const InvitedToOrgWrongAccount = ({ loggedInUserEmail, onLogOut, onGotoDashboard }) => (
    <StyledPage>
        <BGLogo>
            <img src="/rangebackgroundicon.svg" icon="Range icon" />
        </BGLogo>
        <ScrollableContainer>
            <StyledLogo>
                <Logo />
                <LogoText />
            </StyledLogo>
            <LabelLayout>
                <FlexColumn>
                    <SecondaryLabel css={{ color: '$neutral05' }}>Logged in as</SecondaryLabel>
                    <SecondaryLabel css={{ color: '$neutral04', mb: 12, fs: 18, fw: 500 }}>
                        {loggedInUserEmail}
                    </SecondaryLabel>
                </FlexColumn>
                <SecondaryLabel>
                    You don't have access to this organization. Make sure you are logged in using the email address the
                    invite was sent to.
                </SecondaryLabel>
            </LabelLayout>
            <ButtonLayout>
                <Button onClick={onLogOut} variant="primary" type="submit" size="lg" css={{ w: '100%' }}>
                    <Text>Log Out</Text>
                </Button>
                <Button onClick={onGotoDashboard} variant="secondary" type="submit" size="lg" css={{ w: '100%' }}>
                    <Text>Go to Dashboard</Text>
                </Button>
            </ButtonLayout>
        </ScrollableContainer>
    </StyledPage>
)

InvitedToOrgWrongAccount.propTypes = {
    loggedInUserEmail: PropTypes.string.isRequired,
    onLogOut: PropTypes.func.isRequired,
    onGotoDashboard: PropTypes.func.isRequired,
}

const InvitationExpiredModal = ({ onResend }) => (
    <StyledPage>
        <BGLogo>
            <img src="/rangebackgroundicon.svg" icon="Range icon" />
        </BGLogo>
        <ScrollableContainer>
            <StyledLogo>
                <Logo />
                <LogoText />
            </StyledLogo>
            <SecondaryLabel css={{ mt: 16, mb: 8 }}>
                It looks like your invite to join this organization has expired.
            </SecondaryLabel>
            <ButtonLayout>
                <Button onClick={onResend} variant="primary" type="submit" size="lg" css={{ w: '100%' }}>
                    <Text>Request New Invitation</Text>
                </Button>
            </ButtonLayout>
        </ScrollableContainer>
    </StyledPage>
)
/*
 * Verify Email Page
 */

const VerifyEmailModalDumb = ({ wasSent, onResend, onLogout }) => {
    const secondaryLabel =
        "Almost there, please check your email inbox and click the link we just sent to verify it's you."

    return (
        <StyledPage>
            <Box css={{ position: 'fixed' }}>
                <img src="/rangebackgroundicon.svg" icon="Range icon" />
            </Box>
            <ScrollableContainer>
                <StyledLogo>
                    <Logo />
                    <LogoText />
                </StyledLogo>
                {wasSent && (
                    <Flex
                        css={{
                            background: '$neutral09',
                            p: '16px 24px 16px 24px',
                            br: 999,
                            fw: 500,
                            color: '$neutral04',
                        }}
                    >
                        ✅ We resent the email
                    </Flex>
                )}
                <LabelLayout css={{ gap: '20px' }}>
                    {/* <PrimaryLabel>{primaryLabel}</PrimaryLabel> */}
                    <SecondaryLabel>{secondaryLabel}</SecondaryLabel>
                    <LinkTextCTA onClick={onResend} css={{ mt: 16 }}>
                        Didn't receive email? Resend
                    </LinkTextCTA>
                    <LinkTextCTA onClick={onLogout}>Log in with another email</LinkTextCTA>
                </LabelLayout>
            </ScrollableContainer>
        </StyledPage>
    )
}

const VerifyEmailModal = () => {
    const onResend = () => {
        if (auth.currentUser) {
            sendEmailVerification(auth.currentUser)
            setWasSent(true)
        }
    }

    const onLogout = async () => {
        try {
            await attemptSignOut()
            navigate('/')
        } catch (e) {
            console.error('Error logging out from firebase', e)
        }
    }

    const navigate = useNavigate()
    const [wasSent, setWasSent] = useState()
    return <VerifyEmailModalDumb wasSent={wasSent} onResend={onResend} onLogout={onLogout} />
}

export { InvitationExpiredModal, VerifyEmailModalDumb, VerifyEmailModal, InvitedToOrgWrongAccount }
