/*
 * Admin Page: Organization settings panel
 */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Flex, FlexRow, Image as ImageComponent, Switch, Text } from '../components-reusable/index.js'
import { OrganizationChangedCommand } from '../firebase/commands/organization-changed-command.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import * as Segment from '../segment/segment.js'
import AdminNavigationPanel, { AdminNavigationType } from './AdminNavigationPanel.js'
import AdminOrganizationsPanel from './AdminOrganizationsPanel.js'
import ImageUpload from './ImageUpload.js'
import Link from './Link.js'
import { ProceedModal } from './Modal.js'
import OrganizationIcon from './OrganizationIcon.js'
import { Select300, useOrganizationSizeDropdownOptions } from './pages/SharedComponents.js'

// Shared CSS
const topTableRowCss = {
    display: 'grid',
    gridTemplateColumns: '260px 1fr 2fr',
    ai: 'center',
    h: '70px',
}

const middleTableRowCss = {
    display: 'grid',
    gridTemplateColumns: '260px 1fr 2fr',
    ai: 'center',
    minHeight: '70px',
    height: 'auto',
    bt: '1px solid $neutral07',
}

const tableColumn1Css = { marginLeft: '24px', color: '$neutral05' }

const helperTextCss = {
    color: '$neutral05',
    fontStyle: 'italic',
    marginLeft: 'auto',
    marginTop: 8,
    marginBottom: 8,
}

const LogoWrapper = styled('div', {
    height: '40px',
    width: 'fit-content',
    overflow: 'hidden',
    padding: '5px',
    borderRadius: '6px',
    marginLeft: '16px',
    border: '1px solid $neutral07',
    background: '$neutral10',
})

/*
 * Organization name row
 */
const OrganizationName = ({ organization }) => {
    return (
        <Box css={topTableRowCss}>
            <Text css={tableColumn1Css}>Organization Name</Text>
            <Text css={{ marginLeft: '16px', color: '$neutral04' }}>{organization?.name ?? ''}</Text>
            <Text />
            {/* <Button css={{ marginRight: '16px', backgroundColor: '$neutral10' }} variant="secondary" size="lg">
                <Icon name="edit" css={buttonIconCss} />
                <Text>Update</Text>
            </Button> */}
        </Box>
    )
}

/*
 * Organization icon row
 */
const OrganizationIconRow = ({ organization = {}, isEditingAllowed, onUploadNewIcon, onRemoveIcon }) => {
    const { name = '', icon = null } = organization
    const disabled = !isEditingAllowed

    const mlCss = { marginLeft: '16px' }

    return (
        <Box css={middleTableRowCss}>
            <Text css={tableColumn1Css}>Organization Icon</Text>
            <FlexRow css={{ gap: 8, alignItems: 'center', flexWrap: 'wrap' }}>
                <Box css={mlCss}>
                    <OrganizationIcon src={icon} name={name} size={40} circle />
                </Box>
                {icon && (
                    <Button variant="no-appearance" size="sm" onClick={onRemoveIcon} disabled={disabled}>
                        <Text>Remove</Text>
                    </Button>
                )}
            </FlexRow>

            <FlexRow css={{ justifySelf: 'flex-end', alignItems: 'center', gap: '16px' }}>
                <Text css={helperTextCss}>
                    Displays as company avatar in the Organization selector. JPEG, PNG or SVG, 5mb limit.
                </Text>
                <ImageUpload addNewImage={onUploadNewIcon} maxWidth={96} maxHeight={96} disabled={disabled} />
            </FlexRow>
        </Box>
    )
}

/*
 * Organization logo row
 */
const OrganizationLogo = ({ organization = {}, isEditingAllowed, onUploadNewLogo, onRemoveLogo }) => {
    const { name = '', logo = null } = organization
    const disabled = !isEditingAllowed

    return (
        <Box css={middleTableRowCss}>
            <Text css={tableColumn1Css}>Organization Logo</Text>
            <FlexRow css={{ gap: 8, alignItems: 'center', flexWrap: 'wrap' }}>
                {logo ? (
                    <LogoWrapper>
                        <ImageComponent src={logo} alt={`${name} logo`} />
                    </LogoWrapper>
                ) : (
                    <Text css={{ marginLeft: '16px', color: '$neutral04' }}>-</Text>
                )}
                {logo && (
                    <Button variant="no-appearance" size="sm" onClick={onRemoveLogo} disabled={disabled}>
                        <Text>Remove</Text>
                    </Button>
                )}
            </FlexRow>
            <FlexRow css={{ justifySelf: 'flex-end', alignItems: 'center', gap: '16px' }}>
                <Text css={helperTextCss}>Displays on the front page of PDF reports. JPEG, PNG or SVG, 5mb limit.</Text>
                <ImageUpload addNewImage={onUploadNewLogo} disabled={disabled} />
            </FlexRow>
        </Box>
    )
}

/*
 * Turn allowAi on or off.
 * If we're trying allowAi OFF, then just send the change to Firebase and Redux,
 * but if we're turning it ON show a warning modal first and only turn allowAi on if the user agrees
 */
const OrganizationAllowAi = ({ organization = {}, isEditingAllowed }) => {
    const setAllowAi = allowAi => {
        if (!allowAi) runCommand(OrganizationChangedCommand.Outbound(organization.id, { allowAi: false }))
        else setShowWarning(true)
    }

    const turnAllowAiOn = () => {
        setShowWarning(false) // turn the warning off
        runCommand(OrganizationChangedCommand.Outbound(organization.id, { allowAi: true }))
    }

    const showAreYouSureModal = () => (
        <ProceedModal
            onCancel={() => setShowWarning(false)}
            onAction={turnAllowAiOn}
            primaryLabel="Enable AI Photo-Tagging Feature?"
            secondaryLabel="When enabled, we will use AI to automagically tag photos that are uploaded to Range. You can manually edit and delete the AI-generated photo tags to tweak the results. Enabling this feature will do so for everyone in your Organization."
            buttonLabel="Allow"
        />
    )

    const { allowAi = false } = organization
    const disabled = !isEditingAllowed
    const { runCommand } = useCommandHistory()
    const [showWarning, setShowWarning] = useState()

    return (
        <Box css={middleTableRowCss}>
            <Text css={tableColumn1Css}>Enable AI Photo-Tagging</Text>
            <div />
            <FlexRow css={{ alignItems: 'center', marginRight: 16, gap: 16, justifyContent: 'end' }}>
                <Text css={helperTextCss}>
                    AI features within Range are subject to the terms and conditions outlined in{' '}
                    <a href="https://www.range.io/legal" target="_blank" style={{ color: '#5D81FF' }}>
                        Range’s privacy policy
                    </a>
                </Text>
                {showWarning && showAreYouSureModal()}
                <Switch variant="filter" disabled={disabled} checked={allowAi} onCheckedChange={setAllowAi} />
            </FlexRow>
        </Box>
    )
}

/*
 * Organization size row
 */
const OrganizationSizeRow = ({ organization, isEditingAllowed }) => {
    const hasOranizationSizeSet = Boolean(organization.organizationSize)
    const organizationSizeOptions = useOrganizationSizeDropdownOptions()
    const defaultDropdownOptions = !hasOranizationSizeSet
        ? [{ id: 'Please select one...', name: 'Please select one...' }].concat(organizationSizeOptions)
        : organizationSizeOptions

    const [dropdownOptions, setDropdownOptions] = useState(defaultDropdownOptions)
    const [organizationSize, _setOrganizationSize] = useState(
        hasOranizationSizeSet ? organization.organizationSize : dropdownOptions[0].id
    )
    const { runCommand } = useCommandHistory()
    const setOrganizationSize = value => {
        // remove the "Please select one..." option from the dropdown
        if (!organization.organizationSize) setDropdownOptions(organizationSizeOptions)
        _setOrganizationSize(value)
        runCommand(OrganizationChangedCommand.Outbound(organization.id, { organizationSize: value }))
    }

    const css = {
        height: '40px',
        backgroundColor: '$neutral10',
        marginRight: 16,
        width: 160,
        marginLeft: 'auto',
    }

    return (
        <Box css={middleTableRowCss}>
            <Text css={tableColumn1Css}>Organization Size</Text>
            <Text css={{ marginLeft: '16px', color: '$neutral04' }}>
                {hasOranizationSizeSet ? organizationSize : '-'}
            </Text>
            {isEditingAllowed && (
                <Select300
                    css={css}
                    options={dropdownOptions}
                    value={organizationSize}
                    onValueChange={setOrganizationSize}
                    onBlur={setOrganizationSize}
                />
            )}
        </Box>
    )
}

const AdminViewOrganizationSettings = () => {
    const organization = useSelector(ReduxSelectors.selectedOrganization)
    const organizations = useSelector(ReduxSelectors.organizationsAsArray)
    const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)
    const { runCommand } = useCommandHistory()
    const isEditingAllowed = useSelector(ReduxSelectors.isUpdateAllowed('organization', organization))

    const header1Css = { fs: '32px', fw: '700', fg: '$neutral04', lh: '40px' }
    const tableCss = { mt: '40px', border: '1px solid $neutral07', br: '6px', bg: '$neutral09' }

    const handleUploadNewLogo = async fileURI => {
        runCommand(OrganizationChangedCommand.Outbound(organization.id, { logo: fileURI }))
    }

    const handleUploadNewIcon = async fileURI => {
        runCommand(OrganizationChangedCommand.Outbound(organization.id, { icon: fileURI }))
    }

    const handleRemoveIcon = () => {
        runCommand(OrganizationChangedCommand.Outbound(organization.id, { icon: undefined }))
    }

    const handleRemoveLogo = () => {
        runCommand(OrganizationChangedCommand.Outbound(organization.id, { logo: undefined }))
    }

    Segment.sendPage('organization settings', '', {})

    return (
        <Flex css={{ w: '100vw', h: '100vh', bg: '$neutral10' }}>
            <AdminOrganizationsPanel
                relativePath="settings"
                organizations={organizations}
                selectedOrganizationId={selectedOrganization?.id}
            />
            <AdminNavigationPanel selectedPanel={AdminNavigationType.SETTINGS} />
            <Box css={{ flexGrow: 3 }}>
                <Flex direction="column" css={{ m: '64px 42px auto 42px' }}>
                    {/* Organization Settings */}
                    <Text css={header1Css}>Organization Settings</Text>
                    <Box css={tableCss}>
                        <OrganizationName organization={organization} />
                        <OrganizationIconRow
                            organization={organization}
                            onUploadNewIcon={handleUploadNewIcon}
                            onRemoveIcon={handleRemoveIcon}
                            isEditingAllowed={isEditingAllowed}
                        />
                        <OrganizationLogo
                            organization={organization}
                            onUploadNewLogo={handleUploadNewLogo}
                            onRemoveLogo={handleRemoveLogo}
                            isEditingAllowed={isEditingAllowed}
                        />
                        <OrganizationAllowAi organization={organization} isEditingAllowed={isEditingAllowed} />
                        {organization && (
                            <OrganizationSizeRow organization={organization} isEditingAllowed={isEditingAllowed} />
                        )}
                    </Box>
                    <FlexRow css={{ color: '$neutral05', gap: 4, mt: 16, fs: 16, fw: 500 }}>
                        If you would like to change your Organization Name or delete your Organization, please email
                        <Link href="mailto:support@range.io">support@range.io</Link>
                    </FlexRow>
                </Flex>
            </Box>
        </Flex>
    )
}

export default AdminViewOrganizationSettings
