import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged } from '@range.io/functional'
import PropTypes from 'prop-types'

// prettier-ignore
const GeometryShape = tagged('GeometryShape', {
    id            : StringTypes.Id,
    parentId      : StringTypes.Id,
    
    annotationType: /arrow|line|marker|photoMarker|polygon|polyline|rectangle|text/,
    archivedDate  : 'Object?',// Date object
    coordinates   : 'Object',  // [lng, lat]|[[lng, lat]]|[[[lng, lat]]]
    elevation     : 'Number?',
    isArchived    : 'Boolean',
})

// prettier-ignore
const GeometryShapePropTypes = {
    id            : PropTypes.string.isRequired,
    parentId      : PropTypes.string.isRequired,
    
    annotationType: PropTypes.string.isRequired,
    archivedDate  : PropTypes.object,
    coordinates   : PropTypes.array.isRequired,
    elevation     : PropTypes.number,
    isArchived    : PropTypes.bool.isRequired,
}

GeometryShape.fromGeometry = shapeLookupTable => geometry => {
    const shape = GeometryShape.from({
        id: geometry.id,
        parentId: geometry.canvasId,

        annotationType: geometry.annotationType,
        archivedDate: geometry.archivedDate,
        coordinates: geometry.coordinates,
        elevation: geometry.elevation,
        isArchived: !!geometry.archivedDate,
    })

    shape.parent = () => shapeLookupTable.canvases.get(geometry.canvasId)
    return shape
}

export { GeometryShape, GeometryShapePropTypes }
