import React, { useState } from 'react'
import { FlexColumn, FlexRow, Icon, Image, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import TagsPill from './TagsPill.js'

const LocationRowText = styled(Text, {
    fs: '15px',
    fw: 500,
    display: 'inline-block',
    minWidth: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    'span:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
})

const StyledSnapshotColumn = styled(FlexColumn, {
    width: '300px',
    height: 'auto',
    minHeight: '240px',
    position: 'absolute',
    border: '1px solid $neutral07',
    boxShadow: '0px 6px 10px #00000020',
    borderRadius: '6px',
    mt: '32px',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: '$neutral08',
    zIndex: 999,
    overflow: 'hidden',
})

const LocationRow = ({ locationLabel }) => {
    return (
        <FlexRow
            css={{
                ai: 'center',
                gap: '8px',
                p: '8px 8px 10px 8px',
                width: '100%',
            }}
        >
            <Icon name="levels" iconSize="16" />
            <LocationRowText>{locationLabel}</LocationRowText>
        </FlexRow>
    )
}

const CollaborationRow = ({ collaborationName, locationLabel, locationSnapshotUrl, mapPinType, onClick }) => {
    const [showLocation, setShowLocation] = useState(false)
    const getRowText = () => {
        if (collaborationName.length === 0) {
            if (mapPinType === 'task') return 'Untitled task pin'
            if (mapPinType === 'photo') return 'Untitled photo pin'
            return 'Untitled canvas pin'
        } else return collaborationName
    }
    return (
        <FlexColumn css={{ position: 'relative', flex: 1, minWidth: 0 }}>
            <LocationRowText onClick={onClick}>
                <span onMouseOver={() => setShowLocation(true)} onMouseOut={() => setShowLocation(false)}>
                    {getRowText()}
                </span>
            </LocationRowText>
            {showLocation && (
                <StyledSnapshotColumn>
                    <Image src={locationSnapshotUrl} />
                    <LocationRow locationLabel={locationLabel} />
                </StyledSnapshotColumn>
            )}
        </FlexColumn>
    )
}

const MediaViewItemFooterContainer = styled(FlexRow, {
    height: '32px',
    color: '$neutral04',
    width: '100%',
    justifyContent: 'left',
    alignItems: 'center',
    gap: '6px',
})

const MediaViewItemFooter = ({
    collaborationName,
    collaborationTagNames,
    isImage,
    locationLabel,
    locationSnapshotUrl,
    mapPinType,
    onLocationClick,
    tagNames,
    identifierComponent,
}) => {
    const tagNamesGrouped = [
        mapPinType === 'task'
            ? {
                  name: 'Task Pin Tags',
                  tagNames: collaborationTagNames,
              }
            : undefined,
        mapPinType === 'photo'
            ? {
                  name: 'Photo Pin Tags',
                  tagNames: collaborationTagNames,
              }
            : undefined,
        { name: isImage ? 'Photo Tags' : 'Document Tags', tagNames },
    ]
    return (
        <MediaViewItemFooterContainer>
            <Icon iconSize="20px" css={{ color: '$neutral05' }} name="pointAddress" />
            <CollaborationRow
                collaborationName={collaborationName}
                locationLabel={locationLabel}
                locationSnapshotUrl={locationSnapshotUrl}
                onClick={onLocationClick}
                mapPinType={mapPinType}
            />
            {identifierComponent}
            <TagsPill.Grouped tagNameGroups={tagNamesGrouped} />
        </MediaViewItemFooterContainer>
    )
}

export default MediaViewItemFooter
