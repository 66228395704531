/*
 * The entire page for the "Project Details" button
 */
import { Project } from '@range.io/basic-types'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FlexRow, Icon, TextAreaExternallyChangeable, TextInput } from '../../components-reusable/index.js'
import { useCommandHistory } from '../../firebase/commands/UndoRedo.js'
import { ProjectChangedCommand } from '../../firebase/commands/index.js'
import { styled } from '../../range-theme/index.js'
import { ReduxSelectors } from '../../redux/index.js'
import SinglePlaceMap from '../SinglePlaceMap.js'
import {
    Label,
    PanelActions,
    Select300,
    StyledLeftPanelBox,
    StyledLeftPanelTopBox,
    StyledLeftPanelTopBoxContent,
} from './SharedComponents.js'

// ---------------------------------------------------------------------------------------------------------------------
// LeftPanel
// ---------------------------------------------------------------------------------------------------------------------/*

const StyledTextDescription = styled(TextAreaExternallyChangeable, {
    lineHeight: '24px',
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: 0,

    '&:focus, &:hover, &:active': {
        border: 'none',
    },
})
const StyledTextName = styled(TextInput, {
    height: '40px',
})

/*
 * When you're CREATING a new project you probably want to CREATE canvases for your project
 */
const LeftPanel = ({
    address,
    description,
    name,
    projectType,

    onClose,
    setDescription,
    setName,
    setProjectType,
}) => {
    const projectDescriptionCss = {
        alignItems: 'top',
        gap: '4px',
        padding: '10px',
        minHeight: '88px',
        borderRadius: '6px',
        backgroundColor: '$neutral09',
        border: '1px solid $neutral07',
        transitionDuration: '0.2s',

        '&:focus, &:hover, &:active': {
            border: '1px solid $primary03',
            background: '$primary01',
        },
    }
    return (
        <StyledLeftPanelBox>
            <StyledLeftPanelTopBox>
                <StyledLeftPanelTopBoxContent>
                    <Label variant="title" css={{ marginBottom: 16 }}>
                        Project Details
                    </Label>
                    <FlexRow css={{ alignItems: 'center' }}>
                        <Label variant="address" css={{ color: address ? '$neutral04' : '$red04', gap: 6 }}>
                            <Icon name="pointAddress" css={{ minWidth: '15px', height: '18px', color: '$neutral05' }} />
                            {address}
                        </Label>
                    </FlexRow>

                    <Label variant="primary">Project Type</Label>
                    <FlexRow css={{ gap: '4px', height: '40px' }}>
                        <Select300 options={Project.projectTypes} value={projectType} onValueChange={setProjectType} />
                    </FlexRow>

                    <Label variant="primary">Project Name</Label>
                    <FlexRow css={{ gap: '4px' }}>
                        <StyledTextName placeholder="Project name" initialValue={name} onBlur={setName} />
                    </FlexRow>

                    <Label variant="primary">Project Description (Optional)</Label>
                    <FlexRow css={projectDescriptionCss}>
                        <StyledTextDescription
                            placeholder={'Give your project a description...'}
                            externalValue={description}
                            onBlur={setDescription}
                            maxLineCount={8}
                        />
                    </FlexRow>
                </StyledLeftPanelTopBoxContent>
            </StyledLeftPanelTopBox>
            <PanelActions primaryText="Save & Close" onPrimaryAction={onClose} />
        </StyledLeftPanelBox>
    )
}

LeftPanel.propTypes = {
    address: PropTypes.string.isRequired,
    description: PropTypes.string,
    name: PropTypes.string,
    projectType: PropTypes.string.isRequired,

    onClose: PropTypes.func.isRequired,
    setDescription: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setProjectType: PropTypes.func.isRequired,
}

// ---------------------------------------------------------------------------------------------------------------------
// EditProjectPage (UI, not attached to Redux)
// ---------------------------------------------------------------------------------------------------------------------
const EditProjectPage = props => {
    const { center, ...lhsProps } = props
    const { name } = lhsProps

    return (
        <FlexRow css={{ backgroundColor: '$neutral09' }}>
            <LeftPanel {...lhsProps} />
            <SinglePlaceMap
                css={{ width: '100vw', height: '100vh' }}
                mapCenter={center}
                mapZoom={center ? 14 : 1} // if there's a center point zoom in; otherwise entire earth
                markerPoint={center}
                markerTitle={name}
            />
        </FlexRow>
    )
}

EditProjectPage.propTypes = {
    address: PropTypes.string.isRequired,
    center: PropTypes.arrayOf(PropTypes.number),
    description: PropTypes.string,
    name: PropTypes.string,
    projectType: PropTypes.string.isRequired,

    onClose: PropTypes.func.isRequired,
    setDescription: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setProjectType: PropTypes.func.isRequired,
}

// ---------------------------------------------------------------------------------------------------------------------
// EditProjectPage.Live (integrated with Redux)
// ---------------------------------------------------------------------------------------------------------------------
const Live = () => {
    const setProjectChange = field => value =>
        runCommand(ProjectChangedCommand.Outbound(project.id, { [field]: value }))

    const { runCommand } = useCommandHistory()
    const navigate = useNavigate()
    const { workspaceId, projectId } = useParams()
    const project = useSelector(ReduxSelectors.selectedProject)

    const handleClose = () => {
        if (window.history.state?.usr?.safeToNavigateBack) navigate(-1)
        else navigate(`/${workspaceId}/${projectId}`)
    }

    if (!project) return null

    const editProjectProps = {
        address: project.address,
        description: project.description,
        name: project.name,
        projectType: project.projectType || Project.projectTypes[0].id,
        center: project.center,

        setDescription: setProjectChange('description'),
        setName: setProjectChange('name'),
        setProjectType: setProjectChange('projectType'),
    }

    return <EditProjectPage onClose={handleClose} {...editProjectProps} />
}

EditProjectPage.LeftPanel = LeftPanel
EditProjectPage.Live = Live

export default EditProjectPage
