import { NavigatorFilterSettings, TaskListFilterSettings } from '@range.io/basic-types'
import { arrayToLookupTable, pluck, without } from '@range.io/functional'
import LookupTable from '@range.io/functional/src/lookup-table.js'
import PropTypes from 'prop-types'
import React from 'react'
import {
    Button,
    DropdownMenuWithTooltip,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    MultiSelect,
    MultiSelectPopover,
    ScrollArea,
    Text,
    TextInput,
    Tooltip,
} from '../components-reusable/index.js'
import PossiblySuspendedUserName from '../components-reusable/PossiblySuspendedUserName.js'
import Switch from '../components-reusable/Switch.js'
import { getTextWidth } from '../helpers.js'
import { RangeUITheme, styled } from '../range-theme/index.js'
import { CanvasShape, CanvasShapePropTypes } from '../react-shapes/canvas-shape.js'
import { ParticipantShape, ParticipantShapePropTypes } from '../react-shapes/participant-shape.js'
import { StatusShapePropTypes } from '../react-shapes/status-shape.js'
import { TagShapePropTypes } from '../react-shapes/tag-shape.js'
import Pill from './Pill.js'
import TagsDisplay from './TagsDisplay.js'
import UserRow from './UserRow.js'

const StyleCanvasSelectorButton = styled('button', {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$neutral09',
    outline: 'none',
    border: '1px solid $neutral07',
    minHeight: 40,
    transitionDuration: '0.4s',
    color: '$neutral04',

    borderRadius: 6,
    position: 'relative',
    minWidth: 40,
    cursor: 'pointer',
    boxShadow: '0px 0px 0px #5D81FF50',

    '&:hover': {
        backgroundColor: '$primary02',
        borderColor: '$primary03',
        boxShadow: '6px 6px 2px #8B8F9D20',
        color: '$neutral04',
    },

    '&[disabled]': {
        cursor: 'not-allowed',
        pointerEvents: 'none',
        opacity: 0.5,
    },

    variants: {
        variant: {
            taskListFilterButton: {
                gap: 10,
                pl: 16,
                pr: 16,
                fs: 14,
                color: '$neutral05',
            },
        },
    },
})

const StyledFilterIndicator = styled(FlexRow, {
    br: 999,
    backgroundColor: '$primary04',
    minWidth: '8px',
    minHeight: '8px',
    maxHeight: '8px',
    maxWidth: '8px',
    top: 10,
    right: 8,
    position: 'absolute',

    variants: {
        variant: {
            taskListFilterButton: { left: 12 },
        },
    },
})

const StyledStatusGroup = styled(FlexColumn, {
    w: 'auto',
    boxSizing: 'border-box',
    ml: 16,
    mr: 16,
    gap: 12,
    pl: 0,
    pr: 0,
    pt: 16,
    pb: 16,
    borderTop: '1px solid $neutral07',
})

const StyledTitleGroup = styled(FlexRow, {
    w: 320,
    h: 56,
    m: 0,
    gap: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    background: '$neutral10',
    ai: 'center',
    br: '5px 5px 0px 0px',
})

const StyledDropdownMenu = styled(FlexColumn, {
    w: 320,
})

const StyledInputClearButton = styled(Button, {
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '5px',
    margin: 'auto',
    border: 'none',
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    background: '$primary04',
    transitionDuration: '0.4s',

    '&:hover': {
        cursor: 'pointer',
        $$hoverBackgroundColor: 'var(--colors-primary06)',
        $$hoverColor: 'white',
    },
})

const StyledOptionButton = styled(Button, {
    padding: '6px 10px',
    color: '$neutral05',
    fontSize: '13px',
    border: '1px solid $neutral07',
    borderRadius: '100px',
    width: 'fit-content',
    userSelect: 'none',
    flexWrap: 'nowrap',
    alignItems: 'center',
    background: '$neutral10',
    fw: 500,
    maxHeight: 32,
    cursor: 'pointer',
    transitionDuration: '0.2s',

    '&:hover': {
        $$hoverBackgroundColor: 'var(--colors-primary03)',
    },

    variants: {
        selected: {
            true: {
                background: '$primary03',
                color: '$neutral04',
                fw: 400,
            },
        },
    },
})

const Row = ({ title, checked, setChecked, disabled = false }) => (
    <FlexRow css={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Text css={{ color: disabled ? '$neutral06' : '$neutral04', fs: 14, fw: 500 }}>{title}</Text>
        <Switch variant="filter" checked={checked} onCheckedChange={setChecked} disabled={disabled} />
    </FlexRow>
)

const OneRowGroup = ({ title, checked, setChecked, disabled = false, css, 'data-cy': dataCy }) => {
    const elementCss = { w: 'auto', m: 0, gap: 12, p: 16, borderTop: '1px solid $neutral07', ...css }
    return (
        <FlexColumn css={elementCss} data-cy={dataCy}>
            <Row title={title} checked={checked} setChecked={setChecked} disabled={disabled} />
        </FlexColumn>
    )
}

const TitleGroup = ({ showReset, onReset, filterTitle }) => (
    <StyledTitleGroup>
        <Text css={{ color: '$neutral04', fs: 16, fw: 700, mr: 'auto' }}>{filterTitle}</Text>

        {showReset && (
            <Button variant="secondary" size="md" onClick={onReset}>
                Reset
            </Button>
        )}
    </StyledTitleGroup>
)

const TagDetails = ({
    filter,
    allTagShapes,
    selectedTagShapes,
    filterSettings,
    setFilterSettings,
    untaggedOptionText,
}) => {
    const { matchUntagged } = filterSettings
    const selectedTagIds = pluck('id', selectedTagShapes)
    const allTagDatas = arrayToLookupTable('id', allTagShapes)
    const update = func => v => setFilterSettings(func(filterSettings, v))

    const handleTagFilterClick = tagIdClicked => {
        const clickedTagShape = allTagShapes.get(tagIdClicked)

        selectedTagShapes = selectedTagShapes.includes(clickedTagShape)
            ? without(clickedTagShape, selectedTagShapes)
            : [...selectedTagShapes, clickedTagShape]
        const lookupTable = LookupTable(selectedTagShapes.sort((a, b) => a.name.localeCompare(b.name)))
        update(filter.setTagShapes)(lookupTable)
    }

    return (
        <FlexRow css={{ gap: 8, flexWrap: 'wrap', width: 290 }}>
            <TagsDisplay
                variant="tagFilter"
                tags={allTagDatas}
                selectedTagIds={selectedTagIds}
                onItemSelectionChange={handleTagFilterClick}
                matchUntagged={matchUntagged}
                setMatchUntagged={update(filter.toggleMatchUntagged)}
                untaggedOptionText={untaggedOptionText}
            />
        </FlexRow>
    )
}

const StyledText = styled(Text, {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const StyledParticipantShape = styled(PossiblySuspendedUserName, {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const baseInputStyle = {
    fontFamily: RangeUITheme.fonts.default,
    fontSize: '14px',
    lineHeight: '20px',
}

const StyledCanvasInputIcon = styled(Icon, {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    bottom: 0,
    left: 10,
    margin: 'auto',
    color: '$neutral05',
    transitionDuration: '0.4s',

    '&:active': { color: '$neutral05' },
})

const StyleTextInputWrapper = styled('div', {
    position: 'relative',
    padding: 0,
    zIndex: 0,
    height: 30,
    mt: 1,
    width: 'fit-content',
    maxWidth: '100%',
    minWidth: '72px',
    gap: '8px',

    '&:hover': {
        [`& ${StyledCanvasInputIcon}`]: {
            color: 'var(--colors-neutral04)',
        },
    },

    input: {
        ...baseInputStyle,
        transition: 'all 0.4s ease-in-out, width 0s linear 0s',
        boxSizing: 'content-box',
        paddingLeft: '32px',
        borderRadius: '1000px',
        fw: 500,
        boxShadow: '0px 0px 0px #5D81FF50',
        cursor: 'pointer',

        '&::placeholder': {
            transitionDuration: '0.4s',
        },

        '&:hover': {
            border: '1px solid var(--colors-primary03)',
            boxShadow: '6px 6px 2px #8B8F9D20',
            backgroundColor: 'var(--colors-primary02)',
            color: 'var(--colors-neutral04)',

            '&::placeholder': {
                color: 'var(--colors-neutral04)',
            },
        },

        '&:active': {
            backgroundColor: 'var(--colors-primary03)',
            color: 'var(--colors-neutral04)',
            boxShadow: 'none',
        },
    },
})

const AssigneeGroup = ({ filter, disabled = false, allParticipantShapes, filterSettings, setFilterSettings, css }) => {
    // shortcut to update navigatorFilterSettings using function func
    const update = func => v => setFilterSettings(func(filterSettings, v))

    // does the phrase typed by the user match the name of this ParticipantShape?
    const nameMatchesUser = (participantShape, phrase) => ParticipantShape.matchesFullName(participantShape, phrase)

    const inputRef = React.useRef(null)

    // Render the text input field of the MultiSelect
    const renderInput = (inputWrapperRef, showList, setShowList, phraseToFilter, setPhraseToFilter) => {
        const clearInput = () => {
            setPhraseToFilter('')
            inputRef.current?.focus()
        }

        const placeholderText = 'Add'

        // TODO: Add new variant for TextInput
        return (
            <StyleTextInputWrapper ref={inputWrapperRef}>
                <StyledCanvasInputIcon iconSize="16px" name="user" />
                <TextInput
                    ref={inputRef}
                    value={phraseToFilter}
                    placeholder={placeholderText}
                    onBlur={setPhraseToFilter}
                    onChange={value => {
                        setPhraseToFilter(value)
                        if (!showList) setShowList(true) // ensure list shows up back again when user starts typing
                    }}
                    noClear
                    style={{
                        paddingRight: `${phraseToFilter.length ? '28px' : '10px'}`,
                        width: `${
                            phraseToFilter.length
                                ? getTextWidth(phraseToFilter, baseInputStyle)
                                : getTextWidth(placeholderText, baseInputStyle)
                        }`,
                    }}
                    css={{
                        '&::placeholder': {
                            color: '$neutral05',
                        },
                    }}
                />
                {!!phraseToFilter.length && (
                    <Tooltip tooltipText="Clear" side="bottom" align="center">
                        <StyledInputClearButton onClick={clearInput}>
                            <Icon name="close" css={{ size: '8px', color: 'white' }} />
                        </StyledInputClearButton>
                    </Tooltip>
                )}
            </StyleTextInputWrapper>
        )
    }

    // Render a drop-down row of the MultiSelect for the given ParticipantShape
    const renderUserItem = participantShape => {
        const UnassignedUserIcon = () => (
            <Flex
                css={{
                    br: 999,
                    b: '1px solid $neutral07',
                    mr: 8,
                    width: 26,
                    height: 26,
                    minWidth: 26,
                    minHeight: 26,
                    ai: 'center',
                    background: '$neutral08',
                }}
            >
                <Icon iconSize="18" name="unassignedUser" />
            </Flex>
        )

        if (participantShape === unassignedTasksShape)
            return (
                <FlexRow css={{ ai: 'center' }}>
                    <UnassignedUserIcon />
                    <StyledParticipantShape participantShape={participantShape} />
                </FlexRow>
            )

        return <UserRow participantShape={participantShape} />
    }

    // The user has selected a ParticipantShape as a potential assignee; render a pill for it
    const renderPill = participantShape => {
        const onDeselect = () => update(unselectAssignee)(participantShape)
        return (
            <Pill key={participantShape.id} onDeselect={onDeselect} tooltipText="Remove User">
                <PossiblySuspendedUserName
                    participantShape={participantShape}
                    ellipsed={true}
                    css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}
                />
            </Pill>
        )
    }

    // abbreviate filterSettings functions
    const { unselectAssignee, setShouldFilterByAssignee, toggleAssignee, unassignedTasksShape } = filter

    const { shouldFilterByAssignee, assignees } = filterSettings
    allParticipantShapes = [unassignedTasksShape, ...allParticipantShapes]

    return (
        <FlexColumn
            css={{ w: 288, m: 0, gap: 12, pt: 16, pb: 16, borderTop: '1px solid $neutral07', ...css }}
            data-cy="filter-assignee"
        >
            <Row
                title="Filter by Assignee"
                checked={shouldFilterByAssignee && !disabled}
                setChecked={update(setShouldFilterByAssignee)}
                disabled={disabled}
            />

            {shouldFilterByAssignee && (
                <MultiSelectPopover
                    filterItem={nameMatchesUser}
                    items={allParticipantShapes}
                    onItemClick={update(toggleAssignee)}
                    renderItem={renderUserItem}
                    renderInput={renderInput}
                    renderPill={renderPill}
                    selectedItems={assignees}
                    maxListHeight="300px"
                    maxListWidth="370px"
                    variant="assigneeFilter"
                />
            )}
        </FlexColumn>
    )
}

const FollowerGroup = ({ filter, disabled = false, allParticipantShapes, filterSettings, setFilterSettings, css }) => {
    // shortcut to update navigatorFilterSettings using function func
    const update = func => v => setFilterSettings(func(filterSettings, v))

    // does the phrase typed by the user match the name of this ParticipantShape?
    const nameMatchesUser = (participantShape, phrase) => ParticipantShape.matchesFullName(participantShape, phrase)

    const inputRef = React.useRef(null)

    // Render the text input field of the MultiSelect
    const renderInput = (inputWrapperRef, showList, setShowList, phraseToFilter, setPhraseToFilter) => {
        const clearInput = () => {
            setPhraseToFilter('')
            inputRef.current?.focus()
        }

        const placeholderText = 'Add'

        // TODO: Add new variant for TextInput
        return (
            <StyleTextInputWrapper ref={inputWrapperRef}>
                <StyledCanvasInputIcon iconSize="16px" name="user" />
                <TextInput
                    ref={inputRef}
                    value={phraseToFilter}
                    placeholder={placeholderText}
                    onBlur={setPhraseToFilter}
                    onChange={value => {
                        setPhraseToFilter(value)
                        if (!showList) setShowList(true) // ensure list shows up back again when user starts typing
                    }}
                    noClear
                    style={{
                        paddingRight: `${phraseToFilter.length ? '28px' : '10px'}`,
                        width: `${
                            phraseToFilter.length
                                ? getTextWidth(phraseToFilter, baseInputStyle)
                                : getTextWidth(placeholderText, baseInputStyle)
                        }`,
                    }}
                    css={{
                        '&::placeholder': {
                            color: '$neutral05',
                        },
                    }}
                />
                {!!phraseToFilter.length && (
                    <Tooltip tooltipText="Clear" side="bottom" align="center">
                        <StyledInputClearButton onClick={clearInput}>
                            <Icon name="close" css={{ size: '8px', color: 'white' }} />
                        </StyledInputClearButton>
                    </Tooltip>
                )}
            </StyleTextInputWrapper>
        )
    }

    // Render a drop-down row of the MultiSelect for the given ParticipantShape
    const renderUserItem = participantShape => <UserRow participantShape={participantShape} />

    // The user has selected a ParticipantShape as a potential follower; render a pill for it
    const renderPill = participantShape => {
        const onDeselect = () => update(unselectFollower)(participantShape)
        return (
            <Pill key={participantShape.id} onDeselect={onDeselect} tooltipText="Remove User">
                <PossiblySuspendedUserName
                    participantShape={participantShape}
                    ellipsed={true}
                    css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}
                />
            </Pill>
        )
    }

    // abbreviate filterSettings functions
    const { unselectFollower, setShouldFilterByFollower, toggleFollower } = filter

    const { shouldFilterByFollower, followers } = filterSettings

    return (
        <FlexColumn
            css={{ w: 288, m: 0, gap: 12, pt: 16, pb: 16, borderTop: '1px solid $neutral07', ...css }}
            data-cy="filter-follower"
        >
            <Row
                title="Filter by Follower"
                checked={shouldFilterByFollower && !disabled}
                setChecked={update(setShouldFilterByFollower)}
                disabled={disabled}
            />

            {shouldFilterByFollower && (
                <MultiSelectPopover
                    filterItem={nameMatchesUser}
                    items={allParticipantShapes}
                    onItemClick={update(toggleFollower)}
                    renderItem={renderUserItem}
                    renderInput={renderInput}
                    renderPill={renderPill}
                    selectedItems={followers}
                    maxListHeight="300px"
                    maxListWidth="370px"
                    variant="assigneeFilter"
                />
            )}
        </FlexColumn>
    )
}

const IdentifierGroup = ({ filter, allIdentifiers, projectIdentifier, filterSettings, setFilterSettings, css }) => {
    // shortcut to update navigatorFilterSettings using function func
    const update = func => v => setFilterSettings(func(filterSettings, v))

    // does the phrase typed by the user matches identifier
    const textMatchesIdentifier = (identifier, phrase) => {
        const fullIdentifier = `${projectIdentifier}-${identifier}`
        return fullIdentifier.toLowerCase().includes(phrase)
    }

    const inputRef = React.useRef(null)

    // Render the text input field of the MultiSelect
    const renderInput = (inputWrapperRef, showList, setShowList, phraseToFilter, setPhraseToFilter) => {
        const clearInput = () => {
            setPhraseToFilter('')
            inputRef.current?.focus()
        }

        const placeholderText = 'Add'

        // TODO: Add new variant for TextInput
        return (
            <StyleTextInputWrapper ref={inputWrapperRef}>
                <StyledCanvasInputIcon iconSize="16px" name="tags" />
                <TextInput
                    ref={inputRef}
                    value={phraseToFilter}
                    placeholder={placeholderText}
                    onBlur={setPhraseToFilter}
                    onChange={value => {
                        setPhraseToFilter(value)
                        if (!showList) setShowList(true) // ensure list shows up back again when user starts typing
                    }}
                    noClear
                    style={{
                        paddingRight: `${phraseToFilter.length ? '28px' : '10px'}`,
                        width: `${
                            phraseToFilter.length
                                ? getTextWidth(phraseToFilter, baseInputStyle)
                                : getTextWidth(placeholderText, baseInputStyle)
                        }`,
                    }}
                    css={{
                        '&::placeholder': {
                            color: '$neutral05',
                        },
                    }}
                />
                {!!phraseToFilter.length && (
                    <Tooltip tooltipText="Clear" side="bottom" align="center">
                        <StyledInputClearButton onClick={clearInput}>
                            <Icon name="close" css={{ size: '8px', color: 'white' }} />
                        </StyledInputClearButton>
                    </Tooltip>
                )}
            </StyleTextInputWrapper>
        )
    }

    // Render a drop-down row of the MultiSelect for the given ParticipantShape
    const renderItem = identifier => {
        return (
            <Text>
                {projectIdentifier}-{identifier}
            </Text>
        )
    }

    const renderPill = identifier => {
        const onDeselect = () => update(unselectIdentifier)(identifier)
        return (
            <Pill key={identifier} onDeselect={onDeselect} tooltipText="Remove ID">
                <Text ellipsed={true} css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}>
                    {projectIdentifier}-{identifier}
                </Text>
            </Pill>
        )
    }

    // abbreviate filterSettings functions
    const { unselectIdentifier, setShouldFilterByIdentifiers, toggleIdentifier } = filter

    const { shouldFilterByIdentifiers, selectedIdentifiers = [] } = filterSettings

    return (
        <FlexColumn
            css={{ w: 'auto', m: 0, gap: 12, p: 16, borderTop: '1px solid $neutral07', ...css }}
            data-cy="filter-identifier"
        >
            <Row
                title="Filter by ID"
                checked={shouldFilterByIdentifiers}
                setChecked={update(setShouldFilterByIdentifiers)}
            />

            {shouldFilterByIdentifiers && (
                <MultiSelectPopover
                    filterItem={textMatchesIdentifier}
                    items={allIdentifiers}
                    onItemClick={update(toggleIdentifier)}
                    renderItem={renderItem}
                    renderInput={renderInput}
                    renderPill={renderPill}
                    selectedItems={selectedIdentifiers}
                    maxListHeight="300px"
                    variant="identifiers"
                />
            )}
        </FlexColumn>
    )
}

const CanvasGroup = ({ filter, disabled = false, allCanvasShapes, filterSettings, setFilterSettings, css }) => {
    // shortcut to update taskListFilterSettings using function func
    const update = func => v => setFilterSettings(func(filterSettings, v))

    // does the phrase typed by the user match the name of this CanvasShape?
    const phraseMatchesCanvas = (canvasShape, phrase) => CanvasShape.matchesFullName(canvasShape, phrase)

    const inputRef = React.useRef(null)

    // Render the text input field of the MultiSelect
    const renderInput = (inputWrapperRef, showList, setShowList, phraseToFilter, setPhraseToFilter) => {
        const clearInput = () => {
            setPhraseToFilter('')
            setShowList(false)
            inputRef.current?.focus()
        }

        const placeholderText = 'Add Canvas'

        return (
            <StyleTextInputWrapper
                ref={inputWrapperRef}
                onClick={() => {
                    setShowList(prev => !prev)
                }}
                data-cy="filter-by-canvas"
            >
                <StyledCanvasInputIcon iconSize="16px" name="levels" />
                <TextInput
                    ref={inputRef}
                    value={phraseToFilter}
                    placeholder={placeholderText}
                    onBlur={setPhraseToFilter}
                    onChange={value => {
                        setPhraseToFilter(value)
                        if (!showList) setShowList(true) // ensure list shows up back again when user starts typing
                    }}
                    noClear
                    style={{
                        paddingRight: `${phraseToFilter.length ? '28px' : '10px'}`,
                        width: `${
                            phraseToFilter.length
                                ? getTextWidth(phraseToFilter, baseInputStyle)
                                : getTextWidth(placeholderText, baseInputStyle)
                        }`,
                    }}
                    css={{
                        '&::placeholder': {
                            color: '$neutral05',
                        },
                    }}
                />
                {!!phraseToFilter.length && (
                    <Tooltip tooltipText="Clear" side="bottom" align="center">
                        <StyledInputClearButton onClick={clearInput}>
                            <Icon name="close" css={{ size: '8px', color: 'white' }} />
                        </StyledInputClearButton>
                    </Tooltip>
                )}
            </StyleTextInputWrapper>
        )
    }

    // Render a drop-down row of the MultiSelect for the given CanvasShape
    const renderCanvasItem = canvasShape => {
        return (
            <>
                <FlexRow css={{ ai: 'center' }}>
                    <StyledText>{canvasShape.name}</StyledText>
                </FlexRow>
            </>
        )
    }

    // Render a pill for selected canvas shape
    const renderPill = canvasShape => {
        const onDeselect = () => update(unselectCanvas)(canvasShape)
        return (
            <Pill key={canvasShape.id} onDeselect={onDeselect} tooltipText="Remove Canvas">
                <Text ellipsed={true} css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}>
                    {canvasShape.name}
                </Text>
            </Pill>
        )
    }

    // abbreviate filterSettings functions
    const { unselectCanvas, setShouldFilterByCanvas, toggleCanvas } = filter

    const { shouldFilterByCanvas, selectedCanvasShapes } = filterSettings

    return (
        <FlexColumn css={{ w: 288, m: 0, gap: 12, pt: 16, pb: 16, borderTop: '1px solid $neutral07', ...css }}>
            <Row
                title="Filter by Canvas"
                checked={shouldFilterByCanvas && !disabled}
                setChecked={update(setShouldFilterByCanvas)}
                disabled={disabled}
            />
            {shouldFilterByCanvas && (
                <MultiSelect
                    filterItem={phraseMatchesCanvas}
                    items={allCanvasShapes}
                    onItemClick={update(toggleCanvas)}
                    placeholder="Add"
                    renderItem={renderCanvasItem}
                    renderInput={renderInput}
                    renderPill={renderPill}
                    selectedItems={selectedCanvasShapes}
                    maxListHeight="426px"
                    variant="assigneeFilter"
                />
            )}
        </FlexColumn>
    )
}

const TagGroup = ({
    filter,
    allTagShapes,
    selectedTagShapes,
    filterSettings,
    setFilterSettings,
    untaggedOptionText,
}) => {
    const update = func => v => setFilterSettings(func(filterSettings, v))
    const { shouldFilterByTags } = filterSettings
    return (
        <FlexColumn
            css={{ w: 320, m: 0, gap: 12, p: 16, borderTop: '1px solid $neutral07' }}
            data-cy="filter-tag-group"
        >
            <Row
                title="Filter by Tags"
                checked={shouldFilterByTags}
                setChecked={update(filter.setShouldFilterByTags)}
            />
            {shouldFilterByTags && (
                <TagDetails
                    filter={filter}
                    allTagShapes={allTagShapes}
                    selectedTagShapes={selectedTagShapes}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                    untaggedOptionText={untaggedOptionText}
                />
            )}
        </FlexColumn>
    )
}

const DateCreatedGroup = ({ filter, filterSettings, setFilterSettings }) => {
    const update = func => v => setFilterSettings(func(filterSettings, v))
    const { shouldFilterByDateCreated, dateCreatedDayRange } = filterSettings

    return (
        <FlexColumn
            css={{ w: 320, m: 0, gap: 12, p: 16, borderTop: '1px solid $neutral07' }}
            data-cy="filter-date-created"
        >
            <Row
                title="Show pins created in the..."
                checked={shouldFilterByDateCreated}
                setChecked={update(filter.setShouldFilterByDateCreated)}
            />
            {shouldFilterByDateCreated && (
                <Flex wrap="wrap" align="start" justify="start" style={{ gap: '6px' }}>
                    {filter.dateRangeOptions.map(({ name, dayValue }) => (
                        <StyledOptionButton
                            key={dayValue}
                            onClick={() => update(filter.setDateCreatedDayRange)(dayValue)}
                            selected={dateCreatedDayRange === dayValue}
                        >
                            <Text ellipsed={true} css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}>
                                {name}
                            </Text>
                        </StyledOptionButton>
                    ))}
                </Flex>
            )}
        </FlexColumn>
    )
}

const DateUpdatedGroup = ({ filter, filterSettings, setFilterSettings }) => {
    const update = func => v => setFilterSettings(func(filterSettings, v))
    const { shouldFilterByDateUpdated, dateUpdatedDayRange } = filterSettings

    return (
        <FlexColumn
            css={{ w: 320, m: 0, gap: 12, p: 16, borderTop: '1px solid $neutral07' }}
            data-cy="filter-date-updated"
        >
            <Row
                title="Show pins updated in the..."
                checked={shouldFilterByDateUpdated}
                setChecked={update(filter.setShouldFilterByDateUpdated)}
            />
            {shouldFilterByDateUpdated && (
                <Flex wrap="wrap" align="start" justify="start" style={{ gap: '6px' }}>
                    {filter.dateRangeOptions.map(({ name, dayValue }) => (
                        <StyledOptionButton
                            key={dayValue}
                            onClick={() => update(filter.setDateUpdatedDayRange)(dayValue)}
                            selected={dateUpdatedDayRange === dayValue}
                        >
                            <Text ellipsed={true} css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}>
                                {name}
                            </Text>
                        </StyledOptionButton>
                    ))}
                </Flex>
            )}
        </FlexColumn>
    )
}

const DropdownFilter = ({
    tooltipText = 'Filters',
    children,
    isSetToDefault,
    alignment = 'start',
    disabled = false,
}) => {
    const css = { p: 0, mt: 4 }
    return (
        <DropdownMenuWithTooltip.Root disabled={disabled} modal={false}>
            <DropdownMenuWithTooltip.Trigger tooltipText={tooltipText}>
                <StyleCanvasSelectorButton disabled={disabled}>
                    {!isSetToDefault && <StyledFilterIndicator />}
                    <Icon iconSize="16px" name="filter" />
                </StyleCanvasSelectorButton>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Content css={css} side="bottom" align={alignment} sideOffset={0}>
                {children}
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Root>
    )
}

const DropdownFilterTaskList = ({
    tooltipText = 'Filters',
    children,
    isSetToDefault,
    alignment = 'start',
    disabled = false,
}) => {
    const css = { p: 0, mt: 4 }
    return (
        <DropdownMenuWithTooltip.Root disabled={disabled}>
            <DropdownMenuWithTooltip.Trigger tooltipText={tooltipText}>
                <StyleCanvasSelectorButton
                    variant="taskListFilterButton"
                    disabled={disabled}
                    data-cy="task-list-filter-button"
                >
                    {!isSetToDefault && <StyledFilterIndicator variant="taskListFilterButton" />}

                    <Icon iconSize="16px" css={{ minWidth: 16 }} name="filter" />
                    <Text>Filters</Text>
                </StyleCanvasSelectorButton>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Content css={css} side="bottom" align={alignment} sideOffset={0}>
                {children}
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Root>
    )
}

// each status can be toggled on/off
const statusRow = ({ statusShape, disabled = false, showStatuses, filter, filterSettings, setFilterSettings }) => {
    const setChecked = b => setFilterSettings(filter.setStatus(filterSettings, statusShape, b))

    const checked = showStatuses.includes(statusShape) && !disabled
    const props = { title: statusShape.name, checked, disabled, setChecked }
    return <Row key={statusShape.id} {...props} />
}

/*
 * Navigator dropdown menu allowing the user to specify filters
 * @sig NavigatorFilterDropdownMenu :: {Boolean, {k:v}, {k:v}, {k:v}, NavigatorFilterSettings, Update} -> ReactElement
 *  Update = NavigatorFilterSettings -> ()
 */
const NavigatorFilterDropdownMenu = ({
    isSetToDefault,
    allIdentifiers,
    projectIdentifier,
    allStatusShapes,
    allTagShapes,
    allParticipantShapes,
    navigatorFilterSettings,
    setNavigatorFilterSettings,
}) => {
    // abbreviate a call to NavigatorFilterSettings[func]
    const update = func => args => setNavigatorFilterSettings(func(navigatorFilterSettings, args))

    const onReset = () =>
        setNavigatorFilterSettings(NavigatorFilterSettings.defaultFilterSettings(allStatusShapes, allParticipantShapes))

    const { setShowPhotoPins, setShowTaskPins, setShowOverdueTasksOnly, setShowOnlyMyPins, setShowArchivedPins } =
        NavigatorFilterSettings
    const { showPhotoPins, showTaskPins, showOverdueTasksOnly, showOnlyMyPins, showArchivedPins, showStatuses } =
        navigatorFilterSettings
    const { tags: selectedTagShapes } = navigatorFilterSettings
    return (
        <StyledDropdownMenu>
            <TitleGroup showReset={!isSetToDefault} onReset={onReset} filterTitle="Canvas Filters" />
            <ScrollArea maxHeight={'calc(100vh - 200px)'}>
                <DateCreatedGroup
                    filter={NavigatorFilterSettings}
                    filterSettings={navigatorFilterSettings}
                    setFilterSettings={setNavigatorFilterSettings}
                />
                <DateUpdatedGroup
                    filter={NavigatorFilterSettings}
                    filterSettings={navigatorFilterSettings}
                    setFilterSettings={setNavigatorFilterSettings}
                />
                <OneRowGroup title="Show Photo Pins" checked={showPhotoPins} setChecked={update(setShowPhotoPins)} />
                <OneRowGroup title="Show Task Pins" checked={showTaskPins} setChecked={update(setShowTaskPins)} />
                <StyledStatusGroup>
                    {allStatusShapes.map(statusShape =>
                        statusRow({
                            statusShape,
                            disabled: !showTaskPins,
                            showStatuses,
                            filter: NavigatorFilterSettings,
                            filterSettings: navigatorFilterSettings,
                            setFilterSettings: setNavigatorFilterSettings,
                        })
                    )}
                </StyledStatusGroup>
                <OneRowGroup
                    disabled={!showTaskPins}
                    title="Only show overdue Tasks"
                    checked={showOverdueTasksOnly && showTaskPins}
                    setChecked={update(setShowOverdueTasksOnly)}
                    css={{ ml: 16, mr: 16, pl: 0, pr: 0 }}
                />
                <AssigneeGroup
                    filter={NavigatorFilterSettings}
                    allParticipantShapes={allParticipantShapes}
                    filterSettings={navigatorFilterSettings}
                    setFilterSettings={setNavigatorFilterSettings}
                    disabled={!showTaskPins}
                    css={{ ml: 16, mr: 16 }}
                />
                <FollowerGroup
                    filter={NavigatorFilterSettings}
                    allParticipantShapes={allParticipantShapes}
                    filterSettings={navigatorFilterSettings}
                    setFilterSettings={setNavigatorFilterSettings}
                    disabled={!showTaskPins}
                    css={{ ml: 16, mr: 16 }}
                />
                <TagGroup
                    filter={NavigatorFilterSettings}
                    allTagShapes={allTagShapes}
                    selectedTagShapes={selectedTagShapes}
                    filterSettings={navigatorFilterSettings}
                    setFilterSettings={setNavigatorFilterSettings}
                    untaggedOptionText="untagged pins"
                />
                <IdentifierGroup
                    filter={NavigatorFilterSettings}
                    allIdentifiers={allIdentifiers}
                    projectIdentifier={projectIdentifier}
                    filterSettings={navigatorFilterSettings}
                    setFilterSettings={setNavigatorFilterSettings}
                />
                <OneRowGroup
                    title="Only show Pins I've created"
                    checked={showOnlyMyPins}
                    setChecked={update(setShowOnlyMyPins)}
                />
                <OneRowGroup
                    title="Show Archived Pins"
                    checked={showArchivedPins}
                    setChecked={update(setShowArchivedPins)}
                />
            </ScrollArea>
        </StyledDropdownMenu>
    )
}

/*
 * NavigatorFilterSelector select from about a dozen different filters
 * @sig NavigatorFilterSelector = ({ LookupTable, LookupTable, NavigatorFilterSettings, F) -> React component
 *  F = NavigatorFilterSettings -> ()
 */
const NavigatorFilterSelector = ({
    allIdentifiers,
    allStatusShapes,
    allTagShapes,
    allParticipantShapes,
    projectIdentifier,
    navigatorFilterSettings,
    setNavigatorFilterSettings,
}) => {
    const isSetToDefault = NavigatorFilterSettings.isSetToDefaults(
        navigatorFilterSettings,
        allStatusShapes,
        allParticipantShapes
    )

    return (
        <DropdownFilter tooltipText="Canvas Filters" isSetToDefault={isSetToDefault}>
            <NavigatorFilterDropdownMenu
                isSetToDefault={isSetToDefault}
                allIdentifiers={allIdentifiers}
                projectIdentifier={projectIdentifier}
                allStatusShapes={allStatusShapes}
                allTagShapes={allTagShapes}
                allParticipantShapes={allParticipantShapes}
                navigatorFilterSettings={navigatorFilterSettings}
                setNavigatorFilterSettings={setNavigatorFilterSettings}
            />
        </DropdownFilter>
    )
}

const navigatorFilterSettingsPropTypes = {
    showPhotoPins: PropTypes.bool.isRequired,
    showTaskPins: PropTypes.bool.isRequired,
    showOnlyMyPins: PropTypes.bool.isRequired,
    showArchivedPins: PropTypes.bool.isRequired,

    showStatuses: PropTypes.arrayOf(PropTypes.shape(StatusShapePropTypes)),
    showAssignees: PropTypes.arrayOf(PropTypes.shape(ParticipantShapePropTypes)),
    showTags: PropTypes.arrayOf(PropTypes.shape(TagShapePropTypes)),
}

NavigatorFilterSelector.propTypes = {
    allStatusShapes: PropTypes.arrayOf(PropTypes.shape(StatusShapePropTypes)).isRequired,
    allTagShapes: PropTypes.arrayOf(PropTypes.shape(TagShapePropTypes)).isRequired,
    allParticipantShapes: PropTypes.arrayOf(PropTypes.shape(ParticipantShapePropTypes)).isRequired,
    navigatorFilterSettings: PropTypes.shape(navigatorFilterSettingsPropTypes).isRequired,
    setNavigatorFilterSettings: PropTypes.func.isRequired,
}

/*
 * Task list dropdown menu allowing the user to specify filters
 * @sig TaskListFilterDropdownMenu :: {Boolean, {k:v}, {k:v}, {k:v}, TaskListFilterSettings, Update} -> ReactElement
 *  Update = TaskListFilterSettings -> ()
 */
const TaskListFilterDropdownMenu = ({
    isSetToDefault,
    allStatusShapes,
    allTagShapes,
    allParticipantShapes,
    allCanvasShapes,
    allIdentifiers,
    projectIdentifier,
    filterSettings,
    setFilterSettings,
}) => {
    // abbreviate a call to FilterSettings[func]
    const update = func => args => setFilterSettings(func(filterSettings, args))

    const onReset = () =>
        setFilterSettings(TaskListFilterSettings.defaultFilterSettings(allStatusShapes, allParticipantShapes))

    const {
        showOnlyCreatedByMe,
        showOnlyOverdueTasks,
        showArchivedTasks,
        tags: selectedTagShapes,
        showStatuses,
        selectedCanvasShapes,
    } = filterSettings
    const { setShowOnlyCreatedByMe, setShowOnlyOverdueTasks, setShowArchivedTasks } = TaskListFilterSettings

    return (
        <StyledDropdownMenu data-cy="task-list-filters-dropdown">
            <TitleGroup showReset={!isSetToDefault} onReset={onReset} filterTitle="Task List Filters" />
            <ScrollArea maxHeight={'calc(100vh - 220px)'}>
                <DateCreatedGroup
                    filter={TaskListFilterSettings}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                />
                <DateUpdatedGroup
                    filter={TaskListFilterSettings}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                />
                <StyledStatusGroup
                    style={{ marginLeft: 0, marginRight: 0, padding: '16px', width: '100%' }}
                    data-cy="filter-by-status"
                >
                    <Text css={{ marginBottom: '4px' }}>Filter by Status</Text>
                    {allStatusShapes.map(statusShape =>
                        statusRow({
                            statusShape,
                            showStatuses,
                            filter: TaskListFilterSettings,
                            filterSettings,
                            setFilterSettings,
                        })
                    )}
                </StyledStatusGroup>
                <AssigneeGroup
                    filter={TaskListFilterSettings}
                    allParticipantShapes={allParticipantShapes}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                    css={{ p: '16px', w: '100%' }}
                />
                <FollowerGroup
                    filter={TaskListFilterSettings}
                    allParticipantShapes={allParticipantShapes}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                    css={{ p: '16px', w: '100%' }}
                />
                <CanvasGroup
                    filter={TaskListFilterSettings}
                    allCanvasShapes={allCanvasShapes}
                    selectedCanvasShapes={selectedCanvasShapes}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                    css={{ p: '16px', w: '100%' }}
                />
                <TagGroup
                    filter={TaskListFilterSettings}
                    allTagShapes={allTagShapes}
                    selectedTagShapes={selectedTagShapes}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                    untaggedOptionText="untagged tasks"
                />
                <IdentifierGroup
                    filter={TaskListFilterSettings}
                    allIdentifiers={allIdentifiers}
                    projectIdentifier={projectIdentifier}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                />
                <OneRowGroup
                    title="Only show Tasks I've created"
                    checked={showOnlyCreatedByMe}
                    setChecked={update(setShowOnlyCreatedByMe)}
                    data-cy="filter-show-created-by-me"
                />
                <OneRowGroup
                    title="Only show overdue Tasks"
                    checked={showOnlyOverdueTasks}
                    setChecked={update(setShowOnlyOverdueTasks)}
                    data-cy="filter-show-overdue-tasks"
                />
                <OneRowGroup
                    title="Show Archived Tasks"
                    checked={showArchivedTasks}
                    setChecked={update(setShowArchivedTasks)}
                    data-cy="filter-show-archived"
                />
            </ScrollArea>
        </StyledDropdownMenu>
    )
}

/*
 * TaskListFilterSelector select from about a dozen different filters
 * @sig TaskListFilterSelector = ({ LookupTable, LookupTable, NavigatorFilterSettings, F) -> React component
 *  F = NavigatorFilterSettings -> ()
 */
const TaskListFilterSelector = ({
    allStatusShapes,
    allTagShapes,
    allParticipantShapes,
    filterSettings,
    allCanvasShapes,
    setFilterSettings,
    isDataLoaded,
    allIdentifiers,
    projectIdentifier,
}) => {
    const isSetToDefault =
        !isDataLoaded || TaskListFilterSettings.isSetToDefaults(filterSettings, allStatusShapes, allParticipantShapes)

    return (
        <DropdownFilterTaskList
            tooltipText="Task List Filters"
            isSetToDefault={isSetToDefault}
            disabled={!isDataLoaded}
            alignment="end"
            data-cy="task-list-filter-button"
        >
            <TaskListFilterDropdownMenu
                isSetToDefault={isSetToDefault}
                allStatusShapes={allStatusShapes}
                allTagShapes={allTagShapes}
                allParticipantShapes={allParticipantShapes}
                allCanvasShapes={allCanvasShapes}
                allIdentifiers={allIdentifiers}
                projectIdentifier={projectIdentifier}
                filterSettings={filterSettings}
                setFilterSettings={setFilterSettings}
            />
        </DropdownFilterTaskList>
    )
}

const taskListFilterSettingsPropTypes = {
    shouldFilterByCanvas: PropTypes.bool.isRequired,
    showOnlyOverdueTasks: PropTypes.bool.isRequired,
    showOnlyCreatedByMe: PropTypes.bool.isRequired,
    showArchivedTasks: PropTypes.bool.isRequired,
    shouldFilterByTags: PropTypes.bool.isRequired,
    matchUntagged: PropTypes.bool.isRequired,
    shouldFilterByAssignee: PropTypes.bool.isRequired,
    matchUnassigned: PropTypes.bool.isRequired,

    showStatuses: PropTypes.arrayOf(PropTypes.shape(StatusShapePropTypes)),
    allUsers: PropTypes.arrayOf(PropTypes.shape(ParticipantShapePropTypes)),
    tags: PropTypes.arrayOf(PropTypes.shape(TagShapePropTypes)),
    selectedCanvasShapes: PropTypes.arrayOf(PropTypes.shape(CanvasShapePropTypes)),
    assignees: PropTypes.arrayOf(PropTypes.shape(ParticipantShapePropTypes)),
}

TaskListFilterSelector.propTypes = {
    allStatusShapes: PropTypes.arrayOf(PropTypes.shape(StatusShapePropTypes)).isRequired,
    allTagShapes: PropTypes.arrayOf(PropTypes.shape(TagShapePropTypes)).isRequired,
    allParticipantShapes: PropTypes.arrayOf(PropTypes.shape(ParticipantShapePropTypes)).isRequired,
    allCanvasShapes: PropTypes.arrayOf(PropTypes.shape(CanvasShapePropTypes)).isRequired,
    filterSettings: PropTypes.shape(taskListFilterSettingsPropTypes).isRequired,
    setFilterSettings: PropTypes.func.isRequired,
}

export { NavigatorFilterSelector, TaskListFilterSelector, DropdownFilter }
