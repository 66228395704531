/*
 * Feature
 *
 * A Feature is Range metadata associated with a Geometry; the home of Collaborations and data *about* the Geometry
 */
import { mergeRight, tagged } from '@range.io/functional'
import { v4 } from 'uuid'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Feature = tagged('Feature', {
    id             : StringTypes.Id,
    geometryId     : StringTypes.Id,
    name           : 'String',
    description    : 'String?',
    
    collaborationId: StringTypes.OptionalId,
})

// TODO: remove Feature renames
Feature.prototype.renameFieldButLoudly('geometry', 'geometryId')

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
Feature.fromFirebase = o => Feature.from({
    id              : o.id,
    geometryId      : o.geometry,
    collaborationId : o.collaborationId,
    name            : o.name,
    description     : o.description,
})

// prettier-ignore
Feature.toFirebase = feature => ({
    id             : feature.id,
    geometry       : feature.geometryId,
    name           : feature.name,
    description    : feature.description,
})

// ---------------------------------------------------------------------------------------------------------------------
// Syntactic sugar for creating a specific kind of Collaboration object
// ---------------------------------------------------------------------------------------------------------------------

let id = 1
Feature.fromMapDrawFeature = mapDrawFeature => Feature(v4(), mapDrawFeature.id, 'Feature ' + id++, '')

/*
 * Create a new Feature merging an old one with values from changes
 * @sig update :: (Feature, { name: String?, description: String? }) -> Feature
 */
Feature.update = (feature, changes) => Feature.from(mergeRight(feature, changes))

export default Feature
