import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Calendar from 'react-calendar'
import { styled } from '../range-theme/index.js'
import { useKeyMaps } from './hooks/index.js'

const Wrapper = styled('div', {
    '.react-calendar': {
        width: '230px',
        height: 'auto',
        boxSizing: 'border-box',
        border: '1px solid $neutral07',
        borderRadius: '6px',
        backgroundColor: '$neutral10',
        userSelect: 'none',
        mt: '4px',
        boxShadow: '0px 6px 10px #00000010',

        button: {
            background: 'none',
            outline: 'none',
            border: 0,
            color: '$neutral04',

            '&.react-calendar__tile': {
                textAlign: 'center',
                lineHeight: '18px',
                fontSize: '12px',
                fontWeight: 500,
                padding: '5px',
                margin: '4px 0 4px 0',
                transitionDuration: '0.4s',

                '&:not(.react-calendar__tile--hover):hover': {
                    backgroundColor: '$primary02',
                    borderRadius: '4px',
                },

                '&--now': {
                    background: '$neutral10',
                    border: '1px solid $neutral07',
                    borderRadius: '100px',
                    padding: '4px',
                },

                '&--range': {
                    backgroundColor: '$primary04',
                    borderRadius: 0,
                    border: 'none',
                    color: '#FFFFFF',
                },

                '&--hover': {
                    backgroundColor: '$primary04',
                    borderRadius: 0,
                    border: 'none',
                    color: '#FFFFFF',
                },

                '&--rangeStart': {
                    borderRadius: '4px 0 0 4px',
                },
                '&--rangeEnd': {
                    borderRadius: '0 4px 4px 0',
                },
                '&--rangeBothEnds': {
                    borderRadius: '4px',
                },
                '&--hoverStart': {
                    borderRadius: '4px 0 0 4px',
                },
                '&--hoverEnd': {
                    borderRadius: '0 4px 4px 0',
                },
                '&--hoverBothEnds': {
                    borderRadius: '4px',
                },
            },
        },

        '.react-calendar__navigation': {
            display: 'flex',
            height: '24px',
            margin: '12px',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '21px',

            '.react-calendar__navigation__arrow': {
                width: '24px',
                maxWidth: '24px',
                height: '24px',
                color: '$neutral05',
                backgroundColor: '$neutral09',
                border: '1px solid $neutral07',
                borderRadius: '4px',
                cursor: 'pointer',
                transitionDuration: '0.4s',

                '&:hover': {
                    backgroundColor: '$neutral07',
                },
            },

            '.react-calendar__navigation__label__labelText:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
            },
        },

        '.react-calendar__month-view__weekdays': {
            color: '$neutral05',
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '18px',
            padding: '0 12px 8px 12px',
            borderBottom: '1px solid $neutral07',
            textAlign: 'center',

            abbr: {
                textDecoration: 'none',
            },
        },

        '.react-calendar__month-view__days': {
            backgroundColor: '$neutral09',
            padding: '4px 12px',
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
        },

        '.react-calendar__month-view__days__day--neighboringMonth': {
            color: '$neutral06',
        },

        '.react-calendar__year-view': {
            borderTop: '1px solid $neutral07',
            backgroundColor: '$neutral09',
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
            padding: '2px 4px 2px 4px',

            '.react-calendar__tile': {
                padding: '10px 4px',
            },
        },
    },
})

const SHORT_WEEKDAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

const myFormatShortWeekday = (locale, date) => SHORT_WEEKDAYS[new Date(date).getDay()]

const DatePicker = ({ selectRange = false, onChange, value }) => {
    // handle
    useEffect(() => {
        pushKeyMap('DatePicker', { Escape: () => onChange(value) })
        return () => popKeyMap('DatePicker')
    }, [])

    const { pushKeyMap, popKeyMap } = useKeyMaps()

    // Handles any click event on the element wrapping the Calendar and stops event from bubbling up
    // It's a bit dirty but constraints those clicks to within the component so they don't trigger
    // unneeded actions in their parents, like e.g. in the ListView on the due date pill
    const handleWrapperClicked = event => event.stopPropagation()

    return (
        <Wrapper onClick={handleWrapperClicked} data-cy="date-picker">
            <Calendar
                onChange={onChange}
                selectRange={selectRange}
                prev2Label={null}
                next2Label={null}
                minDetail="year"
                formatShortWeekday={myFormatShortWeekday}
                value={value}
            />
        </Wrapper>
    )
}

DatePicker.propTypes = {
    selectRange: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default DatePicker
