/*
 * A scrollable list of items that allows highlighting single items with mouse but also to navigate through the list with arrow keys.
 */

import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import React, { Children, cloneElement, useEffect, useState } from 'react'
import { styled } from '../range-theme/index.js'

const StyledListViewport = styled(ScrollAreaPrimitive.Viewport, {
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    zIndex: 100,
})

const StyledListScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
    width: '4px',
    display: 'flex',
    padding: 2,
    background: 'none',
    // ensures no selection
    userSelect: 'none',
    // disable browser handling of all panning and zooming gestures on touch devices
    touchAction: 'none',
    transition: 'background 160ms ease-out',
})

const StyledListScrollbarThumb = styled(ScrollAreaPrimitive.Thumb, {
    flex: 1,
    background: '#99999950',
    borderRadius: '4px',
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        minWidth: 44,
        minHeight: 44,
    },
})

/*
 * clearSelectionHash - special prop used to inform the list to clear it's highlighting state
 * onNoItemSelectedSubmit - callback when the user pressed Enter but no item was selected, and it has only one child
 * (used to allow creating new tags when it's the only option)
 */
const TagList = ({ children, onNoItemSelectedSubmit }) => {
    const [highlightedIdx, setHighlightedIdx] = useState(null)
    const arrayChildren = Children.toArray(children)

    const scrollItemIntoView = elementIdx => {
        const element = document.getElementById(`tag-list-item-${elementIdx}`)
        if (element) element.scrollIntoView({ block: 'nearest' })
    }

    const handleKeyDown = event => {
        if (event.key === 'ArrowDown') {
            const newHighlightedIdx = highlightedIdx === null ? 0 : (highlightedIdx + 1) % arrayChildren.length
            setHighlightedIdx(newHighlightedIdx)
            scrollItemIntoView(newHighlightedIdx)
            event.preventDefault()
        }
        if (event.key === 'ArrowUp') {
            const newHighlightedIdx =
                highlightedIdx === null
                    ? arrayChildren.length - 1
                    : (highlightedIdx - 1 + arrayChildren.length) % arrayChildren.length
            setHighlightedIdx(newHighlightedIdx)
            scrollItemIntoView(newHighlightedIdx)
            event.preventDefault()
        }
        if (event.key === 'Enter') {
            if (highlightedIdx !== null) {
                const element = document.getElementById(`tag-list-item-${highlightedIdx}`)
                element.click()
            } else if (arrayChildren.length === 1) onNoItemSelectedSubmit()
        }
    }

    useEffect(() => {
        const handler = event => handleKeyDown(event)
        window.addEventListener('keydown', handler)
        return () => window.removeEventListener('keydown', handler)
    })

    return (
        <StyledListViewport>
            {Children.map(arrayChildren, (child, idx) =>
                cloneElement(child, {
                    highlighted: idx === highlightedIdx ? true : undefined,
                    id: `tag-list-item-${idx}`,
                    'data-cy': 'tag-list-item',
                    onMouseEnter: () => setHighlightedIdx(idx),
                    onMouseLeave: () => setHighlightedIdx(null),
                })
            )}
            <StyledListScrollbar orientation="vertical">
                <StyledListScrollbarThumb />
            </StyledListScrollbar>
        </StyledListViewport>
    )
}

export default TagList
