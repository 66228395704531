/*
 * Style a span using stitches
 */
import { mergeRight } from '@range.io/functional'
import React from 'react'
import { styled } from '../range-theme/index.js'

const ErrorMessages = styled('span', {
    fontFamily: '$default',
    lineHeight: '1.5',
    margin: '0',
    fontWeight: 500,
    fontVariantNumeric: 'tabular-nums',
    display: 'block',
    fontSize: '12px',
    width: '100%',
    fontStyle: 'italic',
    color: '$red03',

    variants: {
        ellipsed: {
            true: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        },
    },
})

const ErrorMessage = props => {
    let { limitLinesOfTextTo, css = {}, ...otherProps } = props

    css = limitLinesOfTextTo
        ? mergeRight(css, {
              overflow: 'hidden',
              display: '-webkit-box',
              '-webkit-box-orient': 'vertical',
              '-webkit-line-clamp': limitLinesOfTextTo,
          })
        : css

    return <ErrorMessages css={css} {...otherProps} />
}

export default ErrorMessage
