/*
 * Display the Google/Email+Password/Email+MagicLink modal dialog
 *
 * Include SignUpDumb (for Storybook) and SignUp
 */

import React, { useState } from 'react'

import PasswordStrengthBar from 'react-password-strength-bar'
import { useNavigate } from 'react-router-dom'
import {
    Banner,
    Button,
    Flex,
    FlexColumn,
    FlexRow,
    LoadingDotsAnimation,
    Logo,
    LogoText,
    Text,
} from '../components-reusable/index.js'
import { attemptSignUpWithEmailAndPassword, attemptSignUpWithGoogle } from '../firebase/authentication-helpers.js'
import { styled } from '../range-theme/index.js'
import {
    BGLogo,
    ButtonLayout,
    InputFlex,
    InputLabel,
    LinkTextCTA,
    SecondaryLabel,
    StyledFooter,
    StyledFooterText,
    StyledForm,
    StyledLogo,
    StyledPage,
    StyledTextInput,
    ScrollableContainer,
} from './RegistrationSharedStyles.js'

const StyledFooterLink = styled('a', {
    fontWeight: '500',
    color: '$primary04',
    cursor: 'pointer',
    ml: 4,
    mr: 4,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Breakpoints
// ---------------------------------------------------------------------------------------------------------------------

const breakpoints = {
    small: '493px',
    medium: '810px',
}

/*
 * Sign Up Form (Email/Password, not firstName, role)
 */
const SignUpForm = ({ isLoading, errors, onSubmit, onSignUpWithGoogle, gotoLogin }) => {
    const EMAIL = 'email'
    const PASSWORD = 'password'

    const _onSubmitEmailAndPassword = e => {
        e.preventDefault()

        // values autofilled by the browser itself will appear as target values even if the nominal email/password
        // are still set to their initial useState('') values
        // In order to identify which element is the email and which the password, they're each marked with a data-use
        const formElements = Array.from(e.target)
        const email = formElements.find(t => t.getAttribute('data-use') === EMAIL)
        const password = formElements.find(t => t.getAttribute('data-use') === PASSWORD)

        onSubmit(email.value, password.value)
    }

    // const _onSignUpWithGoogle = async e => {
    //     e.preventDefault()
    //     return onSignUpWithGoogle()
    // }

    const onChangeScore = (score, message) => {
        setPasswordScore(score)
        const s = [message?.warning, message?.suggestions].flat().join(' ')
        setPasswordMessage(s)
    }

    const [password, setPassword] = useState('')
    const [passwordScore, setPasswordScore] = useState(0)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [passwordMessage, setPasswordMessage] = useState('')

    const togglePasswordVisibility = () => setIsPasswordVisible(!isPasswordVisible)

    const privacyPolicyLink = 'https://rangehq.notion.site/Range-Privacy-Policy-7171e619c2ae42489a19fff06fbe47c6'
    const termsOfServiceLink = 'https://rangehq.notion.site/Range-Terms-of-Service-27c0ae45b3b145a883b9cbc72231b32f'
    return (
        <StyledForm onSubmit={_onSubmitEmailAndPassword}>
            <ScrollableContainer
                css={{
                    maxHeight: '90vh',
                }}
            >
                <FlexColumn
                    css={{
                        gap: 24,

                        // Media queries for responsive design
                        [`@media (max-width: ${breakpoints.medium})`]: {
                            width: '100%', // Adjust width for medium screens
                            padding: '0px', // Adjust padding for small screens
                        },
                        [`@media (max-width: ${breakpoints.small})`]: {
                            width: '100%', // Adjust width for small screens
                            padding: '0px', // Adjust padding for small screens
                        },
                    }}
                >
                    <StyledLogo>
                        <Logo />
                        <LogoText />
                    </StyledLogo>
                    {errors && (
                        <Flex css={{ width: '100%' }}>
                            <Banner toastLabel={errors} severity="error"></Banner>
                        </Flex>
                    )}
                    {/* <ContinueWithGoogle onClick={_onSignUpWithGoogle} /> */}
                    <SecondaryLabel>Sign up by entering your email and choosing a password</SecondaryLabel>
                    <InputFlex>
                        <InputLabel>Email</InputLabel>

                        <StyledTextInput data-use={EMAIL} data-cy="signup-email" placeholder="email@work.com" />
                    </InputFlex>
                    <InputFlex>
                        <InputLabel>Choose Password</InputLabel>
                        <FlexRow css={{ position: 'relative' }}>
                            <StyledTextInput
                                data-use={PASSWORD}
                                data-cy="signup-password"
                                type={isPasswordVisible ? 'text' : 'password'}
                                placeholder="Enter a password"
                                onChange={setPassword}
                                noClear
                            />
                            <LinkTextCTA css={{ position: 'absolute', right: 10 }} onClick={togglePasswordVisibility}>
                                {isPasswordVisible ? 'hide' : 'show'}
                            </LinkTextCTA>
                        </FlexRow>
                        <PasswordStrengthBar password={password} onChangeScore={onChangeScore} />

                        <Text
                            css={{
                                fontSize: 13,
                                color: '$neutral05',
                            }}
                        >
                            {passwordMessage}
                        </Text>
                    </InputFlex>
                    <ButtonLayout>
                        <StyledFooter css={{ gap: 0 }}>
                            <StyledFooterText>By continuing, you agree to our</StyledFooterText>
                            <StyledFooterLink href={termsOfServiceLink} target="tos">
                                Terms of Service
                            </StyledFooterLink>
                            <StyledFooterText>and</StyledFooterText>
                            <StyledFooterLink href={privacyPolicyLink} target="privacy">
                                Privacy Policy
                            </StyledFooterLink>
                        </StyledFooter>
                        <Button
                            data-cy="signup-submit"
                            variant="primary"
                            disabled={passwordScore < 3}
                            type="submit"
                            size="lg"
                            css={{ w: '100%' }}
                        >
                            {isLoading ? <LoadingDotsAnimation variant="button" /> : <Text>Sign Up</Text>}
                        </Button>
                        <LinkTextCTA onClick={gotoLogin}>Already have an account? Log in</LinkTextCTA>
                    </ButtonLayout>
                </FlexColumn>
            </ScrollableContainer>
        </StyledForm>
    )
}

const SignUpDumb = ({ isLoading, errors, onSubmit, onSignUpWithGoogle, gotoLogin }) => {
    return (
        <StyledPage>
            <BGLogo>
                <img src="/rangebackgroundicon.svg" icon="Range icon" />
            </BGLogo>

            <SignUpForm
                isLoading={isLoading}
                errors={errors}
                onSubmit={onSubmit}
                onSignUpWithGoogle={onSignUpWithGoogle}
                gotoLogin={gotoLogin}
            />
        </StyledPage>
    )
}

const SignUp = () => {
    const gotoLogin = () => navigate('/login')
    const onSubmit = async (email, password) => {
        try {
            setIsLoading(true)
            await attemptSignUpWithEmailAndPassword(email, password)
            setIsLoading(false)
            navigate('/verifyEmail')
        } catch (e) {
            setIsLoading(false)
            setErrors(e.message)
        }
    }

    const onSignUpWithGoogle = async () => {
        try {
            await attemptSignUpWithGoogle()
            navigate('/')
        } catch (e) {
            setErrors(e.message)
        }
    }

    const [errors, setErrors] = useState()
    const [isLoading, setIsLoading] = useState()
    const navigate = useNavigate()

    return (
        <SignUpDumb
            isLoading={isLoading}
            errors={errors}
            onSubmit={onSubmit}
            onSignUpWithGoogle={onSignUpWithGoogle}
            gotoLogin={gotoLogin}
        />
    )
}

export { SignUp, SignUpDumb }
