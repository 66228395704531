/*
 * Main page for editing canvas sources of an existing project.
 * Uses the canvas source edit component and displays an overlay when save action is ongoing.
 */

import LookupTable from '@range.io/functional/src/lookup-table'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useBooleanState } from '../components-reusable/hooks'
import { selectionChanged } from '../firebase/commands-2/index.js'
import { ProjectCanvasesUpdatedCommand } from '../firebase/commands/project-canvases-updated-command'
import { useCommandHistory } from '../firebase/commands/UndoRedo'
import { ReduxSelectors } from '../redux'
import PDFCanvasSourceEdit from './PDFCanvasSourceEdit'
import PDFCanvasSourceEditLoading from './PDFCanvasSourceEditLoading'
import PDFCanvasSourceEditPanel from './PDFCanvasSourceEditPanel'

const ProjectCanvasSources = () => {
    const { canvases: allCanvasShapes, canvasSources: allCanvasSourceShapes } = useSelector(ReduxSelectors.allShapes)
    const selectedProject = useSelector(ReduxSelectors.selectedProject)
    const navigate = useNavigate()
    const { runCommand } = useCommandHistory()
    const { get: isLoading, set: showLoading, unset: hideLoading } = useBooleanState(false)

    const pdfCanvases = useMemo(() => {
        const pdfTyped = allCanvasShapes.filter(canvas => canvas.canvasSources.every(cs => cs.type === 'pdf'))
        return LookupTable(
            pdfTyped.map(canvas => {
                const canvasSource = allCanvasSourceShapes.get(canvas.canvasSources[0].id)
                const { canvasSources, ...canvasWithoutSources } = canvas
                return { ...canvasWithoutSources, canvasSource }
            })
        )
    }, [allCanvasShapes, allCanvasSourceShapes])

    /*
     * Array of IDs of canvases to display. We only want to display PDF ones so we filter other ones.
     */
    const itemsOrder = useMemo(
        () => selectedProject?.canvasOrder.filter(canvasId => !!pdfCanvases.get(canvasId)) ?? [],
        [selectedProject, pdfCanvases]
    )

    const handleClose = () => navigate('..')

    /*
     * Use clicked save CTA. We call on the update project canvases command but first we need a loading indicator to be shown.
     */
    const handleSave = (newCanvases, newCanvasOrder) => {
        const onDone = () =>
            selectionChanged({ canvasId: newCanvasOrder[0], context: 'canvasSourcesChanged' }, { navigate })
        const onError = () => hideLoading()
        showLoading()
        runCommand(
            ProjectCanvasesUpdatedCommand.Outbound(selectedProject, newCanvasOrder, newCanvases, onDone, onError)
        )
    }

    const renderSidePanel = opts => <PDFCanvasSourceEditPanel.EditCanvases onCancel={handleClose} {...opts} />

    if (isLoading()) return <PDFCanvasSourceEditLoading.Update />

    return (
        <PDFCanvasSourceEdit
            items={pdfCanvases}
            itemUpdateLabel="Update Sheet"
            onSave={handleSave}
            order={itemsOrder}
            renderSidePanel={renderSidePanel}
        />
    )
}

export default ProjectCanvasSources
