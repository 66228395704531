import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, FlexRow } from '../components-reusable/Flex.js'
import { styled } from '../range-theme/index.js'
import { Logo, LogoText, Box } from '../components-reusable/index.js'
import Link from './Link.js'

const StyledNotFoundContainer = styled('div', {
    background: '$neutral09',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    backgroundImage: 'url(/grid_glow.svg)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
})

const StyledPrimaryText = styled('div', {
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: '60px',
    color: '$neutral04',
})

const StyledSecondaryText = styled('div', {
    fontSize: '18px',
    lineHeight: '24px',
    color: '$neutral05',
})

const StyledLogo = styled(FlexRow, {
    width: '100%',
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '32px 0 0 40px',
})

const MaintenanceMode = () => {
    const navigate = useNavigate()
    return (
        <StyledNotFoundContainer>
            <Flex justify="between" css={{ zIndex: 10 }}>
                <StyledLogo onClick={() => navigate('/')}>
                    <Logo />
                    <LogoText />
                </StyledLogo>
                <Link
                    data-cy="contact"
                    href="mailto:hello@range.io"
                    variant="button-outline"
                    css={{ minWidth: 140, height: '40px', margin: '32px 40px 0 0' }}
                >
                    Contact us
                </Link>
            </Flex>
            <Flex direction="column" css={{ maxWidth: '530px', flex: 1, marginLeft: '10%', zIndex: 2, gap: 16 }}>
                <Box>
                    <img src="/maintenance_mode.svg" />
                </Box>
                <StyledPrimaryText>We'll be back shortly...</StyledPrimaryText>
                <StyledSecondaryText>
                    We’re currently undergoing some scheduled maintenance. We apologize for the inconvenience, but we’ll
                    be back online soon.
                    <br />
                    <br />
                    If you need to contact us, please email{' '}
                    <Link
                        href="mailto:support@range.io"
                        css={{ color: '#5D81FF', fontWeight: 400, textDecoration: 'underline' }}
                    >
                        support@range.io
                    </Link>
                    .
                </StyledSecondaryText>
            </Flex>
        </StyledNotFoundContainer>
    )
}

export default MaintenanceMode
