import React from 'react'
import { Box, FlexColumn, Icon, LoadingDotsAnimation, Tooltip } from '../components-reusable/index.js'
import { MediaThumbnail } from '../components-reusable/MediaIcon.js'
import { styled } from '../range-theme/index.js'
import { VersionPill } from './VersionDropdown.js'

const SelectedIndicatorWrapper = styled(Box, {
    width: '27px',
    height: '27px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '12px',
    right: '12px',
    borderRadius: '100px',
    variants: {
        selected: {
            true: {
                color: '#FFFFFF',
                background: '$primary04',
                border: '1px solid $primary03',
                boxShadow: '0px 0px 4px #00000050',
            },
            false: {
                display: 'none',
                border: 'none',
                background: '#00000040',
            },
        },
    },
})

const SelectedIndicator = ({ selected = false, onClick }) => {
    const handleClick = e => {
        e.stopPropagation()
        onClick()
    }
    return (
        <Tooltip tooltipText={selected ? 'Unselect photo' : 'Select photo'} side="bottom" align="center">
            <SelectedIndicatorWrapper selected={selected} onClick={handleClick}>
                {selected && <Icon name="tickSmall" iconSize="12px" />}
            </SelectedIndicatorWrapper>
        </Tooltip>
    )
}

const ImageOverlay = styled('div', {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    br: '6px',
})

const ImageContainer = styled(FlexColumn, {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    background: '$snapshotBackground',
    border: '1px solid $neutral07',
    br: '6px',
    width: '100%',
    margin: '10px 0 12px 0',
    boxSizing: 'border-box',
    transitionDuration: '0.4s',
    aspectRatio: '4/3',
    overflow: 'hidden',

    '&:hover': {
        border: '1px solid $primary04',
        cursor: 'pointer',
        [`& ${SelectedIndicatorWrapper}`]: {
            border: '2px solid white',
            boxShadow: '0px 0px 4px #00000040',
            display: 'flex',
        },
    },

    variants: {
        selected: {
            true: {
                [`& ${ImageOverlay}`]: {
                    background: '$primary03',
                    opacity: 0.2,
                },
            },
            false: {
                '&:hover': {
                    background: '#000000',
                    [`& ${ImageOverlay}`]: {
                        background: '#00000050',
                    },
                },
            },
            undefined: {
                '&:hover': {
                    background: '#000000',
                    [`& ${ImageOverlay}`]: {
                        background: '#00000050',
                    },
                },
            },
        },
    },

    img: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        br: '5px',
    },
})

const StyledNameAndDescription = styled(FlexColumn, {
    bottom: 0,
    position: 'absolute',
    width: '100%',
    gap: 4,
    bg: '#00000090',
    backdropFilter: 'blur(6px) saturate(190%) contrast(50%) brightness(130%)',
    padding: 10,
})

const StyledDescription = styled(Box, {
    color: '$white',
    width: '100%',
    fs: '14px',
    lineHeight: '1.4',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
})

const StyledName = styled(Box, {
    color: '$white',
    width: '100%',
    fs: '14px',
    fw: 700,
    lineHeight: '1.4',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
})

const StyledComments = styled(Box, {
    color: '$secondary01',
    fs: '12px',
    fontWeight: 700,
    overflow: 'hidden',
})

const MediaViewItemContent = ({
    description,
    imageUrl,
    isSelected,
    onClick,
    onSelectClick,
    fileType,
    fileSize,
    url,
    willHaveThumbnail,
    name,
    version,
    commentsCount,
}) => {
    const renderContent = () => {
        if (willHaveThumbnail) return imageUrl ? <img src={imageUrl} /> : <LoadingDotsAnimation />
        else return <MediaThumbnail fileType={fileType} fileSize={fileSize} url={url} description={description} />
    }
    const shouldShowBottomOverlay = name || description || !!commentsCount

    return (
        <ImageContainer onClick={onClick} selected={isSelected}>
            {renderContent()}
            <ImageOverlay />
            <div style={{ position: 'absolute', top: '12px', left: '12px', margin: 'auto' }}>
                <VersionPill version={version} />
            </div>
            <SelectedIndicator onClick={onSelectClick} selected={isSelected} />
            {shouldShowBottomOverlay && (
                <StyledNameAndDescription>
                    {name && <StyledName>{name}</StyledName>}
                    {description && <StyledDescription>{description}</StyledDescription>}
                    {!!commentsCount && (
                        <StyledComments>
                            {commentsCount > 1 ? `${commentsCount} comments` : `${commentsCount} comment`}
                        </StyledComments>
                    )}
                </StyledNameAndDescription>
            )}
        </ImageContainer>
    )
}

export default MediaViewItemContent
