/*
 * Presence
 *
 * A Presence records the last time and place we saw this user move within the current project
 */
import { mergeRight, pick, renameKeys, tagged } from '@range.io/functional'
import { millisToTimestamp, timestampToMillis } from '../helper/timestamp.js'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Presence = tagged('Presence', {
    id                    : StringTypes.Id, // must be an existing user's id
    canvasId              : StringTypes.Id,
    lastPresencePlace     : '[Number]', // [lng, lat]
    lastPresenceTimestamp : 'Number', // Date.now()
})

// TODO: remove renames
Presence.prototype.renameFieldButLoudly('canvas', 'canvasId')

/*
 * Create a new Presence merging an old one with values from changes
 * @sig update :: (Presence, {k:v}) -> Presence
 */
Presence.update = (presence, changes) => Presence.from(mergeRight(presence, changes))

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------
/*
 * Create a Presence from a JSON object
 * @sig fromFirebase :: {k:v} -> Presence
 */
Presence.fromFirebase = o =>
    Presence.from(
        renameKeys(
            {
                canvas: 'canvasId',
                lastPresenceTimestamp: ['lastPresenceTimestamp', () => timestampToMillis(o.lastPresenceTimestamp)],
            },
            o
        )
    )

Presence.toFirebase = presence =>
    pick(
        ['id', 'canvas', 'lastPresencePlace', 'lastPresenceTimestamp'],
        renameKeys(
            {
                canvasId: 'canvas',
                lastPresenceTimestamp: [
                    'lastPresenceTimestamp',
                    () => millisToTimestamp(presence.lastPresenceTimestamp),
                ],
            },
            presence
        )
    )

/*
 * Update the user's presence no more often than...
 */
Presence.updatePresenceThrottleTime = 500 // ms

/*
 * Consider the user no longer present after...
 */
Presence.userIsNoLongerPresentAfter = 60_000 // ms

export default Presence
