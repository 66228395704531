import { createStitches } from '@stitches/react'

const { styled, css, theme, createTheme, getCssText, globalCss, keyframes, config, reset } = createStitches({
    theme: {
        colors: {
            // Primary (Blue)
            blue01: '#5D81FF20', // 20% alpha
            blue02: '#5D81FF30', // 30% alpha
            blue03: '#5D81FF70', // 70% alpha
            blue04: '#5D81FF', // 00% alpha
            blue05: '#BFCDFF', // Double check where this value is being used
            blue06: '#3E60D7',
            blue07: '#23346F',

            // Secondary (Purple)
            purple01: '#9492FF',
            purple02: '#6F6DFC',
            purple03: '#4947D8',
            purple04: '#34328B',

            // Red (20%, 60% & 100% alpha)
            red01: '#F13D1530',
            red02: '#F13D1560',
            red03: '#F13D15',
            red04: '#C82400',
            red05: '#452A24',

            // Green (20%, 60% & 100% alpha)
            green01: '#009E5C20',
            green02: '#009E5C60',
            green03: '#009E5C',
            green04: '#007D49',
            green05: '#223B31',

            // Orange (20%, 60% & 100% alpha)
            orange01: '#FF7A0020',
            orange02: '#FF7A0060',
            orange03: '#FF7A00',
            orange04: '#D96800',
            orange05: '#493522',

            // Pink (20%, 60% & 100% alpha)
            pink01: '#F531B320',
            pink02: '#F531B360',
            pink03: '#F531B3',
            pink04: '#C70585',
            pink05: '#622E50',

            // Special
            notifications: '#FC625D',
            statusOnline: '#00DA7E',
            component: '#8000FF',
            BlockedStatus: '#EF516D',
            archived: '#656B81',
            snapshotBackground: '#262737',

            // Misc
            white: 'white',
            black: 'black',

            // Semantic colors

            primary01: '$blue01',
            primary02: '$blue02',
            primary03: '$blue03',
            primary04: '$blue04',
            primary05: '$blue05',
            primary06: '$blue06',
            primary07: '$blue07',

            secondary01: '$purple01',
            secondary02: '$purple02',
            secondary03: '$purple03',
            secondary04: '$purple04',

            neutral01: '#000000',
            neutral02: '#1E1E1E',
            neutral03: '#282727',
            neutral04: '#3d404c',
            neutral05: '#6f7589',
            neutral06: '#9da3ba',
            neutral07: '#D5D9E8',
            neutral08: '#F0F2F5',
            neutral09: '#F7F8FA',
            neutral10: '#FFFFFF',

            // Neutral variants with alpha

            neutral07Alpha: '#DDDDDD70',
            neutral09Alpha: '#fafafaa3',
            neutral10Alpha: '#ffffffcc',

            neutralOverlay: '#00000030',
        },
        fonts: {
            default: "'Inter', sans-serif",
        },
        space: {},
        sizes: {},
        fontSizes: {
            xs: '11px',
            sm: '13px',
            lg: '14px',
            xxl: '18px',
            xxxxl: '32px',
        },
        radii: {
            xs: '4px',
            sm: '6px',
            round: '50%',
            pill: '9999px',
        },
        shadows: {
            sm: '0px 2px 6px   rgba(47, 54, 71, 0.4)',
            md: '0px 2px 4px   rgba( 0,  0,  0, 0.1);',
            lg: '0px 6px 10px  rgba( 0,  0,  0, 0.1)',
            xl: '0px 2px 12px  rgba( 0,  0,  0, 0.1)',
            xxl: '0px 0px 20px  rgba( 0,  0,  0, 0.1)',
            xxxl: '0px 4px 20px  rgba( 0,  0,  0, 0.1)',
        },
    },
    media: {},
    utils: {
        // padding
        p: padding => ({ padding }),
        pt: paddingTop => ({ paddingTop }),
        pr: paddingRight => ({ paddingRight }),
        pb: paddingBottom => ({ paddingBottom }),
        pl: paddingLeft => ({ paddingLeft }),
        px: value => ({ paddingLeft: value, paddingRight: value }),
        py: value => ({ paddingTop: value, paddingBottom: value }),

        // margin
        m: margin => ({ margin }),
        mt: marginTop => ({ marginTop }),
        mr: marginRight => ({ marginRight }),
        mb: marginBottom => ({ marginBottom }),
        ml: marginLeft => ({ marginLeft }),
        mx: value => ({ marginLeft: value, marginRight: value }),
        my: value => ({ marginTop: value, marginBottom: value }),

        // font
        fw: fontWeight => ({ fontWeight }),
        lh: lineHeight => ({ lineHeight }),

        // color
        fg: color => ({ color }),
        bg: backgroundColor => ({ backgroundColor }),
        fs: fontSize => ({ fontSize }),

        // border
        b: border => ({ border }),
        bc: borderColor => ({ borderColor }),
        br: borderRadius => ({ borderRadius }),
        bt: borderTop => ({ borderTop }),
        bb: borderBottom => ({ borderBottom }),
        brgt: borderRight => ({ borderRight }),

        // sizes
        w: width => ({ width }),
        h: height => ({ height }),
        size: value => ({ width: value, height: value }),

        // flex
        fd: flexDirection => ({ flexDirection }),
        ai: alignItems => ({ alignItems }),
    },
})

const darkTheme = createTheme('dark-theme', {
    colors: {
        neutral01: '#FFFFFF',
        neutral02: '#F7F8FB',
        neutral03: '#ECEEF1',
        neutral04: '#dddff0',
        neutral05: '#8d8ea4',
        neutral06: '#424D66',
        neutral07: '#30303f',
        neutral08: '#292B3D',
        neutral09: '#181921',
        neutral10: '#08080B',

        // Neutral variants with alpha

        neutral07Alpha: '#52526fcf',
        neutral09Alpha: '#181921de',
        neutral10Alpha: '#08080bc2',

        neutralOverlay: '#FFFFFF30',
    },
})

export { darkTheme, styled, css, theme, createTheme, getCssText, globalCss, keyframes, config, reset }
