import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '../range-theme/index.js'
import icons from './icons.js'

const StyledSVG = styled('svg', {
    display: 'inline-block',
    variants: {
        shape: {
            square: {
                outline: 'none',
                borderRadius: '6px',
                border: 'none',
                backgroundColor: '$neutral09',
                color: '$neutral05',
                padding: 0,

                '&:hover': {
                    backgroundColor: '$neutral07',
                    color: '$neutral04',
                },
            },
            circle: {
                borderRadius: '100%',
                border: '1px solid $neutral08',
                backgroundColor: '$neutral09',
                color: '$neutral05',
                padding: 6,
            },
        },
    },
})

const Icon = props => {
    const { name, iconSize, ...otherProps } = props
    const isError = !name || name === 'error' || !icons[name]
    const iconName = isError ? 'error' : name
    const { viewBox, svg } = icons[iconName]

    return (
        <StyledSVG viewBox={viewBox} width={iconSize} height={iconSize} {...otherProps}>
            {svg}
        </StyledSVG>
    )
}

Icon.displayName = 'Icon'
Icon.defaultProps = {}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    iconSize: PropTypes.string,
}

export default Icon
