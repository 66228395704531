import { uniq } from '@range.io/functional'
import PropTypes from 'prop-types'
import React from 'react'
import { Box, FlexRow, Icon, Text } from '../components-reusable'
import { useBooleanState } from '../components-reusable/hooks/index.js'
import { styled } from '../range-theme'

const StyledTagsPill = styled(FlexRow, {
    ai: 'center',
    height: '28px',
    width: 'inherit',
    background: '$neutral09',
    border: '1px solid $neutral07',
    cursor: 'pointer',
    br: 999,
    color: '$neutral04',
    fs: 14,
    fw: 500,
    pl: '6px',
    pr: '8px',
    gap: '5px',
    transitionDuration: '0.4s',
    zIndex: 2,

    '&:hover': { backgroundColor: '$primary02', border: '1px solid $primary03' },
})

const StyledTagListContainer = styled(Box, {
    position: 'absolute',
    right: 0,
    top: '30px',
    background: '$neutral09',
    border: '1px solid $neutral07',
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    padding: '8px 8px 10px 8px',
    mb: '4px',
    mt: '4px',
    width: 'max-content',
    zIndex: 2,

    '& div:not(:first-child)': {
        marginTop: '8px',
    },
})

const StyledTagItem = styled(FlexRow, {
    border: '1px solid $neutral07',
    background: '$primary02',
    br: '100px',
    padding: '6px 10px 6px 10px',
    fs: '13px',
    width: 'fit-content',
    color: '$neutral04',
    fw: '500',
})

/*
 * Shows a pill with tag count and a list of tags on mouse over action
 */
const SimpleTagsPill = ({ tagNames }) => {
    const { set: showTags, unset: hideTags, get: areTagsVisible } = useBooleanState()
    return (
        <Box data-cy="tags-pill" css={{ position: 'relative' }}>
            <StyledTagsPill onMouseOver={showTags} onMouseOut={hideTags}>
                <Icon iconSize="16px" name="tags" css={{ minWidth: '16px' }} />
                <Text css={{ maxWidth: 'inherit' }}>{tagNames.length}</Text>
            </StyledTagsPill>
            {areTagsVisible() && tagNames.length > 0 && (
                <StyledTagListContainer data-cy="tags-pill-list">
                    {tagNames.map((tagName, index) => (
                        <StyledTagItem key={index}>{tagName}</StyledTagItem>
                    ))}
                </StyledTagListContainer>
            )}
        </Box>
    )
}

SimpleTagsPill.propTypes = {
    tagNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const StyledGroupNameLabel = styled('div', {
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
    color: '$neutral04',
})

const StyledEmptyGroupLabel = styled('div', {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    color: '$neutral05',
    ml: 4,
})

const GroupedTagsPill = ({ tagNameGroups }) => {
    const { set: showTags, unset: hideTags, get: areTagsVisible } = useBooleanState()

    // uniq the tagNames to get the appropriate count
    const allTagNamesWithPossibleRepeats = tagNameGroups.flatMap(t => t?.tagNames).filter(t => t)
    const allTagNamesCount = uniq(allTagNamesWithPossibleRepeats).length

    const renderTagNamesGroup = tagNameGroup => {
        if (tagNameGroup.tagNames.length === 0) return <StyledEmptyGroupLabel>0 tags</StyledEmptyGroupLabel>
        return tagNameGroup.tagNames.map((tagName, index) => (
            <StyledTagItem key={`tagName_${index}`}>{tagName}</StyledTagItem>
        ))
    }
    return (
        <Box css={{ position: 'relative' }}>
            <StyledTagsPill onMouseOver={showTags} onMouseOut={hideTags}>
                <Icon iconSize="16px" name="tags" css={{ minWidth: '16px' }} />
                <Text css={{ maxWidth: 'inherit' }}>{allTagNamesCount}</Text>
            </StyledTagsPill>
            {areTagsVisible() && tagNameGroups.length > 0 && (
                <StyledTagListContainer>
                    {tagNameGroups.map(
                        (group, groupIndex) =>
                            group && (
                                <div key={`tags_${groupIndex}`}>
                                    <StyledGroupNameLabel>{group.name}</StyledGroupNameLabel>
                                    {renderTagNamesGroup(group)}
                                </div>
                            )
                    )}
                </StyledTagListContainer>
            )}
        </Box>
    )
}

GroupedTagsPill.propTypes = {
    tagNameGroups: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string.isRequired, tagNames: PropTypes.arrayOf(PropTypes.string).isRequired })
    ).isRequired,
}

const TagsPill = {
    Simple: SimpleTagsPill,
    Grouped: GroupedTagsPill,
}

export default TagsPill
