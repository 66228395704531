/*
 * Define filter settings for the Navigator panel on the main left panel
 * There are about a dozen different filter settings the user can set
 * There is also a "default" value for all the settings used to control:
 *
 * - the "reset" button
 * - the filter button
 * - the filter banner
 *
 * There are a slew of functions to update the settings of the form: (oldSettings, newSetting) => newSettings
 */

import { equals, omit, tagged } from '@range.io/functional'

const MediaViewFilterSettings = tagged('MediaViewFilterSettings', {
    dateRange: '[Object]', // Date
    canvasIds: '[String]',
    tagIds: '[String]',
    participantIds: '[String]',
    fileType: /all|photo|document/,
})

// TODO: remove Feature renames
MediaViewFilterSettings.prototype.renameFieldButLoudly('canvases', 'canvasIds')
MediaViewFilterSettings.prototype.renameFieldButLoudly('tags', 'tagIds')
MediaViewFilterSettings.prototype.renameFieldButLoudly('participants', 'participantIds')

// can't be usefully set until the project data is loaded
const defaults = () => ({
    dateRange: [],
    canvasIds: [],
    tagIds: [],
    participantIds: [],
    fileType: 'all',
})

// Abbreviation
const from = MediaViewFilterSettings.from

// defaults
const defaultFilterSettings = () => from(defaults())

/*
 * Are the current filter settings the same as the default filter settings?
 */
const isSetToDefaults = (MediaViewFilterSettings, statusShapes, participantShapes) => {
    const keysToOmit = ['fileType']

    const currentSettings = omit(keysToOmit, MediaViewFilterSettings)
    const defaultSettings = omit(keysToOmit, defaultFilterSettings(statusShapes, participantShapes))

    return equals(currentSettings, defaultSettings)
}

const setDateRangeUpdated = (nfs, b) => from({ ...nfs, dateRange: b })
const setFileType = (nfs, b) => from({ ...nfs, fileType: b })

const _removeItem = (array, itemId) => {
    if (array.length > 1) {
        array.splice(array.indexOf(itemId), 1)
        return array
    } else return []
}

const _addItem = (array, item) => array.concat(item)

/*
 * The user toggled the checkbox for the given participant.
 */
const toggleAssignee = (nfs, participantId) => {
    const participantIds = [...nfs.participantIds]
    const newParticipantIds =
        participantIds.indexOf(participantId) === -1
            ? _addItem(participantIds, participantId)
            : _removeItem(participantIds, participantId)

    return from({ ...nfs, participantIds: newParticipantIds })
}

/*
 * The user toggled the checkbox for the given tag.
 */
const toggleTag = (nfs, tagId) => {
    const tagIds = [...nfs.tagIds]
    const newTagIds = tagIds.indexOf(tagId) === -1 ? _addItem(tagIds, tagId) : _removeItem(tagIds, tagId)

    return from({ ...nfs, tagIds: newTagIds })
}

/*
 * The user toggled the checkbox for the given canvas.
 */
const toggleCanvas = (nfs, canvasId) => {
    const canvasIds = [...nfs.canvasIds]
    const newCanvasIds =
        canvasIds.indexOf(canvasId) === -1 ? _addItem(canvasIds, canvasId) : _removeItem(canvasIds, canvasId)

    return from({ ...nfs, canvasIds: newCanvasIds })
}

// ---------------------------------------------------------------------------------------------------------------------
// public API
// ---------------------------------------------------------------------------------------------------------------------

// defaults
MediaViewFilterSettings.defaultFilterSettings = defaultFilterSettings
MediaViewFilterSettings.isSetToDefaults = isSetToDefaults

MediaViewFilterSettings.setDateRangeUpdated = setDateRangeUpdated
MediaViewFilterSettings.setFileType = setFileType

// assignees
MediaViewFilterSettings.toggleAssignee = toggleAssignee

// tags
MediaViewFilterSettings.toggleTag = toggleTag

// canvases
MediaViewFilterSettings.toggleCanvas = toggleCanvas

export default MediaViewFilterSettings
