/*
 * Add Mapbox (HTML) markers to the map to indicate users' presence at a particular position.
 *
 * Each marker gets its own HTML element to track
 */

import { Participant, Presence } from '@range.io/basic-types'
import { dissoc, mapObject } from '@range.io/functional'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-dev.js'
import { useSelector } from 'react-redux'
import { RangeUITheme } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'

/* global requestAnimationFrame */

/*
 * Track all the markers; the key is a presence's (aka user's) id
 */
const markers = {}

let _nextColor = 0
const _colors = [
    '#5351E1',
    '#D7850A',
    '#00B998',
    '#964CDF',
    '#D64D48',
    '#50B867',
    '#208BC8',
    '#D73DBE',
    '#1BAFB9',
    '#75A907',
    '#CF692F',
    '#3060DC',
]

/*
 * Pick a color for backgrounds
 */
const color = () => _colors[_nextColor++ % _colors.length]

const spanStyle = () => `
    display: flex;
`
const strokeStyle = () => `
    fill: ${RangeUITheme.White};
`

const fillStyle = color => `
    fill: ${color};
`

const nameStyle = color => `
    background-color: ${color};
    border          : 1px solid white;
    color           : ${RangeUITheme.White};
    border-radius   : 80px;
    padding         : 2px 6px 1px 6px;
    vertical-align  : middle;
    font-size       : 11px;
    font-weight     : 500;
    line-height     : 16px;
    height          : 18px;
    box-shadow      : ${RangeUITheme.shadows.lg};
    white-space     : nowrap;
    margin-top      : 32px;
    margin-left     : -8px;
`

/*
 * Sheesh.
 * Each Marker gets its own HTML element which is styled here
 * margin-top is set in order to put the marker at the visually correct position, because Mapbox assumes the marker
 * is 41px tall
 */
const presenceCursor = (name, color) => `
    <span style="${spanStyle()}">
        <svg width="32" height="32" style="${fillStyle(color)}" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
            stroke="white"
                d="M14.5991 14.0106L29.5451 22.8424L22.1508 24.859L18.0119 31.7571L14.5991 14.0106Z"
        </svg>
        
        <svg width="32" height="32" style="${strokeStyle()}" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M14.5991 14.0106L29.5451 22.8424L22.1508 24.859L18.0119 31.7571L14.5991 14.0106ZM21.5 24L27 22.5L16 16L18.5 29L21.5 24Z"
        </svg>
        <span style="${nameStyle(color)}">${name}</span>
    </span>
`

/*
 * Moderately general algorithm to animate a marker from the startLngLat to the endLngLat in a fixed amount of time
 *
 */
const animateMarker = (marker, startLngLat, endLngLat, startTime) => {
    const animationTime = Presence.updatePresenceThrottleTime

    // linearly interpolate from startLngLat to endLngLat over the given animationTime
    const animate = () => {
        const now = Date.now()
        const t = (now - startTime) / animationTime

        const lng = startLngLat.lng + t * deltaLng
        const lat = startLngLat.lat + t * deltaLat

        // TEMPORARY: trying to understand what causes Sentry error:
        // https://range-hq.sentry.io/issues/3981783337/?project=6703476&query=is%3Aunresolved&referrer=issue-stream
        if (isNaN(lng) || isNaN(lat) || lat < -90 || lat > 90)
            console.error('animateMarker: invalid lng/lat', { lng, lat, startLngLat, endLngLat, startTime, now, t })

        if (t <= 1) marker.setLngLat({ lng, lat }) // presuming t > 1 causes the Sentry error
        if (t < 1) requestAnimationFrame(animate)
    }

    const deltaLng = endLngLat.lng - startLngLat.lng
    const deltaLat = endLngLat.lat - startLngLat.lat
    requestAnimationFrame(animate)
}

/*
 * Return or create a marker for the given user at the presence location
 */
const getMarker = (presence, user) => {
    if (markers[presence.id]) return markers[presence.id]

    const div = document.createElement('div')
    div.innerHTML = presenceCursor(Participant.nickname(user), color())

    const marker = new mapboxgl.Marker({ element: div, anchor: 'top-left', offset: [-16, -16] })
    marker.setLngLat(presence.lastPresencePlace)
    markers[presence.id] = marker
    return marker
}

// :: [lng, lat] -> { lng, lat }
const xyArrayToLngLat = lngLat => ({ lng: lngLat[0], lat: lngLat[1] })

/*
 * Main component responds to changes in presences by adding markers to Mapbox
 */
const MapLayerPresence = ({ mapboxMap }) => {
    const recentPresences = useSelector(ReduxSelectors.presences)
    const participants = useSelector(ReduxSelectors.selectedProjectParticipants)
    const canvas = useSelector(ReduxSelectors.selectedCanvas)

    const moveMarker = presence => {
        const participant = participants[presence.id] // presence.id === some participant.id
        const marker = getMarker(presence, participant)
        const lastPresencePlace = xyArrayToLngLat(presence.lastPresencePlace)

        animateMarker(marker, marker.getLngLat(), lastPresencePlace, Date.now())
        marker.addTo(mapboxMap) // (safe to add the same one multiple times)
    }

    if (!mapboxMap) return null

    // find JUST the recentPresences that are also on this canvas and move their markers
    const presencesOnCanvas = recentPresences.filter(p => p.canvasId === canvas?.id)
    presencesOnCanvas.map(moveMarker)

    // remove all the other markers
    const markersToRemove = presencesOnCanvas.reduce((acc, p) => dissoc(p.id, acc), markers)
    mapObject(m => m.remove(mapboxMap), markersToRemove)

    // nothing to show -- Mapbox does all the work for the markers once we add them to the map
    return null
}

export default MapLayerPresence
