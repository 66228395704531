import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { mergeRight } from '@range.io/functional'
import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '../range-theme/index.js'
import Icon from './Icon.js'
import Tooltip from './Tooltip.js'

// ---------------------------------------------------------------------------------------------------------------------
// IconButton
// ---------------------------------------------------------------------------------------------------------------------
const StyledButton = styled('button', {
    outline: 'none',
    border: 'none',
    padding: 0,
    lineHeight: '60%',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transitionDuration: '0.2s',

    variants: {
        variant: {
            // eg. attach button in CollaborationWindowTopBar: 32px neutral07 rounded-rectangle background;
            createOrg: {
                borderRadius: '6px',
                color: '$neutral05',
                backgroundColor: '$neutral09',
                width: '32px',
                height: '32px',
                br: 999,
                b: '1px solid $neutral07',
                transitionDuration: 0.4,
                '&:hover': {
                    color: '$neutral04',
                    backgroundColor: '$neutral08',
                    boxShadow: '6px 6px 2px #8B8F9D20',
                },
            },
            neutralHover32: {
                borderRadius: '6px',
                color: '$neutral05',
                background_color: '$neutral10',
                width: '32px',
                height: '32px',
                '&:hover': {
                    color: '$neutral04',
                    backgroundColor: '$primary02',
                },
            },
            sheetExpand: {
                borderRadius: '6px',
                color: '$neutral05',
                background_color: '$neutral10',
                width: '40px',
                height: '40px',
                '&:hover': {
                    color: '$neutral04',
                    backgroundColor: '$primary02',
                },
            },
            tagManager: {
                borderRadius: '6px',
                color: '$neutral05',
                background_color: '$neutral10',
                border: '1px solid transparent',
                width: '32px',
                height: '32px',
                '&:hover': {
                    color: '$neutral04',
                    backgroundColor: '$primary02',
                },
            },
            tagManagerDestructive: {
                borderRadius: '6px',
                color: '$red03',
                backgroundColor: '$neutral10',
                border: '1px solid transparent',
                width: '32px',
                height: '32px',
                '&:hover': {
                    color: '$red03',
                    backgroundColor: '$red01',
                },
            },
            toolbar: {
                border: 'none',
                outline: 'none',
                height: '40px',
                width: '40px',
                color: '$neutral01',
                cursor: 'pointer',

                '&[aria-checked=on]': {
                    color: '$neutral01',
                    backgroundColor: '$primary03',
                    '&:hover': { backgroundColor: '$primary03' },
                },

                '&:hover': { backgroundColor: '$primary02' },
            },

            banner: {
                border: 'none',
                outline: 'none',
                height: '20px',
                width: '20px',
                color: '$white',
                cursor: 'pointer',
                br: 6,

                '&[aria-checked=on]': {
                    color: '$white',
                    backgroundColor: '$primary04',
                    '&:hover': { backgroundColor: '$primary04' },
                },

                '&:hover': { backgroundColor: '$primary06' },
            },
        },
        corners: {
            none: { borderRadius: 0 },
            left: { borderRadius: '5px 0 0 5px' },
            right: { borderRadius: '0 5px 5px 0' },
            top: { borderRadius: '5px 5px 0 0' },
            bottom: { borderRadius: '0 0 5px 5px' },
            all: { borderRadius: '5px' },
        },
    },
})

/*
 * A plain Button wrapping a square icon -- but having a Tooltip
 * Note: cannot be used as the button for a dropdown menu, which adds its own idiosyncrasies
 * css will be applied the button containing the icon
 */
const IconButtonWithTooltip = ({
    type = 'button',
    iconName,
    size,
    tooltipText,
    side,
    onClick,
    variant,
    ariaChecked,
    corners,
    dataCy,
    disabled = false,
    css = {},
    tooltipCss = {},
}) => (
    <Tooltip tooltipText={tooltipText} side={side} dataCy={dataCy} css={tooltipCss}>
        <StyledButton
            type={type}
            onClick={onClick}
            variant={variant}
            corners={corners}
            aria-checked={ariaChecked}
            disabled={disabled}
            css={css}
        >
            <Icon name={iconName} iconSize={size} />
        </StyledButton>
    </Tooltip>
)

IconButtonWithTooltip.propTypes = {
    iconName: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
    side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
    onClick: PropTypes.func, // .isRequired,
    variant: PropTypes.string,
    ariaChecked: PropTypes.oneOf(['off', 'on']),
    corners: PropTypes.string,
    css: PropTypes.object,
}

// prettier-ignore
const StyledDropdownMenuTrigger = styled(DropdownMenuTrigger, {
    all             : 'unset',
    display         : 'inlineFlex',
    alignItems      : 'center',
    justifyContent  : 'center',
    outline         : 'none',
    color           : '$neutral05',
    backgroundColor: 'transparent',
    borderRadius    : '5px',
    
    '&:hover': {
        color          : '$neutral04',
        backgroundColor: '$neutral07',
    }
})

const IconButtonWithTooltipForDropdown = ({
    tooltipText,
    iconName,
    iconSize,
    size = iconSize,
    side,
    dataCy,
    css = {},
}) => {
    css = mergeRight({ display: 'inline-flex', width: size, height: size }, css)
    return (
        <Tooltip tooltipText={tooltipText} side={side} dataCy={dataCy}>
            <StyledDropdownMenuTrigger data-cy={dataCy} css={css}>
                <Icon iconSize={iconSize} name={iconName} />
            </StyledDropdownMenuTrigger>
        </Tooltip>
    )
}

export { IconButtonWithTooltipForDropdown }
export default IconButtonWithTooltip
