import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '../range-theme/index.js'
import { Box } from './Box.js'
import { Flex, FlexRow } from './Flex.js'
import { Text } from './Text.js'

const StyledBannerContent = styled(FlexRow, {
    minHeight: '32px',
    ai: 'stretch',
    justifyContent: 'center',
    pl: '12px',
    pr: '12px',
    transitionDuration: '0.4s',
    fontSize: '14px',
    gap: '8px',
    width: '100%',
    br: '0px 6px 6px 0px',

    variants: {
        severity: {
            error: {
                backgroundColor: '$red01',
                color: '$red03',
                '&::before': {
                    content: '🛑',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                },
            },
            success: {
                backgroundColor: '$green01',
                color: '$green03',
                '&::before': {
                    content: '✅',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                },
            },
            alert: {
                backgroundColor: '$orange01',
                color: '$orange03',
                '&::before': {
                    content: '👋',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                },
            },
        },
    },
})

const StyledIndent = styled(Box, {
    width: '6px',
    height: 'auto',
    br: '6px 0px 0px 6px',

    variants: {
        severity: {
            error: { backgroundColor: '$red03' },
            success: { backgroundColor: '$green03' },
            alert: { backgroundColor: '$orange03' },
        },
    },
})

const StyledBannerLabel = styled(Text, {
    width: '100%',
    height: 'auto',
    mb: 'auto',
    mt: 'auto',
    fontSize: '14px',
    fontWeight: '500',
})

/*
 * Banner component
 */

const Banner = ({ severity, toastLabel, onClose }) => {
    return (
        <Flex
            css={{
                width: 'auto',
                background: '$neutral10',
                br: 7,
                b: '1px solid $neutral07',
            }}
        >
            <StyledIndent severity={severity} />
            <StyledBannerContent severity={severity}>
                <FlexRow css={{ flex: '1', ai: 'center' }}>
                    <StyledBannerLabel>{toastLabel}</StyledBannerLabel>
                </FlexRow>
            </StyledBannerContent>
        </Flex>
    )
}

Banner.displayName = 'Banner'

Banner.propTypes = {
    toastLabel: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['error', 'success', 'alert']).isRequired,
}

export { StyledBannerLabel }
export default Banner
