import { Invitation } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import { removeInvitation } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// InvitationRemovedCommand
// ---------------------------------------------------------------------------------------------------------------------
const InvitationRemovedCommand = taggedSum('InvitationRemovedCommand', {
    Inbound: { invitation: 'Invitation' },
    Outbound: {
        invitation: 'Invitation',
        id: StringTypes.Id,
    },
})

/*
 * A InvitationChangedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Invitation)
 */
const runInboundCommand = (resources, command) => {
    const { dispatch } = resources
    const invitation = command.invitation

    dispatch(ReduxActions.invitationRemoved(invitation))
}

/*
 * The user wants to resend an invitation: an InvitationChangedCommand.Outbound; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { dispatch, displayError } = resources

    try {
        const { invitation } = command
        const { id, organizationId } = invitation

        await removeInvitation(organizationId, id)

        // show the toast
        const toastLabel = `Invitation sent to ${invitation.inviteeEmail} has been successfully cancelled`
        const toast = { id: invitation.id, severity: 'success', toastLabel, showUndo: false }
        dispatch(ReduxActions.toastAdded(toast))

        dispatch(ReduxActions.invitationRemoved(invitation))
    } catch (e) {
        displayError(e)
    }
}

const addInvitationRemovedCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        InvitationRemovedCommand,
        CommandPlayer({
            CommandType: InvitationRemovedCommand,
            Type: Invitation,
            collectionPath: organizationId => `/organizations/${organizationId}/invitations/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'removed',
            resourceKey: 'organizationId',
        })
    )
}

export { InvitationRemovedCommand, addInvitationRemovedCommandSingleton }
