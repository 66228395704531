/*
 * Link component that includes button-like styling. Opens the link in a new tab.
 */

import React from 'react'
import { styled } from '../range-theme/index.js'

const StyledLink = styled('a', {
    boxSizing: 'border-box',
    fontWeight: '500',
    color: '$primary04',
    cursor: 'pointer',
    textDecoration: 'none',

    '&:not([data-variant^="button"])': {
        '&:hover': {
            textDecoration: 'underline',
        },
    },

    '&[data-variant^="button"]': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        cursor: 'pointer',
    },

    variants: {
        variant: {
            'button-primary': {
                backgroundColor: 'var(--colors-primary04)',
                boxShadow: '0px 0px 0px #5D81FF50',
                color: '$white',
                border: 'none',
                transitionDuration: '0.4s',

                fontSize: '14px',
                borderRadius: '6px',
                maxHeight: '40px',
                height: '40px',
                paddingLeft: '16px',
                paddingRight: '16px',

                '&:hover': {
                    boxShadow: '6px 6px 2px #5D81FF40',
                    backgroundColor: 'var(--colors-primary06)',
                    color: 'white',
                },

                '&:active:enabled': {
                    backgroundColor: 'var(--colors-primary06)',
                    color: '$white',
                    boxShadow: 'none',
                },
            },
            'button-outline': {
                backgroundColor: 'var(--colors-neutral10)',
                border: '1px solid var(--colors-neutral07)',
                color: 'var(--colors-neutral05)',
                padding: '10px',
                transitionDuration: '0.4s',
                fontSize: '14px',
                borderRadius: '6px',
                fontWeight: 400,

                '&:hover:enabled': {
                    border: '1px solid var(--colors-primary03)',
                    backgroundColor: 'var(--colors-primary02)',
                    color: 'var(--colors-neutral04)',
                },

                '&:active:enabled': {
                    backgroundColor: 'var(--colors-primary03)',
                    color: 'var(--colors-neutral04)',
                },
            },
        },
    },
})

const Link = ({ href, children, css, variant, ...rest }) => (
    <StyledLink href={href} target="_blank" css={css} variant={variant} data-variant={variant} {...rest}>
        {children}
    </StyledLink>
)

export default Link
