/*
 * See Readme
 */

import { FeedItem } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import { deleteCollaboration } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// GeometriesRemovedCommand
// ---------------------------------------------------------------------------------------------------------------------
const GeometriesRemovedCommand = taggedSum('GeometriesRemovedCommand', {
    Inbound: { feedItem: 'FeedItem' },
    Outbound: { geometries: '[Geometry]', customToastLabel: 'String?' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A GeometriesRemovedCommand.Inbound has arrived from Firestore; send it to redux
 */
const runInboundCommand = async (resources, command) => {
    const { getState, dispatch, mapboxDraw } = resources
    const { feedItem } = command
    const { action, itemType, itemId: collaborationId } = feedItem

    if (itemType !== 'Collaboration' || action !== 'deleted') return // not for us

    // No reason to get the geometry from Firestore (it's already deleted anyway); just delete locally
    const state = getState()
    const collaboration = ReduxSelectors.collaborationWithId(state, collaborationId)
    const geometry = ReduxSelectors.geometryForCollaboration(state, collaboration)
    dispatch(ReduxActions.geometriesDeleted([geometry]))

    if (mapboxDraw) mapboxDraw.delete(geometry.id)
}

/*
 * A GeometriesRemovedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { projectId, dispatch, displayError, getState, mapboxDraw } = resources
    const { geometries, customToastLabel } = command

    try {
        let toastLabel = customToastLabel
        if (!toastLabel) {
            // We need to create the label before deleting a geometry, as it may need to pull the collaboration data
            toastLabel = `${geometries.length} pins have been successfully deleted`
            if (geometries.length === 1) {
                const collaboration = ReduxSelectors.firstCollaborationForGeometry(getState(), geometries[0].id)
                toastLabel = `${collaboration.name || 'Untitled Pin'} has been successfully deleted`
            }
        }

        // In Firebase, there is no deleteGeometry call; the appropriate call is deleteCollaboration...
        await Promise.all(
            geometries.map(geometry => {
                const collaboration = ReduxSelectors.firstCollaborationForGeometry(getState(), geometry.id)
                return deleteCollaboration(projectId, collaboration.id)
            })
        )

        // ...but for Redux, it's still geometriesDeleted
        dispatch(ReduxActions.geometriesDeleted(geometries))

        mapboxDraw?.clearSelection()

        dispatch(
            ReduxActions.toastAdded({
                id: window.location.href,
                toastLabel,
                severity: 'success',
                showUndo: false,
            })
        )
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        GeometriesRemovedCommand,
        CommandPlayer({
            CommandType: GeometriesRemovedCommand,
            Type: FeedItem,
            collectionPath: (projectId, userId) => `/projects/${projectId}/participants/${userId}/feedItems`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
        })
    )
}

export { GeometriesRemovedCommand, addCommand }
