import { tagged } from '@range.io/functional'
import { timestampToDate } from '../helper/timestamp.js'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const FeedItem = tagged('FeedItem', {
    id       : StringTypes.Id,
    action   : /added|updated|deleted|access-lost|access-gained|role-changed/,
    itemType : /Collaboration|Comment|Organization|Update|Upload/,
    projectId: StringTypes.Id,
    itemId   : StringTypes.Id,
    userId   : StringTypes.Id,
    createdAt: 'Object', // date
    oldValue : 'Any?', // String|Int|Id
    newValue : 'Any?' // String|Int|Id
})

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Create a FeedItem from a JSON object
 * @sig fromFirebase :: {k:v} -> FeedItem
 */
FeedItem.fromFirebase = o =>
    FeedItem.from({
        id: o.id,
        action: o.action,
        itemType: o.itemType,
        projectId: o.projectId,
        itemId: o.itemId,
        userId: o.userId,
        createdAt: timestampToDate(o.createdAt),
    })

export default FeedItem
