/* ---------------------------------------------------------------------------------------------------------------------
 * Generators
 *
 * By generating code for a Type Constructor and the related 'toString' and 'from' functions we can create the simplest
 * possible functions for these operations. The generated code must then be eval'd in the proper context
 * (see tagged and taggedSum)
 *
 * Generating Type Checks in the constructors
 * ==========================================
 *
 * To reduce the likelihood of type errors, we generate runtime checks in the constructors. For example:
 *
 *   generateTypeConstructor('Circle', { centre: 'Coord', radius: 'Number' }) =>
 *
 * Generates this function which checks the number and types of the arguments:
 *
 *   function Circle(centre, radius) {
 *       if (arguments.length !== 2)           throw new TypeError('Expected 2 arguments, found ' + arguments.length)
 *       if (centre['@@typeName'] !== 'Coord') throw new TypeError('Expected centre to be a Coord; found ' + centre)
 *       if (typeof radius !== 'number')       throw new TypeError('Expected radius to be a Number; found ' + radius)
 *
 *       const result = Object.create(prototype)
 *       result.centre = centre
 *       result.radius = radius
 *       return result
 *   }
 *
 * BEWARE: The use of "prototype" in the generated function means there must be a variable named "prototype" in
 * the context where the generated function is eval'd. tagged and taggedSum both define a "prototype."
 *
 * BEWARE: This constructor should be called *without* using the "new" keyword:
 *
 * Correct:     Coord(centre, 4)
 * Incorrect:   new Coord(centre, 4)
 *
 * The type checks are generated by the type of the field, so in this case, two different checks
 * were generated for 'Coord' and 'Number'
 *
 *       ...
 *       if (centre['@@typeName'] !== 'Coord') throw new TypeError('Expected centre to be a Coord; found ' + centre)
 *       if (typeof radius !== 'number')       throw new TypeError('Expected radius to be a Number; found ' + radius)
 *       ...
 *
 * See generateTypeCheck to see the syntax of fieldTypes
 *
 * Primitive Type Handling
 * =======================
 *
 * There is special handling for:
 *
 *     String
 *     Number
 *     Boolean
 *     Object
 *
 * Regex Handling
 * ==============
 *
 * A fieldType can be a regular expression instead of a string, in which case its type is "String"
 * with the additional constraint that its value must match the regular expression:
 *
 *   HasId = tagged('Id', { id: Id ))
 *
 * Produces the extra id.match test in the constructor:
 *
 *   function HasId(id) {
 *       if (arguments.length !== 1) throw new TypeError('Expected 1 arguments, found ' + arguments.length)
 *
 *       if (typeof id !== 'string') throw new TypeError('Expected id to be a String; found ' + id)
 *       if (!id.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i))
 *           throw new TypeError('Expected id to match /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i; found ' + id)
 *
 *       const result = Object.create(prototype)
 *       result.id = id
 *       return result
 *   }
 *
 * Array Handling
 * ==============
 *
 * A fieldType can include square brackets to indicate it is an array of a specific type -- even nested:
 *
 * Eg: tagged('NestedArray', { p: '[Number]' } ->
 * Eg: tagged('DoubleNestedArray', { p: '[[Number]]' } ->
 * Eg: tagged('TripleNestedArray', { p: '[[[Number]]]' } ->
 *
 * function toString() { return `NestedArray([${this.p.join(', ')}])` }
 * function toString() { return `DoubleNestedArray([[${this.p[0].join(', ')}]])` }
 * function toString() { return `TripleNestedArray([[[${this.p[0][0].join(', ')}]]])` }
 *
 * Conditional Operator
 * =====================
 *
 * A fieldType can include a '?' suffix indicating that it can be undefined, but if it's present it must
 * have the specified type:
 *
 * TODO: check that type names start with Capital letters
 * ----------------------------------------------------------------------------------------------------------------- */
import { reject } from '../ramda-like/list.js'
import TaggedFieldType from './tagged-field-type.js'

// ---------------------------------------------------------------------------------------------------------------------
// Helpers
// ---------------------------------------------------------------------------------------------------------------------

const isTypeAnArray = fieldType => typeof fieldType === 'string' && /^\[.*]\??$/.test(fieldType)
const assoc = (k, v, o) => Object.assign({}, o, { [k]: v })

/*
 * Repeat s count times
 * @sig repeatString :: (String, Number) -> String
 */
const repeatString = (s, count) => {
    let result = ''
    for (let i = 0; i < count; i++) result += s
    return result
}

/*
 * Indent each line of a block of text with 'indent'
 * @sig indentTextBlock :: (String, String) -> String
 */
const indentTextBlock = (indentation, lines) =>
    lines
        .split(/\n/)
        .map(s => indentation + s)
        .join('\n')

/*
 * Rewrite the lines, indenting them "properly" (4 spaces)
 * @sig reindentFunction :: String -> String
 */
const reindentFunction = functionText => {
    // remove all spaces after each newline to guarantee consistency
    functionText = functionText.replace(/\n */g, '\n')

    let result = ''
    let indentation = ''

    for (let i = 0; i < functionText.length; i++) {
        const c = functionText[i]

        // newline special case: followed by '}'. Decrement the indent before outputting '}' with the new indentation
        if (c === '\n' && functionText[i + 1] === '}') {
            indentation = indentation.slice(4)
            result += '\n' + indentation + '}'
            i++
        }

        // newline normal case: add indentation afterwards
        else if (c === '\n') {
            result += c + indentation
        }

        // '{' increments indentation
        else if (c === '{') {
            result += c
            indentation += '    '
        }

        // '}' decrements indentation
        else if (c === '}') {
            result += c
            indentation = indentation.slice(4)
        }

        // normal: just output c
        else {
            result += c
        }
    }

    return result
}

/*
 * Convert the initial character of s to a capital letter
 * @sig capitalizeInitialLetter :: String -> String
 */
const capitalizeInitialLetter = s => s[0].toUpperCase() + s.slice(1)

/*
 * Generate the constructor function
 */
const $mainFunction = (functionName, parameterNames, parameterCountGuard, typeCheckGuards, assignments) => {
    const func = `
        (function ${functionName}(${parameterNames}) {
            ${wrap}
            
            ${parameterCountGuard}
            ${typeCheckGuards.join('\n        ')}
    
            const result = Object.create(prototype)
            ${assignments.join('\n        ')}
            return result
        })`

    return reindentFunction(func)
}

/*
 * Generate an if statement as a block
 */
const $ifBlock = (condition, statements) => `
    if (${condition}) {
        ${statements}
    }`

/*
 * Generate an if guard
 * @sig $ifGuard :: (String, String) -> String
 */
const $ifGuard = (conditions, error) => `if (${conditions}) { debugger; throw new TypeError(${error}) }`

/*
 * Generate a Type Constructor function given its name and its fields. See description at top of file
 *
 * @sig generateTypeConstructor = (String, String, { FieldName: fieldType }) -> String
 *  FieldName = String
 *  fieldType = String
 */
const generateTypeConstructor = (typeName, fullTypeName, fields) => {
    // generate "if (x) result.x = x" if x is an optional argument and simply "result.x = x" otherwise
    // this eliminates getting results like: { rotation: undefined }
    const generateAssignment = f => {
        const type = fields[f].toString() // might be a regex
        const isOptional = type.match(/\?/)

        // x != is JavaScript magic for NEITHER null NOR undefined
        return isOptional ? `if (${f} != null) result.${f} = ${f}` : `result.${f} = ${f}`
    }

    const keys = Object.keys(fields)

    const parameterNames = keys.join(', ')

    const constructorName = `${fullTypeName}(${parameterNames})`
    const typeChecks = Object.entries(fields).map(([name, type]) => generateTypeCheck(constructorName, name, type))
    const assignments = keys.map(generateAssignment)

    // if there are optional values, skip the parameter count check
    const hasOptional = Object.values(fields).some(f => (typeof f === 'string' ? f.match(/\?/) : f.optional))
    const countCheck = hasOptional
        ? ''
        : `if (arguments.length !== ${keys.length}) { debugger; throw new TypeError('In constructor ${constructorName}: expected ${keys.length} arguments, found ' + arguments.length)}`
    return $mainFunction(typeName, parameterNames, countCheck, typeChecks, assignments)
}

/*
 * Generate guards that validate the type of an actual parameter to a constructor versus its "declared" type
 */
const generateTypeCheck = (constructorName, name, fieldType) => {
    /*
     * Generate multi-level "isArray" tests
     * @example if p is declared to be [[[Number]]] generates:
     *
     *      !Array.isArray(p) || !Array.isArray(p[0]) || !Array.isArray(p[0][0])
     */
    const generateArrayCondition = () => {
        let checks = [`!Array.isArray(${name})`]
        let childName = name + '[0]'

        for (let i = 1; i < fieldType.arrayDepth; i++) {
            checks.push(`!Array.isArray(${childName})`)
            childName = childName + '[0]'
        }

        checks = checks.join(' || ')
        return checks
    }

    /*
     * Generates 'if (!condition) { debugger; throw new TypeError() }
     * The specific condition and TypeError message depend on conditions passed in; multiple conditions can be specified
     * @example:
     *
     *      const optionalTypeMatchCheck = (name, type) => guard({ name, type, conditions: ['optional', 'types-match'] })
     *
     * This function is infrastructure; look at its uses to understand what's going on
     * @sig guard :: { conditions: [String], name: String, type: String, regex: RegExp } -> String
     */
    const guard = ({ conditions, name, type, regex }) => {
        // generate an expression that expresses the intent of the given conditionType
        const generateCondition = conditionType => {
            switch (conditionType) {
                case 'optional':
                    return `(${name} != null)` // x != is JavaScript magic for NEITHER null NOR undefined
                case 'types-match':
                    return `typeof ${name} !== '${type}'`
                case 'tags-match':
                    return `${name}['@@typeName'] !== '${taggedType}'`
                case 'regex-matches':
                    return `!${name}.match(${regex})`
                case 'is-array':
                    return generateArrayCondition()
            }
        }

        // generate an ifGuard error message based on the passed-in context
        const expectation = () => {
            if (regex) return `match ${regex}`
            if (fieldType.arrayDepth) return `have type ${TaggedFieldType.toString(fieldType)}`
            return `have type ${capitalizeInitialLetter(type)}`
        }

        // construct the 'if'
        return $ifGuard(
            conditions.map(generateCondition).join(' && '),
            `'In constructor ${constructorName}: expected ${name} to ${expectation()}; found ' + wrap(${name})`
        )
    }

    // 'if (typeof p !== 'eg: string') { debugger; throw new TypeError() }
    // generate 'if (p && typeof p !== 'eg: string') { debugger; throw new TypeError() }
    const typeMatchCheck = (name, type) => guard({ name, type, conditions: ['types-match'] })
    const optionalTypeMatchCheck = (name, type) => guard({ name, type, conditions: ['optional', 'types-match'] })
    const requiredTagCheck = (name, type) => guard({ name, type, conditions: ['tags-match'] })
    const optionalTagCheck = (name, type) => guard({ name, type, conditions: ['optional', 'tags-match'] })

    const requiredStringRegexCheck = (name, regex) =>
        [
            guard({ name, type: 'string', conditions: ['types-match'] }),
            guard({ name, type: 'string', conditions: ['regex-matches'], regex }),
        ].join('\n        ')

    const optionalStringRegexCheck = (name, regex) =>
        [
            guard({ name, type: 'string', conditions: ['optional', 'types-match'] }),
            guard({ name, type: 'string', conditions: ['optional', 'regex-matches'], regex }),
        ].join('\n        ')

    /*
     * Checking an array involves 2 separate tests: is the value an array to the proper arrayDepth
     * Does the first completely-nested element match the fieldType?
     *
     * generate a check for the first 'real' element -- no matter how deeply nested it is -- against the
     * fieldType, after first removing the outer brackets
     */
    const arrayCheck = (name, fieldType) => {
        const childName = name + repeatString('[0]', fieldType.arrayDepth)

        // Because the nested type can be anything, we have to make a recursive call to check its type,
        // but we first have to set the arrayDepth to 0 or we'll recurse forever
        // also: in order to allow 0-length arrays, the whole thing is wrapped in length check
        const baseCheck = `
            if (${name}.length) {
                ${generateTypeCheck(constructorName, childName, assoc('arrayDepth', 0, fieldType))}
            }`

        // combine the two tests
        return [guard({ conditions: ['is-array'], name }), baseCheck].join('\n        ')
    }

    const optionalArrayCheck = (name, fieldType) => {
        const statements = indentTextBlock('    ', arrayCheck(name, fieldType))
        return $ifBlock(`${name} != null`, statements) // x != is JavaScript magic for NEITHER null NOR undefined
    }

    if (typeof fieldType === 'string' || fieldType instanceof RegExp) fieldType = TaggedFieldType.fromString(fieldType)

    const { baseType, optional, regex, arrayDepth, taggedType } = fieldType

    if (arrayDepth && optional) return optionalArrayCheck(name, fieldType)
    if (arrayDepth) return arrayCheck(name, fieldType)

    if (baseType === 'Any') return ''

    // regex
    if (baseType === 'String' && regex && optional) return optionalStringRegexCheck(name, regex)
    if (baseType === 'String' && regex) return requiredStringRegexCheck(name, regex)

    // optional
    if (baseType === 'String' && optional) return optionalTypeMatchCheck(name, 'string')
    if (baseType === 'Number' && optional) return optionalTypeMatchCheck(name, 'number')
    if (baseType === 'Boolean' && optional) return optionalTypeMatchCheck(name, 'boolean')
    if (baseType === 'Object' && optional) return optionalTypeMatchCheck(name, 'object', true)
    if (baseType === 'Tagged' && optional) return optionalTagCheck(name, taggedType)

    // basic
    if (baseType === 'String') return typeMatchCheck(name, 'string')
    if (baseType === 'Number') return typeMatchCheck(name, 'number')
    if (baseType === 'Boolean') return typeMatchCheck(name, 'boolean')
    if (baseType === 'Object') return typeMatchCheck(name, 'object', true)
    if (baseType === 'Tagged') return requiredTagCheck(name, taggedType)

    throw new Error(`Don't understand fieldType ${JSON.stringify(fieldType)}`)
}

/*
 * Generate a Type Constructor function for a "Unit Type" like Nil in a LinkedList. Only one instance of Nil
 * will ever be created and all LinkedLists will end by pointing at that one Nil object. There are no fields
 * for a Unit Type so the function is very simple.
 *
 * Note: we generate this function ONLY so that we can give the generated object a name that's visible in
 * debuggers. The "name" of an object (actually the name of its constructor) is read-only and assigned
 * using a complicated set of rules that are hard to follow when generating code.
 *
 * By generating a function with the proper name we guarantee the Unit object will get the proper name. See taggedSum.
 */
const generateUnitConstructor = (protoName, typeName) => `(function ${typeName}() {})`

/*
 * Generate a TypeConstructor.from function given the prototype to use, the name of the Type Constructor and the fields
 *
 * Example:
 *
 * generateFrom('tagProto', 'Square', ['topLeft', 'bottomRight']) =>
 *
 *   (Square => function from(o) {
 *       if (!o.hasOwnProperty('topLeft')) throw new TypeError('Missing field: topLeft')
 *       if (!o.hasOwnProperty('bottomRight')) throw new TypeError('Missing field: bottomRight')
 *
 *       return Square(o.topLeft, o.bottomRight)
 *   })
 */
const generateFrom = (protoName, typeName, fullName, fields) => {
    const fieldNames = Object.keys(fields)

    const typeIsAStringContainingAQuestionMark = n => typeof fields[n] === 'string' && fields[n].match('\\?')
    const requiredFieldNames = reject(typeIsAStringContainingAQuestionMark, fieldNames)
    const checks = requiredFieldNames.map(f =>
        $ifGuard(`!o.hasOwnProperty('${f}')`, `'${fullName}.from missing field: ${f}'`)
    )
    const params = fieldNames.map(f => `o.${f}`)
    return `
    (${typeName} => function from(o) {
        ${checks.join('\n        ')}

        return ${typeName}(${params.join(', ')})
    })`
}
/* eslint-disable no-eval, no-unused-vars */

/*
 * Usually o.toString(), but adds special handling for arrays and strings to make them more legible
 * @sig wrap :: a -> String
 */
const wrap = `
    const wrap = o => {
        if (typeof o === 'undefined') return undefined
        if (typeof o === 'string') return '"' + o + '"'
        if (Array.isArray(o)) return '[' + o.map(wrap).join(', ') + ']'

        return typeof o === 'object' && !o['@@typeName'] ? JSON.stringify(o) : o.toString()
    }
`

const s1 = fieldType => `
(function toString() {
    ${wrap}
    return '${fieldType}'
})
`

const s2 = (fieldType, parameters) => `
(function toString() {
    ${wrap}
    return \`${fieldType}(${parameters})\`
})
`

/*
 * Generate a 'toString' function given the name of the Type Constructor and the fields. See tagged/taggedSum
 *
 * Example:
 *
 *   generateToString('X.Y', { a: 'Coord', b: 'String', c: 'Any' })
 *
 * Generates
 *
 *   (function toString() { return `X.Y(${this.a}, "${this.b}", ${typeof this.c === 'string' ? '"' + this.c + '"' : this.c})` })
 */
const generateToString = (fieldType, fields) => {
    const generateValueString = ([name, fieldType]) => {
        const v = 'this.' + name

        if (isTypeAnArray(fieldType)) return '${wrap(' + v + ')}'
        if (fieldType === 'String') return '"${' + v + '}"'
        if (fieldType === 'Any') return '${' + `wrap(${v})` + '}'

        return '${' + v + '}'
    }

    const names = Object.keys(fields)
    const parameters = `${Object.entries(fields).map(generateValueString).join(', ')}`
    return names.length === 0 ? s1(fieldType) : s2(fieldType, parameters)
}

const Generator = {
    generateToString,
    generateFrom,
    generateTypeConstructor,
    generateUnitConstructor,
}

export default Generator
