import ArrowHeadImage from './map-images/arrow-head.png'

import PhotoMarker0 from './map-images/marker.0@3x.png'
import PhotoMarker1 from './map-images/marker.1@3x.png'
import PhotoMarker2 from './map-images/marker.2@3x.png'
import PhotoMarker3 from './map-images/marker.3@3x.png'
import PhotoMarker4 from './map-images/marker.4@3x.png'
import PhotoMarker5 from './map-images/marker.5@3x.png'
import PhotoMarker6 from './map-images/marker.6@3x.png'
import PhotoMarker7 from './map-images/marker.7@3x.png'
import PhotoMarker8 from './map-images/marker.8@3x.png'
import PhotoMarker9 from './map-images/marker.9+@3x.png'

import PhotoMarkerNote from './map-images/marker.note@3x.png'
import PhotoMarkerSelected0 from './map-images/marker.selected.0@3x.png'
import PhotoMarkerSelected1 from './map-images/marker.selected.1@3x.png'
import PhotoMarkerSelected2 from './map-images/marker.selected.2@3x.png'
import PhotoMarkerSelected3 from './map-images/marker.selected.3@3x.png'
import PhotoMarkerSelected4 from './map-images/marker.selected.4@3x.png'
import PhotoMarkerSelected5 from './map-images/marker.selected.5@3x.png'
import PhotoMarkerSelected6 from './map-images/marker.selected.6@3x.png'
import PhotoMarkerSelected7 from './map-images/marker.selected.7@3x.png'
import PhotoMarkerSelected8 from './map-images/marker.selected.8@3x.png'
import PhotoMarkerSelected9 from './map-images/marker.selected.9+@3x.png'
import PhotoMarkerSelectedBadge0 from './map-images/marker.selected.badge.0@3x.png'
import PhotoMarkerSelectedBadge1 from './map-images/marker.selected.badge.1@3x.png'
import PhotoMarkerSelectedBadge2 from './map-images/marker.selected.badge.2@3x.png'
import PhotoMarkerSelectedBadge3 from './map-images/marker.selected.badge.3@3x.png'
import PhotoMarkerSelectedBadge4 from './map-images/marker.selected.badge.4@3x.png'
import PhotoMarkerSelectedBadge5 from './map-images/marker.selected.badge.5@3x.png'
import PhotoMarkerSelectedBadge6 from './map-images/marker.selected.badge.6@3x.png'
import PhotoMarkerSelectedBadge7 from './map-images/marker.selected.badge.7@3x.png'
import PhotoMarkerSelectedBadge8 from './map-images/marker.selected.badge.8@3x.png'
import PhotoMarkerSelectedBadge9 from './map-images/marker.selected.badge.9+@3x.png'
import PhotoMarkerSelectedNote from './map-images/marker.selected.note@3x.png'

// task archived badges
import TaskBadgeArchived from './map-images/task.badge.archived@3x.png'
import PhotoMarkerArchived0 from './map-images/marker.archived.0@3x.png'
import PhotoMarkerArchived1 from './map-images/marker.archived.1@3x.png'
import PhotoMarkerArchived2 from './map-images/marker.archived.2@3x.png'
import PhotoMarkerArchived3 from './map-images/marker.archived.3@3x.png'
import PhotoMarkerArchived4 from './map-images/marker.archived.4@3x.png'
import PhotoMarkerArchived5 from './map-images/marker.archived.5@3x.png'
import PhotoMarkerArchived6 from './map-images/marker.archived.6@3x.png'
import PhotoMarkerArchived7 from './map-images/marker.archived.7@3x.png'
import PhotoMarkerArchived8 from './map-images/marker.archived.8@3x.png'
import PhotoMarkerArchived9 from './map-images/marker.archived.9+@3x.png'
import PhotoMarkerSelectedArchived0 from './map-images/marker.selected.archived.0@3x.png'
import PhotoMarkerSelectedArchived1 from './map-images/marker.selected.archived.1@3x.png'
import PhotoMarkerSelectedArchived2 from './map-images/marker.selected.archived.2@3x.png'
import PhotoMarkerSelectedArchived3 from './map-images/marker.selected.archived.3@3x.png'
import PhotoMarkerSelectedArchived4 from './map-images/marker.selected.archived.4@3x.png'
import PhotoMarkerSelectedArchived5 from './map-images/marker.selected.archived.5@3x.png'
import PhotoMarkerSelectedArchived6 from './map-images/marker.selected.archived.6@3x.png'
import PhotoMarkerSelectedArchived7 from './map-images/marker.selected.archived.7@3x.png'
import PhotoMarkerSelectedArchived8 from './map-images/marker.selected.archived.8@3x.png'
import PhotoMarkerSelectedArchived9 from './map-images/marker.selected.archived.9+@3x.png'

import TaskBadgeBlocked from './map-images/task.badge.blocked@3x.png'
import TaskBadgeComplete from './map-images/task.badge.complete@3x.png'
import TaskBadgeInProgress from './map-images/task.badge.in-progress@3x.png'
import TaskBadgeNeedsReview from './map-images/task.badge.needsreview@3x.png'
import TaskBadgeOverdue from './map-images/task.badge.overdue@3x.png'
import TaskSelectedArchived from './map-images/task.selected.archived@3x.png'
import TaskSelectedBlockedNote from './map-images/task.selected.blocked.note@3x.png'
import TaskSelectedBlockedOverdue from './map-images/task.selected.blocked.overdue@3x.png'
import TaskSelectedBlocked from './map-images/task.selected.blocked@3x.png'
import TaskSelectedComplete from './map-images/task.selected.complete@3x.png'
import TaskSelectedInProgressNote from './map-images/task.selected.in-progress.note@3x.png'
import TaskSelectedInProgressOverdue from './map-images/task.selected.in-progress.overdue@3x.png'
import TaskSelectedInProgress from './map-images/task.selected.in-progress@3x.png'
import TaskSelectedNeedsReviewNote from './map-images/task.selected.needsreview.note@3x.png'
import TaskSelectedNeedsReviewOverdue from './map-images/task.selected.needsreview.overdue@3x.png'
import TaskSelectedNeedsReview from './map-images/task.selected.needsreview@3x.png'

// temporarily copy from tasks
const PhotoMarkerArchived = TaskBadgeArchived
const PhotoMarkerSelectedArchived = TaskSelectedArchived

const images = [
    // sdf set to true to allow changing icon color
    { id: 'arrow', content: ArrowHeadImage, opts: { sdf: true } },
    { id: 'photoMarkerNote', content: PhotoMarkerNote },
    { id: 'photoMarkerSelectedNote', content: PhotoMarkerSelectedNote },
    { id: 'photoMarker0', content: PhotoMarker0 },
    { id: 'photoMarker1', content: PhotoMarker1 },
    { id: 'photoMarker2', content: PhotoMarker2 },
    { id: 'photoMarker3', content: PhotoMarker3 },
    { id: 'photoMarker4', content: PhotoMarker4 },
    { id: 'photoMarker5', content: PhotoMarker5 },
    { id: 'photoMarker6', content: PhotoMarker6 },
    { id: 'photoMarker7', content: PhotoMarker7 },
    { id: 'photoMarker8', content: PhotoMarker8 },
    { id: 'photoMarker9+', content: PhotoMarker9 },
    { id: 'photoMarkerSelected0', content: PhotoMarkerSelected0 },
    { id: 'photoMarkerSelected1', content: PhotoMarkerSelected1 },
    { id: 'photoMarkerSelected2', content: PhotoMarkerSelected2 },
    { id: 'photoMarkerSelected3', content: PhotoMarkerSelected3 },
    { id: 'photoMarkerSelected4', content: PhotoMarkerSelected4 },
    { id: 'photoMarkerSelected5', content: PhotoMarkerSelected5 },
    { id: 'photoMarkerSelected6', content: PhotoMarkerSelected6 },
    { id: 'photoMarkerSelected7', content: PhotoMarkerSelected7 },
    { id: 'photoMarkerSelected8', content: PhotoMarkerSelected8 },
    { id: 'photoMarkerSelected9+', content: PhotoMarkerSelected9 },
    { id: 'photoMarkerSelectedBadge0', content: PhotoMarkerSelectedBadge0 },
    { id: 'photoMarkerSelectedBadge1', content: PhotoMarkerSelectedBadge1 },
    { id: 'photoMarkerSelectedBadge2', content: PhotoMarkerSelectedBadge2 },
    { id: 'photoMarkerSelectedBadge3', content: PhotoMarkerSelectedBadge3 },
    { id: 'photoMarkerSelectedBadge4', content: PhotoMarkerSelectedBadge4 },
    { id: 'photoMarkerSelectedBadge5', content: PhotoMarkerSelectedBadge5 },
    { id: 'photoMarkerSelectedBadge6', content: PhotoMarkerSelectedBadge6 },
    { id: 'photoMarkerSelectedBadge7', content: PhotoMarkerSelectedBadge7 },
    { id: 'photoMarkerSelectedBadge8', content: PhotoMarkerSelectedBadge8 },
    { id: 'photoMarkerSelectedBadge9+', content: PhotoMarkerSelectedBadge9 },

    // archived photo marker (no matter what else)
    { id: 'photoMarkerArchived', content: PhotoMarkerArchived },
    { id: 'photoMarkerSelectedArchived', content: PhotoMarkerSelectedArchived },
    { id: 'photoMarkerArchived0', content: PhotoMarkerArchived0 },
    { id: 'photoMarkerArchived1', content: PhotoMarkerArchived1 },
    { id: 'photoMarkerArchived2', content: PhotoMarkerArchived2 },
    { id: 'photoMarkerArchived3', content: PhotoMarkerArchived3 },
    { id: 'photoMarkerArchived4', content: PhotoMarkerArchived4 },
    { id: 'photoMarkerArchived5', content: PhotoMarkerArchived5 },
    { id: 'photoMarkerArchived6', content: PhotoMarkerArchived6 },
    { id: 'photoMarkerArchived7', content: PhotoMarkerArchived7 },
    { id: 'photoMarkerArchived8', content: PhotoMarkerArchived8 },
    { id: 'photoMarkerArchived9+', content: PhotoMarkerArchived9 },
    { id: 'photoMarkerSelectedArchived0', content: PhotoMarkerSelectedArchived0 },
    { id: 'photoMarkerSelectedArchived1', content: PhotoMarkerSelectedArchived1 },
    { id: 'photoMarkerSelectedArchived2', content: PhotoMarkerSelectedArchived2 },
    { id: 'photoMarkerSelectedArchived3', content: PhotoMarkerSelectedArchived3 },
    { id: 'photoMarkerSelectedArchived4', content: PhotoMarkerSelectedArchived4 },
    { id: 'photoMarkerSelectedArchived5', content: PhotoMarkerSelectedArchived5 },
    { id: 'photoMarkerSelectedArchived6', content: PhotoMarkerSelectedArchived6 },
    { id: 'photoMarkerSelectedArchived7', content: PhotoMarkerSelectedArchived7 },
    { id: 'photoMarkerSelectedArchived8', content: PhotoMarkerSelectedArchived8 },
    { id: 'photoMarkerSelectedArchived9+', content: PhotoMarkerSelectedArchived9 },

    // unselected task without note
    { id: 'taskBadgeArchived', content: TaskBadgeArchived },
    { id: 'taskBadgeBlocked', content: TaskBadgeBlocked },
    { id: 'taskBadgeComplete', content: TaskBadgeComplete },
    { id: 'taskBadgeInProgress', content: TaskBadgeInProgress },
    { id: 'taskBadgeNeedsReview', content: TaskBadgeNeedsReview },
    { id: 'taskBadgeOverdue', content: TaskBadgeOverdue },

    // selected task without note
    { id: 'taskSelectedNeedsReview', content: TaskSelectedNeedsReview },
    { id: 'taskSelectedArchived', content: TaskSelectedArchived },
    { id: 'taskSelectedBlocked', content: TaskSelectedBlocked },
    { id: 'taskSelectedComplete', content: TaskSelectedComplete },
    { id: 'taskSelectedInProgress', content: TaskSelectedInProgress },
    { id: 'taskSelectedBlockedOverdue', content: TaskSelectedBlockedOverdue },
    { id: 'taskSelectedInProgressOverdue', content: TaskSelectedInProgressOverdue },
    { id: 'taskSelectedNeedsReviewOverdue', content: TaskSelectedNeedsReviewOverdue },

    // selected task with note
    { id: 'taskSelectedBlockedNote', content: TaskSelectedBlockedNote },
    { id: 'taskSelectedInProgressNote', content: TaskSelectedInProgressNote },
    { id: 'taskSelectedNeedsReviewNote', content: TaskSelectedNeedsReviewNote },
]

const loadMapImages = mapboxMap => {
    return Promise.all(
        images.map(
            img =>
                new Promise((resolve, reject) => {
                    mapboxMap.loadImage(img.content, function (error, res) {
                        if (error) throw error
                        mapboxMap.addImage(img.id, res, { pixelRatio: 3, ...img?.opts })
                        resolve()
                    })
                })
        )
    )
}

export { loadMapImages }
