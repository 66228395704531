import { useSelector } from 'react-redux'
import { ReduxSelectors } from '../../redux/index.js'
import useLocalStorage from './useLocalStorage'

export const useRecentCollaborations = () => {
    const organizationId = useSelector(ReduxSelectors.selectedOrganizationId)
    const projectId = useSelector(ReduxSelectors.selectedProjectId)

    const [, setRecentlyViewedCollaborations, getLocalStorageValue] = useLocalStorage(
        'recentlyViewedCollaborations',
        {}
    )

    const getRecentCollaborations = () => {
        if (!organizationId || !projectId) return []

        const recentlyViewed = getLocalStorageValue() // ensure we always pull most recent value
        return recentlyViewed[organizationId]?.[projectId] || []
    }

    // Write a collaboration id to local storage
    const registerCollaborationAsRecentlyViewed = selectedCollaborationId => {
        if (!selectedCollaborationId || !organizationId || !projectId) return

        setRecentlyViewedCollaborations(prevValue => {
            const prevIds = prevValue[organizationId]?.[projectId] || []
            // Filter out the current ID to avoid duplication
            const filteredCollaborations = prevIds.filter(id => id !== selectedCollaborationId)

            // Initialize prevValue[organizationId] if it doesn't exist
            if (!prevValue[organizationId]) {
                prevValue[organizationId] = {}
            }

            // Add the new ID at the start of the array
            prevValue[organizationId][projectId] = [selectedCollaborationId, ...filteredCollaborations]

            return prevValue
        })
    }

    return {
        getRecentCollaborations,
        registerCollaborationAsRecentlyViewed,
    }
}

export default useRecentCollaborations
