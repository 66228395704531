import React from 'react'
import { styled } from '../range-theme/index.js'
import { FlexRow, Icon, IconButtonWithTooltip, Text } from '../components-reusable/index.js'

const StyledFiltersActiveBanner = styled(FlexRow, {
    height: 32,
    minHeight: 32,
    width: '100%',
    backgroundColor: '$primary04',
    paddingLeft: 16,
    paddingRight: 8,
    color: '$white',
    ai: 'center',
    fs: 12,
    gap: 6,

    variants: {
        variant: {
            taskList: {
                borderBottom: 'none',
            },
            canvas: {
                borderBottom: '1px solid $neutral07',
            },
        },
    },
})

const FiltersActiveBanner = ({
    variant,
    onResetClick,
    bannerText = 'Filters Active',
    tooltipText = 'Clear Filters',
}) => {
    return (
        <StyledFiltersActiveBanner variant={variant}>
            <Icon name="filter" iconSize="12" />
            <Text css={{ mr: 'auto' }}>{bannerText}</Text>
            <IconButtonWithTooltip
                iconName="close"
                size="12px"
                tooltipText={tooltipText}
                side="bottom"
                onClick={onResetClick}
                variant="banner"
            />
        </StyledFiltersActiveBanner>
    )
}

export default FiltersActiveBanner
