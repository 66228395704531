/*
 * Special fullscreen loading indicator used in canvas source editing (both edit and in admin)
 */

import Rive from '@rive-app/react-canvas'
import React from 'react'
import { FlexColumn, Text } from '../components-reusable'
import { styled } from '../range-theme'

const StyledBaseContainer = styled(FlexColumn, {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$neutral09',
    width: '100vw',
    height: '100vh',
})

const StyledPlayerContainer = styled('div', {
    height: '148px',
    width: '148px',
})

const PDFCanvasSourceEditLoadingBase = ({ infoLabel }) => {
    return (
        <StyledBaseContainer>
            <StyledPlayerContainer>
                <Rive src="/range_loading_animation.riv" />
            </StyledPlayerContainer>
            <Text
                css={{ color: '$neutral04', fontSize: '16px', lineHeight: '27px', fontWeight: 600, mt: 8 }}
                id="progress-text"
            >
                {infoLabel}
            </Text>
        </StyledBaseContainer>
    )
}

const PDFCanvasSourceEditLoadingUpdate = () => (
    <PDFCanvasSourceEditLoadingBase infoLabel="Updating project, this could take a minute..." />
)

const PDFCanvasSourceEditLoadingProjectCreate = () => (
    <PDFCanvasSourceEditLoadingBase infoLabel="Creating project, this could take a minute..." />
)

const PDFCanvasSourceEditLoading = {
    ProjectCreate: PDFCanvasSourceEditLoadingProjectCreate,
    Update: PDFCanvasSourceEditLoadingUpdate,
}

export default PDFCanvasSourceEditLoading
