import * as Collapsible from '@radix-ui/react-collapsible'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FlexColumn, FlexRow, Icon, IconButtonWithTooltip, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import PropTypes from 'prop-types'
import { Geometry } from '@range.io/basic-types'

const InformationActionButton = ({ iconName, onClick, tooltipText }) => (
    <IconButtonWithTooltip
        size="12px"
        tooltipText={tooltipText}
        iconName={iconName}
        side="bottom"
        css={{
            br: 1000,
            bg: '$neutral10',
            border: '1px solid $neutral07',
            p: 5,
            boxSizing: 'content-box',
            color: '$neutral04',
            '&:hover': {
                border: '1px solid $primary04',
            },
        }}
        onClick={onClick}
    />
)

const InformationDataRowCopyButton = ({ onClick }) => (
    <IconButtonWithTooltip
        size="12px"
        tooltipText="Copy to clipboard"
        iconName="copyToClipboardLeft"
        side="bottom"
        css={{
            boxSizing: 'content-box',
            marginLeft: '4px',
            color: '$neutral04',
        }}
        onClick={onClick}
    />
)

const StyledAdditionalInformationRoot = styled(Collapsible.Root, {
    backgroundColor: '$neutral09',
    border: '1px solid $neutral08',
    borderRadius: 6,
    padding: '8px 12px',

    variants: {
        pinType: {
            TaskPin: {
                marginTop: 6,
            },
            PhotoPin: {
                marginBottom: 10,
            },
        },
    },
})

const StyledInformationDataRow = styled(FlexRow, {
    justifyContent: 'space-between',
})

const StyledInformationDataRowLabel = styled(Text, {
    fontSize: '13px',
    color: '$neutral05',
})

const StyledInformationDataRowValue = styled(Text, {
    fontSize: '13px',
})

const InformationDataRow = ({ label, onClick, value }) => (
    <StyledInformationDataRow>
        <StyledInformationDataRowLabel>{label}</StyledInformationDataRowLabel>
        <StyledInformationDataRowValue>
            {value}
            <InformationDataRowCopyButton onClick={() => onClick(value)} />
        </StyledInformationDataRowValue>
    </StyledInformationDataRow>
)

const CollaborationAdditionalInformationPanel = ({ geometry, pinType }) => {
    const { canvasSource } = useSelector(ReduxSelectors.canvasAndFirstSourceForId(geometry.canvasId))
    const [isOpen, setIsOpen] = useState(false)

    const shouldDisplay = canvasSource.type === 'mapbox' || canvasSource.type === 'dronedeploy'

    if (!shouldDisplay) return null

    const geometryDataFormatted = Geometry.getFormattedForDisplay(geometry)

    const handleCopyToClipboard = async value => {
        await navigator.clipboard.writeText(value)
    }

    const getBulkCopyText = () => {
        const position = `Latitude: ${geometryDataFormatted.lat}, Longitude: ${geometryDataFormatted.lng}`
        if (geometryDataFormatted?.elevationMetres) {
            return `${position}, Elevation: ${geometryDataFormatted.elevationMetres}m / ${geometryDataFormatted.elevationFeet}ft`
        }
        return position
    }

    return (
        <StyledAdditionalInformationRoot open={isOpen} pinType={pinType}>
            <FlexRow css={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <FlexRow css={{ alignItems: 'center' }}>
                    <Icon name="globe" iconSize="16px" css={{ marginRight: '6px', color: '$neutral05' }} />
                    <Text css={{ fs: '14px' }}>Additional Information</Text>
                </FlexRow>
                <FlexRow css={{ gap: '4px' }}>
                    <InformationActionButton
                        tooltipText="Copy to clipboard"
                        iconName="copyToClipboardRight"
                        onClick={() => handleCopyToClipboard(getBulkCopyText())}
                    />
                    <Collapsible.Trigger asChild>
                        {isOpen ? (
                            <InformationActionButton
                                tooltipText="Collapse"
                                iconName="chevronUp"
                                onClick={() => setIsOpen(false)}
                            />
                        ) : (
                            <InformationActionButton
                                tooltipText="Expand"
                                iconName="chevronDown"
                                onClick={() => setIsOpen(true)}
                            />
                        )}
                    </Collapsible.Trigger>
                </FlexRow>
            </FlexRow>
            <Collapsible.Content>
                <FlexColumn css={{ marginTop: '8px' }}>
                    <InformationDataRow
                        label="Latitude:"
                        value={geometryDataFormatted.lat}
                        onClick={handleCopyToClipboard}
                    />
                    <InformationDataRow
                        label="Longitude:"
                        value={geometryDataFormatted.lng}
                        onClick={handleCopyToClipboard}
                    />
                    {geometry?.elevation && (
                        <>
                            <InformationDataRow
                                label="Elevation:"
                                value={`${geometryDataFormatted.elevationMetres}m`}
                                onClick={handleCopyToClipboard}
                            />
                            <InformationDataRow
                                label=""
                                value={`${geometryDataFormatted.elevationFeet}ft`}
                                onClick={handleCopyToClipboard}
                            />
                        </>
                    )}
                </FlexColumn>
            </Collapsible.Content>
        </StyledAdditionalInformationRoot>
    )
}

CollaborationAdditionalInformationPanel.propTypes = {
    geometry: PropTypes.object.isRequired,
    pinType: PropTypes.oneOf(['TaskPin', 'PhotoPin']).isRequired,
}

export default CollaborationAdditionalInformationPanel
