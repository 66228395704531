import PropTypes from 'prop-types'
import React from 'react'
import { FlexRow, Icon } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const Count = styled(FlexRow, {
    ai: 'center',
    justifyContent: 'center',
    transitionDuration: '0.4s',
    fs: '14px',
    fw: 600,
    br: 1000,
    color: 'white',

    variants: {
        archived: {
            true: { backgroundColor: '$archived', border: 'none' },
            false: { backgroundColor: '$pink03', border: '1px solid $pink04' },
        },
        size: {
            32: { h: 32, w: 32 },
            24: { h: 24, w: 24, minWidth: 24 },
            22: { h: 22, w: 22, minWidth: 22 },
        },
    },

    defaultVariants: {
        archived: false,
        size: 32,
    },
})

const FlagSuperscriptIcon = () => (
    <FlexRow
        css={{
            width: '20px',
            height: '20px',
            background: '$orange03',
            ai: 'center',
            justifyContent: 'center',
            border: '1px solid $orange04',
            br: 1000,
            position: 'absolute',
            left: '20px',
            top: -8,
        }}
    >
        <Icon name="flag" css={{ width: '9px', color: 'white' }} />
    </FlexRow>
)

const CalendarSuperscriptIcon = () => (
    <FlexRow
        css={{
            w: 20,
            h: 20,
            bg: '$red03',
            ai: 'center',
            justifyContent: 'center',
            border: '1px solid $orange04',
            br: 1000,
            position: 'absolute',
            left: '20px',
            top: -8,
        }}
    >
        <Icon name="calendar" css={{ ml: 1, mt: -1, width: '14px', color: 'white' }} />
    </FlexRow>
)

const CheckIcon = ({ color, size = 32 }) => <Icon css={{ color }} name="badge" iconSize={size + 'px'} />

// ---------------------------------------------------------------------------------------------------------------------
// CollaborationWindowBadge
// ---------------------------------------------------------------------------------------------------------------------

const ArchivedCheck = ({ size }) => <CheckIcon size={size} color="$archived" />

const OverdueTask = ({ size, color }) => (
    <FlexRow css={{ width: size, height: size, maxWidth: size, maxHeight: size, position: 'relative' }}>
        <CalendarSuperscriptIcon />
        <CheckIcon color={color} size={size} />
    </FlexRow>
)

const TaskWithNotes = ({ size, color }) => (
    <FlexRow css={{ width: size, height: size, maxWidth: size, maxHeight: size, position: 'relative' }}>
        <FlagSuperscriptIcon />
        <CheckIcon color={color} size={size} />
    </FlexRow>
)

const PhotoWithNotes = ({ size, count }) => (
    <FlexRow css={{ position: 'relative', w: size, h: size }}>
        <FlagSuperscriptIcon />
        <Count size={size}>{count}</Count>
    </FlexRow>
)

const ArchivedCount = ({ size, count }) => (
    <FlexRow css={{ position: 'relative' }}>
        <Count archived={true} size={size}>
            {count}
        </Count>
    </FlexRow>
)

const Y_ARCHIVED = 16
const N_ARCHIVED = 0
const Y_COMPLETED = 8
const N_COMPLETED = 0
const Y_TASK = 4
const N_TASK = 0
const Y_OVERDUE = 2
const N_OVERDUE = 0
const Y_NOTES = 1
const N_NOTES = 0

const badges = []

badges[Y_ARCHIVED + Y_COMPLETED + Y_TASK + Y_OVERDUE + Y_NOTES] = ({ sz }) => <ArchivedCheck size={sz} />
badges[Y_ARCHIVED + Y_COMPLETED + Y_TASK + Y_OVERDUE + N_NOTES] = ({ sz }) => <ArchivedCheck size={sz} />
badges[Y_ARCHIVED + Y_COMPLETED + N_TASK + Y_OVERDUE + Y_NOTES] = ({ c, sz }) => <ArchivedCount size={sz} count={c} />
badges[Y_ARCHIVED + Y_COMPLETED + N_TASK + Y_OVERDUE + N_NOTES] = ({ c, sz }) => <ArchivedCount size={sz} count={c} />

badges[Y_ARCHIVED + N_COMPLETED + Y_TASK + Y_OVERDUE + Y_NOTES] = ({ sz }) => <ArchivedCheck size={sz} />
badges[Y_ARCHIVED + N_COMPLETED + Y_TASK + Y_OVERDUE + N_NOTES] = ({ sz }) => <ArchivedCheck size={sz} />
badges[Y_ARCHIVED + N_COMPLETED + N_TASK + Y_OVERDUE + Y_NOTES] = ({ c, sz }) => <ArchivedCount size={sz} count={c} />
badges[Y_ARCHIVED + N_COMPLETED + N_TASK + Y_OVERDUE + N_NOTES] = ({ c, sz }) => <ArchivedCount size={sz} count={c} />

badges[Y_ARCHIVED + Y_COMPLETED + Y_TASK + N_OVERDUE + Y_NOTES] = ({ sz }) => <ArchivedCheck size={sz} />
badges[Y_ARCHIVED + Y_COMPLETED + Y_TASK + N_OVERDUE + N_NOTES] = ({ sz }) => <ArchivedCheck size={sz} />
badges[Y_ARCHIVED + Y_COMPLETED + N_TASK + N_OVERDUE + Y_NOTES] = ({ c, sz }) => <ArchivedCount size={sz} count={c} />
badges[Y_ARCHIVED + Y_COMPLETED + N_TASK + N_OVERDUE + N_NOTES] = ({ c, sz }) => <ArchivedCount size={sz} count={c} />

badges[Y_ARCHIVED + N_COMPLETED + Y_TASK + N_OVERDUE + Y_NOTES] = ({ sz }) => <ArchivedCheck size={sz} />
badges[Y_ARCHIVED + N_COMPLETED + Y_TASK + N_OVERDUE + N_NOTES] = ({ sz }) => <ArchivedCheck size={sz} />
badges[Y_ARCHIVED + N_COMPLETED + N_TASK + N_OVERDUE + Y_NOTES] = ({ c, sz }) => <ArchivedCount size={sz} count={c} />
badges[Y_ARCHIVED + N_COMPLETED + N_TASK + N_OVERDUE + N_NOTES] = ({ c, sz }) => <ArchivedCount size={sz} count={c} />

badges[N_ARCHIVED + Y_COMPLETED + Y_TASK + Y_OVERDUE + Y_NOTES] = ({ cl, sz }) => <CheckIcon color={cl} size={sz} />
badges[N_ARCHIVED + Y_COMPLETED + Y_TASK + Y_OVERDUE + N_NOTES] = ({ cl, sz }) => <CheckIcon color={cl} size={sz} />
badges[N_ARCHIVED + Y_COMPLETED + N_TASK + Y_OVERDUE + Y_NOTES] = ({ c, sz }) => <Count size={sz}>{c}</Count>
badges[N_ARCHIVED + Y_COMPLETED + N_TASK + Y_OVERDUE + N_NOTES] = ({ c, sz }) => <Count size={sz}>{c}</Count>

badges[N_ARCHIVED + N_COMPLETED + Y_TASK + Y_OVERDUE + Y_NOTES] = ({ cl, sz }) => <OverdueTask color={cl} size={sz} />
badges[N_ARCHIVED + N_COMPLETED + Y_TASK + Y_OVERDUE + N_NOTES] = ({ cl, sz }) => <OverdueTask color={cl} size={sz} />
badges[N_ARCHIVED + N_COMPLETED + N_TASK + Y_OVERDUE + Y_NOTES] = ({ c, sz }) => <PhotoWithNotes count={c} size={sz} />
badges[N_ARCHIVED + N_COMPLETED + N_TASK + Y_OVERDUE + N_NOTES] = ({ c, sz }) => <Count size={sz}>{c}</Count>

badges[N_ARCHIVED + Y_COMPLETED + Y_TASK + N_OVERDUE + Y_NOTES] = ({ cl, sz }) => <CheckIcon color={cl} size={sz} />
badges[N_ARCHIVED + Y_COMPLETED + Y_TASK + N_OVERDUE + N_NOTES] = ({ cl, sz }) => <CheckIcon color={cl} size={sz} />
badges[N_ARCHIVED + Y_COMPLETED + N_TASK + N_OVERDUE + Y_NOTES] = ({ c, sz }) => <Count size={sz}>{c}</Count>
badges[N_ARCHIVED + Y_COMPLETED + N_TASK + N_OVERDUE + N_NOTES] = ({ c, sz }) => <Count size={sz}>{c}</Count>

badges[N_ARCHIVED + N_COMPLETED + Y_TASK + N_OVERDUE + Y_NOTES] = ({ cl, sz }) => <TaskWithNotes color={cl} size={sz} />
badges[N_ARCHIVED + N_COMPLETED + Y_TASK + N_OVERDUE + N_NOTES] = ({ cl, sz }) => <CheckIcon color={cl} size={sz} />

badges[N_ARCHIVED + N_COMPLETED + N_TASK + N_OVERDUE + Y_NOTES] = ({ c, sz }) => <PhotoWithNotes count={c} size={sz} />
badges[N_ARCHIVED + N_COMPLETED + N_TASK + N_OVERDUE + N_NOTES] = ({ c, sz }) => <Count size={sz}>{c}</Count>

// prettier-ignore
const CollaborationWindowBadge = ({ size=32, isArchived, isCompleted, hasNotesPending, count, isTask=false, isOverdue, color }) => {
    const entry =
        (isArchived      ? Y_ARCHIVED   : N_ARCHIVED ) +
        (isCompleted     ? Y_COMPLETED  : N_COMPLETED) +
        (isTask          ? Y_TASK       : N_TASK     ) +
        (isOverdue       ? Y_OVERDUE    : N_OVERDUE  ) +
        (hasNotesPending ? Y_NOTES      : N_NOTES    )
    return badges[entry]({c: count, cl: color, sz: size})
}

CollaborationWindowBadge.propTypes = {
    isArchived: PropTypes.bool, // assumed false if missing
    isTask: PropTypes.bool,
    isOverdue: PropTypes.bool,
    hasNotesPending: PropTypes.bool, // assumed false is missing
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
}

export default CollaborationWindowBadge
