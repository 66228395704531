/*
 * Middleware to track changes to specific paths into the state. If the value of the state changes at the given path
 * it prints the old value, new value and a stack trace at the point the dispatch happened.
 * Uses the path function to read the state, so 'mapData.currentZoom' would work or ['mapData', 'currentZoom']
 *
 * It's automatically included if your host name is localhost (@see store.js)
 */

import { path } from '@range.io/functional'

// path is partially applied, but we always have the parameters
const myPath = (key, state) => {
    try {
        return path(key)(state)
    } catch (e) {
        return 'undefined'
    }
}

const logStateMiddleware = store => next => action => {
    const keysToWatch = window.reduxKeysToWatch || []

    const originalState = store.getState()
    const result = next(action)
    const finalState = store.getState()

    // Check if any of the watched keys have changed
    const changedKeys = keysToWatch.filter(key => myPath(key, originalState) !== myPath(key, finalState))

    /*
     * If state has changed, log the original and final state, using collapsed groups and colors to try to
     * make it easy to read. In the example below, nesting represents collapsed groups
     *
     * A  Redux state: selectedCanvasChanged1 set selectedCanvas: undefined ==> f2ddb9ef-47b8-4ace-b0ee-08b6bdd80546
     * B    selectedCanvas undefined f2ddb9ef-47b8-4ace-b0ee-08b6bdd80546
     * C    trace
     * D      <stack-trace>
     *
     * A is a summary with the data on one line if fits
     */
    if (changedKeys.length) {
        const k = changedKeys[0]
        const a = myPath(k, originalState)
        const b = myPath(k, finalState)

        // A console coloring requires %c and a corresponding parameter
        const showTitle = changedKeys.length === 1 && typeof b !== 'object'
        const title = showTitle ? `${k}: ${a} ==> ${b}` : `keys ['${changedKeys.join("', '")}']`
        const css1 = 'color: blue; background: white' // highlight is blue text over white background
        const css2 = 'color: initial; background: transparent' // reset to default
        console.groupCollapsed(`Redux state: %c${action.type}%c set %c${title}%c`, css1, css2, css1, css2)

        // B show the before and after values for each changed key (this may duplicate the value above)
        changedKeys.map(k => console.log(k, myPath(k, originalState), myPath(k, finalState)))

        // C / D show the stacktrace inside a collapsed group
        console.groupCollapsed('trace')
        console.trace()
        console.groupEnd()
        console.groupEnd()
    }

    return result
}

export default logStateMiddleware
