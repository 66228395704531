/*
 * Wrap a Mapbox map as a *background* view.
 *
 * There is always a foreground Map that controls pan/zoom, tracks the current location and has all the user's drawings.
 *
 * There will also always be a BACKGROUND view, which is sometimes another Mapbox map, but could also
 * be an ESRI map, a PDF or some other view. This component is a Mapbox map for use as a background view
 *
 * This map is controlled by the foreground map and is really just a backdrop to see where you are.
 */
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-dev.js'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Commands2 from '../firebase/commands-2/index.js'
import { addResource, resources } from '../firebase/commands-2/resources.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'

const accessToken = 'pk.eyJ1IjoicmFuZ2UtbWFwYm94IiwiYSI6ImNsZW9tdjFyZDAwZ3Azd25xYW9lbmdyNjUifQ.XwN5HIELuR-mEhA_i_z5XA'
mapboxgl.accessToken = accessToken

// ---------------------------------------------------------------------------------------------------------------------
// Alter Mapbox behavior based on XState
// ---------------------------------------------------------------------------------------------------------------------

const StyledMapboxContainer = styled('div', {
    zIndex: -100, // needs to be behind the foreground map, but goes after it in document-order

    // mapbox checks if this object has this color to determine whether you've loaded mapbox-gl.css -- which we haven't
    '.mapboxgl-canary': {
        backgroundColor: 'rgb(250, 128, 114)',
    },
})

const mapboxStyleUrl = name => {
    switch (name) {
        case 'outdoors':
            return 'mapbox://styles/mapbox/outdoors-v11'
        case 'light':
            return 'mapbox://styles/mapbox/light-v10'
        case 'dark':
            return 'mapbox://styles/mapbox/dark-v10'
        case 'satellite':
            return 'mapbox://styles/mapbox/satellite-v9'
        case 'satelliteStreets':
            return 'mapbox://styles/mapbox/satellite-streets-v11'
        case 'navigationDay':
            return 'mapbox://styles/mapbox/navigation-day-v1'
        case 'navigationNight':
            return 'mapbox://styles/mapbox/navigation-night-v1'
        case 'streets':
        default:
            return 'mapbox://styles/mapbox/streets-v11'
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Map component
// ---------------------------------------------------------------------------------------------------------------------
let ourCanvasId // guarantee we respond only to changes to the canvas we should
const possiblyReplaceDroneDeployLayer = (backgroundMapboxMap, canvasSource) => {
    const droneDeployLayer = backgroundMapboxMap.getLayer(customEndpointLayerId)

    // not all CanvasSources have a droneDeployLayer
    if (!droneDeployLayer) return

    const newSourceProps = { type: 'raster', tiles: [canvasSource.tilesetUrl], tileSize: 256 }
    const newLayerProps = { id: droneDeployLayerId, type: 'raster', source: droneDeploySourceId }

    backgroundMapboxMap.addSource(droneDeploySourceId, newSourceProps)
    backgroundMapboxMap.addLayer(newLayerProps, customEndpointLayerId)
    backgroundMapboxMap.removeLayer(customEndpointLayerId)
}

const customEndpointLayerId = 'Custom endpoint layer'
const droneDeployLayerId = 'droneDeployLayer'
const droneDeploySourceId = 'droneDeploySource'

const BackgroundMapboxMap = props => {
    const switchCanvasSource = passedBackgroundMapboxMap => {
        const backgroundMap = passedBackgroundMapboxMap || backgroundMapboxMap
        if (!foregroundMapboxMap || !backgroundMap) return

        ourCanvasId = canvasSource.canvasId // make sure we don't respond to old canvasSources below
        backgroundMap.setStyle(canvasSource.styleUrl || mapboxStyleUrl(styleName))
    }

    // initialize Mapbox
    const startup = () => {
        if (!foregroundMapboxMap) return

        resources().dispatch(ReduxActions.backgroundMapLoadingStateChanged('loading'))
        const mapPosition = ReduxSelectors.mapPosition(getState())
        const ourMap = new mapboxgl.Map({
            container: 'background-map-container',
            style: canvasSource.styleUrl || mapboxStyleUrl(styleName),
            center: mapPosition.center,
            zoom: mapPosition.zoom,
            maxPitch: 0,
        })

        setBackgroundMapboxMap(ourMap)

        const foregroundMapMoved = event => {
            const currentCanvasId = ReduxSelectors.selectedCanvas(getState()).id
            if (currentCanvasId === ourCanvasId && !event.dontSendSelectionChange)
                ourMap.jumpTo({
                    center: foregroundMapboxMap.getCenter(),
                    zoom: foregroundMapboxMap.getZoom(),
                })
        }

        foregroundMapboxMap.on('move', foregroundMapMoved)

        // passing ourMap here is needed, because the setBackgroundMapboxMap was not yet initialized
        ourMap.on('load', () => {
            addResource('backgroundMapboxMap', ourMap)
            Commands2.selectionChanged({ canvasId, context: 'backgroundMapLoaded' })
            resources().dispatch(ReduxActions.backgroundMapLoadingStateChanged('loaded'))
            switchCanvasSource(ourMap)
        })
        ourMap.on('styledata', () =>
            possiblyReplaceDroneDeployLayer(ourMap, ReduxSelectors.selectedCanvasSource(getState()))
        )

        // cleanup on unmount
        return () => {
            foregroundMapboxMap.off('move', foregroundMapMoved)
            ourMap.remove()
        }
    }

    const { mapboxMap: foregroundMapboxMap, styleName = 'streets', className = 'background-map', canvasSource } = props
    const { getState } = useStore()
    const [backgroundMapboxMap, setBackgroundMapboxMap] = React.useState()
    const { canvasId } = useParams()
    const selectedCanvasSource = useSelector(ReduxSelectors.selectedCanvasSource)

    // happens twice; first time foregroundMapboxMap is undefined
    useEffect(() => {
        return startup()
    }, [foregroundMapboxMap])

    useEffect(() => {
        switchCanvasSource()
    }, [selectedCanvasSource])

    if (!foregroundMapboxMap) return null

    const mapboxCanvas = foregroundMapboxMap.getCanvas()

    return (
        <StyledMapboxContainer
            id="background-map-container"
            width={mapboxCanvas.width}
            height={mapboxCanvas.height}
            style={{
                width: mapboxCanvas.style.width,
                height: mapboxCanvas.style.height,
            }}
            className={className}
        />
    )
}

BackgroundMapboxMap.propTypes = {
    styleName: PropTypes.string,
    className: PropTypes.string.isRequired,
}

export default BackgroundMapboxMap
