/*
 * Gesture recognizer and renderer.
 *
 * - Draws PhotoAnnotationRenderers into an HTML canvas
 * - Gesture recognition converts  mouse events into dragstart, drag, dragend, click and doubleclick events
 * - Tracks the selectedRenderer
 *
 * A PhotoAnnotationRenderer needs these functions
 *
 * - render(ctx, renderer, isSelected, isCreatingNewAnnotation, canvasScale)
 * - isHit(ctx, x, y, renderer))
 * - cursorStyle(ctx, x, y, renderer, isSelected, isDragging)
 *
 * Notes
 *
 * The PhotoAnnotationRenderers will change over time, from one call to another, so we need to use the
 * PhotoRenderers' ids rather than PhotoRenderers per se
 */

import React, { forwardRef, useEffect, useState } from 'react'
import PhotoAnnotationRenderer from './photo-annotation-renderer.js'

const LEFT_BUTTON = 1
const DISTANCE = 5 // distance from the mousedown that means we're dragging rather than clicking (in place)
const TIME = 80 // ms delay before giving up waiting for a doubleclick before deciding it's a click

// We store state in canvas.state (in addition to React state); these are the initial values of that state
const initialState = {
    isDragging: false, // are we currently dragging?
    clickCount: 0, // how many times have we seen a mouseup representing a click?
    clickTimeout: undefined, // to track when "too much" time has gone by to be considered a double click
    mouseDownX: 0, // position of the latest mousedown
    mouseDownY: 0, // position of the latest mousedown
    mouseDownTime: 0, // time of the latest mousedown (for helping to separate clicks from drags)
    mouseDownRendererId: undefined, // the renderer that was under the mouse on the last mousedown

    lastX: 0, // to compute dx
    lastY: 0, // to compute dy

    previousEvent: undefined, // debug only: keep the LAST event we sent to check that this one is allowed to follow it
}

const PhotoAnnotationCanvas = forwardRef(
    (
        {
            allowEditing, // if false, just show the existing annotations, but don't allow the user to do anything
            renderers,
            selectedRendererId,
            width,
            height,
            style,
            onDragStart,
            onDrag,
            onDragEnd,
            onClick,
            onDoubleClick,
            currentTool,
        },
        canvasRef
    ) => {
        const rendererWithId = id => renderers.find(r => r.annotation.id === id)

        // The selectedRenderer is drawn differently, but it's drawn by the renderer itself rather than here
        const renderCanvas = () => {
            const renderOne = renderer => {
                const isSelected = renderer.annotation.id === selectedRendererId
                return PhotoAnnotationRenderer.render(ctx, renderer, isSelected, isCreatingNewAnnotation, canvasScale)
            }

            const canvas = canvasRef.current
            if (!canvas) return
            const ctx = canvas.getContext('2d')
            ctx.clearRect(0, 0, canvas.width, canvas.height)

            renderers.forEach(renderOne)
        }

        // same as rendering, but changing the cursor by having each renderer decide its own cursor if it has been "hit"
        const updateCursor = (x, y) => {
            if (!allowEditing) return setCursor('default')

            const cursorForRenderer = () => {
                const isSelected = renderer.annotation.id === selectedRendererId
                return PhotoAnnotationRenderer.cursorStyle(ctx, x, y, renderer, isSelected, isDragging)
            }

            const computeCursor = () => {
                if (renderer) return cursorForRenderer() // if we're over a renderer, let IT decide
                if (currentTool === 'select') return 'default' // special case for select
                return 'crosshair'
            }

            const ctx = canvasRef.current.getContext('2d')
            const renderer = getEventRenderer(x, y)

            setCursor(computeCursor())
        }

        const addListeners = () => {
            const canvas = canvasRef.current
            if (!canvas) return
            if (!allowEditing) return

            canvas.addEventListener('mousedown', mouseDownHandler)
            canvas.addEventListener('mousemove', mouseMoveHandler)
            canvas.addEventListener('mouseup', mouseUpHandler)
        }

        const unmount = () => {
            const canvas = canvasRef.current
            if (!canvas) return

            canvas.removeEventListener('mousedown', mouseDownHandler)
            canvas.removeEventListener('mousemove', mouseMoveHandler)
            canvas.removeEventListener('mouseup', mouseUpHandler)
        }

        // return the renderer that was "hit" by the user on the mousedown, so we can track which renderer to move, etc.
        const getEventRenderer = (x, y) => {
            const ctx = canvasRef.current.getContext('2d')
            return renderers.find(renderer => PhotoAnnotationRenderer.isHit(ctx, x, y, renderer))
        }

        // compute the new x and y, bearing in mind that HTML coordinates have to be converted to Canvas coordinates
        const getCanvasCoordinates = e => {
            const { clientX, clientY } = e

            const rect = canvasRef.current.getBoundingClientRect()
            const scaleX = canvasRef.current.width / rect.width // Ratio of the canvas's drawing buffer width to the CSS width
            const scaleY = canvasRef.current.height / rect.height // Ratio of the canvas's drawing buffer height to the CSS height
            const x = (clientX - rect.left) * scaleX // Scale mouse coordinate x
            const y = (clientY - rect.top) * scaleY // Scale mouse coordinate y
            return { x, y }
        }

        /*
         * It's too early to make any assessment about the user's gesture: we can't tell if they're clicking or dragging
         * Just capture information to be used later
         */
        const mouseDownHandler = e => {
            const canvas = canvasRef.current
            const { state } = canvas

            // compute the new x and y
            const { x, y } = getCanvasCoordinates(e)

            // update the state
            state.mouseDownX = x
            state.mouseDownY = y
            state.mouseDownTime = Date.now()
            state.mouseDownRendererId = getEventRenderer(x, y)?.annotation.id
            state.lastX = x
            state.lastY = y
        }

        /* It's hard to distinguish a click from a drag.
         * Clicks are "fast" and the mouseup is "close" to the mousedown, so we're instead starting a DRAG if:
         *
         * - it has been "a long time" since the mouse down OR
         * - we're "far away" from where the mousedown happened
         */
        const mouseMoveHandler = e => {
            const startADrag = () => {
                state.isDragging = true

                // If we move fast, we can start a drag in the interval while we're waiting to see if a double click
                // will arrive. If we don't cancel the wait, we'll get the 'click' notification while we're in the
                // middle of the next drag. So, we're essentially canceling that click in favor of this drag
                if (state.clickTimeout) {
                    clearTimeout(state.clickTimeout)
                    state.clickTimeout = undefined
                }

                onDragStart?.({ ctx, x, y, renderer: rendererWithId(state.mouseDownRendererId) })
            }

            const continueADrag = () =>
                onDrag?.({ ctx, x, y, dx, dy, renderer: rendererWithId(state.mouseDownRendererId) })

            const canvas = canvasRef.current
            const { state } = canvas
            const ctx = canvas.getContext('2d')
            const { mouseDownTime, mouseDownX, mouseDownY, lastX, lastY, isDragging } = state

            const { x, y } = getCanvasCoordinates(e)
            const dx = x - lastX
            const dy = y - lastY

            state.lastX = x
            state.lastY = y

            updateCursor(x, y)

            // are we starting a drag?
            const wasALongTimeAgo = Date.now() - mouseDownTime > TIME
            const wasFarAway = Math.abs(x - mouseDownX) > DISTANCE || Math.abs(y - mouseDownY) > DISTANCE
            const startingADrag = e.buttons === LEFT_BUTTON && !isDragging && (wasALongTimeAgo || wasFarAway)

            if (startingADrag) startADrag()
            else if (isDragging) continueADrag()
            /* else: just a mouseover */
        }

        // Even on mouseup, we still can't tell if we're done, because we still want to distinguish click from double-click.
        const mouseUpHandler = e => {
            const finishDrag = () => {
                onDragEnd?.({ ctx, x, y, renderer: rendererWithId(state.mouseDownRendererId) })
                state.isDragging = false
            }

            const finishClick = () => {
                onClick?.({ ctx, x, y, renderer: rendererWithId(state.mouseDownRendererId) })
                state.clickCount = 0 // Reset after action is taken
                state.clickTimeout = undefined
            }

            const finishDoubleClick = () => {
                clearTimeout(state.clickTimeout) // can no longer be a click, since we just got the second click
                onDoubleClick?.({ ctx, x, y, renderer: rendererWithId(state.mouseDownRendererId) })
                state.clickCount = 0 // Reset after action is taken
                state.clickTimeout = undefined
            }

            const canvas = canvasRef.current
            const ctx = canvas.getContext('2d')
            const { state } = canvas
            const { x, y } = getCanvasCoordinates(e)

            if (state.isDragging) return finishDrag() // easy case: we're done dragging

            // click or double click? Only time will tell
            state.clickCount++
            if (state.clickCount === 1) state.clickTimeout = setTimeout(finishClick, 200) // wait for possible double-click
            if (state.clickCount === 2) return finishDoubleClick()
        }

        // the data changed (probably the renderers); re iniitalize everything
        const resetState = () => {
            if (!canvasRef.current) return

            canvasRef.current.state = initialState

            renderCanvas()
            addListeners()

            return unmount
        }

        const [cursor, setCursor] = useState('default')

        const isDragging = false
        const isCreatingNewAnnotation = false
        const canvas = canvasRef?.current
        const canvasScale = canvas ? canvas.width / parseFloat(canvas.style.width.replace('px')) : 1

        useEffect(resetState, [renderers, width, height, selectedRendererId, currentTool])

        return (
            <canvas
                id="photo-annotation-canvas"
                ref={canvasRef}
                width={width}
                height={height}
                style={{ cursor, ...style }}
            />
        )
    }
)

export default PhotoAnnotationCanvas
