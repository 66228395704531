/*
 * See Readme
 */

import { FeedItem } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import { deleteComment } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// CommentRemovedCommand
// ---------------------------------------------------------------------------------------------------------------------
const CommentRemovedCommand = taggedSum('CommentRemovedCommand', {
    Inbound: { feedItem: 'FeedItem' },
    Outbound: { isNote: 'Boolean', commentId: StringTypes.Id },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A CommentRemovedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Comment)
 */
const runInboundCommand = (resources, command) => {
    const { dispatch } = resources
    const { feedItem } = command
    const { action, itemType, itemId } = feedItem

    if (itemType !== 'Comment' || action !== 'deleted') return // not for us

    dispatch(ReduxActions.commentRemoved({ id: itemId }))
}

/*
 * A CommentRemovedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { projectId, dispatch, displayError } = resources
    const { commentId, isNote } = command

    try {
        await deleteComment(projectId, commentId)
        dispatch(ReduxActions.commentRemoved(commentId))

        // show the toast
        const toastLabel = `${isNote ? 'Note' : 'Comment'} successfully deleted`
        const toast = { id: 'upload', severity: 'success', toastLabel, showUndo: false }
        dispatch(ReduxActions.toastAdded(toast))
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CommentRemovedCommand,
        CommandPlayer({
            CommandType: CommentRemovedCommand,
            Type: FeedItem,
            collectionPath: (projectId, userId) => `/projects/${projectId}/participants/${userId}/feedItems`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
        })
    )
}

export { CommentRemovedCommand, addCommand }
