/*
 * Project
 *
 * A Project is the top-level container for Range data
 */
import { filterObject, mergeRight, pick, tagged } from '@range.io/functional'

import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Project = tagged('Project', {
    id              : StringTypes.Id,
    identifier      : 'String?', // human-readable number rather than UUID
    name            : 'String',
    projectType     : 'String',
    address         : 'String',
    center          : '[Number]',
    description     : 'String?',
    organizationName: 'String',
    canvasOrder     : '[String]?',
    participants    : 'Object?',
    isArchived      : 'Boolean?',
})

/*
 * Create a new Project merging an old one with values from changes
 * @sig update :: (Project, {k:v}) -> Project
 */
Project.update = (project, changes) => Project.from(mergeRight(project, changes))

Project.participants = (project, includeSuspended) =>
    includeSuspended ? project.participants : filterObject(p => !p.isSuspended, project.participants)

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Create a Project from a JSON object
 * @sig fromFirebase :: {k:v} -> Project
 */
Project.fromFirebase = data => {
    // participants will be set only when initially loading the Project (see loadUserRelatedDataIntoRedux)
    const { participants } = data

    // in other cases, we don't want to override the participants
    return Project.from(participants ? mergeRight(data, { participants }) : data)
}

Project.toFirebase = project =>
    pick(
        [
            'id',
            'name',
            'projectType',
            'address',
            'center',
            'description',
            'organizationName',
            'canvasOrder',
            'isArchived',
        ],
        project
    )

/*
 * The set of projectTypes we allow
 */
// prettier-ignore
Project.projectTypes = [
    { name: 'Architecture',       id : 'ARCHITECTURE_ID'},
    { name: 'Construction',       id : 'CONSTRUCTION_ID'},
    { name: 'Landscaping',        id : 'LANDSCAPING_ID' },
    { name: 'Remodel / Retrofit', id : 'RETROFIT_ID'    },
    { name: 'Other',              id : 'OTHER_ID'       },
]

export default Project
