import { assoc } from '@range.io/functional'
import React, { useState } from 'react'

/*
 * Show multiple child component in turn as the user clicks the next/previous/cancel/save buttons
 * Each child must accept onNext/onPrevious/onCancel/onSave actions as appropriate for its position in the wizard
 * MultipageWizard will add these functions by cloning the child and including them
 *
 * The wizard itself must define onSave and onCancel which will happen when any page calls one of these functions
 */
const MultipageWizard = ({ css = {}, onSave, onCancel, children }) => {
    const [page, setPage] = useState(0)

    const onPrevious = () => setPage(page - 1)
    const onNext = () => setPage(page + 1)

    // add onCancel/onPrevious/onNext/onSave as appropriate for the child's position in the sequence
    const renderChild = () => {
        const child = children[page]
        let props = assoc('onCancel', onCancel, child.props)

        const isLastPage = page === children.length - 1
        const isFirstPage = page === 0

        if (!isFirstPage) props = assoc('onPrevious', onPrevious, props)
        if (!isLastPage) props = assoc('onNext', onNext, props)
        if (isLastPage) props = assoc('onSave', onSave, props)

        return React.cloneElement(child, props)
    }

    return <div style={css}>{renderChild()}</div>
}

export default MultipageWizard
