import React from 'react'
import { FlexColumn, Text, Tooltip } from './index.js'
import { styled } from '../range-theme/index.js'

const StyledContainer = styled(FlexColumn, {
    mt: 'auto',
    gap: 8,
    color: '$neutral04',
    alignItems: 'center',
    height: 'fit-content',
    p: 8,

    variants: {
        variant: {
            admin: {
                fs: 14,
                mb: 24,
                background: '$neutral08',
                b: '1px solid $neutral07',
                br: 12,
            },
            canvas: {
                fs: 12,
                position: 'absolute',
                top: 12,
                right: 12,
                background: '$neutral10',
                b: '1px solid $neutral07',
                br: 6,
            },
        },
    },
})

const StyledImage = styled('img', {
    overflow: 'hidden',
    b: '1px solid $neutral07',

    variants: {
        variant: {
            admin: {
                width: 116,
                height: 116,
                br: 6,
            },
            canvas: {
                width: 84,
                height: 84,
                br: 2,
            },
        },
    },
})

const QrCode = ({ text, src, variant }) => {
    const tooltipSide = variant === 'admin' ? 'top' : 'bottom'

    return (
        <Tooltip tooltipText="Scan to download" side={tooltipSide} align="center">
            <StyledContainer variant={variant}>
                <StyledImage variant={variant} src={src} alt="QR Code" />
                <Text>{text}</Text>
            </StyledContainer>
        </Tooltip>
    )
}

export { QrCode }
