/*
 * SelectArrowMode
 *
 * Special mode to handle direct selection of an arrow annotation.
 * Extends SelectMode.
 *
 * Type SelectArrowModeState:
 *    headFeature : DrawFeature<JsonFeature>
 *    feature     : DrawFeature<JsonFeature>
 */

import bearing from '@turf/bearing'

import SelectMode from './select-mode.js'

const SelectArrowMode = { ...SelectMode }

/*
 * @sig onSetup :: ({baseState: SelectModeState}) -> SelectArrowModeState
 */
SelectArrowMode.onSetup = function (opts) {
    const state = { ...opts.baseState }
    state.headFeature = this.getFeature(state.feature.properties.arrowFeatureId)
    this.setSelected([state.featureId, state.feature.properties.arrowFeatureId])
    return state
}

/*
 * User is dragging a vertex: update arrow head position and rotation accordingly
 * Impure: changes coordinates and rotation of the arrow head feature
 * @sig dragVertex :: (SelectArrowModeState, Event, Number) -> ()
 */
SelectArrowMode.dragVertex = function (state, e, delta) {
    SelectMode.dragVertex.bind(this)(state, e, delta)
    state.headFeature.updateCoordinate('0', ...state.feature.coordinates[1])
    state.headFeature.setProperty('rotation', bearing(state.feature.coordinates[0], state.feature.coordinates[1]))
}

/*
 * User is dragging a feature: update arrow head position accordingly
 * Impure: changes coordinates of the arrow head feature
 * @sig dragFeature :: (SelectArrowModeState, Event, Number) -> ()
 */
SelectArrowMode.dragFeature = function (state, e, delta) {
    SelectMode.dragFeature.bind(this)(state, e, delta)
    state.headFeature.updateCoordinate('0', ...state.feature.coordinates[1])
}

/*
 * @sig toDisplayFeatures (SelectArrowModeState, GeoJsonFeature, DisplayFunc) -> ()
 * DisplayFunc = GeoJsonFeature -> ()
 */
SelectArrowMode.toDisplayFeatures = function (state, geojson, display) {
    if (state.headFeature.id === geojson.properties.id) {
        geojson.properties.active = 'true'
        display(geojson)
    } else {
        SelectMode.toDisplayFeatures.bind(this)(state, geojson, display)
    }
}

export default SelectArrowMode
