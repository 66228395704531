import * as TabsPrimitive from '@radix-ui/react-tabs'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Box, Button, FlexColumn, FlexRow, Icon, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import Tags from './Tags.js'

const ModalContainer = ({ children, ...restProps }) => {
    return (
        <Box
            onClick={e => {
                e.preventDefault()
                e.stopPropagation() // Needed to not bubble click action so overlay click doesn't trigger
            }}
            {...restProps}
        >
            {children}
        </Box>
    )
}

const StyledModalContainer = styled(ModalContainer, {
    bg: '$neutral10Alpha',
    backdropFilter: 'blur(12px) saturate(190%) contrast(50%) brightness(130%)',
    color: '$neutral04',
    width: '500px',
    border: '1px solid $neutral07',
    br: '6px',
    boxShadow: '0px 40px 40px #00000050',
    zIndex: 2,
})

const StyledModalActions = styled(Box, {
    width: '100%',
    height: '72px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '24px',
    paddingLeft: '24px',
    bt: '1px solid $neutral07',
    display: 'flex',
    gap: '16px',
    justifyContent: 'right',
})

const StyledPrimaryModalLabel = styled(Box, {
    fs: '20px',
    fw: 700,
})

const StyledBoxCss = styled(Box, {
    width: 'inherit',
    ml: 24,
    mr: 24,
    mb: 20,
    p: '12px 10px 0px 10px',
    br: 6,
    background: '$neutral09Alpha',
    b: '1px solid $neutral07',
})

const StyledSecondaryModalLabel = styled(Text, {
    fs: '14px',
    color: '$neutral05',
    lh: '1.4',
    p: '0px 24px 16px 24px',
})

const StyledOverlay = styled(FlexColumn, {
    width: '100vw',
    height: '100vh',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$neutralOverlay',
    ai: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 100,
})

const StyledLabels = styled(FlexRow, {
    gap: 8,
    pb: '8px',
    ai: 'center',
})

const StyledIcon = styled(Icon, {
    color: '$neutral04',
    width: '24px',
    height: '24px',
})

const StyledTabs = styled(TabsPrimitive.Root, {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    b: '1px solid $red',
})

const StyledTabsList = styled(TabsPrimitive.List, {
    flexShrink: 0,
    display: 'flex',
    ml: 24,
    mr: 24,
    mb: 16,
    br: 6,
    background: '$neutral09Alpha',
    b: '1px solid $neutral07',
})

const StyledTabsTrigger = styled(TabsPrimitive.Trigger, {
    all: 'unset',
    fontFamily: 'inherit',
    padding: '0 20px',
    height: 32,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 15,
    background: 'transparent',
    cursor: 'pointer',
    lineHeight: 1,
    color: '$neutral05',
    userSelect: 'none',
    transitionDuration: '0.4s',
    gap: 8,
    flexDirection: 'row',
    boxShadow: '0px 0px 0px #8B8F9D20',
    br: 6,
    b: '1px solid transparent',
    '&:first-child': { borderRadius: 6, m: 4 },
    '&:last-child': { borderRadius: 6, m: 4 },
    '&:hover': {
        color: '$neutral04',
        background: '$primary02',
        b: '1px solid $primary03',
        boxShadow: '6px 6px 2px #8B8F9D20',
    },
    '&[data-state="active"]': {
        color: '$neutral04',
        background: '$primary03',
    },
})

const StyledTabsContent = styled(TabsPrimitive.Content, {
    flexGrow: 1,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    outline: 'none',
    b: 'none',
})

/*
 * Manage tags component
 */

const EditTagsModal = ({ onCancel, onConfirm, addTagsDescription, replaceTagsDescription }) => {
    const [selectedItems, setSelectedItems] = useState([])

    const handleConfirm = editType => onConfirm(editType, selectedItems)

    const handleTagsChange = newTags => setSelectedItems(newTags.map(tag => tag.id))

    return (
        <StyledOverlay onClick={onCancel}>
            <StyledModalContainer data-cy="bulk-tag-editor">
                <Box css={{ p: '30px 24px 8px 24px' }}>
                    <StyledLabels>
                        <StyledIcon name="tags" iconSize="24" />
                        <StyledPrimaryModalLabel>Bulk Tag Editor</StyledPrimaryModalLabel>
                    </StyledLabels>
                </Box>
                <StyledTabs defaultValue="add-tags" onValueChange={() => setSelectedItems([])}>
                    <StyledTabsList aria-label="add-or-replace-tags">
                        <StyledTabsTrigger value="add-tags">Add Tags</StyledTabsTrigger>
                        <StyledTabsTrigger value="replace-tags">Replace Tags</StyledTabsTrigger>
                    </StyledTabsList>
                    <StyledTabsContent value="add-tags">
                        <StyledSecondaryModalLabel>{addTagsDescription}</StyledSecondaryModalLabel>
                        <StyledBoxCss>
                            <Tags
                                tags={selectedItems}
                                onTagsChanged={handleTagsChange}
                                inputPlaceholder="Add new tags"
                            />
                        </StyledBoxCss>
                        <StyledModalActions>
                            <Button
                                css={{ height: '40px' }}
                                variant="secondary"
                                size="lg"
                                onClick={onCancel}
                                data-cy="bulk-tag-editor-cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                size="lg"
                                disabled={selectedItems.length === 0}
                                onClick={() => handleConfirm('add')}
                                data-cy="bulk-tag-editor-submit"
                            >
                                Add Tags
                            </Button>
                        </StyledModalActions>
                    </StyledTabsContent>
                    <StyledTabsContent value="replace-tags">
                        <StyledSecondaryModalLabel>{replaceTagsDescription}</StyledSecondaryModalLabel>
                        <StyledBoxCss>
                            <Tags
                                tags={selectedItems}
                                onTagsChanged={handleTagsChange}
                                inputPlaceholder="Replace tags with"
                            />
                        </StyledBoxCss>
                        <StyledModalActions>
                            <Button
                                css={{ height: '40px' }}
                                variant="secondary"
                                size="lg"
                                onClick={onCancel}
                                data-cy="bulk-tag-editor-cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                size="lg"
                                disabled={selectedItems.length === 0}
                                onClick={() => handleConfirm('replace')}
                                data-cy="bulk-tag-editor-submit-replace"
                            >
                                Replace Tags
                            </Button>
                        </StyledModalActions>
                    </StyledTabsContent>
                </StyledTabs>
            </StyledModalContainer>
        </StyledOverlay>
    )
}

EditTagsModal.displayName = 'EditTagsModal'

EditTagsModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onGoToTagManager: PropTypes.func,
    addTagsDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    replaceTagsDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default EditTagsModal
