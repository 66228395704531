import JSZip from 'jszip'

/*
 * Given a Blob object, representing some data, and a filename
 * trigger a browser action to save that data into a file
 * @sig saveAs :: (Blob, String)
 */
const saveAs = (blob, filename) => {
    const objectURL = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = objectURL
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

/*
 * Given a URL and a string trigger browser download mechanism for the image under that URL
 * and save it locally in a file named from the second parameter
 * @sig downloadImage :: (String, String) -> ()
 */
const downloadImage = async (imageSrcUrl, imageName) => {
    const image = await fetch(imageSrcUrl)
    const imageBlob = await image.blob()
    saveAs(imageBlob, imageName)
}

/*
 * Given a filename and an array of names and URLs, use the browser to
 * download them locally and build a zip archive saved in the filesystem
 * @sig downloadAndSaveToZip :: (String, [{name: String, url: String}]
 */
const downloadAndSaveToZip = (filename, namesAndUrls) => {
    if (!namesAndUrls) return

    const zip = new JSZip()
    const folder = zip.folder(filename) // folder name where all files will be placed in

    namesAndUrls.forEach(({ url, name }) => {
        const blobPromise = fetch(url).then(r => {
            if (r.status === 200) return r.blob()
            return Promise.reject(new Error(r.statusText))
        })
        folder.file(name, blobPromise)
    })

    zip.generateAsync({ type: 'blob' }).then(blob => saveAs(blob, filename))
}

// convert byte count to a nice human-readable value
/*
 * Given a byte count return a string with a formatted, human-readable value
 * @sig humanizeByteCount :: (Number) -> String
 */
const humanizeByteCount = bytes => {
    if (bytes === undefined) return undefined
    if (bytes === 0) return '0 Bytes'

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const selectedUnits = Math.floor(Math.log(bytes) / Math.log(1000))
    const amountInSelectedUnits = bytes / Math.pow(1000, selectedUnits)
    return amountInSelectedUnits.toFixed(0) + ' ' + sizes[selectedUnits]
}

/*
 * Merge refs which allows using forwardRef ref and reference local ref at the same time.
 */
const mergeRefs = refs => {
    return node => {
        refs?.forEach(ref => {
            if (typeof ref === 'function') ref(node)
            else if (ref != null) ref.current = node
        })
    }
}

/*
 * Takes a string of characters and calculates what's the width of the input should be to fit it into it.
 * Uses same font styling as the input itself. Returns a string format as a pixel value ('<value>px').
 * @sig getTextWidth :: (String, {fontFamily: String, fontSize: String, lineHeight: String}) -> String
 */
const getTextWidth = (text, inputStyle) => {
    const element = document.createElement('span')
    element.style.fontFamily = inputStyle.fontFamily
    element.style.fontSize = inputStyle.fontSize
    element.style.lineHeight = inputStyle.lineHeight
    element.style.whiteSpace = 'pre' // make sure spaces are preserved
    element.style.display = 'inline-block'
    element.innerHTML = text
    document.body.appendChild(element)
    const textWidth = `${element.offsetWidth + 5}px` // adding some pixels prevents the text from being cut on the left side when typing
    document.body.removeChild(element)
    return textWidth
}

const getImageHeightToWidth = async file =>
    new Promise((resolve, reject) => {
        const fileReader = new FileReader()

        // callback for readAsDataURL below
        fileReader.onerror = reject
        fileReader.onload = function (e) {
            const img = new Image()
            img.onerror = reject
            img.onload = () => resolve(img.height / img.width)
            img.src = fileReader.result
        }

        fileReader.readAsDataURL(file)
    })

const convertSvgToPng = async (dataUriSvg, callback) =>
    new Promise((resolve, reject) => {
        // Step 1: Create an Image element and set the SVG data URI as its source
        const img = new Image()
        img.src = dataUriSvg

        img.onload = function () {
            // Step 2: Draw the loaded image on a Canvas
            const canvas = document.createElement('canvas')

            // enlarge the resulting file so we don't loose quality
            const width = img.width * 2
            const height = img.height * 2

            canvas.width = width
            canvas.height = height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)

            // Step 3: Convert the canvas to a PNG Base64-encoded string
            const pngBase64 = canvas.toDataURL('image/png')

            // Execute the callback function with the PNG Base64-encoded string
            resolve(callback(pngBase64))
        }
        img.onerror = function () {
            console.error('Error loading SVG')
            reject(callback(null))
        }
    })

/**
 * Convert array of tag ids to array of tag {id, name}.
 * @param {Array} tagsIds - array of tag ids
 * @param {Object} tagsLookupTable - tags lookup table
 * @returns tagsObjects - array of {id, name}
 */
const convertIdsToTagsObjects = (tagsIds, tagsLookupTable) => {
    const tagsObjects = []
    tagsIds?.forEach(tag => {
        if (tagsLookupTable[tag]?.id)
            tagsObjects.push({
                id: tagsLookupTable[tag].id,
                name: tagsLookupTable[tag].name,
            })
    })

    return tagsObjects
}

const formatUsd = amount => `$${amount.toFixed(2)} USD`
const BillingCycles = {
    Annual: 'Annual',
    Monthly: 'Monthly',
}

const RangePlans = {
    Free: 'Free',
    Team: 'Team',
    Enterprise: 'Enterprise',
}

// Return computations for display in various modals
const pricingMetrics = (plan, billingCycle, monthlyPrice, annualPrice, seats) => {
    const isDiscounted = billingCycle === BillingCycles.Annual

    if (plan === RangePlans.Free)
        return {
            isDiscounted: false, // gets a discount for Annual billing?
            percentageDiscount: 0, // percentage discount for Annual (number between 0 and 1)
            discount: 0, // dollar amount of Annual discount (if any)
            basePrice: 0, // dollar price without Annual discount,
            finalPrice: 0, // basePrice - discount
            perSeat: 0, // finalPrice / month / seat
        }

    const percentageDiscount = Math.round(((monthlyPrice - annualPrice / 12) / monthlyPrice) * 100)
    const basePrice = isDiscounted ? monthlyPrice * 12 * seats : monthlyPrice * seats
    const finalPrice = isDiscounted ? annualPrice * seats : basePrice
    const discount = basePrice - finalPrice
    const perSeat = isDiscounted ? annualPrice / 12 : monthlyPrice

    return {
        isDiscounted, // gets a discount for Annual billing?
        percentageDiscount, // percentage discount for Annual (number between 0 and 1)
        discount, // dollar amount of Annual discount (if any)
        basePrice, // dollar price without Annual discount,
        finalPrice, // basePrice - discount
        perSeat, // finalPrice / month / seat
    }
}

export {
    downloadAndSaveToZip,
    downloadImage,
    saveAs,
    humanizeByteCount,
    mergeRefs,
    getTextWidth,
    getImageHeightToWidth,
    convertIdsToTagsObjects,
    convertSvgToPng,
    // pricing
    formatUsd,
    pricingMetrics,
    BillingCycles,
    RangePlans,
}
