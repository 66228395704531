import { useEffect, useState, useRef } from 'react'

/*
 * A hook to detect if the user is selecting a checkbox in bulk-select mode,
 * meaning he has Shift key pressed down and other checkbox was clicked right before.
 *
 * @sig useBulkSelectMode :: () -> Boolean
 */
export const useBulkSelectMode = () => {
    const [canBulkSelect, setCanBulkSelect] = useState(false)
    const isLastClickedCheckboxRef = useRef(null)
    const isShiftPressedRef = useRef(false)

    const handleKeyDown = event => {
        if (event.key === 'Shift') {
            isShiftPressedRef.current = true
            if (isLastClickedCheckboxRef.current) {
                setCanBulkSelect(true)
            }
        }
    }

    const handleKeyUp = event => {
        if (event.key === 'Shift') {
            isShiftPressedRef.current = false
            setCanBulkSelect(false)
        }
    }

    const handleDocumentClick = event => {
        // Record if checkbox was clicked as last element
        // we check both type and role, because checkbox could be a button with role='checkbox'
        isLastClickedCheckboxRef.current = event.target.type === 'checkbox' || event.target.role === 'checkbox'
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        document.addEventListener('keyup', handleKeyUp)
        document.addEventListener('click', handleDocumentClick, true)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('keyup', handleKeyUp)
            document.removeEventListener('click', handleDocumentClick, true)
        }
    }, [])

    return canBulkSelect
}

export default useBulkSelectMode
