/*
 * https://stackoverflow.com/questions/996505/lru-cache-implementation-in-javascript
 */

const LRU = maxItemCount => {
    const cache = new Map()

    // Map keeps entries in insert-order, so the first item is the oldest
    const oldestItem = () => cache.keys().next().value

    // By deleting and inserting the item, we move the item from wherever it was in the map's order to most-recent
    const reinsertItemAtEnd = (key, item) => {
        cache.delete(key)
        cache.set(key, item)
    }

    // we're out of room; throw old the oldest and add item
    const replaceOldestItem = (key, item) => {
        cache.delete(oldestItem())
        cache.set(key, item)
    }

    // get the item for the key, if there is one, and update its "recentness"
    const getItem = key => {
        const item = cache.get(key)

        if (item) reinsertItemAtEnd(key, item)

        return item
    }

    // set the item for the key (as most recent), throwing out an old item if necessary
    const setItem = (key, item) => {
        if (cache.has(key)) reinsertItemAtEnd(key, item)
        else if (cache.size === maxItemCount) replaceOldestItem(key, item)
        else cache.set(key, item)
    }

    return {
        getItem,
        setItem,
    }
}

export default LRU
