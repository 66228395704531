/*
 * DrawLineMode
 *
 * Draw mode used to draw a single line segment between two points on the map
 *
 * Type DrawLineModeState:
 *    feature    : DrawFeature<JsonFeature>
 *    startPoint : XYPoint?
 *    endPoint   : XYPoint?
 */

import BasicDrawMode from './basic-draw-mode.js'

const DrawLineMode = { ...BasicDrawMode }

/*
 * @sig onSetup :: {} -> DrawLineModeState
 */
DrawLineMode.onSetup = function () {
    const feature = this.newFeature({
        type: 'Feature',
        geometry: { type: 'LineString', coordinates: [] },
        properties: { annotationType: 'line' },
    })
    this.addFeature(feature)
    this.clearSelectedFeatures()
    this.setActionableState({ trash: true })
    return { feature }
}

/*
 * @sig onTap :: (DrawLineModeState, Event) -> ()
 */
DrawLineMode.onTap = function (state, e) {
    if (state.startPoint) this.onMouseMove(state, e)
    this.onClick(state, e)
}

/*
 * @sig onClick :: (DrawLineModeState, Event) -> ()
 */
DrawLineMode.onClick = function (state, e) {
    const { lng, lat } = e.lngLat
    const isEndPoint = state.startPoint && state.startPoint[0] !== lng && state.startPoint[1] !== lat
    if (isEndPoint) {
        state.feature.updateCoordinate('1', lng, lat)
        this.finishDrawing(state)
    } else state.startPoint = [lng, lat]
}

/*
 * @sig onMouseMove :: (DrawLineModeState, Event) -> ()
 */
DrawLineMode.onMouseMove = function (state, e) {
    if (state.startPoint) {
        state.feature.updateCoordinate('0', ...state.startPoint)
        state.feature.updateCoordinate('1', e.lngLat.lng, e.lngLat.lat)
    }
}

/*
 * @sig onStop :: (DrawLineModeState) -> ()
 */
DrawLineMode.onStop = function (state) {
    if (this.getFeature(state.feature.id) === undefined) return
    if (state.feature.isValid()) this.map.fire('draw.create', { features: [state.feature.toGeoJSON()] })
}

/*
 * @sig toDisplayFeatures (DrawLineModeState, GeoJsonFeature, DisplayFunc) -> ()
 * DisplayFunc = GeoJsonFeature -> ()
 */
DrawLineMode.toDisplayFeatures = function (state, geojson, display) {
    geojson.properties.active = geojson.properties.id === state.feature.id ? 'true' : 'false'
    display(geojson)
}

/*
 * @sig finishDrawing :: (DrawLineModeState) -> ()
 */
DrawLineMode.finishDrawing = function (state) {
    state.endPoint = state.feature.getCoordinates()[1]
    this.changeMode('select', { featureId: state.feature.id })
}

/*
 * @sig cancelDrawingAndDelete :: (DrawLineModeState) -> ()
 */
DrawLineMode.cancelDrawingAndDelete = function (state) {
    this.deleteFeature([state.feature.id])
    this.changeMode('idle')
}

export default DrawLineMode
