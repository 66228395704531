import * as ToastPrimitive from '@radix-ui/react-toast'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '../range-theme/index.js'
import { ReduxActions } from '../redux/index.js'
import { StyledBannerLabel } from './Banner.js'
import Button from './Button.js'

/* ---------------------------------------------------------------------------------------------------------------------
 * Toast
 *
 * Anatomy of @radix-ui/react-toast
 *
 *   <Toast.Provider>                   <== in App.js (context passing for Toast components)
 *       <Toast.Root>                   <== wrapper
 *           <Toast.Title />            <== title
 *           <Toast.Description />      <== details
 *           <Toast.Action />           <== button for, eg. "undo"
 *           <Toast.Close />            <== button for "close"
 *       </Toast.Root>
 *
 *       <Toast.Viewport />             <== in App.js (all Toasts live within this component)
 *   </Toast.Provider>
 *
 *
 * ToastList
 *
 * A Toast-related component of our own devising that lives in App.js and renders all the Toasts.
 * This is *separate* from the Viewport which is a Portal where Radix puts the Toasts rendered by ToastList
 *
 * ToastList exists because we need to add Toasts from anywhere in our hierarchy -- even if the page that
 * initiated the toast will be dismissed before the Toast has closed.
 *
 * For instance, in the Media Details, page you can delete an Upload, which shows a Toast to allow you a few seconds
 * to cancel your delete -- in the rare circumstances you got it wrong.
 *
 * However, because most of the time you really DID want to delete the Upload, the Media Details View should
 * close immediately, even though the Toast might still have several seconds to live
 *
 * For this reason, all Toasts are actually just objects -- stored in Redux -- for the ToastList to render later
 * Radix then *displays* the Toasts rendered in ToastList in the ToastViewport (which is a Portal).
 *
 * Got that?
 *
 * Toast is just the data passed to Toast: severity, toastLabel, duration, onClose, onUndo, css --
 * but it also potentially includes
 *
 * - a Redux Action to perform when the Toast eventually closes
 * - a Redux Action to perform if the user cancels the action
 *
 * In general, a Toast is likely to have an onCloseAction to dispatch to Redux when the timer is done --
 * but unlikely to have any onUndoAction at all, since it would be called only when the user CANCELED the action
 * and there's probably simply nothing to undo
 *
 * Because the Toast includes an undo/cancel button, onClose and onUndo are mutually exclusive:
 *
 * - onUndo  happens if the user presses the undo button
 * - onClose happens after the duration delay
 *
 * It's a little bizarre to store a Redux Action as data INSIDE Redux, but it's just an Object,
 * and it's easy to get the data to ToastList that way. It does make ToastList less general than
 * allowing arbitrary functions -- but arbitrary functions can't be stored in Redux (they're not serializable).
 *
 * ------------------------------------------------------------------------------------------------------------------ */

const StyledToastViewport = styled(ToastPrimitive.Viewport, {
    position: 'fixed',
    bottom: 16,
    left: '50%',
    transform: 'translate(-50%, 0)',
    listStyle: 'none',
    zIndex: 20000,
})

/*
 * Wrapper around ToastPrimitive.Viewport
 *
 * HACK: adds a listener for the pointer leaving the Viewport. Otherwise, the timer never resumes if it's paused
 * (as when you click on one of the Toasts). This is a hack because it uses details of Radix we shouldn't depend on
 */
const ToastViewport = () => {
    const ref = useRef()
    useEffect(() => {
        const resumeTimer = _ => ref?.current?.dispatchEvent(new CustomEvent('toast.viewportResume'))
        ref?.current?.addEventListener('pointerleave', resumeTimer)
    }, [ref])

    return <StyledToastViewport ref={ref} />
}

// ---------------------------------------------------------------------------------------------------------------------
// ToastRoot
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Wrapper for ToastPrimitive.Root
 */
const ToastRoot = styled(ToastPrimitive.Root, {
    borderRadius: 6,
    backgroundColor: '$neutral10',
    mt: '8px',
})

const StyledContents = styled('div', {
    borderRadius: 6,
    padding: '8px 8px 8px 10px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,

    variants: {
        severity: {
            error: {
                backgroundColor: '$red01',
                border: '1px solid $red03',
                color: '$red03',
                '&::before': {
                    content: '🛑',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                },
            },
            success: {
                backgroundColor: '$green01',
                color: '$green03',
                border: '1px solid $green03',
                '&::before': {
                    content: '✅',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                },
            },
            alert: {
                backgroundColor: '$orange01',
                color: '$orange03',
                border: '1px solid $orange03',
                '&::before': {
                    content: '👋',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                },
            },
            progress: {
                backgroundColor: '$primary01',
                color: '$primary04',
                border: '1px solid $primary04',
                '&::before': {
                    content: '⏳',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                },
            },
        },
    },
})

const nop = () => {}

/*
 * Main Toast renderer, instantiating a subset of the available radix-ui elements
 *
 * Includes special handling to prevent onClose being called even in the user has clicked the undo/cancel button
 */
const Toast = ({
    id,
    severity = 'success',
    toastLabel,
    duration = 5000,
    onClose = nop,
    onUndo = nop,
    showUndo = true,
    css = {},
}) => {
    let alreadyUndone = false

    // undo happens before close, but close will ALWAYS happen; alreadyUndone prevents close from calling onClose
    const undoToast = () => {
        alreadyUndone = true
        onUndo(id)
    }

    // close after the Toast has appeared for duration milliseconds
    const closeToast = () => {
        if (alreadyUndone) return
        onClose(id)
    }

    const onPause = () => console.trace('onPause')
    const onResume = () => console.trace('onResume')

    return (
        <ToastRoot
            key={id}
            duration={duration}
            css={css}
            open={true}
            onOpenChange={closeToast}
            onPause={onPause}
            onResume={onResume}
            data-cy="toast"
        >
            <StyledContents severity={severity}>
                <ToastPrimitive.Description>
                    <StyledBannerLabel>{toastLabel}</StyledBannerLabel>
                </ToastPrimitive.Description>
                {showUndo && (
                    <ToastPrimitive.Close alt="Undo" asChild>
                        <Button css={{ ml: 'auto' }} size="sm" variant="outline" onClick={undoToast}>
                            Undo
                        </Button>
                    </ToastPrimitive.Close>
                )}
            </StyledContents>
        </ToastRoot>
    )
}

const ToastList = ({ toastLookupTable }) => {
    const renderToast = props => <Toast key={props.id} onUndo={onUndo} onClose={onClose} {...props} />

    const onClose = id => {
        const toast = toastLookupTable[id]
        if (toast?.onCloseAction) dispatch(toast.onCloseAction)
        dispatch(ReduxActions.toastRemoved(toast))
    }

    const onUndo = id => {
        const toast = toastLookupTable[id]
        if (toast?.onUndoAction) dispatch(toast.onUndoAction)
        dispatch(ReduxActions.toastRemoved(toast))
    }

    const dispatch = useDispatch()
    return Object.values(toastLookupTable).map(renderToast)
}

Toast.propTypes = {
    toastLabel: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['error', 'success', 'alert', 'progress']), // default is 'success'
    duration: PropTypes.number, // defaults to 4000
    onClose: PropTypes.func, // must be specified for a delayed action, but not necessarily for other toasts
    onUndo: PropTypes.func, // undo probably means more like "don't do" so no onUndo is necessary
    onCloseAction: PropTypes.object, // for example: ReduxActions.commentDeleted(comment)
    onUndoAction: PropTypes.object, // (actually can't think of a use for this, exactly, but...)
}

// Facade for underlying Radix pieces
Toast.Provider = ToastPrimitive.Provider
Toast.Viewport = ToastViewport

export { Toast, ToastList }
