/**
 * memoizeResult creates a memoized version of a given function `f`.
 * The memoized function, fPrime, caches the result of `f` and returns
 * the cached result when called with the same parameters.
 * It throws an error if `f` returns anything other than an object, array, or undefined.
 */
const memoizeResult = f => {
    let lastArgs = []
    let lastResult

    // fPrime
    return (...args) => {
        // Compare the new arguments with the last arguments
        if (args.length === lastArgs.length && args.every((val, index) => val === lastArgs[index])) return lastResult

        const result = f(...args)

        // Ensure that the result is a plain object, array, or undefined
        if (!(result === undefined || Array.isArray(result) || typeof result === 'object'))
            throw new Error(`Function ${f.name} must return either an object, an array, or undefined`)

        // Compare the new result with the last result
        if (isResultEqual(result, lastResult)) return lastResult

        // Update the last arguments and result
        lastArgs = args
        lastResult = result
        return result
    }
}

/**
 * Shallow-compares two results (arrays or plain objects) for equality
 * @sig isResultEqual :: (A, A) -> Boolean
 *
 */
const isResultEqual = (result, lastResult) => {
    if (Array.isArray(result) && Array.isArray(lastResult))
        return result.length === lastResult.length && result.every((val, index) => val === lastResult[index])

    if (typeof result === 'object' && typeof lastResult === 'object') {
        const keys = Object.keys(result)
        if (keys.length !== Object.keys(lastResult).length) return false
        return keys.every(key => result[key] === lastResult[key])
    }

    return false
}

export default memoizeResult
