import PropTypes from 'prop-types'
import React from 'react'
import { Box, Button, FlexColumn, FlexRow, Icon, Select, Text } from '../../components-reusable/index.js'
import featureFlags from '../../feature-flags.js'
import { styled } from '../../range-theme/index.js'
import { Organization } from '@range.io/basic-types'
import Link from '../Link.js'

const StyledLeftPanelBox = styled(Box, {
    bg: '$neutral10',
    color: '$neutral09',
    width: '376px',
    flexGrow: 0,
    flexShrink: 0,
    borderRight: '1px solid $neutral07',
    height: '100vh',
})

const StyledLeftPanelTopBox = styled(Box, {
    overflowY: 'auto',
    height: 'calc(100% - 72px)',
})

const StyledLeftPanelTopBoxContent = styled(Box, {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
})

const Select300 = ({ options, value, onValueChange, fullWidth, triggerCss, css, 'data-cy': dataCy }) => (
    <Select
        data-cy={dataCy}
        css={{
            display: 'flex',
            flex: '1 0 auto',
            justifyContent: 'space-between',
            maxWidth: fullWidth ? '100%' : '321px',
            ...css,
        }}
        triggerCss={triggerCss}
        options={options}
        value={value}
        onValueChange={onValueChange}
    />
)
const StyledSelect300 = styled(Select300, {
    lineHeight: '30px',
})

const SelectAdminRole = ({ options, value, onValueChange }) => {
    const css = { width: '414px' }
    return <StyledSelect300 triggerCss={css} options={options} value={value} onValueChange={onValueChange} fullWidth />
}

const SelectOrgOption = ({ options, value, onValueChange, css }) => {
    return <StyledSelect300 css={css} options={options} value={value} onValueChange={onValueChange} fullWidth />
}

const SelectRole = ({ options, value, onValueChange }) => {
    const css = {}
    return <StyledSelect300 triggerCss={css} options={options} value={value} onValueChange={onValueChange} fullWidth />
}

const Label = styled(Text, {
    lineHeight: '1.4',

    variants: {
        variant: {
            title: {
                fs: '20px',
                color: '$neutral04',
                lh: '1.4',
                width: '100%',
                fw: '700',
            },

            heading: {
                fontSize: '16px',
                color: '$neutral04',
                fontWeight: 700,
                marginTop: '8px',
            },

            sheet: {
                fontSize: '14px',
                color: '$neutral04',
                fontWeight: 600,
            },

            sheetInformation: {
                fontSize: '16px',
                color: '$neutral04',
                fontWeight: 600,
            },

            primary: {
                marginTop: '32px',
                fontSize: '14px',
                color: '$neutral04',
                fontWeight: 700,
                marginBottom: 6,
            },

            secondary: {
                fontSize: '12px',
                color: '$neutral05',
            },

            tertiary: {
                fontSize: '14px',
                marginTop: 4,
                color: '$neutral05',
            },

            error: {
                fontSize: '12px',
                color: '$red03',
                marginTop: '6px',
                fontStyle: 'italic',
            },

            address: {
                fontSize: 14,
                display: 'flex',
                marginTop: 0,
                marginBottom: 0,
                alignItems: 'center',
                gap: 4,
            },
        },
    },
})

const StyledPanelActions = styled(Box, {
    height: '72px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '16px',
    paddingLeft: '16px',
    display: 'flex',
    gap: '16px',
    justifyContent: 'right',
    bt: '1px solid $neutral07',
    bg: '$neutral10',
    borderRight: '1px solid $neutral07',
    width: '376px',
    bottom: 0,
})

/*
 * Moderately generic ok/cancel panel
 */
const PanelActions = ({ primaryText, onPrimaryAction, secondaryText, onSecondaryAction, disabled = false }) => {
    const css = { height: '40px', width: '100%' }
    return (
        <StyledPanelActions>
            {onSecondaryAction && (
                <Button css={css} variant="secondary" size="lg" onClick={onSecondaryAction}>
                    <Text>{secondaryText}</Text>
                </Button>
            )}
            <Button disabled={disabled} css={css} variant="primary" size="lg" onClick={onPrimaryAction}>
                <Text>{primaryText}</Text>
            </Button>
        </StyledPanelActions>
    )
}

PanelActions.propTypes = {
    primaryText: PropTypes.string.isRequired,
    onPrimaryAction: PropTypes.func.isRequired,
    secondaryText: PropTypes.string,
    onSecondaryAction: PropTypes.func,
}

const StyledHintBox = styled(FlexColumn, {
    width: '100%',
    height: 'auto',
    backgroundColor: '$neutral09',
    border: '1px solid $neutral07',
    br: 6,
    p: '16px 16px 4px 16px',
})

const StyledHintHeading = styled(Text, {
    fs: '16px',
    color: '$neutral04',
    lh: '1.4',
    width: '100%',
    fw: '700',
})

const StyledHint = styled(Text, {
    fs: '14px',
    color: '$neutral04',
    lh: '1.4',
    width: '100%',
    fw: '500',
})

const StyledAddressRow = styled(FlexRow, {
    padding: '8px 12px 8px 8px',
    background: '$neutral08',
    border: '1px solid $neutral07',
    borderRadius: 6,
    mt: 12,
    alignItems: 'center',
})

const AdminMenuButtonSupportStyling = styled('button', {
    height: '40px',
    transitionDuration: '0.4s',
    color: '$white',
    background: 'linear-gradient(20deg, #6562FE, #EF516D)',
    display: 'flex',
    flexDirection: 'row',
    ai: 'center',
    cursor: 'pointer',
    br: '100px',
    fs: '14px',
    textDecoration: 'none',
    border: 'none',
    outline: 'none',

    variants: {
        variant: {
            adminNav: {
                justifyContent: 'left',
                paddingLeft: 13,
                w: '100%',
                mb: 12,
                gap: 10,
            },
            canvasNav: {
                justifyContent: 'center',
                paddingLeft: 0,
                pr: 0,
                pl: 0,
                gap: 8,
                w: '133.5px',
            },
            emptyProjects: {
                justifyContent: 'center',
                paddingLeft: 0,
                pr: 16,
                pl: 12,
                gap: 8,
                w: 'content-fit',
            },
        },
    },

    boxShadow: '0px 0px 0px #5D81FF50',

    '&:hover': {
        boxShadow: '0px 0px 16px #EF516D75',
    },

    '&:active': {
        boxShadow: 'none',
    },
})

const AdminMenuButtonSupport = ({ buttonText, variant }) => {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noreferrer')
    }

    const vimeoUrl = featureFlags().ran1564VimeoUrl

    return (
        <AdminMenuButtonSupportStyling variant={variant} onClick={() => openInNewTab(vimeoUrl)}>
            <Icon iconSize="18px" name="video" />
            <Text>{buttonText}</Text>
        </AdminMenuButtonSupportStyling>
    )
}

const AdminMenuButtonFeedback = ({ buttonText, variant }) => {
    return (
        <Link href="mailto:hello@range.io" css={{ width: '133.5px' }} variant={variant}>
            <Icon iconSize="18px" name="support" />
            <Text>{buttonText}</Text>
        </Link>
    )
}

const useOrganizationSizeDropdownOptions = () => {
    const organizationSizesObject = Organization.organizationSizes.reduce(
        (ac, a, index) => ({
            ...ac,
            [index]: {
                id: a,
                name: a,
            },
        }),
        {}
    )

    return Object.values(organizationSizesObject)
}

export {
    AdminMenuButtonFeedback,
    AdminMenuButtonSupport,
    Label,
    Select300,
    SelectAdminRole,
    SelectOrgOption,
    SelectRole,
    useOrganizationSizeDropdownOptions,
    StyledLeftPanelBox,
    StyledLeftPanelTopBox,
    StyledLeftPanelTopBoxContent,
    PanelActions,
    StyledHintBox,
    StyledHint,
    StyledHintHeading,
    StyledAddressRow,
}
