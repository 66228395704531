import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '../range-theme/index.js'

import { Icon } from '../components-reusable/index.js'

const StyledLogoText = styled(Icon, {
    maxWidth: '110px',
    width: '100%',
    height: 'auto',
    color: '$neutral04',
    pt: '12px',
})

const LogoText = props => {
    const { width, height, maxWidth, maxHeight, ...otherProps } = props

    return <StyledLogoText name="rangeText" css={{ width, height, maxWidth, maxHeight }} {...otherProps} />
}

LogoText.displayName = 'LogoText'
LogoText.defaultProps = {}

LogoText.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string,
}

export default LogoText
