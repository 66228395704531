/*
 * A User has changed in some way.
 * This could be just their name or their entire collection of permissions.
 * To be safe, we reload the user's Organizations and Project when the User is added (probably overkill)
 *
 * As a special case, a user can be in the middle of editing a Project they no longer have permission for,
 * in which case we have to eject them back to the dashboard
 *
 */

import { Invitation, Organization, Participant, Project, User } from '@range.io/basic-types'
import { arrayToLookupTable, assocPath, mergeRight, pick, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import { loadItemAndParticipants, loadItemsFromFirestoreCollection } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// UserAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const UserAddedCommand = taggedSum('UserAddedCommand', {
    Inbound: { user: 'User' },
})

/*
 * HACK
 * When we update a User's data (firstName, lastName, email, timezone), there is a delay before
 * the corresponding participant objects are updated, because they're handled by the syncUser Firebase Function
 * at some time AFTER the user has been updated.
 *
 * The right way to fix this is to make syncUser a callable function, and update the user and participants
 * at the same time.
 *
 * Until then, this function will copy the user's fields into the related participants' fields on the theory that
 * inconsistencies between a user and their participant objects should always be resolved in favor of the user
 * "Fixes" ran-2692
 *
 * @sig  copyUserIntoUsersParticipants :: (Type, User) -> Organization|Project -> Organization|Project
 */
const copyUserIntoUsersParticipants = (Type, user) => o => {
    const fromUser = pick(['firstName', 'lastName', 'email', 'timezone'], user)
    const participant = Participant.from(mergeRight(o.participants[user.id], fromUser))
    return Type.from(assocPath(['participants', user.id], participant, o))
}

/*
 * Because this data is for the given user, it's correct to completely overwrite the organizations and projects
 * @sig reloadUsersPermissions :: User-> Promise { organizations: {k:v}, projects: {k:v} }
 *
 */
const reloadUsersPermissions = async user => {
    const loadProject = id => loadItemAndParticipants(Project, `/projects/${id}`)
    const loadOrganization = id => loadItemAndParticipants(Organization, `/organizations/${id}`)

    const organizationPromises = user.permissions.organizations.map(loadOrganization)
    let organizations = await Promise.all(organizationPromises)

    const projectPromises = user.permissions.projects.map(loadProject)
    let projects = await Promise.all(projectPromises)

    // hack: copy data from the User into the corresponding participant objects
    organizations = organizations.map(copyUserIntoUsersParticipants(Organization, user))
    projects = projects.map(copyUserIntoUsersParticipants(Project, user))

    return { organizations: arrayToLookupTable('id', organizations), projects: arrayToLookupTable('id', projects) }
}

/*
 * A UserAddedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the User)
 *
 */
const runInboundCommand = async (resources, command) => {
    const { dispatch, getState, projectId, resourceRemoved } = resources
    const { user } = command

    // permission for the Project was probably revoked (eg. because it was deleted, or the user's permissions changed)
    // resourceRemoved trigger the project and its Firebase listeners to be removed
    // and cause App.ProjectRoute to reroute to the Projects page
    if (projectId && !user.permissions.projects.includes(projectId)) {
        resourceRemoved('projectId') // remove all the Commands' listeners
        dispatch(ReduxActions.projectRemoved(projectId))
    }

    // Ensure we get invitations for that user, as collection is not pulled in the addListenerForDocumentModifications
    const invitations = await loadItemsFromFirestoreCollection(Invitation, `/users/${user.id}/invitations`)
    const newUser = User.addInvitations(user, invitations)

    dispatch(ReduxActions.userAdded(newUser))

    // during the initial load, this data has already been collected for this user
    if (ReduxSelectors.isLoadingInitialData(getState())) return

    const { organizations, projects } = await reloadUsersPermissions(newUser)
    dispatch(ReduxActions.organizationsAndProjectsWereChanged({ organizations, projects }))
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        UserAddedCommand,
        CommandPlayer({
            CommandType: UserAddedCommand,
            Type: User,
            documentPath: userId => `/users/${userId}`,
            runInboundCommand,
            addCommandToHistory,
            changeType: 'added',
            resourceKey: 'userId',
        })
    )
}

export { UserAddedCommand, addCommand }
