const adjectives = [
    'outgoing',
    'few',
    'noisy',
    'flagrant',
    'slow',
    'possessive',
    'various',
    'tall',
    'habitual',
    'modern',
    'magical',
    'misty',
    'cuddly',
    'energetic',
    'tough',
    'tearful',
    'deafening',
    'ludicrous',
    'condemned',
    'needless',
    'chivalrous',
    'luxuriant',
    'sordid',
    'regular',
    'heavy',
    'tense',
    'murky',
    'remarkable',
    'absent',
    'ambitious',
    'pumped',
    'old',
    'immense',
    'ratty',
    'instinctive',
    'plain',
    'tricky',
    'substantial',
    'homeless',
    'furry',
    'productive',
    'axiomatic',
    'filthy',
    'embarrassed',
    'frantic',
    'smoggy',
    'wise',
    'bewildered',
    'tacit',
    'supreme',
]

const adverbs = [
    'literally',
    'bashfully',
    'deftly',
    'faithfully',
    'kindheartedly',
    'scarily',
    'vastly',
    'heavily',
    'defiantly',
    'often',
    'silently',
    'wildly',
]

const nouns = [
    'kittens',
    'crow',
    'gold',
    'sofa',
    'sidewalk',
    'club',
    'lamp',
    'doll',
    'shock',
    'industry',
    'toothpaste',
    'creator',
    'snakes',
    'cars',
    'wax',
    'touch',
    'cake',
    'slip',
    'alarm',
    'cart',
    'brother',
    'star',
    'straw',
    'anger',
    'trains',
    'flag',
    'mask',
    'government',
    'direction',
    'string',
    'bulb',
    'brass',
    'station',
    'hose',
    'nest',
    'railway',
    'sticks',
    'health',
    'beef',
    'collar',
    'cactus',
    'back',
    'ship',
    'rainstorm',
    'curtain',
    'jeans',
    'coach',
    'approval',
    'work',
    'pigs',
]

const randomAdjective = () => adjectives[Math.floor(Math.random() * adjectives.length)]
const randomAdverb = () => adverbs[Math.floor(Math.random() * adverbs.length)]
const randomNoun = () => nouns[Math.floor(Math.random() * nouns.length)]

const phrase = () => `${randomAdverb()} ${randomAdjective()} ${randomNoun()} ${randomNoun()}`

export default phrase
