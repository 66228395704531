import React, { forwardRef, useEffect, useState } from 'react'
import { useKeyMaps } from '../components-reusable/hooks/index.js'
import RangeColors from '../range-theme/range-colors.js'

const isWhite = color => color === RangeColors.White || color.toUpperCase() === '#FFFFFF'

/*
 * Wraps an HTML input that is placed in the right spot to exactly cover a PhotoAnnotation.Text so it can be edited
 * It's tricky to get the HTML element to land exactly on top of the Canvas element that's being edited, because:
 *
 * - the rendering engines for HTML input and Canvas text are slightly different
 * - the Canvas position has to be scaled so that the HTML input goes in the right spot and at the right size
 *
 * The component also handles:
 *
 * - using 'escape' to cancel and edit
 * - using 'enter' to accept an edit
 * - using a mouse click outside the input to accept an input
 *
 * The text is reported ONLY when the user has "finished editing" in one of these ways.
 */
const PhotoAnnotationTextEditor = forwardRef(
    ({ canvasRef, x, y, initialText, fontSize, textColor, onFinishedEditing }, inputRef) => {
        // Locate and size our input, translating from our Canvas coordinates to HTML coordinates
        const getMetrics = text => {
            // get raw font metrics for the text from the canvis
            const canvas = canvasRef.current
            const ctx = canvas.getContext('2d')
            ctx.font = `bold ${fontSize}px Inter`
            const metrics = ctx.measureText(text)

            // compute the scale from Canvas coordinates to HTML coordinates
            const htmlRect = canvas.getBoundingClientRect()
            const scaleX = canvas.width / htmlRect.width
            const scaleY = canvas.height / htmlRect.height

            // compute the padding as we did in PhotoAnnotationRenderer
            // left has to be offset by the same amount we offset it in PhotoAnnotationRenderer
            // width has to include the horizontal padding on both sides
            const scaledFontSize = fontSize / scaleX
            const horizontalPadding = scaledFontSize / 5.0
            const verticalPadding = scaledFontSize / 30.0
            const padding = `${verticalPadding}px ${horizontalPadding}px`
            const left = htmlRect.left + x / scaleX - horizontalPadding
            const width = metrics.width / scaleX + 2 * horizontalPadding

            // 'top' has to be offset by the ascent because HTML inputs are draw from the top-left,
            // but Canvas2D draws from the text's baseline
            const top = htmlRect.top + y / scaleY - metrics.fontBoundingBoxAscent / scaleY - verticalPadding

            return { top, left, width, scaledFontSize, padding }
        }

        /*
         * Set the HTML input's position and style based on the Canvas element's position, the font and the initial text
         */
        const computeInputStyle = () => {
            // compute the input's width from the text plus an extra character to guarantee enough space
            // compute the input's minWidth using an example string ('ooo')
            const { width, left, top, scaledFontSize, padding } = getMetrics(initialText + ' ')
            const { width: minWidth } = getMetrics('ooo')

            return {
                position: 'absolute',
                fontWeight: 'bold',
                border: 'none',
                background: isWhite(textColor) ? RangeColors.Black : RangeColors.White,
                color: textColor,
                borderRadius: 6,
                outline: '2px solid #5D81FF',
                outlineOffset: '4px',
                lineHeight: 1,
                zIndex: 1000,

                // computed from metrics
                fontSize: scaledFontSize,
                top,
                left,
                width,
                minWidth,
                padding,

                // shadow properties
                boxShadow: '0px 2px 8px rgba(0, 0, 0, .25)', // shadow for white area
            }
        }

        // Update the saved text and then recompute the width of the input based on its new contents
        const onTextChange = event => {
            event.preventDefault()
            const value = event.target.value
            setText(value)

            const input = inputRef.current
            const { width } = getMetrics(value + ' ')
            input.style.width = `${width}px`
        }

        // commit the result
        const finishEditing = (newValue, event) => {
            event.preventDefault()
            onFinishedEditing(newValue)
        }

        // clicking outside our input commits the result
        const handleClickOutside = event => {
            event.stopPropagation() // don't send the mousedown to "bubble" listeners
            const input = inputRef.current

            // A click will (sometimes) trigger a "commit" for this PhotoAnnotationTextEditor, but the click has to be on
            // either our canvas or our image. A click elsewhere will NOT commit the edit, mostly so we can use the color
            // and size buttons while editing
            const clickWasOutside = event.target.id === 'photo-annotation-image' || event.target === canvasRef.current

            if (clickWasOutside) finishEditing(input.value, event)
        }

        const [text, setText] = useState(initialText)
        const { pushKeyMap, popKeyMap } = useKeyMaps()

        useEffect(() => {
            const input = inputRef.current
            input.focus() // make sure the cursor shows in the input

            // mouseup outside our input acts as "commit"; by using mouse UP rather than down, we prevent the same mousedown
            // from also creating ANOTHER text annotation, because we can't get the mousedown|mouseover|mouseup
            // sequence that counts as a click (or drag) which the user normally uses to create another text annotation
            document.addEventListener('mouseup', handleClickOutside, true) // 'true' to use capture (not bubble) phase

            // handle keyboard events: enter commits; escape rolls back
            pushKeyMap('PhotoAnnotationTextEditor', {
                Enter: event => finishEditing(event.target.value, event),
                Escape: event => finishEditing(initialText, event),
                Backspace: () => {}, // prevent this backspace from deleting the entire Text annotation when we're done
            })

            // undo listeners on unmount
            return () => {
                popKeyMap('PhotoAnnotationTextEditor')
                document.removeEventListener('mouseup', handleClickOutside, true)
            }
        }, [])

        const style = computeInputStyle()
        return <input data-cy="text-annotation" ref={inputRef} value={text} onChange={onTextChange} style={style} />
    }
)

export default PhotoAnnotationTextEditor
