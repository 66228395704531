/*
 * Convert a 6-character RRGGBB hex string representing a color to HSL
 * 0 <= h <= 360  degrees of color wheel
 * 0 <= s <= 100  percentage
 * 0 <= l <= 100  percentage
 *
 * @sig hexToHsl :: String -> { h: Number, s: Number, l: Number }
 * @see https://gist.github.com/xenozauros/f6e185c8de2a04cdfecf
 */
const hexToHsl = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    const r = parseInt(result[1], 16) / 255
    const g = parseInt(result[2], 16) / 255
    const b = parseInt(result[3], 16) / 255

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)

    const l = (max + min) / 2

    if (max === min) return { h: 0, s: 0, l: l * 100 }

    let h
    const d = max - min
    const s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
        case r:
            h = (g - b) / d + (g < b ? 6 : 0)
            break
        case g:
            h = (b - r) / d + 2
            break
        case b:
            h = (r - g) / d + 4
            break
    }

    h /= 6

    return { h: h * 360, s: s * 100, l: l * 100 }
}

export default hexToHsl
