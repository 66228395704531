import { tagged } from '@range.io/functional'

import StringTypes from '../string-types.js'

const StatusCount = tagged('StatusCount', {
    id: StringTypes.Id,
    name: 'String',
    color: StringTypes.Color,
    count: 'Number',
})

// prettier-ignore
const ProjectMetrics = tagged('ProjectMetrics', {
    projectId   : StringTypes.Id,
    statusCounts: '[StatusCount]'
})

export { StatusCount }
export default ProjectMetrics
