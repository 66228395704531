/*
 * Tools to generalize manipulating the Redux State
 *
 * Types
 *
 *  Item = Collaboration|Comment|Feature|Update|Presence, etc.
 */
import {
    Canvas,
    CanvasSource,
    Collaboration,
    Comment,
    Feature,
    Geometry,
    Invitation,
    MediaViewFilterSettings,
    NavigatorFilterSettings,
    NavigatorSortSettings,
    Organization,
    Participant,
    Presence,
    Project,
    StatusName,
    TagName,
    TaskListFilterSettings,
    Update,
    Upload,
    User,
} from '@range.io/basic-types'
import ProjectMetrics from '@range.io/basic-types/src/core/project-metrics.js'
import * as F from '@range.io/functional'
import {
    arrayToLookupTable,
    assoc,
    assocPath,
    assocPathIfDifferent,
    dissoc,
    dissocPath,
    equals,
    filterObject,
    mapObject,
    mergeRight,
    path,
    pluck,
    reduce,
} from '@range.io/functional'
import { uploadFile } from '../../firebase/firebase-facade.js'
import { ReduxSelectors } from '../index.js'

/*
 * An Item was added
 * @sig itemAdded :: String -> (state, { payload: Item }) -> state
 * Note returns the original state if the value hasn't changed
 */
const itemAdded = field => (state, action) => {
    const newItem = action.payload
    const oldItem = state[field]?.[newItem.id]
    return equals(oldItem, newItem) ? state : F.assocPath([field, action.payload.id], action.payload, state)
}

/*
 * Multiple Items were added
 * convert the new items to an object, clone the old items and merge in the new ones
 * @sig itemsAdded :: String -> (state, { payload: [Item] }) -> state
 * Note returns the original state if the value hasn't changed
 */
const itemsAdded = field => (state, action) => {
    const { payload: items } = action

    const newItemsAsLookupTable = arrayToLookupTable(g => g.id, items)
    const newObject = F.mergeRight(state[field], newItemsAsLookupTable)

    return equals(state[field], newObject) ? state : F.assoc(field, newObject, state)
}

/*
 * An Item was removed
 * @sig itemRemoved :: String -> (state, { payload: Item|Id }) -> state
 * Note returns the original state if the value hasn't changed
 */
const itemRemoved = field => (state, action) => {
    const { payload } = action
    const id = typeof payload === 'string' ? payload : payload.id
    return state[field][id] ? F.dissocPath([field, id], state) : state
}

// map between the name of the key and the type that fills it
const _fieldToType = {
    canvases: Canvas,
    canvasSources: CanvasSource,
    collaborations: Collaboration,
    comments: Comment,
    features: Feature,
    geometries: Geometry,
    invitations: Invitation,
    organizations: Organization,
    presences: Presence,
    projects: Project,
    tagNames: TagName,
    updates: Update,
    uploads: Upload,
}

/*
 * Return the Item with the given id
 * @ itemForId :: (String, state, Id) -> Item
 */
const itemForId = (field, state, id) => state[field][id]

/*
 * An Item changed
 * @sig itemChanged :: String -> (state, { payload }) -> state
 *  Payload = { id: Id, changes: {k:v} }
 * Note returns the original state if the value hasn't changed
 */
const itemChanged = field => {
    const type = _fieldToType[field]
    const update = type.update

    return (state, action) => {
        const { id, changes } = action.payload
        const oldItem = itemForId(field, state, id)

        if (!oldItem) {
            const name = type.name
            const keys = Object.keys(changes)
            const message = `When trying to change existing ${name} with id '${id}' with changed keys for [${keys}], the existing ${name} was not found`
            console.error(message)
            return state
        }

        const newItem = update(oldItem, changes)
        return F.equals(oldItem, newItem) ? state : F.assocPath([field, id], newItem, state)
    }
}

/*
 * An Item was selected, set the field to Item's id; similar to (for example) selectedFeatureId = item.id
 * @sig itemSelected :: String -> (state, { payload: Item }) -> state
 * Note returns the original state if the value hasn't changed
 */
const itemSelected = field => (state, action) =>
    state[field] === action.payload.id ? state : F.assoc(field, action.payload.id, state)

/*
 * Set a field of the state to the given value: eg: fieldSetToConstant('selectedGeometryIds', [])
 * @sig fieldSetToConstant :: (String, *) -> state -> state
 * Note returns the original state if the value hasn't changed
 */
const fieldSetToConstant = (field, value) => state => equals(state[field], value) ? state : F.assoc(field, value, state)

/*
 * Set a field of the state to the action's payload field:
 * eg: fieldSetFromPayload('selectedGeometryIds')(state, { payload: ['id1', ...] })
 *
 * @sig fieldSetFromPayload :: String -> (state, { payload }) -> state
 * Note returns the original state if the value hasn't changed
 */
const fieldSetFromPayload = field => (state, action) => {
    const { payload } = action
    return equals(state[field], payload) ? state : F.assoc(field, payload, state)
}

/*
 * Set a field of the state from the value of ONE of the action's payload's fields:
 *
 * eg: fieldSetFromPayloadKey('cursor', 'cursorName')(state, { payload: { cursorName: 'select' }})
 *      state.cursor = action.payload.cursorName
 *
 * If payloadKey is not specified, use the same name as the field:
 *
 * eg: fieldSetFromPayloadKey('cursor')(state, { payload: { cursor: 'select' }})
 *      state.cursor = action.payload.cursor
 *
 * @sig fieldSetFromPayloadKey :: (String, String) -> (state, { payload: { k:v }}) -> state
 * Note returns the original state if the value hasn't changed
 */
const fieldSetFromPayloadKey = (field, payloadKey) => {
    payloadKey = payloadKey || field
    return (state, action) => {
        const { payload } = action
        const value = payload[payloadKey]
        return equals(state[field], value) ? state : F.assoc(field, value, state)
    }
}

// Firebase knows if the user is logged in or not, but App gets drawn before Firebase gets called,
// so we need a temporary third state "unknown"
export const LoggedInStatus = {
    unknown: 'unknown',
    loggedIn: 'loggedIn',
    loggedOut: 'loggedOut',
    awaitingEmailVerification: 'awaitingEmailVerification',
}

// ---------------------------------------------------------------------------------------------------------------------
// Initial state/state shape
// ---------------------------------------------------------------------------------------------------------------------
const initialState = {
    // current selections
    selectedCanvas: undefined,
    selectedCanvasSource: undefined,
    selectedTool: 'idle', // MapboxDraw mode
    drawingMode: 'idle',
    showTimelineDetails: false,
    cursor: 'select',
    selectedCollaborationId: undefined,
    selectedFeatureId: undefined,
    selectedGeometryIds: [], // MapboxDraw-selected features (which are GeoJSON Features, not Range Features)
    selectedOrganizationId: undefined,
    selectedProjectId: undefined,
    selectedMediaUploadsIds: [],
    user: undefined,
    selectedUserId: undefined, // id of user just above
    mediaViewFilterSettings: {},
    navigatorFilterSettings: {},
    navigatorSortSettings: NavigatorSortSettings.defaultSortSettings(),
    taskListFilterSettings: {},
    currentlyLoadingProject: undefined,
    globalModalData: {},
    shouldFocusCollabWindow: false,

    mapPositionsHistory: {},

    toasts: {}, // currently-display toasts awaiting undo or close
    showAccountCreatedToast: false,

    // independent of a specific project
    organizations: {},
    projects: {},

    // per-organization data
    invitations: {},

    // per-project data
    canvases: {},
    canvasSources: {},
    collaborations: {},
    comments: {},
    features: {},
    geometries: {}, // forcibly kept in sync with MapboxGL Draw features
    presences: {},
    statusNames: {},
    tagNames: {},
    updates: {},
    uploads: {},

    // loading
    pdfLoadingState: 'not loading',
    backgroundMapLoadingState: 'not loading',
    loggedInStatus: LoggedInStatus.unknown,

    // project metrics
    projectMetrics: {},
    rangeStatus: {},
    uploadingFiles: {},

    knockUserToken: '',
    notificationPreferences: { channel_types: { email: true } },
}

// ---------------------------------------------------------------------------------------------------------------------
// Helpers
// ---------------------------------------------------------------------------------------------------------------------

// Return ALL the items as an array
const _collaborationsAsArray = state => Object.values(state.collaborations)
const _commentsAsArray = state => Object.values(state.comments)
const _featuresAsArray = state => Object.values(state.features)
const _updatesAsArray = state => Object.values(state.updates)
const _uploadsAsArray = state => Object.values(state.uploads)

/*
 * Return the first Feature with the given Geometry's id
 * @sig _featureForGeometry :: (state, Id) -> Feature|undefined
 */
const _featureForGeometry = (state, geometryId) => _featuresAsArray(state).find(f => f.geometryId === geometryId)

const _featuresForGeometry = (state, id) => _featuresAsArray(state).filter(f => f.geometryId === id)
const _collaborationsForFeature = (state, id) => _collaborationsAsArray(state).filter(c => c.feature === id)
const _uploadsForCollaboration = (state, id) => _uploadsAsArray(state).filter(u => u.parentId === id)
const _commentsForCollaboration = (state, id) => _commentsAsArray(state).filter(u => u.collaboration === id)
const _updatesForCollaboration = (state, id) => _updatesAsArray(state).filter(u => u.parentId === id)

/*
 * Reset the selected Geometries, Feature and Collaboration
 */
const unsetSelection = state =>
    F.mergeRight(state, {
        selectedCollaborationId: undefined,
        selectedFeatureId: undefined,
        selectedGeometryIds: [],
    })

// ---------------------------------------------------------------------------------------------------------------------
// Geometry Actions
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Delete Collaborations and their subordinate Features
 */
const _collaborationsDeleted = (state, collaborationIds) => {
    const comments = collaborationIds.flatMap(id => _commentsForCollaboration(state, id))
    const uploads = collaborationIds.flatMap(id => _uploadsForCollaboration(state, id))
    const updates = collaborationIds.flatMap(id => _updatesForCollaboration(state, id))

    const commentIds = pluck('id', comments)
    const uploadIds = pluck('id', uploads)
    const updateIds = pluck('id', updates)

    // the selectedCollaborationId can no longer include any of the collaborationIds
    let { selectedCollaborationId } = state
    selectedCollaborationId = collaborationIds.includes(state.selectedCollaborationId)
        ? undefined
        : selectedCollaborationId

    return F.mergeRight(state, {
        collaborations: F.omit(collaborationIds, state.collaborations),
        comments: F.omit(commentIds, state.comments),
        uploads: F.omit(uploadIds, state.uploads),
        updates: F.omit(updateIds, state.updates),
        selectedCollaborationId,
        selectedMediaUploadsIds: F.without(uploadIds, state.selectedMediaUploadsIds),
    })
}

/*
 * Delete Features and their subordinate Features
 */
const _featuresDeleted = (state, featureIds) => {
    const collaborations = featureIds.flatMap(id => _collaborationsForFeature(state, id))
    state = _collaborationsDeleted(state, pluck('id', collaborations))

    // the selectedFeatureId can no longer include any of the featureIds
    let { selectedFeatureId } = state
    selectedFeatureId = featureIds.includes(state.selectedFeatureId) ? undefined : selectedFeatureId

    return F.mergeRight(state, {
        features: F.omit(featureIds, state.features),
        selectedFeatureId,
    })
}

/*
 * Delete geometries and their subordinate Features
 */
const _geometriesDeleted = (state, geometryIds) => {
    const features = geometryIds.flatMap(id => _featuresForGeometry(state, id))
    state = _featuresDeleted(state, pluck('id', features))

    // the selectedGeometryIds can no longer include any of 'geometryIds'
    let { selectedGeometryIds } = state
    selectedGeometryIds = F.without(geometryIds, selectedGeometryIds)

    // remove the geometries themselves
    return F.mergeRight(state, {
        geometries: F.omit(geometryIds, state.geometries),
        selectedGeometryIds,
    })
}

/*
 * Add a User to the state -- along with the Organizations and Projects they are allowed to see
 * @sig userRelatedDataLoaded :: (State, UserRelatedData) -> State
 *  UserRelatedData = { user: User, organizations: [Organization], projects: [Project] }
 */
const userRelatedDataLoaded = (state, { payload }) => {
    const { user, organizations, projects } = payload

    // old user has permissions; new one has invitations
    state = F.assoc('isLoadingInitialData', false, state)
    state = F.assoc('user', User.from(mergeRight(state.user || {}, user)), state)
    state = F.assoc('selectedUserId', user.id, state)
    state = F.assoc('organizations', arrayToLookupTable('id', organizations), state)
    state = F.assoc('projects', arrayToLookupTable('id', projects), state)

    return state
}

const mapMoved = (state, action) => {
    const { lng, lat, zoom } = action.payload

    const position = { center: { lng, lat }, zoom }
    state = F.assocPath(['mapPositionsHistory', state.selectedCanvas], position, state)
    return state
}

// prettier-ignore
const toolToState = {
    idle        : { drawingMode: 'idle'             , cursor: 'select'   , selectedTool: 'none'     },
    select      : { drawingMode: 'select'           , cursor: 'select'   , selectedTool: 'none'     },
    line        : { drawingMode: 'draw_line'        , cursor: 'crosshair', selectedTool: 'line'     },
    polyline    : { drawingMode: 'draw_polyline'    , cursor: 'crosshair', selectedTool: 'polyline' },
    arrow       : { drawingMode: 'draw_arrow'       , cursor: 'crosshair', selectedTool: 'arrow'    },
    polygon     : { drawingMode: 'draw_polygon'     , cursor: 'crosshair', selectedTool: 'polygon'  },
    rectangle   : { drawingMode: 'draw_rectangle'   , cursor: 'crosshair', selectedTool: 'rectangle'},
    point       : { drawingMode: 'draw_marker'      , cursor: 'crosshair', selectedTool: 'point'    },
    text        : { drawingMode: 'draw_text'        , cursor: 'crosshair', selectedTool: 'text'     },
    photoMarker : { drawingMode: 'draw_photo_marker', cursor: 'photo',     selectedTool: 'point'    },
    task        : { drawingMode: 'draw_task_marker' , cursor: 'task',      selectedTool: 'task'     },
}

/*
 * The drawing mode changed either because the user clicked on a drawing tool or MapboxGLDraw set it
 * @sig drawingModeChanged :: (state, { payload: String }) -> state
 */
const drawingModeChanged = (state, { payload: mode }) => {
    state = F.mergeRight(state, toolToState[mode])

    // unless we're in one of the select modes, we also want to unselect everything when you switch modes
    if (!mode.match(/select/)) state = unsetSelection(state)

    return state
}

/*
 * A change for the map cursor has been triggered
 * @sig customCursorSet :: (state, { payload: { cursorName: String }) -> state
 */
const customCursorSet = fieldSetFromPayloadKey('cursor', 'cursorName')

/*
 * An action has been called to change map cursor to default one for current mode
 * @sig customCursorResetToState :: (state, { payload: String }) -> state
 */
const customCursorResetToState = state => {
    const newCursor = F.findInValues(tool => tool.drawingMode === state.drawingMode, toolToState)?.cursor ?? 'pointer'
    return F.assoc('cursor', newCursor, state)
}

/*
 * The selected Geometries changed (eg. the user selected different Geometries)
 * Since a Feature is associated with exactly one Geometry at the moment, select (or reset) the associated Feature too
 * @sig selectedGeometryIdsChanged :: (state, [ID]) -> state
 */
const selectedGeometryIdsChanged = (state, { payload: selectedGeometryIds }) => {
    /*
     * Accumulate the Feature associated with each selectedGeometryId (if there is one) into an object
     * Then, if there is exactly one feature associated with all the geometryIds, return its id
     */
    const singleFeatureSelected = () => {
        const reducer = (acc, id) => {
            const feature = _featureForGeometry(state, id)
            return feature ? F.assoc(feature.id, feature, acc) : acc
        }

        const featuresForGeometries = selectedGeometryIds.reduce(reducer, {})
        const featuresIds = Object.keys(featuresForGeometries)
        return featuresIds.length === 1 ? featuresIds[0] : undefined
    }

    // get the first (and, for now, only) collaboration associated with the selectedFeature
    const firstCollaborationId = () => {
        if (!selectedFeatureId) return undefined

        const collaborations = _collaborationsAsArray(state).filter(c => c.feature === selectedFeatureId)
        return collaborations[0].id
    }

    // could be undefined if there is nothing selected
    const selectedFeatureId = singleFeatureSelected()

    // we changed the selected Feature -- we also have to change the selectedCollaborationId
    // NOTE: for now there is ALWAYS a selected Collaboration whenever any Feature is selected
    return F.mergeRight(state, {
        selectedFeatureId,
        selectedCollaborationId: firstCollaborationId(),
        selectedGeometryIds,
    })
}

/*
 * Some Geometries were deleted. If there were Features or Collaborations associated with those Geometries
 * we have to delete them too -- and clear selectedFeatureId and selectedCollaborationId
 * The Geometries themselves belong to Geometrystate and will be deleted there
 * @sig geometriesDeleted :: (state, { payload: [Geometry] }) -> state
 *
 * TODO: this is too accommodating: it should be hard to delete a Geometry with an associated Feature
 * TODO: delete the associated Collaborations too
 */
const geometriesDeleted = (state, { payload: geometries }) => _geometriesDeleted(state, pluck('id', geometries))

/*
 * Restore a previously deleted Geometry and its graph
 */
const geometryFeatureAndCollaborationAdded = (state, { payload }) => {
    const { geometry, feature, collaboration, selectNewGeometry = true } = payload

    state = assocPath(['geometries', geometry.id], geometry, state)
    state = assocPath(['features', feature.id], feature, state)
    state = assocPath(['collaborations', collaboration.id], collaboration, state)
    if (selectNewGeometry) state = selectedGeometryIdsChanged(state, { payload: [geometry.id] })

    return state
}

/*
 * The geometries changed -- probably they were moved or archived. In either event, we generate a new location snapshot.
 */
const geometriesChanged = (state, { payload: geometries }) => {
    const temporaryLocationSnapshot = (acc, c) => assocPath(['collaborations', c.id], Collaboration.from(c), acc)

    // update the geometries themselves
    state = itemsAdded('geometries')(state, { payload: geometries })

    /*
     * When archiving, we used to wait for the location snapshot to return from Firestore before
     * removing the Collaboration from the task list. Instead, we preemptively CLONE each Collaboration
     * which has the effect of triggering a rerender of the list, and since the geometry is now archived
     * the collaboration will be immediately removed (because clone is not identical to the collaboration)
     */
    const collaborations = geometries.map(g => ReduxSelectors.firstCollaborationForGeometry(state, g.id))
    state = collaborations.reduce(temporaryLocationSnapshot, state)

    return state
}

// ---------------------------------------------------------------------------------------------------------------------
// Project Actions
// ---------------------------------------------------------------------------------------------------------------------

/*
 * We loaded all the data for a project (and they've already been converted to our Items)
 */
const projectIsLoading = (state, { payload: projectSummary }) => assoc('currentlyLoadingProject', projectSummary, state)

/*
 * We loaded all the data for a project (and they've already been converted to our Items)
 */
const projectWasLoadedFromDatabase = (state, { payload }) => {
    const { users: unused, ...otherFields } = payload

    // HACK: update the initial and complete status names
    const statusNamesAsArray = Object.values(payload.statusNames)
    Collaboration.initialCollaborationStatus = statusNamesAsArray.find(s => s.isInitial).id
    Collaboration.completedCollaborationStatus = statusNamesAsArray.find(s => s.isCompleted).id

    state = assoc('currentlyLoadingProject', undefined, state) // loaded; no longer loading
    state = F.mergeRight(state, { ...otherFields })
    return state
}

/*
 * The current project was unloaded (the user probably went to the "Admin" area--possibly to switch to another Project)
 * SOME of our stored data needs to be reset.
 */
const projectUnloaded = state =>
    F.mergeRight(state, {
        selectedProjectId: undefined,
        selectedCanvas: undefined,
        selectedCanvasSource: undefined,
        selectedFeatureId: undefined,
        selectedCollaborationId: undefined,
        selectedTool: 'idle', // MapboxDraw mode
        selectedMediaUploadsIds: [],

        // collaborations
        features: {},
        collaborations: {},
        updates: {},
        comments: {},
        uploads: {},

        // geometry
        cursor: 'select',
        selectedGeometryIds: [], // MapboxDraw-selected features (which are GeoJSON Features, not Range Features)
        canvases: {},
        canvasSources: {},
        geometries: {}, // forcibly kept in sync with MapboxGL Draw features

        // account
        tagNames: {},
        statusNames: {},
        presences: {},

        mapPositionsHistory: {},
    })

/*
 * User no longer even has access to this project
 */
const projectRemoved = (state, { payload: projectId }) => {
    state = dissocPath(['projects', projectId], state)
    if (state.selectedProjectId === projectId) state = projectUnloaded(state)
    return state
}

// ---------------------------------------------------------------------------------------------------------------------
// Selectors
// ---------------------------------------------------------------------------------------------------------------------

/*
 * User moved; update Presence
 */
const presenceChanged = (state, action) => {
    const { id, changes } = action.payload
    const oldPresence = state.presences[id]

    // if we don't have a canvas, we don't really have a Presence either
    if (!oldPresence?.canvasId && !changes.canvasId) return state

    const newPresence = Presence.update(oldPresence, changes)
    return F.equals(oldPresence, newPresence) ? state : F.assocPath(['presences', id], newPresence, state)
}

const manyItemsAdded = (state, { payload: items }) => {
    // prettier-ignore
    const reducer = (acc, item) => {
        if (Canvas          .is(item)) return assocPath(['canvases'         , item.id], item, acc)
        if (CanvasSource    .is(item)) return assocPath(['canvasSources'    , item.id], item, acc)
        if (Collaboration   .is(item)) return assocPath(['collaborations'   , item.id], item, acc)
        if (Comment         .is(item)) return assocPath(['comments'         , item.id], item, acc)
        if (Feature         .is(item)) return assocPath(['features'         , item.id], item, acc)
        if (Update          .is(item)) return assocPath(['updates'          , item.id], item, acc)
        if (Geometry        .is(item)) return assocPath(['geometries'       , item.id], item, acc)
        if (Organization    .is(item)) return assocPath(['organizations'    , item.id], item, acc)
        if (Presence        .is(item)) return assocPath(['presences'        , item.id], item, acc)
        if (Project         .is(item)) return assocPath(['projects'         , item.id], item, acc)
        if (StatusName      .is(item)) return assocPath(['statusNames'      , item.id], item, acc)
        if (TagName         .is(item)) return assocPath(['tagNames'         , item.id], item, acc)
        if (Upload          .is(item)) return assocPath(['uploads'          , item.id], item, acc)

        return acc

    }

    state = items.reduce(reducer, state)
    return state
}

/*
 * Useful to know the map has loaded
 */
const pdfLoadingStateChanged = (state, { payload: loadingState }) => assoc('pdfLoadingState', loadingState, state)
const backgroundMapLoadingStateChanged = (state, { payload: loadingState }) =>
    assoc('backgroundMapLoadingState', loadingState, state)

/*
 * If there is not already a valid canvasSource for the canvas, select the first one at random
 */
const possiblySelectFirstCanvasSource = state => {
    const canvasId = state.selectedCanvas
    const canvasSource = state.canvasSources[state.selectedCanvasSource]

    // already have selected a canvasSource for this canvas?
    if (canvasSource?.canvasId === canvasId) return state

    // otherwise, arbitrarily select the first canvasSource for the canvas
    const canvasSources = Object.values(state.canvasSources).filter(cs => cs.canvasId === canvasId)

    // if we don't have any canvasSources for this canvas, something's wrong: reset both
    if (canvasSources.length === 0)
        return F.mergeRight(state, { selectedCanvas: undefined, selectedCanvasSource: undefined })

    return F.assoc('selectedCanvasSource', canvasSources[0].id, state)
}

/*
 * Canvas was added; if it's the selectedCanvas, select its first canvasSource
 */
const canvasAdded = (state, { payload: canvas }) => {
    state = assocPath(['canvases', canvas.id], canvas, state)
    return possiblySelectFirstCanvasSource(state, canvas)
}

const canvasSourceAdded = (state, { payload: canvasSource }) =>
    assocPath(['canvasSources', canvasSource.id], canvasSource, state)

const selectedUser = state => state.selectedUserId && state.user // fixme: id guards actual value for now

const userChanged = (state, { payload: { id, changes } }) =>
    assoc('user', User.update(selectedUser(state), changes), state)

const userAdded = (state, { payload: user }) => assoc('user', user, state) // does NOT set selectedUserId

// add the Upload IFF its parent exists (it's possible the upload took so long the parent was deleted in the mean time)
const uploadAdded = (state, { payload: upload }) =>
    state.collaborations[upload.parentId] ? assocPathIfDifferent(['uploads', upload.id], upload, state) : state

const uploadRemoved = (state, action) => {
    const { upload, id } = action.payload // we sometimes get only upload id
    const uploadId = upload ? upload.id : id
    if (!uploadId) return state

    state = selectedMediaUploadsIdToggled(state, { payload: { id, shouldRemove: true } })
    state = dissocPath(['uploads', uploadId], state)

    return state
}

const canvasChanged = (state, action) => {
    const { id, changes } = action.payload
    const oldCanvas = state.canvases[id]

    // we requested the pdfUrl, but in the meantime the project was unloaded
    if (!oldCanvas && changes.pdfUrl) return state

    const newCanvas = Canvas.update(oldCanvas, changes)
    return F.assocPath(['canvases', id], newCanvas, state)
}
const canvasSourceChanged = (state, action) => {
    const { id, changes } = action.payload
    const oldCanvasSource = state.canvasSources[id]

    // we requested the pdfUrl, but in the meantime the project was unloaded
    if (!oldCanvasSource && changes.pdfUrl) return state

    const newCanvasSource = CanvasSource.update(oldCanvasSource, changes)
    state = F.assocPath(['canvasSources', id], newCanvasSource, state)
    if (newCanvasSource.canvasId === state.selectedCanvas) state = assoc('selectedCanvasSource', id, state)
    return state
}

/*
 * Multiple canvases changed (probably because the user uploaded a new PDF for an existing project)
 * @sig canvasSourcesChanged = (State, Action) -> State
 *  action.payload = [CanvasSource]
 */
const canvasSourcesChanged = (state, action) => {
    const canvasSources = action.payload
    state = canvasSources.reduce((acc, cs) => canvasSourceChanged(acc, { payload: { id: cs.id, changes: cs } }), state)
    return state
}

/*
 * Add an array of new [Canvas, CanvasSource] pairs to the state (probably because the user uploaded a
 * new PDF with more pages than there were previously)
 * @sig canvasesAndCanvasSourcesAdded = (State, Action) -> State
 *    action.payload = [[Canvas, CanvasSource]]
 */
const canvasesAndCanvasSourcesAdded = (state, action) => {
    const pairs = action.payload

    // add the new canvases and canvasSources
    state = pairs.reduce((acc, [canvas, canvasSource]) => {
        acc = canvasAdded(acc, { payload: canvas })
        acc = canvasSourceAdded(acc, { payload: canvasSource })
        return acc
    }, state)

    return state
}

/*
 * The user uploaded a new PDF with fewer pages than there were previously
 * @sig canvasSourcesTruncated = (State, Action) -> State
 * Action.payload = { retainedCanvasSources: [CanvasSource], removedCanvasSources: [CanvasSource], pdfUrl: String, pdf: PDFDocumentProxy }
 */
const canvasSourcesTruncated = (state, action) => {
    const { retainedCanvasSources, removedCanvasSources, pdfUrl, pdf } = action.payload

    // update pdfUrl, temporaryPdf of retained canvases
    state = retainedCanvasSources.reduce((acc, cs) => {
        const oldCanvasSource = acc.canvasSources[cs.id]
        const canvasSource = CanvasSource.Pdf.update(oldCanvasSource, { pdfUrl, temporaryPdf: pdf })
        return F.assocPath(['canvasSources', cs.id], canvasSource, acc)
    }, state)

    // remove CanvasSources and Canvases that were truncated
    state = removedCanvasSources.reduce((acc, cs) => {
        acc = F.dissocPath(['canvasSources', cs.id], acc)
        acc = F.dissocPath(['canvases', cs.canvas], acc)
        return acc
    }, state)

    return state
}

const canvasRemoved = (state, action) => {
    const { id } = action.payload
    return F.dissocPath(['canvases', id], state)
}

/*
 * Update the existing Collaboration and add the new Update
 */
const collaborationChangedAndUpdateAdded = (state, action) => {
    const { id: collaborationId, changes, update } = action.payload

    const oldCollaboration = state.collaborations[collaborationId]
    const newCollaboration = Collaboration.update(oldCollaboration, changes)

    state = assocPath(['collaborations', collaborationId], newCollaboration, state)
    state = assocPath(['updates', update.id], update, state)
    return state
}

// updateId is optional and absent if we didn't add an update when the collaboration was changed in the first place
const collaborationChangeReversed = (state, action) => {
    const { id: collaborationId, changes, updateId } = action.payload

    const oldCollaboration = state.collaborations[collaborationId]
    const newCollaboration = Collaboration.update(oldCollaboration, changes)

    state = assocPath(['collaborations', collaborationId], newCollaboration, state)
    if (updateId) state = dissocPath(['updates', updateId], state)
    return state
}

const userLogOut = state => {
    state = initialState
    state = assoc('loggedInStatus', LoggedInStatus.loggedOut, state)
    return state
}

const userAwaitingEmailVerification = state => {
    state = assoc('loggedInStatus', LoggedInStatus.awaitingEmailVerification, state)
    state = dissoc('selectedUserId', state)
    return state
}

const mediaViewFilterSettingsReset = state => {
    return assoc('mediaViewFilterSettings', MediaViewFilterSettings.defaultFilterSettings(), state)
}

// Use to toggle item selection
const selectedMediaUploadsIdToggled = (state, action) => {
    const { id, shouldRemove = false } = action.payload // use shouldRemove flag if you want to only toggle the item off

    // do not proceed if user wants to toggle off selection and the selected media list is empty
    if (!id || (shouldRemove && !state.selectedMediaUploadsIds.length)) return state

    const itemIndex = state.selectedMediaUploadsIds.indexOf(id)

    // toggle this item off
    if (itemIndex !== -1) {
        const newSelectedIds = state.selectedMediaUploadsIds.length === 1 ? [] : [...state.selectedMediaUploadsIds]
        newSelectedIds.splice(itemIndex, 1)
        return assoc('selectedMediaUploadsIds', newSelectedIds, state)
    }
    // toggle this item on, but only if user allowed it
    else if (!shouldRemove) return assoc('selectedMediaUploadsIds', [...state.selectedMediaUploadsIds, id], state)
}

// Use to replace selectedMediaUploadsIds with new array of ids
const selectedMediaUploadsChanged = (state, action) => {
    const { ids } = action.payload

    return assoc('selectedMediaUploadsIds', ids, state)
}

const navigatorFilterSettingsReset = state => {
    const { statuses: statusShapes, projectParticipants: participantShapes } = ReduxSelectors.allShapes(state)
    return assoc(
        'navigatorFilterSettings',
        NavigatorFilterSettings.defaultFilterSettings(statusShapes, participantShapes),
        state
    )
}

const taskListFilterSettingsReset = state => {
    const { statuses: statusShapes, projectParticipants: participantShapes } = ReduxSelectors.allShapes(state)
    return assoc(
        'taskListFilterSettings',
        TaskListFilterSettings.defaultFilterSettings(statusShapes, participantShapes),
        state
    )
}

/*
 * A user firstName/lastName, organizationRole or avatarUrl changed.
 * Update each organization's participant for that user
 */
const organizationParticipantChanged = (state, { payload: participant }) => {
    const updateParticipant = organization => {
        const oldParticipant = path(['participants', participant.id])(organization)
        const newParticipant = Participant.from(mergeRight(oldParticipant, participant))
        organization = assocPath(['participants', participant.id], newParticipant, organization)
        return Organization.from(organization)
    }

    const updateProjectParticipants = project => {
        if (project.participants[participant.id]) {
            const oldParticipant = path(['participants', participant.id])(project)
            const newParticipant = Participant.from(mergeRight(oldParticipant, participant))
            project = assocPath(['participants', participant.id], newParticipant, project)
            return Project.from(project)
        } else return project
    }

    // update organization.participants
    const newOrganizations = mapObject(updateParticipant, state.organizations)
    state = assoc('organizations', newOrganizations, state)

    // also update participants for every project that might be affected
    const newProjects = mapObject(updateProjectParticipants, state.projects)
    state = assoc('projects', newProjects, state)

    return state
}

// if I removed myself from an organization, remove the organization and its projects from the state
const selfRemovedFromOrganization = (state, { payload: organizationId }) => {
    const organization = state.organizations[organizationId]
    if (!organization) return state // organization was already removed

    state = organization.projectIds.reduce((acc, p) => dissocPath(['projects', p], acc), state)
    state = dissocPath(['organizations', organizationId], state)
    return state
}

/*
 * Copy the participant to each project of the current organization (because the user was made an Admin)
 * And remove any suspendedParticipant for that participant
 */
const addParticipantToEachProject = (state, { payload: participant }) => {
    // copy the participant to each project; remove the suspendedParticipant (if there was one)
    const addProjectParticipant = (acc, projectId) => {
        // add the participant (and then create a new Project)
        let project = path(['projects', projectId])(acc)
        project = Project.from(assocPath(['participants', participant.id], participant, project))
        acc = assocPath(['projects', projectId], project, acc)

        return acc
    }

    const organization = ReduxSelectors.selectedOrganization(state)
    const projectIds = organization.projectIds
    state = projectIds.reduce(addProjectParticipant, state)
    return state
}

/*
 * Maybe it was added, or maybe it was replaced
 */
const organizationParticipantAdded = (state, { payload: participant }) => {
    const path = ['organizations', state.selectedOrganizationId, 'participants', participant.id]
    return assocPath(path, participant, state)
}

/*
 * A new document at /users/<user-id>/invitations was added; add it to our data as well
 * @sig userInvitationAdded :: (State, { payload: Invitation }) -> State
 *
 */
const userInvitationAdded = (state, { payload: invitation }) => {
    let user = state.user // ignore selectedUserId here; this might arrive before the data
    if (!user) return state

    if (user?.email !== invitation.inviteeEmail) throw new Error('Received invitation for wrong user')

    user = User.addInvitation(user, invitation)
    return assoc('user', user, state)
}

/*
 * The user has accepted an invitation
 */
const userInvitationChanged = (state, { payload: { id, changes } }) => {
    const user = state.user // ignore selectedUserId here; this might arrive before the data
    return assoc('user', User.updateInvitation(user, id, changes), state)
}

/*
 * Add / remove the user's participant for the given project
 */
const toggleProjectsForUser = (state, { payload }) => {
    const { projectIds, organizationParticipant, isSuspended } = payload
    const { id: userId } = organizationParticipant

    projectIds.forEach(projectId => {
        let project = state.projects[projectId]
        const newParticipant = Participant.update(organizationParticipant, { isSuspended })
        const participants = assoc(userId, newParticipant, project.participants)
        project = Project.update(project, { participants })
        state = assocPath(['projects', projectId], project, state)
    })
    return state
}

/*
 * All the Organizations and Projects were changed (probably because the User's permissions were updated)
 */
const organizationsAndProjectsWereChanged = (state, { payload }) => {
    const { organizations, projects } = payload
    return mergeRight(state, { organizations, projects })
}

/*
 * The (selected) user just created the Organization: add an Admin Participant for them (or they can't create a project)
 */
const selectedUserCreatedOrganization = (state, { payload: organization }) => {
    const user = selectedUser(state)
    const participant = Participant.from({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        isSuspended: false,
        email: user.email,
        timezone: user.timezone,
        organizationRole: 'Admin',
        avatarUrl: user.avatarUrl,
    })

    organization = Organization.from(mergeRight(organization, { participants: { [user.id]: participant } }))
    return assocPath(['organizations', organization.id], organization, state)
}

/*
 * Toggle canvas snippet mode on/off
 * @sig showCanvasSnippetMode :: (state, { payload: Boolean }) -> state
 */
const showCanvasSnippetMode = (state, { payload: isOn }) => {
    return assoc('showCanvasSnippetMode', isOn, state)
}

/*
 * The user has changed the PDF canvases, potentially adding or removing canvases (and canvasSources)
 * NOTE: This function assumes the new canvases and canvasSources have ALREADY been added!
 *
 * It removes any obsolete canvasSources and mapPositions and recomputes the mapPositionsHistory for the new canvases
 */
const updateDataForChangedCanvases = state => {
    const canvasIds = Object.keys(state.canvases)

    const canvasSourcesStillAlive = filterObject(cs => canvasIds.includes(cs.canvasId), state.canvasSources)
    state = assoc('canvasSources', canvasSourcesStillAlive, state)
    const { mapPositions } = ReduxSelectors.initialDataForSelectedProject(state)
    state = assoc('mapPositionsHistory', mapPositions, state)
    return state
}

/*
 * Update the StatusCounts for a project's ProjectMetrics
 *
 * @sig projectMetricsChanged :: (State, Payload) -> State
 *  Payload = { projectId: String, statusCounts: [StatusCount] }
 */
const projectMetricsChanged = (state, action) => {
    const { projectId, statusCounts } = action.payload
    const projectMetrics = ProjectMetrics(projectId, statusCounts)
    return assocPath(['projectMetrics', projectId], projectMetrics, state)
}

/*
 * RangeStatus has been changed
 */
const rangeStatusChanged = (state, action) => {
    return assoc('rangeStatus', action.payload, state)
}

/*
 * Add all the items related to the collaborationId to the store
 */
const collaborationAccessGained = (state, { payload }) => {
    const addItem = (acc, item) => assoc(item.id, item, acc)

    // eg. add all the comments related to the collaboration to state.comments
    const addItems = (state, field, items) => {
        const newValue = reduce(addItem, state[field], items)
        return assoc(field, newValue, state)
    }

    const { items } = payload
    const { geometry, feature, collaboration, comments, updates, uploads } = items

    // remove all items with the collaborationId
    state = addItems(state, 'geometries', [geometry])
    state = addItems(state, 'features', [feature])
    state = addItems(state, 'collaborations', [collaboration])
    state = addItems(state, 'comments', comments)
    state = addItems(state, 'updates', updates)
    state = addItems(state, 'uploads', uploads)

    return state
}

/*
 * Remove all the items related to the collaborationId from the store
 */
const collaborationAccessLost = (state, { payload }) => {
    // eg. remove all comments with a collaborationId === our lost collaboration from state.comments
    const withoutCollaboration = (state, field) => {
        const items = filterObject(item => item.collaborationId !== collaborationId, state[field])
        return assoc(field, items, state)
    }

    const { collaborationId } = payload

    // get rid of the collaboration itself
    state = dissocPath(['collaborations', collaborationId], state)

    // remove all items with the collaborationId
    state = withoutCollaboration(state, 'geometries')
    state = withoutCollaboration(state, 'features')
    state = withoutCollaboration(state, 'comments')
    state = withoutCollaboration(state, 'updates')
    state = withoutCollaboration(state, 'uploads')

    return state
}

/*
 * The participant's "suspendedness" in projects changed because their role changed.
 * We presumably found which projects the user is suspended from by querying Firestore.
 *
 * In Redux, we don't store suspended and unsuspended users in different collections,
 * instead we set `isSuspended` in the Participant object, all of which are in the `project.participants` field
 * Suspended participants don't have an email, so we remove that field also when suspending a user
 *
 * @sig updateParticipantProjects :: (State, { Payload }) -> State
 *  Payload = { participant: Participant, suspended: [ProjectId], unsuspended: [ProjectId] }
 */
const updateParticipantProjects = (state, { payload }) => {
    const markParticipantSuspended = (projects, projectId) =>
        assocPath([projectId, 'participants', participant.id], suspendedParticipant, projects)

    const markParticipantUnsuspended = (projects, projectId) =>
        assocPath([projectId, 'participants', participant.id], unsuspendedParticipant, projects)

    const { participant, suspended, unsuspended } = payload

    // a suspendedParticipant doesn't include the email
    const unsuspendedParticipant = Participant.from(assoc('isSuspended', false, participant))
    const suspendedParticipant = Participant.from({
        firstName: participant.firstName,
        lastName: participant.lastName,
        id: participant.id,
        isSuspended: true,
    })

    let projects = suspended.reduce(markParticipantSuspended, state.projects)
    projects = unsuspended.reduce(markParticipantUnsuspended, projects)

    return assoc('projects', projects, state)
}

// ---------------------------------------------------------------------------------------------------------------------
// state
// ---------------------------------------------------------------------------------------------------------------------
const reducers = {
    // one-time loading
    loadingInitialData: fieldSetToConstant('isLoadingInitialData', true),
    userRelatedDataLoaded,

    organizationsAndProjectsWereChanged,

    // canvases
    selectedCanvasChanged: fieldSetFromPayload('selectedCanvas'),
    canvasAdded,
    canvasChanged,
    canvasRemoved,
    updateDataForChangedCanvases,

    // canvasSources
    canvasSourceAdded,
    canvasSourceChanged,
    canvasSourcesChanged,
    canvasesAndCanvasSourcesAdded,
    canvasSourcesTruncated,
    selectedCanvasSourceChanged: fieldSetFromPayload('selectedCanvasSource'),
    possiblySelectFirstCanvasSource,

    // collaborations
    collaborationAdded: itemAdded('collaborations'),
    collaborationChanged: itemChanged('collaborations'),
    collaborationSelected: itemSelected('selectedCollaborationId'),
    collaborationRemoved: itemRemoved('collaborations'),
    selectedCollaborationCleared: fieldSetToConstant('selectedCollaborationId', undefined),

    collaborationChangedAndUpdateAdded,
    collaborationChangeReversed,

    collaborationAccessGained,
    collaborationAccessLost,

    // comments
    commentAdded: itemAdded('comments'),
    commentRemoved: itemRemoved('comments'),
    commentChanged: itemChanged('comments'),

    // features
    featureAdded: itemAdded('features'),
    featureChanged: itemChanged('features'),
    featureSelected: itemSelected('selectedFeatureId'),
    featureRemoved: itemRemoved('features'),

    // Updates
    updateAdded: itemAdded('updates'),

    // map
    mapMoved,
    mapPositionsHistoryInitialized: (state, action) => assoc('mapPositionsHistory', action.payload, state),
    drawingModeChanged,
    customCursorSet,
    customCursorResetToState,
    showCanvasSnippetMode,

    // organization
    organizationAdded: itemAdded('organizations'),
    selectedUserCreatedOrganization,
    selectedOrganizationChanged: fieldSetFromPayload('selectedOrganizationId'),
    organizationChanged: itemChanged('organizations'),
    organizationParticipantChanged,
    organizationParticipantAdded,
    selfRemovedFromOrganization,

    // invitations
    invitationAdded: itemAdded('invitations'),
    invitationChanged: itemChanged('invitations'),
    invitationRemoved: itemRemoved('invitations'),
    userInvitationAdded,
    userInvitationChanged,

    // presences
    presenceChanged,
    presenceAdded: itemAdded('presences'),

    // project
    selectedProjectChanged: fieldSetFromPayload('selectedProjectId'),
    projectAdded: itemAdded('projects'),
    projectChanged: itemChanged('projects'),
    projectUnloaded,
    projectRemoved, // user no longer has access to project
    projectIsLoading,
    projectWasLoadedFromDatabase,
    addParticipantToEachProject,

    updateParticipantProjects,

    projectMetricsChanged,

    // uploads
    uploadAdded,
    uploadRemoved,
    uploadChanged: itemChanged('uploads'),

    // user
    userLogIn: fieldSetToConstant('loggedInStatus', LoggedInStatus.loggedIn),
    userLogOut,
    userAwaitingEmailVerification,
    userChanged,
    userAdded,
    toggleProjectsForUser,

    // tagNames
    tagNameAdded: itemAdded('tagNames'),
    tagNameRemoved: itemRemoved('tagNames'),
    tagNameChanged: itemChanged('tagNames'),

    // geometries
    geometrySelectionCleared: unsetSelection,
    selectedGeometryIdsChanged,
    geometryAdded: itemAdded('geometries'),
    geometriesAdded: itemsAdded('geometries'), // convert [GeoJSON] -> [Geometry] beforehand!
    geometriesChanged,
    geometryRemoved: itemRemoved('geometries'),
    geometriesDeleted,
    geometryFeatureAndCollaborationAdded,
    navigatorFilterSettingsChanged: fieldSetFromPayload('navigatorFilterSettings'),
    navigatorFilterSettingsReset,
    taskListFilterSettingsChanged: fieldSetFromPayload('taskListFilterSettings'),
    taskListFilterSettingsReset,
    navigatorSortSettingsChanged: fieldSetFromPayload('navigatorSortSettings'),
    setFocusCollabWindow: fieldSetFromPayload('shouldFocusCollabWindow'),

    // media
    mediaViewFilterSettingsChanged: fieldSetFromPayload('mediaViewFilterSettings'),
    mediaViewFilterSettingsReset,
    selectedMediaUploadsIdToggled,
    selectedMediaUploadsChanged,

    // toasts
    toastAdded: itemAdded('toasts'),
    toastRemoved: itemRemoved('toasts'),
    setShowAccountCreatedToast: fieldSetFromPayload('showAccountCreatedToast'),

    // pdf state
    pdfLoadingStateChanged,
    backgroundMapLoadingStateChanged,

    // preferences
    showTimelineDetailsChanged: fieldSetFromPayload('showTimelineDetails'),

    manyItemsAdded,

    globalModalDataSet: fieldSetFromPayload('globalModalData'),
    globalModalDataReset: fieldSetToConstant('globalModalData', {}),

    rangeStatusChanged,
    notificationPreferencesChanged: fieldSetFromPayload('notificationPreferences'),
    knockUserTokenChanged: fieldSetFromPayload('knockUserToken'),

    reset: () => initialState,
    loadDataVerbatim: (state, { payload: newState }) => newState,

    uploadingFilesAdded: itemAdded('uploadingFiles'),
    uploadingFilesRemoved: itemRemoved('uploadingFiles'),
}

/*
 * A utility function to create an action creator for the given action type string.
 * @sign createAction :: String -> ActionCreator
 */

/*
 * Generate ActionCreators for each of the reducers
 * Each ActionCreator takes a single argument, which is called 'payload'
 * @sig createActionCreatorsFromReducers = {name: *} -> {name: ActionCreator}
 *  ActionCreator = Payload -> { type: String, payload: Payload }
 *  Payload = *
 */
const createActionCreatorsFromReducers = reducers => {
    // @sig String -> ActionCreator
    const createActionCreatorForType = type => {
        const actionCreator = payload => ({ type, payload })

        // add additional metadata to the ActionCreator
        actionCreator.toString = () => type
        actionCreator.type = type
        return actionCreator
    }

    const replaceReducerWithActionCreator = (_, type) => createActionCreatorForType(type)
    return mapObject(replaceReducerWithActionCreator, reducers)
}

/*
 * Combine the reducers into a single reducer for Redux AND generate ActionCreators for each of the reducers
 * Each ActionCreator takes a single argument, which is called 'payload'
 * @sig combineReducersIntoReducerAndActionCreators = {name: Reducer} -> { reducer: Reducer, actions: {name: ActionCreator})
 *  Reducer = (State, Action) -> *
 *  ActionCreator = Payload -> { type: String, payload: Payload }
 *  Payload = *
 */
const combineReducers = reducers => {
    return (state = initialState, action) => {
        const reducer = reducers[action.type]
        return reducer ? reducer(state, action) : state
    }
}

const reduxReducer = combineReducers(reducers)
const ReduxActions = createActionCreatorsFromReducers(reducers)

/*
 * Dispatch function that handles uploading a local file to Storage. Requires reference to the file, id of the upload
 * and project ID connected to the upload.
 * @sig uploadFileThunk = {id: String, file: File, projectId: String} -> ()
 */
const uploadFileThunk =
    ({ id, file, projectId }) =>
    async (dispatch, getState) => {
        dispatch(ReduxActions.uploadingFilesAdded({ id }))
        const beforeState = getState()
        const beforeUploadingFilesCount = Object.values(beforeState.uploadingFiles).length
        dispatch(
            ReduxActions.toastAdded({
                id: 'uploadingFiles',
                toastLabel: `Uploading media • ${beforeUploadingFilesCount} remaining`,
                severity: 'progress',
                showUndo: false,
                duration: Infinity,
            })
        )
        await uploadFile(projectId, id, file)
        dispatch(ReduxActions.uploadingFilesRemoved({ id }))
        const afterState = getState()
        const afterUploadingFilesCount = Object.values(afterState.uploadingFiles).length
        if (afterUploadingFilesCount === 0)
            dispatch(
                ReduxActions.toastAdded({
                    id: 'uploadingFiles',
                    toastLabel: 'All media successfully uploaded',
                    severity: 'success',
                    showUndo: false,
                    duration: 5000, // we need this to override Infinity set previously
                })
            )
        else
            dispatch(
                ReduxActions.toastAdded({
                    id: 'uploadingFiles',
                    toastLabel: `Uploading media • ${afterUploadingFilesCount} remaining`,
                    severity: 'progress',
                    showUndo: false,
                    duration: Infinity,
                })
            )
    }

export { reduxReducer, ReduxActions, uploadFileThunk }
