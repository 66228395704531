import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { CheckIcon, MinusIcon } from '@radix-ui/react-icons'
import { styled } from '@stitches/react'
import React from 'react'

const StyledCheckboxIndicator = styled(CheckboxPrimitive.Indicator, {
    color: 'white',
    background: '$primary04',
    borderRadius: '2px',
    width: '100%',
    height: 'auto',
    m: 'auto',
    display: 'flex',
})

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
    padding: 0,
    boxSizing: 'content-box',
    backgroundColor: '$neutral10',
    border: '1px solid $neutral06',
    width: 16,
    minWidth: 16,
    height: 16,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transitionDuration: '0.4s',
    '&:hover': { backgroundColor: '$primary04' },
    '&:focus': {
        boxShadow: '0 0 0 2px black',
    },
    '&[aria-checked="true"]': {
        borderColor: '$primary04',
        backgroundColor: '$primary04',
    },

    variants: {
        size: {
            md: {
                width: 12,
                minWidth: 12,
                height: 12,

                svg: {
                    width: '100%',
                    height: 'auto',
                },
            },
        },
    },
})

// Your app...
export const Checkbox = ({
    defaultChecked = true,
    checked,
    onCheckedChange,
    partiallyChecked = false,
    size,
    ...restProps
}) => {
    return (
        <StyledCheckbox
            data-cy="checkbox"
            css={{ ...restProps.style }} // reset d
            defaultChecked={defaultChecked}
            checked={partiallyChecked ? 'indeterminate' : checked}
            onCheckedChange={onCheckedChange}
            size={size}
            {...restProps}
        >
            <StyledCheckboxIndicator>{partiallyChecked ? <MinusIcon /> : <CheckIcon />}</StyledCheckboxIndicator>
        </StyledCheckbox>
    )
}

export default Checkbox
