/*
 * The user has changed their firstName, lastName, role, etc. Publish the change to Firestore and redux
 * Inbound isn't necessary, since it will come in as a UserAddedCommand.Inbound
 */

import { User } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { mergeRight, taggedSum } from '@range.io/functional'
import * as Firestore from 'firebase/firestore'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import * as Segment from '../../segment/segment.js'
import { firestore } from '../configure-environment/config-local.js'
import CommandPlayer from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// UserChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const UserChangedCommand = taggedSum('UserChangedCommand', {
    Outbound: {
        id: StringTypes.Id, // User id
        changes: 'Object', // each entry defines key/value of the existing User that changed
        isCreate: 'Boolean?', // flag to distinguish first change after user creation from other changes
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A UserChangedCommand.Outbound has arrived from the UI; send it to Firestore and Redux
 * This will also happen when a user first signs up, since we force them to set their firstName, lastName and role
 */
const runOutboundCommand = async (resources, command) => {
    const { id, changes, isCreate } = command
    const { dispatch, displayError, runTransaction, getState } = resources

    try {
        await runTransaction(async transaction => transaction.update(Firestore.doc(firestore, `/users/${id}`), changes))

        // Call Segment if the user has set their names for the first time
        const user = ReduxSelectors.selectedUser(getState())
        const shouldIdentifyUser = user.email === user.firstName && changes.firstName
        if (shouldIdentifyUser) await Segment.sendIdentify(mergeRight(user, changes))

        if (isCreate) {
            const utmAttribution = Segment.getUtmAttributionParams()
            Segment.sendTrack('user created', id, {
                firstName: changes.firstName,
                lastName: changes.lastName,
                email: user.email,
                role: changes.role,
                ...utmAttribution,
            })
        }

        // update user
        dispatch(ReduxActions.userChanged({ id, changes }))
    } catch (e) {
        displayError(e)
    }
}

// DocumentPath not needed: UserAddedCommand will listen for Firestore changes
const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        UserChangedCommand,
        CommandPlayer({
            CommandType: UserChangedCommand,
            Type: User,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
        })
    )
}

export { addCommand, UserChangedCommand }
