/*
 * The Range login page, including a top-bar and a login form
 */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Banner, Box, Button, Flex, FlexColumn, Logo, LogoText, Text, TextInput } from '../components-reusable/index.js'
import { attemptLoginWithEmailAndPassword, attemptLogInWithGoogle } from '../firebase/authentication-helpers.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import { StyledForm, ScrollableContainer, StyledLogo, StyledPage } from './RegistrationSharedStyles.js'

// ---------------------------------------------------------------------------------------------------------------------
// Main
// ---------------------------------------------------------------------------------------------------------------------

const StyledSecondaryLabel = styled(Text, {
    color: '$neutral04',
    width: '100%',
    fontSize: '13px',
})

const StyledLinkText = styled('a', {
    fontWeight: '500',
    fontSize: '13px',
    color: '$primary04',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': { textDecoration: 'underline' },
})

const StyledLinkTextNewAccount = styled(Text, {
    fontWeight: '500',
    fontSize: '13px',
    alignSelf: 'center',
    color: '$primary04',

    cursor: 'pointer',
    '&:hover': { textDecoration: 'underline' },
})

const StyledInputFlex = styled(FlexColumn, {
    display: 'flex',
    gap: '6px',
    w: '100%',
})

const StyledTextInput = styled(TextInput, {
    justifySelf: 'stretch',
    flexGrow: '0',
    width: '100%',
    minHeight: '40px',
    color: '$neutral05',
    '&:focus': { color: '$neutral04' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Main
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Main login form; tracks an email and password and calls "onSubmit" when Log In is clicked.
 * Displays any errors from previous log-in attempts at the top of the form
 */
const LoginForm = ({ errors, onSubmit, onLogInWithGoogle, onSignUp }) => {
    const EMAIL = 'email'
    const PASSWORD = 'password'

    const _onSubmitEmailAndPassword = e => {
        e.preventDefault()

        // values autofilled by the browser itself will appear as target values even if the nominal email/password
        // are still set to their initial useState('') values
        // In order to identify which element is the email and which the password, they're each marked with a data-use
        const formElements = Array.from(e.target)
        const email = formElements.find(t => t.getAttribute('data-use') === EMAIL)
        const password = formElements.find(t => t.getAttribute('data-use') === PASSWORD)

        onSubmit(email.value, password.value)
    }

    // const _onLogInWithGoogle = e => {
    //     e.preventDefault()
    //     return onLogInWithGoogle()
    // }

    return (
        <StyledForm onSubmit={_onSubmitEmailAndPassword}>
            <ScrollableContainer>
                <StyledLogo>
                    <Logo />
                    <LogoText />
                </StyledLogo>
                {errors && <Banner toastLabel={errors} severity="error" />}
                {/* <ContinueWithGoogle onClick={_onLogInWithGoogle} /> */}
                <Text css={{ fw: 400, fs: '16px', color: '$neutral05', textAlign: 'center' }}>
                    Log in below using your email and password
                </Text>
                <StyledInputFlex>
                    <StyledSecondaryLabel>Email</StyledSecondaryLabel>
                    <StyledTextInput data-use={EMAIL} data-cy="login-email" placeholder="Enter your email..." />
                </StyledInputFlex>
                <StyledInputFlex>
                    <StyledSecondaryLabel>Password</StyledSecondaryLabel>
                    <StyledTextInput
                        data-use={PASSWORD}
                        data-cy="login-password"
                        type="password"
                        placeholder="Enter your password..."
                    />
                    <StyledLinkText css={{ mt: 2 }} href="/resetPassword">
                        Forgot your password?
                    </StyledLinkText>
                </StyledInputFlex>
                <Flex css={{ flexDirection: 'column', gap: '24px', width: '100%', mt: 8 }}>
                    <Button data-cy="login-submit" variant="primary" type="submit" size="lg" css={{ w: '100%' }}>
                        <Text css={{ fw: 600 }}>Log In</Text>
                    </Button>
                    <StyledLinkTextNewAccount onClick={onSignUp}>No account? Sign up</StyledLinkTextNewAccount>
                </Flex>
            </ScrollableContainer>
        </StyledForm>
    )
}

/*
 * The entire LoginPage
 */
const LoginPage = () => {
    const gotoSignUp = () => navigate('/signup')
    const onsubmit = async (email, password) => {
        if (!email || !password) {
            const errors = []
            if (!email) errors.push('Please enter a valid email')
            if (!password) errors.push('Please enter your password')
            if (errors.length) setLoginErrors(errors.join(' '))
            return
        }

        try {
            await attemptLoginWithEmailAndPassword(email, password)
        } catch (e) {
            setLoginErrors(e.message)
        }
    }

    const onLogInWithGoogle = async () => {
        try {
            await attemptLogInWithGoogle()
        } catch (e) {
            setLoginErrors(e.message)
        }
    }

    const [loginErrors, setLoginErrors] = useState()
    const isLoggedIn = useSelector(ReduxSelectors.isLoggedIn)
    const isAwaitingEmailVerification = useSelector(ReduxSelectors.isAwaitingEmailVerification)
    const navigate = useNavigate()

    useEffect(() => {
        if (isLoggedIn) navigate(window.history.state?.usr?.redirectedFrom ?? '/')
    }, [isLoggedIn])

    useEffect(() => {
        if (isAwaitingEmailVerification) navigate('/verifyEmail')
    }, [isAwaitingEmailVerification])

    return (
        <StyledPage id="main">
            <Box css={{ position: 'fixed' }}>
                <img src="/rangebackgroundicon.svg" icon="Range icon" />
            </Box>
            <LoginForm
                errors={loginErrors}
                onSubmit={onsubmit}
                onSignUp={gotoSignUp}
                onLogInWithGoogle={onLogInWithGoogle}
            />
        </StyledPage>
    )
}

export default LoginPage
