/*
 * See Readme
 */

import { Invitation } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// ParticipantChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const UserInvitationAddedCommand = taggedSum('UserInvitationAddedCommand', {
    Inbound: { invitation: 'Invitation' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A ParticipantChangedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the participant)
 */
const runInboundCommand = (resources, command) => {
    const { dispatch } = resources
    dispatch(ReduxActions.userInvitationAdded(command.invitation))
}

const addUserInvitationAddedCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        UserInvitationAddedCommand,
        CommandPlayer({
            CommandType: UserInvitationAddedCommand,
            Type: Invitation,
            collectionPath: userId => `/users/${userId}/invitations/`,
            runInboundCommand,
            addCommandToHistory,
            changeType: 'added',
            resourceKey: 'userId',
        })
    )
}

export { UserInvitationAddedCommand, addUserInvitationAddedCommandSingleton }
