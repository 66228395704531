import React from 'react'
import { Avatar, Button, Icon, FlexRow } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledButton = styled(Button, {
    p: '4px 6px',
    outline: '0px solid $primary04',
    overflow: 'hidden',
    height: '32px',

    '&:hover, &:focus': {
        backgroundColor: 'var(--colors-neutral05)',
    },
})

const AvatarWrapper = styled('div', {
    position: 'absolute',
    transform: 'translateY(-50%)',
})
const Followers = React.forwardRef(({ followers = [], ...rest }, ref) => {
    const avatarWidth = 24
    const followersCount = followers.length
    const maxAvatars = 3
    const avatarsCount = Math.min(maxAvatars, followersCount)

    const getAvatarY = index => (avatarWidth / 2) * index // avatar has to overlay the previous avatar, starting from half of it

    const getAvatars = () => {
        const avatarsElements = followers.slice(0, avatarsCount).map((follower, index) => {
            return (
                <AvatarWrapper
                    key={follower.id}
                    css={{
                        left: getAvatarY(index) + 'px', // move each avatar to the right by half of its width, so it overlays the previous one
                    }}
                >
                    <Avatar
                        key={follower.id}
                        url={follower.avatarUrl}
                        fallbackText={follower.avatarFallbackText}
                        size={avatarWidth.toString()}
                    />
                </AvatarWrapper>
            )
        })

        // If there are more followers than max avatars we can display,
        // add another avatar placeholder with the count of remaining followers, i.e. +2
        if (followersCount > avatarsCount) {
            avatarsElements.push(
                <AvatarWrapper
                    key={avatarsCount}
                    css={{
                        left: getAvatarY(avatarsElements.length) + 'px', // render it as the last avatar
                    }}
                >
                    <Avatar fallbackText={`+${followersCount - avatarsCount}`} size={avatarWidth.toString()} />
                </AvatarWrapper>
            )
        }

        return avatarsElements
    }

    return (
        <StyledButton {...rest} ref={ref} variant="followers">
            <FlexRow css={{ alignItems: 'center', gap: '6px' }}>
                {avatarsCount ? (
                    <FlexRow
                        css={{
                            // we need to calculate this element width, as avatars inside are placed with absolute position
                            width:
                                (followersCount <= avatarsCount // if there is no +X avatar displaying the followers count
                                    ? getAvatarY(avatarsCount) + avatarWidth / 2 // just add half avatar width as the last one has to be displayed full, not overlayed
                                    : getAvatarY(avatarsCount) + avatarWidth) + // if there is a +X avatar at the end, ensure we treat it as additional avatar in width calculations
                                'px',
                            position: 'relative',
                        }}
                    >
                        {getAvatars()}
                    </FlexRow>
                ) : null}
                <div style={{ position: 'relative', display: 'flex' }}>
                    <Icon
                        iconSize="18"
                        css={{ color: '$neutral05', transitionDuration: '0.3s', '&:hover': { color: '$neutral04' } }}
                        name="accountUserAdd"
                    />
                </div>
            </FlexRow>
        </StyledButton>
    )
})

export default Followers
