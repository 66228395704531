/*
 * See PhotoAnnotationEditor for more information about how this is used
 * PhotoAnnotations stored in Firestore. Wraps multiple PhotoAnnotation objects
 */
import { assoc, mergeRight, tagged } from '@range.io/functional'
import PhotoAnnotation from './photo-annotation.js'

const PhotoAnnotations = tagged('PhotoAnnotations', {
    format: 'String',
    width: 'Number',
    height: 'Number',
    scale: 'Number',
    annotations: '[PhotoAnnotation]',
})

const numberTo6Decimals = n => parseFloat(n.toFixed(6))

/*
 * Compute the annotations' scale value. The scale is 1/400 of the smaller of the height or width.
 * This value is attached to the PhotoAnnotations object and not to individual PhotoAnnotation objects
 * (We agreed that we would scale the annotations in this way on all platforms.)
 */
const computeAnnotationsScale = (imageWidth, imageHeight) => {
    const ANNOTATIONS_REFERENCE_VIEW_SIZE = 400
    return Math.min(imageWidth, imageHeight) / ANNOTATIONS_REFERENCE_VIEW_SIZE
}

PhotoAnnotations.default = (imageWidth, imageHeight) =>
    PhotoAnnotations.from({
        width: imageWidth,
        height: imageHeight,
        format: 'https://range.io/photo-annotations/v1',
        scale: numberTo6Decimals(computeAnnotationsScale(imageWidth, imageHeight)),
        annotations: [],
    })

/*
 * json is string containing one or more annotations
 * @sig fromFirebase :: String -> PhotoAnnotations
 *
 */
PhotoAnnotations.fromFirebase = json => {
    const { format, width, height, scale, annotations } = JSON.parse(json)
    const photoAnnotations = annotations.map(PhotoAnnotation.fromFirebase)
    return PhotoAnnotations.from({ format, width, height, scale, annotations: photoAnnotations })
}

/*
 * Output is a JSON string containing one or more annotations
 * @sig toFirebase :: PhotoAnnotations -> String
 *
 */
PhotoAnnotations.toFirebase = photoAnnotations => {
    const annotations = photoAnnotations.annotations.map(PhotoAnnotation.toFirebase)
    photoAnnotations = assoc('annotations', annotations, photoAnnotations)

    return JSON.stringify(photoAnnotations)
}

PhotoAnnotations.update = (photoAnnotations, changes) => PhotoAnnotations.from(mergeRight(photoAnnotations, changes))

export default PhotoAnnotations
