import { REGEX } from '@range.io/basic-types/src/string-types.js'
import React, { useState } from 'react'
import { useStore } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    Banner,
    Button,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    Logo,
    LogoText,
    Text,
    TextInput,
    LoadingDotsAnimation,
} from '../components-reusable/index.js'
import { attemptSignOut } from '../firebase/authentication-helpers.js'
import { OrganizationAddedCommand } from '../firebase/commands/organization-added-command.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'

import { SelectOrgOption, useOrganizationSizeDropdownOptions } from './pages/SharedComponents.js'
import { industryOptions } from './RegistrationCreateAcccount.js'
import {
    BGLogo,
    InputFlex,
    InputLabel,
    LabelLayout,
    LinkTextCTA,
    PrimaryLabelOrg,
    ScrollableContainer,
    SecondaryLabelOrg,
    StyledForm,
    StyledLogoOrg,
    StyledPage,
    StyledTextInput,
    UrlMessage,
    WrappedInput,
} from './RegistrationSharedStyles.js'

/*
 * New Organization Form
 */

const validateOrganizationName = s => {
    if (!s) return 'Organization Name is required'
    else return ''
}

const validateOrganizationSize = s => {
    if (!s.match(REGEX.organizationSize)) return 'Organization Size has to be selected'
    else return ''
}

const WarningLabel = styled(Text, {
    fw: 400,
    fs: '14px',
    alignSelf: 'start',
    color: '$neutral04',
    textAlign: 'center',
    width: '100%',
    background: '$neutral09',
    b: '1px solid $neutral07',
    p: 8,
    br: 8,
    boxSizing: 'border-box',
})

const NewOrganizationDumb = ({ onSubmit, onLogOut, onCancel, industryOptions, userEmail }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const _onSubmit = async e => {
        e.preventDefault()

        if (hasAllValues()) {
            setIsSubmitting(true)
            const organizationNameTrimmed = organizationName.trim() // can't start or end with a space
            await onSubmit(organizationNameTrimmed, industry, organizationSize)
            setIsSubmitting(false)
        }
    }

    const hasAllValues = () =>
        organizationName?.length > 0 &&
        organizationSize.match(REGEX.organizationSize) &&
        industry !== industryOptions[0].name

    const setOrganizationName = s => {
        setErrors([validateOrganizationName(s), errors[1], errors[2]])
        _setOrganizationName(s)
    }

    const setOrganizationSize = s => {
        setErrors([validateOrganizationSize(s), errors[1], errors[2]])
        _setOrganizationSize(s)
    }

    const setIndustry = id => {
        setErrors([errors[0], errors[1], id === industryOptions[0].id ? 'Industry is required' : ''])
        _setIndustry(id)
    }

    const organizationSizeOptions = [{ id: 'Please select one...', name: 'Please select one...' }].concat(
        useOrganizationSizeDropdownOptions()
    )

    const [organizationName, _setOrganizationName] = useState('')
    const [organizationSize, _setOrganizationSize] = useState(organizationSizeOptions[0].id)
    const [industry, _setIndustry] = useState(industryOptions[0].id)
    const [errors, setErrors] = useState(['', '', ''])

    const disabled = !hasAllValues() || isSubmitting

    // This needs refactor, shows organization url input
    const showSlug = false
    return (
        <StyledPage>
            <BGLogo>
                <img src="/rangebackgroundicon.svg" icon="Range icon" />
            </BGLogo>
            <StyledForm onSubmit={_onSubmit}>
                <ScrollableContainer>
                    <WarningLabel>
                        👉 In the future, Range may bill based partly on how many organizations you are part of. You may
                        want to consider adding projects to an existing organization instead of creating a new
                        organization.
                    </WarningLabel>
                    <StyledLogoOrg>
                        <Logo />
                        <LogoText />
                    </StyledLogoOrg>
                    <FlexColumn
                        css={{
                            color: '$neutral04',
                            overflow: 'hidden',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        <InputLabel css={{ color: '$neutral05', textAlign: 'center', fs: '16px' }}>
                            Logged in as
                        </InputLabel>
                        <SecondaryLabelOrg
                            css={{
                                color: '$neutral04',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100%',
                                fs: 18,
                                fw: 500,
                            }}
                        >
                            {userEmail}
                        </SecondaryLabelOrg>
                    </FlexColumn>
                    <LabelLayout>
                        <PrimaryLabelOrg>Create an Organization</PrimaryLabelOrg>
                        <SecondaryLabelOrg>
                            Organizations are shared environments where teams can collaborate on projects.
                        </SecondaryLabelOrg>
                    </LabelLayout>
                    {errors.join('') && (
                        <Flex css={{ width: '100%' }}>
                            {' '}
                            <Banner toastLabel={errors.join(' ')} severity="error"></Banner>
                        </Flex>
                    )}
                    <InputFlex>
                        <InputLabel>Organization Name</InputLabel>
                        <StyledTextInput
                            placeholder="Enter your organization name..."
                            value={organizationName}
                            onChange={setOrganizationName}
                            onBlur={setOrganizationName}
                        />
                    </InputFlex>
                    {showSlug && (
                        <InputFlex>
                            <InputLabel>Organization URL</InputLabel>
                            <WrappedInput>
                                <Text>app.range.io/</Text>
                                <TextInput
                                    placeholder=""
                                    css={{ b: 'none', pl: 0, '&:hover': { pl: 0 }, '&:focus': { pl: 0 } }}
                                    onBlur={setOrganizationName}
                                />
                            </WrappedInput>
                            <UrlMessage variant="success">
                                <Icon name="check" iconSize="16" />
                                <Text>Organization URL available</Text>
                            </UrlMessage>
                            <UrlMessage variant="error">
                                <Icon name="warningFill" iconSize="16" />
                                <Text>Organization URL already taken</Text>
                            </UrlMessage>
                        </InputFlex>
                    )}
                    <InputFlex>
                        <InputLabel>Industry</InputLabel>
                        <FlexRow css={{ gap: '4px', height: '40px' }}>
                            <SelectOrgOption
                                options={industryOptions}
                                value={industry}
                                onValueChange={setIndustry}
                                onBlur={setIndustry}
                            />
                        </FlexRow>
                    </InputFlex>
                    <InputFlex>
                        <InputLabel>Organization Size</InputLabel>
                        <FlexRow css={{ gap: '4px', height: '40px' }}>
                            <SelectOrgOption
                                options={organizationSizeOptions}
                                value={organizationSize}
                                onValueChange={setOrganizationSize}
                                onBlur={setOrganizationSize}
                            />
                        </FlexRow>
                    </InputFlex>

                    <Button variant="primary" type="submit" size="lg" disabled={disabled} css={{ w: '100%' }}>
                        {isSubmitting ? <LoadingDotsAnimation variant="button" /> : <Text>Create Organization</Text>}
                    </Button>
                    {onLogOut && <LinkTextCTA onClick={onLogOut}>Log out</LinkTextCTA>}
                    {onCancel && <LinkTextCTA onClick={onCancel}>Cancel</LinkTextCTA>}
                </ScrollableContainer>
            </StyledForm>
        </StyledPage>
    )
}

const NewOrganization = ({ includeLogOut = true }) => {
    const createOrganization = async (name, industry, organizationSize) => {
        const id = await runCommand(OrganizationAddedCommand.Outbound(name, industry, organizationSize))
        navigate(`/${id}`)
    }

    const onCancel = () => navigate('/')

    const { getState } = useStore()
    const { runCommand } = useCommandHistory()
    const user = ReduxSelectors.selectedUser(getState())
    const [errors, setErrors] = useState()
    const navigate = useNavigate()

    const props = {
        errors,
        setErrors,
        industryOptions,
        userEmail: user.email,
        onSubmit: (name, industry, organizationSize) => createOrganization(name, industry, organizationSize),
        onLogOut: includeLogOut ? attemptSignOut : undefined,
        onCancel: includeLogOut ? undefined : onCancel,
    }

    return user ? <NewOrganizationDumb {...props} /> : <Text>No User yet</Text>
}

export { NewOrganizationDumb, NewOrganization }
