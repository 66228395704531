/*
 * Component to take a snippet of a canvas that later gets added to a collaboration as an upload.
 * Main requirement is to know what collaboration the user has open, and based on that find the matching
 * canvas and allow a snippet of it. Created upload is then added to the provided collaboration.
 *
 * Because we need to handle 2 different types of canvas sources some considerations have to be taken into account:
 * 1. for PDF canvases - it requires a background HTML canvas to render the PDF contents and a Mapbox instance in front
 * to control it, doesn't store or require a style for the Mapbox map in front, center comes from the project details,
 * not the canvas source itself
 * 2. for satellite canvases - the drawing buffer has to be preserved by Mapbox if we want to access it to take the snippet.
 */

import { CanvasSource, Collaboration, Geometry, TagName, Upload } from '@range.io/basic-types'
import { mapboxBoundsToBounds, projectOffscreenOntoOnscreen } from '@range.io/basic-types/src/math/lng-lat-math'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-dev.js'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useSelector, useStore } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { TagNameAddedCommand } from '../firebase/commands/tag-name-added-command.js'
import { GeometriesAddedCommand, UploadAddedCommand } from '../firebase/commands/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux'
import { uploadFileThunk } from '../redux/slices/redux-actions.js'
import * as Segment from '../segment/segment.js'
import { v4 } from 'uuid'
import { getImageHeightToWidth } from '../helpers.js'

import { OffscreenCanvasWrapper } from './BackgroundPdfMap.js'
import CanvasSnippetOverlay from './CanvasSnippetOverlay.js'
import PageLoading, { PageLoadingWithMessage } from './PageLoading.js'

const StyledMainContainer = styled('div', {
    zIndex: 400,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'gray',
})

const StyledMapboxContainer = styled('div', {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    '& canvas': {
        outline: 'none', // prevent blue outline border on map
    },
    '.mapboxgl-canvas': {
        position: 'relative',
        left: '0',
        top: '0',
    },
    // mapbox checks if this object has this color to determine whether you've loaded mapbox-gl.css -- which we haven't
    '.mapboxgl-canary': {
        backgroundColor: 'rgb(250, 128, 114)',
    },
})

const cropImage = (imageBlob, startX, startY, cropWidth, cropHeight) => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = URL.createObjectURL(imageBlob)

        img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = cropWidth
            canvas.height = cropHeight
            const ctx = canvas.getContext('2d')

            ctx.drawImage(img, startX, startY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight)

            canvas.toBlob(blob => {
                resolve(blob)
            }, 'image/png')
        }

        img.onerror = error => {
            reject(error)
        }
    })
}

const getImageBlobDimensions = blob => {
    return new Promise((resolve, reject) => {
        const url = URL.createObjectURL(blob)
        const img = new Image()

        img.src = url

        img.onload = () => {
            const width = img.width
            const height = img.height
            // Revoke the blob URL to free up resources
            URL.revokeObjectURL(url)
            // Resolve the promise with the width and height as a tuple
            resolve([width, height])
        }

        img.onerror = () => {
            // Revoke the blob URL to free up resources
            URL.revokeObjectURL(url)
            // Reject the promise with an error message
            reject(new Error('Failed to load the image.'))
        }
    })
}

const customEndpointLayerId = 'Custom endpoint layer'
const droneDeployLayerId = 'droneDeployLayer'
const droneDeploySourceId = 'droneDeploySource'

const possiblyReplaceDroneDeployLayer = (mapboxMap, canvasSource) => {
    const droneDeployLayer = mapboxMap.getLayer(customEndpointLayerId)

    // not all CanvasSources have a droneDeployLayer
    if (!droneDeployLayer) return

    const newSourceProps = { type: 'raster', tiles: [canvasSource.tilesetUrl], tileSize: 256 }
    const newLayerProps = { id: droneDeployLayerId, type: 'raster', source: droneDeploySourceId }

    mapboxMap.addSource(droneDeploySourceId, newSourceProps)
    mapboxMap.addLayer(newLayerProps, customEndpointLayerId)
    mapboxMap.removeLayer(customEndpointLayerId)
}

/*
 * Create a new Collaboration.
 * @sig createCollaboration :: (ref, String, Function, Function) -> Collaboration
 */
const createCollaboration = async ({ mapRef, selectedCanvasId, runCommand, getState }) => {
    try {
        const currentCenter = mapRef.current.getCenter()
        const newGeometry = Geometry.fromFirebase({
            id: v4(),
            canvas: selectedCanvasId,
            annotationType: 'marker',
            coordinates: `[${currentCenter.lng}, ${currentCenter.lat}]`,
        })
        const newCollaborationId = await runCommand(GeometriesAddedCommand.Outbound(newGeometry, true))
        const collaboration = getState().collaborations[newCollaborationId]
        return collaboration
    } catch (e) {
        console.error(e)
        alert(`Error while creating collaboration for a snippet.`)
    }
}

/*
 * Create an Upload instance for a given file, and optional Tags to assign to it.
 * Uploads the file to Storage as well.
 * @sig createUpload :: (File, [Id], Collaboration, Id) -> UploadId
 */
const createUpload = async ({
    file,
    customTagIds = null,
    collaboration,
    userId,
    selectedProject,
    runCommand,
    dispatch,
}) => {
    try {
        const id = v4()
        const imageHeightToWidth = Upload.isImageFile(file) ? await getImageHeightToWidth(file) : 1
        const upload = Upload.uploadForCollaboration({
            id,
            collaboration,
            userId,
            name: Upload.dropExtension(file.name),
            imageHeightToWidth,
            fileType: Upload.guessFileType(file),
            fileSize: file.size,
            tagIds: customTagIds ?? [],
        })
        runCommand(UploadAddedCommand.Outbound(upload))
        dispatch(uploadFileThunk({ id, file, projectId: selectedProject.id }))
        return id
    } catch (e) {
        console.error(e)
        alert(`Error while uploading file: ${file.name}`)
    }
}

const CanvasSnippet = ({ collaboration, onSnippetCreated, onCancel }) => {
    const { dispatch, getState } = useStore()
    const { runCommand } = useCommandHistory()
    const selectedProject = useSelector(ReduxSelectors.selectedProject)
    const selectedCanvasId = useSelector(ReduxSelectors.selectedCanvasId)
    const userId = useSelector(ReduxSelectors.selectedUserId)

    const [snippetCanvasSource, setSnippetCanvasSource] = useState(null)
    const [backgroundCanvas, setBackgroundCanvas] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isReady, setIsReady] = useState(false) // flags if the component has everything to be rendered, show loader otherwise
    const { projectId, workspaceId } = useParams()
    const navigate = useNavigate()
    const mapRef = useRef(null)
    const mapCanvasRef = useRef(null)

    /*
     * Builds options for Mapbox renderer for the given canvas source.
     * Those are dependent if we are dealing with a PDF background or not.
     * @sig getMapboxOptionsForCanvasSource :: (CanvasSource) -> Object
     */
    const getMapboxOptionsForCanvasSource = canvasSource => {
        const selectedCanvasSource = ReduxSelectors.selectedCanvasSource(getState())
        const mapPosition = selectedCanvasSource.id === canvasSource.id ? ReduxSelectors.mapPosition(getState()) : null
        const options = {
            container: 'snippet-map-container',
            maxZoom: CanvasSource.getMaxZoom(canvasSource),
            minZoom: CanvasSource.getMinZoom(canvasSource),
            maxPitch: 0,
            dragRotate: false,
            pitchWithRotate: false,
        }
        if (canvasSource.type === 'pdf') {
            options.style = 'mapbox://styles/mapbox/empty-v8'
            options.center = mapPosition?.center ?? selectedProject.center
            options.zoom = mapPosition?.zoom ?? 21
        } else {
            options.style = canvasSource.styleUrl
            options.center = mapPosition?.center ?? canvasSource.center
            options.zoom = mapPosition?.zoom ?? canvasSource.zoom
            options.preserveDrawingBuffer = true
        }
        return options
    }

    const destinationParams = renderedCanvas => {
        const { pdfBounds, pdfSizeInPixels } = renderedCanvas
        const mapboxCanvas = mapRef.current.getCanvas()
        const mapboxBounds = mapboxBoundsToBounds(mapRef.current.getBounds())
        return projectOffscreenOntoOnscreen(mapboxBounds, mapboxCanvas, pdfBounds, pdfSizeInPixels)
    }

    /*
     * Re-centers background map. Only applicable for PDF maps where we have to keep Mapbox map and background canvas in sync.
     * Mapbox is responsible for handling all interactions to mimic how main CanvasView does it, and how the experience is with
     * the satellite maps.
     */
    const recenterBackgroundMap = renderedCanvas => {
        const { offscreenCanvas, pdfSizeInPixels } = renderedCanvas
        const onscreenCanvas = mapCanvasRef.current

        if (!onscreenCanvas) return

        const context = onscreenCanvas.getContext('2d', { willReadFrequently: true })
        const { x, y, width, height } = destinationParams(renderedCanvas)

        // the destination is always the entire canvas
        context.clearRect(0, 0, onscreenCanvas.width, onscreenCanvas.height)
        context.drawImage(offscreenCanvas, 0, 0, pdfSizeInPixels.width, pdfSizeInPixels.height, x, y, width, height)
    }

    const startup = () => {
        if (mapRef.current) return

        // If no collaboration passed, then use currently selected canvas
        const canvas = collaboration
            ? ReduxSelectors.canvasForCollaboration(getState(), collaboration)
            : ReduxSelectors.selectedCanvas(getState())
        if (!canvas) return

        const { canvasSource } = ReduxSelectors.canvasAndFirstSourceForId(canvas.id)(getState())

        const mapboxOptions = getMapboxOptionsForCanvasSource(canvasSource)

        const mapboxMap = new mapboxgl.Map(mapboxOptions)
        mapboxMap.dragRotate.disable()
        mapboxMap.touchZoomRotate.disableRotation()
        mapRef.current = mapboxMap

        mapboxMap.once('load', async () => {
            setSnippetCanvasSource(canvasSource)
            if (canvasSource.type === 'pdf') {
                const renderedCanvas = await OffscreenCanvasWrapper.fromCanvasSource(
                    canvasSource,
                    selectedProject.center
                )
                setBackgroundCanvas(renderedCanvas)
            } else {
                setIsReady(true)
            }
        })

        if (canvasSource.type === 'dronedeploy') {
            mapboxMap.on('styledata', () =>
                possiblyReplaceDroneDeployLayer(mapboxMap, ReduxSelectors.selectedCanvasSource(getState()))
            )
        }

        return () => {
            mapboxMap && mapboxMap.remove()
            mapRef.current = null
        }
    }

    const handleSaveSnippet = () => {
        // get TagIds for a new snippet upload, create them if they don't exist
        const getSnippetTagIds = () => {
            const snippetTagNameLabel = 'snippet'
            const snippetTagName = ReduxSelectors.tagNameForName(getState(), snippetTagNameLabel)
            if (!snippetTagName) {
                const tagName = TagName.fromName(snippetTagNameLabel)
                runCommand(TagNameAddedCommand.Outbound(tagName))
                return [tagName.id]
            }
            return [snippetTagName.id]
        }

        const imageBlobReceived = async imageBlob => {
            const [imageWidth, imageHeight] = await getImageBlobDimensions(imageBlob)
            const cropY = 80 // equal to top margin of the snippet guide
            const cropHeight = imageHeight - 160 // always the size of the screen minus the bottom and top margins of the guide that are the same size
            const cropWidth = (4.0 * cropHeight) / 3.0 // calculated width that respects 4/3 ratio
            const cropX = (imageWidth - cropWidth) * 0.5 // after calculating the width the crop starts at half of the space that's left on the screen
            const resultBlob = await cropImage(imageBlob, cropX, cropY, cropWidth, cropHeight)
            const imageFile = new File([resultBlob], 'canvasSnippet.png', { type: 'image/png' })
            const snippetTagIds = getSnippetTagIds()

            // If no collaboration was passed, create a new one
            const targetCollaboration =
                collaboration || (await createCollaboration({ mapRef, selectedCanvasId, runCommand, getState }))

            // Create an upload and attach it to the collaboration
            const uploadId = await createUpload({
                file: imageFile,
                customTagIds: snippetTagIds,
                collaboration: targetCollaboration,
                userId,
                selectedProject,
                runCommand,
                dispatch,
            })

            setIsProcessing(false)

            const segmentParams = ReduxSelectors.paramsForTrackEvent(getState())
            segmentParams.canvasType = snippetCanvasSource.type
            segmentParams.collaborationType = Collaboration.isTask(targetCollaboration) ? 'task' : 'photo'
            Segment.sendTrack('snippet created', uploadId, segmentParams)
            navigate(`/${workspaceId}/${projectId}/media/${uploadId}`, {
                state: {
                    startWithAnnotations: true,
                    closeAnnotationToPreviousPage: true,
                    safeToNavigateBack: true,
                    navigatedFrom: 'collaborationWindow',
                    navigatedFromUrl: window.location.pathname + window.location.search,
                },
            })

            onSnippetCreated()
        }

        setIsProcessing(true)

        // because we keep the background in different canvases based on the type of canvas source, we need to access it differently.
        if (snippetCanvasSource.type === 'pdf') {
            mapCanvasRef.current.toBlob(imageBlobReceived, 'image/png')
        } else {
            mapRef.current.getCanvas().toBlob(imageBlobReceived, 'image/png')
        }
    }

    useEffect(startup, [])

    useEffect(() => {
        if (!backgroundCanvas) return
        recenterBackgroundMap(backgroundCanvas)
        const recenterListener = () => recenterBackgroundMap(backgroundCanvas)
        mapRef.current.on('move', recenterListener)
        setIsReady(true)
    }, [backgroundCanvas, mapCanvasRef.current])

    const renderPDFBackgroundCanvas = () => {
        if (!mapRef.current) return
        const mapboxCanvas = mapRef.current.getCanvas()
        return (
            <canvas
                ref={mapCanvasRef}
                width={mapboxCanvas.width}
                height={mapboxCanvas.height}
                style={{ width: mapboxCanvas.style.width, height: mapboxCanvas.style.height }}
            />
        )
    }

    return (
        <StyledMainContainer>
            {!isReady && (
                <PageLoadingWithMessage
                    css={{ position: 'absolute', zIndex: 300, color: '$neutral04' }}
                    messageText="Preparing canvas. One moment please..."
                />
            )}
            {isProcessing && <PageLoading css={{ position: 'absolute', zIndex: 300 }} />}
            {snippetCanvasSource && snippetCanvasSource.type === 'pdf' && renderPDFBackgroundCanvas()}
            <StyledMapboxContainer id="snippet-map-container" />
            {isReady && (
                <CanvasSnippetOverlay
                    mapboxMap={mapRef.current}
                    canvasSource={snippetCanvasSource}
                    project={selectedProject}
                    onSave={handleSaveSnippet}
                    onCancel={onCancel}
                />
            )}
        </StyledMainContainer>
    )
}

const CanvasSnippetRenderer = ({ collaboration, onCancel = () => {}, onSnippetCreated = () => {} }) => {
    const { dispatch } = useStore()

    const hideSnippetWindow = () => dispatch(ReduxActions.showCanvasSnippetMode(null))

    const handleSnippetCreated = props => {
        hideSnippetWindow()
        onSnippetCreated(props)
    }

    const handleCancel = () => {
        hideSnippetWindow()
        onCancel()
    }

    return createPortal(
        <CanvasSnippet collaboration={collaboration} onCancel={handleCancel} onSnippetCreated={handleSnippetCreated} />,
        document.body
    )
}

export { CanvasSnippet, CanvasSnippetRenderer }
