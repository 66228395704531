import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, Box, FlexRow } from '../components-reusable'
import { useBooleanState } from '../components-reusable/hooks/index.js'
import PossiblySuspendedUserName from '../components-reusable/PossiblySuspendedUserName.js'
import { styled } from '../range-theme'
import { ParticipantShapePropTypes } from '../react-shapes/participant-shape.js'

const AssigneeContainer = styled(Box, {
    position: 'absolute',
    left: '18px',
    top: '30px',
    background: '$neutral04',
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    padding: '8px',
    mb: '4px',
    width: 'max-content',
    color: '$neutral07',
    fs: 14,
    fw: 500,
    zIndex: 2,

    '& div:not(:first-child)': {
        marginTop: '8px',
    },
})

const FilterPillWrapper = styled(FlexRow, {
    display: 'inline-flex',
    alignItems: 'center',
    color: '$neutral04',
    gap: 8,
    fontWeight: 500,
    fontSize: '14px',
    position: 'relative',
    width: 'inherit',
})

// Still to do - Clicking the ListViewRowAssignee should bring up the assignee user select, like we do in the collaboration window
const ListViewRowAssignee = ({ participantShape, isOnline, ...restProps }) => {
    const { set: show, unset: hide, get: isVisible } = useBooleanState()

    const textCss = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }
    return (
        <FilterPillWrapper {...restProps} data-cy="list-view-assignee" onMouseOver={show} onMouseOut={hide}>
            <Avatar size="24" participantShape={participantShape} isOnline={isOnline} />
            <PossiblySuspendedUserName css={textCss} participantShape={participantShape} />
            {isVisible() && <AssigneeContainer css={{ mt: '4px' }}>Assigned to</AssigneeContainer>}
        </FilterPillWrapper>
    )
}

ListViewRowAssignee.propTypes = {
    participantShape: PropTypes.shape(ParticipantShapePropTypes).isRequired,
    isOnline: PropTypes.bool,
}

export default ListViewRowAssignee
