import { CanvasSource, LngLatMath, PdfPage } from '@range.io/basic-types'
import { LRU, tagged } from '@range.io/functional'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mark } from '../firebase/console.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'

const { boundsFromCenterAndSize, mapboxBoundsToBounds, projectOffscreenOntoOnscreen } = LngLatMath

/*
 * For efficiency, we want to call PDFJS as little as possible, so we:
 *
 * 1 Draw the PDF into an offscreen (DOM) canvas
 * 2 Copy parts of the offscreen canvas into the onscreen canvas as controlled by the current zoom and center
 *
 * Every PDF has an inherent width and height based on Postscript's definition of 72 points per inch.
 * (This width and height was set, explicitly or not, by the creator of the PDF.)
 *
 * PDFJS can draw a PDF at a scale other than 1:1 (this is the "scale" passed to PdfPage.getViewport)
 * If our PDF is 1500 pixels wide and we (arbitrarily) decide to set the PDFJS scale to 4.0, we will need an
 * offscreen canvas 6000 pixels wide.
 */

// ---------------------------------------------------------------------------------------------------------------------
// OffscreenCanvasWrapper
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Object to store an offscreen (DOM) canvas, its pixel bounds and its geospatial coordinates
 */
export const OffscreenCanvasWrapper = tagged('OffscreenCanvasWrapper', {
    offscreenCanvas: 'Object', // HTML Canvas
    pdfSizeInPixels: 'Object', // { width: Number, height: Number }
    pdfBounds: 'Object', // Bounds = { north; LngLat, east: LngLat, south: LngLat, west: LngLat }
})

/*
 * Create a small dummy canvas for now (red 300x200)
 */
const fromMapCanvasSource = async (canvasSource, center) => {
    const width = 300
    const height = 200

    // create a new offscreen (DOM) canvas element and render the PDF page into it
    const offscreenCanvas = document.createElement('canvas')
    offscreenCanvas.width = width
    offscreenCanvas.height = height

    const pdfSizeInPixels = { width, height }
    const pdfBounds = boundsFromCenterAndSize({ lng: center[0], lat: center[1] }, width, height)
    const context = offscreenCanvas.getContext('2d')
    context.width = width
    context.height = height
    context.fillStyle = 'lightgray'
    context.fillRect(0, 0, offscreenCanvas.width, offscreenCanvas.height)

    // Create a OffscreenCanvasWrapper and cache it
    const renderedCanvas = OffscreenCanvasWrapper(offscreenCanvas, pdfSizeInPixels, pdfBounds)
    renderedCanvases.setItem(canvasSource.id, renderedCanvas) // store the OffscreenCanvasWrapper in the LRU
    return renderedCanvas
}

/*
 * Render the selected CanvasSource into an offscreen (DOM) canvas object and return a RenderedObject for it
 * NOTE: If the CanvasSource is not a PDF, create a dummy canvas for now
 *
 * The result is memoized using an LRU limited to MAX_OFFSCREEN_CANVASES
 * @sig fromCanvasSource :: (CanvasSource, [Number, Number]) -> OffscreenCanvasWrapper
 */
OffscreenCanvasWrapper.fromCanvasSource = async (canvasSource, center) => {
    if (!CanvasSource.Pdf.is(canvasSource)) return fromMapCanvasSource(canvasSource, center)

    // when drawing offscreen, scale the PDF up by this factor to allow for better sampling when zoomed in
    const PDF_SCALE = 4

    // first try to pull the OffscreenCanvasWrapper from the LRU cache
    const url = canvasSource.pdfUrl
    const previouslyRenderedCanvas = renderedCanvases.getItem(url)
    if (previouslyRenderedCanvas) return previouslyRenderedCanvas

    // read a page of the PDF
    const pdfPage = await PdfPage.fromUrl(url)

    // scale the offscreen viewport up by PDF_SCALE
    const viewport = PdfPage.getViewport(pdfPage, PDF_SCALE)

    //  compute pixel dimensions of offscreen canvas and (geospatial) bounds
    const [w, h] = [viewport.width / PDF_SCALE, viewport.height / PDF_SCALE]

    const { width: pdfWidthInMeters, height: pdfHeightInMeters } = CanvasSource.dimensionsInMeters(canvasSource, w, h)

    const pdfSizeInPixels = { width: viewport.width, height: viewport.height }
    const pdfBounds = boundsFromCenterAndSize({ lng: center[0], lat: center[1] }, pdfWidthInMeters, pdfHeightInMeters)

    // create a new offscreen (DOM) canvas element and render the PDF page into it
    const offscreenCanvas = document.createElement('canvas')
    offscreenCanvas.width = pdfSizeInPixels.width
    offscreenCanvas.height = pdfSizeInPixels.height
    await PdfPage.render(pdfPage, offscreenCanvas.getContext('2d'), viewport)

    // Create a OffscreenCanvasWrapper and cache it
    const renderedCanvas = OffscreenCanvasWrapper(offscreenCanvas, pdfSizeInPixels, pdfBounds)
    renderedCanvases.setItem(url, renderedCanvas) // store the OffscreenCanvasWrapper in the LRU
    return renderedCanvas
}

// cache up to MAX_OFFSCREEN_CANVASES offscreen RenderedCanvases
const MAX_OFFSCREEN_CANVASES = 10
const renderedCanvases = LRU(MAX_OFFSCREEN_CANVASES)

const StyledPdfCanvas = styled('canvas', {
    zIndex: -100, // needs to be behind the foreground map, but goes after it in document-order
})

// ---------------------------------------------------------------------------------------------------------------------
// Map component
// ---------------------------------------------------------------------------------------------------------------------
let recenterListener
const BackgroundPdfMap = props => {
    const destinationParams = renderedCanvas => {
        const { pdfBounds, pdfSizeInPixels } = renderedCanvas
        const mapboxCanvas = foregroundMapboxMap.getCanvas()
        const mapboxBounds = mapboxBoundsToBounds(foregroundMapboxMap.getBounds())
        return projectOffscreenOntoOnscreen(mapboxBounds, mapboxCanvas, pdfBounds, pdfSizeInPixels)
    }

    /*
     * Recenter the background map
     */
    const recenterBackgroundMap = renderedCanvas => {
        const { offscreenCanvas, pdfSizeInPixels } = renderedCanvas
        const onscreenCanvas = onscreenCanvasRef.current

        if (!onscreenCanvas) return

        const context = onscreenCanvas.getContext('2d', { willReadFrequently: true })
        const { x, y, width, height } = destinationParams(renderedCanvas)

        // the destination is always the entire canvas
        context.clearRect(0, 0, onscreenCanvas.width, onscreenCanvas.height)
        context.drawImage(offscreenCanvas, 0, 0, pdfSizeInPixels.width, pdfSizeInPixels.height, x, y, width, height)
    }

    // the recenterListener is called whenever the foreground map moves or the window resizes so that the
    // background PDF is kept in sync. However, when the foregroundMap, selectedCanvas or URL changes,
    // we need a new recenterListener and need to remove the old listener from the foreground map and window
    // This is also true when unmounting the BackgroundPdfMap
    const removeListeners = () => {
        if (!recenterListener) return

        if (foregroundMapboxMap) foregroundMapboxMap.off('move', recenterListener)
        window.removeEventListener('resize', recenterListener)
    }

    // initialize the PDF document
    const startup = async () => {
        if (!foregroundMapboxMap || !selectedCanvasSource || !selectedCanvasSource.pdfUrl) return null

        const measurePdfRendering = mark('PDFRendering')

        dispatch(ReduxActions.pdfLoadingStateChanged('loading'))

        const renderedCanvas = await OffscreenCanvasWrapper.fromCanvasSource(selectedCanvasSource, center)
        recenterBackgroundMap(renderedCanvas)

        dispatch(ReduxActions.pdfLoadingStateChanged('loaded'))

        // remove old listeners and create new ones
        removeListeners()

        // redraw background map when foreground map moves
        recenterListener = () => recenterBackgroundMap(renderedCanvas)
        foregroundMapboxMap.on('move', recenterListener)
        window.addEventListener('resize', recenterListener)

        measurePdfRendering()
    }

    const dispatch = useDispatch()

    const selectedCanvas = useSelector(ReduxSelectors.selectedCanvas)
    const selectedCanvasSource = useSelector(ReduxSelectors.pdfCanvasSourceForSelectedCanvas)
    const onscreenCanvasRef = useRef(null)
    const { mapboxMap: foregroundMapboxMap, center, ...otherProps } = props

    useEffect(() => {
        startup() // without this style, (function call and braces) React complains about "async within useEffect"
    }, [foregroundMapboxMap, selectedCanvasSource])
    useEffect(() => () => removeListeners(), []) // unmount

    if (!foregroundMapboxMap) return null
    if (!selectedCanvas) return null

    const mapboxCanvas = foregroundMapboxMap.getCanvas()

    const style = {
        width: mapboxCanvas.style.width,
        height: mapboxCanvas.style.height,
    }

    return (
        <StyledPdfCanvas
            ref={onscreenCanvasRef}
            id="background-map-container"
            width={mapboxCanvas.width}
            height={mapboxCanvas.height}
            style={style}
            {...otherProps}
        />
    )
}

export { fromMapCanvasSource }
export default BackgroundPdfMap
