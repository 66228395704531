/*
 * Side panel component in the canvas source editor.
 * Displays some information text, a dropzone for files and a CTA button.
 * Special variants for edit and admin flows.
 */

import { Upload } from '@range.io/basic-types'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useStore } from 'react-redux'
import {
    Button,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    IconButtonWithTooltip,
    Link,
    ScrollArea,
    Text,
} from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { Label } from './pages/SharedComponents.js'
import PDFCanvasSourceDeletePDFsModal from './PDFCanvasSourceDeletePDFsModal'
import { ReduxActions } from '../redux/index.js'

const MAX_FILE_SIZE_MB = 30 // TODO: define max accepted file size

const StyledContainer = styled(FlexColumn, {
    height: '100%',
    alignItems: 'space-between',
    justifyContent: 'space-between',
})

const StyledUploadZoneBox = styled(FlexColumn, {
    width: '100%',
    height: '178px',
    border: '1px dashed $neutral06',
    borderRadius: '6px',
    backgroundColor: '$neutral09',
    justifyContent: 'center',
    alignItems: 'center',
    transitionDuration: '0.2s',

    variants: {
        variant: {
            default: {
                backgroundColor: '$neutral09',
                border: '1px dashed $neutral06',
            },
            dropZone: {
                backgroundColor: '$primary02',
                border: '1px dashed $primary04',
            },
        },
    },

    '&.disabled': {
        pointerEvents: 'none',
        cursor: 'not-allowed',
        opacity: '0.6',
    },
})

const UploadFileIcon = () => (
    <svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="layer.pdf">
            <path
                id="Vector"
                d="M35.9125 2.52148V9.93121H43.1875M35.9125 2.52148L43.1875 9.93121M35.9125 2.52148H6.8125V46.9798H43.1875V9.93121M19.7438 13.7102C13.5146 15.4885 33.6482 34.6704 35.0031 28.2703C36.3581 21.8701 11.0593 30.947 15.5334 35.3836C20.0075 39.8202 25.973 11.9781 19.7438 13.7102Z"
                stroke="url(#paint0_linear_20656_76953)"
                strokeWidth="1.51562"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_20656_76953"
                x1="26.4209"
                y1="-46.171"
                x2="-25.7918"
                y2="-29.3502"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#5D81FF" />
                <stop offset="1" stopColor="#B85DFF" />
            </linearGradient>
        </defs>
    </svg>
)

const UploadZone = ({ onFileDrop, isLoadingFiles }) => {
    const { dispatch } = useStore()
    const { getRootProps, open, isDragActive } = useDropzone({
        noClick: true,
        multiple: true,
        maxSize: MAX_FILE_SIZE_MB * 1000000, // max file size in bytes
        accept: {
            [Upload.mimeTypes.pdf]: [],
        },
        disabled: isLoadingFiles,
        onDrop: onFileDrop,
        onDropRejected: () => {
            dispatch(
                ReduxActions.toastAdded({
                    id: 'pdfToast',
                    toastLabel: 'Something went wrong when uploading a pdf',
                    severity: 'error',
                    showUndo: false,
                })
            )
        },
    })

    return (
        <Flex css={{ cursor: 'pointer' }} onClick={open}>
            {isDragActive ? (
                <StyledUploadZoneBox
                    onClick={open}
                    variant="dropZone"
                    {...getRootProps({ className: `dropzone ${isLoadingFiles ? 'disabled' : ''}` })}
                >
                    <UploadFileIcon />
                    <FlexRow css={{ mt: 16, color: '$neutral04' }}>
                        <Text css={{ fontSize: '14px', lineHeight: '21px' }}>Drag & drop PDF files here or&nbsp;</Text>
                        <Link
                            css={{
                                cursor: 'pointer',
                                fontSize: '14px',
                                lineHeight: '21px',
                                '&:hover': { textDecoration: 'underline' },
                            }}
                            onClick={open}
                        >
                            browse
                        </Link>
                    </FlexRow>
                    <Text
                        css={{ color: '$neutral05', fontSize: '12px', lineHeight: '18px', marginTop: '8px' }}
                    >{`Max file size ${MAX_FILE_SIZE_MB}mb`}</Text>
                </StyledUploadZoneBox>
            ) : (
                <StyledUploadZoneBox
                    onClick={open}
                    variant="default"
                    {...getRootProps({ className: `dropzone ${isLoadingFiles ? 'disabled' : ''}` })}
                >
                    <UploadFileIcon />
                    <FlexRow css={{ mt: 16, color: '$neutral04' }}>
                        <Text css={{ fontSize: '14px', lineHeight: '21px' }}>Drag & drop PDF files here or&nbsp;</Text>
                        <Link
                            css={{ fontSize: '14px', lineHeight: '21px', '&:hover': { textDecoration: 'underline' } }}
                            onClick={open}
                        >
                            browse
                        </Link>
                    </FlexRow>
                    <Text
                        css={{ color: '$neutral05', fontSize: '12px', lineHeight: '18px', marginTop: '8px' }}
                    >{`Max file size ${MAX_FILE_SIZE_MB}mb`}</Text>
                </StyledUploadZoneBox>
            )}
        </Flex>
    )
}

const PdfTips = () => {
    return (
        <FlexColumn css={{ background: '$neutral09', br: 6, p: 16, b: '1px solid $neutral07' }}>
            <Label variant="sheet">👋 PDF Upload Tips & Tricks</Label>
            <Label variant="tertiary">
                For the most efficient/streamlined PDF upload experience, export your PDF drawings as individual sheets,
                then upload these to Range. <br />
                <br />
                This will mean:
                <br />
                <ul>
                    <li>You're unlikely to run into the 30mb file size limit. </li>
                    <li>
                        Range can extract each PDF sheet name, which means that information won't need to be manually
                        entered for each sheet.
                    </li>
                </ul>
                If you're running into file size limitations with your PDF drawings (max file size 30mb), use{' '}
                <a
                    style={{ color: '#5D81FF' }}
                    href="https://smallpdf.com/compress-pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    this free online PDF compressor tool
                </a>
                .
            </Label>
        </FlexColumn>
    )
}

const EditCanvases = ({ isSubmitDisabled, onCancel, onFileDrop, onSave, isLoadingFiles }) => {
    return (
        <StyledContainer>
            <ScrollArea>
                <FlexColumn css={{ padding: '16px', gap: 16 }}>
                    <FlexRow css={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Label variant="title">PDF Canvas Sources</Label>
                        <Button variant="secondary" size="lg" onClick={onCancel}>
                            Cancel
                        </Button>
                    </FlexRow>
                    <Label variant="tertiary">
                        Manage your PDF drawings by either updating existing sheets or by adding new PDF files.
                    </Label>
                    <UploadZone onFileDrop={onFileDrop} isLoadingFiles={isLoadingFiles} />
                    <PdfTips />
                </FlexColumn>
            </ScrollArea>

            <FlexColumn css={{ padding: '16px' }}>
                <Button variant="primary" size="lg" onClick={onSave} disabled={isSubmitDisabled}>
                    Save & Close
                </Button>
            </FlexColumn>
        </StyledContainer>
    )
}
const ProjectWizard = ({
    isSubmitDisabled,
    onCancel,
    onFileDrop,
    onPrevious,
    onSave,
    onDeleteAllPDFs,
    isLoadingFiles,
    showDeleteFilesButton = false,
}) => {
    const [showDeletePDFsModal, setShowDeletePDFsModal] = React.useState(false)
    const onDeletePDFs = () => {
        onDeleteAllPDFs()
        setShowDeletePDFsModal(false)
    }
    const css = { height: '40px', width: 'content-fit', ml: 'auto' }
    return (
        <StyledContainer>
            {showDeletePDFsModal && (
                <PDFCanvasSourceDeletePDFsModal
                    onCancel={() => setShowDeletePDFsModal(false)}
                    onDelete={onDeletePDFs}
                />
            )}
            <FlexColumn css={{ padding: '16px', gap: '16px' }}>
                <FlexColumn>
                    <FlexRow css={{ ai: 'center', marginBottom: 24 }}>
                        <FlexRow css={{ ai: 'center', gap: 4 }}>
                            <Icon
                                name="newProject"
                                iconSize="24px"
                                css={{ minWidth: '24px', height: '24px', color: '$neutral04' }}
                            />
                            <Label variant="title">Create New Project</Label>
                        </FlexRow>
                        <Button css={css} variant="secondary" size="lg" onClick={onCancel}>
                            <Text>Cancel</Text>
                        </Button>
                    </FlexRow>
                    <FlexRow
                        css={{
                            borderBottom: '1px solid $neutral07',
                            paddingBottom: 20,
                            ai: 'center',
                        }}
                    >
                        <FlexColumn>
                            <FlexRow css={{ alignItems: 'center', gap: 4 }}>
                                <Label variant="heading">Project Details</Label>
                                <Icon name="check" css={{ width: 16, maxHeight: 16, color: '$green03', mt: 6 }} />
                            </FlexRow>
                            <Label variant="tertiary">Enter the details of your project below</Label>
                        </FlexColumn>
                        <IconButtonWithTooltip
                            dataCy="button-edit-project"
                            css={{ ml: 'auto' }}
                            iconName="edit"
                            tooltipText="Edit Project Details"
                            side="bottom"
                            onClick={onPrevious}
                            size="16"
                            variant="neutralHover32"
                        />
                    </FlexRow>
                </FlexColumn>
                <FlexColumn>
                    <Label variant="heading">PDF Drawings (Optional)</Label>
                    <Label variant="tertiary">
                        Upload PDF drawings if you have them, or simply click "Create Project" to get started.
                    </Label>
                </FlexColumn>
                <UploadZone onFileDrop={onFileDrop} isLoadingFiles={isLoadingFiles} />
                {showDeleteFilesButton && (
                    <FlexColumn>
                        <Button
                            variant="secondary"
                            size="lg"
                            onClick={() => setShowDeletePDFsModal(true)}
                            disabled={isLoadingFiles}
                        >
                            <Icon name="trash" iconSize="16px" />
                            Remove all PDF's
                        </Button>
                    </FlexColumn>
                )}
                <PdfTips />
            </FlexColumn>

            <FlexColumn css={{ padding: '16px' }}>
                <Button variant="primary" size="lg" onClick={onSave} disabled={isSubmitDisabled}>
                    Create Project
                </Button>
            </FlexColumn>
        </StyledContainer>
    )
}

const PDFCanvasSourceEditPanel = { EditCanvases, ProjectWizard }

export { MAX_FILE_SIZE_MB }
export default PDFCanvasSourceEditPanel
