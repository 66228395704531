/*
 * See Readme
 */

import { Participant } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { equals, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import reloadOrganization from './helpers/reload-organization.js'
import { removeOrganizationParticipant } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// OrganizationParticipantRemovedCommand
// ---------------------------------------------------------------------------------------------------------------------
const OrganizationParticipantRemovedCommand = taggedSum('OrganizationParticipantRemovedCommand', {
    Inbound: { participant: 'Participant' },
    Outbound: { organizationId: StringTypes.Id, participant: 'Participant' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A ParticipantRemovedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the participant)
 * Important note: user which was suspended won't receive this inbound command,
 * as he no longer is the organization participant
 * and doesn't have permissions to listen to the `/organizations/${organizationId}/suspendedParticipants/` path.
 */
const runInboundCommand = async (resources, command) => {
    const { dispatch, organizationId, getState } = resources
    const { participant } = command
    participant.isSuspended = true // since they were added to suspendedParticipants they must be suspended
    const state = getState()

    // if there is already an equal OrganizationParticipant, don't reload the entire Organization:
    // it can't have changed on the basis of this participant, since this participant hasn't changed
    const existingParticipant = ReduxSelectors.organizationParticipantWithId(state, participant.id)
    if (equals(existingParticipant, participant)) return

    const { organizations, projects } = await reloadOrganization(state, organizationId)
    dispatch(ReduxActions.organizationsAndProjectsWereChanged({ organizations, projects }))
}

/*
 * Change the Organization.participant's isSuspended state
 */
const runOutboundCommand = async (resources, command) => {
    const { dispatch, displayError } = resources
    const { organizationId, participant } = command

    try {
        await removeOrganizationParticipant(organizationId, participant.id)

        // show the toast
        const fullName = Participant.fullName(participant)
        dispatch(
            ReduxActions.toastAdded({
                id: participant.id,
                toastLabel: `${fullName} has been successfully suspended`,
                severity: 'success',
                showUndo: false,
            })
        )
    } catch (e) {
        displayError(e)
    }
}
const addOrganizationParticipantRemovedCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        OrganizationParticipantRemovedCommand,
        CommandPlayer({
            CommandType: OrganizationParticipantRemovedCommand,
            Type: Participant,
            collectionPath: organizationId => `/organizations/${organizationId}/suspendedParticipants/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
            resourceKey: 'organizationId',
        })
    )
}

export { OrganizationParticipantRemovedCommand, addOrganizationParticipantRemovedCommandSingleton }
