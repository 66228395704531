/*
 * InvitationDisplay
 *
 * The goal of this component is to collect a list of emails by typing; as you complete an email it
 * becomes a "deleteable pill" you can edit by backspacing through it
 *
 * The email "pill" is actually two buttons: one has the text of the email for this pill and the other
 * is an 'x' that removes this email from the existing list (and puts you back in add-email mode; see below)
 *
 * There are two modes, though they're not called out in the code: add-email and edit-email.
 *
 * add-email
 *
 * You start in add-email mode and there is a persistent text input for this mode.
 * When you type in this mode there are 6 special characters:
 *      tab, space, comma and return: create a new email "pill"
 *      backspace, delete           : puts you back into edit-email mode.
 *
 * edit-email
 *
 * When I'm edit-email mode, the button for the pill which contains the email is replaced with a 2nd text input.
 * In this case, the special characters work slightly differently:
 *
 *      tab, space, command and return: completes your edit and returns you to add-email mode,
 *      backspace and delete          : deletes the email if you back up far enough to leave no content
 *
 * FlexRow
 *   EmailPill(s)
 *     StyledEmailPillButton
 *       {email}
 *     StyledEmailPillDeleteButton
 *   EmailEditor
 *     StyledEditorWrapper
 *       StyledEmailEditor
 *       StyledEmailPillDeleteButton
 *   StyledMainTextInput (where you type in add-email)
 */

import React, { useEffect, useState } from 'react'
import { FlexRow, Icon, Text } from '../components-reusable/index.js'
import { RangeUITheme, styled } from '../range-theme/index.js'
import { getTextWidth } from '../helpers.js'

/* Email text label */
const StyledEmailLabel = styled(Text, {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

/* Button that removes an email from the list used in both add-email and edit-email modes */
const StyledEmailPillDeleteButton = styled(Icon, {
    minWidth: 20,
    minHeight: 20,
    color: '$neutral04',
    borderRadius: '1000px',
    cursor: 'pointer',
    '&:hover': {
        color: '$neutral05',
    },
})

/* Button that allows you to enter edit-email mode with a click or backspace */
const StyledEmailPillButton = styled('button', {
    padding: '5px 5px 5px 10px',
    gap: '8px',
    display: 'flex',
    color: '$neutral04',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid $neutral07',
    borderRadius: '1000px',
    width: 'fit-content',
    userSelect: 'none',
    flexWrap: 'no-wrap',
    alignItems: 'center',
    background: '$primary02',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fw: 500,
    height: 34,
    maxWidth: 'inherit',

    variants: {
        valid: {
            true: { border: '1px solid $neutral07' },
            false: { border: '1px solid red', background: '$red01' },
        },
    },
})

const baseInputStyle = {
    fontFamily: RangeUITheme.fonts.default,
    fontSize: '14px',
    lineHeight: '20px',
}

/* Main text input area when you're NOT editing an existing email */
const StyledMainTextInput = styled('input', {
    border: 'none',
    outline: 'none',
    background: 'none',
    color: '$neutral04',
    pl: 4,
    height: 32,
    ...baseInputStyle,

    '&::placeholder': { color: '$neutral05' },
})

/* Text input area when you're editing an existing email */
const StyledEmailEditor = styled('input', {
    background: 'transparent',
    fontSize: '14px',
    lineHeight: '20px',
    border: 'none',
    outline: 'none',
    color: '$neutral04',
})

/* Wrapper around the text input and close button when you're editing an existing email */
const StyledEditorWrapper = styled(FlexRow, {
    alignItems: 'center',
    padding: '5px 5px 5px 10px',
    gap: 8,
    color: '$neutral04',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid $neutral07',
    borderRadius: '1000px',
    background: '$primary02',
    fw: 500,
    height: 34,
    maxWidth: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    variants: {
        valid: {
            true: { border: '1px solid $neutral07' },
            false: { border: '1px solid red', background: '$red01' },
        },
    },
})

const StyledInviteInput = styled('div', {
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
    minHeight: '88px',
    br: 6,
    background: '$neutral09',
    border: '1px solid $neutral07',
    '&:hover': {
        border: '1px solid $primary04',
    },
    '&:focus': { border: '1px solid $primary04' },
})

const COMPLETE_KEYS = [' ', ',', 'Enter', 'Tab']
const BACK_KEYS = ['Backspace', 'Delete']

const isValidEmail = email => /\S+@\S+\.\S+/.test(email)
const remove = (index, a) => a.slice(0, index).concat(a.slice(index + 1)) // remove element at index from a

/* Text input + Close button for editing existing email */
const EmailEditor = ({ email, onChange, onKeyDown, onBlur, onRemoveEmail }) => {
    const w = `${email.length}ch`
    return (
        <StyledEditorWrapper valid={isValidEmail(email)}>
            <StyledEmailEditor
                css={{ w }}
                key={email}
                value={email}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                autoFocus
            />
            <StyledEmailPillDeleteButton name="filledClose" iconSize="24px" onClick={onRemoveEmail} />
        </StyledEditorWrapper>
    )
}

/* Button to edit email + Close button */
const EmailPill = ({ email, onStartEditing, onRemoveEmail }) => (
    <StyledEmailPillButton valid={isValidEmail(email)} type="button" onClick={onStartEditing}>
        <StyledEmailLabel>{email}</StyledEmailLabel>
        <StyledEmailPillDeleteButton name="filledClose" iconSize="24px" onClick={onRemoveEmail} />
    </StyledEmailPillButton>
)

/*
 * The "preexistingEmails" allow us to tell if a newly-entered email is a duplicate
 */
const InvitationDisplay = React.forwardRef(
    ({ preexistingEmails, emails, setEmails, defaultPlaceholder, setHasValidInvitation, onCancel }, inputRef) => {
        // test if any email is valid--either the one we're currently editing or adding, or another one
        // for editing: remove the text we're editing from emails and check if any email or the text we're editing is valid
        // for adding: check if any email or the text we're adding is valid
        const hasAtLeastOneValidEmail = () =>
            editingEmailInput
                ? isValidEmail(editingEmailInput) || remove(editingIndex, emails).some(isValidEmail)
                : isValidEmail(emailInput) || emails.some(isValidEmail)

        // User closed edit-email mode
        const confirmEmailEdit = () => {
            const trimmedInput = editingEmailInput.trim()

            // if there's no text left, remove this email
            if (trimmedInput === '') handleRemoveEmail(editingIndex)
            else replaceEmail(trimmedInput)

            // there is no longer a "current" email being edited
            setEditingEmailInput('')
            setEditingIndex(null)
        }

        // the email at the current editingIndex will be replaced by this newEmail
        const replaceEmail = newEmail => {
            const newEmails = Array.from(emails)
            newEmails[editingIndex] = newEmail
            setEmails(newEmails)
        }

        // While you're in edit-email mode, some keys complete the edit and other possibly remove it
        const handleEditModeKey = e => {
            if (COMPLETE_KEYS.includes(e.key)) {
                e.preventDefault()
                confirmEmailEdit()
            } else if (BACK_KEYS.includes(e.key) && editingEmailInput.trim() === '') {
                // you can "backspace into a delete" if there is no remaining text in the edit box
                e.preventDefault()
                handleRemoveEmail(editingIndex)
            } else if (e.key === 'Escape') {
                // there is no longer a "current" email being edited
                setEditingEmailInput('')
                setEditingIndex(null)
            }
        }

        // While you're in add-email mode, some keys complete the edit and other's possibly put you in edit-email mode
        const handleAddModeKey = e => {
            if (COMPLETE_KEYS.includes(e.key)) {
                e.preventDefault()
                addEmail()
            } else if (BACK_KEYS.includes(e.key) && emailInput.trim() === '' && emails.length) {
                // you can "backspace into an edit" only if there is no existing text in the main edit box
                // and there IS an existing email to back into in the first place
                e.preventDefault()
                enterEditMode()
            } else if (e.key === 'Escape' && emailInput.length > 0) {
                setEmailInput('')
            } else if (e.key === 'Escape') {
                onCancel()
            }
        }

        const hasDuplicateEmails = () => emails.find(e => preexistingEmails.includes(e))

        // Add a new email to the list
        const addEmail = () => {
            const trimmedInput = emailInput.trim()
            if (trimmedInput) setEmails([...emails, trimmedInput])
            setEmailInput('')
        }

        // Set up the extra data to allow editing in edit-email mode
        const enterEditMode = () => {
            const lastEmailIndex = emails.length - 1
            setEditingEmailInput(emails[lastEmailIndex])
            setEditingIndex(lastEmailIndex)
        }

        // Remove the email at the given index and cancel editing mode if it matches the index
        const handleRemoveEmail = index => {
            const newEmails = [...emails]
            newEmails.splice(index, 1)
            setEmails(newEmails)

            if (index === editingIndex) setEditingIndex(null)
        }

        // Function to start edit-email mode
        const handleStartEditing = index => {
            setEditingEmailInput(emails[index])
            setEditingIndex(index)
        }

        // set the focus to the relatively tiny text input within the larger box that LOOKS like a text input
        const focusInput = () => inputRef.current?.focus()

        // display pill or an editor this email
        const renderExistingEmail = (email, index) => {
            const onEmailChange = e => setEditingEmailInput(e.target.value)
            const onStartEditing = () => handleStartEditing(index)
            const onRemoveEmail = e => {
                e.stopPropagation() // otherwise onStartEditing is called because the EmailPill button is under the 'X'
                handleRemoveEmail(index)
                focusInput() // refocus the add-mode text input
            }

            const isEditing = index === editingIndex
            return isEditing ? (
                <EmailEditor
                    key={email}
                    email={editingEmailInput}
                    onChange={onEmailChange}
                    onKeyDown={handleEditModeKey}
                    onBlur={confirmEmailEdit}
                    onRemoveEmail={onRemoveEmail}
                />
            ) : (
                <EmailPill key={email} email={email} onStartEditing={onStartEditing} onRemoveEmail={onRemoveEmail} />
            )
        }

        // State Variables
        const [emailInput, setEmailInput] = useState('')
        const [editingEmailInput, setEditingEmailInput] = useState('')
        const [editingIndex, setEditingIndex] = useState(null)
        const placeholder = emails.length ? '' : defaultPlaceholder

        // Refocus on main input when editingIndex is null
        useEffect(() => {
            if (editingIndex === null) focusInput()
        }, [editingIndex])

        // tell the caller there's a valid email
        useEffect(() => {
            setHasValidInvitation(hasAtLeastOneValidEmail())
        }, [emailInput, editingEmailInput, emails])

        return (
            <>
                {hasDuplicateEmails() && (
                    <Text
                        css={{
                            p: 8,
                            br: 8,
                            background: '$orange01',
                            mb: 16,
                            color: '$orange03',
                            fs: 14,
                            fw: 500,
                            textAlign: 'center',
                        }}
                    >
                        🤔 One or more emails have already been used
                    </Text>
                )}
                <StyledInviteInput onClick={focusInput}>
                    <FlexRow css={{ flexWrap: 'wrap', gap: 8, rowGap: 10 }}>
                        {emails.map(renderExistingEmail)}
                        <StyledMainTextInput
                            data-cy="invite-list"
                            ref={inputRef}
                            type="email"
                            value={editingIndex !== null ? '' : emailInput}
                            onChange={e => setEmailInput(e.target.value)}
                            onKeyDown={handleAddModeKey}
                            placeholder={placeholder}
                            style={{
                                width: `${emailInput.length ? getTextWidth(emailInput, baseInputStyle) : '161px'}`,
                            }}
                        />
                    </FlexRow>
                </StyledInviteInput>
            </>
        )
    }
)

export default InvitationDisplay
