import React from 'react'
import { Avatar, Box, Icon, Tooltip } from '../components-reusable/index.js'
import PossiblySuspendedUserName from '../components-reusable/PossiblySuspendedUserName.js'
import { styled } from '../range-theme/index.js'

const FilterPillWrapper = styled('div', {
    display: 'inline-flex',
    alignItems: 'center',
    background: '$primary01',
    color: '$neutral04',
    border: '1px solid $neutral07',
    borderRadius: '1000px',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '20px',
})

const FilterPillDeleteButtonWrapper = styled(Box, {
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    background: '$primary04',
    marginLeft: '6px',
    transitionDuration: '0.4s',

    '&:hover': {
        cursor: 'pointer',
        background: '$primary06',
    },
})

const FilterPillDeleteButton = ({ onDeleteClick }) => (
    <Tooltip tooltipText="Remove" side="bottom" align="center">
        <FilterPillDeleteButtonWrapper onClick={onDeleteClick}>
            <Icon name="close" css={{ size: '8px', color: '$neutral09' }} />{' '}
        </FilterPillDeleteButtonWrapper>
    </Tooltip>
)

export const FilterPill = ({ children, onDeleteClick }) => {
    return (
        <FilterPillWrapper css={{ padding: '6px 6px 6px 8px' }}>
            {children}
            <FilterPillDeleteButton onDeleteClick={onDeleteClick} />
        </FilterPillWrapper>
    )
}

export const FilterPillWithAvatar = ({ onDeleteClick, participantShape }) => {
    return (
        <FilterPillWrapper css={{ padding: '3px 6px 3px 3px' }}>
            <Avatar size="24" css={{ marginRight: '6px' }} participantShape={participantShape} />
            <PossiblySuspendedUserName participantShape={participantShape} />
            <FilterPillDeleteButton onDeleteClick={onDeleteClick} />
        </FilterPillWrapper>
    )
}
