import React from 'react'
import { DateFormat } from '@range.io/basic-types'

import { Image, Page as PDFPage, StyleSheet, Text, View, Link } from '@react-pdf/renderer'

import RangeLogo from './img/logo.png'

const styles = StyleSheet.create({
    footer: {
        position: 'absolute',
        minWidth: '100%',
        flex: '1 0 auto',
        bottom: 0,
        height: 40,
        borderTop: '1px solid #ECEEF1',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
    },

    footerInner: {
        padding: '0 18pt',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 9,
    },
    footerLabel: { color: '#333333', fontSize: '9pt', fontWeight: '600' },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerPrimaryLabel: { color: '#333333', fontSize: 12, fontWeight: '700' },
    headerSecondaryLabel: { fontSize: 11 },
})

const Footer = ({ organizationName }) => {
    return (
        <View style={styles.footer} fixed>
            <View style={styles.footerInner}>
                <Text style={styles.footerLabel}>{organizationName}</Text>
                <View style={{ ...styles.flexRow, gap: 4 }}>
                    <Text style={styles.footerLabel}>Made with</Text>
                    <Link href="https://range.io/">
                        <Image
                            style={{
                                'object-fit': 'contain',
                                width: '68pt',
                                height: 'auto',
                            }}
                            src={RangeLogo}
                        />
                    </Link>
                </View>
                <Text render={({ pageNumber, totalPages }) => `Page ${pageNumber}/${totalPages}`} />
            </View>
        </View>
    )
}

const Header = ({ logo, organizationName, projectName }) => {
    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                {!!logo && (
                    <Image
                        style={{
                            height: '30pt',
                            width: 'auto',
                        }}
                        source={logo}
                    />
                )}
                <View style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <Text style={styles.headerPrimaryLabel}>{organizationName}</Text>
                    <Text style={styles.headerSecondaryLabel}>{projectName}</Text>
                </View>
            </View>
            <View>
                <Text style={styles.headerSecondaryLabel}>{DateFormat.primary(new Date())}</Text>
            </View>
        </View>
    )
}

const Page = ({ children }) => (
    <PDFPage size="A4" style={{ padding: '18pt 0 44pt', fontFamily: 'Inter', width: '100%' }} wrap>
        {children}
    </PDFPage>
)

const PageContent = ({ children }) => <View style={{ padding: '0 27pt', width: '100%' }}>{children}</View>

const UserAvatar = ({ name, url, size = 16 }) => (
    <View
        style={{
            width: size,
            minWidth: size,
            height: size,
            borderRadius: '50%',
            border: '1pt solid #DDDDDD',
            backgroundColor: '#DDDDDD',
            boxSizing: 'border-box',
            overflow: 'hidden',
        }}
    >
        {url ? (
            <View
                style={{
                    overflow: 'hidden',
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                }}
            >
                <Image
                    src={url}
                    style={{
                        display: 'block',
                        width: 'auto',
                        height: 'auto',
                        minWidth: '100%',
                        objectFit: 'cover',
                        minHeight: '100%',
                    }}
                />
            </View>
        ) : (
            <View
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    backgroundColor: '#EEEEEE',
                }}
            >
                <Text
                    style={{
                        position: 'absolute',
                        color: '#666666',
                        fontSize: size * 0.6,
                        fontWeight: '500',
                        zIndex: 1,
                    }}
                >
                    {name ? name[0] : '-'}
                </Text>
            </View>
        )}
    </View>
)

export { Footer, Header, Page, PageContent, UserAvatar }
