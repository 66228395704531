import * as SwitchPrimitive from '@radix-ui/react-switch'
import { styled } from '@stitches/react'
import PropTypes from 'prop-types'
import React from 'react'
import { FlexRow, Icon, Tooltip } from '../components-reusable/index.js'

const StyledRow = styled(FlexRow, {
    color: '$neutral04',
    padding: '8px 24px 8px 24px',
    borderBottom: '1px solid $neutral07',
    background: '$neutral08',
    fs: '14px',
    alignItems: 'center',
    gap: '6px',
})

const StyledSwitch = styled(SwitchPrimitive.Root, {
    all: 'unset',
    width: 39,
    height: 22,
    backgroundColor: '$neutral06',
    borderRadius: '9999px',
    position: 'relative',
    transitionDuration: '0.4s',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    cursor: 'pointer',
    '&:focus': { boxShadow: `0 0 0 2px $primary04` },
    '&[data-state="checked"]': { backgroundColor: '$primary04' },
})

const StyledSwitchThumb = styled(SwitchPrimitive.Thumb, {
    display: 'block',
    width: 18,
    height: 18,
    backgroundColor: 'white',
    borderRadius: '9999px',
    transition: 'transform 100ms',
    transform: 'translateX(2px)',
    willChange: 'transform',
    '&[data-state="checked"]': { transform: 'translateX(19px)' },
})

// CollaborationWindowTimelineToggle
const Label = styled('label', {
    color: '$neutral04',
    fontSize: 14,
    userSelect: 'none',
})

const CollaborationWindowTimelineToggle = ({ checked, onCheckedChange }) => {
    return (
        <StyledRow css={{ alignItems: 'center', zIndex: 200, position: 'relative' }}>
            <Label htmlFor="s1">{'Show all timeline updates'}</Label>
            <Tooltip tooltipText={'By default, some timeline updates are hidden'} side="bottom" align="center">
                <FlexRow css={{ mr: 'auto' }}>
                    <Icon name="info" css={{ color: '$neutral05', width: '16px' }} />
                </FlexRow>
            </Tooltip>
            <StyledSwitch data-cy="timeline-toggle-button" checked={checked} onCheckedChange={onCheckedChange} id="s1">
                <StyledSwitchThumb />
            </StyledSwitch>
        </StyledRow>
    )
}

CollaborationWindowTimelineToggle.propTypes = {
    checked: PropTypes.bool.isRequired,
    onCheckedChange: PropTypes.func.isRequired,
}

export default CollaborationWindowTimelineToggle
