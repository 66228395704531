/*
 * Command to change the selected organization; writes to Redux and Segment
 */
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import { resources } from './resources.js'

/*
 * Type
 */
const SelectedOrganizationChangedCommand = tagged('SelectedOrganizationChangedCommand', {
    organizationId: StringTypes.Id,
})

/*
 * run
 */
SelectedOrganizationChangedCommand.run = (resources, selectedOrganizationChangedCommand) => {
    const { dispatch, getState, Segment } = resources
    const { organizationId } = selectedOrganizationChangedCommand

    dispatch(ReduxActions.selectedOrganizationChanged(organizationId))

    const state = getState()
    const organization = ReduxSelectors.selectedOrganization(state)
    const organizationRole = ReduxSelectors.selectedUsersRoleInSelectedOrganization(state)
    organization && organizationRole && Segment.sendGroup(organization, organizationRole)
}

/*
 * Public API (impure: collects resources)
 */
const selectedOrganizationChanged = organizationId =>
    SelectedOrganizationChangedCommand.run(resources(), SelectedOrganizationChangedCommand(organizationId))

export default selectedOrganizationChanged
