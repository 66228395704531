/*
 * Find the expected size of the text rendered with the given font information
 * @sig measureText :: (2dContext, String, String, String, Number) -> { textWidth: Number, textHeight: Number }
 */

// ---------------------------------------------------------------------------------------------------------------------
// Canvas2D operations
// ---------------------------------------------------------------------------------------------------------------------

/*
 * "measure" the given text using the built-in Canvas 2D measureText function
 * @sig measureText :: (Canvas2DContext, String, String, String, Number) -> Metrics
 *  Metrics = { textWidth: Number, textHeight: Number, textBaseline: Number }
 */
const measureText = (ctx, text, fontName, fontWeight, fontSize) => {
    const originalFont = ctx.font
    ctx.font = `${fontWeight} ${fontSize}px ${fontName}`
    const metrics = ctx.measureText(text)
    const textWidth = metrics.width
    const textHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent
    const textBaseline = metrics.fontBoundingBoxAscent

    ctx.font = originalFont // restore
    return { textWidth, textHeight, textBaseline }
}

/*
 * Is the point [x, y] withn the given Path? (Used for hit-testing whether a mousedown should select a given path)
 *
 * ctx.isPointInStroke uses the lineWidth of the Canvas' Context2D at the moment it's checking,
 * not the moment when the Path2D was drawn, so we need to temporarily set the current lineWidth
 * @sig isPointInStroke :: (Canvas2DContext, Number, Path, Number, Number) -> Boolean
 */
const isPointInStroke = (ctx, lineWidth, path2d, x, y) => {
    const currentLineWidth = ctx.lineWidth
    ctx.lineWidth = lineWidth
    const hit = ctx.isPointInStroke(path2d, x, y)
    ctx.lineWidth = currentLineWidth
    return hit
}

// Stroke the boundingBox in the given color
const strokeBoundingBox = (ctx, boundingBox, color) => {
    ctx.strokeStyle = color
    ctx.lineWidth = 2
    const path = new Path2D()
    path.rect(...boundingBox) // x, y, width, height
    ctx.stroke(path)
}

// convert from screen pixel position (eg. offsetX) to the Canvas' coordinate system
const pixelsToCanvasCoordinates = (canvas, px, py) => {
    const canvasX = px * (canvas.width / canvas.offsetWidth)
    const canvasY = py * (canvas.height / canvas.offsetHeight)
    return [canvasX, canvasY]
}

export { isPointInStroke, measureText, strokeBoundingBox, pixelsToCanvasCoordinates }
