import { useState } from 'react'

export const useLocalStorage = (key, initialValue) => {
    const getLocalStorageValue = () => {
        const item = window.localStorage.getItem(key)
        return item ? JSON.parse(item) : initialValue
    }

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === 'undefined') {
            return initialValue
        }
        try {
            return getLocalStorageValue()
        } catch (error) {
            // If error also return initialValue
            console.log(error)
            return initialValue
        }
    })
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = value => {
        try {
            // Allow value to be a function so we have same API as useState
            // Ensure we're working with the most recent data by passing getLocalStorageValue()
            const valueToStore = value instanceof Function ? value(getLocalStorageValue()) : value
            // Save state
            setStoredValue(valueToStore)
            // Save to local storage
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore))
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error)
        }
    }

    return [storedValue, setValue, getLocalStorageValue]
}

export default useLocalStorage
