/*
 * Main component for PDF Canvas Source Editor.
 * It contains a list of draggable items which represent the canvases
 * for the selected project. It allows re-ordering of them, renaming and
 * updating the files.
 *
 * Used in two variants - for editing in an existing project and as a part
 * of project creation wizard
 */

import { Pdf } from '@range.io/basic-types'
import { assoc, moveArrayItem, range } from '@range.io/functional'
import LookupTable from '@range.io/functional/src/lookup-table.js'
import { PDFDocument } from 'pdf-lib'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useStore } from 'react-redux'
import { v4 } from 'uuid'
import useBooleanState from '../components-reusable/hooks/useBooleanState.js'
import { Box, FlexColumn, FlexRow, ScrollArea } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { CanvasSourceShape } from '../react-shapes/canvas-source-shape.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import { AlertModal, CanvasEditorDeleteModal } from './Modal.js'
import PDFCanvasSourceEditEmptyView from './PDFCanvasSourceEditEmptyView.js'
import PDFCanvasSourceEditItem from './PDFCanvasSourceEditItem.js'
import { MAX_FILE_SIZE_MB } from './PDFCanvasSourceEditPanel.js'
import { PageLoadingCreateProject } from './PageLoading.js'

const StyledViewContainer = styled(FlexRow, {
    bg: '$neutral08',
    color: '$neutral04',
    width: '100%',
    height: '100vh',
})

const StyledSidePanel = styled(Box, {
    bg: '$neutral10',
    width: '376px',
    height: '100vh',
    borderRight: '1px solid $neutral07',
})

const StyledContent = styled(Box, {
    width: 'calc(100% - 376px)',
    position: 'relative',
})

// Can't use Droppable as is in StrictMode, this is a hack/fix for that
const StrictModeDroppable = ({ children, ...props }) => {
    const [enabled, setEnabled] = useState(false)
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true))
        return () => {
            cancelAnimationFrame(animation)
            setEnabled(false)
        }
    }, [])
    if (!enabled) {
        return null
    }
    return <Droppable {...props}>{children}</Droppable>
}

const InnerDraggableList = ({ items, itemOrder, itemProps }) => {
    return itemOrder.map((itemId, index) => {
        const item = items.get(itemId)
        return (
            <Draggable key={item.id} draggableId={item.id} index={index}>
                {(dragProvided, dragSnapshot) => (
                    <PDFCanvasSourceEditItem
                        item={item}
                        index={index + 1}
                        totalCount={items.length}
                        key={item.id}
                        provided={dragProvided}
                        dragSnapshot={dragSnapshot}
                        {...itemProps}
                    />
                )}
            </Draggable>
        )
    })
}

const DraggableList = ({ items, itemOrder, itemProps, onDragEnd }) => (
    <ScrollArea maxHeight={'calc(100vh)'} data-cy="draggable-list">
        <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="canvasList">
                {provided => (
                    <FlexColumn
                        css={{ m: '16px 16px 0px 16px' }}
                        className="droppable"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        <InnerDraggableList items={items} itemOrder={itemOrder} itemProps={itemProps} />
                        {provided.placeholder}
                    </FlexColumn>
                )}
            </StrictModeDroppable>
        </DragDropContext>
    </ScrollArea>
)

/*
 * Given a PDF File instance and a pageIndex extracts that page from it and
 * creates a new PDF file with only that single page copied. Then converts it into a new File.
 * @sig getPageFileFromPdf :: (File, Number) -> File
 */
const getPageFileFromPdf = async (pdfFile, pageIndex) => {
    const fileBuffer = await pdfFile.arrayBuffer()
    const pdfDoc = await PDFDocument.load(fileBuffer)
    const subDocument = await PDFDocument.create()
    const [copiedPage] = await subDocument.copyPages(pdfDoc, [pageIndex])
    subDocument.addPage(copiedPage)
    // Have to copy pages here like this, otherwise PDF doesn't save properly
    const pdfBytes = await subDocument.save()
    return new File([pdfBytes], pdfFile.name, { type: 'application/pdf' })
}

/*
 * Given an uploaded PDF, convert each page into data for that page:
 *
 * @sig canvasDataForFile :: (File) -> Promise { newCanvasIds: [Id], newCanvasItems: [CanvasItem] )
 *  CanvasItem = { id: Id, name: String, canvasSource: CanvasSource, justCreated: Boolean }
 */
const canvasDataForFile = async file => {
    const _getCanvasItemsForCanvasId = withPageNumber => async (canvasId, index) => {
        const pdfPage = pdfFiles[index]
        const canvasSource = CanvasSourceShape.from({ id: v4(), name: '', parentId: canvasId, type: 'pdf' })
        canvasSource.temporaryPdf = pdfPage
        canvasSource.pdfFile = await getPageFileFromPdf(file, index)
        const suffix = withPageNumber ? ` (${index + 1})` : ''

        return {
            id: canvasId,
            name: `${filename}${suffix}`,
            canvasSource,
            justCreated: true,
        }
    }

    const filename = file.name.split('.')[0]
    const pdf = await Pdf.fromFile(file)
    const newCanvasIds = range(0, Pdf.numPages(pdf)).map(() => v4())
    const pdfFiles = await Promise.all(range(0, Pdf.numPages(pdf)).map(pageIndex => pdf.wrapped.getPage(pageIndex + 1)))
    const withPageNumber = newCanvasIds.length > 1
    const canvasItemsForCanvasId = _getCanvasItemsForCanvasId(withPageNumber)
    const newCanvasItems = await Promise.all(newCanvasIds.map(canvasItemsForCanvasId))
    return { newCanvasIds, newCanvasItems }
}

/*
 * Given a CanvasSource make a full copy of it.
 * Makes sure new CanvasSource has a proper copy of the PDF file and is marked as a newly added.
 * @sig copyCanvasSource :: CanvasSource -> CanvasSource
 */
const copyCanvasSource = async canvasSource => {
    const newCanvasSource = CanvasSourceShape.from({
        ...canvasSource,
        id: v4(),
        parentId: v4(),
        name: `${canvasSource.name}_copy`,
        pdfUrl: null,
    })

    const saveUrlToFile = async url => {
        const response = await fetch(url)
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
        const blob = await response.blob()
        const file = new File([blob], `${canvasSource.name}_copy`, { type: blob.type })
        return file
    }

    const pdfFile = canvasSource.pdfUrl
        ? await saveUrlToFile(canvasSource.pdfUrl)
        : new File([canvasSource.pdfFile], `${canvasSource.pdfFile.name}_copy`, {
              type: canvasSource.pdfFile.type,
          })

    const pdf = await Pdf.fromFile(pdfFile)
    const newPdfPage = await pdf.wrapped.getPage(1)

    newCanvasSource.pdfFile = pdfFile
    newCanvasSource.temporaryPdf = newPdfPage

    return newCanvasSource
}

/*
 * Main canvas source editor component.
 * Expects a list of items (canvases) to display, it's order and an onSave callback.
 * There's also an additional function that renders the side panel. This is so the parent
 * can pass its props, and so this component can extend them when needed.
 */
const PDFCanvasSourceEdit = ({ items, onSave, order, renderSidePanel, itemUpdateLabel, onDataUpdate = null }) => {
    const [itemOrder, setItemOrder] = useState(order)
    const [canvasItems, setCanvasItems] = useState(items)
    const [storedItemId, setStoredItemId] = useState(null) // used to store temporarily an ID of an item for an action
    const { get: isDeleteWarningVisible, set: showDeleteWarning, reset: hideDeleteWarning } = useBooleanState()
    const { get: isFileAlertVisible, set: showFileAlert, reset: hideFileAlert } = useBooleanState()
    const { getState, dispatch } = useStore()
    const [shouldScrollToLastElement, setShouldScrollToLastElement] = useState(false)
    const [isLoadingFiles, setIsLoadingFiles] = useState(false)

    useEffect(() => {
        const newCanvasItems = canvasItems.map(item => {
            const newItem = items.get(item.id) ?? {}
            return { ...item, ...newItem }
        })
        setCanvasItems(newCanvasItems)
    }, [items])

    useEffect(() => {
        !!onDataUpdate && onDataUpdate(canvasItems, itemOrder)
        if (canvasItems.length && isLoadingFiles) setIsLoadingFiles(false)
    }, [canvasItems, itemOrder])

    useEffect(() => {
        if (shouldScrollToLastElement) {
            const item = document.querySelector(`#pdf-list [data-list-id="${canvasItems.length}"]`)
            item?.scrollIntoView({ behavior: 'smooth', block: 'end', align: true })
            setShouldScrollToLastElement(false)
        }
    }, [shouldScrollToLastElement])

    /*
     * Fired when user drops the item after dragging. Reorder elements appropriately.
     */
    const onDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return

        // dropped in the same place
        if (destination.index === source.index) return

        const newItemsOrder = moveArrayItem(itemOrder, source.index, destination.index)
        setItemOrder(newItemsOrder)
    }

    /*
     * User changed the name of a specific canvas
     * @sig handleNameChange :: (CanvasItem, String) -> ()
     */
    const handleNameChange = (item, newName) => {
        canvasItems.get(item.id).name = newName
        setCanvasItems(LookupTable(canvasItems))
    }

    /*
     * User changed the index of an item (through editing input, not dragging)
     * @sig handleNameChange :: (CanvasItem, Number) -> ()
     */
    const handleIndexChange = (item, newIndex) => {
        const newValidIndex = Math.min(Math.max(newIndex, 1), itemOrder.length)
        const currentItemIndex = itemOrder.findIndex(id => id === item.id)
        const newItemsOrder = moveArrayItem(itemOrder, currentItemIndex, newValidIndex - 1)

        setItemOrder(newItemsOrder)
    }

    /*
     * User requested to remove a canvas. We want to confirm this action.
     */
    const handleRequestDeleteCanvas = item => {
        showDeleteWarning()
        setStoredItemId(item.id)
    }

    /*
     * User confirmed his canvas removal request.
     * Hide warning modal and update the data and order.
     */
    const handleConfirmDeleteCanvas = () => {
        setCanvasItems(canvasItems.removeItem(canvasItems.get(storedItemId)))
        const newItemOrder = itemOrder.filter(id => id !== storedItemId)
        setItemOrder(newItemOrder)
        setStoredItemId(null)
        hideDeleteWarning()
    }

    /*
     * Button for all PDFs removal is present only on the new project wizard.
     * User confirmed his all pdfs removal request.
     * Remove all uploaded PDFs.
     */
    const handleDeleteAllPDFs = () => {
        setCanvasItems(items)
        setItemOrder(order)
        setStoredItemId(null)
    }

    /*
     * User canceled his canvas removal request. Hide warning modal.
     */
    const handleCancelDeleteCanvas = () => {
        setStoredItemId(null)
        hideDeleteWarning()
    }

    /*
     * User updated a single canvas' file.
     * We accept an array of files, but only first is taken into account.
     * Makes sure the canvas source object for that canvas gets update with the correct
     * PDF to render and a File reference for later save.
     * @sig handleFileUpdate :: ([File], CanvasItem) -> ()
     */
    const handleFileUpdate = async (acceptedFiles, item) => {
        const file = acceptedFiles[0] // only accept first files if many were provided
        const newPdfPageFile = await getPageFileFromPdf(file, 0)
        const pdf = await Pdf.fromFile(file)
        const pdfPage = await pdf.wrapped.getPage(1) // we only want the first page for a file update
        const oldFileName = canvasItems.get(item.id).canvasSource?.pdfFile?.name
        const fileName = oldFileName ? `"${oldFileName}"` : 'File'

        canvasItems.get(item.id).canvasSource.temporaryPdf = pdfPage
        canvasItems.get(item.id).canvasSource.pdfFile = newPdfPageFile
        setCanvasItems(LookupTable(canvasItems))

        dispatch(
            ReduxActions.toastAdded({
                id: item.id,
                toastLabel: `${fileName} has been replaced`,
                severity: 'success',
                showUndo: false,
                duration: 3000,
            })
        )
    }

    const displayNewItemsToasts = newCanvasItems => {
        let itemsToToast = newCanvasItems
        let itemsToGroupToast
        const maxToastCount = 10

        if (newCanvasItems.length > maxToastCount) {
            itemsToToast = newCanvasItems.slice(0, maxToastCount)
            itemsToGroupToast = newCanvasItems.slice(maxToastCount)
        }

        itemsToToast.forEach(item => {
            dispatch(
                ReduxActions.toastAdded({
                    id: item.id,
                    toastLabel: `"${item.name}.pdf" has been added`,
                    severity: 'success',
                    showUndo: false,
                    duration: 3000,
                })
            )
        })

        if (itemsToGroupToast) {
            let toastLabel = `+${itemsToGroupToast.length} more PDF pages have been added`

            if (itemsToGroupToast.length < 2) {
                toastLabel = `+${itemsToGroupToast.length} more PDF pages have been added`
            }

            dispatch(
                ReduxActions.toastAdded({
                    id: itemsToGroupToast[0].id,
                    toastLabel,
                    severity: 'success',
                    showUndo: false,
                    duration: 3000,
                })
            )
        }
    }

    /*
     * User dropped in a file to add it to this project as canvases.
     * We accept an array but only parse first file from it.
     * Creates new canvases and canvas sources (with proper structure for render and save)
     * but it's only temporary - no actions are committed outside until user action later.
     * @sig handleFileUpdate :: ([File]) -> ()
     */
    const handleFileDrop = async (acceptedFiles, rejectedFiles) => {
        const showErrorToast = (label = 'Something went wrong when uploading a pdf.') =>
            dispatch(
                ReduxActions.toastAdded({
                    id: 'pdfToast',
                    toastLabel: label,
                    severity: 'error',
                    showUndo: false,
                })
            )

        // Add loading state, so the user gets UI response about the app consuming uploaded PDF.
        // This is really visible with large PDF files.
        setIsLoadingFiles(true)
        try {
            if (rejectedFiles.length) {
                const reducer = (acc, c) => assoc(c.errors[0].code, true, acc)
                const errs = rejectedFiles.reduce(reducer, {}) // we only allow 1 file
                if (errs['file-too-large']) {
                    showFileAlert()
                    setIsLoadingFiles(false)
                    return
                } else {
                    showErrorToast()
                }
            }

            const promises = acceptedFiles.map(canvasDataForFile)
            const results = await Promise.all(promises)
            const newCanvasIds = results.flatMap(i => i.newCanvasIds)
            const newCanvasItems = results.flatMap(i => i.newCanvasItems)
            setCanvasItems(LookupTable([...canvasItems, ...newCanvasItems]))
            setItemOrder([...itemOrder, ...newCanvasIds])

            displayNewItemsToasts(newCanvasItems)
            setShouldScrollToLastElement(true)
        } catch (e) {
            console.error(e)
            showErrorToast(`Something went wrong when uploading a pdf. ${e}.`)
        }

        setIsLoadingFiles(false)
    }

    /*
     * User requested to duplicate a canvas.
     * Create a new canvas item with a new ID and add it to the canvasItems and itemOrder lists.
     */
    const handleDuplicate = async item => {
        const copiedCanvasSource = await copyCanvasSource(item.canvasSource)
        const newItemId = copiedCanvasSource.parentId
        const newItem = {
            ...item,
            id: newItemId,
            name: `${item.name} (copy)`,
            canvasSource: copiedCanvasSource,
            justCreated: true,
        }
        setCanvasItems(LookupTable([...canvasItems, newItem]))
        setItemOrder([...itemOrder, newItemId])
    }

    /*
     * Renders a warning modal if the user decides to delete a canvas.
     * Has 2 variants depending if that canvas has any geometries (pins) attached.
     */
    const renderWarningModal = () => {
        const canvasesWithGeometries = ReduxSelectors.canvasesWithGeometries(getState())
        const doesStoredCanvasHavePins = canvasesWithGeometries.findIndex(canvas => canvas.id === storedItemId) !== -1
        return doesStoredCanvasHavePins ? (
            <CanvasEditorDeleteModal.WithPins
                canvasName={canvasItems.get(storedItemId).name}
                onDelete={handleConfirmDeleteCanvas}
                onCancel={handleCancelDeleteCanvas}
            />
        ) : (
            <CanvasEditorDeleteModal.Empty onDelete={handleConfirmDeleteCanvas} onCancel={handleCancelDeleteCanvas} />
        )
    }

    const handleSave = () => onSave(canvasItems, itemOrder)

    // a list of props we want to pass to every list item
    const listItemProps = {
        onNameChange: handleNameChange,
        onIndexChange: handleIndexChange,
        onDelete: handleRequestDeleteCanvas,
        onFileUpdate: handleFileUpdate,
        onDuplicate: handleDuplicate, // Pass the duplicate function here
        updateLabelText: itemUpdateLabel,
    }

    const isViewEmpty = canvasItems.length === 0

    return (
        <StyledViewContainer>
            {isFileAlertVisible() && (
                <AlertModal
                    onAction={hideFileAlert}
                    buttonLabel="Close"
                    primaryLabel={`One or more files exceed the ${MAX_FILE_SIZE_MB} MB size limit`}
                    secondaryLabel={`Either compress the files or split them into individual PDFs.`}
                />
            )}
            {isDeleteWarningVisible() && renderWarningModal()}
            <StyledSidePanel>
                {renderSidePanel({
                    isSubmitDisabled: false,
                    onSave: handleSave,
                    onFileDrop: handleFileDrop,
                    onDeleteAllPDFs: handleDeleteAllPDFs,
                    isLoadingFiles,
                    showDeleteFilesButton: Boolean(canvasItems?.length),
                })}
            </StyledSidePanel>
            <StyledContent id="pdf-list">
                {isLoadingFiles && <PageLoadingCreateProject css={{ position: 'absolute', zIndex: '1' }} />}
                {isViewEmpty ? (
                    <PDFCanvasSourceEditEmptyView />
                ) : (
                    <DraggableList
                        items={canvasItems}
                        itemOrder={itemOrder}
                        itemProps={listItemProps}
                        onDragEnd={onDragEnd}
                    />
                )}
            </StyledContent>
        </StyledViewContainer>
    )
}

export default PDFCanvasSourceEdit
