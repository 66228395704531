import * as Slider from '@radix-ui/react-slider'
import React from 'react'
import { styled } from '../range-theme/index.js'

const SliderWrapper = styled('div', {
    background: '$neutral10',
    border: '1px solid $neutral07',
    borderRadius: 6,
    marginTop: 8,
})

const SliderRoot = styled(Slider.Root, {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    touchAction: 'none',
    minWidth: '38px',
    borderRadius: 6,
    marginTop: 8,
    marginBottom: 8,

    '&[data-orientation="horizontal"]': { flexDirection: 'row', width: 200, height: 20 },
    '&[data-orientation="vertical"]': { flexDirection: 'column', width: 20, height: 200 },
})

const SliderTrack = styled(Slider.Track, {
    backgroundColor: '$neutral06',
    position: 'relative',
    flexGrow: 1,
    borderRadius: '9999px',
    boxShadow: '0px 6px 10px 0px rgba(0, 0, 0, 0.10)',

    '&[data-orientation="horizontal"]': { height: 3 },
    '&[data-orientation="vertical"]': { width: 3 },
})

const SliderRange = styled(Slider.Range, {
    position: 'absolute',
    left: 0,
    borderRadius: '9999px',
    backgroundColor: '$primary04',

    '&[data-orientation="horizontal"]': { height: '100%' },
    '&[data-orientation="vertical"]': { width: '100%' },
})

const SliderThumb = styled(Slider.Thumb, {
    display: 'block',
    width: 16,
    height: 16,
    backgroundColor: '$neutral04',
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': { backgroundColor: '$neutral03' },
    '&:focus': { boxShadow: `0 2px 2px #00000025` },
})

const SizeSlider = ({ values, initialValue, onValueChanged, orientation = 'vertical' }) => {
    // Handle slider value change
    const handleValueChange = value => {
        const selectedValue = values[value[0]] // Get the value from the array by index
        onValueChanged(selectedValue) // Call the passed function with the new value
    }

    // Find the initial index of the initialValue in the values array
    const initialIndex = values.indexOf(initialValue)

    return (
        <SliderWrapper>
            <SliderRoot
                value={[initialIndex]}
                step={1}
                min={0}
                max={values.length - 1}
                onValueChange={handleValueChange}
                orientation={orientation}
                aria-label="Size selector"
            >
                <SliderTrack orientation="vertical">
                    <SliderRange />
                </SliderTrack>
                <SliderThumb data-cy="annotation-width-dropdown" />
            </SliderRoot>
        </SliderWrapper>
    )
}

export default SizeSlider
