import { useState } from 'react'

const useBooleanState = initial => {
    const [value, setValue] = useState(initial)
    const set = () => setValue(true)
    const unset = () => setValue(false)
    const reset = () => setValue(initial)
    const toggle = () => setValue(!value)
    const get = () => value

    return {
        get,
        set,
        reset,
        toggle,
        unset,
    }
}

export default useBooleanState
