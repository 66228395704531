// Segment snippet

const analytics = (window.analytics = window.analytics || [])

if (!window.Cypress && !analytics.initialize)
    if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.')
    else {
        analytics.invoked = !0
        analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
            'addIntegrationMiddleware',
            'setAnonymousId',
            'addDestinationMiddleware',
        ]

        analytics.factory = function (e) {
            return function () {
                if (window.analytics.initialized) return window.analytics[e].apply(window.analytics, arguments)
                const i = Array.prototype.slice.call(arguments)
                i.unshift(e)
                analytics.push(i)
                return analytics
            }
        }

        for (let i = 0; i < analytics.methods.length; i++) {
            const key = analytics.methods[i]
            analytics[key] = analytics.factory(key)
        }

        analytics.load = function (key, i) {
            const t = document.createElement('script')
            t.type = 'text/javascript'
            t.async = !0
            t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'
            const n = document.getElementsByTagName('script')[0]
            n.parentNode.insertBefore(t, n)
            analytics._loadOptions = i
        }

        // eslint-disable-next-line no-undef
        analytics._writeKey = SEGMENT_WRITE_KEY
        analytics.SNIPPET_VERSION = '4.16.1'
        analytics.load(analytics._writeKey)
    }
