/*
 * The Toolbar for PhotoAnnotations
 *
 * Fundamentally:
 *
 * - Done buttons
 * - Tools (select, arrow, line, scribble, text)
 * - Subtools (color, size, delete)
 *
 * Notes: Uses Radix Toggle Group
 *
 * There are some quirks. The Subtools show for any tool except 'select'; delete makes no sense without a selected
 *
 */

import * as Popover from '@radix-ui/react-popover'
import { styled } from '@stitches/react'
// Toolbar
import React, { useEffect } from 'react'
import { useKeyMaps } from '../components-reusable/hooks/index.js'
import { Button, FlexColumn, FlexRow, Icon, Text, ToggleIconGroup, Tooltip } from '../components-reusable/index.js'
import ColorSelect from './ColorSelect.js'
import SizeSlider from './SizeSlider.js'

const TOOLS = {
    SELECT: 'select',
    LINE: 'line',
    ARROW: 'arrow',
    SCRIBBLE: 'scribble',
    TEXT: 'text',
}

const SHOW_SUBTOOLS = {
    ALL: 'all', // including trash
    NO_TRASH: 'no trash', // no selected renderer; nothing to delete
    NONE: 'none', // no subtools at all
}

// prettier-ignore
const toolIcons = [
    { label: TOOLS.SELECT,   icon: 'selection',          tooltipText: 'Select'   },
    { label: TOOLS.ARROW,    icon: 'arrow',              tooltipText: 'Arrow'    },
    { label: TOOLS.LINE,     icon: 'line',               tooltipText: 'Line'     },
    { label: TOOLS.SCRIBBLE, icon: 'scribbleAnnotation', tooltipText: 'Scribble' },
    { label: TOOLS.TEXT,     icon: 'text',               tooltipText: 'Text'     },
]

const StyledPopoverContent = styled(Popover.Content, {
    zIndex: 1000,
})

const PhotoAnnotationToolbar = ({
    currentTool = TOOLS.SELECT,
    currentColor,
    currentSize,
    showSubtools,
    allColors,
    allSizes,

    onCancel,
    onDone,
    onColorChanged,
    onSizeChanged,
    onDelete,
    onToolChanged,
}) => {
    const doneButton = () => (
        <Button data-cy="commit-annotations" variant="primary" size="lg" onClick={onDone}>
            <Text>Done</Text>
        </Button>
    )

    const trashButton = () => (
        <Tooltip tooltipText="Delete">
            <Button variant={'dropdown-annotation-tools-delete'} onClick={onDelete}>
                <Icon name="trash" iconSize="20" />
            </Button>
        </Tooltip>
    )

    const sizeSelect = () => (
        <FlexColumn align="center">
            <Popover.Root>
                <Tooltip tooltipText="Size">
                    <Popover.Trigger asChild>
                        <Button
                            data-cy="annotation-width-picker"
                            variant={'dropdown-annotation-tools'}
                            css={{
                                w: 40,
                                h: 40,
                                borderTop: '1px solid $neutral07',
                                borderBottom: '1px solid $neutral07',
                            }}
                        >
                            <Icon name="thickness" iconSize="32" />
                        </Button>
                    </Popover.Trigger>
                </Tooltip>

                <Popover.Portal>
                    <StyledPopoverContent>
                        <SizeSlider values={allSizes} initialValue={currentSize} onValueChanged={onSizeChanged} />
                    </StyledPopoverContent>
                </Popover.Portal>
            </Popover.Root>
        </FlexColumn>
    )

    const possiblyShowSubordinateTools = () => {
        if (showSubtools === SHOW_SUBTOOLS.NONE) return null

        const showTrash = showSubtools === SHOW_SUBTOOLS.ALL

        return (
            <FlexRow css={{ gap: 10 }}>
                <FlexRow>
                    <ColorSelect initialValue={currentColor} colors={allColors} onColorChanged={onColorChanged} />
                    {sizeSelect()}
                </FlexRow>
                {showTrash && trashButton()}
            </FlexRow>
        )
    }

    useEffect(() => {
        const { pushKeyMap, popKeyMap } = useKeyMaps()
        pushKeyMap('PhotoAnnotationToolbar', { Escape: onCancel, Delete: onDelete, Backspace: onDelete })

        return () => popKeyMap('PhotoAnnotationToolbar')
    }, [])

    return (
        <FlexRow css={{ position: 'absolute', top: 20, left: 20, zIndex: 3, gap: 10 }}>
            {doneButton()}
            <ToggleIconGroup value={currentTool} onValueChange={onToolChanged} items={toolIcons} />
            {possiblyShowSubordinateTools()}
        </FlexRow>
    )
}

PhotoAnnotationToolbar.TOOLS = TOOLS
PhotoAnnotationToolbar.SHOW_SUBTOOLS = SHOW_SUBTOOLS

export default PhotoAnnotationToolbar
