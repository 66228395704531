import { Upload } from '@range.io/basic-types'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { URL_SEARCH_ORDER, useImageUrl } from '../components-reusable/hooks/useImageUrl.js'
import { Box } from '../components-reusable/index.js'
import { ParticipantShapePropTypes } from '../react-shapes/participant-shape.js'
import { ReduxSelectors } from '../redux/index.js'
import MediaViewItemContent from './MediaViewItemContent.js'
import MediaViewItemFooter from './MediaViewItemFooter.js'

import MediaViewItemHeader from './MediaViewItemHeader.js'

const MediaViewItem = ({
    collaborationName,
    participantShape,
    collaborationTagNames,
    date,
    description,
    name,
    fileType,
    fileSize,
    author,
    id,
    isSelected,
    locationLabel,
    mapPinType,
    upload,
    onClick,
    onDeleteClick,
    onDownloadClick,
    onLocationClick,
    onSelectClick,
    parentId,
    tagNames,
    identifierComponent,
    version,
    commentsCount,
}) => {
    const fakeUpload = { author, name, description, fileType, fileSize } // TODO: pass in a real Upload?
    const willHaveThumbnail = Upload.willHaveThumbnail(fakeUpload)
    const { url } = willHaveThumbnail ? useImageUrl(URL_SEARCH_ORDER.ANNOTATED_THUMBNAIL_1, id) : {}
    const { url: locationSnapshotUrl } = useImageUrl(URL_SEARCH_ORDER.LOCATION_THUMBNAIL_0, parentId)
    const allowUploadDelete = useSelector(ReduxSelectors.isDeleteAllowed('upload', upload))

    return (
        <Box css={{ aspectRatio: '444 / 427', width: '100%', maxWidth: '444px' }}>
            <MediaViewItemHeader
                date={date}
                allowDelete={allowUploadDelete}
                onDeleteClick={onDeleteClick}
                onDownloadClick={onDownloadClick}
                participantShape={participantShape}
            />
            <MediaViewItemContent
                data-cy="media-view-item"
                {...fakeUpload}
                imageUrl={url}
                isSelected={isSelected}
                onClick={onClick}
                onSelectClick={onSelectClick}
                willHaveThumbnail={willHaveThumbnail}
                version={version}
                commentsCount={commentsCount}
            />
            <MediaViewItemFooter
                collaborationName={collaborationName}
                collaborationTagNames={collaborationTagNames}
                isImage={Upload.isImage(fakeUpload)}
                locationLabel={locationLabel}
                locationSnapshotUrl={locationSnapshotUrl}
                mapPinType={mapPinType}
                onLocationClick={onLocationClick}
                tagNames={tagNames}
                identifierComponent={identifierComponent}
            />
        </Box>
    )
}

MediaViewItem.propTypes = {
    collaborationName: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired, // Date
    description: PropTypes.string,
    fileSize: PropTypes.number,
    fileType: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    locationLabel: PropTypes.string.isRequired,
    name: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onDownloadClick: PropTypes.func.isRequired,
    onSelectClick: PropTypes.func.isRequired,
    tagNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    thumbnailUrl: PropTypes.string,
    participantShape: PropTypes.shape(ParticipantShapePropTypes).isRequired,
}

export default MediaViewItem
