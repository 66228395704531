import { TagName } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer, { simpleInboundAddedRunCommand, simpleOutboundAddedRunCommand } from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// TagNameAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const TagNameAddedCommand = taggedSum('TagNameAddedCommand', {
    Inbound: { tagName: 'TagName' },
    Outbound: { tagName: 'TagName' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------
const getItem = c => c.tagName

/*
 * A TagNameAddedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the TagName)
 */
const runInboundCommand = (resources, command) =>
    simpleInboundAddedRunCommand(resources, command, TagName, ReduxActions.tagNameAdded, getItem)

/*
 * A TagNameAddedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) =>
    simpleOutboundAddedRunCommand(
        resources,
        command,
        TagName,
        ReduxActions.tagNameAdded,
        ReduxActions.tagNameRemoved,
        getItem,
        'tagNames'
    )

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        TagNameAddedCommand,
        CommandPlayer({
            CommandType: TagNameAddedCommand,
            Type: TagName,
            collectionPath: projectId => `/projects/${projectId}/tagNames/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
        })
    )
}

export { TagNameAddedCommand, addCommand }
