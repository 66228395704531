/*
 * RadioButton from radix-ui:
 *
 *   RadioGroup
 *      RadioGroup.Item
 */

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { styled } from '../range-theme/index.js'

const RadioGroup = styled(RadioGroupPrimitive.Root, { display: 'flex' })
const RadioGroupItem = styled(RadioGroupPrimitive.Item, {})

export { RadioGroup, RadioGroupItem }
