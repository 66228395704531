import * as Auth from '@firebase/auth'
import {
    browserLocalPersistence,
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    sendEmailVerification,
    setPersistence,
    signInWithEmailAndPassword,
    signInWithPopup,
} from 'firebase/auth'
import { auth } from './configure-environment/config-local.js'

// ---------------------------------------------------------------------------------------------------------------------
// Wrap function in a Promise
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Wrap sendEmailVerification in a Promise
 * @sig sendVerificationEmail = AuthUserCredential -> Promise AuthUserCredential
 */
const sendVerificationEmail = user =>
    new Promise((resolve, reject) =>
        sendEmailVerification(user)
            .then(() => resolve(user))
            .catch(reject)
    )

/*
 * Wrap signInWithEmailAndPassword in a Promise
 * @sig _signInWithEmailAndPassword = (email, password) -> Promise AuthUserCredential
 */
const _signInWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => signInWithEmailAndPassword(auth, email, password).then(resolve).catch(reject))

/*
 * Wrap createUserWithEmailAndPassword in a Promise
 * @sig _createUserWithEmailAndPassword :: (String, String) -> Promise AuthUserCredential
 */
const _createUserWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) =>
        createUserWithEmailAndPassword(auth, email, password)
            .then(userCredential => resolve(userCredential.user))
            .catch(reject)
    )

/*
 * Wrap signInWithPopup in a Promise
 * @sig _signInWithGoogle :: () -> Promise AuthUserCredential
 */
const _signInWithGoogle = () => {
    return new Promise((resolve, reject) => {
        signInWithPopup(auth, new GoogleAuthProvider())
            .then(userCredential => resolve(userCredential.user))
            .catch(reject)
    })
}

/*
 * Wrap setPersistence in a Promise
 * @sig _setPersistence :: () -> Promise void
 */
const _setPersistence = () =>
    new Promise((resolve, reject) => setPersistence(auth, browserLocalPersistence).then(resolve).catch(reject))

// ---------------------------------------------------------------------------------------------------------------------
// Process errors
// ---------------------------------------------------------------------------------------------------------------------

/*
 * An error thrown by the server MAY have this format, which includes an embedded error:
 *
 *     {
 *       code: "auth/internal-error",
 *       customData: { appName: "[DEFAULT]" },
 *       name: "FirebaseError"
 *       message: "Firebase: ((HTTP request to ... returned HTTP error 400: {"error":{"message":"No Invitation for jeff@j.com","status":"INVALID_ARGUMENT"}})) (auth/internal-error)."
 *       stack: ...
 *     }
 *
 * This function will TRY to pull the message out of the embedded error or -- failing that -- return error.message
 * @sig tryToParseEmbeddedError :: Error -> String
 */
const tryToParseEmbeddedError = error => {
    try {
        const embeddedJson = error.message.replace(/[^{]*({[^}]*}}).*/, '$1')
        const embeddedError = JSON.parse(embeddedJson)
        const { message } = embeddedError.error
        return message
    } catch (ignoredMessage) {
        // canonicalizing didn't work; just return the original message
        return error.message
    }
}

/*
 * Errors come in many varieties; try to make them sound consistent and rethrow
 * @sig throwCanonicalError = Error -> Error
 *
 * auth/popup-closed-by-user:
 *    the user closes the popup window before completing the authentication process.
 *    You can handle this error by notifying the user or prompting them to retry the authentication.
 * auth/popup-blocked:
 *    the popup window is blocked by the browser's popup blocker.
 *    You can handle this error by providing instructions to the user on how to disable the popup blocker or suggesting
 *    an alternative authentication method.
 * auth/account-exists-with-different-credential:
 *    the user tries to sign in with a Google account that is already associated with a different authentication method
 *    (e.g., email/password or another social provider).
 *    You can handle this error by guiding the user to sign in using the same method they used previously or providing
 *    options for merging accounts if applicable.
 * auth/operation-not-supported-in-this-environment:
 *    the signInWithPopup method is not supported in the current environment, such as when running in a Node.js
 *    environment or an unsupported browser.
 *    You can handle this error by using an alternative authentication method, such as signInWithRedirect or a
 *    server-side flow.
 *
 * Errors from logging in via signInWithPopup can take 10 seconds or longer to be returned
 */
// prettier-ignore
const throwCanonicalError = error => {
    const c = error.code
    if (c === 'auth/invalid-email')                            throw new Error(`That email doesn't look right`)
    if (c === "auth/error-code:-47")                           throw new Error(`That email is already in use`) // no idea where =47 comes from
    if (c === 'auth/email-already-in-use')                     throw new Error(`That email is already in use`)
    if (c === 'auth/user-not-found')                           throw new Error(`Your email and password don't match`)
    if (c === 'auth/wrong-password')                           throw new Error(`Your email and password don't match`)
    if (c === 'auth/invalid-login-credentials')                throw new Error(`Your email and password don't match`)
    if (c === 'auth/popup-closed-by-user')                     throw new Error(`You closed the popup window before completing the authentication process`)
    if (c === 'auth/popup-blocked')                            throw new Error(`Your browser is set to prevent popups from appearing`)
    if (c === 'auth/account-exists-with-different-credential') throw new Error(`You tried to sign in with a Google account that is already associated with a different authentication method`)
    if (c === 'auth/too-many-requests')                        throw new Error(`Access temporarily disabled due to too many log in attempts. Please try again later, or reset your password`)
    
    throw new Error(tryToParseEmbeddedError(error))
}

/*
 * Try to log in via email + password, after setting persistence to last "forever"
 * @sig attemptLoginWithEmailAndPassword :: (String, String) -> Promise void | Error
 */
const attemptLoginWithEmailAndPassword = async (email, password) => {
    try {
        await _setPersistence()
        await _signInWithEmailAndPassword(email, password)
    } catch (error) {
        throwCanonicalError(error)
    }
}

/*
 * Try to sign up with the given email and password and send a verification email
 * @sig attemptSignUpWithEmailAndPassword ::  (String, String) -> Promise void | Error
 */
const attemptSignUpWithEmailAndPassword = async (email, password) => {
    try {
        const authUser = await _createUserWithEmailAndPassword(email, password)
        await sendVerificationEmail(authUser)
    } catch (error) {
        throwCanonicalError(error)
    }
}

/*
 * Try to log in with Google
 * @sig attemptLogInWithGoogle ::  () -> Promise void | Error
 */
const attemptLogInWithGoogle = async () => {
    try {
        await _signInWithGoogle()
    } catch (error) {
        throwCanonicalError(error)
    }
}

/*
 * Try to sign up with Google -- no email verification is necessary Google has already verified your email
 * In this case, signing and logging in are the same
 * @sig attemptSignUpWithGoogle ::  (String, String) -> Promise void | Error
 */
const attemptSignUpWithGoogle = attemptLogInWithGoogle

/*
 * Sign out
 */
const attemptSignOut = async () => new Promise((resolve, reject) => Auth.signOut(auth).then(resolve).catch(reject))

export {
    attemptLogInWithGoogle,
    attemptLoginWithEmailAndPassword,
    attemptSignUpWithEmailAndPassword,
    attemptSignUpWithGoogle,
    sendVerificationEmail,
    attemptSignOut,
}
