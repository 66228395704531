/*
 * Infrastructure to provide "resources" to Commands
 * @see README.md
 */
import { assoc, dissoc } from '@range.io/functional'
import * as Segment from '../../segment/segment.js'

// singleton holding resources
let _resources = {
    Segment,
    dispatch: undefined, // defined once the store has loaded
    getState: undefined, // defined once the store has loaded
    foregroundMapboxMap: undefined, // defined once there IS a foreground map
}

// return the current resources
const resources = () => _resources

// Add / remove a resource for use by commands
const addResource = (name, value) => (_resources = assoc(name, value, _resources))
const removeResource = name => (_resources = dissoc(name, _resources))

export { resources, addResource, removeResource }
