import React, { useContext } from 'react'
import { useStore } from 'react-redux'
import CommandHistory from './command-history.js'
import CommandPlayer from './command-player.js'

// hook to return the CommandHistory singleton
const CommandHistoryContext = React.createContext()
const useCommandHistory = () => useContext(CommandHistoryContext)

// Provider to make the singleton CommandHistory available anywhere using useCommandHistory
const UndoRedo = ({ children }) => {
    const store = useStore()
    return <CommandHistoryContext.Provider value={CommandHistory(store)}>{children}</CommandHistoryContext.Provider>
}

export { useCommandHistory, UndoRedo, CommandPlayer }
