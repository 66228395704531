/*
 * Integration with Sentry.io
 *
 * https://docs.sentry.io/platforms/javascript/configuration/options/
 */

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

// handle a Sentry Event before it's actually sent to Sentry
// https://docs.sentry.io/platforms/javascript/configuration/filtering/
const beforeSend = (event, hint) => {
    // console.log('About to send event to Sentry.io ', event, hint)
    return event
}

// handle a Sentry Breadcrumb before it's actually sent to Sentry.
// (Breadcrumbs try to show the history of what the user was doing when an error happened, so they're
// sent much more often than Events)
// https://docs.sentry.io/platforms/javascript/configuration/filtering/
const beforeBreadcrumb = (breadcrumb, hint) => {
    // console.log('About to send breadcrumb to Sentry.io ', breadcrumb, hint)
    return breadcrumb
}

// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
// We recommend adjusting this value in production

// eslint-disable-next-line no-unused-vars
const browserTracing = () => ({
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0, // should be less than 1
})

const replay = () => ({
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        new Sentry.Replay({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
})

const basicOptions = environment => ({
    dsn: 'https://edb676c4e55f47479a6d1f1ae1b212c3@o1384740.ingest.sentry.io/6703476',
    environment,

    beforeSend,
    beforeBreadcrumb,
})

// denyUrls: URL's we don't control that are throwing errors
// autoSessionTracking: track sessions too
const initializeSentry = environment => {
    // assuming there's no reason for browserTracing AND replay at the same time...
    Sentry.init({ ...basicOptions(environment), ...replay() /* , browserTracing() */ })
}

export default initializeSentry
