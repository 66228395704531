import {
    format,
    formatDistanceStrict,
    isAfter,
    isBefore,
    isSameDay,
    isSameYear,
    parseISO,
    subDays,
    subHours,
    subSeconds,
} from 'date-fns'
// import * as DateFnsTz from 'date-fns-tz'

// const { formatInTimeZone: _formatInTimeZone } = DateFnsTz

// const formatInTimeZone = (...args) => {
//     if (window.redactTimes) return 'redacted'
//     return _formatInTimeZone(...args)
// }

/*
 * Handle 12:36 noon => 12:36 pm; 12:36 midnight => 12:36 am
 */
const formatTime = comparisonDate => {
    const result = format(comparisonDate, 'h:mm bbb')

    if (result.match(/midnight/) && !result.match(/12:00/)) return result.replace(/midnight/, 'am')
    if (result.match(/noon/) && !result.match(/12:00/)) return result.replace(/noon/, 'pm')

    return result
}

const primary = (comparisonDate, now = new Date()) => {
    /*
     * HACK: Snapshots capture the whole screen, including strings like "17 seconds ago"
     * which are irrelevant for comparison purposes. Our tests will set window.redactTimes
     * to cause these values to always be replaced with the word "redacted"
     */
    if (window?.redactTimes) return 'redacted date/time'

    const getSuffix = () => {
        if (isAfter(comparisonDate, subSeconds(now, 60))) return '(just now)'
        // if we are on yesterday but not within an hour then force formatting in hours (no days)
        if (isSameDay(comparisonDate, subDays(now, 1)) && isBefore(comparisonDate, subHours(now, 1)))
            return `(${formatDistanceStrict(comparisonDate, now, { unit: 'hour' })} ago)`
        return `(${formatDistanceStrict(comparisonDate, now)} ago)`
    }

    const timestamp = formatTime(comparisonDate)
    const thisYearStamp = format(comparisonDate, 'MMM d')
    const olderYearStamp = format(comparisonDate, 'MMM d, y')

    if (isSameDay(comparisonDate, now)) return `Today @${timestamp} ${getSuffix()}`
    if (isSameDay(comparisonDate, subDays(now, 1))) return `Yesterday @${timestamp} ${getSuffix()}`
    if (isSameYear(comparisonDate, now)) return `${thisYearStamp} @${timestamp} ${getSuffix()}`
    return `${olderYearStamp} @${timestamp} ${getSuffix()}`
}

const ago = (comparisonDate, now = new Date()) => {
    /*
     * HACK: Snapshots capture the whole screen, including strings like "17 seconds ago"
     * which are irrelevant for comparison purposes. Our tests will set window.redactTimes
     * to cause these values to always be replaced with the word "redacted"
     */
    if (window?.redactTimes) return 'redacted date/time'

    const getSuffix = () => {
        if (isAfter(comparisonDate, subSeconds(now, 60))) return 'just now'
        // if we are on yesterday but not within an hour then force formatting in hours (no days)
        if (isSameDay(comparisonDate, subDays(now, 1)) && isBefore(comparisonDate, subHours(now, 1)))
            return `${formatDistanceStrict(comparisonDate, now, { unit: 'hour' })} ago`
        return `${formatDistanceStrict(comparisonDate, now)} ago`
    }

    return getSuffix()
}

const secondary = (comparisonDate, now = new Date()) => {
    /*
     * HACK: Snapshots capture the whole screen, including strings like "17 seconds ago"
     * which are irrelevant for comparison purposes. Our tests will set window.redactTimes
     * to cause these values to always be replaced with the word "redacted"
     */
    if (window?.redactTimes) return 'redacted date/time'

    const yesterday = subDays(now, 1)
    const thisYearStamp = format(comparisonDate, 'MMM d')
    const olderYearStamp = format(comparisonDate, 'MMM d, y')

    if (isSameDay(comparisonDate, now)) return `(Today)`
    if (isSameDay(comparisonDate, yesterday)) return `(Yesterday)`
    if (isSameYear(comparisonDate, now)) return `on ${thisYearStamp}`
    return `on ${olderYearStamp}`
}

const tertiary = (comparisonDate, now = new Date()) => {
    /*
     * HACK: Snapshots capture the whole screen, including strings like "17 seconds ago"
     * which are irrelevant for comparison purposes. Our tests will set window.redactTimes
     * to cause these values to always be replaced with the word "redacted"
     */
    if (window?.redactTimes) return 'redacted date/time'

    const yesterday = subDays(now, 1)
    const timestamp = formatTime(comparisonDate, 'h:mm bbb')
    const thisYearStamp = format(comparisonDate, 'MMM d')
    const olderYearStamp = format(comparisonDate, 'MMM d, y')

    if (isSameDay(comparisonDate, now)) return `Today @${timestamp}`
    if (isSameDay(comparisonDate, yesterday)) return `Yesterday @${timestamp}`
    if (isSameYear(comparisonDate, now)) return `${thisYearStamp} @${timestamp}`
    return `${olderYearStamp} @${timestamp}`
}

const simple = (comparisonDate, now = new Date(), withSuffix = true) => {
    /*
     * HACK: Snapshots capture the whole screen, including strings like "17 seconds ago"
     * which are irrelevant for comparison purposes. Our tests will set window.redactTimes
     * to cause these values to always be replaced with the word "redacted"
     */
    if (window?.redactTimes) return 'redacted date/time'

    const getSuffix = () => {
        if (isAfter(comparisonDate, subSeconds(now, 60))) return '(just now)'
        // if we are on yesterday but not within an hour then force formatting in hours (no days)
        if (isSameDay(comparisonDate, subDays(now, 1)) && isBefore(comparisonDate, subHours(now, 1)))
            return `(${formatDistanceStrict(comparisonDate, now, { unit: 'hour' })} ago)`
        return `(${formatDistanceStrict(comparisonDate, now)} ago)`
    }

    const timestamp = formatTime(comparisonDate)
    const dateStamp = format(comparisonDate, 'MMM d, y')
    const suffix = withSuffix ? getSuffix() : ''

    return `${dateStamp} @${timestamp} ${suffix}`
}

const TimeFormat = { primary, secondary, simple, ago, tertiary }

const safeDateFormat = (...args) => {
    if (window.redactTimes) return 'redacted'
    return format(...args)
}

const DateFormat = {
    /*
     * Format the date using the specified timezone, which typically should be the timezone of a Project
     * (but for now is always 'America/Los_Angeles'). This is different than "normal" dates, for which
     * the timezone is the current user's computer's timezone.
     *
     * So: a "due date" of April 9, 2022 is due at 23:59:59 April 9, 2022 Los Angeles time.
     * Even though a Polish user would experience this moment the next date at 08:59:59 April 10, 2022 (9 hours ahead),
     * that Polish user should still see April 9th, because that's the timezone of the Project.
     */
    // temporary fix to ignore timezone
    primary: (date, timezone = 'America/Los_Angeles') => safeDateFormat(date, 'MMM d, y'), // formatInTimeZone(date, timezone, 'MMM d, y'),
    parseISOStringToDate: dateString => parseISO(dateString),
}

export { TimeFormat, DateFormat }
