const updateProgressElement = text => {
    const progressElement = document.getElementById('progress-text')
    if (progressElement) progressElement.textContent = text
}

const reportPromisesProgress = (promisesArray, composeText) => {
    // Ensure all elements are Promises
    const validPromises = promisesArray.filter(p => p instanceof Promise)
    const promisesCount = validPromises.length
    let proceededCount = 0

    return validPromises.map(
        promise =>
            promise &&
            promise
                .then(() => {
                    ++proceededCount
                    const text = composeText(proceededCount, promisesCount)
                    updateProgressElement(text)
                })
                .catch(error => {
                    console.error('A promise failed to resolve:', error)
                })
    )
}

export { reportPromisesProgress, updateProgressElement }
