import { FeedItem, Update } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import { itemFromFeedItem } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// UpdateAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const UpdateAddedCommand = taggedSum('UpdateAddedCommand', {
    Inbound: { feedItem: 'FeedItem' },
    // NO Outbound: always done when modifying a Collaboration
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * An Update FeedItem arrived; read the Update and send it to redux
 */
const runInboundCommand = async (resources, command) => {
    const update = await itemFromFeedItem(Update, 'Update', 'added', command.feedItem)
    if (!update) return

    const { dispatch } = resources
    dispatch(ReduxActions.updateAdded(update))
}

/*
 * A UpdateAddedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 * BUT these currently ALL are handled in the transaction in GeometriesAddedCommand
 */
const runOutboundCommand = async (resources, command) => {
    throw new Error('UpdateAddedCommand.runOutboundCommand should never have been called')
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        UpdateAddedCommand,
        CommandPlayer({
            CommandType: UpdateAddedCommand,
            Type: FeedItem,
            collectionPath: (projectId, userId) => `/projects/${projectId}/participants/${userId}/feedItems`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
        })
    )
}

export { UpdateAddedCommand, addCommand }
