/*
 * SelectRectangleMode
 *
 * Special mode to handle direct selection of an arrow annotation
 * Extends on SelectMode
 *
 * Type SelectRectangleModeState:
 *    feature : DrawFeature<JsonFeature>
 */

import SelectMode from './select-mode.js'

const SelectRectangleMode = { ...SelectMode }

/*
 * @sig onSetup :: ({baseState: SelectModeState}) -> SelectRectangleModeState
 */
SelectRectangleMode.onSetup = function (opts) {
    const state = { ...opts.baseState }
    return state
}

/*
 * @sig onVertex :: (SelectRectangleModeState, Event) -> ()
 */
SelectRectangleMode.onVertex = function (state, e) {
    this.startDragging(state, e)
    const about = e.featureTarget.properties
    state.selectedCoordPaths = [about.coord_path]
    const customCursorName = about.coord_path === '0.3' || about.coord_path === '0.1' ? 'resize-1' : 'resize-2'
    this.map.fire('draw.changeCursor', { cursorName: customCursorName })
    const selectedCoordinates = this.pathsToCoordinates(state.featureId, state.selectedCoordPaths)
    this.setSelectedCoordinates(selectedCoordinates)
    const featureId = state.featureId
    this.doRender(featureId)
}

/*
 * User is dragging a vertex: update neighboring vertices to keep proportional rectangle shape
 * Impure: changes coordinates of neighboring vertices
 * @sig dragVertex :: (SelectRectangleModeState, Event, Number) -> ()
 */
SelectRectangleMode.dragVertex = function (state, e, delta) {
    SelectMode.dragVertex.bind(this)(state, e, delta)
    if (state.selectedCoordPaths[0] === '0.0') {
        state.feature.updateCoordinate('0.1', state.feature.coordinates[0][1][0], state.feature.coordinates[0][0][1])
        state.feature.updateCoordinate('0.3', state.feature.coordinates[0][0][0], state.feature.coordinates[0][3][1])
    }
    if (state.selectedCoordPaths[0] === '0.1') {
        state.feature.updateCoordinate('0.0', state.feature.coordinates[0][0][0], state.feature.coordinates[0][1][1])
        state.feature.updateCoordinate('0.2', state.feature.coordinates[0][1][0], state.feature.coordinates[0][2][1])
    }
    if (state.selectedCoordPaths[0] === '0.2') {
        state.feature.updateCoordinate('0.1', state.feature.coordinates[0][2][0], state.feature.coordinates[0][1][1])
        state.feature.updateCoordinate('0.3', state.feature.coordinates[0][3][0], state.feature.coordinates[0][2][1])
    }
    if (state.selectedCoordPaths[0] === '0.3') {
        state.feature.updateCoordinate('0.0', state.feature.coordinates[0][3][0], state.feature.coordinates[0][0][1])
        state.feature.updateCoordinate('0.2', state.feature.coordinates[0][2][0], state.feature.coordinates[0][3][1])
    }
}

export default SelectRectangleMode
