import { styled } from '@stitches/react'
import PropTypes from 'prop-types'
import React from 'react'
import { Text } from './Text.js'

const StyledStatusPill = styled(Text, {
    borderRadius: 999,
    fontSize: 11,
    fw: 500,
    lineHeight: '17px',
    height: '21px',
    padding: '0 6px',
    boxSizing: 'border-box',
    alignItems: 'center',
    width: 'fit-content',

    variants: {
        variant: {
            default: { background: '$neutral08', color: '$neutral05', border: '1px solid $neutral07' },
            pending: { background: '$primary02', color: '$primary04' },
            expired: { background: '$red01', color: '$red03' },
        },
    },
})

const StatusPill = ({ children, variant = 'default' }) => (
    <StyledStatusPill
        variant={variant}
        css={{
            display: 'inline-flex',
        }}
    >
        {children}
    </StyledStatusPill>
)

StatusPill.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(['default', 'pending', 'expired']),
}

export default StatusPill
