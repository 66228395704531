import Rive from '@rive-app/react-canvas'
import React from 'react'
import LoadingDotsAnimation from '../components-reusable/LoadingDotsAnimation.js'
import { styled } from '../range-theme/index.js'
import { Text } from '../components-reusable/Text.js'

const StyledContainer = styled('div', {
    height: '100vh',
    width: '100%',
    backgroundColor: '$neutral09',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
})

const StyledContainerMedia = styled('div', {
    height: '100vh',
    width: '100%',
    backgroundColor: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
})

const StyledPlayerContainer = styled('div', {
    height: '148px',
    width: '148px',
})

const PageLoading = ({ css }) => {
    return (
        <StyledContainer data-cy="page-loader" css={css}>
            <StyledPlayerContainer>
                <Rive src="/range_loading_animation.riv" />
            </StyledPlayerContainer>
            <LoadingDotsAnimation variant="secondary" />
        </StyledContainer>
    )
}

const PageLoadingWithMessage = ({ css, messageText }) => (
    <StyledContainer data-cy="loader" css={css}>
        <StyledPlayerContainer>
            <Rive src="/range_loading_animation.riv" />
        </StyledPlayerContainer>
        <Text css={{ fs: 16, fw: 500, mt: 8 }}>{messageText}</Text>
    </StyledContainer>
)

const PageLoadingCreateProject = ({ css }) => (
    <PageLoadingWithMessage css={css} messageText="Uploading in progress..." />
)

const PageLoadingMedia = ({ css }) => {
    return (
        <StyledContainerMedia css={css}>
            <StyledPlayerContainer>
                <Rive src="/range_loading_animation.riv" />
            </StyledPlayerContainer>
            <LoadingDotsAnimation variant="secondary" />
        </StyledContainerMedia>
    )
}

export default PageLoading
export { PageLoadingMedia, PageLoadingCreateProject, PageLoadingWithMessage }
