/*
 * See Readme
 */

import { FeedItem } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import { deleteUpload } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// UploadRemovedCommand
// ---------------------------------------------------------------------------------------------------------------------
const UploadRemovedCommand = taggedSum('UploadRemovedCommand', {
    Inbound: { feedItem: 'FeedItem' },
    Outbound: { uploadId: StringTypes.Id }, // just the Upload's id to allow it to work with UploadShapes too
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A UploadRemovedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Upload)
 */
const runInboundCommand = async (resources, command) => {
    const { dispatch } = resources
    const { feedItem } = command
    const { action, itemType, itemId } = feedItem

    if (itemType !== 'Upload' || action !== 'deleted') return // not for us

    dispatch(ReduxActions.uploadRemoved({ id: itemId }))
}

/*
 * A UploadRemovedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { projectId, dispatch, displayError } = resources
    const { uploadId } = command

    try {
        await deleteUpload(projectId, uploadId)
        dispatch(ReduxActions.uploadRemoved(uploadId))

        // show the toast
        const toastLabel = `Media upload(s) successfully deleted`
        const toast = { id: 'upload', severity: 'success', toastLabel, showUndo: false }
        dispatch(ReduxActions.toastAdded(toast))
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        UploadRemovedCommand,
        CommandPlayer({
            CommandType: UploadRemovedCommand,
            Type: FeedItem,
            collectionPath: (projectId, userId) => `/projects/${projectId}/participants/${userId}/feedItems`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added', // the FeedItem is ADDED even though the Upload is removed
        })
    )
}

export { UploadRemovedCommand, addCommand }
