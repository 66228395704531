/*
 * See Readme
 */

import { Comment, FeedItem } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import { itemFromFeedItem } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'
import { updateComment } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// CommentChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const CommentChangedCommand = taggedSum('CommentChangedCommand', {
    Inbound: {
        feedItem: 'FeedItem',
    },
    Outbound: {
        id: StringTypes.Id, // Comment id
        changes: 'Object', // each entry defines key/value of the existing Comment that changed
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A CommentChangedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Comment)
 */
const runInboundCommand = async (resources, command) => {
    const comment = await itemFromFeedItem(Comment, 'Comment', 'updated', command.feedItem)
    if (!comment) return

    const { dispatch } = resources
    dispatch(ReduxActions.commentAdded(comment))
}

/*
 * A CommentChangedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { projectId, dispatch, displayError, getState } = resources
    const { id: commentId, changes } = command
    const oldItem = ReduxSelectors.itemWithId(getState(), Comment, commentId)

    const { completedDate, text = oldItem.text } = changes
    const completedTimestamp = completedDate ? completedDate.getTime() : null

    try {
        dispatch(ReduxActions.commentChanged({ id: commentId, changes }))
        await updateComment({ projectId, commentId, completedTimestamp, text })
    } catch (e) {
        dispatch(ReduxActions.commentChanged({ id: commentId, changes: oldItem }))
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CommentChangedCommand,
        CommandPlayer({
            CommandType: CommentChangedCommand,
            Type: FeedItem,
            collectionPath: (projectId, userId) => `/projects/${projectId}/participants/${userId}/feedItems`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added', // the FeedItem was ADDED, even though the Upload was changed
        })
    )
}

export { CommentChangedCommand, addCommand }
