import { useEffect, useMemo, useState } from 'react'

/**
 * Checks if concatenated values of specified fields in an item include the search value.
 * @sig matchesConcatenatedFields :: (Object, String, [String]) -> Boolean
 */
const matchesConcatenatedFields = (item, searchValue, fields) => {
    const searchString = fields.map(field => item[field]?.toLowerCase()).join(' ')
    const searchStringReversed = [...fields]
        .reverse()
        .map(field => item[field]?.toLowerCase())
        .join(' ')
    return searchString.includes(searchValue) || searchStringReversed.includes(searchValue)
}

/**
 * Determines if the item's specified field value includes the search value.
 * @sig matchesSingleField :: (Object, String, String) -> Boolean
 */
const matchesSingleField = (item, searchValue, field) => {
    const fieldValue = item[field]?.toLowerCase()
    return fieldValue && fieldValue.includes(searchValue)
}

/**
 * Checks if any combination of joined fields in an item includes the search value.
 * matchesJoinedFields :: (Object, String, [[String]]) -> Boolean
 */
const matchesJoinedFields = (item, searchValue, joinedFields) =>
    joinedFields?.some(fields => matchesConcatenatedFields(item, searchValue, fields))

/**
 * Checks if an item matches the search criteria by any given field or joined fields.
 * @sig itemMatchesSearchValue :: (Object, String, [String], [[String]]) -> Boolean
 */
const itemMatchesSearchValue = (item, searchValue, fields = [], joinedFields = []) =>
    fields.some(field => matchesSingleField(item, searchValue, field)) ||
    matchesJoinedFields(item, searchValue, joinedFields)

/**
 * Filters items based on search criteria across specified fields and joined fields.
 * @sig filterItems :: ([Object], String, [String], [[String]]) -> [Object]
 */
const filterItems = (items, searchValue, fields = [], joinedFields = []) => {
    const hasFields = fields.length > 0 || joinedFields.length > 0
    if (!searchValue || !hasFields) return items

    return items.filter(item => itemMatchesSearchValue(item, searchValue.toLowerCase(), fields, joinedFields))
}

/**
 * `useSearch` is a React hook that provides functionality for filtering a list of items based on a search value.
 * It manages the search value's state internally but also accepts an external value to set the search term
 * programmatically. The hook returns the current search value, a function to update it, the filtered list of items,
 * and a function to reset the search value. It supports filtering by specific fields within each item and also by
 * concatenations of fields, useful for searching by combinations of values like 'firstName lastName'.
 *
 * useSearch :: ({ items: [Object], fields: [String], joinedFields: [[String]], initialValue: String, value: String })
 * -> { searchValue: String, setSearchValue: (String -> ()), filteredItems: [Object], resetSearch: (() -> ()) }
 *
 * Where:
 * - `items` is the list of objects to be filtered.
 * - `fields` are the keys in each object that should be considered during filtering.
 * - `joinedFields` are groups of keys whose values should be concatenated and considered as a single string during filtering.
 * - `initialValue` is an optional initial value for the search term.
 * - `value` is an optional external value to set the search term programmatically, useful for sharing the search state across components.
 * - `searchValue` is the current value of the search term managed by the hook.
 * - `setSearchValue` is a function to update `searchValue`.
 * - `filteredItems` is the list of items that match the search term based on the specified `fields` and `joinedFields`.
 * - `resetSearch` is a function to clear the search term, resetting the search state.
 */
const useSearch = ({ items, fields, joinedFields, initialValue, value }) => {
    const resetSearch = () => _setValue('')

    const [_value, _setValue] = useState(initialValue || '')
    const filteredItems = useMemo(
        () => filterItems(items, _value, fields, joinedFields),
        [_value, items, fields, joinedFields]
    )

    useEffect(() => {
        if (value !== undefined) _setValue(value)
    }, [value])

    return {
        searchValue: _value,
        setSearchValue: _setValue,
        filteredItems,
        resetSearch,
    }
}

export { useSearch, filterItems }
