import * as ToggleIconGroupPrimitive from '@radix-ui/react-toggle-group'
import { styled } from '@stitches/react'
import PropTypes from 'prop-types'
import React from 'react'
import { Icon, Tooltip } from './index.js'

const StyledToggleIconGroup = styled(ToggleIconGroupPrimitive.Root, {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    height: '100%',
    background: '$neutral10',
    borderRadius: 6,
    border: '1px solid $neutral07',
    overflow: 'clip',
})

const StyledItem = styled(ToggleIconGroupPrimitive.Item, {
    all: 'unset',
    color: '$neutral04',
    height: 38,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
    borderRadius: 0,
    cursor: 'pointer',
    backgroundColor: 'transparent',

    '&:hover': { backgroundColor: '$neutral08' },
    '&[data-state=on]': { backgroundColor: '$primary04', color: 'white' },
    '&:focus': { position: 'relative' },
})

const ToggleIconGroup = ({ items, value, onValueChange, css }) => {
    const renderItem = item => (
        <Tooltip key={item.label} tooltipText={item.tooltipText}>
            <div>
                <StyledItem data-cy="annotate-tool" type="single" value={item.label} aria-label={item.label}>
                    <Icon name={item.icon} iconSize="28" />
                </StyledItem>
            </div>
        </Tooltip>
    )

    return (
        <StyledToggleIconGroup type="single" value={value} onValueChange={onValueChange} css={css}>
            {items.map(renderItem)}
        </StyledToggleIconGroup>
    )
}

ToggleIconGroup.displayName = 'ToggleIconGroup'

const ItemProp = PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
})

ToggleIconGroup.propTypes = {
    items: PropTypes.arrayOf(ItemProp).isRequired,
    value: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    css: PropTypes.object,
}

export default ToggleIconGroup
