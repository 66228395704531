import PropTypes from 'prop-types'
import React from 'react'
import { Box, Button, FlexColumn, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledOuterBox = styled(Box, {
    bg: '$neutral10',
    color: '$neutral04',
    width: '500px',
    border: '1px solid $neutral08',
    br: '6px',
    boxShadow: '0px 6px 10px #00000010',
    zIndex: 1000,
})

const StyledModalActions = styled(Box, {
    width: '100%',
    height: '72px',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '24px',
    paddingLeft: '24px',
    bt: '1px solid $neutral07',
    display: 'flex',
    gap: '16px',
    justifyContent: 'right',
})

const StyledPrimaryModalLabel = styled(Text, {
    fs: '20px',
    fw: 700,
})

const StyledSecondaryModalLabel = styled(Text, {
    fs: '14px',
    color: '$neutral05',
    lh: '1.4',
})

const StyledOverlay = styled(FlexColumn, {
    width: '100vw',
    height: '100vh',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$neutralOverlay',
    ai: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 300,
})

/*
 * Bulk archive tasks
 */

const ListViewArchiveTasksModal = ({ onArchive, onCancel, areAllCheckedTasksArchived = false }) => {
    const primaryLabel = 'Are you sure you want to archive the selected tasks?'

    return (
        <StyledOverlay>
            <StyledOuterBox data-cy="list-view-archive-tasks-modal">
                <Box css={{ padding: '24px' }}>
                    <StyledPrimaryModalLabel css={{ paddingBottom: '12px' }}>{primaryLabel}</StyledPrimaryModalLabel>
                    <StyledSecondaryModalLabel>
                        The tasks will no longer be visible on the canvas, but can still be made visible through the
                        filters. Tasks can also be unarchived.
                    </StyledSecondaryModalLabel>
                </Box>
                <StyledModalActions>
                    <Button css={{ height: '40px' }} variant="secondary" size="lg" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" size="lg" onClick={onArchive}>
                        {areAllCheckedTasksArchived ? 'Unarchive' : 'Archive'} Tasks
                    </Button>
                </StyledModalActions>
            </StyledOuterBox>
        </StyledOverlay>
    )
}

ListViewArchiveTasksModal.displayName = 'ListViewArchiveTasksModal'

ListViewArchiveTasksModal.propTypes = {
    onArchive: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    areAllCheckedTasksArchived: PropTypes.bool,
}

export default ListViewArchiveTasksModal
