/*
 * See PhotoAnnotationEditor for more information about how this is used
 *
 * A wrapper for a PhotoAnnotation that draws it into a Canvas and performs other related operations
 *
 * API
 *
 * createRenderer   creates an appropriate PhotoAnnotationRenderer for a specific PhotoAnnotation
 * render           draws the PhotoAnnotation into the Canvas
 * move             moves the PhotoAnnotation by [dx, dy]
 * isHit            hitTest: true if a point falls within the PhotoAnnotationRenderer's PhotoAnnotation
 */

import { PhotoAnnotation } from '@range.io/basic-types'
import { arePointsNearEachOther } from '@range.io/basic-types/src/helper/geometry-computations.js'
import { taggedSum } from '@range.io/functional'
import RangeColors from '../range-theme/range-colors.js'
import { isPointInStroke, measureText } from './photo-annotation-computations.js'

const FONT_NAME = 'Inter'
const FONT_WEIGHT = 'bold'
const LINE_SHADOW_SCALE = 2

const PhotoAnnotationRenderer = taggedSum('PhotoAnnotationRenderer', {
    Scribble: {
        annotation: 'PhotoAnnotation',
        path: 'Object', // Path2D
    },
    Text: {
        annotation: 'PhotoAnnotation',
        rectanglePath: 'Object', // Path2D (rounded rectangle background)
        textX: 'Number',
        textY: 'Number',
    },
    Arrow: {
        annotation: 'PhotoAnnotation',
        path: 'Object', // Path2D
        startingHandlePath: 'Object', // Path2D
        endingHandlePath: 'Object', // Path2D
    },
    Line: {
        annotation: 'PhotoAnnotation',
        path: 'Object', // Path2D
        startingHandlePath: 'Object', // Path2D
        endingHandlePath: 'Object', // Path2D
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Helpers
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Create a Path2D that will draw a rounded rectangle
 * @sig roundRectPath :: (Number, Number, Number, Number, Number) -> Path2D
 */
const roundRectPath = (left, top, width, height, radius) => {
    const path2d = new Path2D()
    path2d.roundRect(left, top, width, height, [radius])
    return path2d
}

/*
 * Create a Path2D that will draw an arrow (start -> end -> rightHead -> end -> leftHead)
 * @sig arrowPath :: (Point, Point, Point, Point) -> Path2D
 *  Point = [Number, Number] // [x, y]
 */
const arrowPath = (startPoint, endPoint, rightHead, leftHead) => {
    const path2D = new Path2D()
    path2D.moveTo(...startPoint)
    path2D.lineTo(...endPoint)
    path2D.moveTo(...endPoint)
    path2D.lineTo(...rightHead)
    path2D.moveTo(...endPoint)
    path2D.lineTo(...leftHead)
    return path2D
}

/*
 * Create a Path2D that will draw a line (start -> end)
 * @sig linePath :: (Point, Point) -> Path2D
 *  Point = [Number, Number] // [x, y]
 */
const linePath = (startPoint, endPoint) => {
    const path2D = new Path2D()
    path2D.moveTo(...startPoint)
    path2D.lineTo(...endPoint)
    return path2D
}

/*
 * Create a simple path that can be stroked to display a dot
 * @sig largeDotPath :: (Point) -> Path2D
 *  Point = [Number, Number] // [x, y]
 */
const largeDotPath = point => {
    const path2D = new Path2D()
    path2D.moveTo(...point)
    path2D.lineTo(...point)
    return path2D
}

// ---------------------------------------------------------------------------------------------------------------------
// Generic drawing
// ---------------------------------------------------------------------------------------------------------------------

const stroke = (ctx, path, color, lineWidth) => {
    ctx.strokeStyle = color
    ctx.lineWidth = lineWidth
    ctx.stroke(path)
}

const strokeWithBlur = (ctx, path, color, lineWidth) => {
    // set shadow values
    ctx.shadowBlur = 8
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 2
    ctx.shadowColor = 'rgba(0, 0, 0, .25)'

    // Render start dot
    ctx.strokeStyle = color
    ctx.lineWidth = lineWidth
    ctx.stroke(path)

    // Reset shadow values to defaults
    ctx.shadowBlur = 0
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 0
    ctx.shadowColor = 'transparent'
}

const fillWithBlur = (ctx, path, color) => {
    // set shadow values
    ctx.shadowBlur = 8
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 2
    ctx.shadowColor = 'rgba(0, 0, 0, 0.25)'

    ctx.fillStyle = color
    ctx.fill(path)

    // Reset shadow values to defaults
    ctx.shadowBlur = 0
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 0
    ctx.shadowColor = 'transparent'
}

// ---------------------------------------------------------------------------------------------------------------------
// CreateRenderer
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Create an appropriate PhotoAnnotationRenderer for the PhotoAnnotation
 * @sig createRenderer :: (CanvasCtx, Number, PhotoAnnotation) -> PhotoAnnotationRenderer
 */
PhotoAnnotationRenderer.createRenderer = (ctx, annotation) => {
    const scribble = () => {
        const renderCommand = command => {
            if (command.command === 'M') return scribblePath.moveTo(...command.points)
            if (command.command === 'L') return scribblePath.lineTo(...command.points)
            if (command.command === 'C') return scribblePath.bezierCurveTo(...command.points)

            throw new Error(`Don't understand annotation path ${command}`)
        }

        const scribblePath = new Path2D()
        annotation.drawingCommands.forEach(renderCommand)

        return PhotoAnnotationRenderer.Scribble(annotation, scribblePath)
    }

    const text = () => {
        const { text, fontSize } = annotation
        const { textWidth, textHeight } = measureText(ctx, text, FONT_NAME, FONT_WEIGHT, fontSize)
        const [x, y, width, height] = PhotoAnnotation.boundingBox(annotation, textWidth, textHeight)

        // Measure text
        const { textBaseline } = measureText(ctx, text, FONT_NAME, FONT_WEIGHT, fontSize)

        // Set rectangle dimensions
        // These values need to be kept in sync with iOS and Android for the annotations to look identical everywhere
        const verticalPadding = fontSize / 30.0
        const horizontalPadding = fontSize / 5.0
        const radius = fontSize / 5.0

        const rectanglePath = roundRectPath(x, y, width, height, radius)
        const textX = x + horizontalPadding
        const textY = y + verticalPadding + textBaseline

        return PhotoAnnotationRenderer.Text(annotation, rectanglePath, textX, textY)
    }

    const arrow = () => {
        const { startPoint, endPoint, rightHead, leftHead } = annotation
        const path = arrowPath(startPoint, endPoint, rightHead, leftHead)

        return PhotoAnnotationRenderer.Arrow(annotation, path, largeDotPath(startPoint), largeDotPath(endPoint))
    }

    const line = () => {
        const { startPoint, endPoint } = annotation
        const path = linePath(startPoint, endPoint)
        return PhotoAnnotationRenderer.Line(annotation, path, largeDotPath(startPoint), largeDotPath(endPoint))
    }

    return annotation.match({
        Scribble: scribble,
        Text: text,
        Arrow: arrow,
        Line: line,
    })
}

// ---------------------------------------------------------------------------------------------------------------------
// Move
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Move the PhotoAnnotation embedded in the Renderer and create a new Renderer
 * @sig move :: (CanvasCtx, PhotoAnnotationRenderer, Number, Number) -> PhotoAnnotationRenderer
 */
PhotoAnnotationRenderer.move = (ctx, renderer, dx, dy, toDrag) => {
    const annotation = PhotoAnnotation.move(renderer.annotation, dx, dy, toDrag)
    return PhotoAnnotationRenderer.createRenderer(ctx, annotation)
}

/*
 * Extend the PhotoAnnotation embedded in the Renderer and create a new Renderer
 * @sig extend :: (CanvasCtx, PhotoAnnotationRenderer, Number, Number) -> PhotoAnnotationRenderer
 */
PhotoAnnotationRenderer.extend = (ctx, renderer, dx, dy) => {
    const annotation = PhotoAnnotation.extend(renderer.annotation, dx, dy)
    return PhotoAnnotationRenderer.createRenderer(ctx, annotation)
}

/*
 * Set the hexColor of the PhotoAnnotation embedded in the Renderer and create a new Renderer
 * @sig move :: (CanvasCtx, PhotoAnnotationRenderer, String) -> PhotoAnnotationRenderer
 */
PhotoAnnotationRenderer.setColor = (ctx, renderer, color) => {
    const annotation = PhotoAnnotation.setColor(renderer.annotation, color)
    return PhotoAnnotationRenderer.createRenderer(ctx, annotation)
}

/*
 * Set the hexColor of the PhotoAnnotation embedded in the Renderer and create a new Renderer
 * @sig move :: (CanvasCtx, PhotoAnnotationRenderer, String) -> PhotoAnnotationRenderer
 */
PhotoAnnotationRenderer.setText = (ctx, renderer, text) => {
    const annotation = PhotoAnnotation.setText(renderer.annotation, text)
    return PhotoAnnotationRenderer.createRenderer(ctx, annotation)
}

PhotoAnnotationRenderer.getColor = renderer => renderer.annotation.hexColor
PhotoAnnotationRenderer.getSize = renderer => PhotoAnnotation.getSize(renderer.annotation)
PhotoAnnotationRenderer.getAvailableSizes = renderer => PhotoAnnotation.getAvailableSizes(renderer.annotation)

/*
 * Set the size of the PhotoAnnotation embedded in the Renderer and create a new Renderer
 * @sig setSize :: (CanvasCtx, PhotoAnnotationRenderer, Number) -> PhotoAnnotationRenderer
 */
PhotoAnnotationRenderer.setSize = (ctx, renderer, size) => {
    const annotation = PhotoAnnotation.setSize(renderer.annotation, size)
    return PhotoAnnotationRenderer.createRenderer(ctx, annotation)
}

// ---------------------------------------------------------------------------------------------------------------------
// FinalizeAnnotation
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Sometimes we want to modify the PhotoAnnotation when the user has finished creating it, such as simplifying scribbles
 * Returning undefined will cause the annotation to be thrown away (so we can avoid creating 0-length annotations)
 * @sig finalizeAnnotation :: (Canvas2D, PhotoAnnotationRenderer) -> PhotoAnnotationRenderer|undefined
 */
PhotoAnnotationRenderer.finalizeAnnotation = (ctx, renderer) => {
    const annotation = PhotoAnnotation.finalizeAnnotation(renderer.annotation)
    return annotation ? PhotoAnnotationRenderer.createRenderer(ctx, annotation) : undefined
}
// ---------------------------------------------------------------------------------------------------------------------
// Render
// ---------------------------------------------------------------------------------------------------------------------

// this is actually just a heuristic, since there are other strings that also mean "white"
const isWhite = color => color === RangeColors.White || color.toUpperCase() === '#FFFFFF'

/*
 * Use the PhotoAnnotationRenderer to draw the PhotoAnnotation
 * canvasScale is the ratio of canvas width to html width, because even though we want all the lines and
 * text drawn in canvas coordinates, we want to draw the selection handles in a consistent size no matter how
 * far zoomed-in you are
 *
 * @sig render :: (CanvasCtx, PhotoAnnotationRenderer, Boolean, Boolean, Number) -> nil
 */
PhotoAnnotationRenderer.render = (ctx, renderer, isSelected, isCreatingNewAnnotation, canvasScale) => {
    // Draw the renderer's background rectangle and then its text
    const text = () => {
        const { annotation, rectanglePath, textX, textY } = renderer
        const { text, hexColor, fontSize } = annotation

        // Draw the background rectangle first
        fillWithBlur(ctx, rectanglePath, isWhite(hexColor) ? RangeColors.Black : RangeColors.White)

        // Render text without shadow
        ctx.font = `${FONT_WEIGHT} ${fontSize}px ${FONT_NAME}`
        ctx.fillStyle = hexColor
        ctx.fillText(text, textX, textY)

        // If selected, draw outline
        if (isSelected) {
            ctx.strokeStyle = '#5D81FF' // Outline color
            ctx.lineWidth = 4 // Outline width
            ctx.stroke(rectanglePath)
        }
    }

    const simplePathRender = () => {
        const { annotation, path } = renderer
        const { lineWidth, hexColor } = annotation

        ctx.lineCap = 'round'

        // Draw the background behind the real path with shadow
        const backgroundColor = isWhite(hexColor) ? RangeColors.Black : RangeColors.White
        strokeWithBlur(ctx, path, backgroundColor, lineWidth * LINE_SHADOW_SCALE)

        // Draw the real path
        stroke(ctx, path, hexColor, lineWidth)
    }

    const renderHandles = () => {
        const { startingHandlePath, endingHandlePath } = renderer

        /*
         * Draw the handles with shadow
         *
         * Note: by multiplying the widths by canvasScale we're trying to keep the size of the HANDLES a fixed number of
         * pixels on the screen, even as the lines behind them grow and shrink as you resize the window.
         * The effect is that the handles appear to get BIGGER as you make the window bigger, because they
         * stay the same size as the lines they're attached to grow smaller
         */
        const BACKGROUND_WIDTH = 16 * canvasScale
        const FOREGROUND_WIDTH = 12 * canvasScale

        strokeWithBlur(ctx, startingHandlePath, RangeColors.White, BACKGROUND_WIDTH)
        strokeWithBlur(ctx, startingHandlePath, RangeColors.Primary04, FOREGROUND_WIDTH)
        strokeWithBlur(ctx, endingHandlePath, RangeColors.White, BACKGROUND_WIDTH)
        strokeWithBlur(ctx, endingHandlePath, RangeColors.Primary04, FOREGROUND_WIDTH)
    }

    const renderInnerCore = () => stroke(ctx, renderer.path, RangeColors.Primary06, 6)

    const lineOrArrow = () => {
        const { annotation } = renderer
        const { startPoint, endPoint } = annotation

        // if the user hasn't moved "very far" then don't render anything at all yet (to avoid rendering long
        // arrow heads on very short lines)
        if (arePointsNearEachOther(startPoint, endPoint)) return

        simplePathRender()
        if (isSelected) renderHandles()
    }

    const scribble = () => {
        simplePathRender()
        if (isSelected && !isCreatingNewAnnotation) renderInnerCore()
    }

    // Draw the renderer's path twice, first as a background (with a wider lineWidth) and then as a foreground
    return renderer.match({
        Scribble: scribble,
        Text: text,
        Arrow: lineOrArrow,
        Line: lineOrArrow,
    })
}

const PATH_TOLERANCE = 25 // makes it easier to select thin lines

/*
 * Is the point [x,y] within the bounds of the PhotoAnnotationRender
 * @sig isHit :: (CanvasCtx, Number, Number) -> PhotoAnnotationRenderer -> Boolean
 */
PhotoAnnotationRenderer.isHit = (ctx, x, y, renderer) => {
    return renderer.match({
        Scribble: () => isPointInStroke(ctx, PATH_TOLERANCE, renderer.path, x, y),
        Text: () => ctx.isPointInPath(renderer.rectanglePath, x, y),
        Arrow: () => isPointInStroke(ctx, PATH_TOLERANCE, renderer.path, x, y),
        Line: () => isPointInStroke(ctx, PATH_TOLERANCE, renderer.path, x, y),
    })
}

/*
 * Return the bounding box of the renderer's annotation
 */
PhotoAnnotationRenderer.boundingBox = (ctx, renderer) => {
    if (PhotoAnnotation.Text.is(renderer.annotation)) {
        const { text, fontSize } = renderer.annotation
        const { textWidth, textHeight } = measureText(ctx, text, FONT_NAME, FONT_WEIGHT, fontSize)
        return PhotoAnnotation.boundingBox(renderer.annotation, textWidth, textHeight)
    }

    return PhotoAnnotation.boundingBox(renderer.annotation)
}

/*
 * If we were dragging, what would we be dragging?
 * @sig annotationHandleToDrag :: (Canvas2DContext Number, Number, PhotoAnnotationRenderer) -> DRAG_HANDLE|undefined
 *  DRAG_HANDLE = start|end|all
 */
PhotoAnnotationRenderer.annotationHandleToDrag = (ctx, x, y, renderer) => {
    const arrowOrLine = () => {
        if (isPointInStroke(ctx, PATH_TOLERANCE, renderer.startingHandlePath, x, y)) return DRAG_HANDLES.START
        if (isPointInStroke(ctx, PATH_TOLERANCE, renderer.endingHandlePath, x, y)) return DRAG_HANDLES.END
        if (isPointInStroke(ctx, PATH_TOLERANCE, renderer.path, x, y)) return DRAG_HANDLES.ALL
        return undefined
    }

    const textOrScribble = () => PhotoAnnotationRenderer.isHit(ctx, x, y, renderer) && DRAG_HANDLES.ALL

    const DRAG_HANDLES = PhotoAnnotation.DRAG_HANDLES
    return renderer.match({
        Arrow: arrowOrLine,
        Line: arrowOrLine,
        Scribble: textOrScribble,
        Text: textOrScribble,
    })
}

/*
 * Use current conditions to decide what cursor to show over this renderer.
 * Returning undefined means don't change the cursor for this renderer
 * Predefined cursors to use: https://developer.mozilla.org/en-US/docs/Web/CSS/cursor#formal_definition
 * @sig cursorStyle :: (PhotoAnnotationRenderer, Boolean, Boolean, Boolean) -> String
 */
PhotoAnnotationRenderer.cursorStyle = (ctx, x, y, renderer, isSelected, isDragging) => {
    const arrowLineCursorStyle = () => {
        const annotationHandleToDrag = PhotoAnnotationRenderer.annotationHandleToDrag(ctx, x, y, renderer)

        if (isSelected && annotationHandleToDrag === PhotoAnnotation.DRAG_HANDLES.START) return 'move'
        if (isSelected && annotationHandleToDrag === PhotoAnnotation.DRAG_HANDLES.END) return 'move'
        if (isSelected && isDragging) return 'grabbing'
        if (annotationHandleToDrag === PhotoAnnotation.DRAG_HANDLES.ALL) return 'grab'
        return undefined
    }

    const basicCursorStyle = () => {
        const isHovered = PhotoAnnotationRenderer.isHit(ctx, x, y, renderer)
        if (isSelected && isDragging) return 'grabbing'
        if (isSelected && isHovered) return 'grab'
        if (isHovered) return 'grab'
        return undefined
    }

    return renderer.match({
        Arrow: arrowLineCursorStyle,
        Line: arrowLineCursorStyle,
        Scribble: basicCursorStyle,
        Text: basicCursorStyle,
    })
}

export default PhotoAnnotationRenderer
