/*
 * We use Segment to capture interesting events in our data:
 *
 * - identify (the selected User)
 * - group (the selected Organization)
 * - page (one of several "pages" we identified as interesting)
 * - track (one of many tasks we identified as interesting)
 *
 * These events are typically sent from a React component -- but because React components can easily be
 * called many times, and we don't want to send multiple Segments events for what the user perceives as
 * one action, we track the last time we made a call with the same parameters and only send it again
 * only if the parameters have changed or "enough" time has gone by
 *
 * For now, "enough" time is an hour, so it will only trigger for this reason if the user stays on
 * the same page for an hour. This could be wrong
 *
 * There are separate caches for each event we send to Segment, to prevent situations like
 * adding a tag in the collaboration window, which triggers "tag added" but then, because the
 * state has changed rerenders the collaboration, which we don't want to report to Segment.
 * There is a special case for 'group' because there is effectively only one event for group,
 * which happens when you change organization.
 *
 * NOTE: this design may not be correct in the long-run. If we ever want PRECISE information
 * about these events rather than this somewhat vague effort to send events only when "reasonable,"
 * we probably will need logic at each point where we send Segment data that decides if the event should really be sent.
 * At the moment, a roughly-correct value for events is all we need
 *
 * SegmentEventName = the value reported to Segment, right now:
 *
 * Pages
 *
 *   organization projects
 *   project canvas
 *   project media
 *   project tasklist
 *
 * Track
 *
 *   collaboration created
 *   collaboration viewed
 *   media uploaded
 *   media viewed
 *   tag added
 *   task report created
 *   media report created
 *
 * Group
 *
 *   group (this is a special case; the group we report is always an organization id, not the word "group")
 *
 */

import { assoc, equals, tagged } from '@range.io/functional'
import Cookies from 'js-cookie'
import { generateIntercomUserHash } from '../firebase/commands/https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// Caching
// ---------------------------------------------------------------------------------------------------------------------

// set to a very long time for now
const MILLISECONDS_PER_SECOND = 1000
const SECONDS_PER_HOUR = 3600
const minimumDelayBetweenCalls = SECONDS_PER_HOUR * MILLISECONDS_PER_SECOND

/*
 * Track a single previous call
 */
const PreviousCall = tagged('PreviousCall', {
    previousKey: 'String', // name of the call (eg. "canvas", "tasklist", or some other identifier)
    previousParams: 'Object', // depends on caller
    previousCallTime: 'Number', // Date.now()
})

// cached by SegmentEventName
const previousCalls = {}

/*
 * Returns true if the call to Segment SHOULD be made
 * @sig shouldSendCallToSegment :: (SegmentEventName, String, {k:v}) -> Boolean
 */
const shouldSendCallToSegment = (segmentEventName, key, params) => {
    const previousCall = previousCalls[segmentEventName] || {}
    const { previousKey, previousParams, previousCallTime } = previousCall

    const nameChanged = previousKey !== key
    const sentLongAgo = Date.now() - minimumDelayBetweenCalls > previousCallTime
    const paramsChanged = !equals(params, previousParams)
    const shouldSendToSegment = nameChanged || sentLongAgo || paramsChanged

    // same the new previous value for next time
    if (shouldSendToSegment) previousCalls[segmentEventName] = PreviousCall(key, params, Date.now())

    return shouldSendToSegment
}

// ---------------------------------------------------------------------------------------------------------------------
// Specific calls to Segment (each protected by its own cache)
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Makes a call to analytics.page if the name or params have changed
 * You're only ever on 1 page at a time, so all the page calls share the same PreviousCall value
 */
const sendPage = (name, id, params) => {
    const key = name + id
    if (shouldSendCallToSegment(name, key, params)) window?.analytics?.page?.(name, assoc('title', name, params))
}

/*
 * Makes a call to analytics.track if the name, id or params have changed. id is used only to trigger a new write,
 * if you want to send it to Segment, include it in the params as well
 */
const sendTrack = (name, id, params) => {
    const key = name + id
    if (shouldSendCallToSegment(name, key, params)) {
        const context = { groupId: params.groupId }

        window?.analytics?.track?.(name, params, { context })
    }
}

/*
 * Makes a call to analytics.group if the organization or user's organizationRole has changed
 * You're only ever in 1 group at a time
 * @sig sendGroup = (Organization, String) -> ()
 */
const sendGroup = (organization, organizationRole) => {
    const { id, name, industry, size = '10-50' } = organization

    const params = { name, industry, size, organizationRole }
    if (shouldSendCallToSegment('group', id, params)) window?.analytics?.group?.(id, params)
}

/*
 * Make a call to analytics.identify
 */
const sendIdentify = async user => {
    const data = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        language: user.language,
        jobTitle: user.role,
    }

    const { userHash } = await generateIntercomUserHash()

    const options = {
        integrations: {
            Intercom: {
                user_hash: userHash,
            },
        },
    }

    window?.analytics?.identify?.(user.id, data, options)
}

const reset = () => window?.analytics?.reset?.()

/*
 * Extract UTM attribution parameters from their locally stored place (cookies)
 */
const getUtmAttributionParams = () => {
    const utmCampaign = Cookies.get('utm_campaign')
    const utmMedium = Cookies.get('utm_medium')
    const utmSource = Cookies.get('utm_source')
    return { utmCampaign, utmMedium, utmSource }
}

export { sendIdentify, sendPage, sendGroup, sendTrack, reset, getUtmAttributionParams }
