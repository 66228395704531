/*
 * Manage photo annotations
 *
 * Our strategy for displaying photo annotations is to put a transparent HTML Canvas element on top of the photo,
 * and draw the annotations on the canvas. To make sure the annotations don't "move around" relative
 * to the photo behind the canvas, we resize the canvas whenever the <img> holding the photo changes size.
 *
 * But scaling is a little tricky:
 *
 * Suppose we have a JPG that's 4000 x 3000 and there's a single red dot 40 pixels from the left.
 * No matter how much we rescale the image (perhaps we're resizing the browser window), we still want
 * the red pixel to be 1% from the left edge of the image.
 *
 * And, of course, there's no reason the HTML element showing the photo has to be the same size as the
 * "natural" size of the photo (4000 x 3000 in this case) since this size will change every time the user
 * resizes the browser window.
 *
 * We always display full images even if we shrink them, which means we almost always will have large
 * black borders either on the top and bottom ("letterboxed") or on the left and right ("pillarboxed"),
 * but never on all four sides.
 *
 * The canvas doesn't cover the entire img element, instead it covers only the portion of the element
 * that shows image pixels. By placing the canvas directly on top of the image's pixels, we can simplify
 * the scaling necessary.
 *
 * The width and height of the canvas always match the naturalWidth and naturalHeight of the image,
 * and the canvas' style width and height match the width and height of the image's pixels. That way,
 * the coordinate system of the canvas is identical to that of the image at any given moment, and this
 * is the coordinate system that the annotations use.
 *
 * To simplify the rendering of the annotations, we can draw in "image space" (that is, the 4000 x 3000
 * area defined by the photo) and then transform that drawing to deal with the fact that we're displaying
 * the image in an HTML element that may not even have the same aspect ratio as the original image--let alone
 * the same size.
 *
 *
 * ---------------------------------------------------------------------------------------------------------------------
 * Components collaborating with the PhotoAnnotationEditor
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * The PhotoAnnotationEditor is connected with other objects
 
 *           PhotoAnnotationToolbar
 *                    |
 *                    |        -- 1 PhotoAnnotationCanvas 1 -- N PhotoAnnotationRenderer ----- 1
 *                    |       |                                                                |
 *     PhotoAnnotationEditor  1                                                      PhotoAnnotation
 *                            |                                                                |
 *                             -- 1 PhotoAnnotations -----1 -- N ------------------------------
 *
 *
 * PhotoAnnotations
 *
 *   - a wrapper for many PhotoAnnotation objects (this is what's actually stored in Firestore for an Upload)
 *   - also includes additional information that pertains to ALL the contained PhotoAnnotations, including the 'scale'
 *
 * PhotoAnnotation
 *
 *   - the raw data for a single annotation (one of Text, Line, Arrow or Scribble)
 *   - PhotoAnnotation.boundingBox computes the bounding box of the object
 *   - PhotoAnnotation.move returns a new PhotoAnnotation moved by dx, dy
 *
 * PhotoAnnotationsToolbar
 *
 *   - shows the current state of the toolbar(s)
 *
 * PhotoAnnotationRenderer
 *
 *  - owns and manages a single PhotoAnnotation
 *  - PhotoAnnotationRenderer.render draws the PhotoAnnotation into a canvas
 *  - PhotoAnnotationRenderer.isHit returns true if the mouse was clicked on top of the PhotoAnnotation
 *  - PhotoAnnotationRenderer.move replaces the PhotoAnnotation with a new one offset by dx, dy
 *
 */
import { PhotoAnnotation, PhotoAnnotations } from '@range.io/basic-types'
import { moveItemToEnd, pluck, without } from '@range.io/functional'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Icon } from '../components-reusable/index.js'
import { UploadChangedCommand } from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import PhotoAnnotationRenderer from './photo-annotation-renderer.js'
import PhotoAnnotationCanvas from './PhotoAnnotationCanvas.js'
import PhotoAnnotationTextEditor from './PhotoAnnotationTextEditor.js'
import PhotoAnnotationToolbar from './PhotoAnnotationToolbar.js'

const TOOLS = PhotoAnnotationToolbar.TOOLS

const NOT_EDITING = 'NOT_EDITING'
const SAVING = 'SAVING'
const EDITING = 'EDITING'

/*
 * These are here to prevent the usual complexities with timing changes in data in React.
 * Whenever any of these values change, we need to tell React to redraw, so we use triggerRedraw.
 * Probably there's a way to get this just right using useCallback, but it seems simpler to just trigger
 * the update ourselves, exactly when we need it
 */
let currentTool = TOOLS.TEXT
let selectedRenderer
let renderers = []
let currentColor = PhotoAnnotation.DEFAULT_COLOR
let currentIsEditing = NOT_EDITING // the user has clicked the "annotate" button; see resetSizes

// -----------------------------------------------------------------------------------------------------------------
// Read/write currentLineWidth or currentFontSize depending on which tool we're using
// -----------------------------------------------------------------------------------------------------------------
let currentLineWidth
let currentFontSize

const currentSize = () => (currentTool === TOOLS.TEXT ? currentFontSize : currentLineWidth)
const setCurrentSize = size => (currentTool === TOOLS.TEXT ? (currentFontSize = size) : (currentLineWidth = size))

// -----------------------------------------------------------------------------------------------------------------
// InitialAnnotations
// -----------------------------------------------------------------------------------------------------------------

const initializeAnnotations = (imageRef, upload) => {
    const image = imageRef.current
    return !upload.annotations && image?.naturalWidth
        ? PhotoAnnotations.default(image.naturalWidth, image.naturalHeight)
        : upload.annotations
}

// -----------------------------------------------------------------------------------------------------------------
// Computations to get the size of the canvas to exactly overlap the image and also set up the canvas' scale.
// -----------------------------------------------------------------------------------------------------------------

// Pillarboxed: black borders at the sides ("Letterboxed" would have the black borders at top and bottom)
const isPillarboxed = image => {
    const elementAspectRatio = image.offsetWidth / image.offsetHeight
    const imageAspectRatio = image.naturalWidth / image.naturalHeight

    return elementAspectRatio > imageAspectRatio
}

// Compute the scale and translation as described at the top of the file
const computeTransform = image => {
    const htmlElementHeight = image.offsetHeight
    const htmlElementWidth = image.offsetWidth
    const photoNaturalWidth = image.naturalWidth
    const photoNaturalHeight = image.naturalHeight

    const scale = isPillarboxed(image) ? htmlElementHeight / photoNaturalHeight : htmlElementWidth / photoNaturalWidth

    const left = isPillarboxed(image) ? (htmlElementWidth - photoNaturalWidth * scale) / 2 : 0
    const top = isPillarboxed(image) ? 0 : (htmlElementHeight - photoNaturalHeight * scale) / 2

    return { top, left, scale }
}

const defaultSizes = {
    width: 100,
    height: 100,
    style: {
        top: 0,
        left: 0,
        width: 100,
        height: 100,

        position: 'absolute',
        backgroundColor: 'transparent',
        zIndex: 1,
        visibility: 'hidden',
    },
}

// Resize the canvas to exactly the same size as that part of the img element that is showing pixels
// from the image (and inset from the black borders) and draw the annotations
const recomputeSizes = imageRef => {
    const image = imageRef.current

    if (!image) return

    const { top, left, scale } = computeTransform(image)
    return {
        width: image.naturalWidth,
        height: image.naturalHeight,
        style: {
            width: image.naturalWidth * scale + 'px',
            height: image.naturalHeight * scale + 'px',
            top: top + 'px',
            left: left + 'px',

            position: 'absolute',
            backgroundColor: 'transparent',
            zIndex: 1,
            visibility: currentIsEditing === NOT_EDITING ? 'hidden' : 'visible',
        },
    }
}

/*
 * Main component
 */
const PhotoAnnotationEditor = ({ isEditing, setIsEditing, imageRef, disabled, upload }) => {
    // Show either the "Annotate" button or the PhotoAnnotationToolbar
    const possiblyRenderToolbar = () => {
        const showSubtools = () => {
            if (selectedRenderer) return PhotoAnnotationToolbar.SHOW_SUBTOOLS.ALL
            if (currentTool === PhotoAnnotationToolbar.TOOLS.SELECT) return PhotoAnnotationToolbar.SHOW_SUBTOOLS.NONE
            return PhotoAnnotationToolbar.SHOW_SUBTOOLS.NO_TRASH
        }

        const css = { paddingLeft: 10, gap: 4, position: 'absolute', top: 20, left: 20, zIndex: 3 }

        const allColors = PhotoAnnotation.ANNOTATION_COLORS
        const tool = selectedRenderer ? selectedRenderer.annotation.type : currentTool
        const allSizes = PhotoAnnotation.sizesForTool(tool)

        const currentSize1 = selectedRenderer
            ? PhotoAnnotationRenderer.getSize(selectedRenderer) / annotations.scale
            : currentSize()
        const currentColor1 = selectedRenderer ? PhotoAnnotationRenderer.getColor(selectedRenderer) : currentColor

        disabled = disabled || isEditing === SAVING

        return isEditing === EDITING ? (
            <PhotoAnnotationToolbar
                onDone={onDone}
                onColorChanged={onColorChanged}
                onSizeChanged={onSizeChanged}
                onCancel={onCancel}
                onDelete={onDelete}
                currentTool={currentTool}
                onToolChanged={onToolChanged}
                allSizes={allSizes}
                allColors={allColors}
                currentColor={currentColor1}
                currentSize={currentSize1}
                showSubtools={showSubtools()}
            />
        ) : (
            <Button data-cy="annotate" variant="primary" size="lg" onClick={startEditing} disabled={disabled} css={css}>
                <Icon name="scribbleAnnotation" iconSize="28" />
                {isEditing === SAVING ? 'Saving...' : 'Annotate'}
            </Button>
        )
    }

    // -----------------------------------------------------------------------------------------------------------------
    // PhotoAnnotationCanvas handlers
    // -----------------------------------------------------------------------------------------------------------------
    const resizeCanvas = () => {
        setSizes(recomputeSizes(imageRef))
        triggerRedraw()
    }

    // Work around React/JavaScript closure rules; isEditing would be out of date if we used it directly
    const resetSizes = isEditing => {
        currentIsEditing = isEditing
        resizeCanvas()
    }

    const setRenderers = newRenderers => {
        renderers = newRenderers
        triggerRedraw()
    }

    const initializeRenderers = annotations => {
        const createRenderer = annotation => PhotoAnnotationRenderer.createRenderer(ctx, annotation)

        if (!canvasRef.current) return

        const ctx = canvasRef.current.getContext('2d')
        const renderers = annotations?.annotations.map(createRenderer) || []

        setRenderers(renderers)
    }

    /* -----------------------------------------------------------------------------------------------------------------
     * Non-text annotation creation (arrow, line, scribble)
     * -------------------------------------------------------------------------------------------------------------- */

    /*
     * Create an initial (single-point) PhotoAnnotationRenderer that we will be editing.
     * Because for arrow, line and scribble, we need more points as the user moves the mouse, this just
     * sets up the initial annotation. See extendNewAnnotation for more
     * This function is NOT called for Text annotations; see createNewText
     */
    const createNewAnnotation = (ctx, x, y) => {
        const color = currentColor
        const size = (currentSize() || PhotoAnnotation.defaultSizeForTool(currentTool)) * annotations.scale
        const annotation = PhotoAnnotation.fromInitialPoint(currentTool, [x, y], color, size)
        setSelectedRenderer(PhotoAnnotationRenderer.createRenderer(ctx, annotation))
        setIsCreatingNewAnnotation(true)
    }

    /*
     * Extend a partially-created non-text annotation by calling the extend function. For lines and arrows,
     * this will replace the end point; for scribbles, it will keep adding new points to the path
     */
    const extendNewAnnotation = (ctx, dx, dy) => {
        const newRenderer = PhotoAnnotationRenderer.extend(ctx, selectedRenderer, dx, dy) // move the tiny amount
        setSelectedRenderer(newRenderer)
    }

    /*
     * Finalize the creation of a non-text annotation. For scribbles this causes a complicated computation
     * to simplify the curve using beziers
     */
    const completeNewAnnotation = ctx => {
        const newRenderer = PhotoAnnotationRenderer.finalizeAnnotation(ctx, selectedRenderer)
        setSelectedRenderer(newRenderer)
        setIsCreatingNewAnnotation(false)
    }

    /* -----------------------------------------------------------------------------------------------------------------
     * Text annotation creation
     * -------------------------------------------------------------------------------------------------------------- */

    /*
     * Create a new Text annotation at x, y. Text is not created when dragging, but only on click and doubleclick
     * Text doesn't need to be extended, and the "normal" completeNewAnnotation will be called to finalize it
     * TODO: the size and color shouldn't be using the default values
     */
    const createNewText = (ctx, x, y) => {
        const color = currentColor
        const size = (currentSize() || PhotoAnnotation.defaultSizeForTool(currentTool)) * annotations.scale
        const annotation = PhotoAnnotation.fromInitialPoint(currentTool, [x, y], color, size)
        return PhotoAnnotationRenderer.createRenderer(ctx, annotation)
    }

    // You create new Text annotations on click, not by dragging
    const dragToCreateTools = [TOOLS.ARROW, TOOLS.LINE, TOOLS.SCRIBBLE]

    /* -----------------------------------------------------------------------------------------------------------------
     * Gesture handlers
     *
     * We receive gestures for
     *
     * 1 click
     * 2 doubleclick
     * 3 dragstart + 0 or more drag + dragend
     *
     * These are mutually exclusive: we get 1 OR 2 OR 3, but never overlap among 1, 2, 3
     * -------------------------------------------------------------------------------------------------------------- */

    /*
     *  Clicking selects/unselects the current renderer, and as a special case, creates a new Text annotation if
     *  the user clicked on a blank part of the canvas.
     *
     * Note: there are 2 renderers to consider, the currently-selected renderer and the clicked-on renderer
     * Each could be undefined. We only want to create a new Text if they're both undefined, because:
     *
     * - if there's a clicked-on renderer, we just want to select that, not create a new Text
     * - if there's no clicked-on renderer but there IS a selectedRender, and as another special case,
     *   we just want to unselect the previously unselected renderer
     */
    const onClick = ({ ctx, x, y, renderer }) => {
        if (!renderer && !selectedRenderer && currentTool === TOOLS.TEXT) {
            renderer = createNewText(ctx, x, y)
            setShowTextEditor(true)
        }

        setSelectedRenderer(renderer)
    }

    /*
     * Double-clicking has the same meaning as clicking with one additional special case:
     * it starts the editing of an existing Text annotation if the doubleclick happened to land on one
     */
    const onDoubleClick = ({ ctx, x, y, renderer }) => {
        if (!renderer && currentTool === TOOLS.TEXT) renderer = createNewText(ctx, x, y)

        setSelectedRenderer(renderer)

        if (PhotoAnnotationRenderer.Text.is(renderer)) setShowTextEditor(true)
    }

    /*
     * A dragstart on an existing annotation starts moving it; if there's not an existing annotation,
     * it creates a new (non-text) annotation
     *
     * Note: for lines and arrows, it's possible to start a drag close enough to one of the "handles" of an
     * existing annotation, that just that end should  move, leaving the other handle stationary.
     * extendNewAnnotation is smart enough to handle this case, but depends on knowing what to move --
     * as stored in annotationHandleToDrag
     */
    const onDragStart = ({ ctx, x, y, renderer }) => {
        if (!renderer && dragToCreateTools.includes(currentTool)) return createNewAnnotation(ctx, x, y)
        if (!renderer) return

        setAnnotationHandleToDrag(PhotoAnnotationRenderer.annotationHandleToDrag(ctx, x, y, renderer))
        setSelectedRenderer(renderer)
    }

    /*
     * A continuing drag extends an partially-create (non-text) annotation if we're in the middle of creating one
     * Or, if we in the middle of moving an existing one, it moves it.
     */
    const onDrag = ({ ctx, dx, dy, renderer }) => {
        if (isCreatingNewAnnotation) return extendNewAnnotation(ctx, dx, dy)
        if (!renderer) return

        const newRenderer = PhotoAnnotationRenderer.move(ctx, renderer, dx, dy, annotationHandleToDrag)
        setSelectedRenderer(newRenderer)
    }

    /*
     * Finalize a partially-created (non-text) annotation
     */
    const onDragEnd = ({ ctx }) => {
        if (isCreatingNewAnnotation) return completeNewAnnotation(ctx)
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Renderer Management
    // -----------------------------------------------------------------------------------------------------------------

    /*
     * Set the selectedRenderer (and move it to the top)
     */
    const setSelectedRenderer = renderer => {
        selectedRenderer = renderer

        // move the (selected) renderer to the front, unless it's undefined
        if (renderer) setRenderers(moveItemToEnd(renderer, renderers, r => r.annotation.id === renderer.annotation.id))

        triggerRedraw()
    }

    /*
     * Delete a renderer and deselect it.
     * Note: to be safe, we use the id to find the renderer, create|extend|complete create NEW renderers with the
     * same id, rather than modifying an existing renderer.
     */
    const removeRenderer = renderer => {
        const item = renderers.find(r => r.annotation.id === renderer.annotation.id)
        if (item) setRenderers(without(item, renderers))
        if (selectedRenderer.annotation.id === renderer.annotation.id) setSelectedRenderer(undefined)

        triggerRedraw()
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Text Editing
    // -----------------------------------------------------------------------------------------------------------------

    const onFinishEditing = newText => {
        const deleteText = () => {
            removeRenderer(selectedRenderer)
        }

        const saveText = text => {
            const renderer = PhotoAnnotationRenderer.setText(ctx, selectedRenderer, text)
            setRenderers(moveItemToEnd(renderer, renderers, r => r.annotation.id === renderer.annotation.id))
        }

        const ctx = canvasRef.current.getContext('2d')
        newText === '' ? deleteText() : saveText(newText)
        setSelectedRenderer(undefined)
        setShowTextEditor(false)
    }

    /*
     * Text editing is completely unrelated to non-text editing and involves showing an HTML element on top of
     * the canvas in just the right spot. When the edit is committed, the HTML element is hidden again
     */
    const textEditor = () => {
        const { annotation, textX, textY } = selectedRenderer
        const { text, fontSize, hexColor } = annotation

        return (
            <PhotoAnnotationTextEditor
                y={textY}
                textColor={hexColor}
                x={textX}
                canvasRef={canvasRef}
                initialText={text}
                fontSize={fontSize}
                onFinishedEditing={onFinishEditing}
                ref={inputRef}
            />
        )
    }

    // -----------------------------------------------------------------------------------------------------------------
    // PhotoAnnotationToolbar handlers
    //
    // The user has clicked a button in the toolbar, or the color or size picker, or delete
    // -----------------------------------------------------------------------------------------------------------------

    // The user clicked the "annotate" button to start editing annotations
    const startEditing = () => {
        setIsEditing(EDITING)
        onToolChanged(TOOLS.SELECT) // Set to 'Select' only when starting editing
    }

    // The user has changed the color; change both the currentColor and, if there's a selection, its size
    const onColorChanged = color => {
        currentColor = color

        const ctx = canvasRef.current.getContext('2d')
        if (selectedRenderer) setSelectedRenderer(PhotoAnnotationRenderer.setColor(ctx, selectedRenderer, color))

        triggerRedraw()
    }

    // The user has changed the size; change both the currentSize and, if there's a selection, its size
    const onSizeChanged = size => {
        setCurrentSize(size)

        const ctx = canvasRef.current.getContext('2d')
        if (selectedRenderer)
            setSelectedRenderer(PhotoAnnotationRenderer.setSize(ctx, selectedRenderer, size * annotations.scale))

        triggerRedraw()
    }

    // The user (probably) pressed the escape key with a selectedRender, so unselect it
    const onCancel = () => setSelectedRenderer(undefined)

    // The user clicked the delete button (which is only visible at all if there is a selectedRenderer)
    const onDelete = () => {
        removeRenderer(selectedRenderer)
        setShowTextEditor(false)
    }

    // The user has clicked "done" which commits all the changes and we can (finally!) send the annotations to Firestore
    const onDone = async () => {
        setIsEditing(SAVING)
        if (showTextEditor) onFinishEditing(inputRef.current.value)

        const uploadAnnotations =
            upload.annotations ||
            PhotoAnnotations.default(imageRef.current.naturalWidth, imageRef.current.naturalHeight)

        const individualAnnotations = pluck('annotation', renderers)
        const annotations = PhotoAnnotations.update(uploadAnnotations, { annotations: individualAnnotations })
        await runCommand(UploadChangedCommand.Outbound(upload.id, { annotations }))
        setIsEditing(NOT_EDITING)

        // so Cypress can check our work
        if (window.Cypress) window.annotations = annotations

        if (window.history.state?.usr?.closeAnnotationToPreviousPage) {
            const navigatedFromUrl = window.history.state?.usr?.navigatedFromUrl
            navigate(navigatedFromUrl)
        }
    }

    /*
     * The user picked clicked on a tool (select, arrow, line, scribble, text)
     *
     * Note: we have to reset the currentSize, because there are two different sets of sizes:
     *
     * - one for fontSize (for text)
     * - one for lineWidths (for everything else)
     *
     * The size dropdown has a fixed number of available sizes, perhaps 10, 12, 14 for Text and 2, 4, 6 for lineWidths.
     * A specific text annotation will have a fontSize of 10, 12 or 14. If we don't change the available sizes of the
     * dropdown to 10, 12, 14, we can't find the fontSize of the currently-selected text annotation
     */
    const onToolChanged = tool => {
        if (tool === '' || currentTool === tool) return

        if (showTextEditor) onFinishEditing(inputRef.current.value)

        currentTool = tool
        setCurrentSize(currentSize() || PhotoAnnotation.defaultSizeForTool(tool))
        setSelectedRenderer(undefined)

        triggerRedraw()
    }

    // Force React to redraw
    const triggerRedraw = () => _triggerRedraw(Math.random())

    // -----------------------------------------------------------------------------------------------------------------
    // State
    // -----------------------------------------------------------------------------------------------------------------
    const { runCommand } = useCommandHistory()
    const navigate = useNavigate()

    const [sizes, setSizes] = useState(defaultSizes)
    const [annotationHandleToDrag, setAnnotationHandleToDrag] = useState()
    const [showTextEditor, setShowTextEditor] = useState()
    const [isCreatingNewAnnotation, setIsCreatingNewAnnotation] = useState()
    // eslint-disable-next-line no-unused-vars
    const [_, _triggerRedraw] = useState()
    const canvasRef = useRef(null)
    const inputRef = useRef(null)

    const annotations = initializeAnnotations(imageRef, upload)

    useEffect(() => {
        window.addEventListener('resize', resizeCanvas)
        return () => window.removeEventListener('resize', resizeCanvas)
    }, [])

    useEffect(() => resetSizes(isEditing), [isEditing])
    useEffect(() => initializeRenderers(upload.annotations), [upload])

    // don't show the selectedRenderer if we're also showing the text editor
    const visibleRenderers = showTextEditor ? without(selectedRenderer, renderers) : renderers

    const allowEditing = isEditing === EDITING

    return (
        <>
            <PhotoAnnotationCanvas
                {...sizes}
                ref={canvasRef}
                //
                renderers={visibleRenderers}
                selectedRendererId={selectedRenderer?.annotation?.id}
                //
                key={allowEditing} // guarantee the old PhotoAnnotationCanvas is unmounted
                allowEditing={allowEditing}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                onDragStart={onDragStart}
                onDrag={onDrag}
                onDragEnd={onDragEnd}
                currentTool={currentTool}
            />
            {possiblyRenderToolbar()}
            {showTextEditor && textEditor()}
        </>
    )
}

PhotoAnnotationEditor.NOT_EDITING = NOT_EDITING
PhotoAnnotationEditor.EDITING = EDITING
PhotoAnnotationEditor.SAVING = SAVING

export default PhotoAnnotationEditor
