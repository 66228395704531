/*
 * See Readme
 */

import { Presence } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { equals, firstKey, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import CommandPlayer from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// PresenceChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const PresenceChangedCommand = taggedSum('PresenceChangedCommand', {
    Inbound: {
        presence: 'Presence',
    },
    Outbound: {
        id: StringTypes.Id, // Presence id
        changes: 'Object', // each entry defines key/value of the existing Presence that changed
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A PresenceChangedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Presence)
 */
const runInboundCommand = (resources, command) => {
    const { dispatch, getState } = resources
    const { presence } = command
    const oldPresence = ReduxSelectors.itemWithId(getState(), Presence, presence.id)

    if (equals(presence, oldPresence)) return // no change

    dispatch(ReduxActions.presenceAdded(presence))
}

/*
 * A PresenceChangedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { id, changes } = command
    const { projectId, dispatch, getState, runTransaction, transactionUpdate } = resources
    const presence = ReduxSelectors.itemWithId(getState(), Presence, id)
    const field = firstKey(changes)

    if (equals(presence[field], changes[field])) return // no change

    const { id: _, ...presenceChanges } = Presence.toFirebase(changes)

    try {
        dispatch(ReduxActions.presenceChanged({ id, changes }))
        await runTransaction(async transaction => {
            transactionUpdate(transaction, projectId, 'presences', id, presenceChanges)
        })
    } catch (e) {
        console.error(e) // no reason to reverse a change of Presence
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        PresenceChangedCommand,
        CommandPlayer({
            CommandType: PresenceChangedCommand,
            Type: Presence,
            collectionPath: projectId => `/projects/${projectId}/presences/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
        })
    )
}

export { PresenceChangedCommand, addCommand }
