import * as SwitchPrimitive from '@radix-ui/react-switch'
import { styled } from '@stitches/react'
import PropTypes from 'prop-types'
import React from 'react'

const StyledSwitch = styled(SwitchPrimitive.Root, {
    all: 'unset',
    width: 39,
    minWidth: 39,
    height: 22,
    backgroundColor: '$neutral06',
    borderRadius: '9999px',
    position: 'relative',
    transitionDuration: '0.4s',
    cursor: 'pointer',
    '&[data-state="checked"]': { backgroundColor: '$primary04' },

    '&[disabled]': { cursor: 'not-allowed' },

    variants: {
        variant: {
            filter: {
                '&[data-disabled]': { backgroundColor: '$neutral06', span: { backgroundColor: '#FFFFFF30' } },
                '&[data-state="checked"]': { backgroundColor: '$primary04' },
            },
        },
    },
})

const StyledSwitchThumb = styled(SwitchPrimitive.Thumb, {
    display: 'block',
    width: 18,
    height: 18,
    backgroundColor: 'white',
    borderRadius: '9999px',
    transition: 'transform 100ms',
    transform: 'translateX(2px)',
    willChange: 'transform',
    '&[data-state="checked"]': { transform: 'translateX(19px)' },
})

// Switch component

const Switch = ({ checked = false, onCheckedChange, variant, disabled }) => (
    <StyledSwitch
        variant={variant}
        checked={checked}
        onCheckedChange={onCheckedChange}
        id="s1"
        disabled={disabled}
        data-cy="button-switch"
    >
        <StyledSwitchThumb />
    </StyledSwitch>
)

Switch.propTypes = {
    checked: PropTypes.bool,
    onCheckedChange: PropTypes.func, // .isRequired,
}

export default Switch
