import React from 'react'
import { styled } from '../range-theme/index.js'

const StyledCardButton = styled('div', {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'flex-start',
    gap: '8px',
    cursor: 'pointer',
    fs: '13px',
    fg: '$neutral05',
    p: '16px',

    borderRadius: '6px',
    border: '1px solid var(--colors-neutral07)',
    backgroundColor: 'var(--colors-neutral09)',
    color: 'var(--colors-neutral05)',
    transitionDuration: '0.4s',
    boxShadow: '0px 0px 0px #5D81FF50',

    '&[aria-disabled="true"]': {
        opacity: 0.7,
        pointerEvents: 'none',
        backgroundColor: 'var(--colors-neutral09)',
        cursor: 'not-allowed',
        color: 'var(--colors-neutral06)',
    },

    '&:hover': {
        color: 'var(--colors-neutral04)',
        border: '1px solid var(--colors-primary03)',
        boxShadow: '6px 6px 2px #8B8F9D20',
    },

    '&:active': {
        backgroundColor: 'var(--colors-primary03)',
        color: 'var(--colors-neutral04)',
        boxShadow: 'none',
    },

    '&:focus': {
        border: '1px solid $primary04',
    },

    '&:focus-visible': {
        outline: 'none',
    },
})

const CardButton = ({ onClick, children, css, disabled, ...rest }) => {
    const handleKeyDown = e => {
        if (e.key === 'Enter' || e.keyCode === 32) onClick()
    }

    return (
        <StyledCardButton
            role="button"
            tabIndex={0}
            onClick={onClick}
            css={css}
            aria-disabled={disabled}
            onKeyDown={handleKeyDown}
            {...rest}
        >
            {children}
        </StyledCardButton>
    )
}

export default CardButton
