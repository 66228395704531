/*
 * Define filter settings for the Task List on task list screen
 * There are about a dozen different filter settings the user can set
 * There is also a "default" value for all the settings used to control:
 *
 * - the "reset" button
 * - the filter button
 * - the filter banner
 *
 * There are a slew of functions to update the settings of the form: (oldSettings, newSetting) => newSettings
 */

import { equals, LookupTable, omit, tagged, without } from '@range.io/functional'
import { ParticipantShape } from 'range-editor/src/react-shapes/participant-shape.js'

const TaskListFilterSettings = tagged('TaskListFilterSettings', {
    showStatuses: '[StatusShape]',

    shouldFilterByCanvas: 'Boolean',
    selectedCanvasShapes: '[CanvasShape]',

    showOnlyOverdueTasks: 'Boolean',
    showOnlyCreatedByMe: 'Boolean',
    showArchivedTasks: 'Boolean',

    shouldFilterByTags: 'Boolean',
    tags: '[TagShape]',
    matchUntagged: 'Boolean',

    shouldFilterByDateCreated: 'Boolean',
    dateCreatedDayRange: 'Number?',

    shouldFilterByDateUpdated: 'Boolean',
    dateUpdatedDayRange: 'Number?',

    shouldFilterByAssignee: 'Boolean',
    matchUnassigned: 'Boolean',
    allUsers: '[ParticipantShape]', // LookupTable actually, not array
    assignees: '[ParticipantShape]', // LookupTable actually, not array

    shouldFilterByFollower: 'Boolean',
    followers: '[ParticipantShape]', // LookupTable actually, not array

    shouldFilterByIdentifiers: 'Boolean',
    selectedIdentifiers: '[String]',
})

// can't be usefully set until the project data is loaded
const defaults = (statusShapes, participantShapes) => ({
    showStatuses: statusShapes,
    showOnlyOverdueTasks: false,
    showOnlyCreatedByMe: false,
    showArchivedTasks: false,

    shouldFilterByTags: false,
    matchUntagged: false,
    tags: [],

    shouldFilterByCanvas: false,
    selectedCanvasShapes: LookupTable([], 'id'),

    shouldFilterByDateCreated: false,
    dateCreatedDayRange: undefined,

    shouldFilterByDateUpdated: false,
    dateUpdatedDayRange: undefined,

    shouldFilterByAssignee: false,
    matchUnassigned: false,
    allUsers: LookupTable(participantShapes, 'id'),
    assignees: LookupTable([], 'id'),

    shouldFilterByFollower: false,
    followers: LookupTable([], 'id'),

    shouldFilterByIdentifiers: false,
    selectedIdentifiers: [],
})

// Abbreviation
const from = TaskListFilterSettings.from

// defaults
const defaultFilterSettings = (statusShapes, participantShapes) => from(defaults(statusShapes, participantShapes))

/*
 * Are the current filter settings the same as the default filter settings?
 * As a special case, if shouldFilterByTags or shouldFilterByAssignee or shouldFilterByCanvas ARE true, but no tags or assignees
 * have been selected yet, consider this a match.
 */
const isSetToDefaults = (filterSettings, statusShapes, participantShapes) => {
    // We only want to compare applied statuses ids
    const statusesToIdsMap = statuses => statuses?.map(status => status.id).sort()

    const keysToOmit = [
        'shouldFilterByTags',
        'shouldFilterByAssignee',
        'shouldFilterByCanvas',
        'shouldFilterByDateCreated',
        'shouldFilterByDateUpdated',
        'shouldFilterByIdentifiers',
        'allUsers',
        'shouldFilterByFollower',
    ]

    let currentSettings = omit(keysToOmit, filterSettings)
    currentSettings = {
        ...currentSettings,
        showStatuses: statusesToIdsMap(currentSettings.showStatuses),
    }

    let defaultSettings = omit(keysToOmit, defaultFilterSettings(statusShapes, participantShapes))
    defaultSettings = {
        ...defaultSettings,
        showStatuses: statusesToIdsMap(defaultSettings.showStatuses),
    }

    return equals(currentSettings, defaultSettings)
}

// simple toggles
const setShowOnlyCreatedByMe = (nfs, b) => from({ ...nfs, showOnlyCreatedByMe: b })
const setShowOnlyOverdueTasks = (nfs, b) => from({ ...nfs, showOnlyOverdueTasks: b })
const setShowArchivedTasks = (nfs, b) => from({ ...nfs, showArchivedTasks: b })
const setShouldFilterByAssignee = (nfs, b) => from({ ...nfs, shouldFilterByAssignee: b })
const setShouldFilterByFollower = (nfs, b) => from({ ...nfs, shouldFilterByFollower: b })
const setShouldFilterByTags = (nfs, b) => from({ ...nfs, shouldFilterByTags: b })
const setShouldFilterByCanvas = (nfs, b) => from({ ...nfs, shouldFilterByCanvas: b })
const setShouldFilterByDateCreated = (nfs, b) => from({ ...nfs, shouldFilterByDateCreated: b })
const setDateCreatedDayRange = (nfs, b) => from({ ...nfs, dateCreatedDayRange: b })
const setShouldFilterByDateUpdated = (nfs, b) => from({ ...nfs, shouldFilterByDateUpdated: b })
const setDateUpdatedDayRange = (nfs, b) => from({ ...nfs, dateUpdatedDayRange: b })
const setShouldFilterByIdentifiers = (nfs, b) => from({ ...nfs, shouldFilterByIdentifiers: b })

// ---------------------------------------------------------------------------------------------------------------------
// Handle canvas toggles
// ---------------------------------------------------------------------------------------------------------------------
const setSelectedCanvasShapes = (nfs, b) => from({ ...nfs, selectedCanvasShapes: b })

// The user turned the checkbox OFF for the given canvasShape; remove it from the selected canvases
const unselectCanvas = (nfs, canvasShape) =>
    from({ ...nfs, selectedCanvasShapes: nfs.selectedCanvasShapes.removeItem(canvasShape) })

// toggle the specific canvas
const toggleCanvas = (nfs, canvasShape) => {
    const canvases = nfs.selectedCanvasShapes.toggleItem(canvasShape)

    return from({ ...nfs, selectedCanvasShapes: canvases })
}

// ---------------------------------------------------------------------------------------------------------------------
// Handle status toggles
// ---------------------------------------------------------------------------------------------------------------------

// This is tricky only because the Statuses and nominally part of the project data, and not predefined
const setStatus = (nfs, statusShape, checked) => {
    const newStatuses = checked ? nfs.showStatuses.concat(statusShape) : without(statusShape, nfs.showStatuses)
    return from({ ...nfs, showStatuses: newStatuses })
}

// ---------------------------------------------------------------------------------------------------------------------
// Handle assignee filters
// ---------------------------------------------------------------------------------------------------------------------

// The singleton "unassigned tasks" ParticipantShape
const unassignedTasksShape = ParticipantShape(
    '4db05060-896f-4481-ae74-11190f85700b',
    '',
    null,
    'Unassigned Tasks',
    false
)

// The user turned the checkbox OFF for the given participantShape; remove it from the assignees and reset matchUnassigned
const unselectAssignee = (nfs, participantShape) =>
    from({ ...nfs, assignees: nfs.assignees.removeItem(participantShape), matchUnassigned: false })

/*
 * The user toggled the checkbox for the given participantShape. There are 3 cases
 *
 *                                  side effect
 * - unassigned went on             turn OFF all "real" assignees; set matchUnassigned
 * - unassigned went off            turn OFF "unassigned"; reset matchUnassigned
 * - some other assignee changed    toggle assignee; reset matchUnassigned
 */
const toggleAssignee = (nfs, participantShape) => {
    // set assignees to ["unassigned"]; set matchUnassigned
    const turnOnUnassigned = () =>
        from({ ...nfs, assignees: LookupTable([participantShape], 'id'), matchUnassigned: true })

    // set assignees to [] and reset matchUnassigned
    const turnOffUnassigned = () => from({ ...nfs, assignees: LookupTable([], 'id'), matchUnassigned: false })

    // toggle the specific assignee; turn OFF "unassigned"; reset matchUnassigned
    const toggleAssignee = () => {
        let assignees = nfs.assignees.toggleItem(participantShape)
        assignees = assignees.removeItem(unassignedTasksShape)

        return from({ ...nfs, assignees, matchUnassigned: false })
    }

    if (participantShape === unassignedTasksShape && !nfs.matchUnassigned) return turnOnUnassigned()
    if (participantShape === unassignedTasksShape && nfs.matchUnassigned) return turnOffUnassigned()
    return toggleAssignee()
}

// ---------------------------------------------------------------------------------------------------------------------
// Handle follower filters
// ---------------------------------------------------------------------------------------------------------------------

const unselectFollower = (nfs, participantShape) =>
    from({ ...nfs, followers: nfs.followers.removeItem(participantShape) })

const toggleFollower = (nfs, participantShape) => {
    const newFollowers = nfs.followers.toggleItem(participantShape)
    return from({ ...nfs, followers: newFollowers })
}

// ---------------------------------------------------------------------------------------------------------------------
// Handle tag toggles
// ---------------------------------------------------------------------------------------------------------------------

const toggleMatchUntagged = nfs => from({ ...nfs, tags: [], matchUntagged: !nfs.matchUntagged })
const setTagShapes = (nfs, tags) => from({ ...nfs, tags, matchUntagged: false })

const dateRangeOptions = [
    { name: 'Last day', dayValue: 1 },
    { name: 'Last 5 days', dayValue: 5 },
    { name: 'Last 7 days', dayValue: 7 },
    { name: 'Last 14 days', dayValue: 14 },
    { name: 'Last 30 days', dayValue: 30 },
]

// ---------------------------------------------------------------------------------------------------------------------
// Handle identifier toggles
// ---------------------------------------------------------------------------------------------------------------------
const toggleIdentifier = (nfs, identifier) => {
    const identifiers = nfs.selectedIdentifiers
    const newIdentifiers =
        identifiers.indexOf(identifier) === -1 ? identifiers.concat(identifier) : without(identifier, identifiers)

    return from({ ...nfs, selectedIdentifiers: newIdentifiers })
}

const unselectIdentifier = (nfs, identifier) => {
    const newIdentifiers = without(identifier, nfs.selectedIdentifiers)

    return from({ ...nfs, selectedIdentifiers: newIdentifiers })
}

// ---------------------------------------------------------------------------------------------------------------------
// public API
// ---------------------------------------------------------------------------------------------------------------------

// defaults
TaskListFilterSettings.defaultFilterSettings = defaultFilterSettings
TaskListFilterSettings.isSetToDefaults = isSetToDefaults

// simple toggles
TaskListFilterSettings.setShowOnlyCreatedByMe = setShowOnlyCreatedByMe
TaskListFilterSettings.setShowOnlyOverdueTasks = setShowOnlyOverdueTasks
TaskListFilterSettings.setShowArchivedTasks = setShowArchivedTasks
TaskListFilterSettings.setShouldFilterByAssignee = setShouldFilterByAssignee
TaskListFilterSettings.setShouldFilterByCanvas = setShouldFilterByCanvas
TaskListFilterSettings.setShouldFilterByTags = setShouldFilterByTags
TaskListFilterSettings.setShouldFilterByFollower = setShouldFilterByFollower

TaskListFilterSettings.setShouldFilterByDateCreated = setShouldFilterByDateCreated
TaskListFilterSettings.setDateCreatedDayRange = setDateCreatedDayRange
TaskListFilterSettings.setShouldFilterByDateUpdated = setShouldFilterByDateUpdated
TaskListFilterSettings.setDateUpdatedDayRange = setDateUpdatedDayRange
TaskListFilterSettings.dateRangeOptions = dateRangeOptions

// canvas
TaskListFilterSettings.setSelectedCanvasShapes = setSelectedCanvasShapes
TaskListFilterSettings.unselectCanvas = unselectCanvas
TaskListFilterSettings.toggleCanvas = toggleCanvas

// status
TaskListFilterSettings.setStatus = setStatus

// // assignees
TaskListFilterSettings.unselectAssignee = unselectAssignee
TaskListFilterSettings.toggleAssignee = toggleAssignee
TaskListFilterSettings.unassignedTasksShape = unassignedTasksShape

// followers
TaskListFilterSettings.unselectFollower = unselectFollower
TaskListFilterSettings.toggleFollower = toggleFollower

// // tags
TaskListFilterSettings.setTagShapes = setTagShapes
TaskListFilterSettings.toggleMatchUntagged = toggleMatchUntagged

// identifiers
TaskListFilterSettings.setShouldFilterByIdentifiers = setShouldFilterByIdentifiers
TaskListFilterSettings.toggleIdentifier = toggleIdentifier
TaskListFilterSettings.unselectIdentifier = unselectIdentifier

export default TaskListFilterSettings
