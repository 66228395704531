/*
 * User
 *
 * A User is a person authorized to view a Project
 */
import { assoc, mergeRight, pick, tagged, update } from '@range.io/functional'
import { Invitation } from '../../index.js'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const User = tagged('User', {
    id            : StringTypes.Id,
    email         : 'String',
    firstName     : 'String',
    lastName      : 'String',
    timezone      : StringTypes.OptionalTimezone,
    avatarUrl     : StringTypes.OptionalImageUri,

    // required for the logged-in user only
    language      : StringTypes.OptionalCountryCode,
    preferredUnits: StringTypes.OptionalMeasurementUnits,
    permissions   : 'Object?', // { organizations: [Id], projects: [Id] }
    role          : 'String?', // Architect, Contractor, ...

    // optional
    phoneNumber   : StringTypes.OptionalPhoneNumber,
    title         : 'String?',
    
    invitations   : '[Object]?'
})

/*
 * Create a new User merging an old one with values from changes
 * @sig update :: (User, {k:v}) -> User
 */
User.update = (user, changes) => User.from(mergeRight(user, changes))

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Create a User from a JSON object
 * @sig fromFirebase :: {k:v} -> User
 */
User.fromFirebase = o => User.from(o)
User.toFirebase = user =>
    pick(
        [
            'id',
            'email',
            'avatarUrl',
            'firstName',
            'lastName',
            'language',
            'timezone',
            'preferredUnits',
            'role',
            'title',
            'permissions',
        ],
        user
    )

// Add an Invitation to
User.addInvitation = (user, invitation) => {
    const invitations = (user.invitations || []).concat(invitation)
    return User.from(assoc('invitations', invitations, user))
}

// add multiple invitations to the User
User.addInvitations = (user, invitations) => invitations.reduce(User.addInvitation, user)

// change the invitation with the given id
User.updateInvitation = (user, id, changes) => {
    const index = (user.invitations || []).findIndex(i => i.id === id)

    // the user's invitation may already have been deleted
    if (index === -1) return user

    const invitation = Invitation.from(mergeRight(user.invitations[index], changes))
    const invitations = update(index, invitation, user.invitations)
    return assoc('invitations', invitations, user)
}

// @sig fullName :: User -> String
User.fullName = user => {
    warnIfNotUser(user)
    return user ? `${user.firstName} ${user?.lastName}` : ''
}

// @sig nickname :: User -> String
User.nickname = user => {
    warnIfNotUser(user)
    return user ? `${user.firstName} ${user?.lastName[0]}` : ''
}

const warnIfNotUser = user => {
    if (!User.is(user)) console.warn('Expecting User; found ', user)
}

/*
 * Used as a fallback for an avatar
 */
User.initialLetter = user => {
    warnIfNotUser(user)
    return user.firstName?.[0].toUpperCase() || ' '
}

/*
 * The set of User roles we allow (as shown on the account page)
 */
// prettier-ignore
User.roles = [
    { name: 'Architect'                     , id: 'Architect'                    } ,
    { name: 'Client / Customer'             , id: 'Client / Customer'            } ,
    { name: 'Construction Supervisor'       , id: 'Construction Supervisor'      } ,
    { name: 'Construction Trades'           , id: 'Construction Trades'          } ,
    { name: 'Engineer'                      , id: 'Engineer'                     } ,
    { name: 'GIS Analyst'                   , id: 'GIS Analyst'                  } ,
    { name: 'Interior Designer'             , id: 'Interior Designer'            } ,
    { name: 'Land / Urban Planner'          , id: 'Land / Urban Planner'         } ,
    { name: 'Executive / Owner'             , id: 'Executive / Owner'            } ,
    { name: 'Project Manager'               , id: 'Project Manager'              } ,
    { name: 'Property / Facilities Manager' , id: 'Property / Facilities Manager'} ,
    { name: 'Other'                         , id: 'Other'                        } ,
]

export default User
