import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged } from '@range.io/functional'
import PropTypes from 'prop-types'

const FeatureShape = tagged('FeatureShape', {
    id: StringTypes.Id,
    parentId: StringTypes.Id,

    description: 'String?',
    name: 'String',
})

const FeatureShapePropTypes = {
    id: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,

    description: PropTypes.string,
    name: PropTypes.string.isRequired,
}

FeatureShape.fromFeature = shapeLookupTable => feature => {
    const shape = FeatureShape.from({
        id: feature.id,
        parentId: feature.geometryId,

        description: feature.description,
        name: feature.name,
    })

    shape.parent = () => shapeLookupTable.geometries.get(feature.geometryId)
    return shape
}

export { FeatureShape, FeatureShapePropTypes }
