import { convertSvgToPng } from '../../helpers'

const getImageUri = async uri => {
    if (!uri) return Promise.resolve(null)
    if (uri && uri.includes('data:image/svg+xml')) return await convertSvgToPng(uri, response => response)
    else return Promise.resolve(uri)
}

const makeCancelable = promise => {
    let isCanceled = false
    const wrappedPromise = new Promise((resolve, reject) => {
        promise
            .then(val => (isCanceled ? reject() : resolve(val)))
            .catch(error => (isCanceled ? reject() : reject(error)))
    })
    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true
        },
    }
}

export { getImageUri, makeCancelable }
