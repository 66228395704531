import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'

const queryParamsToKeep = ['emulator']

/*
 * It's possible for paramsToKeep and paramsFromPath to have the same key, in which case we should remove one
 * If they have DIFFERENT values it's not clear which to keep, so log and arbitrarily keep the value from paramsFromPath
 * @sig removeDuplicateParams :: ([String, String], Map String String) => [String, String]
 */
const removeDuplicateParams = (paramsToKeep, paramsFromPath) => {
    const reducer = (acc, paramToKeep) => {
        const [key, value] = paramToKeep

        if (paramsFromPath.has(key) && paramsFromPath.get(key) === value) return acc // ignore duplicate
        if (paramsFromPath.has(key) && paramsFromPath.get(key) !== value) {
            // complain, but ignore duplicate
            console.error('Parameters to navigate conflict', paramToKeep, paramsFromPath.get(key))
            return acc
        }

        // no conflict
        return [...acc, paramToKeep]
    }

    return paramsToKeep.reduce(reducer, [])
}

/*
 * Wrapper around useNavigate hook to allow certain query string parameters to be kept between navigation actions
 */
const useNavigateWithQuery = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    /*
     * Given a Path object, and options Object navigate to that destination while preserving certain query string parameters
     * It does that by dividing the transition into two steps:
     * 1st - normal navigate to the destination while saving parameters that we want to be kept
     * 2nd - on the pathname is resolved do another navigate but this time make sure search query is passed
     * Both this navigation actions have to respect passed in options to allow passing on history state that we use across the app
     * navigateWithQuery :: (Path, Object) => ()
     */
    const navigateWithQuery = (path, options) => {
        let paramsToKeep = []
        for (const [key, value] of searchParams.entries()) {
            if (queryParamsToKeep.indexOf(key) !== -1) paramsToKeep.push([key, value])
        }
        navigate(path, options)

        // keep the current pathname to prevent a race condition if window.location.pathname
        // changes by the time we get to the 2nd navigate below.
        //
        // Hopefully there were no cases in which we depended on this race condition working, but if there were, it's
        // still better to fix those cases and guarantee the pathname is always the same in both these calls to navigate
        const pathname = window.location.pathname

        // we need to extract requests query string parameters from the path and make sure
        // they are retained when doing final navigate
        const paramsString = typeof path === 'number' ? '' : path.split('?').at(1) // (path CAN be a number, eg. -1)
        const paramsFromPath = new URLSearchParams(paramsString)

        paramsToKeep = removeDuplicateParams(paramsToKeep, paramsFromPath)

        setTimeout(() => {
            const search = createSearchParams([...paramsToKeep, ...paramsFromPath]).toString()
            navigate({ pathname, search }, { replace: true, ...options })
        }, 0)
    }

    return navigateWithQuery
}

export default useNavigateWithQuery
