import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { styled } from '../range-theme/index.js'
import { TimeFormat } from '@range.io/basic-types'

import { Button, DropdownMenuWithTooltip, Icon, Text } from '../components-reusable/index.js'

const StyledVersionPill = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
    backgroundColor: '$secondary02',
    color: '$white',
    borderRadius: '1000px',
    padding: '5px 10px',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 700,
})

const VersionPill = ({ version, as = 'none', ...rest }) => {
    if (version === null) return null

    return (
        <StyledVersionPill {...rest}>
            <Icon name="photoStack" iconSize="14px" />
            <Text inherit>Version {version + 1}</Text>
        </StyledVersionPill>
    )
}

VersionPill.propTypes = {
    version: PropTypes.number,
    as: PropTypes.string,
    onClick: PropTypes.func,
}

VersionPill.defaultProps = {
    as: 'none', // it will make the pill role as presentation, but you can turn it to "button"
}

const StyledVersionPillButton = styled(Button, {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
    backgroundColor: '$secondary02',
    color: '$white',
    borderRadius: '1000px',
    padding: '5px 10px',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 700,
})

const VersionDropdown = ({ upload, onUploadClicked, onUploadNewFile }) => {
    const { version, correlatedUploads, id } = upload
    const inputFile = useRef(null)

    const hasVersions = upload.correlatedUploads?.length > 1

    const onButtonClick = e => inputFile.current.click()

    const _onUpload = e => {
        if (e.target.files) onUploadNewFile(e)
    }

    return (
        <div>
            <DropdownMenuWithTooltip.Root>
                <DropdownMenuWithTooltip.Trigger tooltipText="More Actions" asChild>
                    <StyledVersionPillButton variant="primary">
                        <Icon name="photoStack" iconSize="14px" />
                        <Text inherit>Version {version + 1}</Text>
                        <Icon
                            name="chevronDown2"
                            iconSize="8px"
                            css={{
                                marginLeft: '8px',
                            }}
                        />
                    </StyledVersionPillButton>
                </DropdownMenuWithTooltip.Trigger>
                <DropdownMenuWithTooltip.Content
                    css={{
                        background: '$neutral10',
                        border: '1px solid $neutral07',
                        borderRadius: '6px',
                    }}
                    side="bottom"
                    align="start"
                    sideOffset={6}
                >
                    {correlatedUploads.map((upload, index) => {
                        if (upload.id === id) return null // skip if this is the upload that we currently see

                        const date = TimeFormat.simple(upload.date, null, false)

                        return (
                            <DropdownMenuWithTooltip.MenuItem key={index}>
                                <Button
                                    variant="dropdown-menuitem"
                                    onClick={() => onUploadClicked(upload.id)}
                                    css={{
                                        color: '$neutral05',
                                    }}
                                >
                                    <Text>
                                        Version {index + 1} • {date}
                                    </Text>
                                </Button>
                            </DropdownMenuWithTooltip.MenuItem>
                        )
                    })}
                    {hasVersions && <DropdownMenuWithTooltip.Separator />}
                    <DropdownMenuWithTooltip.MenuItem>
                        <Button
                            variant="dropdown-menuitem"
                            onClick={e => onButtonClick(e)}
                            css={{
                                color: '$neutral05',
                                gap: 8,
                            }}
                        >
                            <Icon name="addCircled" iconSize="16px" />
                            <Text>Add New Version</Text>
                        </Button>
                    </DropdownMenuWithTooltip.MenuItem>
                </DropdownMenuWithTooltip.Content>

                <input type="file" id="fileInput" ref={inputFile} onChange={_onUpload} style={{ display: 'none' }} />
            </DropdownMenuWithTooltip.Root>
        </div>
    )
}

VersionDropdown.propTypes = {
    version: PropTypes.number,
}

export { VersionDropdown, VersionPill }
