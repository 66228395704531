/*
 * Top row of the main area including:
 *
 * - left side
 *   - main menu
 *   - title (and selector)
 * - middle (tab menu for Canvas|Media)
 * - right side
 *   - collaborator avatars
 *   - notification count
 *   - toolbar with buttons for share, rotate
 */
import { Participant } from '@range.io/basic-types'
import React, { useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as Popover from '@radix-ui/react-popover'
import {
    AvatarWithTooltip,
    Button,
    DropdownMenuWithTooltip,
    FlexRow,
    Icon,
    RowBox,
    StatusPill,
    TabMenu,
    Text,
    Tooltip,
    Flex,
} from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import './knock-range.css'
import { KnockNotificationDropdown, OnlyProjectItemsCell } from './KnockNotificationDropdown.js' // Required CSS import, unless you're overriding the styling
import { MembersWindowContainer, MembersWindow } from './MembersWindow.js'

// ---------------------------------------------------------------------------------------------------------------------
// Styling
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const StyledControlBar = styled('div', {
    display        : "flex",
    flexDirection  : "row",
    height         : "var(--gridRow0Height)",
    alignItems     : "center",
    justifyContent : "space-between",
    color          : "$neutral04",
    backgroundColor: "$neutral10",
    borderBottom   : "1px solid $neutral07",
    position: 'relative',
    zIndex: 250,
    paddingRight: '14px',
    gridArea: 'controlBar'
})

// ---------------------------------------------------------------------------------------------------------------------
// Components
// ---------------------------------------------------------------------------------------------------------------------

const items = [
    { label: 'Canvas', id: 'nav-canvas' },
    { label: 'Task List', id: 'nav-task-list' },
    { label: 'Media', id: 'nav-media' },
]

// prettier-ignore
const StyledTriggerButton = styled('button', {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '$neutral08',
    outline: 'none',
    border: 'none',
    height: '100%',
    transitionDuration: '0.4s',

    '& svg': {
        color: '$neutral04',
    },

    '&:hover': {
        backgroundColor: '$primary03',
        borderColor: '$neutral07',
    },
})

const StyledProjectName = styled(Text, {
    color: '$neutral04',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600,
    pl: 14,
})

/*
 * Button that brings up the top-left "main menu" dropdown
 */
const MainMenuButton = () => {
    const viewDetails = () => navigate(`${projectPath}/details`, { state: { safeToNavigateBack: true } })
    const viewSources = () => navigate(`${projectPath}/sources`, { state: { safeToNavigateBack: true } })
    const viewTagManager = () => navigate(`${projectPath}/tags`, { state: { safeToNavigateBack: true } })

    const navigate = useNavigate()
    const { workspaceId, projectId } = useParams()
    const isProjectUpdateAllowed = useSelector(ReduxSelectors.isUpdateAllowed('project'))

    const projectPath = `/${workspaceId}/${projectId}`

    return (
        <DropdownMenuWithTooltip.Root>
            <DropdownMenuWithTooltip.Trigger tooltipText="Main Menu">
                <StyledTriggerButton data-cy="nav-dropdown-menu">
                    {/* <Button css={{ w: 32, color: '$neutral05' }} variant="dropdown-trigger"> */}
                    <Icon iconSize="20px" name="logo" css={{ ml: '2px', mr: '2px' }} />
                    <Icon iconSize="12px" name="chevronDown" />
                    {/* </Button> */}
                </StyledTriggerButton>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Content css={{ w: 200, mt: 8, ml: 8 }} side="bottom" align="end" sideOffset={-1}>
                <DropdownMenuWithTooltip.MenuItem>
                    <Button
                        variant="dropdown-menuitem"
                        onClick={() => navigate(`/${workspaceId}/projects`)}
                        data-cy="nav-projects-page"
                    >
                        <Text>All Projects</Text>
                    </Button>
                </DropdownMenuWithTooltip.MenuItem>
                <DropdownMenuWithTooltip.Separator css={{ mb: 0 }} />
                {isProjectUpdateAllowed && (
                    <DropdownMenuWithTooltip.MenuItem>
                        <Button variant="dropdown-menuitem" onClick={viewDetails} data-cy="nav-project-details-page">
                            <Text>Project Details</Text>
                        </Button>
                    </DropdownMenuWithTooltip.MenuItem>
                )}

                {isProjectUpdateAllowed && (
                    <DropdownMenuWithTooltip.MenuItem>
                        <Button variant="dropdown-menuitem" onClick={viewSources} data-cy="nav-canvas-sources-page">
                            <Text>PDF Canvas Sources</Text>
                        </Button>
                    </DropdownMenuWithTooltip.MenuItem>
                )}

                <DropdownMenuWithTooltip.MenuItem>
                    <Button variant="dropdown-menuitem" onClick={viewTagManager} data-cy="nav-tag-manager-page">
                        <Text>Project Tag Manager</Text>
                    </Button>
                </DropdownMenuWithTooltip.MenuItem>
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Root>
    )
}

/*
 * Draw the collaborator avatars (entirely fictitious for now -- doesn't even accept props)
 */
const CollaboratorAvatars = () => {
    const presentParticipants = useSelector(ReduxSelectors.presentProjectParticipants).filter(user => !!user)
    const user = useSelector(ReduxSelectors.selectedUser)

    if (!user) return null

    const avatar = user => {
        const userName = Participant.nickname(user)
        return (
            <AvatarWithTooltip
                key={user.email}
                size="28"
                side="bottom"
                url={user.avatarUrl}
                fallbackText={userName?.[0]}
                tooltipText={userName}
            />
        )
    }

    return (
        <RowBox css={{ ai: 'center', gap: '8px' }}>
            <>
                {user && avatar(user)}
                {presentParticipants.map(avatar)}
            </>
        </RowBox>
    )
}

const ControlBar = ({ tabIndex = 0 }) => {
    const handleTabChange = tabNumber => {
        // Don't do anything if the user clicks on the active tab
        if (tabNumber === tabIndex) return
        if (tabNumber === 0) {
            const selectedCanvas = ReduxSelectors.selectedCanvas(getState())
            if (selectedCanvas) navigate(`../${selectedCanvas.id}`)
            else navigate('..')
        } else if (tabNumber === 1) {
            navigate('../list')
        } else {
            navigate('../media')
        }
    }

    const navigate = useNavigate()
    const { getState } = useStore()
    const project = useSelector(ReduxSelectors.selectedProject)
    const organization = useSelector(ReduxSelectors.selectedOrganization)
    const selectedProjectId = useSelector(ReduxSelectors.selectedProjectId)
    const [showMembersWindow, setShowMembersWindow] = useState(false)
    const canManageProjectMembers = useSelector(ReduxSelectors.isUpdateAllowed('project', selectedProjectId))

    return (
        <StyledControlBar className="control-bar">
            <MainMenuButton />
            <FlexRow css={{ gap: '8px' }}>
                <StyledProjectName data-cy="project-name">{project?.name}</StyledProjectName>
                {project?.isArchived && <StatusPill>ARCHIVED</StatusPill>}
            </FlexRow>
            <TabMenu css={{ margin: '0 auto' }} items={items} onChange={handleTabChange} initialIndex={tabIndex} />

            <Flex css={{ gap: '16px' }}>
                <CollaboratorAvatars />

                {canManageProjectMembers && (
                    <Popover.Root onOpenChange={setShowMembersWindow} open={showMembersWindow} modal={false}>
                        <Popover.Trigger asChild>
                            <div>
                                <Tooltip tooltipText="Edit Project Access" side="bottom" align="center">
                                    <Button
                                        css={{ borderRadius: '999px', color: '$neutral04' }}
                                        size="sm"
                                        variant="secondary"
                                    >
                                        <Icon name="team" iconSize="16" />
                                        <Text>Members</Text>
                                    </Button>
                                </Tooltip>
                            </div>
                        </Popover.Trigger>
                        <Popover.Portal>
                            <Popover.Content
                                side="bottom"
                                align="start"
                                sideOffset={4}
                                style={{
                                    zIndex: 2,
                                    position: 'relative',
                                    height: 'auto',
                                    maxHeight: `var(--radix-popper-available-height)`,
                                }}
                            >
                                <MembersWindowContainer>
                                    <MembersWindow
                                        organizationName={organization.name}
                                        onCloseClick={() => setShowMembersWindow(false)}
                                    />
                                </MembersWindowContainer>
                            </Popover.Content>
                        </Popover.Portal>
                    </Popover.Root>
                )}

                <Tooltip tooltipText="Notifications" side="bottom">
                    <Flex>
                        <KnockNotificationDropdown KnockItemCell={OnlyProjectItemsCell} />
                    </Flex>
                </Tooltip>
            </Flex>
        </StyledControlBar>
    )
}

ControlBar.TABS = {
    CANVAS: 0,
    LIST: 1,
    MEDIA: 2,
}

export default ControlBar
