/*
 * DrawPolyLineMode
 *
 * Draw mode used to draw a line build from many line segments
 *
 * Type DrawPolyLineModeState:
 *    line                  : DrawFeature<JsonFeature>
 *    currentVertexPosition : Integer
 *    direction             : 'forward' | 'backwards'
 */
import { lib as MapboxDrawLib } from '@mapbox/mapbox-gl-draw'
import BasicDrawMode from './basic-draw-mode.js'

const { CommonSelectors, isEventAtCoordinates, createVertex } = MapboxDrawLib

const DrawPolyline = { ...BasicDrawMode }

/*
 * @sig onSetup :: {} -> DrawPolyLineModeState
 */
DrawPolyline.onSetup = function () {
    const line = this.newFeature({
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: [],
        },
        properties: { annotationType: 'polyline' },
    })
    this.addFeature(line)
    const currentVertexPosition = 0
    const direction = 'forward'

    this.clearSelectedFeatures()
    this.setActionableState({ trash: true })

    return {
        line,
        currentVertexPosition,
        direction,
    }
}

/*
 * @sig clickAnywhere :: (DrawPolyLineModeState, Event) -> ()
 */
DrawPolyline.clickAnywhere = function (state, e) {
    if (
        (state.currentVertexPosition > 0 &&
            isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition - 1])) ||
        (state.direction === 'backwards' &&
            isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition + 1]))
    ) {
        return this.finishDrawing(state)
    }
    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat)
    if (state.direction === 'forward') {
        state.currentVertexPosition++
        state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat)
    } else {
        state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat)
    }
}

/*
 * @sig clickOnVertex :: DrawPolyLineModeState -> DrawPolyLineModeState
 */
DrawPolyline.clickOnVertex = function (state) {
    return this.changeMode('select', { featureId: state.line.id })
}

/*
 * @sig onMouseMove :: (DrawPolyLineModeState, Event) -> ()
 */
DrawPolyline.onMouseMove = function (state, e) {
    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat)
}

/*
 * @sig onTap :: (DrawPolyLineModeState, Event) -> ()
 * @sig onClick :: (DrawPolyLineModeState, Event) -> ()
 */
DrawPolyline.onTap = DrawPolyline.onClick = function (state, e) {
    if (CommonSelectors.isVertex(e)) return this.clickOnVertex(state, e)
    this.clickAnywhere(state, e)
}

/*
 * @sig onStop :: (DrawPolyLineModeState) -> ()
 */
DrawPolyline.onStop = function (state) {
    // check to see if we've deleted this feature
    if (this.getFeature(state.line.id) === undefined) return
    // remove last added coordinate
    state.line.removeCoordinate(`${state.currentVertexPosition}`)
    if (state.line.isValid()) this.map.fire('draw.create', { features: [state.line.toGeoJSON()] })
}

/*
 * @sig toDisplayFeatures (DrawPolyLineModeState, GeoJsonFeature, DisplayFunc) -> ()
 * DisplayFunc = GeoJsonFeature -> ()
 */
DrawPolyline.toDisplayFeatures = function (state, geojson, display) {
    const isActiveLine = geojson.properties.id === state.line.id
    geojson.properties.active = isActiveLine ? 'true' : 'false'
    if (!isActiveLine) return display(geojson)
    // Only render the line if it has at least one real coordinate
    if (geojson.geometry.coordinates.length < 2) return
    geojson.properties.meta = 'feature'
    display(
        createVertex(
            state.line.id,
            geojson.geometry.coordinates[state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1],
            `${state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1}`,
            false
        )
    )
    display(geojson)
}

/*
 * @sig finishDrawing :: (DrawPolyLineModeState) -> ()
 */
DrawPolyline.finishDrawing = function (state) {
    this.changeMode('select', { featureId: state.line.id })
}

/*
 * @sig cancelDrawingAndDelete :: (DrawPolyLineModeState) -> ()
 */
DrawPolyline.cancelDrawingAndDelete = function (state) {
    this.deleteFeature([state.line.id])
    this.changeMode('idle')
}

export default DrawPolyline
