/*
 * Participant
 *
 * A Participant is a subset of a User attached to an Organization or Project
 */
import { mergeRight, pick, tagged } from '@range.io/functional'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Participant = tagged('Participant', {
    id            : StringTypes.Id,
    firstName     : 'String',
    lastName      : 'String',
    isSuspended   : 'Boolean?',
    avatarUrl     : StringTypes.OptionalImageUri,
    
    // required for participants; disallowed for removed participant (to be implemented)
    email           : StringTypes.OptionalEmail,
    timezone        : StringTypes.OptionalTimezone,
    organizationRole: StringTypes.OptionalOrganizationRole
})

/*
 * Create a new Participant merging an old one with values from changes
 * @sig update :: (Participant, {k:v}) -> Participant
 */
Participant.update = (participant, changes) => Participant.from(mergeRight(participant, changes))

// @sig fullName :: User -> String
Participant.fullName = p => (p ? `${p.firstName} ${p?.lastName}` : '')

/*
 * Used as a fallback for an avatar
 */
Participant.initialLetter = p => p.firstName?.[0].toUpperCase() || ' '

// @sig nickname :: Participant -> String
Participant.nickname = p => (p ? `${p.firstName} ${p?.lastName[0]}` : '')

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Create a Participant from a JSON object
 * @sig fromFirebase :: {k:v} -> Participant
 */
Participant.fromFirebase = o => Participant.from(mergeRight({ isSuspended: false }, o))

const fields = ['id', 'email', 'firstName', 'lastName', 'avatarUrl', 'timezone', 'organizationRole']
Participant.toFirebase = participant => pick(fields, participant)

export default Participant
