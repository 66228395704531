import { User } from '@range.io/basic-types'
import React from 'react'
import {
    Avatar,
    Button,
    DropdownMenuWithTooltip,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    LoadingDotsAnimation,
    Text,
} from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledUserMenuButton = styled(FlexRow, {
    cursor: 'pointer',
    p: '8px 8px',
    transitionDuration: '0.4s',
    br: 6,
    border: '1px solid $neutral07',
    ai: 'center',
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    backgroundColor: '$neutral10',
    mt: 4,
    gap: 8,
    '&:hover': { background: '$primary02', b: '1px solid $primary03', boxShadow: '6px 6px 2px #8B8F9D20' },
    '&[aria-expanded="true"]': {
        borderColor: '$primary03',
        background: '$primary02',
        '.icon-expand': {
            color: '$primary04',
        },
    },
})

const StyledUserNameText = styled(Text, {
    color: '$neutral04',
    pr: '2px',
    fontWeight: 700,
    fontSize: '13px',
})

const StyledUserEmailText = styled(Text, {
    color: '$neutral05',
    pr: '2px',
    fontSize: '12px',
})

const StyledUserMenu = styled(FlexColumn, {
    gap: 8,
    width: 'var(--radix-dropdown-menu-trigger-width)',
    maxWidth: 'var(--radix-dropdown-menu-content-available-width)',
    p: '10px 9px',
    br: 6,
    border: '1px solid $neutral08',
    ai: 'left',
    overflow: 'hidden',
    backgroundColor: '$neutral10',
})

const StyledMenuItem = styled(Button, {
    '&[role="menuitem"]': {
        padding: '6px 9px',
        color: 'var(--colors-neutral05)',
        height: 40,
        br: 6,

        '&:hover:enabled': {
            color: 'var(--colors-neutral04)',
            backgroundColor: '$neutral09',
        },

        '&[active="true"]': {
            color: 'var(--colors-neutral04)',
            backgroundColor: 'var(--colors-neutral08)',

            '&:hover:enabled': {
                backgroundColor: 'var(--colors-neutral07)',
            },
        },
    },
})

const StyledMenuItemIcon = styled(Icon, {
    mr: 8,
    width: '14px',
    height: '14px',
})

/*
 * UserMenuDropdown: for a selected User show username, email and avatar and links to settings
 */
const UserMenuDropdown = ({
    user,
    selectedPanel,
    navigationTypes,
    onLogOut,
    openInNewTab,
    navigateToAccountSettings,
}) => {
    const fullName = user ? User.fullName(user) : null
    const isLoading = !user // Assuming user is null or undefined while loading

    return (
        <DropdownMenuWithTooltip.Root>
            <DropdownMenuWithTooltip.Trigger tooltipText="Account Menu">
                <StyledUserMenuButton>
                    {isLoading ? (
                        <Flex css={{ alignItems: 'center', width: '100%', height: 38 }}>
                            <LoadingDotsAnimation variant="tertiarySmall" />
                        </Flex>
                    ) : (
                        <>
                            <Avatar
                                size="24"
                                css={{ minWidth: '24px', flex: '1 0 auto', boxSizing: 'border-box' }}
                                color="gray"
                                url={user?.avatarUrl || null}
                                fallbackText={user?.firstName[0]}
                            />
                            <FlexColumn css={{ minWidth: 0, flex: '1 1 auto', fw: 700 }}>
                                <StyledUserNameText ellipsed>{fullName}</StyledUserNameText>
                                <StyledUserEmailText ellipsed>{user?.email}</StyledUserEmailText>
                            </FlexColumn>
                            <Icon
                                className="icon-expand"
                                iconSize="10px"
                                name="userMenuExpand"
                                css={{
                                    color: '$neutral05',
                                    flex: '1 0 auto',
                                }}
                            />
                        </>
                    )}
                </StyledUserMenuButton>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Content side="bottom" align="start" variant="no-appearance" sideOffset={6}>
                <StyledUserMenu>
                    <DropdownMenuWithTooltip.MenuItem>
                        <StyledMenuItem
                            variant="dropdown-menuitem"
                            onClick={navigateToAccountSettings}
                            active={`${navigationTypes.ACCOUNT === selectedPanel}`}
                        >
                            <StyledMenuItemIcon name="settings" />
                            <Text ellipsed>Account Settings</Text>
                        </StyledMenuItem>
                    </DropdownMenuWithTooltip.MenuItem>

                    <DropdownMenuWithTooltip.MenuItem>
                        <StyledMenuItem
                            variant="dropdown-menuitem"
                            onClick={() => openInNewTab('https://range.io/mobile')}
                        >
                            <StyledMenuItemIcon name="mobile" />
                            <Text ellipsed>Get Mobile App</Text>
                        </StyledMenuItem>
                    </DropdownMenuWithTooltip.MenuItem>
                    <DropdownMenuWithTooltip.MenuItem>
                        <StyledMenuItem data-cy="logout" variant="dropdown-menuitem" onClick={onLogOut}>
                            <StyledMenuItemIcon name="logOut" />
                            <Text ellipsed>Log Out</Text>
                        </StyledMenuItem>
                    </DropdownMenuWithTooltip.MenuItem>
                </StyledUserMenu>
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Root>
    )
}

export default UserMenuDropdown
