import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, FlexColumn, IconButtonWithTooltip, LoadingDotsAnimation } from '../components-reusable/index.js'
import Tooltip from '../components-reusable/Tooltip.js'
import * as Commands2 from '../firebase/commands-2/index.js'
import { styled } from '../range-theme/index.js'
import OrganizationIcon from './OrganizationIcon'

const StyledButton = styled(Button, {
    color: '$neutral01',
    backgroundColor: '$neutral08',
    mb: 16,
    borderRadius: 1000,
    h: 32,
    w: 32,
    fw: 500,
    outline: '0px solid $primary04',
    overflow: 'hidden',

    variants: {
        selected: {
            true: {
                overflow: 'hidden',
                outline: '2px solid $primary04',
            },
        },
    },
})

const VerticalToggleGroup = ({ organizations, selectedItemId, setSelectedItemId }) => {
    const onValueChange = id => {
        if (id) setSelectedItemId(id)
    }

    const toggleOrganization = organization => (
        <Tooltip key={organization.id} side="right" tooltipText={organization.name}>
            <ToggleGroupPrimitive.Item type="single" value={organization.id} aria-label={organization.name} asChild>
                <StyledButton
                    data-cy="organization"
                    selected={selectedItemId === organization.id}
                    variant="secondary"
                    css={{
                        padding: 0,
                    }}
                >
                    <OrganizationIcon src={organization.icon} name={organization.name} />
                </StyledButton>
            </ToggleGroupPrimitive.Item>
        </Tooltip>
    )

    return (
        <ToggleGroupPrimitive.Root type="single" value={selectedItemId} onValueChange={onValueChange}>
            {organizations.map(toggleOrganization)}
        </ToggleGroupPrimitive.Root>
    )
}

/*
 * AdminOrganizationsPanelDumb: for a selected Organization show summary information and a selector for
 * Notifications, Projects, Members and Settings
 */
const AdminOrganizationsPanelDumb = ({
    organizations,
    selectedOrganizationId,
    setSelectedOrganizationId,
    createNewOrganization,
}) => {
    const css = {
        minWidth: 64,
        w: 64,
        p: '20px 16px 0px 16px',
        h: '100vh',
        backgroundColor: '$neutral10',
        color: '$neutral01',
        borderRight: '1px solid $neutral07',
    }

    return (
        <FlexColumn css={css}>
            {!selectedOrganizationId ? (
                <FlexColumn css={{ width: '64px', marginLeft: '-8px' }}>
                    <LoadingDotsAnimation variant="tertiarySmall" />
                </FlexColumn>
            ) : (
                <VerticalToggleGroup
                    organizations={organizations}
                    selectedItemId={selectedOrganizationId}
                    setSelectedItemId={setSelectedOrganizationId}
                />
            )}
            <IconButtonWithTooltip
                variant="createOrg"
                onClick={createNewOrganization}
                iconName="zoomIn"
                tooltipText="Create Organization"
                side="right"
                size="24px"
            ></IconButtonWithTooltip>
        </FlexColumn>
    )
}

const AdminOrganizationsPanel = ({ organizations, selectedOrganizationId, relativePath }) => {
    const setSelectedOrganizationId = id => {
        navigate(`/${id}/${relativePath}`) // change URL too
        Commands2.selectedOrganizationChanged(id)
    }

    const createNewOrganization = () => navigate(`/newOrganization`)

    const navigate = useNavigate()
    return (
        <AdminOrganizationsPanelDumb
            organizations={organizations}
            setSelectedOrganizationId={setSelectedOrganizationId}
            selectedOrganizationId={selectedOrganizationId}
            createNewOrganization={createNewOrganization}
        />
    )
}

const OrganizationShape = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

AdminOrganizationsPanelDumb.propTypes = {
    organizations: PropTypes.arrayOf(PropTypes.shape(OrganizationShape)).isRequired,
    selectedOrganizationId: PropTypes.string, // may be absent while loading
    setSelectedOrganizationId: PropTypes.func.isRequired,
}

AdminOrganizationsPanel.propTypes = {
    organizations: PropTypes.arrayOf(PropTypes.shape(OrganizationShape)).isRequired,
    selectedOrganizationId: PropTypes.string, // may be absent while loading
    relativePath: PropTypes.string.isRequired,
}

export { AdminOrganizationsPanelDumb }
export default AdminOrganizationsPanel
