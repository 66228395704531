import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import React, { forwardRef } from 'react'
import { styled } from '../range-theme'

const StyledRoot = styled(ScrollAreaPrimitive.Root, {
    height: '100%',
    overflow: 'hidden',
})

const StyledViewport = styled(ScrollAreaPrimitive.Viewport, {
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    '&>div': {
        tableLayout: 'fixed',
        width: '100%',
    },
})

const StyledScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
    width: '4px',
    display: 'flex',
    padding: 2,
    background: 'none',
    // ensures no selection
    userSelect: 'none',
    // disable browser handling of all panning and zooming gestures on touch devices
    touchAction: 'none',
    transition: 'background 160ms ease-out',
    zIndex: 201,
})

const StyledScrollbarThumb = styled(ScrollAreaPrimitive.Thumb, {
    flex: 1,
    background: '#9da3ba50',
    borderRadius: '4px',
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        minWidth: 44,
        minHeight: 44,
    },
})

const ScrollArea = forwardRef(function ScrollArea(
    { children, css = {}, viewportCss, maxHeight = 'auto', 'data-cy': dataCy },
    ref
) {
    return (
        <StyledRoot css={css}>
            <StyledViewport css={{ maxHeight, ...viewportCss }} ref={ref} data-cy={dataCy}>
                {children}
            </StyledViewport>
            <StyledScrollbar orientation="vertical">
                <StyledScrollbarThumb />
            </StyledScrollbar>
        </StyledRoot>
    )
})

export default ScrollArea
