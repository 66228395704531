import { styled } from '@stitches/react'
import * as TabsPrimitive from '@radix-ui/react-tabs'

const StyledTabsRoot = styled(TabsPrimitive.Root, {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    backgroundColor: '$neutral10',
})

const StyledTriggersList = styled(TabsPrimitive.List, {
    flexShrink: 0,
    display: 'flex',
    borderBottom: `1px solid $neutral07`,
    gap: '20px',
})

const StyledTrigger = styled(TabsPrimitive.Trigger, {
    all: 'unset',
    fontFamily: 'inherit',
    height: 33,
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 500,
    color: '$neutral05',
    userSelect: 'none',
    transitionDuration: '0.4s',

    '&:first-child': { borderTopLeftRadius: 6 },
    '&:last-child': { borderTopRightRadius: 6 },
    '&:hover': { color: '$neutral04', cursor: 'pointer' },
    '&[data-state="active"]': {
        color: '$primary04',
        boxShadow: 'inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor',
    },
})

const StyledContent = styled(TabsPrimitive.Content, {
    flexGrow: 1,
    backgroundColor: '$neutral10',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    outline: 'none',

    '&[hidden]': {
        display: 'none',
    },
})

const Tabs = {
    Root: StyledTabsRoot,
    List: StyledTriggersList,
    Trigger: StyledTrigger,
    Content: StyledContent,
}

export default Tabs
