/*
 * Component that allows the user to input a string of characters for potential tag names.
 * It has a placeholder stage where it's displayed as button.
 * Once active the input should resize according to put in value.
 */

import React from 'react'
import { Button, Icon, TextInput, Tooltip } from '../components-reusable/index.js'
import { getTextWidth } from '../helpers.js'
import { RangeUITheme, styled } from '../range-theme/index.js'

const baseInputStyle = {
    fontFamily: RangeUITheme.fonts.default,
    fontSize: '14px',
    lineHeight: '20px',
}

const StyledInputClearButton = styled(Button, {
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '5px',
    margin: 'auto',
    border: 'none',
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    background: '$primary04',
    transitionDuration: '0.4s',

    '&:hover': {
        cursor: 'pointer',
        $$hoverBackgroundColor: 'var(--colors-primary06)',
        $$hoverColor: 'white',
    },
})

const StyledCanvasInputIcon = styled(Icon, {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    bottom: 0,
    left: 10,
    margin: 'auto',
    color: '$neutral05',
    transitionDuration: '0.4s',

    '&:active': { color: '$neutral05' },
})

const StyleTextInputWrapper = styled('div', {
    position: 'relative',
    padding: 0,
    zIndex: 0,
    height: 30,
    width: 'fit-content',
    maxWidth: '100%',
    minWidth: '72px',
    gap: '8px',

    '&:hover': {
        [`& ${StyledCanvasInputIcon}`]: {
            color: 'var(--colors-neutral04)',
        },
    },

    input: {
        ...baseInputStyle,
        transition: 'all 0.4s ease-in-out, width 0s linear 0s',
        boxSizing: 'content-box',
        paddingLeft: '32px',
        borderRadius: '1000px',
        fw: 500,
        boxShadow: '0px 0px 0px #5D81FF50',
        cursor: 'pointer',

        '&::placeholder': {
            transitionDuration: '0.4s',
        },

        '&:hover': {
            border: '1px solid var(--colors-primary03)',
            boxShadow: '6px 6px 2px #8B8F9D20',
            backgroundColor: 'var(--colors-primary02)',
            color: 'var(--colors-neutral04)',

            '&::placeholder': {
                color: 'var(--colors-neutral04)',
            },
        },

        '&:active': {
            backgroundColor: 'var(--colors-primary03)',
            color: 'var(--colors-neutral04)',
            boxShadow: 'none',
        },
    },
})

const TagInput = ({
    inputWrapperRef,
    showList,
    setShowList,
    phraseToFilter,
    setPhraseToFilter,
    inputRef,
    onKeyPress,
    inputPlaceholder = 'Add Tags',
    ...rest
}) => {
    const clearInput = () => {
        setPhraseToFilter('')
        inputRef.current?.focus()
    }

    const hasPhraseToFilter = phraseToFilter?.length > 0

    return (
        <StyleTextInputWrapper ref={inputWrapperRef} {...rest}>
            <StyledCanvasInputIcon iconSize="16px" name="tags" />
            <TextInput
                onKeyPress={onKeyPress}
                ref={inputRef}
                value={phraseToFilter}
                placeholder={inputPlaceholder}
                onBlur={setPhraseToFilter}
                onChange={value => {
                    setPhraseToFilter(value)
                    if (!showList) setShowList(true) // ensure list shows up back again when user starts typing
                }}
                noClear
                style={{
                    paddingRight: `${hasPhraseToFilter ? '28px' : '10px'}`,
                    width: `${
                        hasPhraseToFilter
                            ? getTextWidth(phraseToFilter, baseInputStyle)
                            : getTextWidth(inputPlaceholder, baseInputStyle)
                    }`,
                }}
                css={{
                    '&::placeholder': {
                        color: '$neutral05',
                    },
                }}
            />
            {hasPhraseToFilter && (
                <Tooltip tooltipText="Clear" side="bottom" align="center">
                    <StyledInputClearButton onClick={clearInput}>
                        <Icon name="close" css={{ size: '8px', color: 'white' }} />
                    </StyledInputClearButton>
                </Tooltip>
            )}
        </StyleTextInputWrapper>
    )
}

export default TagInput
