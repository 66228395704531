import { styled } from '../range-theme/index.js'

const Flex = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',

    variants: {
        direction: {
            row: { flexDirection: 'row' },
            column: { flexDirection: 'column' },
            rowReverse: { flexDirection: 'row-reverse' },
            columnReverse: { flexDirection: 'column-reverse' },
        },
        align: {
            start: { alignItems: 'flex-start' },
            center: { alignItems: 'center' },
            end: { alignItems: 'flex-end' },
            stretch: { alignItems: 'stretch' },
            baseline: { alignItems: 'baseline' },
        },
        justify: {
            start: { justifyContent: 'flex-start' },
            center: { justifyContent: 'center' },
            end: { justifyContent: 'flex-end' },
            between: { justifyContent: 'space-between' },
        },
        wrap: {
            noWrap: { flexWrap: 'nowrap' },
            wrap: { flexWrap: 'wrap' },
            wrapReverse: { flexWrap: 'wrap-reverse' },
        },
    },
    defaultVariants: {
        direction: 'row',
        align: 'stretch',
        justify: 'center',
        wrap: 'noWrap',
    },
})

const FlexRow = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
})

const FlexColumn = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
})

export { Flex, FlexRow, FlexColumn }
