/*
 * An img with a description underneath it
 */
import { Upload } from '@range.io/basic-types'
import PropTypes from 'prop-types'

import React from 'react'
import { humanizeByteCount } from '../helpers.js'
import { styled } from '../range-theme/index.js'
import { Box } from './Box.js'
import { FlexColumn } from './Flex.js'
import { LoadingDotsAnimation } from './index.js'
import { Text } from './Text.js'

const StyledDescription = styled(Box, {
    color: '$white',
    width: '100%',
    fs: '14px',
    lineHeight: '1.4',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
})

const StyledName = styled(Box, {
    color: '$white',
    width: '100%',
    fs: '14px',
    fw: 700,
    lineHeight: '1.4',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
})

const StyledNameFullscreen = styled(Box, {
    color: '$white',
    width: '100%',
    fs: '20px',
    fw: 700,
    lineHeight: '1.4',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    whiteSpace: 'nowrap',
})

const StyledImageOverlay = styled('div', {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    br: '6px',
})

const StyledNameAndDescription = styled(FlexColumn, {
    bottom: 0,
    position: 'absolute',
    width: '100%',
    gap: 6,
    bg: '#00000090',
    backdropFilter: 'blur(6px) saturate(190%) contrast(50%) brightness(130%)',
    padding: 12,
})

const StyledImageContainer = styled(FlexColumn, {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    background: '$snapshotBackground',
    border: '1px solid $neutral07',
    br: '6px',
    width: '100%',
    margin: '6px 0 10px 0',
    boxSizing: 'border-box',
    transitionDuration: '0.4s',
    aspectRatio: '4/3',
    overflow: 'hidden',

    '&:hover': {
        border: '1px solid $primary04',
        cursor: 'pointer',
    },

    variants: {
        selected: {
            true: {
                [`& ${StyledImageOverlay}`]: {
                    background: '$green03',
                    opacity: 0.2,
                },
            },
            false: {
                '&:hover': {
                    background: '#000000',
                    [`& ${StyledImageOverlay}`]: {
                        background: '#00000050',
                    },
                },
            },
            undefined: {
                '&:hover': {
                    background: '#000000',
                    [`& ${StyledImageOverlay}`]: {
                        background: '#00000050',
                    },
                },
            },
        },
    },

    img: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        br: '5px',
    },
})

const StyledImageContainerDocs = styled(FlexColumn, {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    background: '$snapshotBackground',
    width: '100%',
    transitionDuration: '0.4s',
    overflow: 'hidden',
    height: '100%',
})

const ImageIcon = ({ url, description }) => {
    return <img alt={description} src={url} />
}

const NoPreviewIcon = ({ fileType, fileSize }) => {
    const text = () => {
        if (fileType === 'pdf') return '.pdf'
        if (fileType === 'xlsx') return '.xlxs'
        if (fileType === 'docx') return '.docx'
        return 'WHOA!'
    }
    const columnCss = {
        mb: 12,
        w: 90,
        h: 110,
        br: 10,
        bg: '#181921',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        border: '1px solid #30303f',
    }

    const humanized = humanizeByteCount(fileSize)
    return (
        <FlexColumn css={{ alignItems: 'center' }}>
            <FlexColumn css={columnCss}>
                <Text css={{ fs: 20, fw: 700, h: 30, color: '#8d8ea4' }}>{text()}</Text>
                {humanized && <Text css={{ fs: 14, fw: 500, color: '#8d8ea4' }}>{humanized}</Text>}
            </FlexColumn>
        </FlexColumn>
    )
}

const StyledComments = styled(Box, {
    color: '$secondary01',
    fs: '12px',
    fontWeight: 700,
    overflow: 'hidden',
})

const NoPreviewIconFullscreen = ({ fileType, fileSize }) => {
    const text = () => {
        if (fileType === 'pdf') return '.pdf'
        if (fileType === 'xlsx') return '.xlxs'
        if (fileType === 'docx') return '.docx'
        return 'WHOA!'
    }
    const columnCss = {
        mb: 12,
        w: 90,
        h: 110,
        br: 10,
        bg: '#1D1E29',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        border: '1px solid #333333',
    }
    const humanized = humanizeByteCount(fileSize)

    return (
        <FlexColumn css={{ alignItems: 'center', pb: 24 }}>
            <FlexColumn css={columnCss}>
                <Text css={{ fs: 20, fw: 700, h: 30, color: '#999999' }}>{text()}</Text>
                {humanized && <Text css={{ fs: 14, fw: 500, h: 21, color: '#999999' }}>{humanized}</Text>}
            </FlexColumn>
        </FlexColumn>
    )
}

const _mediaThumbnail = (NoPreview, url, fileType, fileSize, description) => {
    const isImage = fileType === 'image' || fileType === 'jpeg' || fileType === 'png'

    if (isImage && !!url) return <ImageIcon url={url} description={description} />
    if (isImage) return <LoadingDotsAnimation />
    return <NoPreview fileType={fileType} fileSize={fileSize} />
}

const MediaThumbnail = ({ url, fileType, fileSize, description }) =>
    _mediaThumbnail(NoPreviewIcon, url, fileType, fileSize, description)

const MediaThumbnailFullscreen = ({ url, fileType, fileSize, description }) =>
    _mediaThumbnail(NoPreviewIconFullscreen, url, fileType, fileSize, description)

const MediaIcon = props => {
    const { fileType, fileSize, url, description, name, onClick, commentsCount } = props
    const hasUsefulName = !Upload.isImage(props)

    const shouldShowBottomOverlay = hasUsefulName || description || !!commentsCount

    return (
        <StyledImageContainer onClick={onClick}>
            <MediaThumbnail fileType={fileType} fileSize={fileSize} url={url} description={description} />
            <StyledImageOverlay />
            {shouldShowBottomOverlay && (
                <StyledNameAndDescription>
                    {hasUsefulName && name && <StyledName>{name}</StyledName>}
                    {description && <StyledDescription>{description}</StyledDescription>}
                    {!!commentsCount && (
                        <StyledComments>
                            {commentsCount > 1 ? `${commentsCount} comments` : `${commentsCount} comment`}
                        </StyledComments>
                    )}
                </StyledNameAndDescription>
            )}
        </StyledImageContainer>
    )
}

const MediaIconFullscreen = props => {
    const { fileType, fileSize, url, name, onClick } = props

    return (
        <StyledImageContainerDocs onClick={onClick}>
            <MediaThumbnailFullscreen fileType={fileType} fileSize={fileSize} url={url} />
            {name && <StyledNameFullscreen>{name}</StyledNameFullscreen>}
        </StyledImageContainerDocs>
    )
}

MediaIcon.displayName = 'Media Icon'
MediaIcon.defaultProps = {}
MediaIcon.propTypes = {
    url: PropTypes.string, // undefined should produce a "loading..." UI
    fileType: PropTypes.oneOf(['jpeg', 'png', 'pdf', 'xlsx', 'docx']).isRequired,
    fileSize: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
}

MediaIconFullscreen.displayName = 'Media Icon Fullscreen'
MediaIconFullscreen.defaultProps = {}
MediaIconFullscreen.propTypes = {
    url: PropTypes.string, // undefined should produce a "loading..." UI
    fileType: PropTypes.oneOf(['jpeg', 'png', 'pdf', 'xlsx', 'docx']).isRequired,
    fileSize: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
}

export { MediaIconFullscreen, MediaThumbnail }
export default MediaIcon
