/*
 * See Readme
 */

import { FeedItem, Upload } from '@range.io/basic-types'
import PhotoAnnotations from '@range.io/basic-types/src/core/photo-annotations.js'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { firstKey, taggedSum } from '@range.io/functional'
import PhotoAnnotationRenderer from '../../components-application/photo-annotation-renderer.js'
import { getImageUrl, URL_SEARCH_ORDER } from '../../components-reusable/hooks/useImageUrl.js'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import * as Segment from '../../segment/segment.js'
import * as FirebaseFacade from '../firebase-facade.js'
import { itemFromFeedItem } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'
import { updateUpload } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// UploadChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const UploadChangedCommand = taggedSum('UploadChangedCommand', {
    Inbound: {
        feedItem: 'FeedItem',
    },
    Outbound: {
        id: StringTypes.Id, // Upload id
        changes: 'Object', // each entry defines key/value of the existing Upload that changed
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A FeedItem has arrived from Firestore; if it's an Upload/updated message, send it to redux
 * Since we're listening to project/participants/feedItems, we need to GET the Upload -- and this FeedItem may not be meant for us
 */
const runInboundCommand = async (resources, command) => {
    const upload = await itemFromFeedItem(Upload, 'Upload', 'updated', command.feedItem)
    if (!upload) return

    const { dispatch } = resources
    dispatch(ReduxActions.uploadAdded(upload))
}

/*
 * @sig loadMainImageForUpload :: (Id, Upload) -> Promise HTMLImage
 */
const loadMainImageForUpload = async (projectId, upload) => {
    const imageUrl = await getImageUrl(projectId, upload.id, URL_SEARCH_ORDER.MAIN, upload.storageVersion)

    return new Promise((resolve, reject) => {
        const image = new Image(imageUrl)
        image.onload = () => resolve(image)
        image.onerror = e => reject(e)
        image.crossOrigin = 'anonymous' // Ensuring CORS is considered
        image.src = imageUrl
    })
}

/*
 * If the Upload's annotations changed, we also need to save a '.annotated' file
 *
 * - load the Upload's 'main' image into an Image
 * - create an OffscreenCanvas with the same size as the Image
 * - draw the main image into the OffscreenCanvas
 * - render all the annotations on top of that
 * - get the bits from the OffscreenCanvas and write them back to storage and the new '.annotated' file
 * @sig saveAnnotatedImage :: (Id, Upload, [PhotoAnnotation]) -> Promise ()
 *
 */
const saveAnnotatedImage = async (projectId, upload, annotations) => {
    const renderAnnotationOffscreen = annotation => {
        const renderer = PhotoAnnotationRenderer.createRenderer(ctx, annotation, false, false, 1)
        PhotoAnnotationRenderer.render(ctx, renderer)
    }

    // load the 'main' image
    const image = await loadMainImageForUpload(projectId, upload)
    const width = image.naturalWidth
    const height = image.naturalHeight

    // create the OffscreenImage with the same size as the image
    const offscreenCanvas = new OffscreenCanvas(width, height)
    const ctx = offscreenCanvas.getContext('2d')

    // Draw the 'main' image into the OffscreenCanvas and then the annotations on top of it
    ctx.drawImage(image, 0, 0)
    annotations.annotations.forEach(renderAnnotationOffscreen)

    // Convert the canvas to a Blob as JPEG and save it as '.annotated'
    const blob = await offscreenCanvas.convertToBlob({ type: 'image/jpeg', quality: 0.75 })
    await FirebaseFacade.uploadFile(projectId, upload.id + '.annotated', blob)
}

/*
 * A UploadChangedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 * Note: we only change 1 field at a time: annotations, description, name or tags
 */
const runOutboundCommand = async (resources, command) => {
    // one track event per added tag
    const sendAddedTagsToSegment = () => {
        const trackOne = tagId => Segment.sendTrack('tag added', tagId, ReduxSelectors.paramsForTrackEvent(getState()))
        const oldValue = oldItem.tagIds
        const newValue = changes.tagIds
        const added = newValue.filter(item => !oldValue.includes(item)) // report only ADDING tags
        added.map(trackOne)
    }

    const handleTags = changes => {
        sendAddedTagsToSegment()

        // replace each tagId with { id: Id, name: String }
        const tagNames = ReduxSelectors.tagNameLookupTable(state)
        const tags = changes.tagIds.map(id => ({ id, name: tagNames[id].name }))
        return { tags }
    }

    const { id, changes } = command
    const { projectId, dispatch, displayError, getState } = resources
    const oldItem = ReduxSelectors.itemWithId(getState(), Upload, id)
    const field = firstKey(changes)
    const oldValue = oldItem[field]
    const state = getState()

    let { ...firebaseChanges } = FirebaseFacade.enrichDeletedFields(changes, null)

    // these fields need special conversions before saving to Firebase
    if (changes.tagIds) firebaseChanges = handleTags(changes)
    if (changes.annotations) firebaseChanges.annotations = PhotoAnnotations.toFirebase(changes.annotations)

    try {
        dispatch(ReduxActions.uploadChanged({ id, changes }))
        await updateUpload({ projectId, uploadId: id, changes: firebaseChanges })
        if (changes.annotations) {
            const upload = ReduxSelectors.itemWithId(state, Upload, id)
            await saveAnnotatedImage(projectId, upload, changes.annotations)
        }
    } catch (e) {
        const params = { id, changes: { [field]: oldValue } }
        dispatch(ReduxActions.uploadChanged(params))
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        UploadChangedCommand,
        CommandPlayer({
            CommandType: UploadChangedCommand,
            Type: FeedItem,
            collectionPath: (projectId, userId) => `/projects/${projectId}/participants/${userId}/feedItems`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added', // the FeedItem was ADDED, even though the Upload was changed
        })
    )
}

export { UploadChangedCommand, addCommand }
