import { TimeFormat, Upload } from '@range.io/basic-types'
import { mergeRight } from '@range.io/functional'
import PropTypes from 'prop-types'
import React from 'react'
import { URL_SEARCH_ORDER, useImageUrl } from '../components-reusable/hooks/useImageUrl.js'
import {
    Box,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    LoadingDotsAnimation,
    ScrollableList,
    Text,
} from '../components-reusable/index.js'
import { CollaborationShapePropTypes } from '../react-shapes/collaboration-shape.js'
import { DrawingToolsToolbarNavigator } from './DrawingToolsToolbar.js'
import Identifier from './Identifier.js'
import StatusDot from './StatusDot.js'

const TopRow = ({ collaborationShape, projectIdentifier }) => {
    const { status, uploads, identifier } = collaborationShape
    const { name, isArchived, isTask, isCompleted } = collaborationShape
    const title = name || 'Untitled Pin'

    const statusDotProps = {
        statusColor: status?.color,
        count: uploads?.length,
        isArchived,
        isCompleted,
        isTask,
        size: 22,
        statusLabel: status?.name,
        color: status?.color,
    }

    return (
        <FlexRow css={{ alignItems: 'center', gap: 8, fw: 500 }}>
            <StatusDot {...statusDotProps} />
            <FlexColumn css={{ overflow: 'hidden' }}>
                {identifier && (
                    <Identifier
                        projectIdentifier={projectIdentifier}
                        collaborationIdentifier={identifier}
                        variant="small"
                        css={{ lineHeight: '13px' }}
                    />
                )}
                <Text css={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</Text>
            </FlexColumn>
        </FlexRow>
    )
}
const BottomRow = ({ collaborationShape }) => {
    const { lastUpdateDate, uploads } = collaborationShape
    return (
        <FlexRow css={{ justifyContent: 'space-between', color: '$neutral05', fontWeight: 500, fontSize: 12 }}>
            <div>{TimeFormat.ago(lastUpdateDate)}</div>
            {uploads.length > 3 && <div>+ {uploads.length - 3} more</div>}
        </FlexRow>
    )
}

// all the boxes have the same size and borders
const thumbnailBoxCss = {
    // image in the center
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '$neutral10',
    alignItems: 'center',
    justifyContent: 'center',

    // fixed size
    width: 78,
    height: 60,

    // border
    borderRadius: 8,
    border: '1px solid $neutral07',
    transitionDuration: '0.4s',
    '&:hover': { border: '1px solid $blue04' },
}

const ThumbnailBox = ({ url, onClick }) => {
    const css = mergeRight(thumbnailBoxCss, { backgroundImage: 'url(' + url + ')' })
    return <Box css={css} onClick={onClick} />
}

const LoadingBox = ({ onClick }) => (
    <Box css={thumbnailBoxCss} onClick={onClick}>
        <LoadingDotsAnimation variant="tertiarySmall" />
    </Box>
)

/*
 * A single Upload image as a small thumbnail
 */
const UploadImage = ({ upload, onImageClicked }) => {
    const onClick = e => onImageClicked(e, upload.id, upload.parentId)
    const { url } = useImageUrl(URL_SEARCH_ORDER.ANNOTATED_THUMBNAIL_0, upload.id)

    return url ? <ThumbnailBox url={url} onClick={onClick} /> : <LoadingBox onClick={onClick} />
}

/*
 * The middle row of the CollaborationCard shows the first 3 Uploads
 */
const PhotoRow = ({ collaborationShape, onImageClicked }) => {
    const uploadRow = u => <UploadImage key={u.id} upload={u} onImageClicked={onImageClicked} />

    let { uploads } = collaborationShape
    uploads = uploads
        .filter(Upload.isImage)
        .sort((a, b) => b.date - a.date) // order uploads from newest to oldest, so most recent uploads are first
        .slice(0, 3) // get only 3 uploads

    return uploads.length ? (
        <FlexRow css={{ alignItems: 'center', gap: 8, fw: 500 }}>{uploads.map(uploadRow)}</FlexRow>
    ) : null
}

/*
 * Show a subset of the Collaboration fields and its Uploads, and allow the user to click on the tile
 */
const CollaborationCard = ({
    collaborationShape,
    projectIdentifier,
    selectedCollaborationId,
    onTileClicked,
    onImageClicked,
}) => {
    const collaborationIsSelected = selectedCollaborationId === collaborationShape.id

    const border = collaborationIsSelected ? '1px solid $primary03' : '1px solid $neutral07'
    const backgroundColor = collaborationIsSelected ? '$primary02' : '$neutral09'

    const css = {
        fontSize: 14,
        backgroundColor,
        color: '$neutral01',
        transitionDuration: '0.4s',
        boxShadow: '0px 0px 0px #5D81FF50',

        margin: '12px 12px 0 12px',
        padding: '12px 12px 10px 12px',
        gap: 8,

        // border
        br: 6,
        border,
        '&:hover': {
            backgroundColor: '$primary02',
            boxShadow: '4px 6px 2px #8B8F9D20',
            border: '1px solid $primary03',
        },

        '&:active': {
            backgroundColor: '$primary03',
            boxShadow: 'none',
            border: '1px solid $primary03',
        },

        cursor: 'pointer',
    }

    const onClick = e => onTileClicked(e, collaborationShape)
    return (
        <FlexColumn css={css} onClick={onClick}>
            <TopRow collaborationShape={collaborationShape} projectIdentifier={projectIdentifier} />
            <PhotoRow collaborationShape={collaborationShape} onImageClicked={onImageClicked} />
            <BottomRow collaborationShape={collaborationShape} />
        </FlexColumn>
    )
}

const ListViewEmptyArea = ({ isSetToDefault, selectedTool, canCreateCollaboration }) => {
    const showDrawingTools = isSetToDefault && canCreateCollaboration

    const emptyListText = canCreateCollaboration
        ? `Place pins on the canvas using the following buttons which are also located in the top left.`
        : null

    return (
        <FlexColumn css={{ height: '100%', width: '100%', pl: 24, pr: 24, pt: '25vh', ai: 'center' }}>
            <Flex>
                <Flex
                    css={{
                        width: 4,
                        height: 2,
                        position: 'absolute',
                        filter: 'blur(4px)',
                        background: '$neutral01',
                        mt: 74,
                    }}
                />
                <Icon
                    name="pin"
                    css={{ color: '$neutral07', width: 48, mb: 12, position: 'relative', border: '1px solid $red' }}
                />
                <Icon name="questionmark" css={{ color: '$neutral04', width: 16, position: 'absolute', pt: 12 }} />
            </Flex>
            <Text css={{ color: '$neutral04', fs: '16px', fw: '600', lh: '24px', mb: '8px', textAlign: 'center' }}>
                {isSetToDefault ? `It looks like there are no pins...🤔` : `It looks like there are no results...🤔`}
            </Text>
            <Text css={{ color: '$neutral05', fs: '12px', fw: '500', lh: '18px', mb: '16px', textAlign: 'center' }}>
                {isSetToDefault ? emptyListText : `Try adjusting, or clearing your filters`}
            </Text>
            <FlexColumn>
                {showDrawingTools ? <DrawingToolsToolbarNavigator selectedTool={selectedTool} /> : <Flex />}
            </FlexColumn>
        </FlexColumn>
    )
}

/*
 * A vertical list of CollaborationCards
 */

const CollaborationCardList = ({
    canCreateCollaboration,
    collaborationShapes,
    projectIdentifier,
    selectedCollaborationId,
    selectedTool,
    onTileClicked,
    onImageClicked,
    isSetToDefault,
}) => {
    const card = collaborationShape => (
        <CollaborationCard
            key={collaborationShape.id}
            collaborationShape={collaborationShape}
            projectIdentifier={projectIdentifier}
            selectedCollaborationId={selectedCollaborationId}
            onTileClicked={onTileClicked}
            onImageClicked={onImageClicked}
        />
    )

    const isViewEmpty = collaborationShapes.length === 0
    if (isViewEmpty)
        return (
            <ListViewEmptyArea
                canCreateCollaboration={canCreateCollaboration}
                selectedTool={selectedTool}
                isSetToDefault={isSetToDefault}
            />
        )

    const scrollAreaCss = { bg: '$neutral10', w: 'var(---gridColumn0Width)' }

    return <ScrollableList css={scrollAreaCss} data={collaborationShapes} renderDataItem={card} />
}

CollaborationCard.propTypes = {
    collaborationShape: PropTypes.shape(CollaborationShapePropTypes).isRequired,
    projectIdentifier: PropTypes.string,
    selectedCollaborationId: PropTypes.string,
    onTileClicked: PropTypes.func.isRequired,
    onImageClicked: PropTypes.func.isRequired,
}

CollaborationCardList.propTypes = {
    collaborationShapes: PropTypes.arrayOf(PropTypes.shape(CollaborationShapePropTypes)).isRequired,
    projectIdentifier: PropTypes.string,
    selectedCollaborationId: PropTypes.string,
    selectedTool: PropTypes.string,

    onTileClicked: PropTypes.func.isRequired,
    onImageClicked: PropTypes.func.isRequired,
}

export { CollaborationCard, CollaborationCardList }
