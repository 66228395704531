import React from 'react'
import { useSelector } from 'react-redux'
import { ReduxSelectors } from '../redux/index.js'
import { styled } from '../range-theme/index.js'

import { FlexColumn } from '../components-reusable/index.js'
import ControlBar from './ControlBar.js'
import { CanvasSnippetRenderer } from './CanvasSnippet.js'

const PageWrapperStyled = styled('div', {
    width: '100%',
    height: '100vh',
    maxHeight: '100vh',
    maxWidth: '100vw',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: '50px 1fr',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: "'controlBar' 'pageContent'",
    justifyItems: 'stretch',
    boxSizing: 'border-box',
})

const PageContentWrapperStyled = styled(FlexColumn, {
    gridArea: 'pageContent',
    minWidth: 0,
    overflow: 'hidden',
})

const PageContent = styled(FlexColumn, {
    padding: '12px',
    width: '100%',
    height: '100%',
    background: '$neutral09',
})

const ProjectPage = ({ children, activeTab, 'data-cy': dataCy }) => {
    const showCanvasSnippetMode = useSelector(ReduxSelectors.showCanvasSnippetMode)

    return (
        <PageWrapperStyled data-cy={dataCy}>
            <ControlBar tabIndex={activeTab} />
            <PageContentWrapperStyled>{children}</PageContentWrapperStyled>
            {showCanvasSnippetMode && <CanvasSnippetRenderer collaboration={showCanvasSnippetMode.collaboration} />}
        </PageWrapperStyled>
    )
}

export { PageContent }

export default ProjectPage
