import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '../range-theme/index.js'

const StyledLogo = styled('div', {
    width: '100%',
    height: 'auto',
    maxWidth: '48px',
    maxHeight: '49px',
    display: 'inline-block',
})

const Logo = props => {
    const { width, height, maxWidth, maxHeight, ...otherProps } = props

    return (
        <StyledLogo css={{ width, height, maxWidth, maxHeight }} {...otherProps}>
            <img type="image/svg+xml" src="/rangeicon.svg" alt="Range logo" />
        </StyledLogo>
    )
}

Logo.displayName = 'Logo'
Logo.defaultProps = {}

Logo.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string,
}

export default Logo
