import * as Select from '@radix-ui/react-select'
import React from 'react'
import { styled } from '../range-theme/index.js'
import Tooltip from '../components-reusable/Tooltip.js'

const zIndex = 400 // what Sam called: "400 - Floating Components" ?

const StyledRoot = styled(Select.Root, {})

const StyledTrigger = styled(Select.Trigger, {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    background: '$neutral10',
    borderRadius: '6px 0px 0px 6px',
    borderTop: '1px solid $neutral07',
    borderBottom: '1px solid $neutral07',
    borderLeft: '1px solid $neutral07',

    '&:hover': {
        background: '$neutral08',
    },

    '&[aria-expanded="true"]': {
        backgroundColor: 'var(--colors-neutral08)',
    },
})

const StyledColorMenu = styled(Select.Content, {
    background: '$neutral10',
    width: 40,
    border: '1px solid $neutral07',
    borderRadius: 6,
    cursor: 'pointer',
    overflow: 'clip',
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    marginTop: 8,
})

const StyledColorMenuItem = styled(Select.Item, {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: 38,
    height: 40,
    '&:hover': {
        background: '$neutral08',
        width: 38,
        height: 40,
    },
})

const StyledCircle = styled('span', {
    display: 'block',
    width: 16,
    height: 16,
    margin: 5,
    borderRadius: '50%',
    border: '1px solid $neutral05',
})

const ColorSelect = ({ colors, initialValue, onColorChanged }) => {
    // Find the index of the initialValue in the colors array
    const initialIndex = colors.indexOf(initialValue.toUpperCase())

    return (
        <StyledRoot
            defaultValue={initialIndex.toString()}
            onValueChange={value => onColorChanged(colors[parseInt(value)])}
        >
            <Tooltip tooltipText="Color">
                <StyledTrigger data-cy="annotation-color-picker" aria-label="Select a color">
                    <Select.Value>
                        <StyledCircle style={{ background: initialValue }} />
                    </Select.Value>
                    {/* <StyledTriggerIcon /> */}
                </StyledTrigger>
            </Tooltip>

            <Select.Portal style={{ zIndex }}>
                <StyledColorMenu position="popper">
                    <Select.Viewport>
                        {colors.map((color, index) => (
                            <StyledColorMenuItem
                                data-cy={'annotation-color-' + index}
                                key={index}
                                value={index.toString()}
                            >
                                <Select.ItemText>
                                    <StyledCircle style={{ background: color, margin: 8 }} />
                                </Select.ItemText>
                            </StyledColorMenuItem>
                        ))}
                    </Select.Viewport>
                </StyledColorMenu>
            </Select.Portal>
        </StyledRoot>
    )
}

export default ColorSelect
