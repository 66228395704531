/*
 * Is the 'action' allowed on the 'resourceType' for the currently selected user
 * (possibly considering an actual 'object')
 *
 * @sig checkPermissions :: (State, Action, ResourceType, Type|Shape) -> Boolean
 *  Action = create|read|update|delete
 *  ResourceType = comment|upload
 */
import { selectedOrganization, selectedProject, selectedUser } from './redux-selectors-basic.js'

// enum to store all possible roles for a User
const UserRoles = {
    ADMIN: 'Admin',
    COLLABORATOR: 'Collaborator',
    GUEST: 'Guest',
}

const fieldsAllowedToEditForGuest = {
    collaboration: [
        'assignee',
        'assigneeId',
        'dueDate',
        'name',
        'description',
        'tags',
        'addTags',
        'statusId',
        'followers',
    ],
}

const checkPermissions = (state, action, resourceType, object) => {
    const isProjectAdmin = () => {
        const user = selectedUser(state)
        const project = selectedProject(state)
        if (!user || !project || !project.participants) return false

        return project?.participants[user.id]?.organizationRole === UserRoles.ADMIN
    }

    const isOrganizationAdmin = () => {
        const user = selectedUser(state)
        const organization = selectedOrganization(state)
        if (!user || !organization) return false

        return organization.participants[user.id].organizationRole === UserRoles.ADMIN
    }

    const isAuthor = () => {
        if (!object) return false

        // object.author|authorId could be either a Shape or id
        let authorId = ''
        if (typeof object.author === 'string') authorId = object.author
        else if (typeof object.authorId === 'string') authorId = object.authorId
        else authorId = object?.author?.id

        const user = selectedUser(state)
        return user && user.id === authorId
    }

    const isPaidUser = () => {
        const user = selectedUser(state)
        const organization = selectedOrganization(state)
        if (!user || !organization) return false

        const userOrganizationRole = organization.participants[user.id].organizationRole
        return userOrganizationRole === UserRoles.ADMIN || userOrganizationRole === UserRoles.COLLABORATOR
    }

    const isAllowedGuestForResource = resource => {
        const user = selectedUser(state)
        const organization = selectedOrganization(state)
        if (!user || !organization) return false

        const userOrganizationRole = organization.participants[user.id].organizationRole
        // we only are interested in Guest users here
        if (userOrganizationRole !== UserRoles.GUEST) return false

        // we require 'changes' property to validate specific update call
        if (!object.changes) {
            console.warn(`There's no changes property for check in ${action} :: ${resourceType}`)
            return false
        }

        return Object.keys(object.changes).every(changeKey => fieldsAllowedToEditForGuest[resource].includes(changeKey))
    }

    if (action === 'create' && resourceType === 'project') return isOrganizationAdmin()
    if (action === 'create' && resourceType === 'collaboration') return isPaidUser()
    if (action === 'update' && resourceType === 'comment') return isAuthor() || isProjectAdmin()
    if (action === 'update' && resourceType === 'geometry') return isPaidUser()
    if (action === 'update' && resourceType === 'upload') return isAuthor() || isProjectAdmin()
    if (action === 'update' && resourceType === 'project') return isOrganizationAdmin()
    if (action === 'update' && resourceType === 'organization') return isOrganizationAdmin()
    if (action === 'update' && resourceType === 'collaboration')
        return isPaidUser() || isAllowedGuestForResource('collaboration')
    if (action === 'delete' && resourceType === 'collaboration') return isAuthor() || isProjectAdmin()
    if (action === 'delete' && resourceType === 'upload') return isAuthor() || isProjectAdmin()

    throw new Error(`Don't understand ${action} for resourceType ${resourceType}`)
}

/*
 * Convenience functions to wrap checkPermissions
 */
const isCreateAllowed = (resource, object) => state => checkPermissions(state, 'create', resource, object)
const isUpdateAllowed = (resource, object) => state => checkPermissions(state, 'update', resource, object)
const isDeleteAllowed = (resource, object) => state => checkPermissions(state, 'delete', resource, object)
const isViewAllowed = (resource, object) => state => checkPermissions(state, 'view', resource, object)

export { isUpdateAllowed, isCreateAllowed, isDeleteAllowed, isViewAllowed, UserRoles }
