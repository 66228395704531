import { DateFormat } from '@range.io/basic-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { Box, FlexRow, Icon, Tooltip } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import { FilterPill, FilterPillWithAvatar } from './FilterPill.js'

const ClearAllFiltersButtonWrapper = styled(Box, {
    width: '32px',
    minWidth: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '$neutral09',
    border: '1px solid $neutral07',
    borderRadius: '100px',
    transitionDuration: '0.4s',

    '&:hover': {
        cursor: 'pointer',
        border: '1px solid $red03',
    },
})

const FilterPillContainer = styled(FlexRow, {
    marginLeft: '10px',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& > div': {
        marginRight: '8px',
        marginBottom: '4px',
    },
})

const ActiveFilterList = ({
    currentFilters,
    formatCanvasItem,
    formatTagItem,
    formatUserItem,
    onClearAllClick,
    onFilterClearClick,
}) => {
    const participantShapes = useSelector(ReduxSelectors.selectedProjectParticipantShapes)

    const buildDateFilterPill = () => {
        return currentFilters.dateRange.length ? (
            <FilterPill onDeleteClick={() => onFilterClearClick('date')}>
                <Icon name="calendar" iconSize="14px" css={{ marginRight: '6px' }} />
                {DateFormat.primary(currentFilters.dateRange[0])} - {DateFormat.primary(currentFilters.dateRange[1])}
            </FilterPill>
        ) : null
    }

    const buildCanvasFilterPill = () =>
        currentFilters.canvasIds.map((canvasId, idx) => (
            <FilterPill onDeleteClick={() => onFilterClearClick('canvas', canvasId)} key={`filter_tag_${idx}`}>
                <Icon name="levels" iconSize="14px" css={{ marginRight: '6px' }} />
                {formatCanvasItem(canvasId)}
            </FilterPill>
        ))

    const buildTagFilterPills = () =>
        currentFilters.tagIds.map((tagIds, idx) => (
            <FilterPill onDeleteClick={() => onFilterClearClick('tag', tagIds)} key={`filter_tag_${idx}`}>
                <Icon name="tags" iconSize="14px" css={{ marginRight: '6px' }} />
                {formatTagItem(tagIds)}
            </FilterPill>
        ))

    const buildUserFilterPills = () => {
        return currentFilters.participantIds.map((participantId, idx) => {
            return (
                <FilterPillWithAvatar
                    key={`user_tag_${idx}`}
                    participantShape={participantShapes[participantId]}
                    onDeleteClick={() => onFilterClearClick('user', participantId)}
                />
            )
        })
    }

    return (
        <FlexRow css={{ width: '100%', marginTop: '16px' }}>
            <Tooltip tooltipText="Clear all" side="bottom" align="center">
                <ClearAllFiltersButtonWrapper onClick={onClearAllClick}>
                    <Icon name="close" css={{ size: '12px', color: '$red03' }} />
                </ClearAllFiltersButtonWrapper>
            </Tooltip>
            <FilterPillContainer>
                {buildDateFilterPill()}
                {buildCanvasFilterPill()}
                {buildTagFilterPills()}
                {buildUserFilterPills()}
            </FilterPillContainer>
        </FlexRow>
    )
}

export default ActiveFilterList
