/*
 * A "simple" Mapbox box that:
 *
 * - shows 0 or 1 "place" (point + name)
 * - optionally allows the user to click to geocode a new place
 * - optionally allows the user to enter a place name into a Mapbox search box and geocode its point
 *
 *
 * mapCenter        specifies the initial Mapbox center (before the user pans)
 * mapZoom          specifies the initial Mapbox zoom (before the user zooms)
 * place            specifies a Place
 * setPlace         optional callback when the user has changed the place by clicking the map or geocoding a place name
 * css              optional css to wrap the map with
 *
 * Type
 *
 * XYArray          [lng, lat]
 * Place            { point: XYArray, name: String }
 */
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-dev.js'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react' // eslint-disable-line import/no-webpack-loader-syntax
import { styled } from '../range-theme/index.js'
import './MapboxGeocoder.css'

/* global fetch */

// ---------------------------------------------------------------------------------------------------------------------
// Mapbox setup
// ---------------------------------------------------------------------------------------------------------------------
const accessToken = 'pk.eyJ1IjoicmFuZ2UtbWFwYm94IiwiYSI6ImNsZW9tdjFyZDAwZ3Azd25xYW9lbmdyNjUifQ.XwN5HIELuR-mEhA_i_z5XA'
mapboxgl.accessToken = accessToken

const StyledMap = styled('div', {
    // defeat Mapbox's test to see if you've loaded mapboxgl.css -- which we haven't
    '.mapboxgl-canary': {
        backgroundColor: 'rgb(250, 128, 114)',
    },

    // place the geocoder text input box
    '.mapboxgl-ctrl-geocoder': {
        width: '100%',
        boxShadow: 'none',
        border: '1px solid $neutral07',
        borderRadius: 6,
        outline: 'none',
        color: '$neutral05',
        backgroundColor: '$neutral09',

        '&:focus-within': {
            border: '1px solid $primary04',
        },

        '.mapboxgl-ctrl-geocoder--input': {
            width: '100%',
        },
    },
})

const MAPBOX_STYLE = 'mapbox://styles/range-mapbox/cleoron2t000401qryhl9exx2'
const MAPBOX_SOURCE_ID = 'points'
const MAPBOX_LAYER_ID = 'marker'

/*
 * Create the GeoJSON for a Mapbox Source with 0 or 1 point depending on whether markerPoint is defined
 * The source will be updated later if the marker changes via source.setData
 * @sig mapboxSource :: XYArray? -> GeoJSON
 */
const mapboxSource = place => ({
    type: 'geojson',
    data: {
        type: 'FeatureCollection',
        features: place?.point ? [{ type: 'Feature', geometry: { type: 'Point', coordinates: place.point } }] : [],
    },
})

/*
 * Create a Mapbox Layer to display a marker with the markerTitle
 * @sig mapboxLayer :: String -> MapboxLayerJSON
 */
const mapboxLayer = place => ({
    id: MAPBOX_LAYER_ID,
    type: 'symbol',
    source: MAPBOX_SOURCE_ID,
    layout: {
        'icon-image': 'custom-marker',
        'icon-anchor': 'bottom',
        'text-field': place?.name || '',
        'text-font': ['Arial Unicode MS Bold'],
        'text-offset': [0, 0],
        'text-anchor': 'top',
    },
    paint: {
        'text-color': '#ffffff',
        'text-halo-color': 'hsl(0, 5%, 0%)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// StaticMap
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Convert ForwardGeocodedData into a Place
 * see https://docs.mapbox.com/api/search/geocoding/#geocoding-response-object
 */
const createPlaceFromForwardGeocode = data => ({ point: data.center, name: data.place_name })

/*
 * Convert ReverseGeocodedData into a Place
 * see https://docs.mapbox.com/api/search/geocoding/#geocoding-response-object
 */
const createPlaceFromReverseGeocode = data => {
    const name = data?.features?.[0]?.place_name
    return name ? { point: data.query, name } : undefined
}

/*
 * Return a Geocode result from Mapbox's Geocoding REST API (https://docs.mapbox.com/api/search/geocoding/)
 * @sig reverseGeocode :: XYArray -> Promise Place
 */
const reverseGeocode = async point =>
    new Promise((resolve, reject) => {
        const [lng, lat] = point
        const s = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`
        fetch(s)
            .then(response => response.json())
            .then(data => resolve(createPlaceFromReverseGeocode(data)))
            .catch(reject)
    })

/*
 * Wrap a Mapbox map in a React component
 * if setMarkerPoint is specified the map will call it when the user clicks
 */
const SinglePlaceMap = ({ mapCenter, mapZoom, place, setPlace, css = {}, addressContainerId }) => {
    const _setPlace = place => {
        mapboxRef.current.flyTo({ center: place.point, zoom: 18, speed: 3 })
        setPlace(place)
    }

    // mapbox loaded: add the source (with 0 or 1 point) and layer
    const onLoadMap = () => {
        const iconUrl = '/pin.projectlocation.png'
        mapboxRef.current.loadImage(iconUrl, (error, image) => {
            if (error) throw error

            if (!mapboxRef.current) return // (happens in Cypress tests in CI/CD sometimes)

            mapboxRef.current.addImage('custom-marker', image)
            mapboxRef.current.addSource(MAPBOX_SOURCE_ID, mapboxSource(place))
            mapboxRef.current.addLayer(mapboxLayer(place))
        })
    }

    // user dragged/zoomed
    const onMoveMap = () => {
        setCenter(mapboxRef.current.getCenter().toArray())
        setZoom(mapboxRef.current.getZoom())
    }

    // if the markerPoint or markerTitle moved since last time, update the map source
    const onMovePlace = () => {
        if (!mapboxRef?.current?._loaded) return // can be called too early by useEffect

        const source = mapboxRef.current.getSource(MAPBOX_SOURCE_ID)
        if (!source) return

        source.setData(mapboxSource(place).data)
        mapboxRef.current.setLayoutProperty(MAPBOX_LAYER_ID, 'text-field', place.name)
    }

    const onForwardGeocodeResult = ({ result }) => _setPlace(createPlaceFromForwardGeocode(result))

    // called once via useEffect
    const initializeMapbox = () => {
        if (mapboxRef.current) return // initialize map only once

        // create the map and add listeners
        const container = mapContainer.current
        const mapOptions = {
            container,
            style: MAPBOX_STYLE,
            center,
            zoom,
            attributionControl: false,
        }
        mapboxRef.current = new mapboxgl.Map(mapOptions)
        mapboxRef.current.on('move', onMoveMap)
        mapboxRef.current.on('load', onLoadMap)

        // Add the control to the map.
        // listen for clicks ONLY if setMarkerPoint is set
        if (setPlace) {
            const placeholder = 'Enter an address'
            const geocoder = new MapboxGeocoder({ mapboxgl, accessToken, placeholder })
            if (addressContainerId) {
                document.getElementById(addressContainerId).innerHTML = '' // ensure the input renders only once
                geocoder.addTo(`#${addressContainerId}`)
            } else mapboxRef.current.addControl(geocoder)

            geocoder.on('result', onForwardGeocodeResult)

            mapboxRef.current.on('click', async e => {
                const point = e.lngLat.toArray()
                const place = await reverseGeocode(point)

                if (place) _setPlace(place)
            })
        }

        if (window.Cypress) {
            window.mapboxMap = mapboxRef.current
        }

        // for cleanup on unmount
        return () => {
            mapboxRef.current.remove()
            mapboxRef.current = undefined
        }
    }

    const mapContainer = useRef(null)
    const mapboxRef = useRef(null)
    const [center, setCenter] = useState(mapCenter)
    const [zoom, setZoom] = useState(mapZoom)
    useEffect(initializeMapbox, [])
    useEffect(onMovePlace, [place])

    return <StyledMap style={css} ref={mapContainer} />
}

const PlaceType = PropTypes.shape({
    name: PropTypes.string,
    point: PropTypes.arrayOf(PropTypes.number),
})

SinglePlaceMap.propTypes = {
    mapCenter: PropTypes.arrayOf(PropTypes.number).isRequired,
    mapZoom: PropTypes.number.isRequired,
    place: PlaceType,
    setPlace: PropTypes.func,
    css: PropTypes.object,
}

export default SinglePlaceMap
