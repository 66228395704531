/*
 * SelectMode
 *
 * Base select mode for geometries, handles all common interactions.
 * Extends Mapbox GL Draw's direct_select mode if not called on a Point feature (marker or text).
 * Base for all custom select modes.
 *
 * Type SelectModeState:
 *    showMidpoints : Boolean
 */

import MapboxDraw, { constants as Constants, lib as MapboxDrawLib } from '@mapbox/mapbox-gl-draw'
import { initHoverState } from './hoverStateHandler.js'

const { createSupplementaryPoints } = MapboxDrawLib

/*
 * Get if midpoints should be display for a given feature's annotation type
 * @sig getShowMidpointsForAnnotation :: JsonFeature -> Boolean
 */
const getShowMidpointsForAnnotation = feature =>
    ['line', 'arrow', 'rectangle'].indexOf(feature?.properties?.annotationType) === -1

/*
 * Get if a given annotation is one of the point types
 * @sig getIsPointAnnotationType :: JsonFeature -> Boolean
 */
const getIsPointAnnotationType = feature =>
    ['marker', 'text', 'photoMarker'].indexOf(feature?.properties?.annotationType) !== -1

const SelectMode = { ...MapboxDraw.modes.direct_select }

/*
 * Initializes base select state and redirects into custom select modes when needed
 * @sig onSetup :: {} -> SelectModeState
 */
SelectMode.onSetup = function (opts) {
    const selectedFeature = this.getFeature(opts.featureId)
    const isPointAnnotationType = getIsPointAnnotationType(selectedFeature)
    const state = isPointAnnotationType ? {} : MapboxDraw.modes.direct_select.onSetup.bind(this)(opts)
    state.showMidpoints = getShowMidpointsForAnnotation(selectedFeature)
    state.hoverState = initHoverState({ doRender: id => this.doRender(id), getFeature: id => this.getFeature(id) })
    if (isPointAnnotationType) {
        setTimeout(() => {
            if (!this._ctx.store) return // the map may have been unloaded since the timeout was set
            this.changeMode('select_symbol', { featureId: opts.featureId, baseState: state })
        }, 10) // 0 is too few; 10 seems to work
        return state
    }
    if (selectedFeature?.properties?.annotationType === 'arrow') {
        setTimeout(() => {
            this.changeMode('select_arrow', { featureId: opts.featureId, baseState: state })
        }, 10) // 0 is too few; 10 seems to work
        return state
    }
    if (selectedFeature?.properties?.annotationType === 'rectangle') {
        setTimeout(() => {
            this.changeMode('select_rectangle', { featureId: opts.featureId, baseState: state })
        }, 10) // 0 is too few; 10 seems to work
        return state
    }
    return state
}

/*
 * Called on every mouse move action when this mode is active.
 * Checks if the user hovers over any vertex or midpoint to update hoverState
 * @sig onMouseMove :: (SelectModeState, Event) -> ()
 */
SelectMode.onMouseMove = function (state, e) {
    state.hoverState.handleMouseMove(e)

    // TODO: commented to fix [#72]. Kept here for testing purposes if a potential fix is built.
    // if (isActiveFeature(e)) this.map.fire('draw.changeCursor', { cursorName: 'hover-selected' })
    // else this.map.fire('draw.resetCursor')
}

/*
 * Called when user clicked outside of any geometry
 * @sig clickNoTarget :: (SelectModeState, Event) -> ()
 */
SelectMode.clickNoTarget = function (state, e) {
    this.changeMode('idle')
}

/*
 * Called when user clicked on an inactive geometry
 * @sig clickInactive :: (SelectModeState, Event) -> ()
 */
SelectMode.clickInactive = function (state, e) {
    const featureId = e.featureTarget.properties.user_parentFeatureId || e.featureTarget.properties.id
    const isFeatureSelected = this.isSelected(featureId)
    if (e.originalEvent.shiftKey && !isFeatureSelected) {
        this.changeMode('multi_select', { featureId })
    } else this.changeMode('select', { featureId })
}

/*
 * @sig onKeyDown :: (SelectModeState, Event) -> ()
 */
SelectMode.onKeyDown = function (state, e) {
    // fire only if key is not hold continuously
    if (e.key === 'Shift' && !e.repeat) return this.map.fire('draw.changeCursor', { cursorName: 'select' })
}

/*
 * @sig onKeyUp :: (SelectModeState, Event) -> ()
 */
SelectMode.onKeyUp = function (state, e) {
    if (e.key === 'Escape') return this.changeMode('idle')
    if (e.key === 'Shift') return this.map.fire('draw.resetCursor')
}

/*
 * @sig onTouchEnd :: (SelectModeState, Event) -> ()
 * @sig onMouseUp :: (SelectModeState, Event) -> ()
 */
SelectMode.onTouchEnd = SelectMode.onMouseUp = function (state, e) {
    state.selectedCoordPaths = []
    this.clearSelectedCoordinates()
    MapboxDraw.modes.direct_select.onMouseUp.bind(this)(state, e)
}

/*
 * @sig startDragging :: (SelectModeState, Event) -> ()
 */
SelectMode.startDragging = function (state, e) {
    MapboxDraw.modes.direct_select.startDragging.bind(this)(state, e)
    if (e.featureTarget.properties.meta !== 'vertex' && e.featureTarget.properties.meta !== 'midpoint')
        this.map.fire('draw.changeCursor', { cursorName: 'move' })
}

/*
 * Called when a feature is dragged.
 * Because startDragging is also called on click we have to move startDrag here.
 * @sig onDrag :: (SelectModeState, Event) -> ()
 */
SelectMode.onDrag = function (state, e) {
    if (!state.dragMoving) this.map.fire('draw.startDrag')
    MapboxDraw.modes.direct_select.onDrag.bind(this)(state, e)
}

/*
 * @sig stopDragging :: (SelectModeState) -> ()
 */
SelectMode.stopDragging = function (state) {
    if (state.canDragMove) {
        this.map.fire('draw.resetCursor')
        this.map.fire('draw.stopDrag')
    }
    MapboxDraw.modes.direct_select.stopDragging.bind(this)(state)
}

/*
 * @sig toDisplayFeatures (SelectModeState, GeoJsonFeature, DisplayFunc) -> ()
 * DisplayFunc = GeoJsonFeature -> ()
 */
SelectMode.toDisplayFeatures = function (state, geojson, display) {
    if (state.featureId === geojson.properties.id) {
        const displayHandle = handle => {
            handle.properties.user_hover = state.hoverState.isHandleHovered(handle)
            display(handle)
        }
        geojson.properties.active = Constants.activeStates.ACTIVE
        display(geojson)
        createSupplementaryPoints(geojson, {
            map: this.map,
            midpoints: state.showMidpoints,
            selectedPaths: state.selectedCoordPaths,
        }).forEach(displayHandle)
    } else {
        geojson.properties.active = Constants.activeStates.INACTIVE
        geojson.properties.user_hover = state.hoverState.isGeoJsonHovered(geojson)
        display(geojson)
    }
}

/*
 * @sig onTrash :: (SelectModeState) -> ()
 */
SelectMode.onTrash = function () {
    this.deleteFeature(this.getSelectedIds())
}

export default SelectMode
