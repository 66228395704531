/*
 * See PhotoAnnotationEditor for more information about how this is used
 *
 * Data related to PhotoAnnotations:
 *
 * - Arrow
 * - Line
 * - Scribble
 * - Text
 *
 * PhotoAnnotations are immutable, but these functions will create a new PhotoAnnotation from an existing one:
 *
 * - update
 * - move
 * - extend (modifies the final point or adds a new one)
 * - setSize
 * - setColor
 */
import { mergeRight, splitEvery, tagged, taggedSum } from '@range.io/functional'
import { v4 } from 'uuid'
import {
    arePointsNearEachOther,
    boundingBox,
    convertPointsToBeziers,
    expandBoundingBox,
    offsetPoint,
    pointAtAngleToLine,
    simplifyPoints,
} from '../helper/geometry-computations.js'
import StringTypes from '../string-types.js'

const ARROW_HEAD_ANGLE = 135 // +/- angle of arrow heads relative to direction of arrow

// ---------------------------------------------------------------------------------------------------------------------
// DrawingCommand
// ---------------------------------------------------------------------------------------------------------------------
const DrawingCommand = tagged('DrawingCommand', {
    command: /[MLC]/,
    points: '[Number]', // [x0, y0, ..., xn, yn]
})

/*
 * @sig drawingCommandsFromPath :: PathString -> [DrawingCommand]
 *  PathString = String but:
 *
 * PathString is broken into commands (identical to SVG), so an arbitrary combination of M, L or C commands, eg.
 * 'M1,2 L3,4 C1,2,3,4,5,6 L7,8'
 */
DrawingCommand.drawingCommandsFromPath = s => {
    const parseSvgCommand = commandText => {
        const command = commandText[0]
        const points = commandText
            .substring(1)
            .trim()
            .split(/[\s,]+/)
            .map(Number)

        return DrawingCommand.from({ command, points })
    }

    const commandTexts = s.split(/(?=[MLC])/)
    return commandTexts.map(parseSvgCommand)
}

/*
 * Move every point in a DrawingCommand by [dx, dy]
 * @sig move :: (DrawingCommand, Number, Number) -> DrawingCommand
 */
DrawingCommand.move = (command, dx, dy) => {
    const points = splitEvery(2, command.points).flatMap(p => offsetPoint(p, dx, dy))
    return DrawingCommand.from({ command: command.command, points })
}

// Number -> Number
const numberTo2Decimals = n => parseFloat(n.toFixed(2))
const numberTo6Decimals = n => parseFloat(n.toFixed(6))

// convert to a string appropriate to send to Firestore
DrawingCommand.drawingCommandToPath = command => `${command.command}${command.points.map(numberTo2Decimals).join(',')}`
DrawingCommand.drawingCommandsToPath = commands => commands.map(DrawingCommand.drawingCommandToPath).join(' ')

// return the point for a DrawingCommand which will always be the last two values, since M and L have only two points
// and for C, we have 6 values, but the first 4 are the control points
DrawingCommand.point = command => command.points.slice(-2)

/*
 * Create DrawingCommands for an array of Points by creating a single 'M' followed by one 'L' for each other point
 *
 * @sig drawingCommandsFromPoints :: [Point] -> [DrawingCommand]
 *  Point = [x, y]
 */
DrawingCommand.drawingCommandsFromPoints = points => {
    const drawingCommands = [DrawingCommand('M', points[0])]
    return drawingCommands.concat(points.slice(1).map(p => DrawingCommand('L', p)))
}

/*
 * Create DrawingCommands for an array of Beziers by creating a single 'M' followed by one 'C' for each other point
 *
 * @sig drawingCommandsFromPoints :: (Point, [Bezier]) -> [DrawingCommand]
 *  Bezier = [Point, Point, Point] // [Control Point 1, Control Point 2, Final Point]
 *  Point = [x, y]
 */
DrawingCommand.drawingCommandsFromBeziers = (startPoint, beziers) => {
    const drawingCommands = [DrawingCommand('M', startPoint)]
    return drawingCommands.concat(beziers.map(bz => DrawingCommand('C', bz)))
}

// ---------------------------------------------------------------------------------------------------------------------
// PhotoAnnotation
// ---------------------------------------------------------------------------------------------------------------------
const PhotoAnnotation = taggedSum('PhotoAnnotation', {
    Scribble: {
        id: StringTypes.Id,
        type: /scribble/,
        hexColor: StringTypes.HexColor,
        lineWidth: 'Number',

        // computed
        drawingCommands: '[DrawingCommand]',
    },
    Text: {
        id: StringTypes.Id,
        type: /text/,
        hexColor: StringTypes.HexColor,
        text: 'String',
        fontSize: 'Number',
        position: '[Number]',
    },
    Arrow: {
        id: StringTypes.Id,
        type: /arrow/,
        hexColor: StringTypes.HexColor,
        lineWidth: 'Number',
        startPoint: '[Number]',
        endPoint: '[Number]',

        // computed
        leftHead: '[Number]', // [x, y]
        rightHead: '[Number]', // [x, y]
    },
    Line: {
        id: StringTypes.Id,
        type: /line/,
        hexColor: StringTypes.HexColor,
        lineWidth: 'Number',
        startPoint: '[Number]',
        endPoint: '[Number]',
    },
})

/*
 * Update o with values for leftHead and rightHead
 * The length of the line 4 * o.lineWidth is the same as the algorithm used by iOS
 * @sig updateScribbleHeads :: {k:v} -> {k:v}
 */
const updateScribbleHeads = o =>
    mergeRight(o, {
        leftHead: pointAtAngleToLine(o.startPoint, o.endPoint, -ARROW_HEAD_ANGLE, 4 * o.lineWidth),
        rightHead: pointAtAngleToLine(o.startPoint, o.endPoint, ARROW_HEAD_ANGLE, 4 * o.lineWidth),
    })

/*
 * Update o with values for drawingCommands (starting from o.path)
 * @sig updateDrawingCommands :: {k:v} -> {k:v}
 */
const updateDrawingCommands = o => mergeRight(o, { drawingCommands: DrawingCommand.drawingCommandsFromPath(o.path) })

/*
 * @sig fromFirebase :: {k:v} -> PhotoAnnotation
 */
PhotoAnnotation.fromFirebase = o => {
    if (o.type === 'arrow') return PhotoAnnotation.from(updateScribbleHeads(o))
    if (o.type === 'line') return PhotoAnnotation.from(o)
    if (o.type === 'scribble') return PhotoAnnotation.from(updateDrawingCommands(o))
    if (o.type === 'text') return PhotoAnnotation.from(o)

    throw new Error("Don't understand PhotoAnnotation", o)
}

/*
 * Convert a PhotoAnnotation to the form stored in Firestore -- but NOT JSON.stringified
 * @sig toFirebase :: PhotoAnnotation -> {k:v}
 */
PhotoAnnotation.toFirebase = photoAnnotation => {
    // for Scribbles, we need to convert our DrawingCommands back into an SVG-like path
    const scribbleToFirebase = () => {
        let { type, id, lineWidth, hexColor, drawingCommands } = photoAnnotation
        const path = DrawingCommand.drawingCommandsToPath(drawingCommands)
        lineWidth = numberTo6Decimals(lineWidth)
        return { type, id, lineWidth, hexColor, path }
    }

    const lineOrArrowToFirebase = () => {
        let { type, id, lineWidth, hexColor, startPoint, endPoint } = photoAnnotation
        startPoint = startPoint.map(numberTo6Decimals)
        endPoint = endPoint.map(numberTo6Decimals)
        lineWidth = numberTo6Decimals(lineWidth)
        return { type, id, lineWidth, hexColor, startPoint, endPoint }
    }

    const textToFirebase = () => {
        let { type, id, fontSize, hexColor, position, text } = photoAnnotation
        position = position.map(numberTo6Decimals)
        fontSize = numberTo6Decimals(fontSize)
        return { type, id, fontSize, hexColor, position, text }
    }

    return photoAnnotation.match({
        Arrow: lineOrArrowToFirebase,
        Line: lineOrArrowToFirebase,
        Scribble: scribbleToFirebase,
        Text: textToFirebase,
    })
}

/*
 * Convert a plain object that conforms to PhotoAnnotation into a real PhotoAnnotation object
 * For Arrows, the left and right heads will be recomputed
 * For Scribbles, there must already be drawingCommands (so data from Firebase should use 'fromFirebase', not 'from')
 * @sig from :: {k:v} -> PhotoAnnotation
 */
PhotoAnnotation.from = o => {
    if (o.type === 'arrow') return PhotoAnnotation.Arrow.from(updateScribbleHeads(o))
    if (o.type === 'line') return PhotoAnnotation.Line.from(o)
    if (o.type === 'scribble') return PhotoAnnotation.Scribble.from(o)
    if (o.type === 'text') return PhotoAnnotation.Text.from(o)

    throw new Error(`Don't understand PhotoAnnotation type: ${o.type}`)
}

/*
 * Create a PhotoAnnotation of the given type with a single point, presumably because we're creating a new annotation
 * The size MUST be multiplied by the annotationsScale. That is, if the annotationsScale is 5 and the nominal
 * lineWidth is 9, the size MUST be 45 (because that's what we store in Firestore, and therefore what we use here too)
 * @sig fromInitialPoint = (String, Point, String, Number) -> PhotoAnnotation
 */
PhotoAnnotation.fromInitialPoint = (type, point, hexColor, size) => {
    let o
    if (type === 'arrow') o = { startPoint: point, endPoint: point, lineWidth: size }
    if (type === 'line') o = { startPoint: point, endPoint: point, lineWidth: size }
    if (type === 'scribble') o = { drawingCommands: [DrawingCommand('M', point)], lineWidth: size }
    if (type === 'text') o = { text: '', fontSize: size, position: point }

    return PhotoAnnotation.from(mergeRight({ id: v4(), type, hexColor }, o))
}

/*
 * Compute a bounding rectangle for an annotation. textWidth and textHeight are required only for PhotoAnnotation.Text
 * @sig boundingBox :: (PhotoAnnotation, Number, Number) -> BoundingBox
 *  BoundingBox = [Number, Number, Number, Number] // [X, Y, Width, Height]
 *  X = Y = Width = Height = Number
 */
PhotoAnnotation.boundingBox = (annotation, textWidth, textHeight) => {
    const arrow = () => {
        const { startPoint, endPoint, rightHead, leftHead, lineWidth } = annotation
        return expandBoundingBox(boundingBox([startPoint, endPoint, rightHead, leftHead]), lineWidth, lineWidth)
    }

    const line = () => {
        const { startPoint, endPoint, lineWidth } = annotation
        return expandBoundingBox(boundingBox([startPoint, endPoint]), lineWidth, lineWidth)
    }

    const scribble = () => {
        const { drawingCommands, lineWidth } = annotation
        const drawingCommandPoints = drawingCommands.map(c => c.points.slice(-2))
        return expandBoundingBox(boundingBox(drawingCommandPoints), lineWidth, lineWidth)
    }

    const text = () => {
        const { fontSize, position } = annotation

        // Set rectangle dimensions
        // hack the "+ 2" expressions added to width and height are approximations because we're using
        // different fonts on iOS and web, and the sizes are slightly different.
        const verticalPadding = fontSize / 30.0
        const horizontalPadding = fontSize / 5.0
        const width = textWidth + horizontalPadding * 2 + 2
        const height = textHeight + verticalPadding * 2 + 2

        const [x, y] = position
        return [x, y, width, height]
    }

    return annotation.match({
        Arrow: arrow,
        Line: line,
        Scribble: scribble,
        Text: text,
    })
}

/*
 * Create a new PhotoAnnotation from an old one and an object containing changes to make
 * For Arrows, the left and right heads will be recomputed as well
 * @sig update :: (PhotoAnnotation, {k:v}) -> PhotoAnnotation
 */
PhotoAnnotation.update = (annotation, changes) =>
    annotation.match({
        Arrow: () => PhotoAnnotation.Arrow.from(updateScribbleHeads(mergeRight(annotation, changes))),
        Line: () => PhotoAnnotation.Line.from(mergeRight(annotation, changes)),
        Scribble: () => PhotoAnnotation.Scribble.from(mergeRight(annotation, changes)),
        Text: () => PhotoAnnotation.Text.from(mergeRight(annotation, changes)),
    })

/*
 * Create a new PhotoAnnotation from an old one, moving it by dx, dy
 * @sig move :: (PhotoAnnotation, Number, Number) -> PhotoAnnotation
 */
PhotoAnnotation.move = (annotation, dx, dy, annotationHandleToDrag = PhotoAnnotation.DRAG_HANDLES.ALL) => {
    const arrowOrLine = () => {
        let { startPoint, endPoint } = annotation

        const shouldDragStart = annotationHandleToDrag !== PhotoAnnotation.DRAG_HANDLES.END // start or all
        const shouldDragEnd = annotationHandleToDrag !== PhotoAnnotation.DRAG_HANDLES.START // end or all

        startPoint = shouldDragStart ? offsetPoint(startPoint, dx, dy) : startPoint
        endPoint = shouldDragEnd ? offsetPoint(endPoint, dx, dy) : endPoint

        return PhotoAnnotation.update(annotation, { startPoint, endPoint })
    }

    const scribble = () => {
        const drawingCommands = annotation.drawingCommands.map(dc => DrawingCommand.move(dc, dx, dy))
        return PhotoAnnotation.update(annotation, { drawingCommands })
    }

    return annotation.match({
        Arrow: arrowOrLine,
        Line: arrowOrLine,
        Scribble: scribble,
        Text: () => PhotoAnnotation.update(annotation, { position: offsetPoint(annotation.position, dx, dy) }),
    })
}

/*
 * Create a new PhotoAnnotation from an old one, extending its end point by dx, dy.
 * For Lines and Arrows, this means overwriting the endPoint
 * For Scribbles, it means adding another point
 * For Text, it means nothing
 * @sig move :: (PhotoAnnotation, Number, Number) -> PhotoAnnotation
 */
PhotoAnnotation.extend = (annotation, dx, dy) => {
    const arrowOrLine = () => {
        let { endPoint } = annotation
        endPoint = offsetPoint(endPoint, dx, dy)

        return PhotoAnnotation.update(annotation, { endPoint })
    }

    const scribble = () => {
        let { drawingCommands } = annotation
        const { points } = drawingCommands.at(-1)
        const endPoint = offsetPoint(points, dx, dy)
        drawingCommands = drawingCommands.concat(DrawingCommand('L', endPoint))
        return PhotoAnnotation.update(annotation, { drawingCommands })
    }

    return annotation.match({
        Arrow: arrowOrLine,
        Line: arrowOrLine,
        Scribble: scribble,
        Text: () => {},
    })
}

/*
 * The editor's "size" selector will work for text or lines, but text has a fontSize and the others have lineWidth
 * @sig getSize :: Annotation -> Number
 */
PhotoAnnotation.getSize = annotation =>
    annotation.match({
        Arrow: () => annotation.lineWidth,
        Line: () => annotation.lineWidth,
        Scribble: () => annotation.lineWidth,
        Text: () => annotation.fontSize,
    })

/*
 * Set the size of a (new) PhotoAnnotation (that is, fontSize or lineWidth, depending)
 * @sig setSize :: (Annotation, Number, Number) -> PhotoAnnotation
 */
PhotoAnnotation.setSize = (annotation, size) =>
    annotation.match({
        Text: () => PhotoAnnotation.update(annotation, { fontSize: size }),
        Scribble: () => PhotoAnnotation.update(annotation, { lineWidth: size }),
        Line: () => PhotoAnnotation.update(annotation, { lineWidth: size }),
        Arrow: () => PhotoAnnotation.update(annotation, { lineWidth: size }),
    })

PhotoAnnotation.getAvailableSizes = annotation => PhotoAnnotation.sizesForTool(annotation.type)

PhotoAnnotation.sizesForTool = type => {
    if (type.toLowerCase() === 'select') return []
    if (type.toLowerCase() === 'arrow') return PhotoAnnotation.ANNOTATION_LINE_WIDTHS
    if (type.toLowerCase() === 'line') return PhotoAnnotation.ANNOTATION_LINE_WIDTHS
    if (type.toLowerCase() === 'scribble') return PhotoAnnotation.ANNOTATION_LINE_WIDTHS
    if (type.toLowerCase() === 'text') return PhotoAnnotation.ANNOTATION_FONT_SIZES

    throw new Error(`Don't understand type ${type}`)
}

PhotoAnnotation.defaultSizeForTool = type => {
    if (type.toLowerCase() === 'select') return 0 // hmm...
    if (type.toLowerCase() === 'arrow') return PhotoAnnotation.DEFAULT_LINE_WIDTH
    if (type.toLowerCase() === 'line') return PhotoAnnotation.DEFAULT_LINE_WIDTH
    if (type.toLowerCase() === 'scribble') return PhotoAnnotation.DEFAULT_LINE_WIDTH
    if (type.toLowerCase() === 'text') return PhotoAnnotation.DEFAULT_FONT_SIZE

    throw new Error(`Don't understand type ${type}`)
}

/*
 * Set the hexColor of a (new) PhotoAnnotation
 * @sig setColor :: (Annotation, String) -> PhotoAnnotation
 */
PhotoAnnotation.setColor = (annotation, hexColor) => PhotoAnnotation.update(annotation, { hexColor })

PhotoAnnotation.setText = (annotation, text) => PhotoAnnotation.update(annotation, { text })

/*
 * Sometimes we want to modify the PhotoAnnotation when the user has finished creating it, such as simplifying scribbles
 * @sig finalizeAnnotation :: (PhotoAnnotation) -> PhotoAnnotation
 */
PhotoAnnotation.finalizeAnnotation = annotation => {
    // Changing epsilon will dramatically change the quality of the resulting line,
    // since it represents how close is so close that a given point is useless
    const epsilon = 10

    const scribble = () => {
        const points = annotation.drawingCommands.map(DrawingCommand.point)
        const simplified = simplifyPoints(points, epsilon)
        const beziers = convertPointsToBeziers(simplified)
        const drawingCommands = DrawingCommand.drawingCommandsFromBeziers(points[0], beziers)
        return PhotoAnnotation.update(annotation, { drawingCommands })
    }

    /*
     * Sometimes we DON'T want to finalize the PhotoAnnotation, because the endPoint is so close to the
     * startPoint we would end up with just a single point or just an arrow head with no line at all.
     * Returning undefined indicates that the Annotation should NOT to be created after all
     *
     * @sig lineOrArrow :: () -> Annotation|undefined
     */
    const lineOrArrow = () => {
        const { startPoint, endPoint } = annotation
        return arePointsNearEachOther(startPoint, endPoint) ? undefined : annotation
    }

    return annotation.match({
        Arrow: lineOrArrow,
        Line: lineOrArrow,
        Text: () => annotation,
        Scribble: scribble,
    })
}

/*
 * Colors used by (Photo) Annotations
 * These colors MUST use the #000000 format to match the syntax requirements of PhotoAnnotation's hexColor field
 */
PhotoAnnotation.ANNOTATION_COLORS = [
    '#FFFFFF', // White
    '#000000', // Black
    '#5D81FF', // Primary04
    '#F531B3', // Pink03
    '#FF7A00', // Orange03
    '#F13D15', // Red03
    '#009E5C', // Green03
]
PhotoAnnotation.DEFAULT_COLOR = '#F531B3' // Pink03

PhotoAnnotation.ANNOTATION_LINE_WIDTHS = [0.5, 1, 1.5, 2, 2.5, 3, 4.5, 6, 9, 12]
PhotoAnnotation.DEFAULT_LINE_WIDTH = 3

PhotoAnnotation.ANNOTATION_FONT_SIZES = [4, 5.5, 7, 8.5, 10, 12, 16, 20, 26, 32]
PhotoAnnotation.DEFAULT_FONT_SIZE = 12

PhotoAnnotation.DRAG_HANDLES = {
    START: 'start',
    END: 'end',
    ALL: 'all',
}

export { DrawingCommand }
export default PhotoAnnotation
