import { PdfPage, Upload } from '@range.io/basic-types'
import React, { useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useOnClickOutside } from '../components-reusable/hooks/index.js'
import {
    Button,
    FlexRow,
    Icon,
    IconButtonWithTooltip,
    LoadingDotsAnimation,
    Text,
    TextInput,
    Tooltip,
} from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledItemContainer = styled('div', {
    borderRadius: '6px',
    border: '1px solid $neutral07',
    marginBottom: '12px',
    overflow: 'hidden',
    boxShadow: '0px 0px 0px #000000',

    '&:hover': {
        border: '1px solid $primary04',
    },

    variants: {
        isDragging: {
            true: {
                border: '1px solid $primary04',
                boxShadow: '0px 20px 20px #00000020',
            },
        },
        isExpanded: {
            true: {
                borderBottom: '1px solid $neutral07',
            },
            false: {
                borderBottom: 'none',
            },
        },
    },
})

const StyledItemHeader = styled(FlexRow, {
    borderRadius: '6px 6px 0px 0px',
    backgroundColor: '$neutral10',
    color: '$neutral05',
    padding: '16px',
    gap: '16px',
    alignItems: 'center',

    variants: {
        isExpanded: {
            true: {
                borderBottom: '1px solid $neutral07',
            },
            false: {
                borderBottom: 'none',
            },
        },
    },
})

const StyledItemContent = styled(FlexRow, {
    backgroundColor: '$neutral09',
    height: '0px',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    variants: {
        isLoading: {
            false: {
                backgroundImage: 'url(../../grid_background2.svg)',
            },
        },

        isExpanded: {
            true: {
                minHeight: '550px',
                height: 'auto',
            },
        },
    },
})

// resize the canvas to the viewport size, taking into account the devicePixelRatio
const resizeCanvasToViewport = (canvas, viewport) => {
    const { width, height } = viewport
    const outputScale = window.devicePixelRatio || 1

    canvas.style.width = Math.floor(width) + 'px'
    canvas.style.height = Math.floor(height) + 'px'
    canvas.width = Math.floor(width * outputScale)
    canvas.height = Math.floor(height * outputScale)

    return outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null
}

// calculate new viewport scaled down to container's height preserving aspect ratio
const adjustViewportForContainer = ({ containerHeight, viewport }) => {
    const scale = containerHeight / viewport.height
    // scale down the transform matrix (because scale in viewport won't do it for you)
    const newTransform = viewport.transform.map(x => x * scale)
    return {
        ...viewport,
        scale,
        height: viewport.height * scale,
        width: viewport.width * scale,
        transform: newTransform,
    }
}

const PDFCanvasSourceEditItem = ({
    index,
    item,
    provided,
    totalCount,
    onDelete,
    onIndexChange,
    onNameChange,
    onFileUpdate,
    onDuplicate, // Add this prop
    lastUpdatedLabelText,
    updateLabelText,
    dragSnapshot,
}) => {
    const [pageNumberInputValue, sePageNumberInputValue] = useState(index.toString())
    const [isPageNumberInputFocused, setIsPageNumberInputFocused] = useState(false)
    const pageNumberInputRef = useRef()

    const canvasImageRef = useRef()
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(true)

    // only the first page should be expanded by default
    // or the items that were newly added
    const [isExpanded, setIsExpanded] = useState(index === 1 || item.justCreated)

    useEffect(() => {
        setIsInitialLoad(false)
    }, [])

    const handleFileDrop = acceptedFiles => {
        setIsLoading(true)
        onFileUpdate(acceptedFiles, item)
    }

    const { getRootProps } = useDropzone({
        accept: {
            [Upload.mimeTypes.pdf]: [],
        },
        onDrop: handleFileDrop,
        multiple: false,
    })

    const resetPageNumber = () => {
        sePageNumberInputValue(index.toString()) // reset to previous value
    }

    useEffect(() => {
        resetPageNumber()
    }, [index])

    useEffect(() => {
        // render the page into the canvas ref we created earlier
        const renderPage = async () => {
            try {
                // if the canvas doesn't have a source with valid PDF data then skip
                if (!item?.canvasSource?.pdfUrl && !item?.canvasSource?.temporaryPdf) return

                const htmlCanvas = canvasImageRef.current
                // skip if there's no canvas to render to
                if (!htmlCanvas) return

                setIsLoading(true)
                const pdfPage = item?.canvasSource?.temporaryPdf
                    ? await PdfPage.fromTemporaryPdfPage(item.canvasSource.temporaryPdf)
                    : await PdfPage.fromUrl(item.canvasSource.pdfUrl)

                const initialScale = 1
                // get current, unscaled viewport of the PDF
                const viewport = PdfPage.getViewport(pdfPage, initialScale)
                // scale the viewport down to the desired container size (520px height with preserved aspect ratio)
                const containerViewport = adjustViewportForContainer({ containerHeight: 520, viewport })
                // calculate the transform of the canvas for the scaled container
                const transform = resizeCanvasToViewport(htmlCanvas, containerViewport)
                return PdfPage.render(pdfPage, htmlCanvas.getContext('2d'), containerViewport, { transform }, () =>
                    setIsLoading(false)
                )
            } catch (e) {
                console.error(e)
                throw e
            }
        }
        !isInitialLoad && renderPage()
    }, [item.canvasSource.pdfUrl, item.canvasSource.temporaryPdf, isInitialLoad])

    const handleInputConfirmation = () => {
        if (pageNumberInputValue.length) {
            onIndexChange(item, pageNumberInputValue)
        } else {
            resetPageNumber()
        }
        pageNumberInputRef.current.blur()
    }

    const handleInputChange = value => {
        sePageNumberInputValue(value)
    }

    const handleKeyDown = ({ key }) => {
        if (key === 'Escape') {
            pageNumberInputRef.current.blur()
            resetPageNumber()
        } else if (key === 'Enter') {
            handleInputConfirmation()
        }
    }

    useOnClickOutside(pageNumberInputRef, () => {
        if (isPageNumberInputFocused) {
            // handle specific case, when input is blurred by outside click
            handleInputConfirmation()
            setIsPageNumberInputFocused(false)
        }
    })

    return (
        <StyledItemContainer
            ref={provided.innerRef}
            isDragging={dragSnapshot.isDragging}
            data-is-dragging={dragSnapshot.isDragging}
            data-list-id={index}
            className="pdf-sheet"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <StyledItemHeader data-cy="pdf-sheet-header" isExpanded={isExpanded}>
                <Icon
                    data-cy="pdf-sheet-drag-icon"
                    name="drag"
                    iconSize="18px"
                    css={{ color: '$neutral06', minWidth: 18 }}
                />
                <IconButtonWithTooltip
                    css={{ minWidth: 40 }}
                    variant="sheetExpand"
                    onClick={() => setIsExpanded(!isExpanded)}
                    iconName={isExpanded ? 'chevronUp' : 'chevronDownNew'}
                    tooltipText={isExpanded ? 'Hide PDF Sheet' : 'Show PDF Sheet'}
                    side="bottom"
                    size="16px"
                ></IconButtonWithTooltip>

                <FlexRow css={{ alignItems: 'center', flex: '0 0 auto' }}>
                    <TextInput
                        data-cy="pdf-sheet-number-input"
                        ref={pageNumberInputRef}
                        css={{ maxWidth: 40, marginRight: 6 }}
                        value={pageNumberInputValue}
                        onChange={handleInputChange}
                        onFocus={() => {
                            setIsPageNumberInputFocused(true)
                            handleInputChange('')
                        }}
                        onKeyDown={handleKeyDown}
                        pattern="[0-9]*"
                        checkInputValidity
                        autoComplete="off"
                        aria-autocomplete="off"
                        noClear
                        placeholder=""
                        style={{ padding: '0 6px 0 6px', textAlign: 'center' }}
                    />
                    {` / ${totalCount}`}
                </FlexRow>
                <TextInput
                    data-cy="pdf-sheet-name-input"
                    css={{ flexBasis: '40%', maxWidth: 600, minWidth: 200, mr: 'auto' }}
                    value={item.name}
                    onChange={newValue => onNameChange(item, newValue)}
                    noClear
                    placeholder=""
                />
                <Text
                    css={{ fontSize: '12px', lineHeight: '18px', fontWeight: 500, minWidth: 125, textAlign: 'right' }}
                >
                    {lastUpdatedLabelText}
                </Text>
                <Button css={{ minWidth: 155 }} variant="secondary" size="lg" {...getRootProps()}>
                    <Icon css={{ minWidth: 16 }} iconSize="16" name="reset" />
                    <Text css={{ fontWeight: 400 }}>{updateLabelText}</Text>
                </Button>
                <Tooltip tooltipText="Duplicate Sheet" align="center" side="bottom">
                    <Button
                        css={{ mindWidth: 40, width: 40, paddingLeft: 0, paddingRight: 0 }}
                        data-cy="pdf-sheet-duplicate-button"
                        variant="secondary"
                        size="lg"
                        onClick={() => onDuplicate(item)}
                    >
                        <Icon iconSize="" name="duplicate" />
                    </Button>
                </Tooltip>
                <Tooltip tooltipText="Delete Sheet" align="center" side="bottom">
                    <Button
                        data-cy="pdf-sheet-delete-button"
                        variant="destructiveSecondary"
                        size="lg"
                        onClick={() => onDelete(item)}
                    >
                        <Icon iconSize="16" name="trash" />
                    </Button>
                </Tooltip>
            </StyledItemHeader>
            <StyledItemContent isLoading={isLoading} isExpanded={isExpanded}>
                {isLoading && <LoadingDotsAnimation variant="secondary" />}
                <canvas
                    id={`canvasSource-${item.id}`}
                    ref={canvasImageRef}
                    style={{ display: isLoading ? 'none' : 'block' }}
                />
            </StyledItemContent>
        </StyledItemContainer>
    )
}

export default PDFCanvasSourceEditItem
