/*
 * Dropdown selector for the status of a Collaboration.
 *
 * Based on radix-ui Select https://www.radix-ui.com/docs/primitives/components/select
 *
 *   <Select.Root>
 *       <Select.Trigger>                       <-- the button that causes the dropdown menu to appear
 *           <Select.Value />
 *           <Select.Icon />
 *       </Select.Trigger>
 *
 *       <Select.Content>                       <-- wrapper around all the items in the dropdown menu
 *           <Select.ScrollUpButton />          <-- optional up button if there are a lot of items in the menu
 *           <Select.Viewport>                  <-- wrapper around just the visible items in the menu
 *               <Select.Item>                  <-- a single item in the dropdown list
 *                   <Select.ItemText />        <-- a text item
 *                   <Select.ItemIndicator />   <-- a checkbox next to the selected item
 *               </Select.Item>
 *               <Select.Separator />
 *           </Select.Viewport>
 *           <Select.ScrollDownButton />
 *       </Select.Content>
 *   </Select.Root>
 */
import * as RadixSelect from '@radix-ui/react-select'
import { hexToHsl, StatusName } from '@range.io/basic-types'
import { mapValues } from '@range.io/functional'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Icon, Tooltip } from '../components-reusable/index.js'
import { RangeUITheme, styled } from '../range-theme/index.js'

// convert the background color to HSL so we can dim it if selected
const hoverColor = bg => {
    const { h, s, l } = hexToHsl(bg)
    return `hsla(${h}, ${s}%, ${l}%, 0.8)`
}

const normalColor = bg => {
    const { h, s, l } = hexToHsl(bg)
    return `hsla(${h}, ${s}%, ${l}%, 1.0)`
}

const StyledSelectIcon = styled(RadixSelect.SelectIcon, {
    alignSelf: 'center',
    paddingTop: '4px',
    marginLeft: '4px',
})

const StyledTrigger = styled(RadixSelect.Trigger, {
    display: 'inline-flex',
    alignItems: 'center',
    border: 'none',
    borderRadius: 80,
    color: 'white',
    fontFamily: '$default',
    fontSize: '14px',
    fontWeight: 500,
    height: 32,
    outline: 'none',
    padding: '0 10px',
    cursor: 'pointer',
    zIndex: 999,
})

const StyledViewport = styled(RadixSelect.Viewport, {
    color: '$neutral04',
    backgroundColor: '$neutral09',
    outline: 'none',
    padding: '0 8px',
    borderRadius: '6px',
    border: '1px solid $neutral07',
    boxShadow: `${RangeUITheme.shadows.lg}`,
})

const StyledItem = styled(RadixSelect.Item, {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: '$default',
    fontWeight: 500,
    fontSize: 14,
    padding: '7px 10px',
    outline: 'none',
    borderRadius: 999,
    gap: 4,
    color: 'white',
    transitionDuration: '0.4s',
    cursor: 'pointer',
})

const StyledRoot = styled(RadixSelect.Root, {})

const StyledItemIndicator = styled(RadixSelect.ItemIndicator, {
    height: '13px',
})

const StyledGroup = styled(RadixSelect.SelectGroup, {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '8px',
    padding: '8px 0',
})

const StatusSelectorTrigger = ({ disabled, selectedStatus }) => {
    const css = { backgroundColor: selectedStatus?.color, cursor: disabled ? 'default' : 'pointer' }

    if (disabled)
        return (
            <StyledTrigger data-cy="current-status" css={css} disabled={disabled}>
                <RadixSelect.Value />
            </StyledTrigger>
        )

    return (
        <Tooltip side="bottom" tooltipText="Update Status" asChild>
            <StyledTrigger data-cy="current-status" css={css}>
                <RadixSelect.Value />
                <StyledSelectIcon>
                    <Icon name="chevronDown" iconSize="12px" />
                </StyledSelectIcon>
            </StyledTrigger>
        </Tooltip>
    )
}

/*
 * Dropdown menu to select the view shown in the background map
 */
const StatusSelector = ({
    onStatusChanged,
    allStatusNames,
    initialStatusName,
    defaultOpen = false,
    displayAsList = false,
    ...otherProps
}) => {
    // convert the dropdown text back to a StatusName id since only the id is stored in a Collaboration
    const _onStatusChanged = name => {
        const statusName = StatusName.withName(allStatusNames, name)
        setSelectedStatusName(statusName)
        onStatusChanged(statusName.id)
    }

    // a single drop-down menu item of the status selector -- with a small checkmark if it's the selected one
    const dropdownItem = selectedStatus => statusName => {
        const css = {
            backgroundColor: normalColor(statusName.color),
            '&:hover': { backgroundColor: hoverColor(statusName.color) },
        }

        const isSelected = selectedStatus?.id === statusName.id

        // if the status is disabled, meaning user cannot click it
        // and is not currently selected option, just skip showing it
        if (!isSelected && statusName.disabled) return null

        return (
            <StyledItem key={statusName.id} css={css} value={statusName.name} bg={statusName.color}>
                <RadixSelect.SelectItemText>{statusName.name}</RadixSelect.SelectItemText>
                <StyledItemIndicator>
                    <Icon name="tickSmall" iconSize="12px" />
                </StyledItemIndicator>
            </StyledItem>
        )
    }

    // all possible status choices as a dropdown menu
    const dropdownItems = selectedStatusName => (
        <RadixSelect.Content>
            <StyledViewport data-cy="select-viewport">
                <StyledGroup>{mapValues(dropdownItem(selectedStatusName), allStatusNames)}</StyledGroup>
            </StyledViewport>
        </RadixSelect.Content>
    )

    // if the initial status name changes, update the selected status name
    useEffect(() => setSelectedStatusName(initialStatusName), [initialStatusName])

    // main ------------------------------------------------------------------------------------------------------------
    const [selectedStatusName, setSelectedStatusName] = useState(initialStatusName)
    const shouldDisplayAsDisabled = selectedStatusName?.disabled // it means user cannot change current status

    return (
        <StyledRoot
            value={selectedStatusName?.name}
            onValueChange={_onStatusChanged}
            defaultOpen={defaultOpen}
            {...otherProps}
        >
            {!displayAsList && (
                <StatusSelectorTrigger disabled={shouldDisplayAsDisabled} selectedStatus={selectedStatusName} />
            )}
            {dropdownItems(selectedStatusName)}
        </StyledRoot>
    )
}

StatusSelector.propTypes = {
    onStatusChanged: PropTypes.func.isRequired,
    allStatusNames: PropTypes.object.isRequired, // lookupTable
    initialStatusName: PropTypes.object,
    defaultOpen: PropTypes.bool,
    displayAsList: PropTypes.bool,
}

export default StatusSelector
