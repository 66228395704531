import { FeedItem, TagName, Upload } from '@range.io/basic-types'
import { mergeRight, pick, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import { mark } from '../console.js'
import { itemFromFeedItem } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'
import { createUpload } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// UploadAddedCommand
// ---------------------------------------------------------------------------------------------------------------------
const UploadAddedCommand = taggedSum('UploadAddedCommand', {
    Inbound: { feedItem: 'FeedItem' },
    Outbound: { upload: 'Upload' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------
const getItem = c => c.upload

/*
 * A FeedItem has arrived from Firestore; if it's an Upload/added message, send it to redux
 * Since we're listening to project/participants/items, we need to GET the Upload -- and this FeedItem may not be meant for us
 */
const runInboundCommand = async (resources, command) => {
    const upload = await itemFromFeedItem(Upload, 'Upload', 'added', command.feedItem)
    if (!upload) return

    const { dispatch } = resources
    const measureUpload = mark('LoadUpload ', upload.path)
    dispatch(ReduxActions.uploadAdded(upload))
    measureUpload()
}

/*
 * A UploadAddedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const tagNameAndId = t => pick(['id', 'name'], ReduxSelectors.itemWithId(state, TagName, t))

    const upload = getItem(command)
    const { projectId, getState, displayError, Segment } = resources
    const state = getState()

    try {
        const uploadData = Upload.toNewUpload(upload)
        const tags = upload.tagIds.map(tagNameAndId) // createUpload wants: { id: Id, name: String }
        await createUpload({ projectId, ...uploadData, ...{ tags } })

        const params = ReduxSelectors.paramsForTrackEvent(state)
        Segment.sendTrack('media uploaded', uploadData.uploadId, mergeRight(params, { mediaType: upload.fileType }))
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        UploadAddedCommand,
        CommandPlayer({
            CommandType: UploadAddedCommand,
            Type: FeedItem,
            collectionPath: (projectId, userId) => `/projects/${projectId}/participants/${userId}/feedItems`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'added',
        })
    )
}

export { UploadAddedCommand, addCommand }
