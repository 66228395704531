/* eslint-disable no-unused-vars */
import { mergeDeepRight } from '@range.io/functional'
import RangeColors from '../range-theme/range-colors.js'

// -----------------------------------------------------------------------------------------------------------------
// Predicates
// -----------------------------------------------------------------------------------------------------------------
const isPolygon = ['==', '$type', 'Polygon']
const isLineString = ['==', '$type', 'LineString']
const isPoint = ['==', '$type', 'Point']
const isVertex = ['==', 'meta', 'vertex']
const isNotVertex = ['!=', 'meta', 'vertex']
const isMidpoint = ['==', 'meta', 'midpoint']
const isNotMidpoint = ['!=', 'meta', 'midpoint']
const isActive = ['==', 'active', 'true']
const isNotActive = ['==', 'active', 'false']
const hasText = ['has', 'user_text']
const hasIcon = ['has', 'user_icon']
const hasNoIcon = ['!has', 'user_icon']

const isPinMarker = ['any', ['==', 'user_pinType', 'TaskPin'], ['==', 'user_pinType', 'PhotoPin']]

const isRangeText = ['all', isPoint, hasText, hasNoIcon, isNotMidpoint, isNotVertex]
const isRangeMarker = ['all', isPoint, hasIcon, isNotMidpoint, isNotVertex]

// -----------------------------------------------------------------------------------------------------------------
// Styles
// -----------------------------------------------------------------------------------------------------------------
const activeColor = RangeColors.Primary04
const inactiveColor = RangeColors.Red03
const midpointColor = RangeColors.Secondary04
const haloColor = '#FFF'

const defaultLineLayout = { 'line-cap': 'round', 'line-join': 'round' }
const fillOpacity = 0.4
const thinLineWidth = 2
const thickLineWidth = 2
const dashArray = [0.2, thinLineWidth]

// circle
const midpointRadius = 4
const haloRadius = 5

// -----------------------------------------------------------------------------------------------------------------
// Line
// -----------------------------------------------------------------------------------------------------------------

// active line stroke
const activeLineStroke = {
    id: 'gl-draw-active-line-stroke',
    type: 'line',
    filter: ['all', isLineString, isActive],
    layout: defaultLineLayout,
    paint: {
        'line-color': activeColor,
        'line-dasharray': dashArray,
        'line-width': thinLineWidth,
    },
}

// inactive line stroke
const inactiveLineStroke = {
    id: 'gl-draw-inactive-line-stroke',
    type: 'line',
    filter: ['all', isLineString, isNotActive],
    layout: defaultLineLayout,
    paint: {
        'line-color': ['case', ['boolean', ['get', 'user_hover'], false], activeColor, inactiveColor],
        'line-width': thickLineWidth,
    },
}

// active polygon stroke
// This doesn't style the first edge of the polygon, which uses the line stroke styling instead
const activePolygonStroke = {
    id: 'gl-draw-active-polygon-stroke',
    type: 'line',
    filter: ['all', isPolygon, isActive],
    layout: defaultLineLayout,
    paint: {
        'line-color': activeColor,
        'line-dasharray': dashArray,
        'line-width': thinLineWidth,
    },
}

// inactive polygon stroke
const inactivePolygonStroke = {
    id: 'gl-draw-inactive-polygon-stroke',
    type: 'line',
    filter: ['all', isPolygon, isNotActive],
    layout: defaultLineLayout,
    paint: {
        'line-color': ['case', ['boolean', ['get', 'user_hover'], false], activeColor, inactiveColor],
        'line-width': thickLineWidth,
    },
}

// -----------------------------------------------------------------------------------------------------------------
// Fill
// -----------------------------------------------------------------------------------------------------------------

const activeFill = {
    id: 'gl-draw--fill',
    type: 'fill',
    filter: ['all', isPolygon, isActive],
    paint: {
        'fill-color': activeColor,
        'fill-outline-color': activeColor,
        'fill-opacity': fillOpacity,
    },
}

const inactiveFill = {
    id: 'gl-draw-inactive-fill',
    type: 'fill',
    filter: ['all', isPolygon, isNotActive],
    paint: {
        'fill-color': inactiveColor,
        'fill-outline-color': inactiveColor,
        'fill-opacity': fillOpacity,
    },
}

// -----------------------------------------------------------------------------------------------------------------
// Symbol
// -----------------------------------------------------------------------------------------------------------------
// prettier-ignore
const formattedText        = [
    'format',
    ['get', 'user_text'],          { 'font-scale': ['coalesce', ['get', 'user_fontSize' ], 1.2    ]},
    ['get', 'user_conversations'], { 'text-color': ['coalesce', ['get', 'user_textColor'], 'blue'  ]},
    ['get', 'user_photos'],        { 'text-font':  ['coalesce', ['get', 'user_font'     ], ['literal', ['Arial Unicode MS Bold']]]},
]

const defaultTextLayout = {
    'symbol-sort-key': 100,
    'text-allow-overlap': true,
    'text-ignore-placement': true,
    'text-max-width': 10,
    'text-optional': true,

    'text-field': formattedText,
}

const defaultMarkerLayout = {
    'symbol-sort-key': 100,
    'icon-image': ['coalesce', ['get', 'user_icon'], 'red'],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,

    'text-allow-overlap': true,
    'text-ignore-placement': true,

    'text-justify': 'left',
    'text-anchor': 'left',
    'text-offset': [2, 0],
    'text-max-width': 10,
    'text-optional': true,

    'text-field': formattedText,
}

const activeText = {
    id: 'gl-draw-active-text',
    type: 'symbol',
    filter: ['all', isRangeText, isActive],
    layout: defaultTextLayout,
    paint: {
        'text-color': 'red',
    },
}

const inactiveText = {
    id: 'gl-draw-inactive-text',
    type: 'symbol',
    filter: ['all', isRangeText, isNotActive],
    layout: defaultTextLayout,
}

const activeMarker = {
    id: 'gl-draw-active-marker',
    type: 'symbol',
    filter: ['all', isRangeMarker, isActive],
    layout: defaultMarkerLayout,
    paint: {
        'text-halo-width': 1,
        'text-halo-color': 'rgba(255,255,255,0.9)',
    },
}

const inactiveMarker = mergeDeepRight(activeMarker, {
    id: 'gl-draw-inactive-marker',
    filter: ['all', isRangeMarker, isNotActive],
    layout: Object.assign({}, defaultMarkerLayout, {
        'icon-image': 'blue',
        'text-allow-overlap': false,
        'text-ignore-placement': false,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
    }),
})

// -----------------------------------------------------------------------------------------------------------------
// Circle
// -----------------------------------------------------------------------------------------------------------------

const activeVertex = {
    id: 'gl-draw-active-vertex',
    type: 'circle',
    filter: ['all', isPoint, isVertex],
    paint: {
        'circle-radius': midpointRadius,
        'circle-color': ['case', ['boolean', ['get', 'user_hover'], false], midpointColor, activeColor],
    },
}

const activeVertexHalo = {
    id: 'gl-draw-active-vertex-halo',
    type: 'circle',
    filter: ['all', isPoint, isVertex],
    paint: {
        'circle-radius': haloRadius,
        'circle-color': haloColor,
    },
}

const activeMidpoint = {
    id: 'gl-draw-active-midpoint',
    type: 'circle',
    filter: ['all', isPoint, isMidpoint],
    paint: {
        'circle-radius': midpointRadius,
        'circle-color': ['case', ['boolean', ['get', 'user_hover'], false], activeColor, midpointColor],
    },
}

// -----------------------------------------------------------------------------------------------------------------
// Arrow
// -----------------------------------------------------------------------------------------------------------------

const isArrow = ['==', 'user_annotationType', 'arrow']

const defaultArrowHeadLayout = {
    'icon-image': 'arrow',
    'icon-size': 0.4,
    'icon-rotate': {
        type: 'identity',
        property: 'user_rotation',
    },
    'icon-anchor': 'top',
    'icon-offset': [0, -10],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
}

const activeArrowHead = {
    type: 'symbol',
    id: 'gl-draw-active-arrow-head',
    filter: ['all', isPoint, isArrow, isActive],
    layout: defaultArrowHeadLayout,
    paint: {
        'icon-color': activeColor,
    },
}

const inactiveArrowHead = {
    type: 'symbol',
    id: 'gl-draw-inactive-arrow-head',
    filter: ['all', isPoint, isArrow, isNotActive],
    layout: defaultArrowHeadLayout,
    paint: {
        'icon-color': ['case', ['boolean', ['get', 'user_hover'], false], activeColor, inactiveColor],
    },
}

// -----------------------------------------------------------------------------------------------------------------
// Marker Pins
// -----------------------------------------------------------------------------------------------------------------

const pinMarkerLayoutProperties = selected => {
    return {
        'icon-image': selected ? ['get', 'user_selectedIconImage'] : ['get', 'user_iconImage'],
        'icon-anchor': 'bottom',
        'icon-allow-overlap': true,
        'icon-optional': false, // icons don't hide on collision
        'icon-offset': selected ? [0, 0] : [0, 9],
    }
}

const pinMarkerPaintProperties = selected => ({
    'text-color': '#FFFFFF',
    'icon-opacity': selected ? 1 : ['case', ['boolean', ['get', 'anythingSelected'], false], 0.3, 1],
})

const inactivePinMarker = {
    type: 'symbol',
    id: 'gl-draw-inactive-pin-marker',
    filter: ['all', isPoint, isPinMarker, isNotActive],
    layout: pinMarkerLayoutProperties(false),
    paint: pinMarkerPaintProperties(false),
}

// 'icon-offset': [0, 5],

const activePinMarker = {
    type: 'symbol',
    id: 'gl-draw-active-pin-marker',
    filter: ['all', isPoint, isPinMarker, isActive],
    layout: pinMarkerLayoutProperties(true),
    paint: pinMarkerPaintProperties(true),
}

// -----------------------------------------------------------------------------------------------------------------
// Main
// -----------------------------------------------------------------------------------------------------------------

// List of styles that get rendered on the map, in order from bottom to top
const styles = [
    // // other
    // activeArrowHead,
    // inactiveArrowHead,

    // // inactive
    // inactiveLineStroke,
    // inactiveFill,
    // inactivePolygonStroke,
    // inactiveMarker,
    // inactiveText,
    inactivePinMarker,

    // // active
    // activeLineStroke,
    // activeFill,
    // activePolygonStroke,
    // activeVertexHalo,
    // activeMidpoint,
    // activeVertex,
    // activeMarker,
    // activeText,
    activePinMarker,
]

export default styles
