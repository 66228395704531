/*
 * Basic component to display tag name, that also has an action button that can be assigned actions (like removing a tag or clearing an input that's inside the pill).
 */

import React from 'react'
import { Box, FlexRow, Icon, Tooltip } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'

const StyledTagPillButton = styled(Box, {
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    background: '$primary04',
    transitionDuration: '0.4s',
    mr: '-5px',

    '&:hover': {
        cursor: 'pointer',
        background: '$primary06',
    },
})

const StyledTagPill = styled(FlexRow, {
    padding: '5px 10px 5px 10px',
    gap: '8px',
    color: '$neutral04',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid $neutral07',
    borderRadius: '1000px',
    width: 'fit-content',
    userSelect: 'none',
    flexWrap: 'wrap',
    alignItems: 'center',
    background: '$primary02',
    fw: 500,

    variants: {
        outlined: {
            true: {
                border: '1px solid $primary04',
                maxWidth: '100%',
                flexWrap: 'nowrap',
            },
        },
    },
})

const TagPill = ({ children, outlined, onBtnClick, onClick, showButton = true }) => (
    <StyledTagPill outlined={outlined} onClick={onClick}>
        {children}
        {showButton && (
            <Tooltip tooltipText="Remove Tag" side="bottom" align="center">
                <StyledTagPillButton onClick={onBtnClick} css={{ flexShrink: '0' }}>
                    <Icon name="close" css={{ size: '8px', color: 'white' }} />
                </StyledTagPillButton>
            </Tooltip>
        )}
    </StyledTagPill>
)

export default TagPill
