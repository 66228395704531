import StringTypes from '@range.io/basic-types/src/string-types.js'
import { assoc, memoizeReduxState, tagged } from '@range.io/functional'
import PropTypes from 'prop-types'

const TagShape = tagged('TagShape', {
    id: StringTypes.Id,
    name: 'String',
    count: 'Number',
    canvasCounts: 'Object', // { Id: Number }
})

const TagShapePropTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    canvasCounts: PropTypes.objectOf(PropTypes.number).isRequired,
}

// @sig canvasIdForCollaboration :: (State, Collaboration) -> Id
const canvasIdForCollaboration = (state, collaboration) => {
    const feature = state.features[collaboration.feature]
    const geometry = state.geometries[feature.geometryId]
    return geometry.canvasId
}

// @sig canvasIdForUpload :: (State, Upload) -> Id
const canvasIdForUpload = (state, upload) => canvasIdForCollaboration(state, state.collaborations[upload.parentId])

/*
 * Return all TagNames enriched with their count of usage across the app
 * @sig :: computeCountData :: State -> { TagId: CountData }
 */
const _computeCountData = state => {
    // :: ({ Id: CountData }, TagName) -> { Id: CountData }
    const initializeCountData = (acc, tn) => {
        acc[tn.id] = { id: tn.id, name: tn.name, count: 0, canvasCounts: { ...initialCanvasCounts } }
        return acc
    }

    // Impure: updates both the overall count and the count for the specific canvasId
    const incrementTagCounts = (canvasId, tagId) => {
        countData[tagId].count++
        countData[tagId].canvasCounts[canvasId]++
    }

    // Increment the count for each tagId in a Collaboration
    const incrementTagCountsForCollaboration = collaboration =>
        collaboration.tags.forEach(tagId => incrementTagCounts(canvasIdForCollaboration(state, collaboration), tagId))

    // Increment the count for each tagId in an Upload
    const incrementTagCountsForUpload = upload =>
        upload.tagIds.forEach(tagId => incrementTagCounts(canvasIdForUpload(state, upload), tagId))

    const collaborations = Object.values(state.collaborations)
    const uploads = Object.values(state.uploads)
    const canvasKeys = Object.keys(state.canvases)
    const initialCanvasCounts = canvasKeys.reduce((acc, id) => assoc(id, 0, acc), {})
    const countData = Object.values(state.tagNames).reduce(initializeCountData, {})

    // update tag counts for each Upload and Collaboration
    collaborations.forEach(incrementTagCountsForCollaboration)
    uploads.forEach(incrementTagCountsForUpload)

    return countData
}

const computeCountData = memoizeReduxState(['canvases', 'collaborations', 'tagNames', 'uploads'], _computeCountData)

TagShape.fromTagName = (shapeLookupTable, state) => tagName => {
    const countData = computeCountData(state)[tagName.id]

    return TagShape.from({
        id: tagName.id,
        name: tagName.name,
        count: countData.count,
        canvasCounts: countData.canvasCounts,
    })
}

export { TagShape, TagShapePropTypes }
