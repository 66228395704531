/*
 * See Readme
 */

import { Participant } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import { removeSelfFromOrganizationParticipants } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// OrganizationParticipantRemovedSelfCommand
// ---------------------------------------------------------------------------------------------------------------------
const OrganizationParticipantRemovedSelfCommand = taggedSum('OrganizationParticipantRemovedSelfCommand', {
    Inbound: { participant: 'Participant' },
    Outbound: { organizationId: StringTypes.Id, participant: 'Participant' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A ParticipantRemovedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the participant)
 */
// const runInboundCommand = (resources, command) => {
//     const { dispatch } = resources
//     dispatch(ReduxActions.userRemovedFromOrganization(command.organizationId))
// }

/*
 * Change the Organization.participant's organizationRole
 */
const runOutboundCommand = async (resources, command) => {
    const { dispatch, displayError } = resources
    const { organizationId, participant } = command

    try {
        await removeSelfFromOrganizationParticipants(organizationId, participant.id)
        dispatch(ReduxActions.selfRemovedFromOrganization(organizationId))
    } catch (e) {
        displayError(e)
    }
}
const addOrganizationParticipantRemovedSelfCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        OrganizationParticipantRemovedSelfCommand,
        CommandPlayer({
            CommandType: OrganizationParticipantRemovedSelfCommand,
            Type: Participant,
            collectionPath: organizationId => `/organizations/${organizationId}/participants/`,
            // runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'removed',
            resourceKey: 'organizationId',
        })
    )
}

export { OrganizationParticipantRemovedSelfCommand, addOrganizationParticipantRemovedSelfCommandSingleton }
