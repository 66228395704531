/*
 * Wrapper for PdfJs Page
 *
 * Viewport:
 *
 *   scale: 4
 *   rotation: 0
 *   width: 1036
 *   height: 6912
 *   viewBox: [0, 0, 2592, 1728] // AFTER rotation has been applied
 *   offsetX: 0
 *   offsetY: 0
 *   transform: [4, 0, 0, -4, 0, 6912] // AFTER scale has been applied
 */

import { mergeRight, tagged } from '@range.io/functional'
import * as PdfJsLib from 'pdfjs-dist'

// keep tests & storybook happy by not referencing missing PdfJsLib
if (typeof PdfJsLib !== 'undefined' && PdfJsLib.GlobalWorkerOptions)
    PdfJsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'

/*
 * Define the PdfPage type
 */
const PdfPage = tagged('PdfPage', {
    wrapped: 'Object',
})

/*
 * Create a PdfPage from a URL
 * @sig fromUrl :: (String) -> Promise PdfPage
 */
PdfPage.fromUrl = async url => {
    const document = await PdfJsLib.getDocument(url).promise
    const PdfJsLibPage = await document.getPage(1)
    return PdfPage(PdfJsLibPage)
}

/*
 * Create a PdfPage from a temporary PDF object
 * @sig fromTemporaryPdf :: (PDF) -> Promise PdfPage
 */
PdfPage.fromTemporaryPdf = async pdf => {
    const document = pdf.wrapped
    const PdfJsLibPage = await document.getPage(1)
    return PdfPage(PdfJsLibPage)
}

PdfPage.fromTemporaryPdfPage = async pdfPage => {
    return PdfPage(pdfPage)
}

/*
 * Return the viewport of the pdfPage
 * @sig getViewport :: (PdfPage, Number) -> Viewport
 */
PdfPage.getViewport = (pdfPage, scale = 1) => {
    return pdfPage.wrapped.getViewport({ scale })
}

/*
 * otherOptions come from PdfJs: https://github.com/mozilla/pdf.js/blob/master/src/display/api.js (line 1405?)
 * @sig render :: (PdfPage, Canvas-2d-Context, Viewport, {k:v}) -> Promise null
 */
PdfPage.render = async (pdfPage, canvasContext, viewport, otherOptions = {}, onDone = null) => {
    if (canvasContext.canvas.isCurrentlyRendering) return
    canvasContext.canvas.isCurrentlyRendering = true

    const options = mergeRight(otherOptions, { canvasContext, viewport })
    const result = await pdfPage.wrapped.render(options).promise
    canvasContext.canvas.isCurrentlyRendering = false
    if (onDone) onDone()
    return result
}

export default PdfPage
