/*
 * Remote Config allows a program to react to changes that a Range employee modifies using the
 * Firebase Remote Config UI (https://console.firebase.google.com/project/development-18fcf/config)
 *
 * Remote Config basically tracks key/value pairs that you can use any way you want, including as feature flags.
 * It's actually much more powerful than simply setting a value for every instance of an application,
 * you can send 10% of your users a specific value to test it, for instance, and because the app is
 * listening for values sent by the server, only those users will see the test.
 *
 * Remote config has both a client and a server, and for there is a "default template" that
 * represents the value that an app should use (unless, for instance, it's among the 10% of apps
 * that should show "the test").
 *
 * The default template can be downloaded by a client, but it should also start from hard-coded defaults of its own,
 * since it's possible that the app is offline and unable to retrieve the values from the server.
 *
 * Since we're using multiple environments (development, beta, etc.) each with its own Remote Config values,
 * we need to use the values appropriate for the environment we're running in.
 *
 * It's also possible to sync client values with server values at any time. Remote Config separates fetching
 * values from "activating" them so that you can get the values at one time and only change the UI
 * at a "good" time later. However, there is also a fetchAndActivate function for the common case you
 * want to do both at once.
 *
 * Here, we're using Remote Config to sync feature flags and keeping the values locally in a cache called featureFlags.
 * The file returns a function getFeatureFlags, which simply returns the value of featureFlags
 *
 * When using the emulator, we fetchAndActivate often in order to check for changes.
 */

import { mapObject } from '@range.io/functional'
import { fetchAndActivate, getAll } from 'firebase/remote-config'
import { remoteConfig } from './firebase/configure-environment/config-local.js'
import envFromWindowLocation from './firebase/configure-environment/env-from-window-location.js'

const MS_PER_SECOND = 1000
const MS_PER_HOUR = MS_PER_SECOND * 60 * 60
const TEN_SECONDS = 10 * MS_PER_SECOND

const initializeDefaults = () => {
    const { env, useEmulator } = envFromWindowLocation()
    if (useEmulator)
        return {
            minimumFetchIntervalMillis: TEN_SECONDS,
            defaultConfig: {
                ran1564VimeoUrl: 'https://www.range.io/tutorials',
            },
        }

    if (env === 'development')
        return {
            minimumFetchIntervalMillis: MS_PER_HOUR,
            defaultConfig: {
                ran1564VimeoUrl: 'https://www.range.io/tutorials',
            },
        }

    return {
        minimumFetchIntervalMillis: MS_PER_HOUR,
        defaultConfig: {
            ran1564VimeoUrl: 'https://www.range.io/tutorials',
        },
    }
}

const { defaultConfig, minimumFetchIntervalMillis } = initializeDefaults()

remoteConfig.settings.minimumFetchIntervalMillis = minimumFetchIntervalMillis
remoteConfig.defaultConfig = defaultConfig

/*
 * RemoteConfig (actually getAll) will return a string value even for number or boolean parameters
 * This function convert such a string parameter to the right type, guessing if we don't actually know
 *
 * @sig convertValue :: (String, String) -> number|boolean|string
 */
const convertValue = (value, key) => {
    // we know about the key because we have a defaultConfig for it
    if (defaultConfig[key] === true) return value.asBoolean()
    if (defaultConfig[key] === false) return value.asBoolean()
    if (typeof defaultConfig[key] === 'number') return value.asNumber()
    if (typeof defaultConfig[key] === 'string') return value.asString()

    // we don't know about the key; guess its type
    let result
    if (value._value === 'true') result = true
    else if (value._value === 'false') result = false
    else if (value._value.match(/^[0-9.+-]+$/)) result = value.asNumber()
    else result = value._value

    // avoid ""a string"" in the console by removing embedded "'s
    const s = value._value[0] === '"' ? value._value.slice(1, -1) : value._value

    console.warn(`Found unexpected RemoteConfig property ${key}: "${s}"; interpreting it as ${result}`)
    return result
}

/*
 * Get all the remoteConfig parameters and merge them into featureFlags cache with the same structure as defaultConfig
 */
const fetchFeatureFlags = async () => {
    try {
        await fetchAndActivate(remoteConfig)
        const values = getAll(remoteConfig)
        featureFlags = mapObject(convertValue, values)
    } catch (e) {
        console.error('Error trying to download data from remoteConfig', e)
    }
}

// run fetchFeatureFlags asynchronously the first time to avoid blocking while we retrieve the values
let featureFlags = defaultConfig // initial values comes from hard-coded values
fetchFeatureFlags() // then download from the server right away...
setInterval(fetchFeatureFlags, minimumFetchIntervalMillis) // and schedule future periodic downloads

// return the current featureFlags
const getFeatureFlags = () => featureFlags

export default getFeatureFlags
