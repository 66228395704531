import React from 'react'
import { Button, Icon, Text } from '../components-reusable/index.js'

const FollowButton = ({ isFollowing, onClick, ...rest }) => {
    return (
        <Button variant="secondary" size="lg" onClick={onClick} {...rest}>
            <Icon iconSize="16" name="followers" />
            <Text>{isFollowing ? 'Unfollow' : 'Follow'}</Text>
        </Button>
    )
}

const FollowButtonSimple = ({ isFollowing, onClick, ...rest }) => {
    return (
        <Button variant="followers" onClick={onClick} {...rest} withLoadingState>
            <Text css={{ fontSize: '12px', color: '$neutral05' }}>{isFollowing ? 'Unfollow' : 'Follow'}</Text>
        </Button>
    )
}

export { FollowButton, FollowButtonSimple }
