/*
 * Admin Page: Organization settings panel
 */
import React, { useRef } from 'react'
import { Button, Icon } from '../components-reusable/index.js'

const ImageUpload = ({ addNewImage, maxFileSizeMb = 5, maxWidth = 240, maxHeight = 120, disabled = false }) => {
    const inputFile = useRef(null)

    const handleAddingImage = fileURI => {
        try {
            if (!fileURI) throw new Error('Error while uploading file!')
            addNewImage(fileURI)
        } catch (e) {
            console.error(e)
            alert(`Error while uploading file: ${fileURI}`)
        }
    }

    const compressUpload = (file, callback) => {
        try {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e) {
                if (file.type === 'image/svg+xml') {
                    return callback(e.target.result)
                }

                const img = new Image()
                img.src = e.target.result
                img.onload = function () {
                    const canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')

                    let width = img.width
                    let height = img.height

                    // Calculate the width and height, maintaining aspect ratio
                    if (width > height) {
                        if (width > maxWidth) {
                            height = Math.round(height * (maxWidth / width))
                            width = maxWidth
                        }
                    } else {
                        if (height > maxHeight) {
                            width = Math.round(width * (maxHeight / height))
                            height = maxHeight
                        }
                    }

                    // Set canvas size to desired dimensions
                    canvas.width = width
                    canvas.height = height

                    // Clear the canvas
                    ctx.clearRect(0, 0, canvas.width, canvas.height)

                    // Draw the resized image
                    ctx.drawImage(img, 0, 0, width, height)
                    const fileURI = canvas.toDataURL(file.type, 0.9)
                    callback(fileURI)
                }
            }
        } catch (e) {
            console.error(e)
            alert(`Error while uploading file: ${file.name}`)
        }
    }

    const _onUpload = e => {
        if (!e.target.files?.length) return
        const file = e.target.files[0]
        const fileMb = file.size / 1024 ** 2
        if (fileMb > maxFileSizeMb) {
            alert('Please select a file less than 5MB.')
        } else {
            compressUpload(file, handleAddingImage)
        }
    }

    const onButtonClick = e => inputFile.current.click()

    return (
        <>
            <Button
                onClick={onButtonClick}
                css={{
                    marginRight: '16px',
                    backgroundColor: '$neutral10',
                    width: '40px',
                    minWidth: '40px',
                    height: '40px',
                    padding: 0,
                }}
                variant="secondary"
                size="lg"
                disabled={disabled}
            >
                <Icon name="uploadAsset" iconSize="14px" />
            </Button>
            <input
                type="file"
                id="fileInput"
                ref={inputFile}
                accept="image/*"
                onChange={_onUpload}
                style={{ display: 'none' }}
            />
        </>
    )
}

export default ImageUpload
