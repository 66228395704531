/*
 * See Readme
 */

import { Canvas } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer, { simpleInboundRemovedRunCommand } from './command-player.js'
import { deleteCanvas } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// CanvasRemovedCommand
// ---------------------------------------------------------------------------------------------------------------------
const CanvasRemovedCommand = taggedSum('CanvasRemovedCommand', {
    Inbound: { item: 'Canvas' },
    Outbound: { item: 'Canvas' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A CanvasRemovedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the Canvas)
 */
const runInboundCommand = (resources, command) =>
    simpleInboundRemovedRunCommand(resources, command, Canvas, ReduxActions.canvasRemoved)

/*
 * A CanvasRemovedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { dispatch, displayError, projectId } = resources
    const { item } = command

    try {
        await deleteCanvas(projectId, item.id)
        dispatch(ReduxActions.canvasRemoved(item))
    } catch (e) {
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CanvasRemovedCommand,
        CommandPlayer({
            CommandType: CanvasRemovedCommand,
            Type: Canvas,
            collectionPath: projectId => `/projects/${projectId}/canvases/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'removed',
        })
    )
}

export { CanvasRemovedCommand, addCommand }
