import { Geometry } from '@range.io/basic-types'
import { equals, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import { updateCollaboration } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// GeometriesChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const GeometriesChangedCommand = taggedSum('GeometriesChangedCommand', {
    Inbound: { geometry: 'Geometry' },
    Outbound: { geometries: '[Geometry]' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A GeometriesChangedCommand.Inbound has arrived from Firestore; send it to redux.
 *
 */
const runInboundCommand = (resources, command) => {
    const { dispatch, getState, mapboxDraw } = resources
    const { geometry } = command
    const oldGeometry = ReduxSelectors.itemWithId(getState(), Geometry, geometry.id)

    if (!equals(geometry, oldGeometry)) {
        dispatch(ReduxActions.geometriesChanged([geometry]))
        mapboxDraw?.addGeometriesOnSelectedCanvasOnly([geometry])
    }
}

/*
 * A GeometriesChangedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) => {
    const { dispatch, displayError, getState, mapboxDraw, projectId } = resources
    const { geometries } = command

    // keep oldGeometries in case we have to revert later
    const state = getState()
    const oldGeometries = geometries.map(g => ReduxSelectors.itemWithId(state, Geometry, g.id))

    try {
        dispatch(ReduxActions.geometriesChanged(geometries))

        const update = async geometry => {
            let { archivedDate, canvasId, coordinates } = geometry
            const archivedTimestamp = archivedDate ? archivedDate.getTime() : null
            coordinates = JSON.stringify(coordinates)

            const { id: collaborationId } = ReduxSelectors.firstCollaborationForGeometry(state, geometry.id)
            await updateCollaboration({ projectId, collaborationId, archivedTimestamp, canvasId, coordinates })
        }

        const state = getState()
        const promises = geometries.map(update)
        await Promise.all(promises)
    } catch (e) {
        dispatch(ReduxActions.geometriesChanged(oldGeometries))
        mapboxDraw?.addGeometriesOnSelectedCanvasOnly(oldGeometries)
        displayError(e)
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        GeometriesChangedCommand,
        CommandPlayer({
            CommandType: GeometriesChangedCommand,
            Type: Geometry,
            collectionPath: projectId => `/projects/${projectId}/geometries/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
        })
    )
}

export { GeometriesChangedCommand, addCommand }
