import { equals } from '@range.io/functional'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Box,
    Button,
    DatePicker,
    FlexColumn,
    FlexRow,
    Icon,
    MultiSelect,
    TabMenu,
    Text,
} from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import ActiveFilterList from './ActiveFilterList.js'
import UserRow from './UserRow.js'

const MediaViewHeaderWrapper = styled(FlexColumn, {
    color: '$neutral04',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '16px 16px 1px 16px',
    borderBottom: '1px solid $neutral07',
    zIndex: 1,
})

const ControlsContainer = styled(FlexRow, {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    // '& > div': {
    //     display: 'flex',
    //     flexDirection: 'row',
    //     alignItems: 'center',

    //     '& > *': {
    //         marginLeft: '16px',
    //     },
    // },
})

const StyledSheetIndicator = styled('span', {
    br: '100%',
    backgroundColor: '$primary04',
    width: '8px',
    height: '8px',
    flex: '0 0 auto',
})

const DatePickerButton = styled(Button, {
    '&:hover': {
        border: '1px solid $primary04',
    },
    variants: {
        active: {
            true: {
                border: '1px solid $primary04',
                backgroundColor: '$$hoverBackgroundColor',
                color: '$$hoverColor',
            },
        },
    },
})

const DateRangePicker = ({ dateRange, onDateRangeChange }) => {
    const [showPicker, setShowPicker] = useState(false)
    const buttonRef = useRef()
    const pickerRef = useRef()

    const handleDataRangeChange = newRange => {
        onDateRangeChange(newRange)
        setShowPicker(false)
    }

    useEffect(() => {
        const listener = event => {
            // if we clicked outside of the input or the list then hide the list
            if (
                !buttonRef.current.contains(event.target) &&
                (!pickerRef?.current || !pickerRef.current.contains(event.target))
            )
                setShowPicker(false)
        }
        document.addEventListener('mousedown', listener)
        return () => document.removeEventListener('mousedown', listener)
    }, [])

    return (
        <Box css={{ position: 'relative' }}>
            <DatePickerButton
                variant="secondary"
                size="lg"
                ref={buttonRef}
                onClick={() => setShowPicker(prev => !prev)}
                active={showPicker.toString()}
            >
                <Icon name="calendar" iconSize="16px" />
                <Text>Date Range</Text>
            </DatePickerButton>
            {showPicker && (
                <Box css={{ position: 'absolute', top: '40px', left: '-50px', zIndex: 200 }} ref={pickerRef}>
                    <DatePicker selectRange={true} onChange={handleDataRangeChange} value={dateRange} />
                </Box>
            )}
        </Box>
    )
}

const FILE_TYPE_FILTERS = ['all', 'photo', 'document']

const MediaViewHeader = ({ mediaCount, updateFilter, filter, filterSettings, resetFilters, areFiltersActive }) => {
    const participantShapes = useSelector(ReduxSelectors.selectedProjectParticipantShapes)
    const enrichedTagNames = useSelector(ReduxSelectors.enrichedTagNames)
    const { canvases: allCanvasShapes, collaborations: allCollaborationsShapes } = useSelector(ReduxSelectors.allShapes)

    const handleDataRangeChange = newDataRange => updateFilter(filter.setDateRangeUpdated)(newDataRange)
    const handleCanvasFilterClick = canvasClicked => updateFilter(filter.toggleCanvas)(canvasClicked)
    const handleTagFilterClick = tagClicked => updateFilter(filter.toggleTag)(tagClicked)
    const handleUserFilterClick = userClicked => updateFilter(filter.toggleAssignee)(userClicked)

    const formatCanvasItem = canvasId => allCanvasShapes.find(cs => cs.id === canvasId).name

    const formatTagItem = tagId => `${enrichedTagNames[tagId].name} (${enrichedTagNames[tagId].count})`

    const formatUserItem = userId => {
        const participant = participantShapes[userId]
        return `${participant.firstName} ${participant.lastName}`
    }

    const renderCanvasItem = canvasId => {
        const canvasShape = allCanvasShapes.get(canvasId)
        const hasUploads = allCollaborationsShapes.some(cs => cs.canvasId === canvasId && cs.uploads?.length)

        return (
            <FlexRow css={{ alignItems: 'center', justifyContent: 'space-between', gap: '6px', flex: '1 0 auto' }}>
                <Text ellipsed>{canvasShape?.name || 'Unknown'}</Text>
                {hasUploads && <StyledSheetIndicator />}
            </FlexRow>
        )
    }

    const renderTagItem = tagId => <span>{formatTagItem(tagId)}</span>

    const renderUserItem = userId => {
        const participantShape = participantShapes[userId]

        return <UserRow participantShape={participantShape} />
    }

    const getCanvasIds = () => allCanvasShapes.sort((a, b) => a.name.localeCompare(b.name)).map(t => t.id)

    const getTagIds = () =>
        Object.values(enrichedTagNames)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(t => t.id)

    const getParticipants = () => Object.values(participantShapes ?? {}).map(p => p.id)

    const filterCanvas = (canvasId, phrase) => {
        if (!phrase?.length) return true // if there is nothing to filter by, mark all as match

        const canvasName = allCanvasShapes.find(cs => cs.id === canvasId).name
        return canvasName.toLowerCase().includes(phrase.toLowerCase())
    }

    const filterUser = (userId, phrase) => {
        if (!phrase?.length) return true // if there is nothing to filter by, mark all as match

        const participantShape = participantShapes[userId]
        return `${participantShape.fullName}`.toLowerCase().includes(phrase.toLowerCase())
    }

    const filterTag = (tagId, phrase) => {
        if (!phrase?.length) return true // if there is nothing to filter by, mark all as match

        const tagName = enrichedTagNames[tagId]?.name ?? ''
        return tagName.toLowerCase().includes(phrase.toLowerCase())
    }

    const handleClearAllFilters = () => resetFilters()

    const handleFilterClear = (filterType, filterToClear) => {
        if (filterType === 'date') handleDataRangeChange([])
        if (filterType === 'canvas') handleCanvasFilterClick(filterToClear)
        if (filterType === 'tag') handleTagFilterClick(filterToClear)
        if (filterType === 'user') handleUserFilterClick(filterToClear)
    }

    const getTabItems = () => {
        return [
            { label: `All Media (${mediaCount.photo + mediaCount.document})` },
            { label: `Photos (${mediaCount.photo})` },
            { label: `Documents (${mediaCount.document})` },
        ]
    }

    const handleTabChange = tabIndex => updateFilter(filter.setFileType)(FILE_TYPE_FILTERS[tabIndex])

    // don't render anything if the organization data hasn't been loaded
    if (equals(participantShapes, {})) return null

    return (
        <MediaViewHeaderWrapper>
            <ControlsContainer>
                <Text css={{ fontSize: 20, fontWeight: 700, marginRight: 'auto' }}>Media</Text>
                <FlexRow css={{ alignItems: 'center', gap: 16 }}>
                    Filters
                    <DateRangePicker onDateRangeChange={handleDataRangeChange} dateRange={filterSettings.date} />
                    <MultiSelect
                        filterItem={filterCanvas}
                        items={getCanvasIds()}
                        onItemClick={handleCanvasFilterClick}
                        placeholder="By Canvas"
                        renderItem={renderCanvasItem}
                        selectedItems={filterSettings.canvasIds}
                        variant="mediaViewCanvas"
                    />
                    <MultiSelect
                        filterItem={filterUser}
                        items={getParticipants()}
                        onItemClick={handleUserFilterClick}
                        placeholder="By Users"
                        renderItem={renderUserItem}
                        selectedItems={filterSettings.participantIds}
                        variant="mediaViewUser"
                    />
                    <MultiSelect
                        filterItem={filterTag}
                        items={getTagIds()}
                        onItemClick={handleTagFilterClick}
                        placeholder="By Tags"
                        renderItem={renderTagItem}
                        selectedItems={filterSettings.tagIds}
                        variant="mediaViewTags"
                    />
                </FlexRow>
            </ControlsContainer>
            {areFiltersActive && (
                <ActiveFilterList
                    currentFilters={filterSettings}
                    onClearAllClick={handleClearAllFilters}
                    onFilterClearClick={handleFilterClear}
                    formatCanvasItem={formatCanvasItem}
                    formatTagItem={formatTagItem}
                    formatUserItem={formatUserItem}
                />
            )}
            <TabMenu
                css={{ height: '33px', marginTop: '20px' }}
                items={getTabItems()}
                onChange={handleTabChange}
                controlledIndex={FILE_TYPE_FILTERS.indexOf(filterSettings.fileType)}
                initialIndex={0}
            />
        </MediaViewHeaderWrapper>
    )
}

export default MediaViewHeader
