/*
 * Canvas
 *
 * A Canvas contains a subset of a Project's Geometries and Features appropriate for the current selection.
 * It's a "Canvas" to represent a page of a PDF file or Map; see CanvasSource for more details.
 */
import { mergeRight, pick, tagged } from '@range.io/functional'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Canvas = tagged('Canvas', {
    id   : StringTypes.Id,
    name : 'String'
})

/*
 * Create a new Canvas merging an old one with values from changes
 * @sig update :: (Canvas, {k:v}) -> Canvas
 */
Canvas.update = (canvas, changes) => Canvas.from(mergeRight(canvas, changes))

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Create a Canvas from a JSON object
 * @sig fromFirebase :: {k:v} -> Canvas
 */
Canvas.fromFirebase = o =>
    Canvas.from({
        id: o.id,
        name: o.name,
    })

Canvas.toFirebase = o => pick(['id', 'name'], o)

export default Canvas
