/*
 * A command grouping actions for updating project's canvases.
 * Given a new array of canvases and their order updates the data in Firebase.
 * Uploads PDF files when appropriate (when the items have a special flag with new file reference)
 */

import { Canvas, CanvasSource } from '@range.io/basic-types'
import { equals, taggedSum } from '@range.io/functional'
import * as Firestore from 'firebase/firestore'
import { ReduxActions, ReduxSelectors } from '../../redux'
import { uploadCanvasSource } from '../firebase-facade'
import CommandPlayer from './command-player'
import { reportPromisesProgress } from './helpers/report-progress'
import { deleteCanvas } from './https-calls.js'

const ProjectCanvasesUpdatedCommand = taggedSum('ProjectCanvasesUpdatedCommand', {
    Outbound: {
        project: 'Project',
        canvasOrder: '[String]',
        canvases: '[Object]',
        onDone: 'Any', // this probably should be marked as function
        onFailure: 'Any',
    },
})

const runOutboundCommand = async (resources, command) => {
    // Update PDF of a canvas source if it has a temporary PDF
    const updateCanvasSourcePdf = async canvasSource => {
        if (!canvasSource.temporaryPdf) return null
        const pdfUrl = await uploadCanvasSource(projectId, canvasSource.id, canvasSource.pdfFile)
        return dispatch(ReduxActions.canvasSourceChanged({ id: canvasSource.id, changes: { pdfUrl } }))
    }

    // Update canvas' name if it was changed
    const updateCanvas = canvas => {
        const oldItem = ReduxSelectors.itemWithId(getState(), Canvas, canvas.id)
        if (equals(canvas.name, oldItem.name)) return null // no change
        const canvasChanges = { name: canvas.name }
        dispatch(ReduxActions.canvasChanged({ id: canvas.id, changes: canvasChanges }))
        return runTransaction(async transaction =>
            transactionUpdate(transaction, projectId, 'canvases', canvas.id, canvasChanges)
        )
    }

    // Handle removing canvases
    const removeCanvas = canvas => {
        dispatch(ReduxActions.canvasRemoved(canvas))
        return deleteCanvas(projectId, canvas.id)
    }

    // Handle adding new canvases and their canvas sources
    const addNewCanvas = async canvas => {
        const pdfUrl = await uploadCanvasSource(projectId, canvas.canvasSource.id, canvas.canvasSource.pdfFile)
        const newCanvas = Canvas(canvas.id, canvas.name)
        const { temporaryPdf, pdfFile, parentId, ...canvasSourceMain } = canvas.canvasSource
        const newCanvasSource = CanvasSource.Pdf.from({
            ...canvasSourceMain,
            canvasId: parentId,
            pdfUrl,
            userDefinedScale: 48,
        })
        await runTransaction(async transaction => {
            const { id: canvasId, ...canvasData } = Canvas.toFirebase(newCanvas)
            const { id: canvasSourceId, ...canvasSourceData } = CanvasSource.toFirebase(newCanvasSource)

            transactionSet(transaction, projectId, 'canvases', canvasId, canvasData)
            transactionSet(transaction, projectId, 'canvasSources', canvasSourceId, canvasSourceData)
        })
        dispatch(ReduxActions.canvasesAndCanvasSourcesAdded([[newCanvas, newCanvasSource]]))
    }

    const { canvases, canvasOrder, onDone, onFailure, project } = command
    const {
        dispatch,
        displayError,
        firestore,
        getState,
        projectId,
        runTransaction,
        transactionUpdate,
        transactionSet,
    } = resources
    const pdfCanvases = ReduxSelectors.pdfCanvases(getState())

    try {
        // Split the existing canvases between those that were removed and (potentially) modified
        const [removed, modified] = pdfCanvases.reduce(
            (acc, canvas) => {
                if (canvases.get(canvas.id)) return [acc[0], [...acc[1], canvases.get(canvas.id)]]
                else return [[...acc[0], canvas], acc[1]]
            },
            [[], []]
        )

        const removedPromises = removed.map(removeCanvas)

        // Handle modifying of existing canvases and their canvas sources
        const modifyPromises = modified
            .map(canvas => [updateCanvas(canvas), updateCanvasSourcePdf(canvas.canvasSource)])
            .flat()

        const addedCanvases = canvases.filter(canvas => canvas.justCreated)
        const addPromises = [...addedCanvases].map(addNewCanvas)

        const promisesArray = [...removedPromises, ...modifyPromises, ...addPromises]
        const getProgressText = (proceededCount, promisesCount) =>
            `${proceededCount}/${promisesCount} PDF sheets processed...`
        await Promise.all(reportPromisesProgress(promisesArray, getProgressText))

        // re-calculate new canvas order (make sure non-pdf ones are kept in order at the beginning) and update
        const path = Firestore.doc(firestore, `/projects/${project.id}`)
        const nonPdfCanvasSources = Object.values(ReduxSelectors.canvasSources(getState())).filter(
            cs => cs.type !== 'pdf'
        )
        const nonPdfCanvases = nonPdfCanvasSources.reduce((acc, cs) => {
            return { ...acc, [cs.canvasId]: true }
        }, {})
        const newCanvasOrder = project.canvasOrder.filter(id => !!nonPdfCanvases[id]).concat(canvasOrder)
        const canvasOrderChanges = { canvasOrder: newCanvasOrder }
        await runTransaction(async transaction => transaction.update(path, canvasOrderChanges))
        dispatch(ReduxActions.projectChanged({ id: project.id, changes: canvasOrderChanges }))

        onDone()
    } catch (e) {
        displayError(e)
        onFailure()
    }
}

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        ProjectCanvasesUpdatedCommand,
        CommandPlayer({
            CommandType: ProjectCanvasesUpdatedCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
        })
    )
}

export { ProjectCanvasesUpdatedCommand, addCommand }
