/*
 * See Readme
 */

import { Invitation } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import CommandPlayer from './command-player.js'
import { acceptInvitation } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// InvitationChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const UserInvitationChangedCommand = taggedSum('UserInvitationChangedCommand', {
    Inbound: { invitation: 'Invitation' },
    Outbound: { id: StringTypes.Id, changes: 'Object' },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------
const runInboundCommand = async (resources, command) => {
    // no-op: if the invitation was updated that means the user has accepted it and it will be deleted shortly anyway
}

/*
 * A InvitationChangedCommand.Outbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the invitation)
 */
const runOutboundCommand = async (resources, command) => {
    const { id, changes } = command
    const { dispatch, displayError } = resources

    try {
        if (!changes.acceptedTimestamp) throw new Error("Don't understand parameters")
        await acceptInvitation(id)
        dispatch(ReduxActions.userInvitationChanged({ id, changes }))
    } catch (e) {
        displayError(e)
    }
}

const addUserInvitationChangedCommandSingleton = (addCommandToHistory, registerCommandPlayer) =>
    registerCommandPlayer(
        UserInvitationChangedCommand,
        CommandPlayer({
            CommandType: UserInvitationChangedCommand,
            Type: Invitation,
            collectionPath: userId => `/users/${userId}/invitations/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
            resourceKey: 'userId',
        })
    )

export { UserInvitationChangedCommand, addUserInvitationChangedCommandSingleton }
