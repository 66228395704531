import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { App } from './components-application/index.js'
import { UndoRedo } from './firebase/commands/UndoRedo.js'
import './index.css'
import createStore from './redux/store.js'

const store = createStore()

const root = createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <UndoRedo>
                <App />
            </UndoRedo>
        </React.StrictMode>
    </Provider>
)
