import React from 'react'

import { Button, DropdownMenuWithTooltip, Icon, Text } from '../components-reusable/index.js'

const ProjectActionsDropdown = ({
    isArchived,
    canDelete = false,
    onShowProject,
    onHideProject,
    onDeleteProject,
    ...rest
}) => {
    return (
        <DropdownMenuWithTooltip.Root {...rest}>
            <DropdownMenuWithTooltip.Trigger tooltipText="More Actions">
                <Button
                    css={{ w: 28, color: '$neutral05' }}
                    variant="dropdown-trigger"
                    data-cy="project-actions-trigger"
                    onClick={e => {
                        e.preventDefault()
                        e.stopPropagation() // do not propagate to prevent clicking project card
                    }}
                >
                    <Icon iconSize="28px" name="threeDot" />
                </Button>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Portal>
                <DropdownMenuWithTooltip.Content
                    data-cy="project-actions-dropdown"
                    side="bottom"
                    align="end"
                    sideOffset={6}
                    css={{
                        backgroundColor: '$neutral10',
                    }}
                    onClick={e => e.stopPropagation()} // do not propagate to prevent clicking project card
                >
                    {isArchived ? (
                        <DropdownMenuWithTooltip.MenuItem>
                            <Button variant="dropdown-menuitem" onClick={onShowProject}>
                                <Icon iconSize="16px" name="hide" css={{ mr: 8 }} />
                                <Text>Unarchive Project</Text>
                            </Button>
                        </DropdownMenuWithTooltip.MenuItem>
                    ) : (
                        <DropdownMenuWithTooltip.MenuItem>
                            <Button variant="dropdown-menuitem" onClick={onHideProject}>
                                <Icon iconSize="16px" name="show" css={{ mr: 8 }} />
                                <Text>Archive Project</Text>
                            </Button>
                        </DropdownMenuWithTooltip.MenuItem>
                    )}
                    {canDelete && (
                        <DropdownMenuWithTooltip.MenuItem>
                            <Button variant="dropdown-menuitem-destructive" onClick={onDeleteProject}>
                                <Icon iconSize="16px" name="trash" css={{ mr: 8 }} />
                                <Text>Delete Project</Text>
                            </Button>
                        </DropdownMenuWithTooltip.MenuItem>
                    )}
                </DropdownMenuWithTooltip.Content>
            </DropdownMenuWithTooltip.Portal>
        </DropdownMenuWithTooltip.Root>
    )
}

export default ProjectActionsDropdown
