import { tagged } from '@range.io/functional'

const NavigatorSortSettings = tagged('NavigatorSortSettings', {
    sortOrder: /alphabetical|recent/,
})

const defaults = () => ({
    sortOrder: 'recent',
})

NavigatorSortSettings.defaultSortSettings = () => NavigatorSortSettings.from(defaults())

export default NavigatorSortSettings
