/*
 * See Readme
 */

import { CanvasSource } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux/index.js'
import { downloadUrlForPath } from '../firebase-facade.js'
import CommandPlayer, { simpleOutboundChangedRunCommand } from './command-player.js'

// ---------------------------------------------------------------------------------------------------------------------
// CanvasSourceChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const CanvasSourceChangedCommand = taggedSum('CanvasSourceChangedCommand', {
    Inbound: {
        canvasSource: 'CanvasSource',
    },
    Outbound: {
        id: StringTypes.Id, // CanvasSource id
        changes: 'Object', // each entry defines key/value of the existing CanvasSource that changed
    },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A CanvasSourceChangedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the CanvasSource)
 */
const runInboundCommand = (resources, command) => {
    const addPdfUrl = pdfUrl => dispatch(ReduxActions.canvasSourceChanged({ id: canvasSource.id, changes: { pdfUrl } }))

    const { dispatch, projectId } = resources
    const { canvasSource } = command

    dispatch(ReduxActions.canvasSourceAdded(canvasSource))

    // download the pdfUrl; no need to (a)wait
    downloadUrlForPath(`projects/${projectId}/canvasSources/${canvasSource.id}`).then(addPdfUrl)
}

/*
 * A CanvasSourceChangedCommand.Outbound has arrived from the UI; send it to redux AND Firestore
 */
const runOutboundCommand = async (resources, command) =>
    await simpleOutboundChangedRunCommand(
        resources,
        command,
        CanvasSource.Pdf, // TODO: always Pdf for now
        ReduxActions.canvasSourceChanged,
        'canvasSources'
    )

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CanvasSourceChangedCommand,
        CommandPlayer({
            CommandType: CanvasSourceChangedCommand,
            Type: CanvasSource,
            collectionPath: projectId => `/projects/${projectId}/canvasSources/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
        })
    )
}

export { CanvasSourceChangedCommand, addCommand }
