import { Geometry } from '@range.io/basic-types'
import { arrayToLookupTable, mergeDeepRight } from '@range.io/functional'
import diffLookupTables from '@range.io/functional/src/ramda-like/diff-lookup-tables.js'
import { useEffect, useState } from 'react'
import { useSelector, useStore } from 'react-redux'

import { ReduxSelectors } from '../redux/index.js'

/*
 * Geometry properties:
 *
 *   For all pins:
 *
 *     iconImage          String
 *     selectedIconImage  String
 *     pinType            PhotoPin|TaskPin
 *
 *   For Task Pins:
 *
 *     status             String (=== StatusName.name)
 *     statusColor        String (=== StatusName.color)
 *     text               String (=== Collaboration.name)
 *     assignee           String (from Collaboration.assignee)
 *
 * TODO: Task Pins and PhotoMarkers *both* have annotationType === 'photoMarker', which should be renamed to "pin" (?)
 * Tasks are distinguished from Photo Markers by pinType -- not by annotationType
 */

/*
 * PhotoMarkerController - manages contents of photo markers on the map.
 * Makes sure they changed based on changes in comments and uploads.
 */
const PhotoMarkerController = ({ mapboxMap, mapboxDraw }) => {
    const { getState } = useStore()

    const selectedCanvas = useSelector(ReduxSelectors.selectedCanvas)
    const allCollaborations = useSelector(ReduxSelectors.collaborationLookupTable)
    const allComments = useSelector(ReduxSelectors.commentLookupTable)
    const allUploads = useSelector(ReduxSelectors.uploadLookupTable)

    /*
     * Given a geometry of a photo marker build it's GeoJSON filling in
     * all needed properties (information about images used for selected and idle states)
     * @sig getEnrichedGeometry Geometry => GeoJSON
     */
    const getEnrichedGeometry = geometry => {
        const getMarkerCountSuffix = count => (count < 9 ? count : '9+')

        const enrichTaskPin = () => {
            const getIconImageName = () => {
                if (isArchived) return `taskBadgeArchived`
                if (isCompleted) return `taskBadgeComplete`
                if (isOverdue) return `taskBadgeOverdue`
                if (hasNote && !isCompleted) return 'photoMarkerNote'
                return `taskBadge${statusName.name.replace(/ /g, '')}`
            }

            const getIconSelectedImageName = () => {
                if (isArchived) return `taskSelectedArchived`
                if (isCompleted) return `taskSelectedComplete`
                if (isOverdue) return `taskSelected${statusName.name.replace(/ /g, '')}Overdue`
                if (hasNote && !isCompleted) return `taskSelected${statusName.name.replace(/ /g, '')}Note`
                return `taskSelected${statusName.name.replace(/ /g, '')}`
            }

            const { name, dueDate } = collaboration
            const isOverdue = dueDate && dueDate.getTime() < Date.now()

            return {
                iconImage: getIconImageName(),
                selectedIconImage: getIconSelectedImageName(),
                status: statusName.name.toUpperCase(),
                statusColor: statusName.color,
                text: name || 'Untitled Task',
                // assignee: no longer used (?)
                pinType: 'TaskPin',
            }
        }

        const enrichPhotoPin = () => {
            const getIconImageName = () => {
                if (isArchived) return `photoMarkerArchived${getMarkerCountSuffix(photoCount)}`
                if (hasNote) return 'photoMarkerNote'
                return `photoMarker${getMarkerCountSuffix(photoCount)}`
            }

            const getIconSelectedImageName = () => {
                if (isArchived) return `photoMarkerSelectedArchived${getMarkerCountSuffix(photoCount)}`
                if (hasNote && photoCount > 0) return `photoMarkerSelectedBadge${getMarkerCountSuffix(photoCount)}`
                if (hasNote) return 'photoMarkerSelectedNote'
                return `photoMarkerSelected${getMarkerCountSuffix(photoCount)}`
            }

            const uploads = ReduxSelectors.uploadsForCollaboration(getState(), collaboration)
            const photoCount = uploads.length
            return {
                iconImage: getIconImageName(),
                selectedIconImage: getIconSelectedImageName(),
                pinType: 'PhotoPin',
            }
        }

        const collaboration = ReduxSelectors.firstCollaborationForGeometry(getState(), geometry.id) // may be missing
        const comments = ReduxSelectors.commentsForCollaboration(getState(), collaboration)
        const statusName = ReduxSelectors.statusNameForCollaboration(getState(), collaboration)
        const hasNote = comments.some(comment => comment.isNote && !comment.completedById)
        const isArchived = geometry.archivedDate
        const isCompleted = statusName?.isCompleted

        const geoJson = Geometry.asGeoJson(geometry)
        const properties = statusName ? enrichTaskPin() : enrichPhotoPin()
        return mergeDeepRight(geoJson, { properties })
    }

    /*
     * The geometries changed in some way; so we need to tell Mapbox Draw to redraw;
     * Uses diffLookupTables to find added, changed or removed geometries and ignores the unchanged ones
     */
    const reconcileGeometries = () => {
        // because we call this function from a setTimeout (below), the mapboxDraw context may no longer be alive,
        // because the CanvasView has already unmounted -- which will have removed mapboxDraw from mapboxMap
        if (!mapboxMap.hasControl(mapboxDraw)) return

        // because we call this function from a setTimeout (below), geometriesForSelectedCanvas can be out of date
        const geometriesForSelectedCanvas = ReduxSelectors.geometriesForSelectedCanvas(getState())

        const enrichedGeometries = geometriesForSelectedCanvas.map(getEnrichedGeometry)

        const enrichedGeometriesLookupTable = arrayToLookupTable('id', enrichedGeometries)
        const { added, removed, changed } = diffLookupTables(
            lastEnrichedGeometriesLookupTable,
            enrichedGeometriesLookupTable
        )

        // add or remove changed geometries if there are any
        if (added.length + removed.length + changed.length > 0) {
            mapboxDraw.delete(removed)
            added.map(id => mapboxDraw.add(enrichedGeometriesLookupTable[id]))
            changed.map(id => mapboxDraw.add(enrichedGeometriesLookupTable[id])) // changed has same semantics as add
            setLastEnrichedGeometriesLookupTable(enrichedGeometriesLookupTable)
        }

        mapboxMap.fire('draw.refresh')

        // We need to do this step to make sure Mapbox Draw notices the changes and re-renders
        enrichedGeometries.forEach(f => mapboxDraw.setFeatureProperty(f.id, 'iconImage', f.properties.iconImage))
    }

    const geometriesForSelectedCanvas = ReduxSelectors.geometriesForSelectedCanvas(getState())

    const [lastEnrichedGeometriesLookupTable, setLastEnrichedGeometriesLookupTable] = useState({})

    useEffect(() => {
        if (!mapboxDraw || !selectedCanvas) return

        //! HACK - for some reason we need to setTimeout this call for MapboxDraw to update on app load
        setTimeout(reconcileGeometries, 0)
    }, [geometriesForSelectedCanvas, allCollaborations, allComments, allUploads, selectedCanvas, mapboxDraw])

    return null
}

export default PhotoMarkerController
