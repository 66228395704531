/*
 * The user was invited to one or more organizations; show a modal to allow them to accept the invitations
 * NOTE: right now, they have no choice about accepting; they're really just being notified that they WILL be added
 */
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import {
    Avatar,
    Button,
    FlexColumn,
    FlexRow,
    LoadingDotsAnimation,
    Logo,
    LogoText,
    ScrollArea,
    Text,
} from '../components-reusable/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { UserInvitationChangedCommand } from '../firebase/commands/user-invitation-changed-command.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import {
    BGLogo,
    LabelLayout,
    OrganizationLabelContainer,
    OrganizationMemberRow,
    PrimaryLabel,
    SecondaryLabel,
    SecondaryLabelOrganization,
    StyledForm,
    StyledLogo,
    StyledPage,
    ScrollableContainer,
} from './RegistrationSharedStyles.js'

const InvitationRow = styled(FlexRow, {
    color: '$neutral04',
    backgroundColor: '$neutral10',
    border: '1px solid $neutral07',
    width: '100%',
    height: 'auto',
    alignItems: 'center',
    flexWrap: 'noWrap',
    gap: '12px',
    borderRadius: '6px',
    padding: '16px',
})

// Form for RegistrationAcceptInvitations; currently not really a form so much as a simple alert with an "ok" button
const AcceptInvitationsForm = ({ onSubmit, invitations, isLoading }) => {
    const invitationRow = invitation => {
        const { logo = null, inviterFirstName, inviterLastName, organizationName } = invitation
        return (
            <InvitationRow key={invitation.id}>
                <Avatar
                    size="40"
                    color="gray"
                    url={logo}
                    fallbackText={organizationName[0]}
                    tooltipText={organizationName}
                />
                <OrganizationLabelContainer>
                    <Text css={{ fontSize: '18px', fontWeight: '600' }}>{organizationName}</Text>
                    <OrganizationMemberRow>
                        <SecondaryLabelOrganization>
                            Invited by {inviterFirstName} {inviterLastName}
                        </SecondaryLabelOrganization>
                    </OrganizationMemberRow>
                </OrganizationLabelContainer>
            </InvitationRow>
        )
    }

    const _onSubmit = e => {
        e.preventDefault()
        onSubmit()
    }
    const scrollAreaHeight = '320px'

    return (
        <StyledForm onSubmit={_onSubmit}>
            <ScrollableContainer>
                <StyledLogo>
                    <Logo />
                    <LogoText />
                </StyledLogo>
                <LabelLayout>
                    <PrimaryLabel>You've been invited!</PrimaryLabel>
                    <SecondaryLabel>You will be added as a member of the following organizations.</SecondaryLabel>
                </LabelLayout>
                <ScrollArea
                    css={{ background: '$neutral09', b: '1px solid $neutral07', br: 6 }}
                    maxHeight={scrollAreaHeight}
                >
                    <FlexColumn css={{ p: 8, gap: 8, width: '100%' }}>{invitations.map(invitationRow)}</FlexColumn>
                </ScrollArea>
                <FlexColumn css={{ gap: 16, width: '100%' }}>
                    <Button variant="primary" type="submit" size="lg" css={{ w: '100%' }}>
                        {isLoading ? <LoadingDotsAnimation variant="button" /> : <Text>Accept Invitations</Text>}
                    </Button>
                </FlexColumn>
            </ScrollableContainer>
        </StyledForm>
    )
}

/*
 * Storybook-friendly version of the RegistrationAcceptInvitations form
 */
const RegistrationAcceptInvitationsDumb = ({ onSubmit, invitations, isLoading, organization = {} }) => {
    return (
        <StyledPage>
            <BGLogo>
                <img src="/rangebackgroundicon.svg" icon="Range icon" />
            </BGLogo>
            <AcceptInvitationsForm
                organization={organization}
                onSubmit={onSubmit}
                invitations={invitations}
                isLoading={isLoading}
            />
        </StyledPage>
    )
}

/*
 * The user was invited to one or more organizations; show a modal to allow them to accept the invitations
 * NOTE: right now, they have no choice about accepting; they're really just being notified that they WILL be added
 */
const RegistrationAcceptInvitations = ({ organization = {} }) => {
    const changeInvitation = invitation =>
        runCommand(UserInvitationChangedCommand.Outbound(invitation.id, { acceptedTimestamp: new Date() }))

    const onSubmit = () => invitations.map(changeInvitation)

    const { runCommand } = useCommandHistory()
    const user = useSelector(ReduxSelectors.selectedUser)
    const invitations = user?.invitations || []

    // if user has an invitation with an acceptedTimestamp, then we have sent our acceptance already,
    // but Firestore hasn't yet processed it and removed our invitation, so it's still pending
    const pending = invitations.length && invitations.some(i => i.acceptedTimestamp)

    return invitations.length ? (
        <RegistrationAcceptInvitationsDumb
            org
            invitations={invitations}
            onSubmit={onSubmit}
            organization={organization}
            isLoading={pending}
        />
    ) : (
        <Navigate to="/" replace={true} />
    )
}

export { RegistrationAcceptInvitationsDumb, RegistrationAcceptInvitations }
